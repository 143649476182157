/* Copyright Information: Created by BuyGroup */
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const ChangePassword = (props) => {
  const closeModal = () => {
    props.handleModalHide();
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        onSubmit={(values, actions) => {
          // return;
          props.onSubmit(values, actions);
        }}
        validationSchema={Yup.object().shape({
          old_password: Yup.string().required("Please enter old password"),
          password: Yup.string()
            .notOneOf(
              [Yup.ref("old_password"), null],
              "Old passwords is same as new password"
            )
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            )
            .required("Please enter new password"),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              isOpen={props.changePasswordModal}
              toggle={closeModal}
              style={{ overlay: { opacity: 0.1 } }}
            >
              <ModalHeader toggle={closeModal}>
                <h5 className="modal-title f-w-600">Update Password</h5>
              </ModalHeader>
              <ModalBody>
                {props.errorMessage !== "" ? (
                  <p className="alert alert-danger">{props.errorMessage}</p>
                ) : (
                  ""
                )}
                <FormGroup>
                  <Label htmlFor="old_password" className="col-form-label">
                    Old Password
                  </Label>
                  <input
                    type="password"
                    name="old_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.old_password}
                    className="form-control"
                    placeholder="Enter your old password"
                  />
                  <ErrorMessage name="old_password">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="password" className="col-form-label">
                    New Password
                  </Label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className="form-control"
                    placeholder="Enter your new password"
                  />
                  <ErrorMessage name="password">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={props.isloading}
                >
                  {props.isloading ? "Loading.." : "Update"}
                </Button>
                <Button
                  color="light"
                  type="button"
                  onClick={() => closeModal()}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
/* Copyright Information: Created by BuyGroup */
