/* Copyright Information: Created by BuyGroup */
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Moment from "react-moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import { getOrderList, orderStatus } from "../services/OrderServices";
import {
  categoryList,
  getSubCategoryDetail,
} from "../services/ProductServices";
import "./Order.css";
import { generatePdfDocument } from "./OrderPDf";
import OrderUpdate from "./OrderUpdate";
toast.configure();

const OrderList = (props) => {
  /* const SearchBar  responsible for serach */
  const { SearchBar } = Search;

  /* const "orderList" constant store all data of order List*/
  const [orderList, setOrderList] = useState([]);

  /* const "isloading" constant is responsible for showing loader in order List */
  const [isLoading, setIsLoading] = useState(false);

  /*  const "orderUpdateModal" responsible for order Update Modal open or closed  */
  const [orderUpdateModal, setOrderUpdateModal] = useState(false);

  /* const "isLoadingButton" constant is disable button when data is feaching from api */
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /* const "rowData"  store select Row data */
  const [rowData, setRowData] = useState({});

  const [lodingDownloadPdf, setLodingDownloadPdf] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [categorys, setCategorys] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [subCategorys, setSubCategorys] = useState([]);

  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [categoryId, setCategoryId] = useState("");

  const [subCategoryId, setSubCategoryId] = useState("");

  const [searchableGroupId, setSearchableGroupId] = useState("");

  const [searchableGroupValue, setSearchableGroupValue] = useState("");

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const [loadingFilter, setLoadingFilter] = useState(false);

  const [hideFilters, setHideFilters] = useState(false);

  const columns = [
    {
      dataField: "Order_id",
      text: "Order Id",
    },
    {
      dataField: "customer_name",
      text: "Name",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "total",
      text: "Total Amount",
      headerAlign: "right",
      align: "right",
      formatter: priceFormatter,
    },
    {
      dataField: "Total_tax",
      text: "Tax Amount",
      headerAlign: "right",
      align: "right",
      formatter: priceFormatter,
    },
    {
      dataField: "Shipping_value",
      text: "Shipping Value",
      headerAlign: "right",
      align: "right",
      formatter: priceFormatter,
    },
    {
      dataField: "Status",
      text: "Status",
      headerAlign: "center",
      headerClasses: "header-classes-status_column",
      classes: "column-classes-status_column",
      align: "center",
      formatter: (cell, row) => {
        return (
          <div className={`status_column ${cell}`}>
            <span>{cell}</span>
          </div>
        );
      },
    },
  ];

  const orderStatusList = [
    {
      label: "Pending",
      value: "in_progress",
    },
    {
      label: "To Be Shipped",
      value: "shipping",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Cancelled",
      value: "cancelled",
      filterValue: (cell, row) => row,
    },
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Payment Failed",
      value: "payment_failed",
    },
  ];

  useEffect(() => {
    // setDateRangeValue([getstartDate(), new Date()]);
    getCategoryList();
    getOrder();
  }, []);

  useEffect(() => {
    if (categoryId) {
      getSubCategoryList(categoryId);
    }
  }, [categoryId]);

  /**
   * This function will return date of one month before
   * @returns
   */
  const getstartDate = () => {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  };

  /* it will formate number into two decimal number  */
  function priceFormatter(cell, row) {
    let myNumber = cell;
    let myNumberWithTwoDecimalPlaces = parseFloat(myNumber).toFixed(2);
    return myNumberWithTwoDecimalPlaces;
  }

  /**
   * This funciton will formate start date of date formator in yyyy-mm-dd formate
   * @param {array} dateRangeValue
   * @returns
   */
  const formatStartDate = (dateRangeValue) => {
    if (dateRangeValue !== null) {
      if (dateRangeValue.length > 1) {
        return moment(dateRangeValue[0]).format("YYYY-MM-DD");
      }
      return moment(getstartDate()).format("YYYY-MM-DD");
    }
    return null;
  };

  /**
   * This funciton will formate end date of date formator in yyyy-mm-dd formate
   * @param {array} dateRangeValue
   * @returns
   */

  const formatEndDate = (dateRangeValue) => {
    if (dateRangeValue !== null) {
      if (dateRangeValue.length > 1) {
        return moment(dateRangeValue[1]).format("YYYY-MM-DD");
      }
      return moment(new Date()).format("YYYY-MM-DD");
    }
    return null;
  };

  /**
   * This function will handle status value
   * @param {object} selectedStatus
   * @returns
   */
  const handleStatus = (selectedStatus) => {
    if (selectedStatus) {
      return selectedStatus.value;
    }
    return "";
  };

  // Get all order list from API
  const getOrder = () => {
    setIsLoading(true);
    setLoadingFilter(true);
    const prpareData = {
      status: handleStatus(selectedStatus),
      group_name: searchableGroupValue,
      range_start: formatStartDate(dateRangeValue),
      range_end: formatEndDate(dateRangeValue),
      group_id: searchableGroupId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
    };

    getOrderList(prpareData)
      .then((res) => {
        if (res.status === 200) {
          setOrderList(res.data);
          setIsLoading(false);
          setLoadingFilter(false);
          setHideFilters(true);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoading(false);
        setLoadingFilter(false);
        setHideFilters(true);
      });
  };

  /**
   * Function handleModalOpen open update order modal
   * @param {row} row data object
   */

  const handleModalOpen = (orderSubRow, index) => {
    setRowData(orderSubRow);
    setOrderUpdateModal(true);
  };

  /**
   * Function updateOrder update order status and API calling
   *@param {values} values data object
   */
  const updateOrder = (values, actions) => {
    setIsLoadingButton(true);
    orderStatus(values)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setIsLoadingButton(false);
          setOrderUpdateModal(false);
          getOrder();
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoadingButton(false);
      });
  };

  const handleLodingDownloadPdf = (value) => {
    setLodingDownloadPdf(value);
  };

  /**
   * This function will handle category selection
   * @param {object} selectedCategory
   */
  const handleChangeCategory = (selectedCategory) => {
    const categoryId = selectedCategory ? selectedCategory.value : "";
    setSelectedCategory(selectedCategory);
    setCategoryId(categoryId);
  };

  /**
   * This function will handle sub category selection
   * @param {object} selectedSubCategory
   */
  const handleSubChangeCategory = (selectedSubCategory) => {
    const subCategoryId = selectedSubCategory ? selectedSubCategory.value : "";
    setSelectedSubCategory(selectedSubCategory);
    setSubCategoryId(subCategoryId);
  };

  /*
   * Function getAllCategory get all the data for categories
   */

  const getCategoryList = () => {
    categoryList()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.category_id, label: cat.category_name };
          });
          setCategorys(options);
        }
      })
      .catch((error) => {
        setCategorys([]);
        toast.error(error.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This function will give sub category of respected categoryId
   * @param {string} categoryId
   */
  const getSubCategoryList = (categoryId) => {
    if (categoryId) {
      getSubCategoryDetail(categoryId)
        .then((res) => {
          if (res?.status === 200) {
            let options = res.data.map(function (cat) {
              return { value: cat.id, label: cat.category };
            });
            setSubCategorys(options);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const expandRow = {
    renderer: (orderRow) => (
      <div className="innerSubCategory">
        <table className="table">
          <tr>
            <th>Image</th>
            <th>Group Id</th>
            <th>Group Name</th>
            <th>Category</th>
            <th>Sub Category</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Supplier</th>
            <th>Status</th>
          </tr>

          {orderRow.order_lines.length > 0 ? (
            orderRow.order_lines.map((orderSubRow, index) => {
              return (
                <tr key={orderSubRow.order_line_id}>
                  <td>
                    <div className="product_thumb">
                      <img
                        alt="Not Found"
                        className="img-responsive"
                        src={orderSubRow.path}
                      />
                    </div>
                  </td>
                  <td>{orderSubRow.group_id}</td>
                  <td>{orderSubRow.group_name}</td>
                  <td>{orderSubRow.category}</td>
                  <td>{orderSubRow.sub_category}</td>
                  <td>
                    <Moment format="MM/DD/YYYY h:mm a">
                      {orderSubRow.start_date}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="MM/DD/YYYY h:mm a">
                      {orderSubRow.end_date}
                    </Moment>
                  </td>
                  <td>{orderSubRow.quantity}</td>
                  <td>${parseFloat(orderSubRow.rate).toFixed(2)}</td>
                  <td>{orderSubRow.company_name}</td>
                  <td>
                    <div className={`status_column ${orderSubRow.status}`}>
                      <span>{orderSubRow.status}</span>
                    </div>
                  </td>
                  {orderSubRow.status !== "completed" ? (
                    <td>
                      <span
                        className="inline-block pointer-cursor"
                        onClick={() => handleModalOpen(orderSubRow, index)}
                      >
                        <i
                          className="fa fa-pencil"
                          style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: "#e4566e",
                          }}
                        ></i>
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              );
            })
          ) : (
            <td>No Data Found</td>
          )}
        </table>
      </div>
    ),
    onlyOneExpanding: true,
  };

  const applyFilter = () => {
    getOrder();
  };

  return (
    <>
      <Breadcrumbs title="Orders" parent="" subtitle="Orders Admin Panel" />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <CardBody>
                      <ToolkitProvider
                        keyField="Order_id"
                        data={orderList}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <>
                            <div className="order-header d-flex align-items-center flex-wrap justify-content-between">
                              <h5>Orders</h5>
                              <Button
                                type="button"
                                color="secondary"
                                data-toggle="modal"
                                data-original-title="Download pdf"
                                onClick={() =>
                                  generatePdfDocument(
                                    orderList,
                                    handleLodingDownloadPdf
                                  )
                                }
                                disabled={lodingDownloadPdf || isLoading}
                              >
                                <i className="fa fa-download mr-2"></i>
                                {lodingDownloadPdf ? (
                                  <>
                                    <Spinner size="sm" /> Download... Pdf
                                  </>
                                ) : (
                                  "Download Pdf"
                                )}
                              </Button>
                            </div>
                            {hideFilters && (
                              <div className="select_dropdown">
                                <Row className="table_SearchBar">
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={selectedStatus}
                                      onChange={(value) =>
                                        setSelectedStatus(value)
                                      }
                                      options={orderStatusList}
                                      isClearable={true}
                                      placeholder="Filter By Status"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={selectedCategory}
                                      onChange={handleChangeCategory}
                                      options={categorys}
                                      isClearable={true}
                                      placeholder="Filter By Category"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={selectedSubCategory}
                                      onChange={handleSubChangeCategory}
                                      options={subCategorys}
                                      isClearable={true}
                                      placeholder="Filter By Sub Category"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Input
                                      type="search"
                                      value={searchableGroupValue}
                                      onChange={(e) =>
                                        setSearchableGroupValue(e.target.value)
                                      }
                                      placeholder="Search Group"
                                    />
                                  </Col>
                                </Row>
                                <Row className="filter-container">
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Input
                                      type="number"
                                      value={searchableGroupId}
                                      onChange={(e) =>
                                        setSearchableGroupId(e.target.value)
                                      }
                                      placeholder="Search Group by Id"
                                      min="0"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <DateRangePicker
                                      onChange={(dates) => {
                                        console.log("dates", dates);
                                        setDateRangeValue(dates);
                                      }}
                                      value={dateRangeValue}
                                      rangeDivider="to"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  ></Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col d-flex justify-content-end align-items-center"
                                  >
                                    {" "}
                                    <Button
                                      type="submit"
                                      color="primary"
                                      onClick={applyFilter}
                                      disabled={loadingFilter}
                                    >
                                      {loadingFilter ? "Apply..." : "Apply"}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            )}

                            {isLoading ? (
                              <div className="custom-loader">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              <>
                                <BootstrapTable
                                  {...props.baseProps}
                                  striped
                                  hover
                                  id="pdftable"
                                  condensed
                                  noDataIndication={"No order found"}
                                  bordered={false}
                                  expandRow={expandRow}
                                  pagination={paginationFactory({
                                    sizePerPage: 10,
                                    showTotal: true,
                                    hideSizePerPage: true,
                                  })}
                                  rowClasses="row-hover pointer-cursor"
                                />
                              </>
                            )}
                          </>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
      {orderUpdateModal && (
        <OrderUpdate
          orderUpdateModal={orderUpdateModal}
          onSubmit={updateOrder}
          isLoadingButton={isLoadingButton}
          rowData={rowData}
          handleModalClose={() => setOrderUpdateModal(false)}
        />
      )}
    </>
  );
};
export default OrderList;
/* Copyright Information: Created by BuyGroup */
