/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, FormGroup, Label, Row, Col, Container } from "reactstrap";
import * as Yup from "yup";
import { login } from "../../services/AuthServices";
import { getAuthRedirectUrl } from "../../utils/General";
import { Link } from "react-router-dom";
import logo from "../../assets/images/dashboard/logo.png";
const Login = () => {
  const history = useHistory();

  //this is used for loading and disable the button of login
  const [isLoading, setLoading] = useState(false);

  //this is used for error message
  const [errorMessage, setErrorMessage] = useState("");

 // lgoin function takes object of email and password
  const onSubmit = (user, onSubmitProps) => {
    console.log(onSubmitProps, "onSubmitProps");
    setLoading(true);
    login(user)
      .then((response) => {
        if (response !== undefined && response.token !== undefined) {
          setLoading(false);
          localStorage.setItem("token", response.token);
          localStorage.setItem("user", JSON.stringify(response));
          let role = [];
          role.push(response.role);
          localStorage.setItem("role", JSON.stringify(role));
          history.push(getAuthRedirectUrl());
          onSubmitProps.resetForm();
        
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.data.message);
        onSubmitProps.resetForm();
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <section className="login-page section-b-space">
          <Container>
            <Row>
              <Col lg="12">
                <div>
                  <img src={logo} className="logoImg" />
                </div>
                <div className="theme-card">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={(values, onSubmitProps) => {
                      onSubmit(values, onSubmitProps);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Please enter a valid email")
                        .required("Please enter email address"),
                      password: Yup.string().required("Please enter password"),
                    })}
                  >
                    {(props) => {
                      const {
                         handleSubmit,
                       } = props;
                      return (
                        <Form
                          onSubmit={handleSubmit}
                          className="form-horizontal auth-form"
                        >
                          <h3 className="text-center">Login</h3>
                          {errorMessage && (
                            <p className="alert alert-danger">{errorMessage}</p>
                          )}

                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Email
                            </Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="email"
                            />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Password
                            </Label>
                            <Field
                              type="password"
                              className="form-control"
                              name="password"
                            />
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div className="input-feedback">{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                          <Row className="align-items-center mt-4 my_account_footer">
                            <Col md="6">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading.." : "Login"}
                              </Button>
                            </Col>
                            <Col md="6" className="forgot-link">
                              <Link to="/forgot-password">Forgot Password</Link>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Login;
