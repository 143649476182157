import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import * as Yup from "yup";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
const ApproveCustomerDocument = (props) => {
  const {
    documentData,
    openVerfiyModal,
    closeVerfiyModal,
    verifyDocument,
    verifyModalOpenState,
  } = props;
  const onSubmit = (data) => {
    const doc_data = {
      doc_id: documentData.doc_id,
      expiration_date: data.expiration_date,
    };
    verifyDocument(doc_data);
  };

  return (
    <Modal isOpen={verifyModalOpenState} style={{ overlay: { opacity: 0.1 } }}>
      <ModalHeader toggle={closeVerfiyModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Verify Document
        </h5>
      </ModalHeader>

      <Formik
        initialValues={{ expiration_date: documentData?.expiration_date }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          expiration_date: Yup.string()
            .required("Please Select Expiration Date")
            .nullable(),
        })}
      >
        {({
          setFieldValue,
          handleBlur,
          errors,
          touched,
          values,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form>
            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="col-form-label">File Name</Label>
                    <span className="d-flex">{documentData?.doc_filename}</span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Document Expiration Date
                    </Label>
                    <div className="d-block full-calendar">
                      <DatePicker
                        className="d-block form-control"
                        onChange={(selectedDate) => {
                          console.log(selectedDate);
                          setFieldValue("expiration_date", selectedDate);
                        }}
                        selected={
                          values.expiration_date
                            ? new Date(values.expiration_date)
                            : null
                        }
                        minDate={new Date()}
                        name="expiration_date"
                      />
                      <label className="err-msg">
                        {errors.expiration_date &&
                          touched.expiration_date &&
                          errors.expiration_date}
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Please Wait" : "Approve"}
              </Button>
              <Button type="button" color="light" onClick={closeVerfiyModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ApproveCustomerDocument;
