/* Copyright Information: Created by BuyGroup */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Link } from "react-router-dom";
import UserPanel from "./user-panel";

// image import
import logo from "../../assets/images/dashboard/logo.png";

function Sidebar(props) {
  // let history = useHistory();

  // function handleLogout() {
  //   localStorage.removeItem("roles");
  //   history.push("/");
  // }

  return (
    <>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <span >
              <img
                className="blur-up lazyloaded img-responsive logo"
                src={logo}
                alt="logo"
              />
            </span>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />
          <ul className="sidebar-menu">
            {props.routes
              .filter(
                (route) => route.hidden === undefined || route.hidden !== true
              )
              .map(({ path, title, icon }) => {
                return (
                  <li key={title}>
                    <NavLink
                      key={path}
                      className="sidebar-menu-item sidebar-header"
                      to={`${props.prefix}${path}`}
                    >
                      <FontAwesomeIcon icon={icon} />
                      <span className="sidebar-title">{title}</span>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  prefix: PropTypes.string,
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  prefix: "",
  className: "",
};

export default memo(Sidebar);
/* Copyright Information: Created by BuyGroup */
