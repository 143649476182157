/* Copyright Information: Created by BuyGroup */
import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, FormGroup, Label, Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { forgotPassword } from "../../services/AuthServices";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { toast } from "react-toastify";
import logo from "../../assets/images/dashboard/logo.png";
toast.configure();
const ForgotPassword = () => {
  // This is used for loading and disable the button of login
  const [isLoading, setLoading] = useState(false);

// forgot password function takes object of email and url
  const onSubmit = (userData, onSubmitProps) => {
    setLoading(true);
    forgotPassword(userData)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          onSubmitProps.resetForm();
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });

        onSubmitProps.resetForm();
      });
  };

  

  return (
    <div>
      <div>
        <div className="container-fluid">
          <section className="login-page section-b-space">
            <Container>
              <Row>
                <Col lg="12">
                  <div>
                    <img src={logo} className="logoImg" />
                  </div>
                  <div className="theme-card">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      onSubmit={(values, onSubmitProps) =>
                        onSubmit(values, onSubmitProps)
                      }
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Please enter a valid email")
                          .required("Please enter email address"),
                      })}
                    >
                      {(props) => {
                        const {
                          errors,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          handleBlur,
                          touched,
                        } = props;
                        return (
                          <Form
                            onSubmit={handleSubmit}
                            className="form-horizontal auth-form"
                          >
                            <div>
                              <div
                                style={{
                                  fontSize: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {/* <strong>FORGOT PASSWORD</strong> */}
                                <h3 className="text-center">FORGOT PASSWORD</h3>
                              </div>
                              Please enter your email address. You will receive
                              password reset link on your email.
                            </div>
                            {/* {errorMessage && (
                            <p className="alert alert-danger">{errorMessage}</p>
                          )} */}

                            <FormGroup>
                              <Label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Email
                              </Label>
                              <Field
                                type="text"
                                className="form-control"
                                name="email"
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div className="input-feedback">{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>

                            <Row className="align-items-center mt-4 my_account_footer">
                              <Col md="6">
                                <Button
                                  type="submit"
                                  color="primary"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Loading.." : "Submit"}
                                </Button>
                              </Col>
                              <Col md="6" className="forgot-link">
                                <Link to="/">Back to Login</Link>
                              </Col>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
/* Copyright Information: Created by BuyGroup */
