/* Copyright Information: Created by BuyGroup */
import React, { Fragment } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { getAllowedRoutes, isLoggedIn } from "../utils";
import PrivateRoutesConfig from "../config/PrivateRoutesConfig";
import Sidebar from "../components/common/Sidebar";

function PrivateRoutes(props) {
  const match = useRouteMatch("/app");
  let allowedRoutes = [];

  if (isLoggedIn()) {
    allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
  } else {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Sidebar
        routes={allowedRoutes}
        path={match.path}
        className="bg-white"
        handleClass={props.handleClass}
      />
      {/* <MapAllowedRoutes
                routes={allowedRoutes}
                basePath="/app"
                isAddNotFound
            /> */}
    </Fragment>
  );
}

export default PrivateRoutes;
/* Copyright Information: Created by BuyGroup */
