/* Copyright Information: Created by BuyGroup */
const StateListJson =[
  {
     "name":"Southern Nations, Nationalities, and Peoples' Region",
     "isoCode":"SN",
     "countryCode":"ET",
     "latitude":"6.51569110",
     "longitude":"36.95410700"
  },
  {
     "name":"Somali Region",
     "isoCode":"SO",
     "countryCode":"ET",
     "latitude":"6.66122930",
     "longitude":"43.79084530"
  },
  {
     "name":"Amhara Region",
     "isoCode":"AM",
     "countryCode":"ET",
     "latitude":"11.34942470",
     "longitude":"37.97845850"
  },
  {
     "name":"Tigray Region",
     "isoCode":"TI",
     "countryCode":"ET",
     "latitude":"14.03233360",
     "longitude":"38.31657250"
  },
  {
     "name":"Oromia Region",
     "isoCode":"OR",
     "countryCode":"ET",
     "latitude":"7.54603770",
     "longitude":"40.63468510"
  },
  {
     "name":"Afar Region",
     "isoCode":"AF",
     "countryCode":"ET",
     "latitude":"11.75593880",
     "longitude":"40.95868800"
  },
  {
     "name":"Harari Region",
     "isoCode":"HA",
     "countryCode":"ET",
     "latitude":"9.31486600",
     "longitude":"42.19677160"
  },
  {
     "name":"Dire Dawa",
     "isoCode":"DD",
     "countryCode":"ET",
     "latitude":"9.60087470",
     "longitude":"41.85014200"
  },
  {
     "name":"Benishangul-Gumuz Region",
     "isoCode":"BE",
     "countryCode":"ET",
     "latitude":"10.78028890",
     "longitude":"35.56578620"
  },
  {
     "name":"Gambela Region",
     "isoCode":"GA",
     "countryCode":"ET",
     "latitude":"7.92196870",
     "longitude":"34.15319470"
  },
  {
     "name":"Addis Ababa",
     "isoCode":"AA",
     "countryCode":"ET",
     "latitude":"8.98060340",
     "longitude":"38.75776050"
  },
  {
     "name":"Petnjica Municipality",
     "isoCode":"23",
     "countryCode":"ME",
     "latitude":"42.93534800",
     "longitude":"20.02114490"
  },
  {
     "name":"Bar Municipality",
     "isoCode":"02",
     "countryCode":"ME",
     "latitude":"42.12781190",
     "longitude":"19.14043800"
  },
  {
     "name":"Danilovgrad Municipality",
     "isoCode":"07",
     "countryCode":"ME",
     "latitude":"42.58357000",
     "longitude":"19.14043800"
  },
  {
     "name":"Rožaje Municipality",
     "isoCode":"17",
     "countryCode":"ME",
     "latitude":"42.84083890",
     "longitude":"20.16706280"
  },
  {
     "name":"Plužine Municipality",
     "isoCode":"15",
     "countryCode":"ME",
     "latitude":"43.15933840",
     "longitude":"18.85514840"
  },
  {
     "name":"Nikšić Municipality",
     "isoCode":"12",
     "countryCode":"ME",
     "latitude":"42.79971840",
     "longitude":"18.76009630"
  },
  {
     "name":"Šavnik Municipality",
     "isoCode":"18",
     "countryCode":"ME",
     "latitude":"42.96037560",
     "longitude":"19.14043800"
  },
  {
     "name":"Plav Municipality",
     "isoCode":"13",
     "countryCode":"ME",
     "latitude":"42.60013370",
     "longitude":"19.94075410"
  },
  {
     "name":"Pljevlja Municipality",
     "isoCode":"14",
     "countryCode":"ME",
     "latitude":"43.27233830",
     "longitude":"19.28315310"
  },
  {
     "name":"Berane Municipality",
     "isoCode":"03",
     "countryCode":"ME",
     "latitude":"42.82572890",
     "longitude":"19.90205090"
  },
  {
     "name":"Mojkovac Municipality",
     "isoCode":"11",
     "countryCode":"ME",
     "latitude":"42.96880180",
     "longitude":"19.52110630"
  },
  {
     "name":"Andrijevica Municipality",
     "isoCode":"01",
     "countryCode":"ME",
     "latitude":"42.73624770",
     "longitude":"19.78595560"
  },
  {
     "name":"Gusinje Municipality",
     "isoCode":"22",
     "countryCode":"ME",
     "latitude":"42.55634550",
     "longitude":"19.83060510"
  },
  {
     "name":"Bijelo Polje Municipality",
     "isoCode":"04",
     "countryCode":"ME",
     "latitude":"43.08465260",
     "longitude":"19.71154720"
  },
  {
     "name":"Kotor Municipality",
     "isoCode":"10",
     "countryCode":"ME",
     "latitude":"42.57402610",
     "longitude":"18.64131450"
  },
  {
     "name":"Podgorica Municipality",
     "isoCode":"16",
     "countryCode":"ME",
     "latitude":"42.36938340",
     "longitude":"19.28315310"
  },
  {
     "name":"Old Royal Capital Cetinje",
     "isoCode":"06",
     "countryCode":"ME",
     "latitude":"42.39309590",
     "longitude":"18.91159640"
  },
  {
     "name":"Tivat Municipality",
     "isoCode":"19",
     "countryCode":"ME",
     "latitude":"42.42348000",
     "longitude":"18.71851840"
  },
  {
     "name":"Budva Municipality",
     "isoCode":"05",
     "countryCode":"ME",
     "latitude":"42.31407200",
     "longitude":"18.83138320"
  },
  {
     "name":"Kolašin Municipality",
     "isoCode":"09",
     "countryCode":"ME",
     "latitude":"42.76019160",
     "longitude":"19.42591140"
  },
  {
     "name":"Žabljak Municipality",
     "isoCode":"21",
     "countryCode":"ME",
     "latitude":"43.15551520",
     "longitude":"19.12260180"
  },
  {
     "name":"Ulcinj Municipality",
     "isoCode":"20",
     "countryCode":"ME",
     "latitude":"41.96527950",
     "longitude":"19.30694320"
  },
  {
     "name":"Kunene Region",
     "isoCode":"KU",
     "countryCode":"NA",
     "latitude":"-19.40863170",
     "longitude":"13.91439900"
  },
  {
     "name":"Kavango West Region",
     "isoCode":"KW",
     "countryCode":"NA",
     "latitude":"-18.27104800",
     "longitude":"18.42760470"
  },
  {
     "name":"Kavango East Region",
     "isoCode":"KE",
     "countryCode":"NA",
     "latitude":"-18.27104800",
     "longitude":"18.42760470"
  },
  {
     "name":"Oshana Region",
     "isoCode":"ON",
     "countryCode":"NA",
     "latitude":"-18.43050640",
     "longitude":"15.68817880"
  },
  {
     "name":"Hardap Region",
     "isoCode":"HA",
     "countryCode":"NA",
     "latitude":"-24.23101340",
     "longitude":"17.66888700"
  },
  {
     "name":"Omusati Region",
     "isoCode":"OS",
     "countryCode":"NA",
     "latitude":"-18.40702940",
     "longitude":"14.84546190"
  },
  {
     "name":"Ohangwena Region",
     "isoCode":"OW",
     "countryCode":"NA",
     "latitude":"-17.59792910",
     "longitude":"16.81783770"
  },
  {
     "name":"Omaheke Region",
     "isoCode":"OH",
     "countryCode":"NA",
     "latitude":"-21.84666510",
     "longitude":"19.18800470"
  },
  {
     "name":"Oshikoto Region",
     "isoCode":"OT",
     "countryCode":"NA",
     "latitude":"-18.41525750",
     "longitude":"16.91225100"
  },
  {
     "name":"Erongo Region",
     "isoCode":"ER",
     "countryCode":"NA",
     "latitude":"-22.25656820",
     "longitude":"15.40680790"
  },
  {
     "name":"Khomas Region",
     "isoCode":"KH",
     "countryCode":"NA",
     "latitude":"-22.63778540",
     "longitude":"17.10119310"
  },
  {
     "name":"Karas Region",
     "isoCode":"KA",
     "countryCode":"NA",
     "latitude":"-26.84296450",
     "longitude":"17.29028390"
  },
  {
     "name":"Otjozondjupa Region",
     "isoCode":"OD",
     "countryCode":"NA",
     "latitude":"-20.54869160",
     "longitude":"17.66888700"
  },
  {
     "name":"Zambezi Region",
     "isoCode":"CA",
     "countryCode":"NA",
     "latitude":"-17.81934190",
     "longitude":"23.95364660"
  },
  {
     "name":"Ashanti Region",
     "isoCode":"AH",
     "countryCode":"GH",
     "latitude":"6.74704360",
     "longitude":"-1.52086240"
  },
  {
     "name":"Western Region",
     "isoCode":"WP",
     "countryCode":"GH",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Eastern Region",
     "isoCode":"EP",
     "countryCode":"GH",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Northern Region",
     "isoCode":"NP",
     "countryCode":"GH",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Central Region",
     "isoCode":"CP",
     "countryCode":"GH",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Brong-Ahafo Region",
     "isoCode":"BA",
     "countryCode":"GH",
     "latitude":"7.95592470",
     "longitude":"-1.67606910"
  },
  {
     "name":"Greater Accra Region",
     "isoCode":"AA",
     "countryCode":"GH",
     "latitude":"5.81428360",
     "longitude":"0.07467670"
  },
  {
     "name":"Upper East Region",
     "isoCode":"UE",
     "countryCode":"GH",
     "latitude":"10.70824990",
     "longitude":"-0.98206680"
  },
  {
     "name":"Volta Region",
     "isoCode":"TV",
     "countryCode":"GH",
     "latitude":"6.57813730",
     "longitude":"0.45023680"
  },
  {
     "name":"Upper West Region",
     "isoCode":"UW",
     "countryCode":"GH",
     "latitude":"10.25297570",
     "longitude":"-2.14502450"
  },
  {
     "name":"San Marino",
     "isoCode":"07",
     "countryCode":"SM",
     "latitude":"43.94236000",
     "longitude":"12.45777700"
  },
  {
     "name":"Acquaviva",
     "isoCode":"01",
     "countryCode":"SM",
     "latitude":"41.86715970",
     "longitude":"14.74694790"
  },
  {
     "name":"Chiesanuova",
     "isoCode":"02",
     "countryCode":"SM",
     "latitude":"45.42261720",
     "longitude":"7.65038540"
  },
  {
     "name":"Borgo Maggiore",
     "isoCode":"06",
     "countryCode":"SM",
     "latitude":"43.95748820",
     "longitude":"12.45525460"
  },
  {
     "name":"Faetano",
     "isoCode":"04",
     "countryCode":"SM",
     "latitude":"43.93489670",
     "longitude":"12.48965540"
  },
  {
     "name":"Montegiardino",
     "isoCode":"08",
     "countryCode":"SM",
     "latitude":"43.90529990",
     "longitude":"12.48105420"
  },
  {
     "name":"Domagnano",
     "isoCode":"03",
     "countryCode":"SM",
     "latitude":"43.95019290",
     "longitude":"12.46815370"
  },
  {
     "name":"Serravalle",
     "isoCode":"09",
     "countryCode":"SM",
     "latitude":"44.72320840",
     "longitude":"8.85740050"
  },
  {
     "name":"Fiorentino",
     "isoCode":"05",
     "countryCode":"SM",
     "latitude":"43.90783370",
     "longitude":"12.45812090"
  },
  {
     "name":"Tillabéri Region",
     "isoCode":"6",
     "countryCode":"NE",
     "latitude":"14.64895250",
     "longitude":"2.14502450"
  },
  {
     "name":"Dosso Region",
     "isoCode":"3",
     "countryCode":"NE",
     "latitude":"13.15139470",
     "longitude":"3.41955270"
  },
  {
     "name":"Zinder Region",
     "isoCode":"7",
     "countryCode":"NE",
     "latitude":"15.17188810",
     "longitude":"10.26001250"
  },
  {
     "name":"Maradi Region",
     "isoCode":"4",
     "countryCode":"NE",
     "latitude":"13.80180740",
     "longitude":"7.43813550"
  },
  {
     "name":"Agadez Region",
     "isoCode":"1",
     "countryCode":"NE",
     "latitude":"20.66707520",
     "longitude":"12.07182810"
  },
  {
     "name":"Diffa Region",
     "isoCode":"2",
     "countryCode":"NE",
     "latitude":"13.67686470",
     "longitude":"12.71351210"
  },
  {
     "name":"Tahoua Region",
     "isoCode":"5",
     "countryCode":"NE",
     "latitude":"16.09025430",
     "longitude":"5.39395510"
  },
  {
     "name":"Mqabba",
     "isoCode":"33",
     "countryCode":"MT",
     "latitude":"35.84441430",
     "longitude":"14.46941860"
  },
  {
     "name":"San Ġwann",
     "isoCode":"49",
     "countryCode":"MT",
     "latitude":"35.90773650",
     "longitude":"14.47524160"
  },
  {
     "name":"Żurrieq",
     "isoCode":"68",
     "countryCode":"MT",
     "latitude":"35.82163060",
     "longitude":"14.48106480"
  },
  {
     "name":"Luqa",
     "isoCode":"25",
     "countryCode":"MT",
     "latitude":"35.85828650",
     "longitude":"14.48688830"
  },
  {
     "name":"Marsaxlokk",
     "isoCode":"28",
     "countryCode":"MT",
     "latitude":"35.84116990",
     "longitude":"14.53930970"
  },
  {
     "name":"Qala",
     "isoCode":"42",
     "countryCode":"MT",
     "latitude":"36.03886280",
     "longitude":"14.31810100"
  },
  {
     "name":"Żebbuġ Malta",
     "isoCode":"66",
     "countryCode":"MT",
     "latitude":"35.87646480",
     "longitude":"14.43908400"
  },
  {
     "name":"Xgħajra",
     "isoCode":"63",
     "countryCode":"MT",
     "latitude":"35.88682820",
     "longitude":"14.54723910"
  },
  {
     "name":"Kirkop",
     "isoCode":"23",
     "countryCode":"MT",
     "latitude":"35.84378620",
     "longitude":"14.48543240"
  },
  {
     "name":"Rabat",
     "isoCode":"46",
     "countryCode":"MT",
     "latitude":"33.97159040",
     "longitude":"-6.84981290"
  },
  {
     "name":"Floriana",
     "isoCode":"09",
     "countryCode":"MT",
     "latitude":"45.49521850",
     "longitude":"-73.71395760"
  },
  {
     "name":"Żebbuġ Gozo",
     "isoCode":"65",
     "countryCode":"MT",
     "latitude":"36.07164030",
     "longitude":"14.24540800"
  },
  {
     "name":"Swieqi",
     "isoCode":"57",
     "countryCode":"MT",
     "latitude":"35.91911820",
     "longitude":"14.46941860"
  },
  {
     "name":"Saint Lawrence",
     "isoCode":"50",
     "countryCode":"MT",
     "latitude":"38.95780560",
     "longitude":"-95.25656890"
  },
  {
     "name":"Birżebbuġa",
     "isoCode":"05",
     "countryCode":"MT",
     "latitude":"35.81359890",
     "longitude":"14.52474630"
  },
  {
     "name":"Mdina",
     "isoCode":"29",
     "countryCode":"MT",
     "latitude":"35.88809300",
     "longitude":"14.40683570"
  },
  {
     "name":"Santa Venera",
     "isoCode":"54",
     "countryCode":"MT",
     "latitude":"35.89022010",
     "longitude":"14.47669740"
  },
  {
     "name":"Kerċem",
     "isoCode":"22",
     "countryCode":"MT",
     "latitude":"36.04479390",
     "longitude":"14.22506050"
  },
  {
     "name":"Għarb",
     "isoCode":"14",
     "countryCode":"MT",
     "latitude":"36.06890900",
     "longitude":"14.20180980"
  },
  {
     "name":"Iklin",
     "isoCode":"19",
     "countryCode":"MT",
     "latitude":"35.90987740",
     "longitude":"14.45777320"
  },
  {
     "name":"Santa Luċija",
     "isoCode":"53",
     "countryCode":"MT",
     "latitude":"35.85614200",
     "longitude":"14.50436000"
  },
  {
     "name":"Valletta",
     "isoCode":"60",
     "countryCode":"MT",
     "latitude":"35.89890850",
     "longitude":"14.51455280"
  },
  {
     "name":"Msida",
     "isoCode":"34",
     "countryCode":"MT",
     "latitude":"35.89563880",
     "longitude":"14.48688830"
  },
  {
     "name":"Birkirkara",
     "isoCode":"04",
     "countryCode":"MT",
     "latitude":"35.89547060",
     "longitude":"14.46650720"
  },
  {
     "name":"Siġġiewi",
     "isoCode":"55",
     "countryCode":"MT",
     "latitude":"35.84637420",
     "longitude":"14.43157460"
  },
  {
     "name":"Kalkara",
     "isoCode":"21",
     "countryCode":"MT",
     "latitude":"35.89142420",
     "longitude":"14.53202780"
  },
  {
     "name":"St. Julian's",
     "isoCode":"48",
     "countryCode":"MT",
     "latitude":"42.21225130",
     "longitude":"-85.89171270"
  },
  {
     "name":"Victoria",
     "isoCode":"45",
     "countryCode":"MT",
     "latitude":"28.80526740",
     "longitude":"-97.00359820"
  },
  {
     "name":"Mellieħa",
     "isoCode":"30",
     "countryCode":"MT",
     "latitude":"35.95235290",
     "longitude":"14.35009750"
  },
  {
     "name":"Tarxien",
     "isoCode":"59",
     "countryCode":"MT",
     "latitude":"35.86725520",
     "longitude":"14.51164050"
  },
  {
     "name":"Sliema",
     "isoCode":"56",
     "countryCode":"MT",
     "latitude":"35.91100810",
     "longitude":"14.50290400"
  },
  {
     "name":"Ħamrun",
     "isoCode":"18",
     "countryCode":"MT",
     "latitude":"35.88612370",
     "longitude":"14.48834420"
  },
  {
     "name":"Għasri",
     "isoCode":"16",
     "countryCode":"MT",
     "latitude":"36.06680750",
     "longitude":"14.21924750"
  },
  {
     "name":"Birgu",
     "isoCode":"03",
     "countryCode":"MT",
     "latitude":"35.88792140",
     "longitude":"14.52256200"
  },
  {
     "name":"Balzan",
     "isoCode":"02",
     "countryCode":"MT",
     "latitude":"35.89574140",
     "longitude":"14.45340650"
  },
  {
     "name":"Mġarr",
     "isoCode":"31",
     "countryCode":"MT",
     "latitude":"35.91893270",
     "longitude":"14.36173430"
  },
  {
     "name":"Attard",
     "isoCode":"01",
     "countryCode":"MT",
     "latitude":"35.89049670",
     "longitude":"14.41993220"
  },
  {
     "name":"Qrendi",
     "isoCode":"44",
     "countryCode":"MT",
     "latitude":"35.83284880",
     "longitude":"14.45486210"
  },
  {
     "name":"Naxxar",
     "isoCode":"38",
     "countryCode":"MT",
     "latitude":"35.93175180",
     "longitude":"14.43157460"
  },
  {
     "name":"Gżira",
     "isoCode":"12",
     "countryCode":"MT",
     "latitude":"35.90589700",
     "longitude":"14.49533380"
  },
  {
     "name":"Xagħra",
     "isoCode":"61",
     "countryCode":"MT",
     "latitude":"36.05084500",
     "longitude":"14.26748200"
  },
  {
     "name":"Paola",
     "isoCode":"39",
     "countryCode":"MT",
     "latitude":"38.57223530",
     "longitude":"-94.87912940"
  },
  {
     "name":"Sannat",
     "isoCode":"52",
     "countryCode":"MT",
     "latitude":"36.01926430",
     "longitude":"14.25994370"
  },
  {
     "name":"Dingli",
     "isoCode":"07",
     "countryCode":"MT",
     "latitude":"35.86273090",
     "longitude":"14.38501070"
  },
  {
     "name":"Gudja",
     "isoCode":"11",
     "countryCode":"MT",
     "latitude":"35.84698030",
     "longitude":"14.50290400"
  },
  {
     "name":"Qormi",
     "isoCode":"43",
     "countryCode":"MT",
     "latitude":"35.87643880",
     "longitude":"14.46941860"
  },
  {
     "name":"Għargħur",
     "isoCode":"15",
     "countryCode":"MT",
     "latitude":"35.92205690",
     "longitude":"14.45631760"
  },
  {
     "name":"Xewkija",
     "isoCode":"62",
     "countryCode":"MT",
     "latitude":"36.02992360",
     "longitude":"14.25994370"
  },
  {
     "name":"Ta' Xbiex",
     "isoCode":"58",
     "countryCode":"MT",
     "latitude":"35.89914480",
     "longitude":"14.49635190"
  },
  {
     "name":"Żabbar",
     "isoCode":"64",
     "countryCode":"MT",
     "latitude":"35.87247150",
     "longitude":"14.54513540"
  },
  {
     "name":"Għaxaq",
     "isoCode":"17",
     "countryCode":"MT",
     "latitude":"35.84403590",
     "longitude":"14.51600900"
  },
  {
     "name":"Pembroke",
     "isoCode":"40",
     "countryCode":"MT",
     "latitude":"34.68016260",
     "longitude":"-79.19503730"
  },
  {
     "name":"Lija",
     "isoCode":"24",
     "countryCode":"MT",
     "latitude":"49.18007600",
     "longitude":"-123.10331700"
  },
  {
     "name":"Pietà",
     "isoCode":"41",
     "countryCode":"MT",
     "latitude":"42.21862000",
     "longitude":"-83.73464700"
  },
  {
     "name":"Marsa",
     "isoCode":"26",
     "countryCode":"MT",
     "latitude":"34.03195870",
     "longitude":"-118.44555350"
  },
  {
     "name":"Fgura",
     "isoCode":"08",
     "countryCode":"MT",
     "latitude":"35.87382690",
     "longitude":"14.52329010"
  },
  {
     "name":"Għajnsielem",
     "isoCode":"13",
     "countryCode":"MT",
     "latitude":"36.02479660",
     "longitude":"14.28029610"
  },
  {
     "name":"Mtarfa",
     "isoCode":"35",
     "countryCode":"MT",
     "latitude":"35.88951250",
     "longitude":"14.39519530"
  },
  {
     "name":"Munxar",
     "isoCode":"36",
     "countryCode":"MT",
     "latitude":"36.02880580",
     "longitude":"14.22506050"
  },
  {
     "name":"Nadur",
     "isoCode":"37",
     "countryCode":"MT",
     "latitude":"36.04470190",
     "longitude":"14.29192730"
  },
  {
     "name":"Fontana",
     "isoCode":"10",
     "countryCode":"MT",
     "latitude":"34.09223350",
     "longitude":"-117.43504800"
  },
  {
     "name":"Żejtun",
     "isoCode":"67",
     "countryCode":"MT",
     "latitude":"35.85487140",
     "longitude":"14.53639690"
  },
  {
     "name":"Senglea",
     "isoCode":"20",
     "countryCode":"MT",
     "latitude":"35.88730410",
     "longitude":"14.51673710"
  },
  {
     "name":"Marsaskala",
     "isoCode":"27",
     "countryCode":"MT",
     "latitude":"35.86036400",
     "longitude":"14.55678760"
  },
  {
     "name":"Cospicua",
     "isoCode":"06",
     "countryCode":"MT",
     "latitude":"35.88067530",
     "longitude":"14.52183380"
  },
  {
     "name":"St. Paul's Bay",
     "isoCode":"51",
     "countryCode":"MT",
     "latitude":"35.93601700",
     "longitude":"14.39665030"
  },
  {
     "name":"Mosta",
     "isoCode":"32",
     "countryCode":"MT",
     "latitude":"35.91415040",
     "longitude":"14.42284270"
  },
  {
     "name":"Mangystau Region",
     "isoCode":"MAN",
     "countryCode":"KZ",
     "latitude":"44.59080200",
     "longitude":"53.84995080"
  },
  {
     "name":"Kyzylorda Region",
     "isoCode":"KZY",
     "countryCode":"KZ",
     "latitude":"44.69226130",
     "longitude":"62.65718850"
  },
  {
     "name":"Almaty Region",
     "isoCode":"ALM",
     "countryCode":"KZ",
     "latitude":"45.01192270",
     "longitude":"78.42293920"
  },
  {
     "name":"North Kazakhstan Region",
     "isoCode":"SEV",
     "countryCode":"KZ",
     "latitude":"54.16220660",
     "longitude":"69.93870710"
  },
  {
     "name":"Akmola Region",
     "isoCode":"AKM",
     "countryCode":"KZ",
     "latitude":"51.91653200",
     "longitude":"69.41104940"
  },
  {
     "name":"Pavlodar Region",
     "isoCode":"PAV",
     "countryCode":"KZ",
     "latitude":"52.28784440",
     "longitude":"76.97334530"
  },
  {
     "name":"Jambyl Region",
     "isoCode":"ZHA",
     "countryCode":"KZ",
     "latitude":"44.22203080",
     "longitude":"72.36579670"
  },
  {
     "name":"West Kazakhstan Province",
     "isoCode":"ZAP",
     "countryCode":"KZ",
     "latitude":"49.56797270",
     "longitude":"50.80666160"
  },
  {
     "name":"Turkestan Region",
     "isoCode":"YUZ",
     "countryCode":"KZ",
     "latitude":"43.36669580",
     "longitude":"68.40944050"
  },
  {
     "name":"Karaganda Region",
     "isoCode":"KAR",
     "countryCode":"KZ",
     "latitude":"47.90221820",
     "longitude":"71.77068070"
  },
  {
     "name":"Aktobe Region",
     "isoCode":"AKT",
     "countryCode":"KZ",
     "latitude":"48.77970780",
     "longitude":"57.99743780"
  },
  {
     "name":"Almaty",
     "isoCode":"ALA",
     "countryCode":"KZ",
     "latitude":"43.22201460",
     "longitude":"76.85124850"
  },
  {
     "name":"Atyrau Region",
     "isoCode":"ATY",
     "countryCode":"KZ",
     "latitude":"47.10761880",
     "longitude":"51.91413300"
  },
  {
     "name":"East Kazakhstan Region",
     "isoCode":"VOS",
     "countryCode":"KZ",
     "latitude":"48.70626870",
     "longitude":"80.79225340"
  },
  {
     "name":"Baikonur",
     "isoCode":"BAY",
     "countryCode":"KZ",
     "latitude":"45.96458510",
     "longitude":"63.30524280"
  },
  {
     "name":"Nur-Sultan",
     "isoCode":"AST",
     "countryCode":"KZ",
     "latitude":"51.16052270",
     "longitude":"71.47035580"
  },
  {
     "name":"Kostanay Region",
     "isoCode":"KUS",
     "countryCode":"KZ",
     "latitude":"51.50770960",
     "longitude":"64.04790730"
  },
  {
     "name":"Kakamega County",
     "isoCode":"11",
     "countryCode":"KE",
     "latitude":"0.30789400",
     "longitude":"34.77407930"
  },
  {
     "name":"Kisii County",
     "isoCode":"16",
     "countryCode":"KE",
     "latitude":"-0.67733400",
     "longitude":"34.77960300"
  },
  {
     "name":"Central Province",
     "isoCode":"200",
     "countryCode":"KE",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Busia County",
     "isoCode":"04",
     "countryCode":"KE",
     "latitude":"0.43465060",
     "longitude":"34.24215970"
  },
  {
     "name":"North Eastern Province",
     "isoCode":"500",
     "countryCode":"KE",
     "latitude":"7.78530510",
     "longitude":"81.42789840"
  },
  {
     "name":"Embu County",
     "isoCode":"06",
     "countryCode":"KE",
     "latitude":"-0.65604770",
     "longitude":"37.72376780"
  },
  {
     "name":"Laikipia County",
     "isoCode":"20",
     "countryCode":"KE",
     "latitude":"0.36060630",
     "longitude":"36.78195050"
  },
  {
     "name":"Nandi District",
     "isoCode":"32",
     "countryCode":"KE",
     "latitude":"0.18358670",
     "longitude":"35.12687810"
  },
  {
     "name":"Lamu County",
     "isoCode":"21",
     "countryCode":"KE",
     "latitude":"-2.23550580",
     "longitude":"40.47200040"
  },
  {
     "name":"Kirinyaga County",
     "isoCode":"15",
     "countryCode":"KE",
     "latitude":"-0.65905650",
     "longitude":"37.38272340"
  },
  {
     "name":"Bungoma County",
     "isoCode":"03",
     "countryCode":"KE",
     "latitude":"0.56952520",
     "longitude":"34.55837660"
  },
  {
     "name":"Uasin Gishu District",
     "isoCode":"44",
     "countryCode":"KE",
     "latitude":"0.55276380",
     "longitude":"35.30272260"
  },
  {
     "name":"Isiolo County",
     "isoCode":"09",
     "countryCode":"KE",
     "latitude":"0.35243520",
     "longitude":"38.48499230"
  },
  {
     "name":"Kisumu County",
     "isoCode":"17",
     "countryCode":"KE",
     "latitude":"-0.09170160",
     "longitude":"34.76795680"
  },
  {
     "name":"Coast Province",
     "isoCode":"300",
     "countryCode":"KE",
     "latitude":"39.13733050",
     "longitude":"-76.72941440"
  },
  {
     "name":"Kwale County",
     "isoCode":"19",
     "countryCode":"KE",
     "latitude":"-4.18161150",
     "longitude":"39.46056120"
  },
  {
     "name":"Kilifi County",
     "isoCode":"14",
     "countryCode":"KE",
     "latitude":"-3.51065080",
     "longitude":"39.90932690"
  },
  {
     "name":"Narok County",
     "isoCode":"33",
     "countryCode":"KE",
     "latitude":"-1.10411100",
     "longitude":"36.08934060"
  },
  {
     "name":"Taita–Taveta County",
     "isoCode":"39",
     "countryCode":"KE",
     "latitude":"-3.31606870",
     "longitude":"38.48499230"
  },
  {
     "name":"Western Province",
     "isoCode":"800",
     "countryCode":"KE",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Muranga County",
     "isoCode":"29",
     "countryCode":"KE",
     "latitude":"-0.78392810",
     "longitude":"37.04003390"
  },
  {
     "name":"Rift Valley Province",
     "isoCode":"700",
     "countryCode":"KE",
     "latitude":"11.40987370",
     "longitude":"41.28085770"
  },
  {
     "name":"Nyeri County",
     "isoCode":"36",
     "countryCode":"KE",
     "latitude":"-0.41969150",
     "longitude":"37.04003390"
  },
  {
     "name":"Baringo County",
     "isoCode":"01",
     "countryCode":"KE",
     "latitude":"0.85549880",
     "longitude":"36.08934060"
  },
  {
     "name":"Wajir County",
     "isoCode":"46",
     "countryCode":"KE",
     "latitude":"1.63604750",
     "longitude":"40.30886260"
  },
  {
     "name":"Trans-Nzoia District",
     "isoCode":"42",
     "countryCode":"KE",
     "latitude":"1.05666670",
     "longitude":"34.95066250"
  },
  {
     "name":"Machakos County",
     "isoCode":"22",
     "countryCode":"KE",
     "latitude":"-1.51768370",
     "longitude":"37.26341460"
  },
  {
     "name":"Tharaka Nithi County",
     "isoCode":"41",
     "countryCode":"KE",
     "latitude":"-0.29648510",
     "longitude":"37.72376780"
  },
  {
     "name":"Siaya County",
     "isoCode":"38",
     "countryCode":"KE",
     "latitude":"-0.06173280",
     "longitude":"34.24215970"
  },
  {
     "name":"Mandera County",
     "isoCode":"24",
     "countryCode":"KE",
     "latitude":"3.57379910",
     "longitude":"40.95868800"
  },
  {
     "name":"Makueni County",
     "isoCode":"23",
     "countryCode":"KE",
     "latitude":"-2.25587340",
     "longitude":"37.89366630"
  },
  {
     "name":"Eastern Province",
     "isoCode":"400",
     "countryCode":"KE",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Migori County",
     "isoCode":"27",
     "countryCode":"KE",
     "latitude":"-0.93657020",
     "longitude":"34.41982430"
  },
  {
     "name":"Nairobi",
     "isoCode":"110",
     "countryCode":"KE",
     "latitude":"-1.29206590",
     "longitude":"36.82194620"
  },
  {
     "name":"Nyandarua County",
     "isoCode":"35",
     "countryCode":"KE",
     "latitude":"-0.18038550",
     "longitude":"36.52296410"
  },
  {
     "name":"Kericho County",
     "isoCode":"12",
     "countryCode":"KE",
     "latitude":"-0.18279130",
     "longitude":"35.47819260"
  },
  {
     "name":"Marsabit County",
     "isoCode":"25",
     "countryCode":"KE",
     "latitude":"2.44264030",
     "longitude":"37.97845850"
  },
  {
     "name":"Homa Bay County",
     "isoCode":"08",
     "countryCode":"KE",
     "latitude":"-0.62206550",
     "longitude":"34.33103640"
  },
  {
     "name":"Garissa County",
     "isoCode":"07",
     "countryCode":"KE",
     "latitude":"-0.45322930",
     "longitude":"39.64609880"
  },
  {
     "name":"Kajiado County",
     "isoCode":"10",
     "countryCode":"KE",
     "latitude":"-2.09807510",
     "longitude":"36.78195050"
  },
  {
     "name":"Meru County",
     "isoCode":"26",
     "countryCode":"KE",
     "latitude":"0.35571740",
     "longitude":"37.80876930"
  },
  {
     "name":"Kiambu County",
     "isoCode":"13",
     "countryCode":"KE",
     "latitude":"-1.03137010",
     "longitude":"36.86807910"
  },
  {
     "name":"Mombasa County",
     "isoCode":"28",
     "countryCode":"KE",
     "latitude":"-3.97682910",
     "longitude":"39.71371810"
  },
  {
     "name":"Elgeyo-Marakwet County",
     "isoCode":"05",
     "countryCode":"KE",
     "latitude":"1.04982370",
     "longitude":"35.47819260"
  },
  {
     "name":"Vihiga District",
     "isoCode":"45",
     "countryCode":"KE",
     "latitude":"0.07675530",
     "longitude":"34.70776650"
  },
  {
     "name":"Nakuru District",
     "isoCode":"31",
     "countryCode":"KE",
     "latitude":"-0.30309880",
     "longitude":"36.08002600"
  },
  {
     "name":"Nyanza Province",
     "isoCode":"600",
     "countryCode":"KE",
     "latitude":"-0.08898940",
     "longitude":"34.77179120"
  },
  {
     "name":"Tana River County",
     "isoCode":"40",
     "countryCode":"KE",
     "latitude":"-1.65184680",
     "longitude":"39.65181650"
  },
  {
     "name":"Turkana County",
     "isoCode":"43",
     "countryCode":"KE",
     "latitude":"3.31224770",
     "longitude":"35.56578620"
  },
  {
     "name":"Samburu County",
     "isoCode":"37",
     "countryCode":"KE",
     "latitude":"1.21545060",
     "longitude":"36.95410700"
  },
  {
     "name":"West Pokot County",
     "isoCode":"47",
     "countryCode":"KE",
     "latitude":"1.62100760",
     "longitude":"35.39050460"
  },
  {
     "name":"Nyamira District",
     "isoCode":"34",
     "countryCode":"KE",
     "latitude":"-0.56694050",
     "longitude":"34.93412340"
  },
  {
     "name":"Bomet County",
     "isoCode":"02",
     "countryCode":"KE",
     "latitude":"-0.80150090",
     "longitude":"35.30272260"
  },
  {
     "name":"Kitui County",
     "isoCode":"18",
     "countryCode":"KE",
     "latitude":"-1.68328220",
     "longitude":"38.31657250"
  },
  {
     "name":"Bié Province",
     "isoCode":"BIE",
     "countryCode":"AO",
     "latitude":"-12.57279070",
     "longitude":"17.66888700"
  },
  {
     "name":"Huambo Province",
     "isoCode":"HUA",
     "countryCode":"AO",
     "latitude":"-12.52682210",
     "longitude":"15.59433880"
  },
  {
     "name":"Zaire Province",
     "isoCode":"ZAI",
     "countryCode":"AO",
     "latitude":"-6.57334580",
     "longitude":"13.17403480"
  },
  {
     "name":"Cunene Province",
     "isoCode":"CNN",
     "countryCode":"AO",
     "latitude":"-16.28022210",
     "longitude":"16.15809370"
  },
  {
     "name":"Cuanza Sul",
     "isoCode":"CUS",
     "countryCode":"AO",
     "latitude":"-10.59519100",
     "longitude":"15.40680790"
  },
  {
     "name":"Cuanza Norte Province",
     "isoCode":"CNO",
     "countryCode":"AO",
     "latitude":"-9.23985130",
     "longitude":"14.65878210"
  },
  {
     "name":"Benguela Province",
     "isoCode":"BGU",
     "countryCode":"AO",
     "latitude":"-12.80037440",
     "longitude":"13.91439900"
  },
  {
     "name":"Moxico Province",
     "isoCode":"MOX",
     "countryCode":"AO",
     "latitude":"-13.42935790",
     "longitude":"20.33088140"
  },
  {
     "name":"Lunda Sul Province",
     "isoCode":"LSU",
     "countryCode":"AO",
     "latitude":"-10.28665780",
     "longitude":"20.71224650"
  },
  {
     "name":"Bengo Province",
     "isoCode":"BGO",
     "countryCode":"AO",
     "latitude":"-9.10422570",
     "longitude":"13.72891670"
  },
  {
     "name":"Luanda Province",
     "isoCode":"LUA",
     "countryCode":"AO",
     "latitude":"-9.03508800",
     "longitude":"13.26634790"
  },
  {
     "name":"Lunda Norte Province",
     "isoCode":"LNO",
     "countryCode":"AO",
     "latitude":"-8.35250220",
     "longitude":"19.18800470"
  },
  {
     "name":"Uíge Province",
     "isoCode":"UIG",
     "countryCode":"AO",
     "latitude":"-7.17367320",
     "longitude":"15.40680790"
  },
  {
     "name":"Huíla Province",
     "isoCode":"HUI",
     "countryCode":"AO",
     "latitude":"-14.92805530",
     "longitude":"14.65878210"
  },
  {
     "name":"Cuando Cubango Province",
     "isoCode":"CCU",
     "countryCode":"AO",
     "latitude":"-16.41808240",
     "longitude":"18.80761950"
  },
  {
     "name":"Malanje Province",
     "isoCode":"MAL",
     "countryCode":"AO",
     "latitude":"-9.82511830",
     "longitude":"16.91225100"
  },
  {
     "name":"Cabinda Province",
     "isoCode":"CAB",
     "countryCode":"AO",
     "latitude":"-5.02487490",
     "longitude":"12.34638750"
  },
  {
     "name":"Gasa District",
     "isoCode":"GA",
     "countryCode":"BT",
     "latitude":"28.01858860",
     "longitude":"89.92532330"
  },
  {
     "name":"Tsirang District",
     "isoCode":"21",
     "countryCode":"BT",
     "latitude":"27.03220700",
     "longitude":"90.18696440"
  },
  {
     "name":"Wangdue Phodrang District",
     "isoCode":"24",
     "countryCode":"BT",
     "latitude":"27.45260460",
     "longitude":"90.06749280"
  },
  {
     "name":"Haa District",
     "isoCode":"13",
     "countryCode":"BT",
     "latitude":"27.26516690",
     "longitude":"89.17059980"
  },
  {
     "name":"Zhemgang District",
     "isoCode":"34",
     "countryCode":"BT",
     "latitude":"27.07697500",
     "longitude":"90.82940020"
  },
  {
     "name":"Lhuntse District",
     "isoCode":"44",
     "countryCode":"BT",
     "latitude":"27.82649890",
     "longitude":"91.13530200"
  },
  {
     "name":"Punakha District",
     "isoCode":"23",
     "countryCode":"BT",
     "latitude":"27.69037160",
     "longitude":"89.88793040"
  },
  {
     "name":"Trashigang District",
     "isoCode":"41",
     "countryCode":"BT",
     "latitude":"27.25667950",
     "longitude":"91.75388170"
  },
  {
     "name":"Paro District",
     "isoCode":"11",
     "countryCode":"BT",
     "latitude":"27.42859490",
     "longitude":"89.41665160"
  },
  {
     "name":"Dagana District",
     "isoCode":"22",
     "countryCode":"BT",
     "latitude":"27.03228610",
     "longitude":"89.88793040"
  },
  {
     "name":"Chukha District",
     "isoCode":"12",
     "countryCode":"BT",
     "latitude":"27.07843040",
     "longitude":"89.47421770"
  },
  {
     "name":"Bumthang District",
     "isoCode":"33",
     "countryCode":"BT",
     "latitude":"27.64183900",
     "longitude":"90.67730460"
  },
  {
     "name":"Thimphu District",
     "isoCode":"15",
     "countryCode":"BT",
     "latitude":"27.47122160",
     "longitude":"89.63390410"
  },
  {
     "name":"Mongar District",
     "isoCode":"42",
     "countryCode":"BT",
     "latitude":"27.26170590",
     "longitude":"91.28910360"
  },
  {
     "name":"Samdrup Jongkhar District",
     "isoCode":"45",
     "countryCode":"BT",
     "latitude":"26.80356820",
     "longitude":"91.50392070"
  },
  {
     "name":"Pemagatshel District",
     "isoCode":"43",
     "countryCode":"BT",
     "latitude":"27.00238200",
     "longitude":"91.34692470"
  },
  {
     "name":"Trongsa District",
     "isoCode":"32",
     "countryCode":"BT",
     "latitude":"27.50022690",
     "longitude":"90.50806340"
  },
  {
     "name":"Samtse District",
     "isoCode":"14",
     "countryCode":"BT",
     "latitude":"27.02918320",
     "longitude":"89.05615320"
  },
  {
     "name":"Sarpang District",
     "isoCode":"31",
     "countryCode":"BT",
     "latitude":"26.93730410",
     "longitude":"90.48799160"
  },
  {
     "name":"Tombouctou Region",
     "isoCode":"6",
     "countryCode":"ML",
     "latitude":"21.05267060",
     "longitude":"-3.74350900"
  },
  {
     "name":"Ségou Region",
     "isoCode":"4",
     "countryCode":"ML",
     "latitude":"13.83944560",
     "longitude":"-6.06791940"
  },
  {
     "name":"Koulikoro Region",
     "isoCode":"2",
     "countryCode":"ML",
     "latitude":"13.80180740",
     "longitude":"-7.43813550"
  },
  {
     "name":"Ménaka Region",
     "isoCode":"9",
     "countryCode":"ML",
     "latitude":"15.91564210",
     "longitude":"2.39617400"
  },
  {
     "name":"Kayes Region",
     "isoCode":"1",
     "countryCode":"ML",
     "latitude":"14.08183080",
     "longitude":"-9.90181310"
  },
  {
     "name":"Bamako",
     "isoCode":"BKO",
     "countryCode":"ML",
     "latitude":"12.63923160",
     "longitude":"-8.00288920"
  },
  {
     "name":"Sikasso Region",
     "isoCode":"3",
     "countryCode":"ML",
     "latitude":"10.89051860",
     "longitude":"-7.43813550"
  },
  {
     "name":"Mopti Region",
     "isoCode":"5",
     "countryCode":"ML",
     "latitude":"14.63380390",
     "longitude":"-3.41955270"
  },
  {
     "name":"Taoudénit Region",
     "isoCode":"10",
     "countryCode":"ML",
     "latitude":"22.67641320",
     "longitude":"-3.97891430"
  },
  {
     "name":"Kidal Region",
     "isoCode":"8",
     "countryCode":"ML",
     "latitude":"18.79868320",
     "longitude":"1.83183340"
  },
  {
     "name":"Gao Region",
     "isoCode":"7",
     "countryCode":"ML",
     "latitude":"16.90663320",
     "longitude":"1.52086240"
  },
  {
     "name":"Southern Province",
     "isoCode":"05",
     "countryCode":"RW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Western Province",
     "isoCode":"04",
     "countryCode":"RW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Eastern Province",
     "isoCode":"02",
     "countryCode":"RW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Kigali district",
     "isoCode":"01",
     "countryCode":"RW",
     "latitude":"-1.94407270",
     "longitude":"30.06188510"
  },
  {
     "name":"Northern Province",
     "isoCode":"03",
     "countryCode":"RW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Belize District",
     "isoCode":"BZ",
     "countryCode":"BZ",
     "latitude":"17.56776790",
     "longitude":"-88.40160410"
  },
  {
     "name":"Stann Creek District",
     "isoCode":"SC",
     "countryCode":"BZ",
     "latitude":"16.81166310",
     "longitude":"-88.40160410"
  },
  {
     "name":"Corozal District",
     "isoCode":"CZL",
     "countryCode":"BZ",
     "latitude":"18.13492380",
     "longitude":"-88.24611830"
  },
  {
     "name":"Toledo District",
     "isoCode":"TOL",
     "countryCode":"BZ",
     "latitude":"16.24919230",
     "longitude":"-88.86469800"
  },
  {
     "name":"Orange Walk District",
     "isoCode":"OW",
     "countryCode":"BZ",
     "latitude":"17.76035300",
     "longitude":"-88.86469800"
  },
  {
     "name":"Cayo District",
     "isoCode":"CY",
     "countryCode":"BZ",
     "latitude":"17.09844450",
     "longitude":"-88.94138650"
  },
  {
     "name":"Príncipe Province",
     "isoCode":"P",
     "countryCode":"ST",
     "latitude":"1.61393810",
     "longitude":"7.40569280"
  },
  {
     "name":"São Tomé Province",
     "isoCode":"S",
     "countryCode":"ST",
     "latitude":"0.33019240",
     "longitude":"6.73334300"
  },
  {
     "name":"Havana Province",
     "isoCode":"03",
     "countryCode":"CU",
     "latitude":"23.05406980",
     "longitude":"-82.34518900"
  },
  {
     "name":"Santiago de Cuba Province",
     "isoCode":"13",
     "countryCode":"CU",
     "latitude":"20.23976820",
     "longitude":"-75.99276520"
  },
  {
     "name":"Sancti Spíritus Province",
     "isoCode":"07",
     "countryCode":"CU",
     "latitude":"21.99382140",
     "longitude":"-79.47038850"
  },
  {
     "name":"Granma Province",
     "isoCode":"12",
     "countryCode":"CU",
     "latitude":"20.38449020",
     "longitude":"-76.64127120"
  },
  {
     "name":"Mayabeque Province",
     "isoCode":"16",
     "countryCode":"CU",
     "latitude":"22.89265290",
     "longitude":"-81.95348150"
  },
  {
     "name":"Pinar del Río Province",
     "isoCode":"01",
     "countryCode":"CU",
     "latitude":"22.40762560",
     "longitude":"-83.84730150"
  },
  {
     "name":"Isla de la Juventud",
     "isoCode":"99",
     "countryCode":"CU",
     "latitude":"21.70847370",
     "longitude":"-82.82202320"
  },
  {
     "name":"Holguín Province",
     "isoCode":"11",
     "countryCode":"CU",
     "latitude":"20.78378930",
     "longitude":"-75.80690820"
  },
  {
     "name":"Villa Clara Province",
     "isoCode":"05",
     "countryCode":"CU",
     "latitude":"22.49372040",
     "longitude":"-79.91927020"
  },
  {
     "name":"Las Tunas Province",
     "isoCode":"10",
     "countryCode":"CU",
     "latitude":"21.06051620",
     "longitude":"-76.91820970"
  },
  {
     "name":"Ciego de Ávila Province",
     "isoCode":"08",
     "countryCode":"CU",
     "latitude":"21.93295150",
     "longitude":"-78.56608520"
  },
  {
     "name":"Artemisa Province",
     "isoCode":"15",
     "countryCode":"CU",
     "latitude":"22.75229030",
     "longitude":"-82.99316070"
  },
  {
     "name":"Matanzas Province",
     "isoCode":"04",
     "countryCode":"CU",
     "latitude":"22.57671230",
     "longitude":"-81.33994140"
  },
  {
     "name":"Guantánamo Province",
     "isoCode":"14",
     "countryCode":"CU",
     "latitude":"20.14559170",
     "longitude":"-74.87410450"
  },
  {
     "name":"Camagüey Province",
     "isoCode":"09",
     "countryCode":"CU",
     "latitude":"21.21672470",
     "longitude":"-77.74520810"
  },
  {
     "name":"Cienfuegos Province",
     "isoCode":"06",
     "countryCode":"CU",
     "latitude":"22.23797830",
     "longitude":"-80.36586500"
  },
  {
     "name":"Jigawa State",
     "isoCode":"JI",
     "countryCode":"NG",
     "latitude":"12.22801200",
     "longitude":"9.56158670"
  },
  {
     "name":"Enugu State",
     "isoCode":"EN",
     "countryCode":"NG",
     "latitude":"6.53635300",
     "longitude":"7.43561940"
  },
  {
     "name":"Kebbi State",
     "isoCode":"KE",
     "countryCode":"NG",
     "latitude":"11.49420030",
     "longitude":"4.23333550"
  },
  {
     "name":"Benue State",
     "isoCode":"BE",
     "countryCode":"NG",
     "latitude":"7.33690240",
     "longitude":"8.74036870"
  },
  {
     "name":"Sokoto State",
     "isoCode":"SO",
     "countryCode":"NG",
     "latitude":"13.05331430",
     "longitude":"5.32227220"
  },
  {
     "name":"Federal Capital Territory",
     "isoCode":"FC",
     "countryCode":"NG",
     "latitude":"8.89406910",
     "longitude":"7.18604020"
  },
  {
     "name":"Kaduna State",
     "isoCode":"KD",
     "countryCode":"NG",
     "latitude":"10.37640060",
     "longitude":"7.70945370"
  },
  {
     "name":"Kwara State",
     "isoCode":"KW",
     "countryCode":"NG",
     "latitude":"8.96689610",
     "longitude":"4.38740510"
  },
  {
     "name":"Oyo State",
     "isoCode":"OY",
     "countryCode":"NG",
     "latitude":"8.15738090",
     "longitude":"3.61465340"
  },
  {
     "name":"Yobe State",
     "isoCode":"YO",
     "countryCode":"NG",
     "latitude":"12.29387600",
     "longitude":"11.43904110"
  },
  {
     "name":"Kogi State",
     "isoCode":"KO",
     "countryCode":"NG",
     "latitude":"7.73373250",
     "longitude":"6.69058360"
  },
  {
     "name":"Zamfara State",
     "isoCode":"ZA",
     "countryCode":"NG",
     "latitude":"12.12218050",
     "longitude":"6.22358190"
  },
  {
     "name":"Kano State",
     "isoCode":"KN",
     "countryCode":"NG",
     "latitude":"11.74706980",
     "longitude":"8.52471070"
  },
  {
     "name":"Nasarawa State",
     "isoCode":"NA",
     "countryCode":"NG",
     "latitude":"8.49979080",
     "longitude":"8.19969370"
  },
  {
     "name":"Plateau State",
     "isoCode":"PL",
     "countryCode":"NG",
     "latitude":"9.21820930",
     "longitude":"9.51794880"
  },
  {
     "name":"Abia State",
     "isoCode":"AB",
     "countryCode":"NG",
     "latitude":"5.45273540",
     "longitude":"7.52484140"
  },
  {
     "name":"Akwa Ibom State",
     "isoCode":"AK",
     "countryCode":"NG",
     "latitude":"4.90573710",
     "longitude":"7.85366750"
  },
  {
     "name":"Bayelsa State",
     "isoCode":"BY",
     "countryCode":"NG",
     "latitude":"4.77190710",
     "longitude":"6.06985260"
  },
  {
     "name":"Lagos",
     "isoCode":"LA",
     "countryCode":"NG",
     "latitude":"6.52437930",
     "longitude":"3.37920570"
  },
  {
     "name":"Borno State",
     "isoCode":"BO",
     "countryCode":"NG",
     "latitude":"11.88463560",
     "longitude":"13.15196650"
  },
  {
     "name":"Imo State",
     "isoCode":"IM",
     "countryCode":"NG",
     "latitude":"5.57201220",
     "longitude":"7.05882190"
  },
  {
     "name":"Ekiti State",
     "isoCode":"EK",
     "countryCode":"NG",
     "latitude":"7.71898620",
     "longitude":"5.31095050"
  },
  {
     "name":"Gombe State",
     "isoCode":"GO",
     "countryCode":"NG",
     "latitude":"10.36377950",
     "longitude":"11.19275870"
  },
  {
     "name":"Ebonyi State",
     "isoCode":"EB",
     "countryCode":"NG",
     "latitude":"6.26492320",
     "longitude":"8.01373020"
  },
  {
     "name":"Bauchi State",
     "isoCode":"BA",
     "countryCode":"NG",
     "latitude":"10.77606240",
     "longitude":"9.99919430"
  },
  {
     "name":"Katsina State",
     "isoCode":"KT",
     "countryCode":"NG",
     "latitude":"12.37967070",
     "longitude":"7.63057480"
  },
  {
     "name":"Cross River State",
     "isoCode":"CR",
     "countryCode":"NG",
     "latitude":"5.87017240",
     "longitude":"8.59880140"
  },
  {
     "name":"Anambra State",
     "isoCode":"AN",
     "countryCode":"NG",
     "latitude":"6.22089970",
     "longitude":"6.93695590"
  },
  {
     "name":"Delta State",
     "isoCode":"DE",
     "countryCode":"NG",
     "latitude":"33.74537840",
     "longitude":"-90.73545080"
  },
  {
     "name":"Niger State",
     "isoCode":"NI",
     "countryCode":"NG",
     "latitude":"9.93092240",
     "longitude":"5.59832100"
  },
  {
     "name":"Edo State",
     "isoCode":"ED",
     "countryCode":"NG",
     "latitude":"6.63418310",
     "longitude":"5.93040560"
  },
  {
     "name":"Taraba State",
     "isoCode":"TA",
     "countryCode":"NG",
     "latitude":"7.99936160",
     "longitude":"10.77398630"
  },
  {
     "name":"Adamawa State",
     "isoCode":"AD",
     "countryCode":"NG",
     "latitude":"9.32647510",
     "longitude":"12.39838530"
  },
  {
     "name":"Ondo State",
     "isoCode":"ON",
     "countryCode":"NG",
     "latitude":"6.91486820",
     "longitude":"5.14781440"
  },
  {
     "name":"Osun State",
     "isoCode":"OS",
     "countryCode":"NG",
     "latitude":"7.56289640",
     "longitude":"4.51995930"
  },
  {
     "name":"Ogun State",
     "isoCode":"OG",
     "countryCode":"NG",
     "latitude":"6.99797470",
     "longitude":"3.47373780"
  },
  {
     "name":"Rukungiri District",
     "isoCode":"412",
     "countryCode":"UG",
     "latitude":"-0.75184900",
     "longitude":"29.92779470"
  },
  {
     "name":"Kyankwanzi District",
     "isoCode":"123",
     "countryCode":"UG",
     "latitude":"1.09660370",
     "longitude":"31.71954590"
  },
  {
     "name":"Kabarole District",
     "isoCode":"405",
     "countryCode":"UG",
     "latitude":"0.58507910",
     "longitude":"30.25127280"
  },
  {
     "name":"Mpigi District",
     "isoCode":"106",
     "countryCode":"UG",
     "latitude":"0.22735280",
     "longitude":"32.32492360"
  },
  {
     "name":"Apac District",
     "isoCode":"302",
     "countryCode":"UG",
     "latitude":"1.87302630",
     "longitude":"32.62774550"
  },
  {
     "name":"Abim District",
     "isoCode":"314",
     "countryCode":"UG",
     "latitude":"2.70669800",
     "longitude":"33.65953370"
  },
  {
     "name":"Yumbe District",
     "isoCode":"313",
     "countryCode":"UG",
     "latitude":"3.46980230",
     "longitude":"31.24832910"
  },
  {
     "name":"Rukiga District",
     "isoCode":"431",
     "countryCode":"UG",
     "latitude":"-1.13263370",
     "longitude":"30.04341200"
  },
  {
     "name":"Northern Region",
     "isoCode":"N",
     "countryCode":"UG",
     "latitude":"9.54392690",
     "longitude":"-0.90566230"
  },
  {
     "name":"Serere District",
     "isoCode":"232",
     "countryCode":"UG",
     "latitude":"1.49940330",
     "longitude":"33.54900780"
  },
  {
     "name":"Kamuli District",
     "isoCode":"205",
     "countryCode":"UG",
     "latitude":"0.91871070",
     "longitude":"33.12390490"
  },
  {
     "name":"Amuru District",
     "isoCode":"316",
     "countryCode":"UG",
     "latitude":"2.96678780",
     "longitude":"32.08374450"
  },
  {
     "name":"Kaberamaido District",
     "isoCode":"213",
     "countryCode":"UG",
     "latitude":"1.69633220",
     "longitude":"33.21385100"
  },
  {
     "name":"Namutumba District",
     "isoCode":"224",
     "countryCode":"UG",
     "latitude":"0.84926100",
     "longitude":"33.66233010"
  },
  {
     "name":"Kibuku District",
     "isoCode":"227",
     "countryCode":"UG",
     "latitude":"1.04528740",
     "longitude":"33.79925360"
  },
  {
     "name":"Ibanda District",
     "isoCode":"417",
     "countryCode":"UG",
     "latitude":"-0.09648900",
     "longitude":"30.57395790"
  },
  {
     "name":"Iganga District",
     "isoCode":"203",
     "countryCode":"UG",
     "latitude":"0.66001370",
     "longitude":"33.48319060"
  },
  {
     "name":"Dokolo District",
     "isoCode":"317",
     "countryCode":"UG",
     "latitude":"1.96364210",
     "longitude":"33.03387670"
  },
  {
     "name":"Lira District",
     "isoCode":"307",
     "countryCode":"UG",
     "latitude":"2.23161690",
     "longitude":"32.94376670"
  },
  {
     "name":"Bukedea District",
     "isoCode":"219",
     "countryCode":"UG",
     "latitude":"1.35568980",
     "longitude":"34.10867930"
  },
  {
     "name":"Alebtong District",
     "isoCode":"323",
     "countryCode":"UG",
     "latitude":"2.25457730",
     "longitude":"33.34861470"
  },
  {
     "name":"Koboko District",
     "isoCode":"319",
     "countryCode":"UG",
     "latitude":"3.52370580",
     "longitude":"31.03351000"
  },
  {
     "name":"Kiryandongo District",
     "isoCode":"421",
     "countryCode":"UG",
     "latitude":"2.01799070",
     "longitude":"32.08374450"
  },
  {
     "name":"Kiboga District",
     "isoCode":"103",
     "countryCode":"UG",
     "latitude":"0.96575900",
     "longitude":"31.71954590"
  },
  {
     "name":"Kitgum District",
     "isoCode":"305",
     "countryCode":"UG",
     "latitude":"3.33968290",
     "longitude":"33.16888830"
  },
  {
     "name":"Bududa District",
     "isoCode":"218",
     "countryCode":"UG",
     "latitude":"1.00296930",
     "longitude":"34.33381230"
  },
  {
     "name":"Mbale District",
     "isoCode":"209",
     "countryCode":"UG",
     "latitude":"1.03442740",
     "longitude":"34.19768820"
  },
  {
     "name":"Namayingo District",
     "isoCode":"230",
     "countryCode":"UG",
     "latitude":"-0.28035750",
     "longitude":"33.75177230"
  },
  {
     "name":"Amuria District",
     "isoCode":"216",
     "countryCode":"UG",
     "latitude":"2.03017000",
     "longitude":"33.64275330"
  },
  {
     "name":"Amudat District",
     "isoCode":"324",
     "countryCode":"UG",
     "latitude":"1.79162240",
     "longitude":"34.90655100"
  },
  {
     "name":"Masindi District",
     "isoCode":"409",
     "countryCode":"UG",
     "latitude":"1.49203630",
     "longitude":"31.71954590"
  },
  {
     "name":"Kiruhura District",
     "isoCode":"419",
     "countryCode":"UG",
     "latitude":"-0.19279980",
     "longitude":"30.80394740"
  },
  {
     "name":"Masaka District",
     "isoCode":"105",
     "countryCode":"UG",
     "latitude":"-0.44636910",
     "longitude":"31.90179540"
  },
  {
     "name":"Pakwach District",
     "isoCode":"332",
     "countryCode":"UG",
     "latitude":"2.46071410",
     "longitude":"31.49417380"
  },
  {
     "name":"Rubanda District",
     "isoCode":"429",
     "countryCode":"UG",
     "latitude":"-1.18611900",
     "longitude":"29.84535760"
  },
  {
     "name":"Tororo District",
     "isoCode":"212",
     "countryCode":"UG",
     "latitude":"0.68709940",
     "longitude":"34.06414190"
  },
  {
     "name":"Kamwenge District",
     "isoCode":"413",
     "countryCode":"UG",
     "latitude":"0.22579300",
     "longitude":"30.48184460"
  },
  {
     "name":"Adjumani District",
     "isoCode":"301",
     "countryCode":"UG",
     "latitude":"3.25485270",
     "longitude":"31.71954590"
  },
  {
     "name":"Wakiso District",
     "isoCode":"113",
     "countryCode":"UG",
     "latitude":"0.06301900",
     "longitude":"32.44672380"
  },
  {
     "name":"Moyo District",
     "isoCode":"309",
     "countryCode":"UG",
     "latitude":"3.56964640",
     "longitude":"31.67393710"
  },
  {
     "name":"Mityana District",
     "isoCode":"115",
     "countryCode":"UG",
     "latitude":"0.44548450",
     "longitude":"32.08374450"
  },
  {
     "name":"Butaleja District",
     "isoCode":"221",
     "countryCode":"UG",
     "latitude":"0.84749220",
     "longitude":"33.84112880"
  },
  {
     "name":"Gomba District",
     "isoCode":"121",
     "countryCode":"UG",
     "latitude":"0.22297910",
     "longitude":"31.67393710"
  },
  {
     "name":"Jinja District",
     "isoCode":"204",
     "countryCode":"UG",
     "latitude":"0.53437430",
     "longitude":"33.30371430"
  },
  {
     "name":"Kayunga District",
     "isoCode":"112",
     "countryCode":"UG",
     "latitude":"0.98601820",
     "longitude":"32.85357550"
  },
  {
     "name":"Kween District",
     "isoCode":"228",
     "countryCode":"UG",
     "latitude":"1.44387900",
     "longitude":"34.59713200"
  },
  {
     "name":"Western Region",
     "isoCode":"W",
     "countryCode":"UG",
     "latitude":"40.76672150",
     "longitude":"-111.88772030"
  },
  {
     "name":"Mubende District",
     "isoCode":"107",
     "countryCode":"UG",
     "latitude":"0.57727580",
     "longitude":"31.53700030"
  },
  {
     "name":"Eastern Region",
     "isoCode":"E",
     "countryCode":"UG",
     "latitude":"6.23740360",
     "longitude":"-0.45023680"
  },
  {
     "name":"Kanungu District",
     "isoCode":"414",
     "countryCode":"UG",
     "latitude":"-0.81952530",
     "longitude":"29.74260400"
  },
  {
     "name":"Omoro District",
     "isoCode":"331",
     "countryCode":"UG",
     "latitude":"2.71522300",
     "longitude":"32.49200880"
  },
  {
     "name":"Bukomansimbi District",
     "isoCode":"118",
     "countryCode":"UG",
     "latitude":"-0.14327520",
     "longitude":"31.60548930"
  },
  {
     "name":"Lyantonde District",
     "isoCode":"114",
     "countryCode":"UG",
     "latitude":"-0.22406960",
     "longitude":"31.21684660"
  },
  {
     "name":"Buikwe District",
     "isoCode":"117",
     "countryCode":"UG",
     "latitude":"0.31440460",
     "longitude":"32.98883190"
  },
  {
     "name":"Nwoya District",
     "isoCode":"328",
     "countryCode":"UG",
     "latitude":"2.56244400",
     "longitude":"31.90179540"
  },
  {
     "name":"Zombo District",
     "isoCode":"330",
     "countryCode":"UG",
     "latitude":"2.55442930",
     "longitude":"30.94173680"
  },
  {
     "name":"Buyende District",
     "isoCode":"226",
     "countryCode":"UG",
     "latitude":"1.24136820",
     "longitude":"33.12390490"
  },
  {
     "name":"Bunyangabu District",
     "isoCode":"430",
     "countryCode":"UG",
     "latitude":"0.48709180",
     "longitude":"30.20510960"
  },
  {
     "name":"Kampala District",
     "isoCode":"102",
     "countryCode":"UG",
     "latitude":"0.34759640",
     "longitude":"32.58251970"
  },
  {
     "name":"Isingiro District",
     "isoCode":"418",
     "countryCode":"UG",
     "latitude":"-0.84354300",
     "longitude":"30.80394740"
  },
  {
     "name":"Butambala District",
     "isoCode":"119",
     "countryCode":"UG",
     "latitude":"0.17425000",
     "longitude":"32.10646680"
  },
  {
     "name":"Bukwo District",
     "isoCode":"220",
     "countryCode":"UG",
     "latitude":"1.28186510",
     "longitude":"34.72987650"
  },
  {
     "name":"Bushenyi District",
     "isoCode":"402",
     "countryCode":"UG",
     "latitude":"-0.48709180",
     "longitude":"30.20510960"
  },
  {
     "name":"Bugiri District",
     "isoCode":"201",
     "countryCode":"UG",
     "latitude":"0.53161270",
     "longitude":"33.75177230"
  },
  {
     "name":"Butebo District",
     "isoCode":"233",
     "countryCode":"UG",
     "latitude":"1.21411240",
     "longitude":"33.90808960"
  },
  {
     "name":"Buliisa District",
     "isoCode":"416",
     "countryCode":"UG",
     "latitude":"2.02996070",
     "longitude":"31.53700030"
  },
  {
     "name":"Otuke District",
     "isoCode":"329",
     "countryCode":"UG",
     "latitude":"2.52140590",
     "longitude":"33.34861470"
  },
  {
     "name":"Buhweju District",
     "isoCode":"420",
     "countryCode":"UG",
     "latitude":"-0.29113590",
     "longitude":"30.29741990"
  },
  {
     "name":"Agago District",
     "isoCode":"322",
     "countryCode":"UG",
     "latitude":"2.92508200",
     "longitude":"33.34861470"
  },
  {
     "name":"Nakapiripirit District",
     "isoCode":"311",
     "countryCode":"UG",
     "latitude":"1.96061730",
     "longitude":"34.59713200"
  },
  {
     "name":"Kalungu District",
     "isoCode":"122",
     "countryCode":"UG",
     "latitude":"-0.09528310",
     "longitude":"31.76513620"
  },
  {
     "name":"Moroto District",
     "isoCode":"308",
     "countryCode":"UG",
     "latitude":"2.61685450",
     "longitude":"34.59713200"
  },
  {
     "name":"Central Region",
     "isoCode":"C",
     "countryCode":"UG",
     "latitude":"44.29687500",
     "longitude":"-94.74017330"
  },
  {
     "name":"Oyam District",
     "isoCode":"321",
     "countryCode":"UG",
     "latitude":"2.27762810",
     "longitude":"32.44672380"
  },
  {
     "name":"Kaliro District",
     "isoCode":"222",
     "countryCode":"UG",
     "latitude":"1.04311070",
     "longitude":"33.48319060"
  },
  {
     "name":"Kakumiro District",
     "isoCode":"428",
     "countryCode":"UG",
     "latitude":"0.78080350",
     "longitude":"31.32413890"
  },
  {
     "name":"Namisindwa District",
     "isoCode":"234",
     "countryCode":"UG",
     "latitude":"0.90710100",
     "longitude":"34.35740370"
  },
  {
     "name":"Kole District",
     "isoCode":"325",
     "countryCode":"UG",
     "latitude":"2.37010970",
     "longitude":"32.76330360"
  },
  {
     "name":"Kyenjojo District",
     "isoCode":"415",
     "countryCode":"UG",
     "latitude":"0.60929230",
     "longitude":"30.64012310"
  },
  {
     "name":"Kagadi District",
     "isoCode":"427",
     "countryCode":"UG",
     "latitude":"0.94007610",
     "longitude":"30.81256380"
  },
  {
     "name":"Ntungamo District",
     "isoCode":"411",
     "countryCode":"UG",
     "latitude":"-0.98073410",
     "longitude":"30.25127280"
  },
  {
     "name":"Kalangala District",
     "isoCode":"101",
     "countryCode":"UG",
     "latitude":"-0.63505780",
     "longitude":"32.53727410"
  },
  {
     "name":"Nakasongola District",
     "isoCode":"109",
     "countryCode":"UG",
     "latitude":"1.34897210",
     "longitude":"32.44672380"
  },
  {
     "name":"Sheema District",
     "isoCode":"426",
     "countryCode":"UG",
     "latitude":"-0.55152980",
     "longitude":"30.38966510"
  },
  {
     "name":"Pader District",
     "isoCode":"312",
     "countryCode":"UG",
     "latitude":"2.94306820",
     "longitude":"32.80844960"
  },
  {
     "name":"Kisoro District",
     "isoCode":"408",
     "countryCode":"UG",
     "latitude":"-1.22094300",
     "longitude":"29.64991620"
  },
  {
     "name":"Mukono District",
     "isoCode":"108",
     "countryCode":"UG",
     "latitude":"0.28354760",
     "longitude":"32.76330360"
  },
  {
     "name":"Lamwo District",
     "isoCode":"326",
     "countryCode":"UG",
     "latitude":"3.57075680",
     "longitude":"32.53727410"
  },
  {
     "name":"Pallisa District",
     "isoCode":"210",
     "countryCode":"UG",
     "latitude":"1.23242060",
     "longitude":"33.75177230"
  },
  {
     "name":"Gulu District",
     "isoCode":"304",
     "countryCode":"UG",
     "latitude":"2.81857760",
     "longitude":"32.44672380"
  },
  {
     "name":"Buvuma District",
     "isoCode":"120",
     "countryCode":"UG",
     "latitude":"-0.37649120",
     "longitude":"33.25879300"
  },
  {
     "name":"Mbarara District",
     "isoCode":"410",
     "countryCode":"UG",
     "latitude":"-0.60715960",
     "longitude":"30.65450220"
  },
  {
     "name":"Amolatar District",
     "isoCode":"315",
     "countryCode":"UG",
     "latitude":"1.60544020",
     "longitude":"32.80844960"
  },
  {
     "name":"Lwengo District",
     "isoCode":"124",
     "countryCode":"UG",
     "latitude":"-0.41652880",
     "longitude":"31.39989950"
  },
  {
     "name":"Mayuge District",
     "isoCode":"214",
     "countryCode":"UG",
     "latitude":"-0.21829820",
     "longitude":"33.57280270"
  },
  {
     "name":"Bundibugyo District",
     "isoCode":"401",
     "countryCode":"UG",
     "latitude":"0.68517630",
     "longitude":"30.02029640"
  },
  {
     "name":"Katakwi District",
     "isoCode":"207",
     "countryCode":"UG",
     "latitude":"1.97310300",
     "longitude":"34.06414190"
  },
  {
     "name":"Maracha District",
     "isoCode":"320",
     "countryCode":"UG",
     "latitude":"3.28731270",
     "longitude":"30.94030230"
  },
  {
     "name":"Ntoroko District",
     "isoCode":"424",
     "countryCode":"UG",
     "latitude":"1.07881780",
     "longitude":"30.38966510"
  },
  {
     "name":"Nakaseke District",
     "isoCode":"116",
     "countryCode":"UG",
     "latitude":"1.22308480",
     "longitude":"32.08374450"
  },
  {
     "name":"Ngora District",
     "isoCode":"231",
     "countryCode":"UG",
     "latitude":"1.49081150",
     "longitude":"33.75177230"
  },
  {
     "name":"Kumi District",
     "isoCode":"208",
     "countryCode":"UG",
     "latitude":"1.48769990",
     "longitude":"33.93039910"
  },
  {
     "name":"Kabale District",
     "isoCode":"404",
     "countryCode":"UG",
     "latitude":"-1.24930840",
     "longitude":"30.06652360"
  },
  {
     "name":"Sembabule District",
     "isoCode":"111",
     "countryCode":"UG",
     "latitude":"0.06377150",
     "longitude":"31.35416310"
  },
  {
     "name":"Bulambuli District",
     "isoCode":"225",
     "countryCode":"UG",
     "latitude":"1.47988460",
     "longitude":"34.37544140"
  },
  {
     "name":"Sironko District",
     "isoCode":"215",
     "countryCode":"UG",
     "latitude":"1.23022740",
     "longitude":"34.24910640"
  },
  {
     "name":"Napak District",
     "isoCode":"327",
     "countryCode":"UG",
     "latitude":"2.36299450",
     "longitude":"34.24215970"
  },
  {
     "name":"Busia District",
     "isoCode":"202",
     "countryCode":"UG",
     "latitude":"0.40447310",
     "longitude":"34.01958270"
  },
  {
     "name":"Kapchorwa District",
     "isoCode":"206",
     "countryCode":"UG",
     "latitude":"1.33502050",
     "longitude":"34.39763560"
  },
  {
     "name":"Luwero District",
     "isoCode":"104",
     "countryCode":"UG",
     "latitude":"0.82711180",
     "longitude":"32.62774550"
  },
  {
     "name":"Kaabong District",
     "isoCode":"318",
     "countryCode":"UG",
     "latitude":"3.51262150",
     "longitude":"33.97500180"
  },
  {
     "name":"Mitooma District",
     "isoCode":"423",
     "countryCode":"UG",
     "latitude":"-0.61932760",
     "longitude":"30.02029640"
  },
  {
     "name":"Kibaale District",
     "isoCode":"407",
     "countryCode":"UG",
     "latitude":"0.90668020",
     "longitude":"31.07937050"
  },
  {
     "name":"Kyegegwa District",
     "isoCode":"422",
     "countryCode":"UG",
     "latitude":"0.48181930",
     "longitude":"31.05500930"
  },
  {
     "name":"Manafwa District",
     "isoCode":"223",
     "countryCode":"UG",
     "latitude":"0.90635990",
     "longitude":"34.28660910"
  },
  {
     "name":"Rakai District",
     "isoCode":"110",
     "countryCode":"UG",
     "latitude":"-0.70691350",
     "longitude":"31.53700030"
  },
  {
     "name":"Kasese District",
     "isoCode":"406",
     "countryCode":"UG",
     "latitude":"0.06462850",
     "longitude":"30.06652360"
  },
  {
     "name":"Budaka District",
     "isoCode":"217",
     "countryCode":"UG",
     "latitude":"1.10162770",
     "longitude":"33.93039910"
  },
  {
     "name":"Rubirizi District",
     "isoCode":"425",
     "countryCode":"UG",
     "latitude":"-0.26424100",
     "longitude":"30.10840330"
  },
  {
     "name":"Kotido District",
     "isoCode":"306",
     "countryCode":"UG",
     "latitude":"3.04156790",
     "longitude":"33.88577470"
  },
  {
     "name":"Soroti District",
     "isoCode":"211",
     "countryCode":"UG",
     "latitude":"1.72291170",
     "longitude":"33.52800720"
  },
  {
     "name":"Luuka District",
     "isoCode":"229",
     "countryCode":"UG",
     "latitude":"0.72505990",
     "longitude":"33.30371430"
  },
  {
     "name":"Nebbi District",
     "isoCode":"310",
     "countryCode":"UG",
     "latitude":"2.44093920",
     "longitude":"31.35416310"
  },
  {
     "name":"Arua District",
     "isoCode":"303",
     "countryCode":"UG",
     "latitude":"2.99598460",
     "longitude":"31.17103890"
  },
  {
     "name":"Kyotera District",
     "isoCode":"125",
     "countryCode":"UG",
     "latitude":"-0.63589880",
     "longitude":"31.54556370"
  },
  {
     "name":"Schellenberg",
     "isoCode":"08",
     "countryCode":"LI",
     "latitude":"47.23096600",
     "longitude":"9.54678430"
  },
  {
     "name":"Schaan",
     "isoCode":"07",
     "countryCode":"LI",
     "latitude":"47.12043400",
     "longitude":"9.59416020"
  },
  {
     "name":"Eschen",
     "isoCode":"02",
     "countryCode":"LI",
     "latitude":"40.76695740",
     "longitude":"-73.95228210"
  },
  {
     "name":"Vaduz",
     "isoCode":"11",
     "countryCode":"LI",
     "latitude":"47.14103030",
     "longitude":"9.52092770"
  },
  {
     "name":"Ruggell",
     "isoCode":"06",
     "countryCode":"LI",
     "latitude":"47.25292220",
     "longitude":"9.54021270"
  },
  {
     "name":"Planken",
     "isoCode":"05",
     "countryCode":"LI",
     "latitude":"40.66505760",
     "longitude":"-73.50479800"
  },
  {
     "name":"Mauren",
     "isoCode":"04",
     "countryCode":"LI",
     "latitude":"47.21892850",
     "longitude":"9.54173500"
  },
  {
     "name":"Triesenberg",
     "isoCode":"10",
     "countryCode":"LI",
     "latitude":"47.12245110",
     "longitude":"9.57019850"
  },
  {
     "name":"Gamprin",
     "isoCode":"03",
     "countryCode":"LI",
     "latitude":"47.21324900",
     "longitude":"9.50251950"
  },
  {
     "name":"Balzers",
     "isoCode":"01",
     "countryCode":"LI",
     "latitude":"42.05283570",
     "longitude":"-88.03668480"
  },
  {
     "name":"Triesen",
     "isoCode":"09",
     "countryCode":"LI",
     "latitude":"47.10979880",
     "longitude":"9.52482960"
  },
  {
     "name":"Brčko District",
     "isoCode":"BRC",
     "countryCode":"BA",
     "latitude":"44.84059440",
     "longitude":"18.74215300"
  },
  {
     "name":"Tuzla Canton",
     "isoCode":"03",
     "countryCode":"BA",
     "latitude":"44.53434630",
     "longitude":"18.69727970"
  },
  {
     "name":"Central Bosnia Canton",
     "isoCode":"06",
     "countryCode":"BA",
     "latitude":"44.13818560",
     "longitude":"17.68667140"
  },
  {
     "name":"Herzegovina-Neretva Canton",
     "isoCode":"07",
     "countryCode":"BA",
     "latitude":"43.52651590",
     "longitude":"17.76362100"
  },
  {
     "name":"Posavina Canton",
     "isoCode":"02",
     "countryCode":"BA",
     "latitude":"45.07520940",
     "longitude":"18.37763040"
  },
  {
     "name":"Una-Sana Canton",
     "isoCode":"01",
     "countryCode":"BA",
     "latitude":"44.65031160",
     "longitude":"16.31716290"
  },
  {
     "name":"Sarajevo Canton",
     "isoCode":"09",
     "countryCode":"BA",
     "latitude":"43.85125640",
     "longitude":"18.29534420"
  },
  {
     "name":"Federation of Bosnia and Herzegovina",
     "isoCode":"BIH",
     "countryCode":"BA",
     "latitude":"43.88748970",
     "longitude":"17.84279300"
  },
  {
     "name":"Zenica-Doboj Canton",
     "isoCode":"04",
     "countryCode":"BA",
     "latitude":"44.21271090",
     "longitude":"18.16046250"
  },
  {
     "name":"West Herzegovina Canton",
     "isoCode":"08",
     "countryCode":"BA",
     "latitude":"43.43692440",
     "longitude":"17.38488310"
  },
  {
     "name":"Republika Srpska",
     "isoCode":"SRP",
     "countryCode":"BA",
     "latitude":"44.72801860",
     "longitude":"17.31481360"
  },
  {
     "name":"Canton 10",
     "isoCode":"10",
     "countryCode":"BA",
     "latitude":"43.95341550",
     "longitude":"16.94251870"
  },
  {
     "name":"Bosnian Podrinje Canton",
     "isoCode":"05",
     "countryCode":"BA",
     "latitude":"43.68749000",
     "longitude":"18.82443940"
  },
  {
     "name":"Dakar",
     "isoCode":"DK",
     "countryCode":"SN",
     "latitude":"14.71667700",
     "longitude":"-17.46768610"
  },
  {
     "name":"Kolda",
     "isoCode":"KD",
     "countryCode":"SN",
     "latitude":"12.91074950",
     "longitude":"-14.95056710"
  },
  {
     "name":"Kaffrine",
     "isoCode":"KA",
     "countryCode":"SN",
     "latitude":"14.10520200",
     "longitude":"-15.54157550"
  },
  {
     "name":"Matam",
     "isoCode":"MT",
     "countryCode":"SN",
     "latitude":"15.66002250",
     "longitude":"-13.25769060"
  },
  {
     "name":"Saint-Louis",
     "isoCode":"SL",
     "countryCode":"SN",
     "latitude":"38.62700250",
     "longitude":"-90.19940420"
  },
  {
     "name":"Ziguinchor",
     "isoCode":"ZG",
     "countryCode":"SN",
     "latitude":"12.56414790",
     "longitude":"-16.26398250"
  },
  {
     "name":"Fatick",
     "isoCode":"FK",
     "countryCode":"SN",
     "latitude":"14.33901670",
     "longitude":"-16.41114250"
  },
  {
     "name":"Diourbel Region",
     "isoCode":"DB",
     "countryCode":"SN",
     "latitude":"14.72830850",
     "longitude":"-16.25221430"
  },
  {
     "name":"Kédougou",
     "isoCode":"KE",
     "countryCode":"SN",
     "latitude":"12.56046070",
     "longitude":"-12.17470770"
  },
  {
     "name":"Sédhiou",
     "isoCode":"SE",
     "countryCode":"SN",
     "latitude":"12.70460400",
     "longitude":"-15.55623040"
  },
  {
     "name":"Kaolack",
     "isoCode":"KL",
     "countryCode":"SN",
     "latitude":"14.16520830",
     "longitude":"-16.07577490"
  },
  {
     "name":"Thiès Region",
     "isoCode":"TH",
     "countryCode":"SN",
     "latitude":"14.79100520",
     "longitude":"-16.93586040"
  },
  {
     "name":"Louga",
     "isoCode":"LG",
     "countryCode":"SN",
     "latitude":"15.61417680",
     "longitude":"-16.22868000"
  },
  {
     "name":"Tambacounda Region",
     "isoCode":"TC",
     "countryCode":"SN",
     "latitude":"13.56190110",
     "longitude":"-13.17403480"
  },
  {
     "name":"Encamp",
     "isoCode":"03",
     "countryCode":"AD",
     "latitude":"42.53597640",
     "longitude":"1.58367730"
  },
  {
     "name":"Andorra la Vella",
     "isoCode":"07",
     "countryCode":"AD",
     "latitude":"42.50631740",
     "longitude":"1.52183550"
  },
  {
     "name":"Canillo",
     "isoCode":"02",
     "countryCode":"AD",
     "latitude":"42.59782490",
     "longitude":"1.65663770"
  },
  {
     "name":"Sant Julià de Lòria",
     "isoCode":"06",
     "countryCode":"AD",
     "latitude":"42.45296310",
     "longitude":"1.49182350"
  },
  {
     "name":"Ordino",
     "isoCode":"05",
     "countryCode":"AD",
     "latitude":"42.59944330",
     "longitude":"1.54023270"
  },
  {
     "name":"Escaldes-Engordany",
     "isoCode":"08",
     "countryCode":"AD",
     "latitude":"42.49093790",
     "longitude":"1.58869660"
  },
  {
     "name":"La Massana",
     "isoCode":"04",
     "countryCode":"AD",
     "latitude":"42.54562500",
     "longitude":"1.51473920"
  },
  {
     "name":"Mont Buxton",
     "isoCode":"17",
     "countryCode":"SC",
     "latitude":"-4.61666670",
     "longitude":"55.44577680"
  },
  {
     "name":"La Digue",
     "isoCode":"15",
     "countryCode":"SC",
     "latitude":"49.76669220",
     "longitude":"-97.15466290"
  },
  {
     "name":"Saint Louis",
     "isoCode":"22",
     "countryCode":"SC",
     "latitude":"38.62700250",
     "longitude":"-90.19940420"
  },
  {
     "name":"Baie Lazare",
     "isoCode":"06",
     "countryCode":"SC",
     "latitude":"-4.74825250",
     "longitude":"55.48593630"
  },
  {
     "name":"Mont Fleuri",
     "isoCode":"18",
     "countryCode":"SC",
     "latitude":"-4.63565430",
     "longitude":"55.45546880"
  },
  {
     "name":"Les Mamelles",
     "isoCode":"24",
     "countryCode":"SC",
     "latitude":"38.82505050",
     "longitude":"-90.48345170"
  },
  {
     "name":"Grand'Anse Mahé",
     "isoCode":"13",
     "countryCode":"SC",
     "latitude":"-4.67739200",
     "longitude":"55.46377700"
  },
  {
     "name":"Roche Caiman",
     "isoCode":"25",
     "countryCode":"SC",
     "latitude":"-4.63960280",
     "longitude":"55.46793150"
  },
  {
     "name":"Anse Royale",
     "isoCode":"05",
     "countryCode":"SC",
     "latitude":"-4.74079880",
     "longitude":"55.50810120"
  },
  {
     "name":"Glacis",
     "isoCode":"12",
     "countryCode":"SC",
     "latitude":"47.11573030",
     "longitude":"-70.30281830"
  },
  {
     "name":"Grand'Anse Praslin",
     "isoCode":"14",
     "countryCode":"SC",
     "latitude":"-4.31762190",
     "longitude":"55.70783630"
  },
  {
     "name":"Bel Ombre",
     "isoCode":"10",
     "countryCode":"SC",
     "latitude":"-20.50100950",
     "longitude":"57.42596240"
  },
  {
     "name":"Anse-aux-Pins",
     "isoCode":"01",
     "countryCode":"SC",
     "latitude":"-4.69004430",
     "longitude":"55.51502890"
  },
  {
     "name":"Port Glaud",
     "isoCode":"21",
     "countryCode":"SC",
     "latitude":"-4.64885230",
     "longitude":"55.41947530"
  },
  {
     "name":"Au Cap",
     "isoCode":"04",
     "countryCode":"SC",
     "latitude":"-4.70597230",
     "longitude":"55.50810120"
  },
  {
     "name":"Takamaka",
     "isoCode":"23",
     "countryCode":"SC",
     "latitude":"37.96459170",
     "longitude":"-1.22177270"
  },
  {
     "name":"Pointe La Rue",
     "isoCode":"20",
     "countryCode":"SC",
     "latitude":"-4.68048900",
     "longitude":"55.51918570"
  },
  {
     "name":"Plaisance",
     "isoCode":"19",
     "countryCode":"SC",
     "latitude":"45.60709500",
     "longitude":"-75.11427450"
  },
  {
     "name":"Beau Vallon",
     "isoCode":"08",
     "countryCode":"SC",
     "latitude":"-4.62109670",
     "longitude":"55.42778020"
  },
  {
     "name":"Anse Boileau",
     "isoCode":"02",
     "countryCode":"SC",
     "latitude":"-4.70472680",
     "longitude":"55.48593630"
  },
  {
     "name":"Baie Sainte Anne",
     "isoCode":"07",
     "countryCode":"SC",
     "latitude":"47.05259000",
     "longitude":"-64.95245790"
  },
  {
     "name":"Bel Air",
     "isoCode":"09",
     "countryCode":"SC",
     "latitude":"34.10024550",
     "longitude":"-118.45946300"
  },
  {
     "name":"La Rivière Anglaise",
     "isoCode":"16",
     "countryCode":"SC",
     "latitude":"-4.61061500",
     "longitude":"55.45408410"
  },
  {
     "name":"Cascade",
     "isoCode":"11",
     "countryCode":"SC",
     "latitude":"44.51628210",
     "longitude":"-116.04179830"
  },
  {
     "name":"Shaki",
     "isoCode":"SA",
     "countryCode":"AZ",
     "latitude":"41.19747530",
     "longitude":"47.15712410"
  },
  {
     "name":"Tartar District",
     "isoCode":"TAR",
     "countryCode":"AZ",
     "latitude":"40.34438750",
     "longitude":"46.93765190"
  },
  {
     "name":"Shirvan",
     "isoCode":"SR",
     "countryCode":"AZ",
     "latitude":"39.94697070",
     "longitude":"48.92239190"
  },
  {
     "name":"Qazakh District",
     "isoCode":"QAZ",
     "countryCode":"AZ",
     "latitude":"41.09710740",
     "longitude":"45.35163310"
  },
  {
     "name":"Sadarak District",
     "isoCode":"SAD",
     "countryCode":"AZ",
     "latitude":"39.71051140",
     "longitude":"44.88642770"
  },
  {
     "name":"Yevlakh District",
     "isoCode":"YEV",
     "countryCode":"AZ",
     "latitude":"40.61966380",
     "longitude":"47.15003240"
  },
  {
     "name":"Khojali District",
     "isoCode":"XCI",
     "countryCode":"AZ",
     "latitude":"39.91325530",
     "longitude":"46.79430500"
  },
  {
     "name":"Kalbajar District",
     "isoCode":"KAL",
     "countryCode":"AZ",
     "latitude":"40.10243290",
     "longitude":"46.03648720"
  },
  {
     "name":"Qakh District",
     "isoCode":"QAX",
     "countryCode":"AZ",
     "latitude":"41.42068270",
     "longitude":"46.93201840"
  },
  {
     "name":"Fizuli District",
     "isoCode":"FUZ",
     "countryCode":"AZ",
     "latitude":"39.53786050",
     "longitude":"47.30338770"
  },
  {
     "name":"Astara District",
     "isoCode":"AST",
     "countryCode":"AZ",
     "latitude":"38.49378450",
     "longitude":"48.69443650"
  },
  {
     "name":"Shamakhi District",
     "isoCode":"SMI",
     "countryCode":"AZ",
     "latitude":"40.63187310",
     "longitude":"48.63638010"
  },
  {
     "name":"Neftchala District",
     "isoCode":"NEF",
     "countryCode":"AZ",
     "latitude":"39.38810520",
     "longitude":"49.24137430"
  },
  {
     "name":"Goychay",
     "isoCode":"GOY",
     "countryCode":"AZ",
     "latitude":"40.62361680",
     "longitude":"47.74030340"
  },
  {
     "name":"Bilasuvar District",
     "isoCode":"BIL",
     "countryCode":"AZ",
     "latitude":"39.45988330",
     "longitude":"48.55098130"
  },
  {
     "name":"Tovuz District",
     "isoCode":"TOV",
     "countryCode":"AZ",
     "latitude":"40.99545230",
     "longitude":"45.61659070"
  },
  {
     "name":"Ordubad District",
     "isoCode":"ORD",
     "countryCode":"AZ",
     "latitude":"38.90216220",
     "longitude":"46.02376250"
  },
  {
     "name":"Sharur District",
     "isoCode":"SAR",
     "countryCode":"AZ",
     "latitude":"39.55363320",
     "longitude":"44.98456800"
  },
  {
     "name":"Samukh District",
     "isoCode":"SMX",
     "countryCode":"AZ",
     "latitude":"40.76046310",
     "longitude":"46.40631810"
  },
  {
     "name":"Khizi District",
     "isoCode":"XIZ",
     "countryCode":"AZ",
     "latitude":"40.91094890",
     "longitude":"49.07292640"
  },
  {
     "name":"Yevlakh",
     "isoCode":"YE",
     "countryCode":"AZ",
     "latitude":"40.61966380",
     "longitude":"47.15003240"
  },
  {
     "name":"Ujar District",
     "isoCode":"UCA",
     "countryCode":"AZ",
     "latitude":"40.50675250",
     "longitude":"47.64896410"
  },
  {
     "name":"Absheron District",
     "isoCode":"ABS",
     "countryCode":"AZ",
     "latitude":"40.36296930",
     "longitude":"49.27368150"
  },
  {
     "name":"Lachin District",
     "isoCode":"LAC",
     "countryCode":"AZ",
     "latitude":"39.63834140",
     "longitude":"46.54608530"
  },
  {
     "name":"Qabala District",
     "isoCode":"QAB",
     "countryCode":"AZ",
     "latitude":"40.92539250",
     "longitude":"47.80161060"
  },
  {
     "name":"Agstafa District",
     "isoCode":"AGA",
     "countryCode":"AZ",
     "latitude":"41.26559330",
     "longitude":"45.51342910"
  },
  {
     "name":"Imishli District",
     "isoCode":"IMI",
     "countryCode":"AZ",
     "latitude":"39.86946860",
     "longitude":"48.06652180"
  },
  {
     "name":"Salyan District",
     "isoCode":"SAL",
     "countryCode":"AZ",
     "latitude":"28.35248110",
     "longitude":"82.12784000"
  },
  {
     "name":"Lerik District",
     "isoCode":"LER",
     "countryCode":"AZ",
     "latitude":"38.77361920",
     "longitude":"48.41514830"
  },
  {
     "name":"Agsu District",
     "isoCode":"AGU",
     "countryCode":"AZ",
     "latitude":"40.52833390",
     "longitude":"48.36508350"
  },
  {
     "name":"Qubadli District",
     "isoCode":"QBI",
     "countryCode":"AZ",
     "latitude":"39.27139960",
     "longitude":"46.63543120"
  },
  {
     "name":"Kurdamir District",
     "isoCode":"KUR",
     "countryCode":"AZ",
     "latitude":"40.36986510",
     "longitude":"48.16446260"
  },
  {
     "name":"Yardymli District",
     "isoCode":"YAR",
     "countryCode":"AZ",
     "latitude":"38.90589170",
     "longitude":"48.24961270"
  },
  {
     "name":"Goranboy District",
     "isoCode":"GOR",
     "countryCode":"AZ",
     "latitude":"40.53805060",
     "longitude":"46.59908910"
  },
  {
     "name":"Baku",
     "isoCode":"BA",
     "countryCode":"AZ",
     "latitude":"40.40926170",
     "longitude":"49.86709240"
  },
  {
     "name":"Agdash District",
     "isoCode":"AGS",
     "countryCode":"AZ",
     "latitude":"40.63354270",
     "longitude":"47.46743100"
  },
  {
     "name":"Beylagan District",
     "isoCode":"BEY",
     "countryCode":"AZ",
     "latitude":"39.77230730",
     "longitude":"47.61541660"
  },
  {
     "name":"Masally District",
     "isoCode":"MAS",
     "countryCode":"AZ",
     "latitude":"39.03407220",
     "longitude":"48.65893540"
  },
  {
     "name":"Oghuz District",
     "isoCode":"OGU",
     "countryCode":"AZ",
     "latitude":"41.07279240",
     "longitude":"47.46506720"
  },
  {
     "name":"Saatly District",
     "isoCode":"SAT",
     "countryCode":"AZ",
     "latitude":"39.90955030",
     "longitude":"48.35951220"
  },
  {
     "name":"Lankaran District",
     "isoCode":"LA",
     "countryCode":"AZ",
     "latitude":"38.75286690",
     "longitude":"48.84750150"
  },
  {
     "name":"Agdam District",
     "isoCode":"AGM",
     "countryCode":"AZ",
     "latitude":"39.99318530",
     "longitude":"46.99495620"
  },
  {
     "name":"Balakan District",
     "isoCode":"BAL",
     "countryCode":"AZ",
     "latitude":"41.70375090",
     "longitude":"46.40442130"
  },
  {
     "name":"Dashkasan District",
     "isoCode":"DAS",
     "countryCode":"AZ",
     "latitude":"40.52022570",
     "longitude":"46.07793040"
  },
  {
     "name":"Nakhchivan Autonomous Republic",
     "isoCode":"NX",
     "countryCode":"AZ",
     "latitude":"39.32568140",
     "longitude":"45.49126480"
  },
  {
     "name":"Quba District",
     "isoCode":"QBA",
     "countryCode":"AZ",
     "latitude":"41.15642420",
     "longitude":"48.41350210"
  },
  {
     "name":"Ismailli District",
     "isoCode":"ISM",
     "countryCode":"AZ",
     "latitude":"40.74299360",
     "longitude":"48.21255560"
  },
  {
     "name":"Sabirabad District",
     "isoCode":"SAB",
     "countryCode":"AZ",
     "latitude":"39.98706630",
     "longitude":"48.46925450"
  },
  {
     "name":"Zaqatala District",
     "isoCode":"ZAQ",
     "countryCode":"AZ",
     "latitude":"41.59068890",
     "longitude":"46.72403730"
  },
  {
     "name":"Kangarli District",
     "isoCode":"KAN",
     "countryCode":"AZ",
     "latitude":"39.38719400",
     "longitude":"45.16398520"
  },
  {
     "name":"Martuni",
     "isoCode":"XVD",
     "countryCode":"AZ",
     "latitude":"39.79146930",
     "longitude":"47.11008140"
  },
  {
     "name":"Barda District",
     "isoCode":"BAR",
     "countryCode":"AZ",
     "latitude":"40.37065550",
     "longitude":"47.13789090"
  },
  {
     "name":"Jabrayil District",
     "isoCode":"CAB",
     "countryCode":"AZ",
     "latitude":"39.26455440",
     "longitude":"46.96215620"
  },
  {
     "name":"Hajigabul District",
     "isoCode":"HAC",
     "countryCode":"AZ",
     "latitude":"40.03937700",
     "longitude":"48.92025330"
  },
  {
     "name":"Julfa District",
     "isoCode":"CUL",
     "countryCode":"AZ",
     "latitude":"38.96049830",
     "longitude":"45.62929390"
  },
  {
     "name":"Gobustan District",
     "isoCode":"QOB",
     "countryCode":"AZ",
     "latitude":"40.53261040",
     "longitude":"48.92737500"
  },
  {
     "name":"Goygol District",
     "isoCode":"GYG",
     "countryCode":"AZ",
     "latitude":"40.55953780",
     "longitude":"46.33149530"
  },
  {
     "name":"Babek District",
     "isoCode":"BAB",
     "countryCode":"AZ",
     "latitude":"39.15076130",
     "longitude":"45.44853680"
  },
  {
     "name":"Zardab District",
     "isoCode":"ZAR",
     "countryCode":"AZ",
     "latitude":"40.21481140",
     "longitude":"47.71494400"
  },
  {
     "name":"Aghjabadi District",
     "isoCode":"AGC",
     "countryCode":"AZ",
     "latitude":"28.78918410",
     "longitude":"77.51607880"
  },
  {
     "name":"Jalilabad District",
     "isoCode":"CAL",
     "countryCode":"AZ",
     "latitude":"39.20516320",
     "longitude":"48.51006040"
  },
  {
     "name":"Shahbuz District",
     "isoCode":"SAH",
     "countryCode":"AZ",
     "latitude":"39.44521030",
     "longitude":"45.65680090"
  },
  {
     "name":"Mingachevir",
     "isoCode":"MI",
     "countryCode":"AZ",
     "latitude":"40.77025630",
     "longitude":"47.04960150"
  },
  {
     "name":"Zangilan District",
     "isoCode":"ZAN",
     "countryCode":"AZ",
     "latitude":"39.08568990",
     "longitude":"46.65247280"
  },
  {
     "name":"Sumqayit",
     "isoCode":"SM",
     "countryCode":"AZ",
     "latitude":"40.58547650",
     "longitude":"49.63174110"
  },
  {
     "name":"Shamkir District",
     "isoCode":"SKR",
     "countryCode":"AZ",
     "latitude":"40.82881440",
     "longitude":"46.01668790"
  },
  {
     "name":"Siazan District",
     "isoCode":"SIY",
     "countryCode":"AZ",
     "latitude":"41.07838330",
     "longitude":"49.11184770"
  },
  {
     "name":"Ganja",
     "isoCode":"GA",
     "countryCode":"AZ",
     "latitude":"36.36873380",
     "longitude":"-95.99857670"
  },
  {
     "name":"Shaki District",
     "isoCode":"SAK",
     "countryCode":"AZ",
     "latitude":"41.11346620",
     "longitude":"47.13169270"
  },
  {
     "name":"Lankaran",
     "isoCode":"LAN",
     "countryCode":"AZ",
     "latitude":"38.75286690",
     "longitude":"48.84750150"
  },
  {
     "name":"Qusar District",
     "isoCode":"QUS",
     "countryCode":"AZ",
     "latitude":"41.42668860",
     "longitude":"48.43455770"
  },
  {
     "name":"Gədəbəy",
     "isoCode":"GAD",
     "countryCode":"AZ",
     "latitude":"40.56996390",
     "longitude":"45.81068830"
  },
  {
     "name":"Khachmaz District",
     "isoCode":"XAC",
     "countryCode":"AZ",
     "latitude":"41.45911680",
     "longitude":"48.80206260"
  },
  {
     "name":"Shabran District",
     "isoCode":"SBN",
     "countryCode":"AZ",
     "latitude":"41.22283760",
     "longitude":"48.84573040"
  },
  {
     "name":"Shusha District",
     "isoCode":"SUS",
     "countryCode":"AZ",
     "latitude":"39.75374380",
     "longitude":"46.74647550"
  },
  {
     "name":"Skrapar District",
     "isoCode":"SK",
     "countryCode":"AL",
     "latitude":"40.53499460",
     "longitude":"20.28322170"
  },
  {
     "name":"Kavajë District",
     "isoCode":"KA",
     "countryCode":"AL",
     "latitude":"41.18445290",
     "longitude":"19.56275960"
  },
  {
     "name":"Lezhë District",
     "isoCode":"LE",
     "countryCode":"AL",
     "latitude":"41.78607300",
     "longitude":"19.64607580"
  },
  {
     "name":"Librazhd District",
     "isoCode":"LB",
     "countryCode":"AL",
     "latitude":"41.18292320",
     "longitude":"20.31747690"
  },
  {
     "name":"Korçë District",
     "isoCode":"KO",
     "countryCode":"AL",
     "latitude":"40.59056700",
     "longitude":"20.61689210"
  },
  {
     "name":"Elbasan County",
     "isoCode":"03",
     "countryCode":"AL",
     "latitude":"41.12666720",
     "longitude":"20.23556470"
  },
  {
     "name":"Lushnjë District",
     "isoCode":"LU",
     "countryCode":"AL",
     "latitude":"40.94198300",
     "longitude":"19.69964280"
  },
  {
     "name":"Has District",
     "isoCode":"HA",
     "countryCode":"AL",
     "latitude":"42.79013360",
     "longitude":"-83.61220120"
  },
  {
     "name":"Kukës County",
     "isoCode":"07",
     "countryCode":"AL",
     "latitude":"42.08074640",
     "longitude":"20.41429230"
  },
  {
     "name":"Malësi e Madhe District",
     "isoCode":"MM",
     "countryCode":"AL",
     "latitude":"42.42451730",
     "longitude":"19.61631850"
  },
  {
     "name":"Berat County",
     "isoCode":"01",
     "countryCode":"AL",
     "latitude":"40.69530120",
     "longitude":"20.04496620"
  },
  {
     "name":"Gjirokastër County",
     "isoCode":"05",
     "countryCode":"AL",
     "latitude":"40.06728740",
     "longitude":"20.10452290"
  },
  {
     "name":"Dibër District",
     "isoCode":"DI",
     "countryCode":"AL",
     "latitude":"41.58881630",
     "longitude":"20.23556470"
  },
  {
     "name":"Pogradec District",
     "isoCode":"PG",
     "countryCode":"AL",
     "latitude":"40.90153140",
     "longitude":"20.65562890"
  },
  {
     "name":"Bulqizë District",
     "isoCode":"BU",
     "countryCode":"AL",
     "latitude":"41.49425870",
     "longitude":"20.21471570"
  },
  {
     "name":"Devoll District",
     "isoCode":"DV",
     "countryCode":"AL",
     "latitude":"40.64473470",
     "longitude":"20.95066360"
  },
  {
     "name":"Lezhë County",
     "isoCode":"08",
     "countryCode":"AL",
     "latitude":"41.78137590",
     "longitude":"19.80679160"
  },
  {
     "name":"Dibër County",
     "isoCode":"09",
     "countryCode":"AL",
     "latitude":"41.58881630",
     "longitude":"20.23556470"
  },
  {
     "name":"Shkodër County",
     "isoCode":"10",
     "countryCode":"AL",
     "latitude":"42.15037100",
     "longitude":"19.66393090"
  },
  {
     "name":"Kuçovë District",
     "isoCode":"KC",
     "countryCode":"AL",
     "latitude":"40.78370630",
     "longitude":"19.87823480"
  },
  {
     "name":"Vlorë District",
     "isoCode":"VL",
     "countryCode":"AL",
     "latitude":"40.46606680",
     "longitude":"19.49135600"
  },
  {
     "name":"Krujë District",
     "isoCode":"KR",
     "countryCode":"AL",
     "latitude":"41.50947650",
     "longitude":"19.77107320"
  },
  {
     "name":"Tirana County",
     "isoCode":"11",
     "countryCode":"AL",
     "latitude":"41.24275980",
     "longitude":"19.80679160"
  },
  {
     "name":"Tepelenë District",
     "isoCode":"TE",
     "countryCode":"AL",
     "latitude":"40.29666320",
     "longitude":"20.01816730"
  },
  {
     "name":"Gramsh District",
     "isoCode":"GR",
     "countryCode":"AL",
     "latitude":"40.86698730",
     "longitude":"20.18493230"
  },
  {
     "name":"Delvinë District",
     "isoCode":"DL",
     "countryCode":"AL",
     "latitude":"39.94813640",
     "longitude":"20.09558910"
  },
  {
     "name":"Peqin District",
     "isoCode":"PQ",
     "countryCode":"AL",
     "latitude":"41.04709020",
     "longitude":"19.75023840"
  },
  {
     "name":"Pukë District",
     "isoCode":"PU",
     "countryCode":"AL",
     "latitude":"42.04697720",
     "longitude":"19.89609680"
  },
  {
     "name":"Gjirokastër District",
     "isoCode":"GJ",
     "countryCode":"AL",
     "latitude":"40.06728740",
     "longitude":"20.10452290"
  },
  {
     "name":"Kurbin District",
     "isoCode":"KB",
     "countryCode":"AL",
     "latitude":"41.64126440",
     "longitude":"19.70559500"
  },
  {
     "name":"Kukës District",
     "isoCode":"KU",
     "countryCode":"AL",
     "latitude":"42.08074640",
     "longitude":"20.41429230"
  },
  {
     "name":"Sarandë District",
     "isoCode":"SR",
     "countryCode":"AL",
     "latitude":"39.85921190",
     "longitude":"20.02710010"
  },
  {
     "name":"Përmet District",
     "isoCode":"PR",
     "countryCode":"AL",
     "latitude":"40.23618370",
     "longitude":"20.35173340"
  },
  {
     "name":"Shkodër District",
     "isoCode":"SH",
     "countryCode":"AL",
     "latitude":"42.06929850",
     "longitude":"19.50325590"
  },
  {
     "name":"Fier District",
     "isoCode":"FR",
     "countryCode":"AL",
     "latitude":"40.72750400",
     "longitude":"19.56275960"
  },
  {
     "name":"Kolonjë District",
     "isoCode":"ER",
     "countryCode":"AL",
     "latitude":"40.33732620",
     "longitude":"20.67946760"
  },
  {
     "name":"Berat District",
     "isoCode":"BR",
     "countryCode":"AL",
     "latitude":"40.70863770",
     "longitude":"19.94373140"
  },
  {
     "name":"Korçë County",
     "isoCode":"06",
     "countryCode":"AL",
     "latitude":"40.59056700",
     "longitude":"20.61689210"
  },
  {
     "name":"Fier County",
     "isoCode":"04",
     "countryCode":"AL",
     "latitude":"40.91913920",
     "longitude":"19.66393090"
  },
  {
     "name":"Durrës County",
     "isoCode":"02",
     "countryCode":"AL",
     "latitude":"41.50809720",
     "longitude":"19.61631850"
  },
  {
     "name":"Tirana District",
     "isoCode":"TR",
     "countryCode":"AL",
     "latitude":"41.32754590",
     "longitude":"19.81869820"
  },
  {
     "name":"Vlorë County",
     "isoCode":"12",
     "countryCode":"AL",
     "latitude":"40.15009600",
     "longitude":"19.80679160"
  },
  {
     "name":"Mat District",
     "isoCode":"MT",
     "countryCode":"AL",
     "latitude":"41.59376750",
     "longitude":"19.99732440"
  },
  {
     "name":"Tropojë District",
     "isoCode":"TP",
     "countryCode":"AL",
     "latitude":"42.39821510",
     "longitude":"20.16259550"
  },
  {
     "name":"Mallakastër District",
     "isoCode":"MK",
     "countryCode":"AL",
     "latitude":"40.52733760",
     "longitude":"19.78297910"
  },
  {
     "name":"Mirditë District",
     "isoCode":"MR",
     "countryCode":"AL",
     "latitude":"41.76428600",
     "longitude":"19.90205090"
  },
  {
     "name":"Durrës District",
     "isoCode":"DR",
     "countryCode":"AL",
     "latitude":"41.37065170",
     "longitude":"19.52110630"
  },
  {
     "name":"Sveti Nikole Municipality",
     "isoCode":"69",
     "countryCode":"MK",
     "latitude":"41.89803120",
     "longitude":"21.99994350"
  },
  {
     "name":"Kratovo Municipality",
     "isoCode":"43",
     "countryCode":"MK",
     "latitude":"42.05371410",
     "longitude":"22.07148350"
  },
  {
     "name":"Zajas Municipality",
     "isoCode":"31",
     "countryCode":"MK",
     "latitude":"41.60303280",
     "longitude":"20.87913430"
  },
  {
     "name":"Staro Nagoričane Municipality",
     "isoCode":"71",
     "countryCode":"MK",
     "latitude":"42.21916920",
     "longitude":"21.90455410"
  },
  {
     "name":"Češinovo-Obleševo Municipality",
     "isoCode":"81",
     "countryCode":"MK",
     "latitude":"41.86393160",
     "longitude":"22.26224600"
  },
  {
     "name":"Debarca Municipality",
     "isoCode":"22",
     "countryCode":"MK",
     "latitude":"41.35840770",
     "longitude":"20.85529190"
  },
  {
     "name":"Probištip Municipality",
     "isoCode":"63",
     "countryCode":"MK",
     "latitude":"41.95891460",
     "longitude":"22.16686700"
  },
  {
     "name":"Krivogaštani Municipality",
     "isoCode":"45",
     "countryCode":"MK",
     "latitude":"41.30823060",
     "longitude":"21.36796890"
  },
  {
     "name":"Gevgelija Municipality",
     "isoCode":"18",
     "countryCode":"MK",
     "latitude":"41.21186060",
     "longitude":"22.38146240"
  },
  {
     "name":"Bogdanci Municipality",
     "isoCode":"05",
     "countryCode":"MK",
     "latitude":"41.18696160",
     "longitude":"22.59602680"
  },
  {
     "name":"Vraneštica Municipality",
     "isoCode":"15",
     "countryCode":"MK",
     "latitude":"41.48290870",
     "longitude":"21.05796320"
  },
  {
     "name":"Veles Municipality",
     "isoCode":"13",
     "countryCode":"MK",
     "latitude":"41.72744260",
     "longitude":"21.71376940"
  },
  {
     "name":"Bosilovo Municipality",
     "isoCode":"07",
     "countryCode":"MK",
     "latitude":"41.49048640",
     "longitude":"22.78671740"
  },
  {
     "name":"Mogila Municipality",
     "isoCode":"53",
     "countryCode":"MK",
     "latitude":"41.14796450",
     "longitude":"21.45143690"
  },
  {
     "name":"Tearce Municipality",
     "isoCode":"75",
     "countryCode":"MK",
     "latitude":"42.07775110",
     "longitude":"21.05349230"
  },
  {
     "name":"Demir Kapija Municipality",
     "isoCode":"24",
     "countryCode":"MK",
     "latitude":"41.37955380",
     "longitude":"22.21455710"
  },
  {
     "name":"Aračinovo Municipality",
     "isoCode":"02",
     "countryCode":"MK",
     "latitude":"42.02473810",
     "longitude":"21.57664070"
  },
  {
     "name":"Drugovo Municipality",
     "isoCode":"28",
     "countryCode":"MK",
     "latitude":"41.44081530",
     "longitude":"20.92682010"
  },
  {
     "name":"Vasilevo Municipality",
     "isoCode":"11",
     "countryCode":"MK",
     "latitude":"41.47416990",
     "longitude":"22.64221280"
  },
  {
     "name":"Lipkovo Municipality",
     "isoCode":"48",
     "countryCode":"MK",
     "latitude":"42.20066260",
     "longitude":"21.61837550"
  },
  {
     "name":"Brvenica Municipality",
     "isoCode":"08",
     "countryCode":"MK",
     "latitude":"41.96814820",
     "longitude":"20.98195860"
  },
  {
     "name":"Štip Municipality",
     "isoCode":"83",
     "countryCode":"MK",
     "latitude":"41.70792970",
     "longitude":"22.19071220"
  },
  {
     "name":"Vevčani Municipality",
     "isoCode":"12",
     "countryCode":"MK",
     "latitude":"41.24075430",
     "longitude":"20.59156490"
  },
  {
     "name":"Tetovo Municipality",
     "isoCode":"76",
     "countryCode":"MK",
     "latitude":"42.02748600",
     "longitude":"20.95066360"
  },
  {
     "name":"Negotino Municipality",
     "isoCode":"54",
     "countryCode":"MK",
     "latitude":"41.49899850",
     "longitude":"22.09532970"
  },
  {
     "name":"Konče Municipality",
     "isoCode":"41",
     "countryCode":"MK",
     "latitude":"41.51710110",
     "longitude":"22.38146240"
  },
  {
     "name":"Prilep Municipality",
     "isoCode":"62",
     "countryCode":"MK",
     "latitude":"41.26931420",
     "longitude":"21.71376940"
  },
  {
     "name":"Saraj Municipality",
     "isoCode":"68",
     "countryCode":"MK",
     "latitude":"41.98694960",
     "longitude":"21.26065540"
  },
  {
     "name":"Želino Municipality",
     "isoCode":"30",
     "countryCode":"MK",
     "latitude":"41.90065310",
     "longitude":"21.11757670"
  },
  {
     "name":"Mavrovo and Rostuša Municipality",
     "isoCode":"50",
     "countryCode":"MK",
     "latitude":"41.60924270",
     "longitude":"20.60124880"
  },
  {
     "name":"Plasnica Municipality",
     "isoCode":"61",
     "countryCode":"MK",
     "latitude":"41.45463490",
     "longitude":"21.10565390"
  },
  {
     "name":"Valandovo Municipality",
     "isoCode":"10",
     "countryCode":"MK",
     "latitude":"41.32119090",
     "longitude":"22.50066930"
  },
  {
     "name":"Vinica Municipality",
     "isoCode":"14",
     "countryCode":"MK",
     "latitude":"41.85710200",
     "longitude":"22.57218810"
  },
  {
     "name":"Zrnovci Municipality",
     "isoCode":"33",
     "countryCode":"MK",
     "latitude":"41.82282210",
     "longitude":"22.41722560"
  },
  {
     "name":"Karbinci",
     "isoCode":"37",
     "countryCode":"MK",
     "latitude":"41.81801590",
     "longitude":"22.23247580"
  },
  {
     "name":"Dolneni Municipality",
     "isoCode":"27",
     "countryCode":"MK",
     "latitude":"41.46409350",
     "longitude":"21.40374070"
  },
  {
     "name":"Čaška Municipality",
     "isoCode":"80",
     "countryCode":"MK",
     "latitude":"41.64743800",
     "longitude":"21.69141150"
  },
  {
     "name":"Kriva Palanka Municipality",
     "isoCode":"44",
     "countryCode":"MK",
     "latitude":"42.20584540",
     "longitude":"22.33079650"
  },
  {
     "name":"Jegunovce Municipality",
     "isoCode":"35",
     "countryCode":"MK",
     "latitude":"42.07407200",
     "longitude":"21.12204780"
  },
  {
     "name":"Bitola Municipality",
     "isoCode":"04",
     "countryCode":"MK",
     "latitude":"41.03633020",
     "longitude":"21.33219740"
  },
  {
     "name":"Šuto Orizari Municipality",
     "isoCode":"84",
     "countryCode":"MK",
     "latitude":"42.02904160",
     "longitude":"21.40970270"
  },
  {
     "name":"Karpoš Municipality",
     "isoCode":"38",
     "countryCode":"MK",
     "latitude":"41.97096610",
     "longitude":"21.39181680"
  },
  {
     "name":"Oslomej Municipality",
     "isoCode":"57",
     "countryCode":"MK",
     "latitude":"41.57583910",
     "longitude":"21.02219600"
  },
  {
     "name":"Kumanovo Municipality",
     "isoCode":"47",
     "countryCode":"MK",
     "latitude":"42.07326130",
     "longitude":"21.78531430"
  },
  {
     "name":"Greater Skopje",
     "isoCode":"85",
     "countryCode":"MK",
     "latitude":"41.99812940",
     "longitude":"21.42543550"
  },
  {
     "name":"Pehčevo Municipality",
     "isoCode":"60",
     "countryCode":"MK",
     "latitude":"41.77371320",
     "longitude":"22.88204890"
  },
  {
     "name":"Kisela Voda Municipality",
     "isoCode":"39",
     "countryCode":"MK",
     "latitude":"41.92748000",
     "longitude":"21.49317130"
  },
  {
     "name":"Demir Hisar Municipality",
     "isoCode":"25",
     "countryCode":"MK",
     "latitude":"41.22708300",
     "longitude":"21.14142260"
  },
  {
     "name":"Kičevo Municipality",
     "isoCode":"40",
     "countryCode":"MK",
     "latitude":"41.51291120",
     "longitude":"20.95250650"
  },
  {
     "name":"Vrapčište Municipality",
     "isoCode":"16",
     "countryCode":"MK",
     "latitude":"41.87911600",
     "longitude":"20.83145000"
  },
  {
     "name":"Ilinden Municipality",
     "isoCode":"34",
     "countryCode":"MK",
     "latitude":"41.99574430",
     "longitude":"21.56769750"
  },
  {
     "name":"Rosoman Municipality",
     "isoCode":"67",
     "countryCode":"MK",
     "latitude":"41.48480060",
     "longitude":"21.88070640"
  },
  {
     "name":"Makedonski Brod Municipality",
     "isoCode":"52",
     "countryCode":"MK",
     "latitude":"41.51330880",
     "longitude":"21.21743290"
  },
  {
     "name":"Gostivar Municipality",
     "isoCode":"19",
     "countryCode":"MK",
     "latitude":"41.80255410",
     "longitude":"20.90893780"
  },
  {
     "name":"Butel Municipality",
     "isoCode":"09",
     "countryCode":"MK",
     "latitude":"42.08950680",
     "longitude":"21.46336100"
  },
  {
     "name":"Delčevo Municipality",
     "isoCode":"23",
     "countryCode":"MK",
     "latitude":"41.96843870",
     "longitude":"22.76288300"
  },
  {
     "name":"Novaci Municipality",
     "isoCode":"55",
     "countryCode":"MK",
     "latitude":"41.04426610",
     "longitude":"21.45888940"
  },
  {
     "name":"Dojran Municipality",
     "isoCode":"26",
     "countryCode":"MK",
     "latitude":"41.24366720",
     "longitude":"22.69137640"
  },
  {
     "name":"Petrovec Municipality",
     "isoCode":"59",
     "countryCode":"MK",
     "latitude":"41.90298970",
     "longitude":"21.68992100"
  },
  {
     "name":"Ohrid Municipality",
     "isoCode":"58",
     "countryCode":"MK",
     "latitude":"41.06820880",
     "longitude":"20.75992660"
  },
  {
     "name":"Struga Municipality",
     "isoCode":"72",
     "countryCode":"MK",
     "latitude":"41.31737440",
     "longitude":"20.66456830"
  },
  {
     "name":"Makedonska Kamenica Municipality",
     "isoCode":"51",
     "countryCode":"MK",
     "latitude":"42.06946040",
     "longitude":"22.54834900"
  },
  {
     "name":"Centar Municipality",
     "isoCode":"77",
     "countryCode":"MK",
     "latitude":"41.96989340",
     "longitude":"21.42162670"
  },
  {
     "name":"Aerodrom Municipality",
     "isoCode":"01",
     "countryCode":"MK",
     "latitude":"41.94643630",
     "longitude":"21.49317130"
  },
  {
     "name":"Čair Municipality",
     "isoCode":"79",
     "countryCode":"MK",
     "latitude":"41.99303550",
     "longitude":"21.43653180"
  },
  {
     "name":"Lozovo Municipality",
     "isoCode":"49",
     "countryCode":"MK",
     "latitude":"41.78181390",
     "longitude":"21.90008270"
  },
  {
     "name":"Zelenikovo Municipality",
     "isoCode":"32",
     "countryCode":"MK",
     "latitude":"41.87338120",
     "longitude":"21.60272500"
  },
  {
     "name":"Gazi Baba Municipality",
     "isoCode":"17",
     "countryCode":"MK",
     "latitude":"42.01629610",
     "longitude":"21.49913340"
  },
  {
     "name":"Gradsko Municipality",
     "isoCode":"20",
     "countryCode":"MK",
     "latitude":"41.59916080",
     "longitude":"21.88070640"
  },
  {
     "name":"Radoviš Municipality",
     "isoCode":"64",
     "countryCode":"MK",
     "latitude":"41.64955310",
     "longitude":"22.47682870"
  },
  {
     "name":"Strumica Municipality",
     "isoCode":"73",
     "countryCode":"MK",
     "latitude":"41.43780040",
     "longitude":"22.64274280"
  },
  {
     "name":"Studeničani Municipality",
     "isoCode":"74",
     "countryCode":"MK",
     "latitude":"41.92256390",
     "longitude":"21.53639650"
  },
  {
     "name":"Resen Municipality",
     "isoCode":"66",
     "countryCode":"MK",
     "latitude":"40.93680930",
     "longitude":"21.04604070"
  },
  {
     "name":"Kavadarci Municipality",
     "isoCode":"36",
     "countryCode":"MK",
     "latitude":"41.28900680",
     "longitude":"21.99994350"
  },
  {
     "name":"Kruševo Municipality",
     "isoCode":"46",
     "countryCode":"MK",
     "latitude":"41.37693310",
     "longitude":"21.26065540"
  },
  {
     "name":"Čučer-Sandevo Municipality",
     "isoCode":"82",
     "countryCode":"MK",
     "latitude":"42.14839460",
     "longitude":"21.40374070"
  },
  {
     "name":"Berovo Municipality",
     "isoCode":"03",
     "countryCode":"MK",
     "latitude":"41.66619290",
     "longitude":"22.76288300"
  },
  {
     "name":"Rankovce Municipality",
     "isoCode":"65",
     "countryCode":"MK",
     "latitude":"42.18081410",
     "longitude":"22.09532970"
  },
  {
     "name":"Novo Selo Municipality",
     "isoCode":"56",
     "countryCode":"MK",
     "latitude":"41.43255800",
     "longitude":"22.88204890"
  },
  {
     "name":"Sopište Municipality",
     "isoCode":"70",
     "countryCode":"MK",
     "latitude":"41.86384920",
     "longitude":"21.30834990"
  },
  {
     "name":"Centar Župa Municipality",
     "isoCode":"78",
     "countryCode":"MK",
     "latitude":"41.46522590",
     "longitude":"20.59305480"
  },
  {
     "name":"Bogovinje Municipality",
     "isoCode":"06",
     "countryCode":"MK",
     "latitude":"41.92363710",
     "longitude":"20.91638870"
  },
  {
     "name":"Gjorče Petrov Municipality",
     "isoCode":"29",
     "countryCode":"MK",
     "latitude":"42.06063740",
     "longitude":"21.32027360"
  },
  {
     "name":"Kočani Municipality",
     "isoCode":"42",
     "countryCode":"MK",
     "latitude":"41.98583740",
     "longitude":"22.40530460"
  },
  {
     "name":"Požega-Slavonia County",
     "isoCode":"11",
     "countryCode":"HR",
     "latitude":"45.34178680",
     "longitude":"17.81143590"
  },
  {
     "name":"Split-Dalmatia County",
     "isoCode":"17",
     "countryCode":"HR",
     "latitude":"43.52403280",
     "longitude":"16.81783770"
  },
  {
     "name":"Međimurje County",
     "isoCode":"20",
     "countryCode":"HR",
     "latitude":"46.37666440",
     "longitude":"16.42132980"
  },
  {
     "name":"Zadar County",
     "isoCode":"13",
     "countryCode":"HR",
     "latitude":"44.14693900",
     "longitude":"15.61649430"
  },
  {
     "name":"Dubrovnik-Neretva County",
     "isoCode":"19",
     "countryCode":"HR",
     "latitude":"43.07665880",
     "longitude":"17.52684710"
  },
  {
     "name":"Krapina-Zagorje County",
     "isoCode":"02",
     "countryCode":"HR",
     "latitude":"46.10133930",
     "longitude":"15.88096930"
  },
  {
     "name":"Šibenik-Knin County",
     "isoCode":"15",
     "countryCode":"HR",
     "latitude":"43.92814850",
     "longitude":"16.10376940"
  },
  {
     "name":"Lika-Senj County",
     "isoCode":"09",
     "countryCode":"HR",
     "latitude":"44.61922180",
     "longitude":"15.47016080"
  },
  {
     "name":"Virovitica-Podravina County",
     "isoCode":"10",
     "countryCode":"HR",
     "latitude":"45.65579850",
     "longitude":"17.79324720"
  },
  {
     "name":"Sisak-Moslavina County",
     "isoCode":"03",
     "countryCode":"HR",
     "latitude":"45.38379260",
     "longitude":"16.53809940"
  },
  {
     "name":"Bjelovar-Bilogora County",
     "isoCode":"07",
     "countryCode":"HR",
     "latitude":"45.89879720",
     "longitude":"16.84230930"
  },
  {
     "name":"Primorje-Gorski Kotar County",
     "isoCode":"08",
     "countryCode":"HR",
     "latitude":"45.31739960",
     "longitude":"14.81674660"
  },
  {
     "name":"Zagreb County",
     "isoCode":"01",
     "countryCode":"HR",
     "latitude":"45.87066120",
     "longitude":"16.39549100"
  },
  {
     "name":"Brod-Posavina County",
     "isoCode":"12",
     "countryCode":"HR",
     "latitude":"45.26379510",
     "longitude":"17.32645620"
  },
  {
     "name":"Zagreb",
     "isoCode":"21",
     "countryCode":"HR",
     "latitude":"45.81501080",
     "longitude":"15.98191890"
  },
  {
     "name":"Varaždin County",
     "isoCode":"05",
     "countryCode":"HR",
     "latitude":"46.23174730",
     "longitude":"16.33605590"
  },
  {
     "name":"Osijek-Baranja County",
     "isoCode":"14",
     "countryCode":"HR",
     "latitude":"45.55764280",
     "longitude":"18.39421410"
  },
  {
     "name":"Vukovar-Syrmia County",
     "isoCode":"16",
     "countryCode":"HR",
     "latitude":"45.17735520",
     "longitude":"18.80535270"
  },
  {
     "name":"Koprivnica-Križevci County",
     "isoCode":"06",
     "countryCode":"HR",
     "latitude":"46.15689190",
     "longitude":"16.83908260"
  },
  {
     "name":"Istria County",
     "isoCode":"18",
     "countryCode":"HR",
     "latitude":"45.12864550",
     "longitude":"13.90154200"
  },
  {
     "name":"Kyrenia District",
     "isoCode":"06",
     "countryCode":"CY",
     "latitude":"35.29919400",
     "longitude":"33.23632460"
  },
  {
     "name":"Nicosia District",
     "isoCode":"01",
     "countryCode":"CY",
     "latitude":"35.18556590",
     "longitude":"33.38227640"
  },
  {
     "name":"Paphos District",
     "isoCode":"05",
     "countryCode":"CY",
     "latitude":"34.91645940",
     "longitude":"32.49200880"
  },
  {
     "name":"Larnaca District",
     "isoCode":"03",
     "countryCode":"CY",
     "latitude":"34.85072060",
     "longitude":"33.48319060"
  },
  {
     "name":"Limassol District",
     "isoCode":"02",
     "countryCode":"CY",
     "latitude":"34.70713010",
     "longitude":"33.02261740"
  },
  {
     "name":"Famagusta District",
     "isoCode":"04",
     "countryCode":"CY",
     "latitude":"35.28570230",
     "longitude":"33.84112880"
  },
  {
     "name":"Rangpur Division",
     "isoCode":"F",
     "countryCode":"BD",
     "latitude":"25.84833880",
     "longitude":"88.94138650"
  },
  {
     "name":"Cox's Bazar District",
     "isoCode":"11",
     "countryCode":"BD",
     "latitude":"21.56406260",
     "longitude":"92.02821290"
  },
  {
     "name":"Bandarban District",
     "isoCode":"01",
     "countryCode":"BD",
     "latitude":"21.83110020",
     "longitude":"92.36863210"
  },
  {
     "name":"Rajshahi Division",
     "isoCode":"E",
     "countryCode":"BD",
     "latitude":"24.71057760",
     "longitude":"88.94138650"
  },
  {
     "name":"Pabna District",
     "isoCode":"49",
     "countryCode":"BD",
     "latitude":"24.15850500",
     "longitude":"89.44807180"
  },
  {
     "name":"Sherpur District",
     "isoCode":"57",
     "countryCode":"BD",
     "latitude":"25.07462350",
     "longitude":"90.14949040"
  },
  {
     "name":"Bhola District",
     "isoCode":"07",
     "countryCode":"BD",
     "latitude":"22.17853150",
     "longitude":"90.71010230"
  },
  {
     "name":"Jessore District",
     "isoCode":"22",
     "countryCode":"BD",
     "latitude":"23.16340140",
     "longitude":"89.21816640"
  },
  {
     "name":"Mymensingh Division",
     "isoCode":"H",
     "countryCode":"BD",
     "latitude":"24.71362000",
     "longitude":"90.45023680"
  },
  {
     "name":"Rangpur District",
     "isoCode":"55",
     "countryCode":"BD",
     "latitude":"25.74679250",
     "longitude":"89.25083350"
  },
  {
     "name":"Dhaka Division",
     "isoCode":"C",
     "countryCode":"BD",
     "latitude":"23.95357420",
     "longitude":"90.14949880"
  },
  {
     "name":"Chapai Nawabganj District",
     "isoCode":"45",
     "countryCode":"BD",
     "latitude":"24.74131110",
     "longitude":"88.29120690"
  },
  {
     "name":"Faridpur District",
     "isoCode":"15",
     "countryCode":"BD",
     "latitude":"23.54239190",
     "longitude":"89.63089210"
  },
  {
     "name":"Comilla District",
     "isoCode":"08",
     "countryCode":"BD",
     "latitude":"23.45756670",
     "longitude":"91.18089960"
  },
  {
     "name":"Netrokona District",
     "isoCode":"41",
     "countryCode":"BD",
     "latitude":"24.81032840",
     "longitude":"90.86564150"
  },
  {
     "name":"Sylhet Division",
     "isoCode":"G",
     "countryCode":"BD",
     "latitude":"24.70498110",
     "longitude":"91.67606910"
  },
  {
     "name":"Mymensingh District",
     "isoCode":"34",
     "countryCode":"BD",
     "latitude":"24.75385750",
     "longitude":"90.40729190"
  },
  {
     "name":"Sylhet District",
     "isoCode":"60",
     "countryCode":"BD",
     "latitude":"24.89933570",
     "longitude":"91.87004730"
  },
  {
     "name":"Chandpur District",
     "isoCode":"09",
     "countryCode":"BD",
     "latitude":"23.25131480",
     "longitude":"90.85178460"
  },
  {
     "name":"Narail District",
     "isoCode":"43",
     "countryCode":"BD",
     "latitude":"23.11629290",
     "longitude":"89.58404040"
  },
  {
     "name":"Narayanganj District",
     "isoCode":"40",
     "countryCode":"BD",
     "latitude":"23.71466010",
     "longitude":"90.56360900"
  },
  {
     "name":"Dhaka District",
     "isoCode":"13",
     "countryCode":"BD",
     "latitude":"23.81051400",
     "longitude":"90.33718890"
  },
  {
     "name":"Nilphamari District",
     "isoCode":"46",
     "countryCode":"BD",
     "latitude":"25.84827980",
     "longitude":"88.94141340"
  },
  {
     "name":"Rajbari District",
     "isoCode":"53",
     "countryCode":"BD",
     "latitude":"23.71513400",
     "longitude":"89.58748190"
  },
  {
     "name":"Kushtia District",
     "isoCode":"30",
     "countryCode":"BD",
     "latitude":"23.89069950",
     "longitude":"89.10993680"
  },
  {
     "name":"Khulna Division",
     "isoCode":"D",
     "countryCode":"BD",
     "latitude":"22.80878160",
     "longitude":"89.24671910"
  },
  {
     "name":"Meherpur District",
     "isoCode":"39",
     "countryCode":"BD",
     "latitude":"23.80519910",
     "longitude":"88.67235780"
  },
  {
     "name":"Patuakhali District",
     "isoCode":"51",
     "countryCode":"BD",
     "latitude":"22.22486320",
     "longitude":"90.45475030"
  },
  {
     "name":"Jhalokati District",
     "isoCode":"25",
     "countryCode":"BD",
     "latitude":"22.57208000",
     "longitude":"90.18696440"
  },
  {
     "name":"Kishoreganj District",
     "isoCode":"26",
     "countryCode":"BD",
     "latitude":"24.42604570",
     "longitude":"90.98206680"
  },
  {
     "name":"Lalmonirhat District",
     "isoCode":"32",
     "countryCode":"BD",
     "latitude":"25.99233980",
     "longitude":"89.28472510"
  },
  {
     "name":"Sirajganj District",
     "isoCode":"59",
     "countryCode":"BD",
     "latitude":"24.31411150",
     "longitude":"89.56996150"
  },
  {
     "name":"Tangail District",
     "isoCode":"63",
     "countryCode":"BD",
     "latitude":"24.39174270",
     "longitude":"89.99482570"
  },
  {
     "name":"Dinajpur District",
     "isoCode":"14",
     "countryCode":"BD",
     "latitude":"25.62791230",
     "longitude":"88.63317580"
  },
  {
     "name":"Barguna District",
     "isoCode":"02",
     "countryCode":"BD",
     "latitude":"22.09529150",
     "longitude":"90.11206960"
  },
  {
     "name":"Chittagong District",
     "isoCode":"10",
     "countryCode":"BD",
     "latitude":"22.51501050",
     "longitude":"91.75388170"
  },
  {
     "name":"Khagrachari District",
     "isoCode":"29",
     "countryCode":"BD",
     "latitude":"23.13217510",
     "longitude":"91.94902100"
  },
  {
     "name":"Natore District",
     "isoCode":"44",
     "countryCode":"BD",
     "latitude":"24.41024300",
     "longitude":"89.00761770"
  },
  {
     "name":"Chuadanga District",
     "isoCode":"12",
     "countryCode":"BD",
     "latitude":"23.61605120",
     "longitude":"88.82630060"
  },
  {
     "name":"Jhenaidah District",
     "isoCode":"23",
     "countryCode":"BD",
     "latitude":"23.54498730",
     "longitude":"89.17260310"
  },
  {
     "name":"Munshiganj District",
     "isoCode":"35",
     "countryCode":"BD",
     "latitude":"23.49809310",
     "longitude":"90.41266210"
  },
  {
     "name":"Pirojpur District",
     "isoCode":"50",
     "countryCode":"BD",
     "latitude":"22.57907440",
     "longitude":"89.97592640"
  },
  {
     "name":"Gopalganj District",
     "isoCode":"17",
     "countryCode":"BD",
     "latitude":"26.48315840",
     "longitude":"84.43655000"
  },
  {
     "name":"Kurigram District",
     "isoCode":"28",
     "countryCode":"BD",
     "latitude":"25.80724140",
     "longitude":"89.62947460"
  },
  {
     "name":"Moulvibazar District",
     "isoCode":"38",
     "countryCode":"BD",
     "latitude":"24.30953440",
     "longitude":"91.73149030"
  },
  {
     "name":"Gaibandha District",
     "isoCode":"19",
     "countryCode":"BD",
     "latitude":"25.32969280",
     "longitude":"89.54296520"
  },
  {
     "name":"Bagerhat District",
     "isoCode":"05",
     "countryCode":"BD",
     "latitude":"22.66024360",
     "longitude":"89.78954780"
  },
  {
     "name":"Bogra District",
     "isoCode":"03",
     "countryCode":"BD",
     "latitude":"24.85104020",
     "longitude":"89.36972250"
  },
  {
     "name":"Gazipur District",
     "isoCode":"18",
     "countryCode":"BD",
     "latitude":"24.09581710",
     "longitude":"90.41251810"
  },
  {
     "name":"Satkhira District",
     "isoCode":"58",
     "countryCode":"BD",
     "latitude":"22.31548120",
     "longitude":"89.11145250"
  },
  {
     "name":"Panchagarh District",
     "isoCode":"52",
     "countryCode":"BD",
     "latitude":"26.27087050",
     "longitude":"88.59517510"
  },
  {
     "name":"Shariatpur District",
     "isoCode":"62",
     "countryCode":"BD",
     "latitude":"23.24232140",
     "longitude":"90.43477110"
  },
  {
     "name":"Bahadia",
     "isoCode":"33",
     "countryCode":"BD",
     "latitude":"23.78987120",
     "longitude":"90.16714830"
  },
  {
     "name":"Chittagong Division",
     "isoCode":"B",
     "countryCode":"BD",
     "latitude":"23.17931570",
     "longitude":"91.98815270"
  },
  {
     "name":"Thakurgaon District",
     "isoCode":"64",
     "countryCode":"BD",
     "latitude":"26.04183920",
     "longitude":"88.42826160"
  },
  {
     "name":"Habiganj District",
     "isoCode":"20",
     "countryCode":"BD",
     "latitude":"24.47712360",
     "longitude":"91.45065650"
  },
  {
     "name":"Joypurhat District",
     "isoCode":"24",
     "countryCode":"BD",
     "latitude":"25.09473490",
     "longitude":"89.09449370"
  },
  {
     "name":"Barisal Division",
     "isoCode":"A",
     "countryCode":"BD",
     "latitude":"22.38111310",
     "longitude":"90.33718890"
  },
  {
     "name":"Jamalpur District",
     "isoCode":"21",
     "countryCode":"BD",
     "latitude":"25.08309260",
     "longitude":"89.78532180"
  },
  {
     "name":"Rangamati Hill District",
     "isoCode":"56",
     "countryCode":"BD",
     "latitude":"22.73241730",
     "longitude":"92.29851340"
  },
  {
     "name":"Brahmanbaria District",
     "isoCode":"04",
     "countryCode":"BD",
     "latitude":"23.96081810",
     "longitude":"91.11150140"
  },
  {
     "name":"Khulna District",
     "isoCode":"27",
     "countryCode":"BD",
     "latitude":"22.67377350",
     "longitude":"89.39665810"
  },
  {
     "name":"Sunamganj District",
     "isoCode":"61",
     "countryCode":"BD",
     "latitude":"25.07145350",
     "longitude":"91.39916270"
  },
  {
     "name":"Rajshahi District",
     "isoCode":"54",
     "countryCode":"BD",
     "latitude":"24.37330870",
     "longitude":"88.60487160"
  },
  {
     "name":"Naogaon District",
     "isoCode":"48",
     "countryCode":"BD",
     "latitude":"24.91315970",
     "longitude":"88.75309520"
  },
  {
     "name":"Noakhali District",
     "isoCode":"47",
     "countryCode":"BD",
     "latitude":"22.87237890",
     "longitude":"91.09731840"
  },
  {
     "name":"Feni District",
     "isoCode":"16",
     "countryCode":"BD",
     "latitude":"22.94087840",
     "longitude":"91.40666460"
  },
  {
     "name":"Madaripur District",
     "isoCode":"36",
     "countryCode":"BD",
     "latitude":"23.23933460",
     "longitude":"90.18696440"
  },
  {
     "name":"Barisal District",
     "isoCode":"06",
     "countryCode":"BD",
     "latitude":"22.70220980",
     "longitude":"90.36963160"
  },
  {
     "name":"Lakshmipur District",
     "isoCode":"31",
     "countryCode":"BD",
     "latitude":"22.94467440",
     "longitude":"90.82819070"
  },
  {
     "name":"Okayama Prefecture",
     "isoCode":"33",
     "countryCode":"JP",
     "latitude":"34.89634070",
     "longitude":"133.63753140"
  },
  {
     "name":"Chiba Prefecture",
     "isoCode":"12",
     "countryCode":"JP",
     "latitude":"35.33541550",
     "longitude":"140.18325160"
  },
  {
     "name":"Ōita Prefecture",
     "isoCode":"44",
     "countryCode":"JP",
     "latitude":"33.15892990",
     "longitude":"131.36111210"
  },
  {
     "name":"Tokyo",
     "isoCode":"13",
     "countryCode":"JP",
     "latitude":"35.67619190",
     "longitude":"139.65031060"
  },
  {
     "name":"Nara Prefecture",
     "isoCode":"29",
     "countryCode":"JP",
     "latitude":"34.29755280",
     "longitude":"135.82797340"
  },
  {
     "name":"Shizuoka Prefecture",
     "isoCode":"22",
     "countryCode":"JP",
     "latitude":"35.09293970",
     "longitude":"138.31902760"
  },
  {
     "name":"Shimane Prefecture",
     "isoCode":"32",
     "countryCode":"JP",
     "latitude":"35.12440940",
     "longitude":"132.62934460"
  },
  {
     "name":"Aichi Prefecture",
     "isoCode":"23",
     "countryCode":"JP",
     "latitude":"35.01825050",
     "longitude":"137.29238930"
  },
  {
     "name":"Hiroshima Prefecture",
     "isoCode":"34",
     "countryCode":"JP",
     "latitude":"34.88234080",
     "longitude":"133.01948970"
  },
  {
     "name":"Akita Prefecture",
     "isoCode":"05",
     "countryCode":"JP",
     "latitude":"40.13762930",
     "longitude":"140.33434100"
  },
  {
     "name":"Ishikawa Prefecture",
     "isoCode":"17",
     "countryCode":"JP",
     "latitude":"36.32603170",
     "longitude":"136.52896530"
  },
  {
     "name":"Hyōgo Prefecture",
     "isoCode":"28",
     "countryCode":"JP",
     "latitude":"34.85795180",
     "longitude":"134.54537870"
  },
  {
     "name":"Hokkaidō Prefecture",
     "isoCode":"01",
     "countryCode":"JP",
     "latitude":"43.22032660",
     "longitude":"142.86347370"
  },
  {
     "name":"Mie Prefecture",
     "isoCode":"24",
     "countryCode":"JP",
     "latitude":"33.81439010",
     "longitude":"136.04870470"
  },
  {
     "name":"Kyōto Prefecture",
     "isoCode":"26",
     "countryCode":"JP",
     "latitude":"35.15666090",
     "longitude":"135.52519820"
  },
  {
     "name":"Yamaguchi Prefecture",
     "isoCode":"35",
     "countryCode":"JP",
     "latitude":"34.27967690",
     "longitude":"131.52127420"
  },
  {
     "name":"Tokushima Prefecture",
     "isoCode":"36",
     "countryCode":"JP",
     "latitude":"33.94196550",
     "longitude":"134.32365570"
  },
  {
     "name":"Yamagata Prefecture",
     "isoCode":"06",
     "countryCode":"JP",
     "latitude":"38.53705640",
     "longitude":"140.14351980"
  },
  {
     "name":"Toyama Prefecture",
     "isoCode":"16",
     "countryCode":"JP",
     "latitude":"36.69582660",
     "longitude":"137.21370710"
  },
  {
     "name":"Aomori Prefecture",
     "isoCode":"02",
     "countryCode":"JP",
     "latitude":"40.76570770",
     "longitude":"140.91758790"
  },
  {
     "name":"Kagoshima Prefecture",
     "isoCode":"46",
     "countryCode":"JP",
     "latitude":"31.39119580",
     "longitude":"130.87785860"
  },
  {
     "name":"Niigata Prefecture",
     "isoCode":"15",
     "countryCode":"JP",
     "latitude":"37.51783860",
     "longitude":"138.92697940"
  },
  {
     "name":"Kanagawa Prefecture",
     "isoCode":"14",
     "countryCode":"JP",
     "latitude":"35.49135350",
     "longitude":"139.28414300"
  },
  {
     "name":"Nagano Prefecture",
     "isoCode":"20",
     "countryCode":"JP",
     "latitude":"36.15439410",
     "longitude":"137.92182040"
  },
  {
     "name":"Wakayama Prefecture",
     "isoCode":"30",
     "countryCode":"JP",
     "latitude":"33.94809140",
     "longitude":"135.37453580"
  },
  {
     "name":"Shiga Prefecture",
     "isoCode":"25",
     "countryCode":"JP",
     "latitude":"35.32920140",
     "longitude":"136.05632120"
  },
  {
     "name":"Kumamoto Prefecture",
     "isoCode":"43",
     "countryCode":"JP",
     "latitude":"32.85944270",
     "longitude":"130.79691490"
  },
  {
     "name":"Fukushima Prefecture",
     "isoCode":"07",
     "countryCode":"JP",
     "latitude":"37.38343730",
     "longitude":"140.18325160"
  },
  {
     "name":"Fukui Prefecture",
     "isoCode":"18",
     "countryCode":"JP",
     "latitude":"35.89622700",
     "longitude":"136.21115790"
  },
  {
     "name":"Nagasaki Prefecture",
     "isoCode":"42",
     "countryCode":"JP",
     "latitude":"33.24885250",
     "longitude":"129.69309120"
  },
  {
     "name":"Tottori Prefecture",
     "isoCode":"31",
     "countryCode":"JP",
     "latitude":"35.35731610",
     "longitude":"133.40666180"
  },
  {
     "name":"Ibaraki Prefecture",
     "isoCode":"08",
     "countryCode":"JP",
     "latitude":"36.21935710",
     "longitude":"140.18325160"
  },
  {
     "name":"Yamanashi Prefecture",
     "isoCode":"19",
     "countryCode":"JP",
     "latitude":"35.66351130",
     "longitude":"138.63888790"
  },
  {
     "name":"Okinawa Prefecture",
     "isoCode":"47",
     "countryCode":"JP",
     "latitude":"26.12019110",
     "longitude":"127.70250120"
  },
  {
     "name":"Tochigi Prefecture",
     "isoCode":"09",
     "countryCode":"JP",
     "latitude":"36.67147390",
     "longitude":"139.85472660"
  },
  {
     "name":"Miyazaki Prefecture",
     "isoCode":"45",
     "countryCode":"JP",
     "latitude":"32.60360220",
     "longitude":"131.44125100"
  },
  {
     "name":"Iwate Prefecture",
     "isoCode":"03",
     "countryCode":"JP",
     "latitude":"39.58329890",
     "longitude":"141.25345740"
  },
  {
     "name":"Miyagi Prefecture",
     "isoCode":"04",
     "countryCode":"JP",
     "latitude":"38.63061200",
     "longitude":"141.11930480"
  },
  {
     "name":"Gifu Prefecture",
     "isoCode":"21",
     "countryCode":"JP",
     "latitude":"35.74374910",
     "longitude":"136.98051030"
  },
  {
     "name":"Ōsaka Prefecture",
     "isoCode":"27",
     "countryCode":"JP",
     "latitude":"34.64133150",
     "longitude":"135.56293940"
  },
  {
     "name":"Saitama Prefecture",
     "isoCode":"11",
     "countryCode":"JP",
     "latitude":"35.99625130",
     "longitude":"139.44660050"
  },
  {
     "name":"Fukuoka Prefecture",
     "isoCode":"40",
     "countryCode":"JP",
     "latitude":"33.56625590",
     "longitude":"130.71585700"
  },
  {
     "name":"Gunma Prefecture",
     "isoCode":"10",
     "countryCode":"JP",
     "latitude":"36.56053880",
     "longitude":"138.87999720"
  },
  {
     "name":"Saga Prefecture",
     "isoCode":"41",
     "countryCode":"JP",
     "latitude":"33.30783710",
     "longitude":"130.22712430"
  },
  {
     "name":"Kagawa Prefecture",
     "isoCode":"37",
     "countryCode":"JP",
     "latitude":"34.22259150",
     "longitude":"134.01991520"
  },
  {
     "name":"Ehime Prefecture",
     "isoCode":"38",
     "countryCode":"JP",
     "latitude":"33.60253060",
     "longitude":"132.78575830"
  },
  {
     "name":"Ontario",
     "isoCode":"ON",
     "countryCode":"CA",
     "latitude":"51.25377500",
     "longitude":"-85.32321400"
  },
  {
     "name":"Manitoba",
     "isoCode":"MB",
     "countryCode":"CA",
     "latitude":"53.76086080",
     "longitude":"-98.81387620"
  },
  {
     "name":"New Brunswick",
     "isoCode":"NB",
     "countryCode":"CA",
     "latitude":"46.56531630",
     "longitude":"-66.46191640"
  },
  {
     "name":"Yukon",
     "isoCode":"YT",
     "countryCode":"CA",
     "latitude":"35.50672150",
     "longitude":"-97.76254410"
  },
  {
     "name":"Saskatchewan",
     "isoCode":"SK",
     "countryCode":"CA",
     "latitude":"52.93991590",
     "longitude":"-106.45086390"
  },
  {
     "name":"Prince Edward Island",
     "isoCode":"PE",
     "countryCode":"CA",
     "latitude":"46.51071200",
     "longitude":"-63.41681360"
  },
  {
     "name":"Alberta",
     "isoCode":"AB",
     "countryCode":"CA",
     "latitude":"53.93327060",
     "longitude":"-116.57650350"
  },
  {
     "name":"Quebec",
     "isoCode":"QC",
     "countryCode":"CA",
     "latitude":"52.93991590",
     "longitude":"-73.54913610"
  },
  {
     "name":"Nova Scotia",
     "isoCode":"NS",
     "countryCode":"CA",
     "latitude":"44.68198660",
     "longitude":"-63.74431100"
  },
  {
     "name":"British Columbia",
     "isoCode":"BC",
     "countryCode":"CA",
     "latitude":"53.72666830",
     "longitude":"-127.64762050"
  },
  {
     "name":"Nunavut",
     "isoCode":"NU",
     "countryCode":"CA",
     "latitude":"70.29977110",
     "longitude":"-83.10757700"
  },
  {
     "name":"Newfoundland and Labrador",
     "isoCode":"NL",
     "countryCode":"CA",
     "latitude":"53.13550910",
     "longitude":"-57.66043640"
  },
  {
     "name":"Northwest Territories",
     "isoCode":"NT",
     "countryCode":"CA",
     "latitude":"64.82554410",
     "longitude":"-124.84573340"
  },
  {
     "name":"White Nile",
     "isoCode":"NW",
     "countryCode":"SD",
     "latitude":"9.33215160",
     "longitude":"31.46153000"
  },
  {
     "name":"Red Sea",
     "isoCode":"RS",
     "countryCode":"SD",
     "latitude":"20.28023200",
     "longitude":"38.51257300"
  },
  {
     "name":"Khartoum",
     "isoCode":"KH",
     "countryCode":"SD",
     "latitude":"15.50065440",
     "longitude":"32.55989940"
  },
  {
     "name":"Sennar",
     "isoCode":"SI",
     "countryCode":"SD",
     "latitude":"13.56746900",
     "longitude":"33.56720450"
  },
  {
     "name":"South Kordofan",
     "isoCode":"KS",
     "countryCode":"SD",
     "latitude":"11.19901920",
     "longitude":"29.41793240"
  },
  {
     "name":"Kassala",
     "isoCode":"KA",
     "countryCode":"SD",
     "latitude":"15.45813320",
     "longitude":"36.40396290"
  },
  {
     "name":"Al Jazirah",
     "isoCode":"GZ",
     "countryCode":"SD",
     "latitude":"14.88596110",
     "longitude":"33.43835300"
  },
  {
     "name":"Al Qadarif",
     "isoCode":"GD",
     "countryCode":"SD",
     "latitude":"14.02430700",
     "longitude":"35.36856790"
  },
  {
     "name":"Blue Nile",
     "isoCode":"NB",
     "countryCode":"SD",
     "latitude":"47.59867300",
     "longitude":"-122.33441900"
  },
  {
     "name":"West Darfur",
     "isoCode":"DW",
     "countryCode":"SD",
     "latitude":"12.84635610",
     "longitude":"23.00119890"
  },
  {
     "name":"West Kordofan",
     "isoCode":"GK",
     "countryCode":"SD",
     "latitude":"11.19901920",
     "longitude":"29.41793240"
  },
  {
     "name":"North Darfur",
     "isoCode":"DN",
     "countryCode":"SD",
     "latitude":"15.76619690",
     "longitude":"24.90422080"
  },
  {
     "name":"River Nile",
     "isoCode":"NR",
     "countryCode":"SD",
     "latitude":"23.97275950",
     "longitude":"32.87492060"
  },
  {
     "name":"East Darfur",
     "isoCode":"DE",
     "countryCode":"SD",
     "latitude":"14.37827470",
     "longitude":"24.90422080"
  },
  {
     "name":"North Kordofan",
     "isoCode":"KN",
     "countryCode":"SD",
     "latitude":"13.83064410",
     "longitude":"29.41793240"
  },
  {
     "name":"South Darfur",
     "isoCode":"DS",
     "countryCode":"SD",
     "latitude":"11.64886390",
     "longitude":"24.90422080"
  },
  {
     "name":"Northern",
     "isoCode":"NO",
     "countryCode":"SD",
     "latitude":"38.06381700",
     "longitude":"-84.46286480"
  },
  {
     "name":"Central Darfur",
     "isoCode":"DC",
     "countryCode":"SD",
     "latitude":"14.37827470",
     "longitude":"24.90422080"
  },
  {
     "name":"Khelvachauri Municipality",
     "isoCode":"29",
     "countryCode":"GE",
     "latitude":"41.58019260",
     "longitude":"41.66107420"
  },
  {
     "name":"Senaki Municipality",
     "isoCode":"50",
     "countryCode":"GE",
     "latitude":"42.26963600",
     "longitude":"42.06568960"
  },
  {
     "name":"Tbilisi",
     "isoCode":"TB",
     "countryCode":"GE",
     "latitude":"41.71513770",
     "longitude":"44.82709600"
  },
  {
     "name":"Adjara",
     "isoCode":"AJ",
     "countryCode":"GE",
     "latitude":"41.60056260",
     "longitude":"42.06883830"
  },
  {
     "name":"Autonomous Republic of Abkhazia",
     "isoCode":"AB",
     "countryCode":"GE",
     "latitude":"43.00155440",
     "longitude":"41.02340700"
  },
  {
     "name":"Mtskheta-Mtianeti",
     "isoCode":"MM",
     "countryCode":"GE",
     "latitude":"42.16821850",
     "longitude":"44.65060580"
  },
  {
     "name":"Shida Kartli",
     "isoCode":"SK",
     "countryCode":"GE",
     "latitude":"42.07569440",
     "longitude":"43.95404620"
  },
  {
     "name":"Kvemo Kartli",
     "isoCode":"KK",
     "countryCode":"GE",
     "latitude":"41.47918330",
     "longitude":"44.65604510"
  },
  {
     "name":"Imereti",
     "isoCode":"IM",
     "countryCode":"GE",
     "latitude":"42.23010800",
     "longitude":"42.90086640"
  },
  {
     "name":"Samtskhe-Javakheti",
     "isoCode":"SJ",
     "countryCode":"GE",
     "latitude":"41.54792960",
     "longitude":"43.27764000"
  },
  {
     "name":"Guria",
     "isoCode":"GU",
     "countryCode":"GE",
     "latitude":"41.94427360",
     "longitude":"42.04580910"
  },
  {
     "name":"Samegrelo-Zemo Svaneti",
     "isoCode":"SZ",
     "countryCode":"GE",
     "latitude":"42.73522470",
     "longitude":"42.16893620"
  },
  {
     "name":"Racha-Lechkhumi and Kvemo Svaneti",
     "isoCode":"RL",
     "countryCode":"GE",
     "latitude":"42.67188730",
     "longitude":"43.05628360"
  },
  {
     "name":"Kakheti",
     "isoCode":"KA",
     "countryCode":"GE",
     "latitude":"41.64816020",
     "longitude":"45.69055540"
  },
  {
     "name":"Northern Province",
     "isoCode":"N",
     "countryCode":"SL",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Southern Province",
     "isoCode":"S",
     "countryCode":"SL",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Western Area",
     "isoCode":"W",
     "countryCode":"SL",
     "latitude":"40.25459690",
     "longitude":"-80.24554440"
  },
  {
     "name":"Eastern Province",
     "isoCode":"E",
     "countryCode":"SL",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Hiran",
     "isoCode":"HI",
     "countryCode":"SO",
     "latitude":"4.32101500",
     "longitude":"45.29938620"
  },
  {
     "name":"Mudug",
     "isoCode":"MU",
     "countryCode":"SO",
     "latitude":"6.56567260",
     "longitude":"47.76375650"
  },
  {
     "name":"Bakool",
     "isoCode":"BK",
     "countryCode":"SO",
     "latitude":"4.36572210",
     "longitude":"44.09603110"
  },
  {
     "name":"Galguduud",
     "isoCode":"GA",
     "countryCode":"SO",
     "latitude":"5.18501280",
     "longitude":"46.82528380"
  },
  {
     "name":"Sanaag Region",
     "isoCode":"SA",
     "countryCode":"SO",
     "latitude":"10.39382180",
     "longitude":"47.76375650"
  },
  {
     "name":"Nugal",
     "isoCode":"NU",
     "countryCode":"SO",
     "latitude":"43.27938610",
     "longitude":"17.03392050"
  },
  {
     "name":"Lower Shebelle",
     "isoCode":"SH",
     "countryCode":"SO",
     "latitude":"1.87664580",
     "longitude":"44.24790150"
  },
  {
     "name":"Middle Juba",
     "isoCode":"JD",
     "countryCode":"SO",
     "latitude":"2.07804880",
     "longitude":"41.60118140"
  },
  {
     "name":"Middle Shebelle",
     "isoCode":"SD",
     "countryCode":"SO",
     "latitude":"2.92502470",
     "longitude":"45.90396890"
  },
  {
     "name":"Lower Juba",
     "isoCode":"JH",
     "countryCode":"SO",
     "latitude":"0.22402100",
     "longitude":"41.60118140"
  },
  {
     "name":"Awdal Region",
     "isoCode":"AW",
     "countryCode":"SO",
     "latitude":"10.63342850",
     "longitude":"43.32946600"
  },
  {
     "name":"Bay",
     "isoCode":"BY",
     "countryCode":"SO",
     "latitude":"37.03655340",
     "longitude":"-95.61747670"
  },
  {
     "name":"Banaadir",
     "isoCode":"BN",
     "countryCode":"SO",
     "latitude":"2.11873750",
     "longitude":"45.33694590"
  },
  {
     "name":"Gedo",
     "isoCode":"GE",
     "countryCode":"SO",
     "latitude":"3.50392270",
     "longitude":"42.23624350"
  },
  {
     "name":"Togdheer Region",
     "isoCode":"TO",
     "countryCode":"SO",
     "latitude":"9.44605870",
     "longitude":"45.29938620"
  },
  {
     "name":"Bari",
     "isoCode":"BR",
     "countryCode":"SO",
     "latitude":"41.11714320",
     "longitude":"16.87187150"
  },
  {
     "name":"Northern Cape",
     "isoCode":"NC",
     "countryCode":"ZA",
     "latitude":"-29.04668080",
     "longitude":"21.85685860"
  },
  {
     "name":"Free State",
     "isoCode":"FS",
     "countryCode":"ZA",
     "latitude":"37.68585250",
     "longitude":"-97.28112560"
  },
  {
     "name":"Limpopo",
     "isoCode":"LP",
     "countryCode":"ZA",
     "latitude":"-23.40129460",
     "longitude":"29.41793240"
  },
  {
     "name":"North West",
     "isoCode":"NW",
     "countryCode":"ZA",
     "latitude":"32.75885200",
     "longitude":"-97.32880600"
  },
  {
     "name":"KwaZulu-Natal",
     "isoCode":"KZN",
     "countryCode":"ZA",
     "latitude":"-28.53055390",
     "longitude":"30.89582420"
  },
  {
     "name":"Gauteng",
     "isoCode":"GP",
     "countryCode":"ZA",
     "latitude":"-26.27075930",
     "longitude":"28.11226790"
  },
  {
     "name":"Mpumalanga",
     "isoCode":"MP",
     "countryCode":"ZA",
     "latitude":"-25.56533600",
     "longitude":"30.52790960"
  },
  {
     "name":"Eastern Cape",
     "isoCode":"EC",
     "countryCode":"ZA",
     "latitude":"-32.29684020",
     "longitude":"26.41938900"
  },
  {
     "name":"Western Cape",
     "isoCode":"WC",
     "countryCode":"ZA",
     "latitude":"-33.22779180",
     "longitude":"21.85685860"
  },
  {
     "name":"Chontales Department",
     "isoCode":"CO",
     "countryCode":"NI",
     "latitude":"11.93947170",
     "longitude":"-85.18940450"
  },
  {
     "name":"Managua Department",
     "isoCode":"MN",
     "countryCode":"NI",
     "latitude":"12.13916990",
     "longitude":"-86.33767610"
  },
  {
     "name":"Rivas Department",
     "isoCode":"RI",
     "countryCode":"NI",
     "latitude":"11.40234900",
     "longitude":"-85.68457800"
  },
  {
     "name":"Granada Department",
     "isoCode":"GR",
     "countryCode":"NI",
     "latitude":"11.93440730",
     "longitude":"-85.95600050"
  },
  {
     "name":"León Department",
     "isoCode":"LE",
     "countryCode":"NI",
     "latitude":"12.50920370",
     "longitude":"-86.66110830"
  },
  {
     "name":"Estelí Department",
     "isoCode":"ES",
     "countryCode":"NI",
     "latitude":"13.08511390",
     "longitude":"-86.36301970"
  },
  {
     "name":"Boaco Department",
     "isoCode":"BO",
     "countryCode":"NI",
     "latitude":"12.46928400",
     "longitude":"-85.66146820"
  },
  {
     "name":"Matagalpa Department",
     "isoCode":"MT",
     "countryCode":"NI",
     "latitude":"12.94984360",
     "longitude":"-85.43755740"
  },
  {
     "name":"Madriz Department",
     "isoCode":"MD",
     "countryCode":"NI",
     "latitude":"13.47260050",
     "longitude":"-86.45920910"
  },
  {
     "name":"Río San Juan Department",
     "isoCode":"SJ",
     "countryCode":"NI",
     "latitude":"11.47816100",
     "longitude":"-84.77333250"
  },
  {
     "name":"Carazo Department",
     "isoCode":"CA",
     "countryCode":"NI",
     "latitude":"11.72747290",
     "longitude":"-86.21584970"
  },
  {
     "name":"North Caribbean Coast Autonomous Region",
     "isoCode":"AN",
     "countryCode":"NI",
     "latitude":"13.83944560",
     "longitude":"-83.93208060"
  },
  {
     "name":"South Caribbean Coast Autonomous Region",
     "isoCode":"AS",
     "countryCode":"NI",
     "latitude":"12.19185020",
     "longitude":"-84.10128610"
  },
  {
     "name":"Masaya Department",
     "isoCode":"MS",
     "countryCode":"NI",
     "latitude":"11.97593280",
     "longitude":"-86.07334980"
  },
  {
     "name":"Chinandega Department",
     "isoCode":"CI",
     "countryCode":"NI",
     "latitude":"12.88200620",
     "longitude":"-87.14228950"
  },
  {
     "name":"Jinotega Department",
     "isoCode":"JI",
     "countryCode":"NI",
     "latitude":"13.08839070",
     "longitude":"-85.99939970"
  },
  {
     "name":"Karak Governorate",
     "isoCode":"KA",
     "countryCode":"JO",
     "latitude":"31.18535270",
     "longitude":"35.70476820"
  },
  {
     "name":"Tafilah Governorate",
     "isoCode":"AT",
     "countryCode":"JO",
     "latitude":"30.83380630",
     "longitude":"35.61605130"
  },
  {
     "name":"Madaba Governorate",
     "isoCode":"MD",
     "countryCode":"JO",
     "latitude":"31.71960970",
     "longitude":"35.79327540"
  },
  {
     "name":"Aqaba Governorate",
     "isoCode":"AQ",
     "countryCode":"JO",
     "latitude":"29.53208600",
     "longitude":"35.00628210"
  },
  {
     "name":"Irbid Governorate",
     "isoCode":"IR",
     "countryCode":"JO",
     "latitude":"32.55696360",
     "longitude":"35.84789650"
  },
  {
     "name":"Balqa Governorate",
     "isoCode":"BA",
     "countryCode":"JO",
     "latitude":"32.03668060",
     "longitude":"35.72884800"
  },
  {
     "name":"Mafraq Governorate",
     "isoCode":"MA",
     "countryCode":"JO",
     "latitude":"32.34169230",
     "longitude":"36.20201750"
  },
  {
     "name":"Ajloun Governorate",
     "isoCode":"AJ",
     "countryCode":"JO",
     "latitude":"32.33255840",
     "longitude":"35.75168440"
  },
  {
     "name":"Ma'an Governorate",
     "isoCode":"MN",
     "countryCode":"JO",
     "latitude":"30.19267890",
     "longitude":"35.72493190"
  },
  {
     "name":"Amman Governorate",
     "isoCode":"AM",
     "countryCode":"JO",
     "latitude":"31.94536330",
     "longitude":"35.92838950"
  },
  {
     "name":"Jerash Governorate",
     "isoCode":"JA",
     "countryCode":"JO",
     "latitude":"32.27472370",
     "longitude":"35.89609540"
  },
  {
     "name":"Zarqa Governorate",
     "isoCode":"AZ",
     "countryCode":"JO",
     "latitude":"32.06085050",
     "longitude":"36.09421210"
  },
  {
     "name":"Manzini District",
     "isoCode":"MA",
     "countryCode":"SZ",
     "latitude":"-26.50819990",
     "longitude":"31.37131640"
  },
  {
     "name":"Hhohho District",
     "isoCode":"HH",
     "countryCode":"SZ",
     "latitude":"-26.13656620",
     "longitude":"31.35416310"
  },
  {
     "name":"Lubombo District",
     "isoCode":"LU",
     "countryCode":"SZ",
     "latitude":"-26.78517730",
     "longitude":"31.81070790"
  },
  {
     "name":"Shiselweni District",
     "isoCode":"SH",
     "countryCode":"SZ",
     "latitude":"-26.98275770",
     "longitude":"31.35416310"
  },
  {
     "name":"Al Jahra Governorate",
     "isoCode":"JA",
     "countryCode":"KW",
     "latitude":"29.99318310",
     "longitude":"47.76347310"
  },
  {
     "name":"Hawalli Governorate",
     "isoCode":"HA",
     "countryCode":"KW",
     "latitude":"29.30567160",
     "longitude":"48.03076130"
  },
  {
     "name":"Mubarak Al-Kabeer Governorate",
     "isoCode":"MU",
     "countryCode":"KW",
     "latitude":"29.21224000",
     "longitude":"48.06051080"
  },
  {
     "name":"Al Farwaniyah Governorate",
     "isoCode":"FA",
     "countryCode":"KW",
     "latitude":"29.27335700",
     "longitude":"47.94001540"
  },
  {
     "name":"Capital Governorate",
     "isoCode":"KU",
     "countryCode":"KW",
     "latitude":"26.22851610",
     "longitude":"50.58604970"
  },
  {
     "name":"Al Ahmadi Governorate",
     "isoCode":"AH",
     "countryCode":"KW",
     "latitude":"28.57451250",
     "longitude":"48.10247430"
  },
  {
     "name":"Luang Prabang Province",
     "isoCode":"LP",
     "countryCode":"LA",
     "latitude":"20.06562290",
     "longitude":"102.62162110"
  },
  {
     "name":"Vientiane Prefecture",
     "isoCode":"VT",
     "countryCode":"LA",
     "latitude":"18.11054100",
     "longitude":"102.52980280"
  },
  {
     "name":"Vientiane Province",
     "isoCode":"VI",
     "countryCode":"LA",
     "latitude":"18.57050630",
     "longitude":"102.62162110"
  },
  {
     "name":"Salavan Province",
     "isoCode":"SL",
     "countryCode":"LA",
     "latitude":"15.81710730",
     "longitude":"106.25221430"
  },
  {
     "name":"Attapeu Province",
     "isoCode":"AT",
     "countryCode":"LA",
     "latitude":"14.93634000",
     "longitude":"107.10119310"
  },
  {
     "name":"Xaisomboun Province",
     "isoCode":"XS",
     "countryCode":"LA",
     "latitude":"18.43629240",
     "longitude":"104.47233010"
  },
  {
     "name":"Sekong Province",
     "isoCode":"XE",
     "countryCode":"LA",
     "latitude":"15.57674460",
     "longitude":"107.00670310"
  },
  {
     "name":"Bolikhamsai Province",
     "isoCode":"BL",
     "countryCode":"LA",
     "latitude":"18.43629240",
     "longitude":"104.47233010"
  },
  {
     "name":"Khammouane Province",
     "isoCode":"KH",
     "countryCode":"LA",
     "latitude":"17.63840660",
     "longitude":"105.21948080"
  },
  {
     "name":"Phongsaly Province",
     "isoCode":"PH",
     "countryCode":"LA",
     "latitude":"21.59193770",
     "longitude":"102.25479190"
  },
  {
     "name":"Oudomxay Province",
     "isoCode":"OU",
     "countryCode":"LA",
     "latitude":"20.49219290",
     "longitude":"101.88917210"
  },
  {
     "name":"Houaphanh Province",
     "isoCode":"HO",
     "countryCode":"LA",
     "latitude":"20.32541750",
     "longitude":"104.10013260"
  },
  {
     "name":"Savannakhet Province",
     "isoCode":"SV",
     "countryCode":"LA",
     "latitude":"16.50653810",
     "longitude":"105.59433880"
  },
  {
     "name":"Bokeo Province",
     "isoCode":"BK",
     "countryCode":"LA",
     "latitude":"20.28726620",
     "longitude":"100.70978670"
  },
  {
     "name":"Luang Namtha Province",
     "isoCode":"LM",
     "countryCode":"LA",
     "latitude":"20.91701870",
     "longitude":"101.16173560"
  },
  {
     "name":"Sainyabuli Province",
     "isoCode":"XA",
     "countryCode":"LA",
     "latitude":"19.39078860",
     "longitude":"101.52480550"
  },
  {
     "name":"Xaisomboun",
     "isoCode":"XN",
     "countryCode":"LA",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Xiangkhouang Province",
     "isoCode":"XI",
     "countryCode":"LA",
     "latitude":"19.60930030",
     "longitude":"103.72891670"
  },
  {
     "name":"Champasak Province",
     "isoCode":"CH",
     "countryCode":"LA",
     "latitude":"14.65786640",
     "longitude":"105.96998780"
  },
  {
     "name":"Talas Region",
     "isoCode":"T",
     "countryCode":"KG",
     "latitude":"42.28673390",
     "longitude":"72.52048270"
  },
  {
     "name":"Batken Region",
     "isoCode":"B",
     "countryCode":"KG",
     "latitude":"39.97214250",
     "longitude":"69.85974060"
  },
  {
     "name":"Naryn Region",
     "isoCode":"N",
     "countryCode":"KG",
     "latitude":"41.29432270",
     "longitude":"75.34121790"
  },
  {
     "name":"Jalal-Abad Region",
     "isoCode":"J",
     "countryCode":"KG",
     "latitude":"41.10680800",
     "longitude":"72.89880690"
  },
  {
     "name":"Bishkek",
     "isoCode":"GB",
     "countryCode":"KG",
     "latitude":"42.87462120",
     "longitude":"74.56976170"
  },
  {
     "name":"Issyk-Kul Region",
     "isoCode":"Y",
     "countryCode":"KG",
     "latitude":"42.18594280",
     "longitude":"77.56194190"
  },
  {
     "name":"Osh",
     "isoCode":"GO",
     "countryCode":"KG",
     "latitude":"36.06313990",
     "longitude":"-95.91828950"
  },
  {
     "name":"Chuy Region",
     "isoCode":"C",
     "countryCode":"KG",
     "latitude":"42.56550000",
     "longitude":"74.40566120"
  },
  {
     "name":"Osh Region",
     "isoCode":"O",
     "countryCode":"KG",
     "latitude":"39.84073660",
     "longitude":"72.89880690"
  },
  {
     "name":"Trøndelag",
     "isoCode":"50",
     "countryCode":"NO",
     "latitude":"63.54201250",
     "longitude":"10.93692670"
  },
  {
     "name":"Oslo",
     "isoCode":"03",
     "countryCode":"NO",
     "latitude":"59.91386880",
     "longitude":"10.75224540"
  },
  {
     "name":"Vestfold",
     "isoCode":"07",
     "countryCode":"NO",
     "latitude":"59.17078620",
     "longitude":"10.11443550"
  },
  {
     "name":"Oppland",
     "isoCode":"05",
     "countryCode":"NO",
     "latitude":"61.54227520",
     "longitude":"9.71663150"
  },
  {
     "name":"Sør-Trøndelag",
     "isoCode":"16",
     "countryCode":"NO",
     "latitude":"63.01368230",
     "longitude":"10.34871360"
  },
  {
     "name":"Buskerud",
     "isoCode":"06",
     "countryCode":"NO",
     "latitude":"60.48460250",
     "longitude":"8.69837640"
  },
  {
     "name":"Nord-Trøndelag",
     "isoCode":"17",
     "countryCode":"NO",
     "latitude":"64.43707920",
     "longitude":"11.74629500"
  },
  {
     "name":"Svalbard",
     "isoCode":"21",
     "countryCode":"NO",
     "latitude":"77.87497250",
     "longitude":"20.97518210"
  },
  {
     "name":"Vest-Agder",
     "isoCode":"10",
     "countryCode":"NO",
     "latitude":"58.09990810",
     "longitude":"6.58698090"
  },
  {
     "name":"Troms",
     "isoCode":"19",
     "countryCode":"NO",
     "latitude":"69.81782420",
     "longitude":"18.78193650"
  },
  {
     "name":"Finnmark",
     "isoCode":"20",
     "countryCode":"NO",
     "latitude":"70.48303880",
     "longitude":"26.01351070"
  },
  {
     "name":"Akershus",
     "isoCode":"02",
     "countryCode":"NO",
     "latitude":"28.37042030",
     "longitude":"-81.54680580"
  },
  {
     "name":"Sogn og Fjordane",
     "isoCode":"14",
     "countryCode":"NO",
     "latitude":"61.55394350",
     "longitude":"6.33258790"
  },
  {
     "name":"Hedmark",
     "isoCode":"04",
     "countryCode":"NO",
     "latitude":"61.39673110",
     "longitude":"11.56273690"
  },
  {
     "name":"Møre og Romsdal",
     "isoCode":"15",
     "countryCode":"NO",
     "latitude":"62.84068330",
     "longitude":"7.00714300"
  },
  {
     "name":"Rogaland",
     "isoCode":"11",
     "countryCode":"NO",
     "latitude":"59.14895440",
     "longitude":"6.01434320"
  },
  {
     "name":"Østfold",
     "isoCode":"01",
     "countryCode":"NO",
     "latitude":"59.25582860",
     "longitude":"11.32790060"
  },
  {
     "name":"Hordaland",
     "isoCode":"12",
     "countryCode":"NO",
     "latitude":"60.27336740",
     "longitude":"5.72201940"
  },
  {
     "name":"Telemark",
     "isoCode":"08",
     "countryCode":"NO",
     "latitude":"59.39139850",
     "longitude":"8.32112090"
  },
  {
     "name":"Nordland",
     "isoCode":"18",
     "countryCode":"NO",
     "latitude":"67.69305800",
     "longitude":"12.70739360"
  },
  {
     "name":"Jan Mayen",
     "isoCode":"22",
     "countryCode":"NO",
     "latitude":"71.03181800",
     "longitude":"-8.29203460"
  },
  {
     "name":"Hódmezővásárhely",
     "isoCode":"HV",
     "countryCode":"HU",
     "latitude":"46.41812620",
     "longitude":"20.33003150"
  },
  {
     "name":"Érd",
     "isoCode":"ER",
     "countryCode":"HU",
     "latitude":"47.39197180",
     "longitude":"18.90454400"
  },
  {
     "name":"Szeged",
     "isoCode":"SD",
     "countryCode":"HU",
     "latitude":"46.25301020",
     "longitude":"20.14142530"
  },
  {
     "name":"Nagykanizsa",
     "isoCode":"NK",
     "countryCode":"HU",
     "latitude":"46.45902180",
     "longitude":"16.98967960"
  },
  {
     "name":"Csongrád County",
     "isoCode":"CS",
     "countryCode":"HU",
     "latitude":"46.41670500",
     "longitude":"20.25661610"
  },
  {
     "name":"Debrecen",
     "isoCode":"DE",
     "countryCode":"HU",
     "latitude":"47.53160490",
     "longitude":"21.62731240"
  },
  {
     "name":"Székesfehérvár",
     "isoCode":"SF",
     "countryCode":"HU",
     "latitude":"47.18602620",
     "longitude":"18.42213580"
  },
  {
     "name":"Nyíregyháza",
     "isoCode":"NY",
     "countryCode":"HU",
     "latitude":"47.94953240",
     "longitude":"21.72440530"
  },
  {
     "name":"Somogy County",
     "isoCode":"SO",
     "countryCode":"HU",
     "latitude":"46.55485900",
     "longitude":"17.58667320"
  },
  {
     "name":"Békéscsaba",
     "isoCode":"BC",
     "countryCode":"HU",
     "latitude":"46.67359390",
     "longitude":"21.08773090"
  },
  {
     "name":"Eger",
     "isoCode":"EG",
     "countryCode":"HU",
     "latitude":"47.90253480",
     "longitude":"20.37722840"
  },
  {
     "name":"Tolna County",
     "isoCode":"TO",
     "countryCode":"HU",
     "latitude":"46.47627540",
     "longitude":"18.55706270"
  },
  {
     "name":"Vas County",
     "isoCode":"VA",
     "countryCode":"HU",
     "latitude":"47.09291110",
     "longitude":"16.68121830"
  },
  {
     "name":"Heves County",
     "isoCode":"HE",
     "countryCode":"HU",
     "latitude":"47.80576170",
     "longitude":"20.20385590"
  },
  {
     "name":"Győr",
     "isoCode":"GY",
     "countryCode":"HU",
     "latitude":"47.68745690",
     "longitude":"17.65039740"
  },
  {
     "name":"Győr-Moson-Sopron County",
     "isoCode":"GS",
     "countryCode":"HU",
     "latitude":"47.65092850",
     "longitude":"17.25058830"
  },
  {
     "name":"Jász-Nagykun-Szolnok County",
     "isoCode":"JN",
     "countryCode":"HU",
     "latitude":"47.25555790",
     "longitude":"20.52324560"
  },
  {
     "name":"Fejér County",
     "isoCode":"FE",
     "countryCode":"HU",
     "latitude":"47.12179320",
     "longitude":"18.52948150"
  },
  {
     "name":"Szabolcs-Szatmár-Bereg County",
     "isoCode":"SZ",
     "countryCode":"HU",
     "latitude":"48.03949540",
     "longitude":"22.00333000"
  },
  {
     "name":"Zala County",
     "isoCode":"ZA",
     "countryCode":"HU",
     "latitude":"46.73844040",
     "longitude":"16.91522520"
  },
  {
     "name":"Szolnok",
     "isoCode":"SK",
     "countryCode":"HU",
     "latitude":"47.16213550",
     "longitude":"20.18247120"
  },
  {
     "name":"Bács-Kiskun County",
     "isoCode":"BK",
     "countryCode":"HU",
     "latitude":"46.56614370",
     "longitude":"19.42724640"
  },
  {
     "name":"Dunaújváros",
     "isoCode":"DU",
     "countryCode":"HU",
     "latitude":"46.96190590",
     "longitude":"18.93552270"
  },
  {
     "name":"Zalaegerszeg",
     "isoCode":"ZE",
     "countryCode":"HU",
     "latitude":"46.84169360",
     "longitude":"16.84163220"
  },
  {
     "name":"Nógrád County",
     "isoCode":"NO",
     "countryCode":"HU",
     "latitude":"47.90410310",
     "longitude":"19.04985040"
  },
  {
     "name":"Szombathely",
     "isoCode":"SH",
     "countryCode":"HU",
     "latitude":"47.23068510",
     "longitude":"16.62184410"
  },
  {
     "name":"Pécs",
     "isoCode":"PS",
     "countryCode":"HU",
     "latitude":"46.07273450",
     "longitude":"18.23226600"
  },
  {
     "name":"Veszprém County",
     "isoCode":"VE",
     "countryCode":"HU",
     "latitude":"47.09309740",
     "longitude":"17.91007630"
  },
  {
     "name":"Baranya County",
     "isoCode":"BA",
     "countryCode":"HU",
     "latitude":"46.04845850",
     "longitude":"18.27191730"
  },
  {
     "name":"Kecskemét",
     "isoCode":"KM",
     "countryCode":"HU",
     "latitude":"46.89637110",
     "longitude":"19.68968610"
  },
  {
     "name":"Sopron",
     "isoCode":"SN",
     "countryCode":"HU",
     "latitude":"47.68166190",
     "longitude":"16.58447950"
  },
  {
     "name":"Borsod-Abaúj-Zemplén County",
     "isoCode":"BZ",
     "countryCode":"HU",
     "latitude":"48.29394010",
     "longitude":"20.69341120"
  },
  {
     "name":"Pest County",
     "isoCode":"PE",
     "countryCode":"HU",
     "latitude":"47.44800010",
     "longitude":"19.46181280"
  },
  {
     "name":"Békés County",
     "isoCode":"BE",
     "countryCode":"HU",
     "latitude":"46.67048990",
     "longitude":"21.04349960"
  },
  {
     "name":"Szekszárd",
     "isoCode":"SS",
     "countryCode":"HU",
     "latitude":"46.34743260",
     "longitude":"18.70622930"
  },
  {
     "name":"Veszprém",
     "isoCode":"VM",
     "countryCode":"HU",
     "latitude":"47.10280870",
     "longitude":"17.90930190"
  },
  {
     "name":"Hajdú-Bihar County",
     "isoCode":"HB",
     "countryCode":"HU",
     "latitude":"47.46883550",
     "longitude":"21.54532270"
  },
  {
     "name":"Budapest",
     "isoCode":"BU",
     "countryCode":"HU",
     "latitude":"47.49791200",
     "longitude":"19.04023500"
  },
  {
     "name":"Miskolc",
     "isoCode":"MI",
     "countryCode":"HU",
     "latitude":"48.10347750",
     "longitude":"20.77843840"
  },
  {
     "name":"Tatabánya",
     "isoCode":"TB",
     "countryCode":"HU",
     "latitude":"47.56924600",
     "longitude":"18.40481800"
  },
  {
     "name":"Kaposvár",
     "isoCode":"KV",
     "countryCode":"HU",
     "latitude":"46.35936060",
     "longitude":"17.79676390"
  },
  {
     "name":"Salgótarján",
     "isoCode":"ST",
     "countryCode":"HU",
     "latitude":"48.09352370",
     "longitude":"19.79998130"
  },
  {
     "name":"County Tipperary",
     "isoCode":"TA",
     "countryCode":"IE",
     "latitude":"52.47378940",
     "longitude":"-8.16185140"
  },
  {
     "name":"County Sligo",
     "isoCode":"SO",
     "countryCode":"IE",
     "latitude":"54.15532770",
     "longitude":"-8.60645320"
  },
  {
     "name":"County Donegal",
     "isoCode":"DL",
     "countryCode":"IE",
     "latitude":"54.65489930",
     "longitude":"-8.10409670"
  },
  {
     "name":"County Dublin",
     "isoCode":"D",
     "countryCode":"IE",
     "latitude":"53.34980530",
     "longitude":"-6.26030970"
  },
  {
     "name":"Leinster",
     "isoCode":"L",
     "countryCode":"IE",
     "latitude":"53.32715380",
     "longitude":"-7.51408410"
  },
  {
     "name":"County Cork",
     "isoCode":"CO",
     "countryCode":"IE",
     "latitude":"51.89851430",
     "longitude":"-8.47560350"
  },
  {
     "name":"County Monaghan",
     "isoCode":"MN",
     "countryCode":"IE",
     "latitude":"54.24920460",
     "longitude":"-6.96831320"
  },
  {
     "name":"County Longford",
     "isoCode":"LD",
     "countryCode":"IE",
     "latitude":"53.72749820",
     "longitude":"-7.79315270"
  },
  {
     "name":"County Kerry",
     "isoCode":"KY",
     "countryCode":"IE",
     "latitude":"52.15446070",
     "longitude":"-9.56686330"
  },
  {
     "name":"County Offaly",
     "isoCode":"OY",
     "countryCode":"IE",
     "latitude":"53.23568710",
     "longitude":"-7.71222290"
  },
  {
     "name":"County Galway",
     "isoCode":"G",
     "countryCode":"IE",
     "latitude":"53.35645090",
     "longitude":"-8.85341130"
  },
  {
     "name":"Munster",
     "isoCode":"M",
     "countryCode":"IE",
     "latitude":"51.94711970",
     "longitude":"7.58453200"
  },
  {
     "name":"County Roscommon",
     "isoCode":"RN",
     "countryCode":"IE",
     "latitude":"53.75926040",
     "longitude":"-8.26816210"
  },
  {
     "name":"County Kildare",
     "isoCode":"KE",
     "countryCode":"IE",
     "latitude":"53.21204340",
     "longitude":"-6.81947080"
  },
  {
     "name":"County Louth",
     "isoCode":"LH",
     "countryCode":"IE",
     "latitude":"53.92523240",
     "longitude":"-6.48894230"
  },
  {
     "name":"County Mayo",
     "isoCode":"MO",
     "countryCode":"IE",
     "latitude":"54.01526040",
     "longitude":"-9.42893690"
  },
  {
     "name":"County Wicklow",
     "isoCode":"WW",
     "countryCode":"IE",
     "latitude":"52.98623130",
     "longitude":"-6.36725430"
  },
  {
     "name":"Ulster",
     "isoCode":"U",
     "countryCode":"IE",
     "latitude":"54.76165550",
     "longitude":"-6.96122730"
  },
  {
     "name":"Connacht",
     "isoCode":"C",
     "countryCode":"IE",
     "latitude":"53.83762430",
     "longitude":"-8.95844810"
  },
  {
     "name":"County Cavan",
     "isoCode":"CN",
     "countryCode":"IE",
     "latitude":"53.97654240",
     "longitude":"-7.29966230"
  },
  {
     "name":"County Waterford",
     "isoCode":"WD",
     "countryCode":"IE",
     "latitude":"52.19435490",
     "longitude":"-7.62275120"
  },
  {
     "name":"County Kilkenny",
     "isoCode":"KK",
     "countryCode":"IE",
     "latitude":"52.57769570",
     "longitude":"-7.21800200"
  },
  {
     "name":"County Clare",
     "isoCode":"CE",
     "countryCode":"IE",
     "latitude":"43.04664000",
     "longitude":"-87.89958100"
  },
  {
     "name":"County Meath",
     "isoCode":"MH",
     "countryCode":"IE",
     "latitude":"53.60554800",
     "longitude":"-6.65641690"
  },
  {
     "name":"County Wexford",
     "isoCode":"WX",
     "countryCode":"IE",
     "latitude":"52.47936030",
     "longitude":"-6.58399130"
  },
  {
     "name":"County Limerick",
     "isoCode":"LK",
     "countryCode":"IE",
     "latitude":"52.50905170",
     "longitude":"-8.74749550"
  },
  {
     "name":"County Carlow",
     "isoCode":"CW",
     "countryCode":"IE",
     "latitude":"52.72322170",
     "longitude":"-6.81082950"
  },
  {
     "name":"County Laois",
     "isoCode":"LS",
     "countryCode":"IE",
     "latitude":"52.99429500",
     "longitude":"-7.33230070"
  },
  {
     "name":"County Westmeath",
     "isoCode":"WH",
     "countryCode":"IE",
     "latitude":"53.53453080",
     "longitude":"-7.46532170"
  },
  {
     "name":"Djelfa",
     "isoCode":"17",
     "countryCode":"DZ",
     "latitude":"34.67039560",
     "longitude":"3.25037610"
  },
  {
     "name":"El Oued",
     "isoCode":"39",
     "countryCode":"DZ",
     "latitude":"33.36781100",
     "longitude":"6.85165110"
  },
  {
     "name":"El Tarf",
     "isoCode":"36",
     "countryCode":"DZ",
     "latitude":"36.75766780",
     "longitude":"8.30763430"
  },
  {
     "name":"Oran",
     "isoCode":"31",
     "countryCode":"DZ",
     "latitude":"35.60823510",
     "longitude":"-0.56360900"
  },
  {
     "name":"Naama",
     "isoCode":"45",
     "countryCode":"DZ",
     "latitude":"33.26673170",
     "longitude":"-0.31286590"
  },
  {
     "name":"Annaba",
     "isoCode":"23",
     "countryCode":"DZ",
     "latitude":"36.80205080",
     "longitude":"7.52472430"
  },
  {
     "name":"Bouïra",
     "isoCode":"10",
     "countryCode":"DZ",
     "latitude":"36.36918460",
     "longitude":"3.90061940"
  },
  {
     "name":"Chlef",
     "isoCode":"02",
     "countryCode":"DZ",
     "latitude":"36.16935150",
     "longitude":"1.28910360"
  },
  {
     "name":"Tiaret",
     "isoCode":"14",
     "countryCode":"DZ",
     "latitude":"35.37086890",
     "longitude":"1.32178520"
  },
  {
     "name":"Tlemcen",
     "isoCode":"13",
     "countryCode":"DZ",
     "latitude":"34.67802840",
     "longitude":"-1.36621600"
  },
  {
     "name":"Béchar",
     "isoCode":"08",
     "countryCode":"DZ",
     "latitude":"31.62380980",
     "longitude":"-2.21624430"
  },
  {
     "name":"Médéa",
     "isoCode":"26",
     "countryCode":"DZ",
     "latitude":"36.26370780",
     "longitude":"2.75878570"
  },
  {
     "name":"Skikda",
     "isoCode":"21",
     "countryCode":"DZ",
     "latitude":"36.67211980",
     "longitude":"6.83509990"
  },
  {
     "name":"Blida",
     "isoCode":"09",
     "countryCode":"DZ",
     "latitude":"36.53112300",
     "longitude":"2.89762540"
  },
  {
     "name":"Illizi",
     "isoCode":"33",
     "countryCode":"DZ",
     "latitude":"26.16900050",
     "longitude":"8.48424650"
  },
  {
     "name":"Jijel",
     "isoCode":"18",
     "countryCode":"DZ",
     "latitude":"36.71796810",
     "longitude":"5.98325770"
  },
  {
     "name":"Biskra",
     "isoCode":"07",
     "countryCode":"DZ",
     "latitude":"34.84494370",
     "longitude":"5.72485670"
  },
  {
     "name":"Tipasa",
     "isoCode":"42",
     "countryCode":"DZ",
     "latitude":"36.54626500",
     "longitude":"2.18432850"
  },
  {
     "name":"Bordj Bou Arréridj",
     "isoCode":"34",
     "countryCode":"DZ",
     "latitude":"36.07399250",
     "longitude":"4.76302710"
  },
  {
     "name":"Tébessa",
     "isoCode":"12",
     "countryCode":"DZ",
     "latitude":"35.12906910",
     "longitude":"7.95928630"
  },
  {
     "name":"Adrar",
     "isoCode":"01",
     "countryCode":"DZ",
     "latitude":"26.41813100",
     "longitude":"-0.60147170"
  },
  {
     "name":"Aïn Defla",
     "isoCode":"44",
     "countryCode":"DZ",
     "latitude":"36.25094290",
     "longitude":"1.93938150"
  },
  {
     "name":"Tindouf",
     "isoCode":"37",
     "countryCode":"DZ",
     "latitude":"27.80631190",
     "longitude":"-5.72998210"
  },
  {
     "name":"Constantine",
     "isoCode":"25",
     "countryCode":"DZ",
     "latitude":"36.33739110",
     "longitude":"6.66381200"
  },
  {
     "name":"Aïn Témouchent",
     "isoCode":"46",
     "countryCode":"DZ",
     "latitude":"35.29926980",
     "longitude":"-1.13927920"
  },
  {
     "name":"Saïda",
     "isoCode":"20",
     "countryCode":"DZ",
     "latitude":"34.84152070",
     "longitude":"0.14560550"
  },
  {
     "name":"Mascara",
     "isoCode":"29",
     "countryCode":"DZ",
     "latitude":"35.39041250",
     "longitude":"0.14949880"
  },
  {
     "name":"Boumerdès",
     "isoCode":"35",
     "countryCode":"DZ",
     "latitude":"36.68395590",
     "longitude":"3.62178020"
  },
  {
     "name":"Khenchela",
     "isoCode":"40",
     "countryCode":"DZ",
     "latitude":"35.42694040",
     "longitude":"7.14601550"
  },
  {
     "name":"Ghardaïa",
     "isoCode":"47",
     "countryCode":"DZ",
     "latitude":"32.49437410",
     "longitude":"3.64446000"
  },
  {
     "name":"Béjaïa",
     "isoCode":"06",
     "countryCode":"DZ",
     "latitude":"36.75152580",
     "longitude":"5.05568370"
  },
  {
     "name":"El Bayadh",
     "isoCode":"32",
     "countryCode":"DZ",
     "latitude":"32.71488240",
     "longitude":"0.90566230"
  },
  {
     "name":"Relizane",
     "isoCode":"48",
     "countryCode":"DZ",
     "latitude":"35.73834050",
     "longitude":"0.75328090"
  },
  {
     "name":"Tizi Ouzou",
     "isoCode":"15",
     "countryCode":"DZ",
     "latitude":"36.70691100",
     "longitude":"4.23333550"
  },
  {
     "name":"Mila",
     "isoCode":"43",
     "countryCode":"DZ",
     "latitude":"36.36479570",
     "longitude":"6.15269850"
  },
  {
     "name":"Tissemsilt",
     "isoCode":"38",
     "countryCode":"DZ",
     "latitude":"35.60537810",
     "longitude":"1.81309800"
  },
  {
     "name":"M'Sila",
     "isoCode":"28",
     "countryCode":"DZ",
     "latitude":"35.71866460",
     "longitude":"4.52334230"
  },
  {
     "name":"Tamanghasset",
     "isoCode":"11",
     "countryCode":"DZ",
     "latitude":"22.79029720",
     "longitude":"5.51932680"
  },
  {
     "name":"Oum El Bouaghi",
     "isoCode":"04",
     "countryCode":"DZ",
     "latitude":"35.86887890",
     "longitude":"7.11082660"
  },
  {
     "name":"Guelma",
     "isoCode":"24",
     "countryCode":"DZ",
     "latitude":"36.46274440",
     "longitude":"7.43308330"
  },
  {
     "name":"Laghouat",
     "isoCode":"03",
     "countryCode":"DZ",
     "latitude":"33.80783410",
     "longitude":"2.86282940"
  },
  {
     "name":"Ouargla",
     "isoCode":"30",
     "countryCode":"DZ",
     "latitude":"32.22648630",
     "longitude":"5.72998210"
  },
  {
     "name":"Mostaganem",
     "isoCode":"27",
     "countryCode":"DZ",
     "latitude":"35.95830540",
     "longitude":"0.33718890"
  },
  {
     "name":"Sétif",
     "isoCode":"19",
     "countryCode":"DZ",
     "latitude":"36.30733890",
     "longitude":"5.56172790"
  },
  {
     "name":"Batna",
     "isoCode":"05",
     "countryCode":"DZ",
     "latitude":"35.59659540",
     "longitude":"5.89871390"
  },
  {
     "name":"Souk Ahras",
     "isoCode":"41",
     "countryCode":"DZ",
     "latitude":"36.28010620",
     "longitude":"7.93840330"
  },
  {
     "name":"Algiers",
     "isoCode":"16",
     "countryCode":"DZ",
     "latitude":"36.69972940",
     "longitude":"3.05761990"
  },
  {
     "name":"Burgos Province",
     "isoCode":"BU",
     "countryCode":"ES",
     "latitude":"42.33807580",
     "longitude":"-3.58126920"
  },
  {
     "name":"Salamanca Province",
     "isoCode":"SA",
     "countryCode":"ES",
     "latitude":"40.95152630",
     "longitude":"-6.23759470"
  },
  {
     "name":"Palencia Province",
     "isoCode":"P",
     "countryCode":"ES",
     "latitude":"42.00968320",
     "longitude":"-4.52879490"
  },
  {
     "name":"Madrid",
     "isoCode":"MD",
     "countryCode":"ES",
     "latitude":"40.41675150",
     "longitude":"-3.70383220"
  },
  {
     "name":"Melilla",
     "isoCode":"ML",
     "countryCode":"ES",
     "latitude":"35.29227750",
     "longitude":"-2.93809730"
  },
  {
     "name":"Asturias",
     "isoCode":"AS",
     "countryCode":"ES",
     "latitude":"43.36139530",
     "longitude":"-5.85932670"
  },
  {
     "name":"Zamora Province",
     "isoCode":"ZA",
     "countryCode":"ES",
     "latitude":"41.60957440",
     "longitude":"-5.89871390"
  },
  {
     "name":"Galicia",
     "isoCode":"GA",
     "countryCode":"ES",
     "latitude":"42.57505540",
     "longitude":"-8.13385580"
  },
  {
     "name":"Cantabria",
     "isoCode":"CB",
     "countryCode":"ES",
     "latitude":"43.18283960",
     "longitude":"-3.98784270"
  },
  {
     "name":"La Rioja",
     "isoCode":"RI",
     "countryCode":"ES",
     "latitude":"42.28707330",
     "longitude":"-2.53960300"
  },
  {
     "name":"Balearic Islands",
     "isoCode":"PM",
     "countryCode":"ES",
     "latitude":"39.35877590",
     "longitude":"2.73563280"
  },
  {
     "name":"Valencia",
     "isoCode":"VC",
     "countryCode":"ES",
     "latitude":"39.48401080",
     "longitude":"-0.75328090"
  },
  {
     "name":"Murcia",
     "isoCode":"MC",
     "countryCode":"ES",
     "latitude":"38.13981410",
     "longitude":"-1.36621600"
  },
  {
     "name":"Aragon",
     "isoCode":"AR",
     "countryCode":"ES",
     "latitude":"41.59762750",
     "longitude":"-0.90566230"
  },
  {
     "name":"Valladolid Province",
     "isoCode":"VA",
     "countryCode":"ES",
     "latitude":"41.65173750",
     "longitude":"-4.72449500"
  },
  {
     "name":"Castile and León",
     "isoCode":"CL",
     "countryCode":"ES",
     "latitude":"41.83568210",
     "longitude":"-4.39763570"
  },
  {
     "name":"Canary Islands",
     "isoCode":"CN",
     "countryCode":"ES",
     "latitude":"28.29156370",
     "longitude":"-16.62913040"
  },
  {
     "name":"Ávila",
     "isoCode":"AV",
     "countryCode":"ES",
     "latitude":"40.69345110",
     "longitude":"-4.89356270"
  },
  {
     "name":"Extremadura",
     "isoCode":"EX",
     "countryCode":"ES",
     "latitude":"39.49373920",
     "longitude":"-6.06791940"
  },
  {
     "name":"Basque Country",
     "isoCode":"PV",
     "countryCode":"ES",
     "latitude":"42.98962480",
     "longitude":"-2.61892730"
  },
  {
     "name":"Segovia Province",
     "isoCode":"SG",
     "countryCode":"ES",
     "latitude":"40.94292960",
     "longitude":"-4.10889420"
  },
  {
     "name":"Andalusia",
     "isoCode":"AN",
     "countryCode":"ES",
     "latitude":"37.54427060",
     "longitude":"-4.72775280"
  },
  {
     "name":"Léon",
     "isoCode":"LE",
     "countryCode":"ES",
     "latitude":"42.59870410",
     "longitude":"-5.56708390"
  },
  {
     "name":"Catalonia",
     "isoCode":"CT",
     "countryCode":"ES",
     "latitude":"41.59115890",
     "longitude":"1.52086240"
  },
  {
     "name":"Navarra",
     "isoCode":"NC",
     "countryCode":"ES",
     "latitude":"42.69539090",
     "longitude":"-1.67606910"
  },
  {
     "name":"Castilla La Mancha",
     "isoCode":"CM",
     "countryCode":"ES",
     "latitude":"39.27956070",
     "longitude":"-3.09770200"
  },
  {
     "name":"Ceuta",
     "isoCode":"CE",
     "countryCode":"ES",
     "latitude":"35.88938740",
     "longitude":"-5.32134550"
  },
  {
     "name":"Soria Province",
     "isoCode":"SO",
     "countryCode":"ES",
     "latitude":"41.76654640",
     "longitude":"-2.47903060"
  },
  {
     "name":"Guanacaste Province",
     "isoCode":"G",
     "countryCode":"CR",
     "latitude":"10.62673990",
     "longitude":"-85.44367060"
  },
  {
     "name":"Puntarenas Province",
     "isoCode":"P",
     "countryCode":"CR",
     "latitude":"9.21695310",
     "longitude":"-83.33618800"
  },
  {
     "name":"Provincia de Cartago",
     "isoCode":"C",
     "countryCode":"CR",
     "latitude":"9.86223110",
     "longitude":"-83.92141870"
  },
  {
     "name":"Heredia Province",
     "isoCode":"H",
     "countryCode":"CR",
     "latitude":"10.47352300",
     "longitude":"-84.01674230"
  },
  {
     "name":"Limón Province",
     "isoCode":"L",
     "countryCode":"CR",
     "latitude":"9.98963980",
     "longitude":"-83.03324170"
  },
  {
     "name":"San José Province",
     "isoCode":"SJ",
     "countryCode":"CR",
     "latitude":"9.91297270",
     "longitude":"-84.07682940"
  },
  {
     "name":"Alajuela Province",
     "isoCode":"A",
     "countryCode":"CR",
     "latitude":"10.39158300",
     "longitude":"-84.43827210"
  },
  {
     "name":"Brunei-Muara District",
     "isoCode":"BM",
     "countryCode":"BN",
     "latitude":"4.93112060",
     "longitude":"114.95168690"
  },
  {
     "name":"Belait District",
     "isoCode":"BE",
     "countryCode":"BN",
     "latitude":"4.37507490",
     "longitude":"114.61928990"
  },
  {
     "name":"Temburong District",
     "isoCode":"TE",
     "countryCode":"BN",
     "latitude":"4.62041280",
     "longitude":"115.14148400"
  },
  {
     "name":"Tutong District",
     "isoCode":"TU",
     "countryCode":"BN",
     "latitude":"4.71403730",
     "longitude":"114.66679390"
  },
  {
     "name":"Saint Philip",
     "isoCode":"10",
     "countryCode":"BB",
     "latitude":"35.23311400",
     "longitude":"-89.43640420"
  },
  {
     "name":"Saint Lucy",
     "isoCode":"07",
     "countryCode":"BB",
     "latitude":"38.76146250",
     "longitude":"-77.44914390"
  },
  {
     "name":"Saint Peter",
     "isoCode":"09",
     "countryCode":"BB",
     "latitude":"37.08271190",
     "longitude":"-94.51712500"
  },
  {
     "name":"Saint Joseph",
     "isoCode":"06",
     "countryCode":"BB",
     "latitude":"39.76745780",
     "longitude":"-94.84668100"
  },
  {
     "name":"Saint James",
     "isoCode":"04",
     "countryCode":"BB",
     "latitude":"48.52356600",
     "longitude":"-1.32378850"
  },
  {
     "name":"Saint Thomas",
     "isoCode":"11",
     "countryCode":"BB",
     "latitude":"18.33809650",
     "longitude":"-64.89409460"
  },
  {
     "name":"Saint George",
     "isoCode":"03",
     "countryCode":"BB",
     "latitude":"37.09652780",
     "longitude":"-113.56841640"
  },
  {
     "name":"Saint John",
     "isoCode":"05",
     "countryCode":"BB",
     "latitude":"45.27331530",
     "longitude":"-66.06330800"
  },
  {
     "name":"Christ Church",
     "isoCode":"01",
     "countryCode":"BB",
     "latitude":"36.00604070",
     "longitude":"-95.92112100"
  },
  {
     "name":"Saint Andrew",
     "isoCode":"02",
     "countryCode":"BB",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Michael",
     "isoCode":"08",
     "countryCode":"BB",
     "latitude":"36.03597700",
     "longitude":"-95.84905200"
  },
  {
     "name":"Ta'izz Governorate",
     "isoCode":"TA",
     "countryCode":"YE",
     "latitude":"13.57758860",
     "longitude":"44.01779890"
  },
  {
     "name":"Sana'a",
     "isoCode":"SA",
     "countryCode":"YE",
     "latitude":"15.36944510",
     "longitude":"44.19100660"
  },
  {
     "name":"Ibb Governorate",
     "isoCode":"IB",
     "countryCode":"YE",
     "latitude":"14.14157170",
     "longitude":"44.24790150"
  },
  {
     "name":"Ma'rib Governorate",
     "isoCode":"MA",
     "countryCode":"YE",
     "latitude":"15.51588800",
     "longitude":"45.44980650"
  },
  {
     "name":"Al Mahwit Governorate",
     "isoCode":"MW",
     "countryCode":"YE",
     "latitude":"15.39632290",
     "longitude":"43.56069460"
  },
  {
     "name":"Sana'a Governorate",
     "isoCode":"SN",
     "countryCode":"YE",
     "latitude":"15.31689130",
     "longitude":"44.47480180"
  },
  {
     "name":"Abyan Governorate",
     "isoCode":"AB",
     "countryCode":"YE",
     "latitude":"13.63434130",
     "longitude":"46.05632120"
  },
  {
     "name":"Hadhramaut Governorate",
     "isoCode":"HD",
     "countryCode":"YE",
     "latitude":"16.93041350",
     "longitude":"49.36531490"
  },
  {
     "name":"Socotra Governorate",
     "isoCode":"SU",
     "countryCode":"YE",
     "latitude":"12.46342050",
     "longitude":"53.82373850"
  },
  {
     "name":"Al Bayda' Governorate",
     "isoCode":"BA",
     "countryCode":"YE",
     "latitude":"14.35886620",
     "longitude":"45.44980650"
  },
  {
     "name":"Al Hudaydah Governorate",
     "isoCode":"HU",
     "countryCode":"YE",
     "latitude":"15.30530720",
     "longitude":"43.01948970"
  },
  {
     "name":"'Adan Governorate",
     "isoCode":"AD",
     "countryCode":"YE",
     "latitude":"12.82574810",
     "longitude":"44.79438040"
  },
  {
     "name":"Al Jawf Governorate",
     "isoCode":"JA",
     "countryCode":"YE",
     "latitude":"16.79018190",
     "longitude":"45.29938620"
  },
  {
     "name":"Hajjah Governorate",
     "isoCode":"HJ",
     "countryCode":"YE",
     "latitude":"16.11806310",
     "longitude":"43.32946600"
  },
  {
     "name":"Lahij Governorate",
     "isoCode":"LA",
     "countryCode":"YE",
     "latitude":"13.14895880",
     "longitude":"44.85054950"
  },
  {
     "name":"Dhamar Governorate",
     "isoCode":"DH",
     "countryCode":"YE",
     "latitude":"14.71953440",
     "longitude":"44.24790150"
  },
  {
     "name":"Shabwah Governorate",
     "isoCode":"SH",
     "countryCode":"YE",
     "latitude":"14.75463030",
     "longitude":"46.51626200"
  },
  {
     "name":"Raymah Governorate",
     "isoCode":"RA",
     "countryCode":"YE",
     "latitude":"14.62776820",
     "longitude":"43.71424840"
  },
  {
     "name":"Saada Governorate",
     "isoCode":"SD",
     "countryCode":"YE",
     "latitude":"16.84765280",
     "longitude":"43.94367880"
  },
  {
     "name":"'Amran Governorate",
     "isoCode":"AM",
     "countryCode":"YE",
     "latitude":"16.25692140",
     "longitude":"43.94367880"
  },
  {
     "name":"Al Mahrah Governorate",
     "isoCode":"MR",
     "countryCode":"YE",
     "latitude":"16.52384230",
     "longitude":"51.68342750"
  },
  {
     "name":"Sangha-Mbaéré",
     "isoCode":"SE",
     "countryCode":"CF",
     "latitude":"3.43686070",
     "longitude":"16.34637910"
  },
  {
     "name":"Nana-Grébizi Economic Prefecture",
     "isoCode":"KB",
     "countryCode":"CF",
     "latitude":"7.18486070",
     "longitude":"19.37832060"
  },
  {
     "name":"Ouham Prefecture",
     "isoCode":"AC",
     "countryCode":"CF",
     "latitude":"7.09091100",
     "longitude":"17.66888700"
  },
  {
     "name":"Ombella-M'Poko Prefecture",
     "isoCode":"MP",
     "countryCode":"CF",
     "latitude":"5.11888250",
     "longitude":"18.42760470"
  },
  {
     "name":"Lobaye Prefecture",
     "isoCode":"LB",
     "countryCode":"CF",
     "latitude":"4.35259810",
     "longitude":"17.47951730"
  },
  {
     "name":"Mambéré-Kadéï",
     "isoCode":"HS",
     "countryCode":"CF",
     "latitude":"4.70556530",
     "longitude":"15.96998780"
  },
  {
     "name":"Haut-Mbomou Prefecture",
     "isoCode":"HM",
     "countryCode":"CF",
     "latitude":"6.25371340",
     "longitude":"25.47335540"
  },
  {
     "name":"Bamingui-Bangoran Prefecture",
     "isoCode":"BB",
     "countryCode":"CF",
     "latitude":"8.27334550",
     "longitude":"20.71224650"
  },
  {
     "name":"Nana-Mambéré Prefecture",
     "isoCode":"NM",
     "countryCode":"CF",
     "latitude":"5.69321350",
     "longitude":"15.21948080"
  },
  {
     "name":"Vakaga Prefecture",
     "isoCode":"VK",
     "countryCode":"CF",
     "latitude":"9.51132960",
     "longitude":"22.23840170"
  },
  {
     "name":"Bangui",
     "isoCode":"BGF",
     "countryCode":"CF",
     "latitude":"4.39467350",
     "longitude":"18.55818990"
  },
  {
     "name":"Kémo Prefecture",
     "isoCode":"KG",
     "countryCode":"CF",
     "latitude":"5.88677940",
     "longitude":"19.37832060"
  },
  {
     "name":"Basse-Kotto Prefecture",
     "isoCode":"BK",
     "countryCode":"CF",
     "latitude":"4.87193190",
     "longitude":"21.28450250"
  },
  {
     "name":"Ouaka Prefecture",
     "isoCode":"UK",
     "countryCode":"CF",
     "latitude":"6.31682160",
     "longitude":"20.71224650"
  },
  {
     "name":"Mbomou Prefecture",
     "isoCode":"MB",
     "countryCode":"CF",
     "latitude":"5.55683700",
     "longitude":"23.76328280"
  },
  {
     "name":"Ouham-Pendé Prefecture",
     "isoCode":"OP",
     "countryCode":"CF",
     "latitude":"6.48509840",
     "longitude":"16.15809370"
  },
  {
     "name":"Haute-Kotto Prefecture",
     "isoCode":"HK",
     "countryCode":"CF",
     "latitude":"7.79643790",
     "longitude":"23.38235450"
  },
  {
     "name":"Romblon",
     "isoCode":"ROM",
     "countryCode":"PH",
     "latitude":"12.57780160",
     "longitude":"122.26914600"
  },
  {
     "name":"Bukidnon",
     "isoCode":"BUK",
     "countryCode":"PH",
     "latitude":"8.05150540",
     "longitude":"124.92299460"
  },
  {
     "name":"Rizal",
     "isoCode":"RIZ",
     "countryCode":"PH",
     "latitude":"14.60374460",
     "longitude":"121.30840880"
  },
  {
     "name":"Bohol",
     "isoCode":"BOH",
     "countryCode":"PH",
     "latitude":"9.84999110",
     "longitude":"124.14354270"
  },
  {
     "name":"Quirino",
     "isoCode":"QUI",
     "countryCode":"PH",
     "latitude":"16.27004240",
     "longitude":"121.53700030"
  },
  {
     "name":"Biliran",
     "isoCode":"BIL",
     "countryCode":"PH",
     "latitude":"11.58331520",
     "longitude":"124.46418480"
  },
  {
     "name":"Quezon",
     "isoCode":"QUE",
     "countryCode":"PH",
     "latitude":"14.03139060",
     "longitude":"122.11309090"
  },
  {
     "name":"Siquijor",
     "isoCode":"SIG",
     "countryCode":"PH",
     "latitude":"9.19987790",
     "longitude":"123.59519250"
  },
  {
     "name":"Sarangani",
     "isoCode":"SAR",
     "countryCode":"PH",
     "latitude":"5.92671750",
     "longitude":"124.99475100"
  },
  {
     "name":"Bulacan",
     "isoCode":"BUL",
     "countryCode":"PH",
     "latitude":"14.79427350",
     "longitude":"120.87990080"
  },
  {
     "name":"Cagayan",
     "isoCode":"CAG",
     "countryCode":"PH",
     "latitude":"18.24896290",
     "longitude":"121.87878330"
  },
  {
     "name":"South Cotabato",
     "isoCode":"SCO",
     "countryCode":"PH",
     "latitude":"6.33575650",
     "longitude":"124.77407930"
  },
  {
     "name":"Sorsogon",
     "isoCode":"SOR",
     "countryCode":"PH",
     "latitude":"12.99270950",
     "longitude":"124.01474640"
  },
  {
     "name":"Sultan Kudarat",
     "isoCode":"SUK",
     "countryCode":"PH",
     "latitude":"6.50694010",
     "longitude":"124.41982430"
  },
  {
     "name":"Camarines Norte",
     "isoCode":"CAN",
     "countryCode":"PH",
     "latitude":"14.13902650",
     "longitude":"122.76330360"
  },
  {
     "name":"Southern Leyte",
     "isoCode":"SLE",
     "countryCode":"PH",
     "latitude":"10.33462060",
     "longitude":"125.17087410"
  },
  {
     "name":"Camiguin",
     "isoCode":"CAM",
     "countryCode":"PH",
     "latitude":"9.17321640",
     "longitude":"124.72987650"
  },
  {
     "name":"Surigao del Norte",
     "isoCode":"SUN",
     "countryCode":"PH",
     "latitude":"9.51482800",
     "longitude":"125.69699840"
  },
  {
     "name":"Camarines Sur",
     "isoCode":"CAS",
     "countryCode":"PH",
     "latitude":"13.52501970",
     "longitude":"123.34861470"
  },
  {
     "name":"Sulu",
     "isoCode":"SLU",
     "countryCode":"PH",
     "latitude":"5.97490110",
     "longitude":"121.03351000"
  },
  {
     "name":"Davao Oriental",
     "isoCode":"DAO",
     "countryCode":"PH",
     "latitude":"7.31715850",
     "longitude":"126.54198870"
  },
  {
     "name":"Eastern Samar",
     "isoCode":"EAS",
     "countryCode":"PH",
     "latitude":"11.50007310",
     "longitude":"125.49999080"
  },
  {
     "name":"Dinagat Islands",
     "isoCode":"DIN",
     "countryCode":"PH",
     "latitude":"10.12818160",
     "longitude":"125.60954740"
  },
  {
     "name":"Capiz",
     "isoCode":"CAP",
     "countryCode":"PH",
     "latitude":"11.55288160",
     "longitude":"122.74072300"
  },
  {
     "name":"Tawi-Tawi",
     "isoCode":"TAW",
     "countryCode":"PH",
     "latitude":"5.13381100",
     "longitude":"119.95092600"
  },
  {
     "name":"Calabarzon",
     "isoCode":"40",
     "countryCode":"PH",
     "latitude":"14.10078030",
     "longitude":"121.07937050"
  },
  {
     "name":"Tarlac",
     "isoCode":"TAR",
     "countryCode":"PH",
     "latitude":"15.47547860",
     "longitude":"120.59634920"
  },
  {
     "name":"Surigao del Sur",
     "isoCode":"SUR",
     "countryCode":"PH",
     "latitude":"8.54049060",
     "longitude":"126.11447580"
  },
  {
     "name":"Zambales",
     "isoCode":"ZMB",
     "countryCode":"PH",
     "latitude":"15.50817660",
     "longitude":"119.96978080"
  },
  {
     "name":"Ilocos Norte",
     "isoCode":"ILN",
     "countryCode":"PH",
     "latitude":"18.16472810",
     "longitude":"120.71155920"
  },
  {
     "name":"Mimaropa",
     "isoCode":"41",
     "countryCode":"PH",
     "latitude":"9.84320650",
     "longitude":"118.73647830"
  },
  {
     "name":"Ifugao",
     "isoCode":"IFU",
     "countryCode":"PH",
     "latitude":"16.83307920",
     "longitude":"121.17103890"
  },
  {
     "name":"Catanduanes",
     "isoCode":"CAT",
     "countryCode":"PH",
     "latitude":"13.70886840",
     "longitude":"124.24215970"
  },
  {
     "name":"Zamboanga del Norte",
     "isoCode":"ZAN",
     "countryCode":"PH",
     "latitude":"8.38862820",
     "longitude":"123.16888830"
  },
  {
     "name":"Guimaras",
     "isoCode":"GUI",
     "countryCode":"PH",
     "latitude":"10.59286610",
     "longitude":"122.63250810"
  },
  {
     "name":"Bicol Region",
     "isoCode":"05",
     "countryCode":"PH",
     "latitude":"13.42098850",
     "longitude":"123.41367360"
  },
  {
     "name":"Western Visayas",
     "isoCode":"06",
     "countryCode":"PH",
     "latitude":"11.00498360",
     "longitude":"122.53727410"
  },
  {
     "name":"Cebu",
     "isoCode":"CEB",
     "countryCode":"PH",
     "latitude":"10.31569920",
     "longitude":"123.88543660"
  },
  {
     "name":"Cavite",
     "isoCode":"CAV",
     "countryCode":"PH",
     "latitude":"14.47912970",
     "longitude":"120.89696340"
  },
  {
     "name":"Central Visayas",
     "isoCode":"07",
     "countryCode":"PH",
     "latitude":"9.81687500",
     "longitude":"124.06414190"
  },
  {
     "name":"Davao Occidental",
     "isoCode":"DVO",
     "countryCode":"PH",
     "latitude":"6.09413960",
     "longitude":"125.60954740"
  },
  {
     "name":"Soccsksargen",
     "isoCode":"12",
     "countryCode":"PH",
     "latitude":"6.27069180",
     "longitude":"124.68565090"
  },
  {
     "name":"Compostela Valley",
     "isoCode":"COM",
     "countryCode":"PH",
     "latitude":"7.51251500",
     "longitude":"126.17626150"
  },
  {
     "name":"Kalinga",
     "isoCode":"KAL",
     "countryCode":"PH",
     "latitude":"17.47404220",
     "longitude":"121.35416310"
  },
  {
     "name":"Isabela",
     "isoCode":"ISA",
     "countryCode":"PH",
     "latitude":"18.50077590",
     "longitude":"-67.02434620"
  },
  {
     "name":"Caraga",
     "isoCode":"13",
     "countryCode":"PH",
     "latitude":"8.80145620",
     "longitude":"125.74068820"
  },
  {
     "name":"Iloilo",
     "isoCode":"ILI",
     "countryCode":"PH",
     "latitude":"10.72015010",
     "longitude":"122.56210630"
  },
  {
     "name":"Autonomous Region in Muslim Mindanao",
     "isoCode":"14",
     "countryCode":"PH",
     "latitude":"6.95683130",
     "longitude":"124.24215970"
  },
  {
     "name":"La Union",
     "isoCode":"LUN",
     "countryCode":"PH",
     "latitude":"38.87668780",
     "longitude":"-77.12809120"
  },
  {
     "name":"Davao del Sur",
     "isoCode":"DAS",
     "countryCode":"PH",
     "latitude":"6.76626870",
     "longitude":"125.32842690"
  },
  {
     "name":"Davao del Norte",
     "isoCode":"DAV",
     "countryCode":"PH",
     "latitude":"7.56176990",
     "longitude":"125.65328480"
  },
  {
     "name":"Cotabato",
     "isoCode":"NCO",
     "countryCode":"PH",
     "latitude":"7.20466680",
     "longitude":"124.23104390"
  },
  {
     "name":"Ilocos Sur",
     "isoCode":"ILS",
     "countryCode":"PH",
     "latitude":"17.22786640",
     "longitude":"120.57395790"
  },
  {
     "name":"Eastern Visayas",
     "isoCode":"08",
     "countryCode":"PH",
     "latitude":"12.24455330",
     "longitude":"125.03881640"
  },
  {
     "name":"Agusan del Norte",
     "isoCode":"AGN",
     "countryCode":"PH",
     "latitude":"8.94562590",
     "longitude":"125.53192340"
  },
  {
     "name":"Abra",
     "isoCode":"ABR",
     "countryCode":"PH",
     "latitude":"42.49708300",
     "longitude":"-96.38441000"
  },
  {
     "name":"Zamboanga Peninsula",
     "isoCode":"09",
     "countryCode":"PH",
     "latitude":"8.15407700",
     "longitude":"123.25879300"
  },
  {
     "name":"Agusan del Sur",
     "isoCode":"AGS",
     "countryCode":"PH",
     "latitude":"8.04638880",
     "longitude":"126.06153840"
  },
  {
     "name":"Lanao del Norte",
     "isoCode":"LAN",
     "countryCode":"PH",
     "latitude":"7.87218110",
     "longitude":"123.88577470"
  },
  {
     "name":"Laguna",
     "isoCode":"LAG",
     "countryCode":"PH",
     "latitude":"33.54271890",
     "longitude":"-117.78535680"
  },
  {
     "name":"Marinduque",
     "isoCode":"MAD",
     "countryCode":"PH",
     "latitude":"13.47671710",
     "longitude":"121.90321920"
  },
  {
     "name":"Maguindanao",
     "isoCode":"MAG",
     "countryCode":"PH",
     "latitude":"6.94225810",
     "longitude":"124.41982430"
  },
  {
     "name":"Aklan",
     "isoCode":"AKL",
     "countryCode":"PH",
     "latitude":"11.81661090",
     "longitude":"122.09415410"
  },
  {
     "name":"Leyte",
     "isoCode":"LEY",
     "countryCode":"PH",
     "latitude":"10.86245360",
     "longitude":"124.88111950"
  },
  {
     "name":"Lanao del Sur",
     "isoCode":"LAS",
     "countryCode":"PH",
     "latitude":"7.82317600",
     "longitude":"124.41982430"
  },
  {
     "name":"Apayao",
     "isoCode":"APA",
     "countryCode":"PH",
     "latitude":"18.01203040",
     "longitude":"121.17103890"
  },
  {
     "name":"Cordillera Administrative Region",
     "isoCode":"15",
     "countryCode":"PH",
     "latitude":"17.35125420",
     "longitude":"121.17188510"
  },
  {
     "name":"Antique",
     "isoCode":"ANT",
     "countryCode":"PH",
     "latitude":"37.03586950",
     "longitude":"-95.63616940"
  },
  {
     "name":"Albay",
     "isoCode":"ALB",
     "countryCode":"PH",
     "latitude":"13.17748270",
     "longitude":"123.52800720"
  },
  {
     "name":"Masbate",
     "isoCode":"MAS",
     "countryCode":"PH",
     "latitude":"12.35743460",
     "longitude":"123.55040760"
  },
  {
     "name":"Northern Mindanao",
     "isoCode":"10",
     "countryCode":"PH",
     "latitude":"8.02016350",
     "longitude":"124.68565090"
  },
  {
     "name":"Davao Region",
     "isoCode":"11",
     "countryCode":"PH",
     "latitude":"7.30416220",
     "longitude":"126.08934060"
  },
  {
     "name":"Aurora",
     "isoCode":"AUR",
     "countryCode":"PH",
     "latitude":"36.97089100",
     "longitude":"-93.71797900"
  },
  {
     "name":"Cagayan Valley",
     "isoCode":"02",
     "countryCode":"PH",
     "latitude":"16.97537580",
     "longitude":"121.81070790"
  },
  {
     "name":"Misamis Occidental",
     "isoCode":"MSC",
     "countryCode":"PH",
     "latitude":"8.33749030",
     "longitude":"123.70706190"
  },
  {
     "name":"Bataan",
     "isoCode":"BAN",
     "countryCode":"PH",
     "latitude":"14.64168420",
     "longitude":"120.48184460"
  },
  {
     "name":"Central Luzon",
     "isoCode":"03",
     "countryCode":"PH",
     "latitude":"15.48277220",
     "longitude":"120.71200230"
  },
  {
     "name":"Basilan",
     "isoCode":"BAS",
     "countryCode":"PH",
     "latitude":"6.42963490",
     "longitude":"121.98701650"
  },
  {
     "name":"Metro Manila",
     "isoCode":"NCR",
     "countryCode":"PH",
     "latitude":"14.60905370",
     "longitude":"121.02225650"
  },
  {
     "name":"Misamis Oriental",
     "isoCode":"MSR",
     "countryCode":"PH",
     "latitude":"8.50455580",
     "longitude":"124.62195920"
  },
  {
     "name":"Northern Samar",
     "isoCode":"NSA",
     "countryCode":"PH",
     "latitude":"12.36131990",
     "longitude":"124.77407930"
  },
  {
     "name":"Negros Oriental",
     "isoCode":"NER",
     "countryCode":"PH",
     "latitude":"9.62820830",
     "longitude":"122.98883190"
  },
  {
     "name":"Negros Occidental",
     "isoCode":"NEC",
     "countryCode":"PH",
     "latitude":"10.29256090",
     "longitude":"123.02465180"
  },
  {
     "name":"Batanes",
     "isoCode":"BTN",
     "countryCode":"PH",
     "latitude":"20.44850740",
     "longitude":"121.97081290"
  },
  {
     "name":"Mountain Province",
     "isoCode":"MOU",
     "countryCode":"PH",
     "latitude":"40.70754370",
     "longitude":"-73.95010330"
  },
  {
     "name":"Oriental Mindoro",
     "isoCode":"MDR",
     "countryCode":"PH",
     "latitude":"13.05645980",
     "longitude":"121.40694170"
  },
  {
     "name":"Ilocos Region",
     "isoCode":"01",
     "countryCode":"PH",
     "latitude":"16.08321440",
     "longitude":"120.61998950"
  },
  {
     "name":"Occidental Mindoro",
     "isoCode":"MDC",
     "countryCode":"PH",
     "latitude":"13.10241110",
     "longitude":"120.76512840"
  },
  {
     "name":"Zamboanga del Sur",
     "isoCode":"ZAS",
     "countryCode":"PH",
     "latitude":"7.83830540",
     "longitude":"123.29666570"
  },
  {
     "name":"Nueva Vizcaya",
     "isoCode":"NUV",
     "countryCode":"PH",
     "latitude":"16.33011070",
     "longitude":"121.17103890"
  },
  {
     "name":"Batangas",
     "isoCode":"BTG",
     "countryCode":"PH",
     "latitude":"13.75646510",
     "longitude":"121.05830760"
  },
  {
     "name":"Nueva Ecija",
     "isoCode":"NUE",
     "countryCode":"PH",
     "latitude":"15.57837500",
     "longitude":"121.11126150"
  },
  {
     "name":"Palawan",
     "isoCode":"PLW",
     "countryCode":"PH",
     "latitude":"9.83494930",
     "longitude":"118.73836150"
  },
  {
     "name":"Zamboanga Sibugay",
     "isoCode":"ZSI",
     "countryCode":"PH",
     "latitude":"7.52252470",
     "longitude":"122.31075170"
  },
  {
     "name":"Benguet",
     "isoCode":"BEN",
     "countryCode":"PH",
     "latitude":"16.55772570",
     "longitude":"120.80394740"
  },
  {
     "name":"Pangasinan",
     "isoCode":"PAN",
     "countryCode":"PH",
     "latitude":"15.89490550",
     "longitude":"120.28631830"
  },
  {
     "name":"Pampanga",
     "isoCode":"PAM",
     "countryCode":"PH",
     "latitude":"15.07940900",
     "longitude":"120.61998950"
  },
  {
     "name":"Northern District",
     "isoCode":"Z",
     "countryCode":"IL",
     "latitude":"36.15118640",
     "longitude":"-95.99517630"
  },
  {
     "name":"Central District",
     "isoCode":"M",
     "countryCode":"IL",
     "latitude":"47.60875830",
     "longitude":"-122.29642350"
  },
  {
     "name":"Southern District",
     "isoCode":"D",
     "countryCode":"IL",
     "latitude":"40.71375860",
     "longitude":"-74.00090590"
  },
  {
     "name":"Haifa District",
     "isoCode":"HA",
     "countryCode":"IL",
     "latitude":"32.48141110",
     "longitude":"34.99475100"
  },
  {
     "name":"Jerusalem District",
     "isoCode":"JM",
     "countryCode":"IL",
     "latitude":"31.76482430",
     "longitude":"34.99475100"
  },
  {
     "name":"Tel Aviv District",
     "isoCode":"TA",
     "countryCode":"IL",
     "latitude":"32.09290750",
     "longitude":"34.80721650"
  },
  {
     "name":"Limburg",
     "isoCode":"VLI",
     "countryCode":"BE",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Flanders",
     "isoCode":"VLG",
     "countryCode":"BE",
     "latitude":"51.01087060",
     "longitude":"3.72646130"
  },
  {
     "name":"Flemish Brabant",
     "isoCode":"VBR",
     "countryCode":"BE",
     "latitude":"50.88154340",
     "longitude":"4.56459700"
  },
  {
     "name":"Hainaut",
     "isoCode":"WHT",
     "countryCode":"BE",
     "latitude":"50.52570760",
     "longitude":"4.06210170"
  },
  {
     "name":"Brussels-Capital Region",
     "isoCode":"BRU",
     "countryCode":"BE",
     "latitude":"50.85034630",
     "longitude":"4.35172110"
  },
  {
     "name":"East Flanders",
     "isoCode":"VOV",
     "countryCode":"BE",
     "latitude":"51.03621010",
     "longitude":"3.73731240"
  },
  {
     "name":"Namur",
     "isoCode":"WNA",
     "countryCode":"BE",
     "latitude":"50.46738830",
     "longitude":"4.87198540"
  },
  {
     "name":"Luxembourg",
     "isoCode":"WLX",
     "countryCode":"BE",
     "latitude":"49.81527300",
     "longitude":"6.12958300"
  },
  {
     "name":"Wallonia",
     "isoCode":"WAL",
     "countryCode":"BE",
     "latitude":"50.41756370",
     "longitude":"4.45100660"
  },
  {
     "name":"Antwerp",
     "isoCode":"VAN",
     "countryCode":"BE",
     "latitude":"51.21944750",
     "longitude":"4.40246430"
  },
  {
     "name":"Walloon Brabant",
     "isoCode":"WBR",
     "countryCode":"BE",
     "latitude":"50.63324100",
     "longitude":"4.52431500"
  },
  {
     "name":"West Flanders",
     "isoCode":"VWV",
     "countryCode":"BE",
     "latitude":"40.01793340",
     "longitude":"-105.28067330"
  },
  {
     "name":"Liège",
     "isoCode":"WLG",
     "countryCode":"BE",
     "latitude":"50.63255740",
     "longitude":"5.57966620"
  },
  {
     "name":"Darién Province",
     "isoCode":"5",
     "countryCode":"PA",
     "latitude":"7.86817130",
     "longitude":"-77.83672820"
  },
  {
     "name":"Colón Province",
     "isoCode":"3",
     "countryCode":"PA",
     "latitude":"9.18519890",
     "longitude":"-80.05349230"
  },
  {
     "name":"Coclé Province",
     "isoCode":"2",
     "countryCode":"PA",
     "latitude":"8.62660680",
     "longitude":"-80.36586500"
  },
  {
     "name":"Guna Yala",
     "isoCode":"KY",
     "countryCode":"PA",
     "latitude":"9.23443950",
     "longitude":"-78.19262500"
  },
  {
     "name":"Herrera Province",
     "isoCode":"6",
     "countryCode":"PA",
     "latitude":"7.77042820",
     "longitude":"-80.72144170"
  },
  {
     "name":"Los Santos Province",
     "isoCode":"7",
     "countryCode":"PA",
     "latitude":"7.59093020",
     "longitude":"-80.36586500"
  },
  {
     "name":"Ngöbe-Buglé Comarca",
     "isoCode":"NB",
     "countryCode":"PA",
     "latitude":"8.65958330",
     "longitude":"-81.77870210"
  },
  {
     "name":"Veraguas Province",
     "isoCode":"9",
     "countryCode":"PA",
     "latitude":"8.12310330",
     "longitude":"-81.07546570"
  },
  {
     "name":"Bocas del Toro Province",
     "isoCode":"1",
     "countryCode":"PA",
     "latitude":"9.41655210",
     "longitude":"-82.52077870"
  },
  {
     "name":"Panamá Oeste Province",
     "isoCode":"10",
     "countryCode":"PA",
     "latitude":"9.11967510",
     "longitude":"-79.29021330"
  },
  {
     "name":"Panamá Province",
     "isoCode":"8",
     "countryCode":"PA",
     "latitude":"9.11967510",
     "longitude":"-79.29021330"
  },
  {
     "name":"Emberá-Wounaan Comarca",
     "isoCode":"EM",
     "countryCode":"PA",
     "latitude":"8.37669830",
     "longitude":"-77.65361250"
  },
  {
     "name":"Chiriquí Province",
     "isoCode":"4",
     "countryCode":"PA",
     "latitude":"8.58489800",
     "longitude":"-82.38857830"
  },
  {
     "name":"Howland Island",
     "isoCode":"UM-84",
     "countryCode":"US",
     "latitude":"0.81132190",
     "longitude":"-176.61827360"
  },
  {
     "name":"Delaware",
     "isoCode":"DE",
     "countryCode":"US",
     "latitude":"38.91083250",
     "longitude":"-75.52766990"
  },
  {
     "name":"Alaska",
     "isoCode":"AK",
     "countryCode":"US",
     "latitude":"64.20084130",
     "longitude":"-149.49367330"
  },
  {
     "name":"Maryland",
     "isoCode":"MD",
     "countryCode":"US",
     "latitude":"39.04575490",
     "longitude":"-76.64127120"
  },
  {
     "name":"Baker Island",
     "isoCode":"UM-81",
     "countryCode":"US",
     "latitude":"0.19362660",
     "longitude":"-176.47690800"
  },
  {
     "name":"Kingman Reef",
     "isoCode":"UM-89",
     "countryCode":"US",
     "latitude":"6.38333300",
     "longitude":"-162.41666700"
  },
  {
     "name":"New Hampshire",
     "isoCode":"NH",
     "countryCode":"US",
     "latitude":"43.19385160",
     "longitude":"-71.57239530"
  },
  {
     "name":"Wake Island",
     "isoCode":"UM-79",
     "countryCode":"US",
     "latitude":"19.27961900",
     "longitude":"166.64993480"
  },
  {
     "name":"Kansas",
     "isoCode":"KS",
     "countryCode":"US",
     "latitude":"39.01190200",
     "longitude":"-98.48424650"
  },
  {
     "name":"Texas",
     "isoCode":"TX",
     "countryCode":"US",
     "latitude":"31.96859880",
     "longitude":"-99.90181310"
  },
  {
     "name":"Nebraska",
     "isoCode":"NE",
     "countryCode":"US",
     "latitude":"41.49253740",
     "longitude":"-99.90181310"
  },
  {
     "name":"Vermont",
     "isoCode":"VT",
     "countryCode":"US",
     "latitude":"44.55880280",
     "longitude":"-72.57784150"
  },
  {
     "name":"Jarvis Island",
     "isoCode":"UM-86",
     "countryCode":"US",
     "latitude":"-0.37435030",
     "longitude":"-159.99672060"
  },
  {
     "name":"Hawaii",
     "isoCode":"HI",
     "countryCode":"US",
     "latitude":"19.89676620",
     "longitude":"-155.58278180"
  },
  {
     "name":"Guam",
     "isoCode":"GU",
     "countryCode":"US",
     "latitude":"13.44430400",
     "longitude":"144.79373100"
  },
  {
     "name":"United States Virgin Islands",
     "isoCode":"VI",
     "countryCode":"US",
     "latitude":"18.33576500",
     "longitude":"-64.89633500"
  },
  {
     "name":"Utah",
     "isoCode":"UT",
     "countryCode":"US",
     "latitude":"39.32098010",
     "longitude":"-111.09373110"
  },
  {
     "name":"Oregon",
     "isoCode":"OR",
     "countryCode":"US",
     "latitude":"43.80413340",
     "longitude":"-120.55420120"
  },
  {
     "name":"California",
     "isoCode":"CA",
     "countryCode":"US",
     "latitude":"36.77826100",
     "longitude":"-119.41793240"
  },
  {
     "name":"New Jersey",
     "isoCode":"NJ",
     "countryCode":"US",
     "latitude":"40.05832380",
     "longitude":"-74.40566120"
  },
  {
     "name":"North Dakota",
     "isoCode":"ND",
     "countryCode":"US",
     "latitude":"47.55149260",
     "longitude":"-101.00201190"
  },
  {
     "name":"Kentucky",
     "isoCode":"KY",
     "countryCode":"US",
     "latitude":"37.83933320",
     "longitude":"-84.27001790"
  },
  {
     "name":"Minnesota",
     "isoCode":"MN",
     "countryCode":"US",
     "latitude":"46.72955300",
     "longitude":"-94.68589980"
  },
  {
     "name":"Oklahoma",
     "isoCode":"OK",
     "countryCode":"US",
     "latitude":"35.46756020",
     "longitude":"-97.51642760"
  },
  {
     "name":"Pennsylvania",
     "isoCode":"PA",
     "countryCode":"US",
     "latitude":"41.20332160",
     "longitude":"-77.19452470"
  },
  {
     "name":"New Mexico",
     "isoCode":"NM",
     "countryCode":"US",
     "latitude":"34.51994020",
     "longitude":"-105.87009010"
  },
  {
     "name":"American Samoa",
     "isoCode":"AS",
     "countryCode":"US",
     "latitude":"-14.27097200",
     "longitude":"-170.13221700"
  },
  {
     "name":"Illinois",
     "isoCode":"IL",
     "countryCode":"US",
     "latitude":"40.63312490",
     "longitude":"-89.39852830"
  },
  {
     "name":"Michigan",
     "isoCode":"MI",
     "countryCode":"US",
     "latitude":"44.31484430",
     "longitude":"-85.60236430"
  },
  {
     "name":"Virginia",
     "isoCode":"VA",
     "countryCode":"US",
     "latitude":"37.43157340",
     "longitude":"-78.65689420"
  },
  {
     "name":"Johnston Atoll",
     "isoCode":"UM-67",
     "countryCode":"US",
     "latitude":"16.72950350",
     "longitude":"-169.53364770"
  },
  {
     "name":"West Virginia",
     "isoCode":"WV",
     "countryCode":"US",
     "latitude":"38.59762620",
     "longitude":"-80.45490260"
  },
  {
     "name":"Mississippi",
     "isoCode":"MS",
     "countryCode":"US",
     "latitude":"32.35466790",
     "longitude":"-89.39852830"
  },
  {
     "name":"Northern Mariana Islands",
     "isoCode":"MP",
     "countryCode":"US",
     "latitude":"15.09790000",
     "longitude":"145.67390000"
  },
  {
     "name":"United States Minor Outlying Islands",
     "isoCode":"UM",
     "countryCode":"US",
     "latitude":"19.28231920",
     "longitude":"166.64704700"
  },
  {
     "name":"Massachusetts",
     "isoCode":"MA",
     "countryCode":"US",
     "latitude":"42.40721070",
     "longitude":"-71.38243740"
  },
  {
     "name":"Arizona",
     "isoCode":"AZ",
     "countryCode":"US",
     "latitude":"34.04892810",
     "longitude":"-111.09373110"
  },
  {
     "name":"Connecticut",
     "isoCode":"CT",
     "countryCode":"US",
     "latitude":"41.60322070",
     "longitude":"-73.08774900"
  },
  {
     "name":"Florida",
     "isoCode":"FL",
     "countryCode":"US",
     "latitude":"27.66482740",
     "longitude":"-81.51575350"
  },
  {
     "name":"District of Columbia",
     "isoCode":"DC",
     "countryCode":"US",
     "latitude":"38.90719230",
     "longitude":"-77.03687070"
  },
  {
     "name":"Midway Atoll",
     "isoCode":"UM-71",
     "countryCode":"US",
     "latitude":"28.20721680",
     "longitude":"-177.37349260"
  },
  {
     "name":"Navassa Island",
     "isoCode":"UM-76",
     "countryCode":"US",
     "latitude":"18.41006890",
     "longitude":"-75.01146120"
  },
  {
     "name":"Indiana",
     "isoCode":"IN",
     "countryCode":"US",
     "latitude":"40.26719410",
     "longitude":"-86.13490190"
  },
  {
     "name":"Wisconsin",
     "isoCode":"WI",
     "countryCode":"US",
     "latitude":"43.78443970",
     "longitude":"-88.78786780"
  },
  {
     "name":"Wyoming",
     "isoCode":"WY",
     "countryCode":"US",
     "latitude":"43.07596780",
     "longitude":"-107.29028390"
  },
  {
     "name":"South Carolina",
     "isoCode":"SC",
     "countryCode":"US",
     "latitude":"33.83608100",
     "longitude":"-81.16372450"
  },
  {
     "name":"Arkansas",
     "isoCode":"AR",
     "countryCode":"US",
     "latitude":"35.20105000",
     "longitude":"-91.83183340"
  },
  {
     "name":"South Dakota",
     "isoCode":"SD",
     "countryCode":"US",
     "latitude":"43.96951480",
     "longitude":"-99.90181310"
  },
  {
     "name":"Montana",
     "isoCode":"MT",
     "countryCode":"US",
     "latitude":"46.87968220",
     "longitude":"-110.36256580"
  },
  {
     "name":"North Carolina",
     "isoCode":"NC",
     "countryCode":"US",
     "latitude":"35.75957310",
     "longitude":"-79.01929970"
  },
  {
     "name":"Palmyra Atoll",
     "isoCode":"UM-95",
     "countryCode":"US",
     "latitude":"5.88850260",
     "longitude":"-162.07866560"
  },
  {
     "name":"Puerto Rico",
     "isoCode":"PR",
     "countryCode":"US",
     "latitude":"18.22083300",
     "longitude":"-66.59014900"
  },
  {
     "name":"Colorado",
     "isoCode":"CO",
     "countryCode":"US",
     "latitude":"39.55005070",
     "longitude":"-105.78206740"
  },
  {
     "name":"Missouri",
     "isoCode":"MO",
     "countryCode":"US",
     "latitude":"37.96425290",
     "longitude":"-91.83183340"
  },
  {
     "name":"New York",
     "isoCode":"NY",
     "countryCode":"US",
     "latitude":"40.71277530",
     "longitude":"-74.00597280"
  },
  {
     "name":"Maine",
     "isoCode":"ME",
     "countryCode":"US",
     "latitude":"45.25378300",
     "longitude":"-69.44546890"
  },
  {
     "name":"Tennessee",
     "isoCode":"TN",
     "countryCode":"US",
     "latitude":"35.51749130",
     "longitude":"-86.58044730"
  },
  {
     "name":"Georgia",
     "isoCode":"GA",
     "countryCode":"US",
     "latitude":"32.16562210",
     "longitude":"-82.90007510"
  },
  {
     "name":"Alabama",
     "isoCode":"AL",
     "countryCode":"US",
     "latitude":"32.31823140",
     "longitude":"-86.90229800"
  },
  {
     "name":"Louisiana",
     "isoCode":"LA",
     "countryCode":"US",
     "latitude":"30.98429770",
     "longitude":"-91.96233270"
  },
  {
     "name":"Nevada",
     "isoCode":"NV",
     "countryCode":"US",
     "latitude":"38.80260970",
     "longitude":"-116.41938900"
  },
  {
     "name":"Iowa",
     "isoCode":"IA",
     "countryCode":"US",
     "latitude":"41.87800250",
     "longitude":"-93.09770200"
  },
  {
     "name":"Idaho",
     "isoCode":"ID",
     "countryCode":"US",
     "latitude":"44.06820190",
     "longitude":"-114.74204080"
  },
  {
     "name":"Rhode Island",
     "isoCode":"RI",
     "countryCode":"US",
     "latitude":"41.58009450",
     "longitude":"-71.47742910"
  },
  {
     "name":"Washington",
     "isoCode":"WA",
     "countryCode":"US",
     "latitude":"47.75107410",
     "longitude":"-120.74013850"
  },
  {
     "name":"Shinyanga Region",
     "isoCode":"22",
     "countryCode":"TZ",
     "latitude":"-3.68099610",
     "longitude":"33.42714030"
  },
  {
     "name":"Simiyu Region",
     "isoCode":"30",
     "countryCode":"TZ",
     "latitude":"-2.83087380",
     "longitude":"34.15319470"
  },
  {
     "name":"Kagera Region",
     "isoCode":"05",
     "countryCode":"TZ",
     "latitude":"-1.30011150",
     "longitude":"31.26263660"
  },
  {
     "name":"Dodoma Region",
     "isoCode":"03",
     "countryCode":"TZ",
     "latitude":"-6.57382280",
     "longitude":"36.26308460"
  },
  {
     "name":"Kilimanjaro Region",
     "isoCode":"09",
     "countryCode":"TZ",
     "latitude":"-4.13369270",
     "longitude":"37.80876930"
  },
  {
     "name":"Mara Region",
     "isoCode":"13",
     "countryCode":"TZ",
     "latitude":"-1.77535380",
     "longitude":"34.15319470"
  },
  {
     "name":"Tabora Region",
     "isoCode":"24",
     "countryCode":"TZ",
     "latitude":"-5.03421380",
     "longitude":"32.80844960"
  },
  {
     "name":"Morogoro Region",
     "isoCode":"16",
     "countryCode":"TZ",
     "latitude":"-8.81371730",
     "longitude":"36.95410700"
  },
  {
     "name":"Zanzibar Central/South Region",
     "isoCode":"11",
     "countryCode":"TZ",
     "latitude":"-6.26428510",
     "longitude":"39.44502810"
  },
  {
     "name":"South Pemba Region",
     "isoCode":"10",
     "countryCode":"TZ",
     "latitude":"-5.31469610",
     "longitude":"39.75495110"
  },
  {
     "name":"Zanzibar North Region",
     "isoCode":"07",
     "countryCode":"TZ",
     "latitude":"-5.93950930",
     "longitude":"39.27910110"
  },
  {
     "name":"Singida Region",
     "isoCode":"23",
     "countryCode":"TZ",
     "latitude":"-6.74533520",
     "longitude":"34.15319470"
  },
  {
     "name":"Zanzibar Urban/West Region",
     "isoCode":"15",
     "countryCode":"TZ",
     "latitude":"-6.22981360",
     "longitude":"39.25832930"
  },
  {
     "name":"Mtwara Region",
     "isoCode":"17",
     "countryCode":"TZ",
     "latitude":"-10.33984550",
     "longitude":"40.16574660"
  },
  {
     "name":"Rukwa Region",
     "isoCode":"20",
     "countryCode":"TZ",
     "latitude":"-8.01094440",
     "longitude":"31.44561790"
  },
  {
     "name":"Kigoma Region",
     "isoCode":"08",
     "countryCode":"TZ",
     "latitude":"-4.88240920",
     "longitude":"29.66150550"
  },
  {
     "name":"Mwanza Region",
     "isoCode":"18",
     "countryCode":"TZ",
     "latitude":"-2.46711970",
     "longitude":"32.89868120"
  },
  {
     "name":"Njombe Region",
     "isoCode":"29",
     "countryCode":"TZ",
     "latitude":"-9.24226320",
     "longitude":"35.12687810"
  },
  {
     "name":"Geita Region",
     "isoCode":"27",
     "countryCode":"TZ",
     "latitude":"-2.82422570",
     "longitude":"32.26538870"
  },
  {
     "name":"Katavi Region",
     "isoCode":"28",
     "countryCode":"TZ",
     "latitude":"-6.36771250",
     "longitude":"31.26263660"
  },
  {
     "name":"Lindi Region",
     "isoCode":"12",
     "countryCode":"TZ",
     "latitude":"-9.23433940",
     "longitude":"38.31657250"
  },
  {
     "name":"Manyara Region",
     "isoCode":"26",
     "countryCode":"TZ",
     "latitude":"-4.31500580",
     "longitude":"36.95410700"
  },
  {
     "name":"Pwani Region",
     "isoCode":"19",
     "countryCode":"TZ",
     "latitude":"-7.32377140",
     "longitude":"38.82054540"
  },
  {
     "name":"Ruvuma Region",
     "isoCode":"21",
     "countryCode":"TZ",
     "latitude":"-10.68787170",
     "longitude":"36.26308460"
  },
  {
     "name":"Tanga Region",
     "isoCode":"25",
     "countryCode":"TZ",
     "latitude":"-5.30497890",
     "longitude":"38.31657250"
  },
  {
     "name":"North Pemba Region",
     "isoCode":"06",
     "countryCode":"TZ",
     "latitude":"-5.03193520",
     "longitude":"39.77555710"
  },
  {
     "name":"Iringa Region",
     "isoCode":"04",
     "countryCode":"TZ",
     "latitude":"-7.78874420",
     "longitude":"35.56578620"
  },
  {
     "name":"Dar es Salaam Region",
     "isoCode":"02",
     "countryCode":"TZ",
     "latitude":"-6.79235400",
     "longitude":"39.20832840"
  },
  {
     "name":"Arusha Region",
     "isoCode":"01",
     "countryCode":"TZ",
     "latitude":"-3.38692540",
     "longitude":"36.68299270"
  },
  {
     "name":"Eastern Finland Province",
     "isoCode":"IS",
     "countryCode":"FI",
     "latitude":"62.56338910",
     "longitude":"28.50240420"
  },
  {
     "name":"Tavastia Proper",
     "isoCode":"06",
     "countryCode":"FI",
     "latitude":"60.90701500",
     "longitude":"24.30054980"
  },
  {
     "name":"Central Ostrobothnia",
     "isoCode":"07",
     "countryCode":"FI",
     "latitude":"63.56217350",
     "longitude":"24.00136310"
  },
  {
     "name":"Southern Savonia",
     "isoCode":"04",
     "countryCode":"FI",
     "latitude":"61.69451480",
     "longitude":"27.80050150"
  },
  {
     "name":"Kainuu",
     "isoCode":"05",
     "countryCode":"FI",
     "latitude":"64.37365640",
     "longitude":"28.74374750"
  },
  {
     "name":"South Karelia",
     "isoCode":"02",
     "countryCode":"FI",
     "latitude":"61.11819490",
     "longitude":"28.10243720"
  },
  {
     "name":"Southern Ostrobothnia",
     "isoCode":"03",
     "countryCode":"FI",
     "latitude":"62.94330990",
     "longitude":"23.52852670"
  },
  {
     "name":"Oulu Province",
     "isoCode":"OL",
     "countryCode":"FI",
     "latitude":"65.01207480",
     "longitude":"25.46504960"
  },
  {
     "name":"Lapland",
     "isoCode":"LL",
     "countryCode":"FI",
     "latitude":"67.92223040",
     "longitude":"26.50464380"
  },
  {
     "name":"Satakunta",
     "isoCode":"17",
     "countryCode":"FI",
     "latitude":"61.59327580",
     "longitude":"22.14830810"
  },
  {
     "name":"Päijänne Tavastia",
     "isoCode":"16",
     "countryCode":"FI",
     "latitude":"61.32300410",
     "longitude":"25.73224960"
  },
  {
     "name":"Northern Savonia",
     "isoCode":"15",
     "countryCode":"FI",
     "latitude":"63.08448000",
     "longitude":"27.02535040"
  },
  {
     "name":"North Karelia",
     "isoCode":"13",
     "countryCode":"FI",
     "latitude":"62.80620780",
     "longitude":"30.15538870"
  },
  {
     "name":"Northern Ostrobothnia",
     "isoCode":"14",
     "countryCode":"FI",
     "latitude":"65.27949300",
     "longitude":"26.28904170"
  },
  {
     "name":"Pirkanmaa",
     "isoCode":"11",
     "countryCode":"FI",
     "latitude":"61.69869180",
     "longitude":"23.78955980"
  },
  {
     "name":"Finland Proper",
     "isoCode":"19",
     "countryCode":"FI",
     "latitude":"60.36279140",
     "longitude":"22.44393690"
  },
  {
     "name":"Ostrobothnia",
     "isoCode":"12",
     "countryCode":"FI",
     "latitude":"63.11817570",
     "longitude":"21.90610620"
  },
  {
     "name":"Åland Islands",
     "isoCode":"01",
     "countryCode":"FI",
     "latitude":"60.17852470",
     "longitude":"19.91561050"
  },
  {
     "name":"Uusimaa",
     "isoCode":"18",
     "countryCode":"FI",
     "latitude":"60.21872000",
     "longitude":"25.27162100"
  },
  {
     "name":"Central Finland",
     "isoCode":"08",
     "countryCode":"FI",
     "latitude":"62.56667430",
     "longitude":"25.55494450"
  },
  {
     "name":"Kymenlaakso",
     "isoCode":"09",
     "countryCode":"FI",
     "latitude":"60.78051200",
     "longitude":"26.88293360"
  },
  {
     "name":"Canton of Diekirch",
     "isoCode":"DI",
     "countryCode":"LU",
     "latitude":"49.86717840",
     "longitude":"6.15956330"
  },
  {
     "name":"Luxembourg District",
     "isoCode":"L",
     "countryCode":"LU",
     "latitude":"49.59537060",
     "longitude":"6.13331780"
  },
  {
     "name":"Canton of Echternach",
     "isoCode":"EC",
     "countryCode":"LU",
     "latitude":"49.81141330",
     "longitude":"6.41756350"
  },
  {
     "name":"Canton of Redange",
     "isoCode":"RD",
     "countryCode":"LU",
     "latitude":"49.76455000",
     "longitude":"5.88948000"
  },
  {
     "name":"Canton of Esch-sur-Alzette",
     "isoCode":"ES",
     "countryCode":"LU",
     "latitude":"49.50088050",
     "longitude":"5.98609250"
  },
  {
     "name":"Canton of Capellen",
     "isoCode":"CA",
     "countryCode":"LU",
     "latitude":"49.64039310",
     "longitude":"5.95538460"
  },
  {
     "name":"Canton of Remich",
     "isoCode":"RM",
     "countryCode":"LU",
     "latitude":"49.54501700",
     "longitude":"6.36742220"
  },
  {
     "name":"Grevenmacher District",
     "isoCode":"G",
     "countryCode":"LU",
     "latitude":"49.68085100",
     "longitude":"6.44075240"
  },
  {
     "name":"Canton of Clervaux",
     "isoCode":"CL",
     "countryCode":"LU",
     "latitude":"50.05463130",
     "longitude":"6.02858750"
  },
  {
     "name":"Canton of Mersch",
     "isoCode":"ME",
     "countryCode":"LU",
     "latitude":"49.75429060",
     "longitude":"6.12921850"
  },
  {
     "name":"Canton of Vianden",
     "isoCode":"VD",
     "countryCode":"LU",
     "latitude":"49.93419240",
     "longitude":"6.20199170"
  },
  {
     "name":"Diekirch District",
     "isoCode":"D",
     "countryCode":"LU",
     "latitude":"49.86717200",
     "longitude":"6.15963620"
  },
  {
     "name":"Canton of Grevenmacher",
     "isoCode":"GR",
     "countryCode":"LU",
     "latitude":"49.68084100",
     "longitude":"6.44075930"
  },
  {
     "name":"Canton of Wiltz",
     "isoCode":"WI",
     "countryCode":"LU",
     "latitude":"49.96622000",
     "longitude":"5.93243060"
  },
  {
     "name":"Canton of Luxembourg",
     "isoCode":"LU",
     "countryCode":"LU",
     "latitude":"49.63010250",
     "longitude":"6.15201850"
  },
  {
     "name":"Region Zealand",
     "isoCode":"85",
     "countryCode":"DK",
     "latitude":"55.46325180",
     "longitude":"11.72149790"
  },
  {
     "name":"Region of Southern Denmark",
     "isoCode":"83",
     "countryCode":"DK",
     "latitude":"55.33077140",
     "longitude":"9.09249030"
  },
  {
     "name":"Capital Region of Denmark",
     "isoCode":"84",
     "countryCode":"DK",
     "latitude":"55.67518120",
     "longitude":"12.54932610"
  },
  {
     "name":"Central Denmark Region",
     "isoCode":"82",
     "countryCode":"DK",
     "latitude":"56.30213900",
     "longitude":"9.30277700"
  },
  {
     "name":"North Denmark Region",
     "isoCode":"81",
     "countryCode":"DK",
     "latitude":"56.83074160",
     "longitude":"9.49305270"
  },
  {
     "name":"Gävleborg County",
     "isoCode":"X",
     "countryCode":"SE",
     "latitude":"61.30119930",
     "longitude":"16.15342140"
  },
  {
     "name":"Dalarna County",
     "isoCode":"W",
     "countryCode":"SE",
     "latitude":"61.09170120",
     "longitude":"14.66636530"
  },
  {
     "name":"Värmland County",
     "isoCode":"S",
     "countryCode":"SE",
     "latitude":"59.72940650",
     "longitude":"13.23540240"
  },
  {
     "name":"Östergötland County",
     "isoCode":"E",
     "countryCode":"SE",
     "latitude":"58.34536350",
     "longitude":"15.51978440"
  },
  {
     "name":"Blekinge",
     "isoCode":"K",
     "countryCode":"SE",
     "latitude":"56.27838370",
     "longitude":"15.01800580"
  },
  {
     "name":"Norrbotten County",
     "isoCode":"BD",
     "countryCode":"SE",
     "latitude":"66.83092160",
     "longitude":"20.39919660"
  },
  {
     "name":"Örebro County",
     "isoCode":"T",
     "countryCode":"SE",
     "latitude":"59.53503600",
     "longitude":"15.00657310"
  },
  {
     "name":"Södermanland County",
     "isoCode":"D",
     "countryCode":"SE",
     "latitude":"59.03363490",
     "longitude":"16.75188990"
  },
  {
     "name":"Skåne County",
     "isoCode":"M",
     "countryCode":"SE",
     "latitude":"55.99025720",
     "longitude":"13.59576920"
  },
  {
     "name":"Kronoberg County",
     "isoCode":"G",
     "countryCode":"SE",
     "latitude":"56.71834030",
     "longitude":"14.41146730"
  },
  {
     "name":"Västerbotten County",
     "isoCode":"AC",
     "countryCode":"SE",
     "latitude":"65.33373110",
     "longitude":"16.51616940"
  },
  {
     "name":"Kalmar County",
     "isoCode":"H",
     "countryCode":"SE",
     "latitude":"57.23501560",
     "longitude":"16.18493490"
  },
  {
     "name":"Uppsala County",
     "isoCode":"C",
     "countryCode":"SE",
     "latitude":"60.00922620",
     "longitude":"17.27145880"
  },
  {
     "name":"Gotland County",
     "isoCode":"I",
     "countryCode":"SE",
     "latitude":"57.46841210",
     "longitude":"18.48674470"
  },
  {
     "name":"Västra Götaland County",
     "isoCode":"O",
     "countryCode":"SE",
     "latitude":"58.25279260",
     "longitude":"13.05964250"
  },
  {
     "name":"Halland County",
     "isoCode":"N",
     "countryCode":"SE",
     "latitude":"56.89668050",
     "longitude":"12.80339930"
  },
  {
     "name":"Västmanland County",
     "isoCode":"U",
     "countryCode":"SE",
     "latitude":"59.67138790",
     "longitude":"16.21589530"
  },
  {
     "name":"Jönköping County",
     "isoCode":"F",
     "countryCode":"SE",
     "latitude":"57.37084340",
     "longitude":"14.34391740"
  },
  {
     "name":"Stockholm County",
     "isoCode":"AB",
     "countryCode":"SE",
     "latitude":"59.60249580",
     "longitude":"18.13843830"
  },
  {
     "name":"Västernorrland County",
     "isoCode":"Y",
     "countryCode":"SE",
     "latitude":"63.42764730",
     "longitude":"17.72924440"
  },
  {
     "name":"Plungė District Municipality",
     "isoCode":"35",
     "countryCode":"LT",
     "latitude":"55.91078400",
     "longitude":"21.84540690"
  },
  {
     "name":"Šiauliai District Municipality",
     "isoCode":"44",
     "countryCode":"LT",
     "latitude":"55.97214560",
     "longitude":"23.03323710"
  },
  {
     "name":"Jurbarkas District Municipality",
     "isoCode":"12",
     "countryCode":"LT",
     "latitude":"55.07740700",
     "longitude":"22.74195690"
  },
  {
     "name":"Kaunas County",
     "isoCode":"KU",
     "countryCode":"LT",
     "latitude":"54.98728630",
     "longitude":"23.95257360"
  },
  {
     "name":"Mažeikiai District Municipality",
     "isoCode":"26",
     "countryCode":"LT",
     "latitude":"56.30924390",
     "longitude":"22.34146800"
  },
  {
     "name":"Panevėžys County",
     "isoCode":"PN",
     "countryCode":"LT",
     "latitude":"55.97480490",
     "longitude":"25.07947670"
  },
  {
     "name":"Elektrėnai municipality",
     "isoCode":"08",
     "countryCode":"LT",
     "latitude":"54.76539340",
     "longitude":"24.77405830"
  },
  {
     "name":"Švenčionys District Municipality",
     "isoCode":"49",
     "countryCode":"LT",
     "latitude":"55.10280980",
     "longitude":"26.00718550"
  },
  {
     "name":"Akmenė District Municipality",
     "isoCode":"01",
     "countryCode":"LT",
     "latitude":"56.24550290",
     "longitude":"22.74711690"
  },
  {
     "name":"Ignalina District Municipality",
     "isoCode":"09",
     "countryCode":"LT",
     "latitude":"55.40903420",
     "longitude":"26.32848930"
  },
  {
     "name":"Neringa Municipality",
     "isoCode":"28",
     "countryCode":"LT",
     "latitude":"55.45724030",
     "longitude":"21.08390050"
  },
  {
     "name":"Visaginas Municipality",
     "isoCode":"59",
     "countryCode":"LT",
     "latitude":"55.59411800",
     "longitude":"26.43079540"
  },
  {
     "name":"Kaunas District Municipality",
     "isoCode":"16",
     "countryCode":"LT",
     "latitude":"54.99362360",
     "longitude":"23.63249410"
  },
  {
     "name":"Biržai District Municipality",
     "isoCode":"06",
     "countryCode":"LT",
     "latitude":"56.20177190",
     "longitude":"24.75601180"
  },
  {
     "name":"Jonava District Municipality",
     "isoCode":"10",
     "countryCode":"LT",
     "latitude":"55.07272420",
     "longitude":"24.27933370"
  },
  {
     "name":"Radviliškis District Municipality",
     "isoCode":"37",
     "countryCode":"LT",
     "latitude":"55.81083990",
     "longitude":"23.54648700"
  },
  {
     "name":"Telšiai County",
     "isoCode":"TE",
     "countryCode":"LT",
     "latitude":"56.10266160",
     "longitude":"22.11139150"
  },
  {
     "name":"Marijampolė County",
     "isoCode":"MR",
     "countryCode":"LT",
     "latitude":"54.78199710",
     "longitude":"23.13413650"
  },
  {
     "name":"Kretinga District Municipality",
     "isoCode":"22",
     "countryCode":"LT",
     "latitude":"55.88384200",
     "longitude":"21.23509190"
  },
  {
     "name":"Tauragė District Municipality",
     "isoCode":"50",
     "countryCode":"LT",
     "latitude":"55.25036600",
     "longitude":"22.29095000"
  },
  {
     "name":"Tauragė County",
     "isoCode":"TA",
     "countryCode":"LT",
     "latitude":"55.30725860",
     "longitude":"22.35729390"
  },
  {
     "name":"Alytus County",
     "isoCode":"AL",
     "countryCode":"LT",
     "latitude":"54.20002140",
     "longitude":"24.15126340"
  },
  {
     "name":"Kazlų Rūda municipality",
     "isoCode":"17",
     "countryCode":"LT",
     "latitude":"54.78075260",
     "longitude":"23.48402430"
  },
  {
     "name":"Šakiai District Municipality",
     "isoCode":"41",
     "countryCode":"LT",
     "latitude":"54.95267100",
     "longitude":"23.04801990"
  },
  {
     "name":"Šalčininkai District Municipality",
     "isoCode":"42",
     "countryCode":"LT",
     "latitude":"54.30976700",
     "longitude":"25.38756400"
  },
  {
     "name":"Prienai District Municipality",
     "isoCode":"36",
     "countryCode":"LT",
     "latitude":"54.63835800",
     "longitude":"23.94680090"
  },
  {
     "name":"Druskininkai municipality",
     "isoCode":"07",
     "countryCode":"LT",
     "latitude":"53.99336850",
     "longitude":"24.03424380"
  },
  {
     "name":"Kaunas City Municipality",
     "isoCode":"15",
     "countryCode":"LT",
     "latitude":"54.91453260",
     "longitude":"23.90535180"
  },
  {
     "name":"Joniškis District Municipality",
     "isoCode":"11",
     "countryCode":"LT",
     "latitude":"56.23607300",
     "longitude":"23.61365790"
  },
  {
     "name":"Molėtai District Municipality",
     "isoCode":"27",
     "countryCode":"LT",
     "latitude":"55.22653090",
     "longitude":"25.41800110"
  },
  {
     "name":"Kaišiadorys District Municipality",
     "isoCode":"13",
     "countryCode":"LT",
     "latitude":"54.85886690",
     "longitude":"24.42779290"
  },
  {
     "name":"Kėdainiai District Municipality",
     "isoCode":"18",
     "countryCode":"LT",
     "latitude":"55.35609470",
     "longitude":"23.98326830"
  },
  {
     "name":"Kupiškis District Municipality",
     "isoCode":"23",
     "countryCode":"LT",
     "latitude":"55.84287410",
     "longitude":"25.02958160"
  },
  {
     "name":"Šiauliai County",
     "isoCode":"SA",
     "countryCode":"LT",
     "latitude":"55.99857510",
     "longitude":"23.13800510"
  },
  {
     "name":"Raseiniai District Municipality",
     "isoCode":"38",
     "countryCode":"LT",
     "latitude":"55.38194990",
     "longitude":"23.11561290"
  },
  {
     "name":"Palanga City Municipality",
     "isoCode":"31",
     "countryCode":"LT",
     "latitude":"55.92019800",
     "longitude":"21.06776140"
  },
  {
     "name":"Panevėžys City Municipality",
     "isoCode":"32",
     "countryCode":"LT",
     "latitude":"55.73479150",
     "longitude":"24.35747740"
  },
  {
     "name":"Rietavas municipality",
     "isoCode":"39",
     "countryCode":"LT",
     "latitude":"55.70217190",
     "longitude":"21.99865640"
  },
  {
     "name":"Kalvarija municipality",
     "isoCode":"14",
     "countryCode":"LT",
     "latitude":"54.37616740",
     "longitude":"23.19203210"
  },
  {
     "name":"Vilnius District Municipality",
     "isoCode":"58",
     "countryCode":"LT",
     "latitude":"54.77325780",
     "longitude":"25.58671130"
  },
  {
     "name":"Trakai District Municipality",
     "isoCode":"52",
     "countryCode":"LT",
     "latitude":"54.63791130",
     "longitude":"24.93468940"
  },
  {
     "name":"Širvintos District Municipality",
     "isoCode":"47",
     "countryCode":"LT",
     "latitude":"55.04310200",
     "longitude":"24.95698100"
  },
  {
     "name":"Pakruojis District Municipality",
     "isoCode":"30",
     "countryCode":"LT",
     "latitude":"56.07326050",
     "longitude":"23.93899060"
  },
  {
     "name":"Ukmergė District Municipality",
     "isoCode":"53",
     "countryCode":"LT",
     "latitude":"55.24526500",
     "longitude":"24.77607490"
  },
  {
     "name":"Klaipeda City Municipality",
     "isoCode":"20",
     "countryCode":"LT",
     "latitude":"55.70329480",
     "longitude":"21.14427950"
  },
  {
     "name":"Utena District Municipality",
     "isoCode":"54",
     "countryCode":"LT",
     "latitude":"55.50846140",
     "longitude":"25.68326420"
  },
  {
     "name":"Alytus District Municipality",
     "isoCode":"03",
     "countryCode":"LT",
     "latitude":"54.32974960",
     "longitude":"24.19609310"
  },
  {
     "name":"Klaipėda County",
     "isoCode":"KL",
     "countryCode":"LT",
     "latitude":"55.65197440",
     "longitude":"21.37439560"
  },
  {
     "name":"Vilnius County",
     "isoCode":"VL",
     "countryCode":"LT",
     "latitude":"54.80865020",
     "longitude":"25.21821390"
  },
  {
     "name":"Varėna District Municipality",
     "isoCode":"55",
     "countryCode":"LT",
     "latitude":"54.22033300",
     "longitude":"24.57899700"
  },
  {
     "name":"Birštonas Municipality",
     "isoCode":"05",
     "countryCode":"LT",
     "latitude":"54.56696640",
     "longitude":"24.00930980"
  },
  {
     "name":"Klaipėda District Municipality",
     "isoCode":"21",
     "countryCode":"LT",
     "latitude":"55.68416150",
     "longitude":"21.44164640"
  },
  {
     "name":"Alytus City Municipality",
     "isoCode":"02",
     "countryCode":"LT",
     "latitude":"54.39629380",
     "longitude":"24.04587610"
  },
  {
     "name":"Vilnius City Municipality",
     "isoCode":"57",
     "countryCode":"LT",
     "latitude":"54.67107610",
     "longitude":"25.28787210"
  },
  {
     "name":"Šilutė District Municipality",
     "isoCode":"46",
     "countryCode":"LT",
     "latitude":"55.35041400",
     "longitude":"21.46598590"
  },
  {
     "name":"Telšiai District Municipality",
     "isoCode":"51",
     "countryCode":"LT",
     "latitude":"55.91752150",
     "longitude":"22.34518400"
  },
  {
     "name":"Šiauliai City Municipality",
     "isoCode":"43",
     "countryCode":"LT",
     "latitude":"55.93490850",
     "longitude":"23.31368230"
  },
  {
     "name":"Marijampolė Municipality",
     "isoCode":"25",
     "countryCode":"LT",
     "latitude":"54.57110940",
     "longitude":"23.48593710"
  },
  {
     "name":"Lazdijai District Municipality",
     "isoCode":"24",
     "countryCode":"LT",
     "latitude":"54.23432670",
     "longitude":"23.51565050"
  },
  {
     "name":"Pagėgiai municipality",
     "isoCode":"29",
     "countryCode":"LT",
     "latitude":"55.17213200",
     "longitude":"21.96836140"
  },
  {
     "name":"Šilalė District Municipality",
     "isoCode":"45",
     "countryCode":"LT",
     "latitude":"55.49268000",
     "longitude":"22.18455590"
  },
  {
     "name":"Panevėžys District Municipality",
     "isoCode":"33",
     "countryCode":"LT",
     "latitude":"55.61667280",
     "longitude":"24.31422830"
  },
  {
     "name":"Rokiškis District Municipality",
     "isoCode":"40",
     "countryCode":"LT",
     "latitude":"55.95550390",
     "longitude":"25.58592490"
  },
  {
     "name":"Pasvalys District Municipality",
     "isoCode":"34",
     "countryCode":"LT",
     "latitude":"56.06046190",
     "longitude":"24.39629100"
  },
  {
     "name":"Skuodas District Municipality",
     "isoCode":"48",
     "countryCode":"LT",
     "latitude":"56.27021690",
     "longitude":"21.52143310"
  },
  {
     "name":"Kelmė District Municipality",
     "isoCode":"19",
     "countryCode":"LT",
     "latitude":"55.62663520",
     "longitude":"22.87817200"
  },
  {
     "name":"Zarasai District Municipality",
     "isoCode":"60",
     "countryCode":"LT",
     "latitude":"55.73096090",
     "longitude":"26.24529500"
  },
  {
     "name":"Vilkaviškis District Municipality",
     "isoCode":"56",
     "countryCode":"LT",
     "latitude":"54.65194500",
     "longitude":"23.03515500"
  },
  {
     "name":"Utena County",
     "isoCode":"UT",
     "countryCode":"LT",
     "latitude":"55.53189690",
     "longitude":"25.79046990"
  },
  {
     "name":"Opole Voivodeship",
     "isoCode":"OP",
     "countryCode":"PL",
     "latitude":"50.80037610",
     "longitude":"17.93798900"
  },
  {
     "name":"Silesian Voivodeship",
     "isoCode":"SL",
     "countryCode":"PL",
     "latitude":"50.57165950",
     "longitude":"19.32197680"
  },
  {
     "name":"Pomeranian Voivodeship",
     "isoCode":"PM",
     "countryCode":"PL",
     "latitude":"54.29442520",
     "longitude":"18.15311640"
  },
  {
     "name":"Kuyavian-Pomeranian Voivodeship",
     "isoCode":"KP",
     "countryCode":"PL",
     "latitude":"53.16483630",
     "longitude":"18.48342240"
  },
  {
     "name":"Podkarpackie Voivodeship",
     "isoCode":"PK",
     "countryCode":"PL",
     "latitude":"50.05747490",
     "longitude":"22.08956910"
  },
  {
     "name":"Warmian-Masurian Voivodeship",
     "isoCode":"WN",
     "countryCode":"PL",
     "latitude":"53.86711170",
     "longitude":"20.70278610"
  },
  {
     "name":"Lower Silesian Voivodeship",
     "isoCode":"DS",
     "countryCode":"PL",
     "latitude":"51.13398610",
     "longitude":"16.88419610"
  },
  {
     "name":"Świętokrzyskie Voivodeship",
     "isoCode":"SK",
     "countryCode":"PL",
     "latitude":"50.62610410",
     "longitude":"20.94062790"
  },
  {
     "name":"Lubusz Voivodeship",
     "isoCode":"LB",
     "countryCode":"PL",
     "latitude":"52.22746120",
     "longitude":"15.25591030"
  },
  {
     "name":"Podlaskie Voivodeship",
     "isoCode":"PD",
     "countryCode":"PL",
     "latitude":"53.06971590",
     "longitude":"22.96746390"
  },
  {
     "name":"West Pomeranian Voivodeship",
     "isoCode":"ZP",
     "countryCode":"PL",
     "latitude":"53.46578910",
     "longitude":"15.18225810"
  },
  {
     "name":"Greater Poland Voivodeship",
     "isoCode":"WP",
     "countryCode":"PL",
     "latitude":"52.27998600",
     "longitude":"17.35229390"
  },
  {
     "name":"Lesser Poland Voivodeship",
     "isoCode":"MA",
     "countryCode":"PL",
     "latitude":"49.72253060",
     "longitude":"20.25033580"
  },
  {
     "name":"Łódź Voivodeship",
     "isoCode":"LD",
     "countryCode":"PL",
     "latitude":"51.46347710",
     "longitude":"19.17269740"
  },
  {
     "name":"Masovian Voivodeship",
     "isoCode":"MZ",
     "countryCode":"PL",
     "latitude":"51.89271820",
     "longitude":"21.00216790"
  },
  {
     "name":"Lublin Voivodeship",
     "isoCode":"LU",
     "countryCode":"PL",
     "latitude":"51.24935190",
     "longitude":"23.10113920"
  },
  {
     "name":"Aargau",
     "isoCode":"AG",
     "countryCode":"CH",
     "latitude":"47.38766640",
     "longitude":"8.25542950"
  },
  {
     "name":"Canton of Fribourg",
     "isoCode":"FR",
     "countryCode":"CH",
     "latitude":"46.68167480",
     "longitude":"7.11726350"
  },
  {
     "name":"Basel-Landschaft",
     "isoCode":"BL",
     "countryCode":"CH",
     "latitude":"47.44181220",
     "longitude":"7.76440020"
  },
  {
     "name":"Uri",
     "isoCode":"UR",
     "countryCode":"CH",
     "latitude":"41.48606470",
     "longitude":"-71.53085370"
  },
  {
     "name":"Ticino",
     "isoCode":"TI",
     "countryCode":"CH",
     "latitude":"46.33173400",
     "longitude":"8.80045290"
  },
  {
     "name":"Canton of St. Gallen",
     "isoCode":"SG",
     "countryCode":"CH",
     "latitude":"47.14562540",
     "longitude":"9.35043320"
  },
  {
     "name":"canton of Bern",
     "isoCode":"BE",
     "countryCode":"CH",
     "latitude":"46.79886210",
     "longitude":"7.70807010"
  },
  {
     "name":"Canton of Zug",
     "isoCode":"ZG",
     "countryCode":"CH",
     "latitude":"47.16615050",
     "longitude":"8.51547490"
  },
  {
     "name":"Canton of Geneva",
     "isoCode":"GE",
     "countryCode":"CH",
     "latitude":"46.21800730",
     "longitude":"6.12169250"
  },
  {
     "name":"Canton of Valais",
     "isoCode":"VS",
     "countryCode":"CH",
     "latitude":"46.19046140",
     "longitude":"7.54492260"
  },
  {
     "name":"Appenzell Innerrhoden",
     "isoCode":"AI",
     "countryCode":"CH",
     "latitude":"47.31619250",
     "longitude":"9.43165730"
  },
  {
     "name":"Obwalden",
     "isoCode":"OW",
     "countryCode":"CH",
     "latitude":"46.87785800",
     "longitude":"8.25124900"
  },
  {
     "name":"Canton of Vaud",
     "isoCode":"VD",
     "countryCode":"CH",
     "latitude":"46.56131350",
     "longitude":"6.53676500"
  },
  {
     "name":"Nidwalden",
     "isoCode":"NW",
     "countryCode":"CH",
     "latitude":"46.92670160",
     "longitude":"8.38499820"
  },
  {
     "name":"Schwyz",
     "isoCode":"SZ",
     "countryCode":"CH",
     "latitude":"47.02071380",
     "longitude":"8.65298840"
  },
  {
     "name":"Canton of Schaffhausen",
     "isoCode":"SH",
     "countryCode":"CH",
     "latitude":"47.70093640",
     "longitude":"8.56800400"
  },
  {
     "name":"Appenzell Ausserrhoden",
     "isoCode":"AR",
     "countryCode":"CH",
     "latitude":"47.36648100",
     "longitude":"9.30009160"
  },
  {
     "name":"canton of Zürich",
     "isoCode":"ZH",
     "countryCode":"CH",
     "latitude":"47.35953600",
     "longitude":"8.63564520"
  },
  {
     "name":"Thurgau",
     "isoCode":"TG",
     "countryCode":"CH",
     "latitude":"47.60378560",
     "longitude":"9.05573710"
  },
  {
     "name":"Canton of Jura",
     "isoCode":"JU",
     "countryCode":"CH",
     "latitude":"47.34444740",
     "longitude":"7.14306080"
  },
  {
     "name":"Canton of Neuchâtel",
     "isoCode":"NE",
     "countryCode":"CH",
     "latitude":"46.98998740",
     "longitude":"6.92927320"
  },
  {
     "name":"Graubünden",
     "isoCode":"GR",
     "countryCode":"CH",
     "latitude":"46.65698710",
     "longitude":"9.57802570"
  },
  {
     "name":"Glarus",
     "isoCode":"GL",
     "countryCode":"CH",
     "latitude":"47.04112320",
     "longitude":"9.06790000"
  },
  {
     "name":"Canton of Solothurn",
     "isoCode":"SO",
     "countryCode":"CH",
     "latitude":"47.33207170",
     "longitude":"7.63883850"
  },
  {
     "name":"Canton of Lucerne",
     "isoCode":"LU",
     "countryCode":"CH",
     "latitude":"47.07956710",
     "longitude":"8.16624450"
  },
  {
     "name":"Tuscany",
     "isoCode":"52",
     "countryCode":"IT",
     "latitude":"43.77105130",
     "longitude":"11.24862080"
  },
  {
     "name":"Province of Padua",
     "isoCode":"PD",
     "countryCode":"IT",
     "latitude":"45.36618640",
     "longitude":"11.82091390"
  },
  {
     "name":"Province of Parma",
     "isoCode":"PR",
     "countryCode":"IT",
     "latitude":"44.80153220",
     "longitude":"10.32793540"
  },
  {
     "name":"Libero consorzio comunale di Siracusa",
     "isoCode":"SR",
     "countryCode":"IT",
     "latitude":"37.06569240",
     "longitude":"15.28571090"
  },
  {
     "name":"Metropolitan City of Palermo",
     "isoCode":"PA",
     "countryCode":"IT",
     "latitude":"38.11569000",
     "longitude":"13.36148680"
  },
  {
     "name":"Campania",
     "isoCode":"72",
     "countryCode":"IT",
     "latitude":"40.66708870",
     "longitude":"15.10681130"
  },
  {
     "name":"Marche",
     "isoCode":"57",
     "countryCode":"IT",
     "latitude":"30.55670700",
     "longitude":"-81.44930300"
  },
  {
     "name":"Metropolitan City of Reggio Calabria",
     "isoCode":"RC",
     "countryCode":"IT",
     "latitude":"38.10843960",
     "longitude":"15.64370480"
  },
  {
     "name":"Province of Ancona",
     "isoCode":"AN",
     "countryCode":"IT",
     "latitude":"43.54932450",
     "longitude":"13.26634790"
  },
  {
     "name":"Metropolitan City of Venice",
     "isoCode":"VE",
     "countryCode":"IT",
     "latitude":"45.44146850",
     "longitude":"12.31526720"
  },
  {
     "name":"Province of Latina",
     "isoCode":"LT",
     "countryCode":"IT",
     "latitude":"41.40874760",
     "longitude":"13.08179030"
  },
  {
     "name":"Province of Lecce",
     "isoCode":"LE",
     "countryCode":"IT",
     "latitude":"40.23473930",
     "longitude":"18.14286690"
  },
  {
     "name":"Province of Pavia",
     "isoCode":"PV",
     "countryCode":"IT",
     "latitude":"45.32181660",
     "longitude":"8.84662360"
  },
  {
     "name":"Province of Lecco",
     "isoCode":"LC",
     "countryCode":"IT",
     "latitude":"45.93829410",
     "longitude":"9.38572900"
  },
  {
     "name":"Lazio",
     "isoCode":"62",
     "countryCode":"IT",
     "latitude":"45.69916670",
     "longitude":"-73.65583330"
  },
  {
     "name":"Abruzzo",
     "isoCode":"65",
     "countryCode":"IT",
     "latitude":"42.19201190",
     "longitude":"13.72891670"
  },
  {
     "name":"Metropolitan City of Florence",
     "isoCode":"FI",
     "countryCode":"IT",
     "latitude":"43.76791780",
     "longitude":"11.25237920"
  },
  {
     "name":"Province of Ascoli Piceno",
     "isoCode":"AP",
     "countryCode":"IT",
     "latitude":"42.86389330",
     "longitude":"13.58997330"
  },
  {
     "name":"Metropolitan City of Cagliari",
     "isoCode":"CA",
     "countryCode":"IT",
     "latitude":"39.22384110",
     "longitude":"9.12166130"
  },
  {
     "name":"Umbria",
     "isoCode":"55",
     "countryCode":"IT",
     "latitude":"42.93800400",
     "longitude":"12.62162110"
  },
  {
     "name":"Metropolitan City of Bologna",
     "isoCode":"BO",
     "countryCode":"IT",
     "latitude":"44.49488700",
     "longitude":"11.34261620"
  },
  {
     "name":"Province of Pisa",
     "isoCode":"PI",
     "countryCode":"IT",
     "latitude":"43.72283150",
     "longitude":"10.40171940"
  },
  {
     "name":"Province of Barletta-Andria-Trani",
     "isoCode":"BT",
     "countryCode":"IT",
     "latitude":"41.20045430",
     "longitude":"16.20514840"
  },
  {
     "name":"Province of Pistoia",
     "isoCode":"PT",
     "countryCode":"IT",
     "latitude":"43.95437330",
     "longitude":"10.89030990"
  },
  {
     "name":"Apulia",
     "isoCode":"75",
     "countryCode":"IT",
     "latitude":"40.79283930",
     "longitude":"17.10119310"
  },
  {
     "name":"Province of Belluno",
     "isoCode":"BL",
     "countryCode":"IT",
     "latitude":"46.24976590",
     "longitude":"12.19695650"
  },
  {
     "name":"Province of Pordenone",
     "isoCode":"PN",
     "countryCode":"IT",
     "latitude":"46.03788620",
     "longitude":"12.71083500"
  },
  {
     "name":"Province of Perugia",
     "isoCode":"PG",
     "countryCode":"IT",
     "latitude":"42.93800400",
     "longitude":"12.62162110"
  },
  {
     "name":"Province of Avellino",
     "isoCode":"AV",
     "countryCode":"IT",
     "latitude":"40.99645100",
     "longitude":"15.12589550"
  },
  {
     "name":"Pesaro and Urbino Province",
     "isoCode":"PU",
     "countryCode":"IT",
     "latitude":"43.61301180",
     "longitude":"12.71351210"
  },
  {
     "name":"Province of Pescara",
     "isoCode":"PE",
     "countryCode":"IT",
     "latitude":"42.35706550",
     "longitude":"13.96080910"
  },
  {
     "name":"Molise",
     "isoCode":"67",
     "countryCode":"IT",
     "latitude":"41.67388650",
     "longitude":"14.75209390"
  },
  {
     "name":"Province of Piacenza",
     "isoCode":"PC",
     "countryCode":"IT",
     "latitude":"44.82631120",
     "longitude":"9.52914470"
  },
  {
     "name":"Province of Potenza",
     "isoCode":"PZ",
     "countryCode":"IT",
     "latitude":"40.41821940",
     "longitude":"15.87600400"
  },
  {
     "name":"Metropolitan City of Milan",
     "isoCode":"MI",
     "countryCode":"IT",
     "latitude":"45.45862600",
     "longitude":"9.18187300"
  },
  {
     "name":"Metropolitan City of Genoa",
     "isoCode":"GE",
     "countryCode":"IT",
     "latitude":"44.40564930",
     "longitude":"8.94625640"
  },
  {
     "name":"Province of Prato",
     "isoCode":"PO",
     "countryCode":"IT",
     "latitude":"44.04539000",
     "longitude":"11.11644520"
  },
  {
     "name":"Benevento Province",
     "isoCode":"BN",
     "countryCode":"IT",
     "latitude":"41.20350930",
     "longitude":"14.75209390"
  },
  {
     "name":"Piedmont",
     "isoCode":"21",
     "countryCode":"IT",
     "latitude":"45.05223660",
     "longitude":"7.51538850"
  },
  {
     "name":"Calabria",
     "isoCode":"78",
     "countryCode":"IT",
     "latitude":"39.30877140",
     "longitude":"16.34637910"
  },
  {
     "name":"Province of Bergamo",
     "isoCode":"BG",
     "countryCode":"IT",
     "latitude":"45.69826420",
     "longitude":"9.67726980"
  },
  {
     "name":"Lombardy",
     "isoCode":"25",
     "countryCode":"IT",
     "latitude":"45.47906710",
     "longitude":"9.84524330"
  },
  {
     "name":"Basilicata",
     "isoCode":"77",
     "countryCode":"IT",
     "latitude":"40.64307660",
     "longitude":"15.96998780"
  },
  {
     "name":"Province of Ravenna",
     "isoCode":"RA",
     "countryCode":"IT",
     "latitude":"44.41844430",
     "longitude":"12.20359980"
  },
  {
     "name":"Province of Reggio Emilia",
     "isoCode":"RE",
     "countryCode":"IT",
     "latitude":"44.58565800",
     "longitude":"10.55647360"
  },
  {
     "name":"Sicily",
     "isoCode":"82",
     "countryCode":"IT",
     "latitude":"37.59999380",
     "longitude":"14.01535570"
  },
  {
     "name":"Metropolitan City of Turin",
     "isoCode":"TO",
     "countryCode":"IT",
     "latitude":"45.06329900",
     "longitude":"7.66928900"
  },
  {
     "name":"Metropolitan City of Rome",
     "isoCode":"RM",
     "countryCode":"IT",
     "latitude":"41.90270080",
     "longitude":"12.49623520"
  },
  {
     "name":"Province of Rieti",
     "isoCode":"RI",
     "countryCode":"IT",
     "latitude":"42.36744050",
     "longitude":"12.89750980"
  },
  {
     "name":"Province of Rimini",
     "isoCode":"RN",
     "countryCode":"IT",
     "latitude":"44.06782880",
     "longitude":"12.56951580"
  },
  {
     "name":"Province of Brindisi",
     "isoCode":"BR",
     "countryCode":"IT",
     "latitude":"40.61126630",
     "longitude":"17.76362100"
  },
  {
     "name":"Sardinia",
     "isoCode":"88",
     "countryCode":"IT",
     "latitude":"40.12087520",
     "longitude":"9.01289260"
  },
  {
     "name":"Aosta Valley",
     "isoCode":"23",
     "countryCode":"IT",
     "latitude":"45.73888780",
     "longitude":"7.42618660"
  },
  {
     "name":"Province of Brescia",
     "isoCode":"BS",
     "countryCode":"IT",
     "latitude":"45.54155260",
     "longitude":"10.21180190"
  },
  {
     "name":"Libero consorzio comunale di Caltanissetta",
     "isoCode":"CL",
     "countryCode":"IT",
     "latitude":"37.48601300",
     "longitude":"14.06149820"
  },
  {
     "name":"Province of Rovigo",
     "isoCode":"RO",
     "countryCode":"IT",
     "latitude":"45.02418180",
     "longitude":"11.82381620"
  },
  {
     "name":"Province of Salerno",
     "isoCode":"SA",
     "countryCode":"IT",
     "latitude":"40.42878320",
     "longitude":"15.21948080"
  },
  {
     "name":"Province of Campobasso",
     "isoCode":"CB",
     "countryCode":"IT",
     "latitude":"41.67388650",
     "longitude":"14.75209390"
  },
  {
     "name":"Province of Sassari",
     "isoCode":"SS",
     "countryCode":"IT",
     "latitude":"40.79679070",
     "longitude":"8.57504070"
  },
  {
     "name":"Libero consorzio comunale di Enna",
     "isoCode":"EN",
     "countryCode":"IT",
     "latitude":"37.56762160",
     "longitude":"14.27953490"
  },
  {
     "name":"Metropolitan City of Naples",
     "isoCode":"NA",
     "countryCode":"IT",
     "latitude":"40.90197500",
     "longitude":"14.33264400"
  },
  {
     "name":"Trentino-South Tyrol",
     "isoCode":"32",
     "countryCode":"IT",
     "latitude":"46.43366620",
     "longitude":"11.16932960"
  },
  {
     "name":"Province of Verbano-Cusio-Ossola",
     "isoCode":"VB",
     "countryCode":"IT",
     "latitude":"46.13996880",
     "longitude":"8.27246490"
  },
  {
     "name":"Libero consorzio comunale di Agrigento",
     "isoCode":"AG",
     "countryCode":"IT",
     "latitude":"37.31052020",
     "longitude":"13.58579780"
  },
  {
     "name":"Province of Catanzaro",
     "isoCode":"CZ",
     "countryCode":"IT",
     "latitude":"38.88963480",
     "longitude":"16.44058720"
  },
  {
     "name":"Libero consorzio comunale di Ragusa",
     "isoCode":"RG",
     "countryCode":"IT",
     "latitude":"36.92692730",
     "longitude":"14.72551290"
  },
  {
     "name":"Province of Carbonia-Iglesias",
     "isoCode":"CI",
     "countryCode":"IT",
     "latitude":"39.25346590",
     "longitude":"8.57210160"
  },
  {
     "name":"Province of Caserta",
     "isoCode":"CE",
     "countryCode":"IT",
     "latitude":"41.20783540",
     "longitude":"14.10013260"
  },
  {
     "name":"Province of Savona",
     "isoCode":"SV",
     "countryCode":"IT",
     "latitude":"44.28879950",
     "longitude":"8.26505800"
  },
  {
     "name":"Libero consorzio comunale di Trapani",
     "isoCode":"TP",
     "countryCode":"IT",
     "latitude":"38.01831160",
     "longitude":"12.51482650"
  },
  {
     "name":"Province of Siena",
     "isoCode":"SI",
     "countryCode":"IT",
     "latitude":"43.29377320",
     "longitude":"11.43391480"
  },
  {
     "name":"Province of Viterbo",
     "isoCode":"VT",
     "countryCode":"IT",
     "latitude":"42.40024200",
     "longitude":"11.88917210"
  },
  {
     "name":"Province of Verona",
     "isoCode":"VR",
     "countryCode":"IT",
     "latitude":"45.44184980",
     "longitude":"11.07353160"
  },
  {
     "name":"Province of Vibo Valentia",
     "isoCode":"VV",
     "countryCode":"IT",
     "latitude":"38.63785650",
     "longitude":"16.20514840"
  },
  {
     "name":"Province of Vicenza",
     "isoCode":"VI",
     "countryCode":"IT",
     "latitude":"45.69839950",
     "longitude":"11.56614650"
  },
  {
     "name":"Province of Chieti",
     "isoCode":"CH",
     "countryCode":"IT",
     "latitude":"42.03344280",
     "longitude":"14.37919120"
  },
  {
     "name":"Province of Como",
     "isoCode":"CO",
     "countryCode":"IT",
     "latitude":"45.80804160",
     "longitude":"9.08517930"
  },
  {
     "name":"Province of Sondrio",
     "isoCode":"SO",
     "countryCode":"IT",
     "latitude":"46.17276360",
     "longitude":"9.79949170"
  },
  {
     "name":"Province of Cosenza",
     "isoCode":"CS",
     "countryCode":"IT",
     "latitude":"39.56441050",
     "longitude":"16.25221430"
  },
  {
     "name":"Province of Taranto",
     "isoCode":"TA",
     "countryCode":"IT",
     "latitude":"40.57409010",
     "longitude":"17.24299760"
  },
  {
     "name":"Province of Fermo",
     "isoCode":"FM",
     "countryCode":"IT",
     "latitude":"43.09313670",
     "longitude":"13.58997330"
  },
  {
     "name":"Province of Livorno",
     "isoCode":"LI",
     "countryCode":"IT",
     "latitude":"43.02398480",
     "longitude":"10.66471010"
  },
  {
     "name":"Province of Ferrara",
     "isoCode":"FE",
     "countryCode":"IT",
     "latitude":"44.76636800",
     "longitude":"11.76440680"
  },
  {
     "name":"Province of Lodi",
     "isoCode":"LO",
     "countryCode":"IT",
     "latitude":"45.24050360",
     "longitude":"9.52925120"
  },
  {
     "name":"Trentino",
     "isoCode":"TN",
     "countryCode":"IT",
     "latitude":"46.05120040",
     "longitude":"11.11753920"
  },
  {
     "name":"Province of Lucca",
     "isoCode":"LU",
     "countryCode":"IT",
     "latitude":"43.83767360",
     "longitude":"10.49505300"
  },
  {
     "name":"Province of Macerata",
     "isoCode":"MC",
     "countryCode":"IT",
     "latitude":"43.24593220",
     "longitude":"13.26634790"
  },
  {
     "name":"Province of Cremona",
     "isoCode":"CR",
     "countryCode":"IT",
     "latitude":"45.20143750",
     "longitude":"9.98365820"
  },
  {
     "name":"Province of Teramo",
     "isoCode":"TE",
     "countryCode":"IT",
     "latitude":"42.58956080",
     "longitude":"13.63627150"
  },
  {
     "name":"Veneto",
     "isoCode":"34",
     "countryCode":"IT",
     "latitude":"45.44146620",
     "longitude":"12.31525950"
  },
  {
     "name":"Province of Crotone",
     "isoCode":"KR",
     "countryCode":"IT",
     "latitude":"39.13098560",
     "longitude":"17.00670310"
  },
  {
     "name":"Province of Terni",
     "isoCode":"TR",
     "countryCode":"IT",
     "latitude":"42.56345340",
     "longitude":"12.52980280"
  },
  {
     "name":"Friuli–Venezia Giulia",
     "isoCode":"36",
     "countryCode":"IT",
     "latitude":"46.22591770",
     "longitude":"13.10336460"
  },
  {
     "name":"Province of Modena",
     "isoCode":"MO",
     "countryCode":"IT",
     "latitude":"44.55137990",
     "longitude":"10.91804800"
  },
  {
     "name":"Province of Mantua",
     "isoCode":"MN",
     "countryCode":"IT",
     "latitude":"45.16677280",
     "longitude":"10.77536130"
  },
  {
     "name":"Province of Massa and Carrara",
     "isoCode":"MS",
     "countryCode":"IT",
     "latitude":"44.22139980",
     "longitude":"10.03596610"
  },
  {
     "name":"Province of Matera",
     "isoCode":"MT",
     "countryCode":"IT",
     "latitude":"40.66634960",
     "longitude":"16.60436360"
  },
  {
     "name":"Province of Medio Campidano",
     "isoCode":"VS",
     "countryCode":"IT",
     "latitude":"39.53173890",
     "longitude":"8.70407500"
  },
  {
     "name":"Province of Treviso",
     "isoCode":"TV",
     "countryCode":"IT",
     "latitude":"45.66685170",
     "longitude":"12.24306170"
  },
  {
     "name":"Province of Trieste",
     "isoCode":"TS",
     "countryCode":"IT",
     "latitude":"45.68948230",
     "longitude":"13.78330720"
  },
  {
     "name":"Province of Udine",
     "isoCode":"UD",
     "countryCode":"IT",
     "latitude":"46.14079720",
     "longitude":"13.16628960"
  },
  {
     "name":"Province of Varese",
     "isoCode":"VA",
     "countryCode":"IT",
     "latitude":"45.79902600",
     "longitude":"8.73009450"
  },
  {
     "name":"Metropolitan City of Catania",
     "isoCode":"CT",
     "countryCode":"IT",
     "latitude":"37.45154380",
     "longitude":"15.05574150"
  },
  {
     "name":"South Tyrol",
     "isoCode":"BZ",
     "countryCode":"IT",
     "latitude":"46.49494500",
     "longitude":"11.34026570"
  },
  {
     "name":"Liguria",
     "isoCode":"42",
     "countryCode":"IT",
     "latitude":"44.31679170",
     "longitude":"8.39649380"
  },
  {
     "name":"Province of Monza and Brianza",
     "isoCode":"MB",
     "countryCode":"IT",
     "latitude":"45.62359900",
     "longitude":"9.25880150"
  },
  {
     "name":"Metropolitan City of Messina",
     "isoCode":"ME",
     "countryCode":"IT",
     "latitude":"38.19373350",
     "longitude":"15.55420570"
  },
  {
     "name":"Province of Foggia",
     "isoCode":"FG",
     "countryCode":"IT",
     "latitude":"41.63844800",
     "longitude":"15.59433880"
  },
  {
     "name":"Metropolitan City of Bari",
     "isoCode":"BA",
     "countryCode":"IT",
     "latitude":"41.11714320",
     "longitude":"16.87187150"
  },
  {
     "name":"Emilia-Romagna",
     "isoCode":"45",
     "countryCode":"IT",
     "latitude":"44.59676070",
     "longitude":"11.21863960"
  },
  {
     "name":"Province of Novara",
     "isoCode":"NO",
     "countryCode":"IT",
     "latitude":"45.54851330",
     "longitude":"8.51507930"
  },
  {
     "name":"Province of Cuneo",
     "isoCode":"CN",
     "countryCode":"IT",
     "latitude":"44.59703140",
     "longitude":"7.61142170"
  },
  {
     "name":"Province of Frosinone",
     "isoCode":"FR",
     "countryCode":"IT",
     "latitude":"41.65765280",
     "longitude":"13.63627150"
  },
  {
     "name":"Province of Gorizia",
     "isoCode":"GO",
     "countryCode":"IT",
     "latitude":"45.90538990",
     "longitude":"13.51637250"
  },
  {
     "name":"Province of Biella",
     "isoCode":"BI",
     "countryCode":"IT",
     "latitude":"45.56281760",
     "longitude":"8.05827170"
  },
  {
     "name":"Province of Forlì-Cesena",
     "isoCode":"FC",
     "countryCode":"IT",
     "latitude":"43.99476810",
     "longitude":"11.98046130"
  },
  {
     "name":"Province of Asti",
     "isoCode":"AT",
     "countryCode":"IT",
     "latitude":"44.90076520",
     "longitude":"8.20643150"
  },
  {
     "name":"Province of L'Aquila",
     "isoCode":"AQ",
     "countryCode":"IT",
     "latitude":"42.12563170",
     "longitude":"13.63627150"
  },
  {
     "name":"Province of Ogliastra",
     "isoCode":"OG",
     "countryCode":"IT",
     "latitude":"39.84105360",
     "longitude":"9.45615500"
  },
  {
     "name":"Province of Alessandria",
     "isoCode":"AL",
     "countryCode":"IT",
     "latitude":"44.81755870",
     "longitude":"8.70466270"
  },
  {
     "name":"Province of Olbia-Tempio",
     "isoCode":"OT",
     "countryCode":"IT",
     "latitude":"40.82683830",
     "longitude":"9.27855830"
  },
  {
     "name":"Province of Vercelli",
     "isoCode":"VC",
     "countryCode":"IT",
     "latitude":"45.32022040",
     "longitude":"8.41850800"
  },
  {
     "name":"Province of Oristano",
     "isoCode":"OR",
     "countryCode":"IT",
     "latitude":"40.05990680",
     "longitude":"8.74811670"
  },
  {
     "name":"Province of Grosseto",
     "isoCode":"GR",
     "countryCode":"IT",
     "latitude":"42.85180070",
     "longitude":"11.25237920"
  },
  {
     "name":"Province of Imperia",
     "isoCode":"IM",
     "countryCode":"IT",
     "latitude":"43.94186600",
     "longitude":"7.82863680"
  },
  {
     "name":"Province of Isernia",
     "isoCode":"IS",
     "countryCode":"IT",
     "latitude":"41.58915550",
     "longitude":"14.19309180"
  },
  {
     "name":"Province of Nuoro",
     "isoCode":"NU",
     "countryCode":"IT",
     "latitude":"40.32869040",
     "longitude":"9.45615500"
  },
  {
     "name":"Province of La Spezia",
     "isoCode":"SP",
     "countryCode":"IT",
     "latitude":"44.24479130",
     "longitude":"9.76786870"
  },
  {
     "name":"North Sumatra",
     "isoCode":"SU",
     "countryCode":"ID",
     "latitude":"2.11535470",
     "longitude":"99.54509740"
  },
  {
     "name":"Bengkulu",
     "isoCode":"BE",
     "countryCode":"ID",
     "latitude":"-3.79284510",
     "longitude":"102.26076410"
  },
  {
     "name":"Central Kalimantan",
     "isoCode":"KT",
     "countryCode":"ID",
     "latitude":"-1.68148780",
     "longitude":"113.38235450"
  },
  {
     "name":"South Sulawesi",
     "isoCode":"SN",
     "countryCode":"ID",
     "latitude":"-3.66879940",
     "longitude":"119.97405340"
  },
  {
     "name":"Southeast Sulawesi",
     "isoCode":"SG",
     "countryCode":"ID",
     "latitude":"-4.14491000",
     "longitude":"122.17460500"
  },
  {
     "name":"Sumatra",
     "isoCode":"SM",
     "countryCode":"ID",
     "latitude":"-0.58972400",
     "longitude":"101.34310580"
  },
  {
     "name":"Papua",
     "isoCode":"PA",
     "countryCode":"ID",
     "latitude":"-5.01222020",
     "longitude":"141.34701590"
  },
  {
     "name":"West Papua",
     "isoCode":"PB",
     "countryCode":"ID",
     "latitude":"-1.33611540",
     "longitude":"133.17471620"
  },
  {
     "name":"Maluku",
     "isoCode":"MA",
     "countryCode":"ID",
     "latitude":"-3.23846160",
     "longitude":"130.14527340"
  },
  {
     "name":"North Maluku",
     "isoCode":"MU",
     "countryCode":"ID",
     "latitude":"1.57099930",
     "longitude":"127.80876930"
  },
  {
     "name":"Central Java",
     "isoCode":"JT",
     "countryCode":"ID",
     "latitude":"-7.15097500",
     "longitude":"110.14025940"
  },
  {
     "name":"Sulawesi",
     "isoCode":"SL",
     "countryCode":"ID",
     "latitude":"-1.84790000",
     "longitude":"120.52790000"
  },
  {
     "name":"East Kalimantan",
     "isoCode":"KI",
     "countryCode":"ID",
     "latitude":"0.53865860",
     "longitude":"116.41938900"
  },
  {
     "name":"Jakarta",
     "isoCode":"JK",
     "countryCode":"ID",
     "latitude":"-6.20876340",
     "longitude":"106.84559900"
  },
  {
     "name":"Kalimantan",
     "isoCode":"KA",
     "countryCode":"ID",
     "latitude":"0.96188340",
     "longitude":"114.55484950"
  },
  {
     "name":"Riau Islands",
     "isoCode":"KR",
     "countryCode":"ID",
     "latitude":"3.94565140",
     "longitude":"108.14286690"
  },
  {
     "name":"North Sulawesi",
     "isoCode":"SA",
     "countryCode":"ID",
     "latitude":"0.62469320",
     "longitude":"123.97500180"
  },
  {
     "name":"Riau",
     "isoCode":"RI",
     "countryCode":"ID",
     "latitude":"0.29334690",
     "longitude":"101.70682940"
  },
  {
     "name":"Banten",
     "isoCode":"BT",
     "countryCode":"ID",
     "latitude":"-6.40581720",
     "longitude":"106.06401790"
  },
  {
     "name":"Lampung",
     "isoCode":"LA",
     "countryCode":"ID",
     "latitude":"-4.55858490",
     "longitude":"105.40680790"
  },
  {
     "name":"Gorontalo",
     "isoCode":"GO",
     "countryCode":"ID",
     "latitude":"0.54354420",
     "longitude":"123.05676930"
  },
  {
     "name":"Central Sulawesi",
     "isoCode":"ST",
     "countryCode":"ID",
     "latitude":"-1.43002540",
     "longitude":"121.44561790"
  },
  {
     "name":"West Nusa Tenggara",
     "isoCode":"NB",
     "countryCode":"ID",
     "latitude":"-8.65293340",
     "longitude":"117.36164760"
  },
  {
     "name":"Jambi",
     "isoCode":"JA",
     "countryCode":"ID",
     "latitude":"-1.61012290",
     "longitude":"103.61312030"
  },
  {
     "name":"South Sumatra",
     "isoCode":"SS",
     "countryCode":"ID",
     "latitude":"-3.31943740",
     "longitude":"103.91439900"
  },
  {
     "name":"West Sulawesi",
     "isoCode":"SR",
     "countryCode":"ID",
     "latitude":"-2.84413710",
     "longitude":"119.23207840"
  },
  {
     "name":"East Nusa Tenggara",
     "isoCode":"NT",
     "countryCode":"ID",
     "latitude":"-8.65738190",
     "longitude":"121.07937050"
  },
  {
     "name":"South Kalimantan",
     "isoCode":"KS",
     "countryCode":"ID",
     "latitude":"-3.09264150",
     "longitude":"115.28375850"
  },
  {
     "name":"Bangka Belitung Islands",
     "isoCode":"BB",
     "countryCode":"ID",
     "latitude":"-2.74105130",
     "longitude":"106.44058720"
  },
  {
     "name":"Lesser Sunda Islands",
     "isoCode":"NU",
     "countryCode":"ID",
     "latitude":"-9.00000000",
     "longitude":"120.00000000"
  },
  {
     "name":"Aceh",
     "isoCode":"AC",
     "countryCode":"ID",
     "latitude":"4.69513500",
     "longitude":"96.74939930"
  },
  {
     "name":"Maluku Islands",
     "isoCode":"ML",
     "countryCode":"ID",
     "latitude":"-2.86461660",
     "longitude":"129.57659740"
  },
  {
     "name":"North Kalimantan",
     "isoCode":"KU",
     "countryCode":"ID",
     "latitude":"3.07309290",
     "longitude":"116.04138890"
  },
  {
     "name":"West Java",
     "isoCode":"JB",
     "countryCode":"ID",
     "latitude":"-7.09091100",
     "longitude":"107.66888700"
  },
  {
     "name":"Bali",
     "isoCode":"BA",
     "countryCode":"ID",
     "latitude":"-8.34053890",
     "longitude":"115.09195090"
  },
  {
     "name":"East Java",
     "isoCode":"JI",
     "countryCode":"ID",
     "latitude":"-7.53606390",
     "longitude":"112.23840170"
  },
  {
     "name":"West Sumatra",
     "isoCode":"SB",
     "countryCode":"ID",
     "latitude":"-0.73993970",
     "longitude":"100.80000510"
  },
  {
     "name":"Special Region of Yogyakarta",
     "isoCode":"YO",
     "countryCode":"ID",
     "latitude":"-7.87538490",
     "longitude":"110.42620880"
  },
  {
     "name":"Phoenix Islands",
     "isoCode":"P",
     "countryCode":"KI",
     "latitude":"33.32843690",
     "longitude":"-111.98247740"
  },
  {
     "name":"Gilbert Islands",
     "isoCode":"G",
     "countryCode":"KI",
     "latitude":"0.35242620",
     "longitude":"174.75526340"
  },
  {
     "name":"Line Islands",
     "isoCode":"L",
     "countryCode":"KI",
     "latitude":"1.74294390",
     "longitude":"-157.21328260"
  },
  {
     "name":"Primorsky Krai",
     "isoCode":"PRI",
     "countryCode":"RU",
     "latitude":"45.05256410",
     "longitude":"135.00000000"
  },
  {
     "name":"Novgorod Oblast",
     "isoCode":"NGR",
     "countryCode":"RU",
     "latitude":"58.24275520",
     "longitude":"32.56651900"
  },
  {
     "name":"Jewish Autonomous Oblast",
     "isoCode":"YEV",
     "countryCode":"RU",
     "latitude":"48.48081470",
     "longitude":"131.76573670"
  },
  {
     "name":"Nenets Autonomous Okrug",
     "isoCode":"NEN",
     "countryCode":"RU",
     "latitude":"67.60783370",
     "longitude":"57.63383310"
  },
  {
     "name":"Rostov Oblast",
     "isoCode":"ROS",
     "countryCode":"RU",
     "latitude":"47.68532470",
     "longitude":"41.82589520"
  },
  {
     "name":"Khanty-Mansi Autonomous Okrug",
     "isoCode":"KHM",
     "countryCode":"RU",
     "latitude":"62.22870620",
     "longitude":"70.64100570"
  },
  {
     "name":"Magadan Oblast",
     "isoCode":"MAG",
     "countryCode":"RU",
     "latitude":"62.66434170",
     "longitude":"153.91499100"
  },
  {
     "name":"Krasnoyarsk Krai",
     "isoCode":"KYA",
     "countryCode":"RU",
     "latitude":"64.24797580",
     "longitude":"95.11041760"
  },
  {
     "name":"Republic of Karelia",
     "isoCode":"KR",
     "countryCode":"RU",
     "latitude":"63.15587020",
     "longitude":"32.99055520"
  },
  {
     "name":"Republic of Buryatia",
     "isoCode":"BU",
     "countryCode":"RU",
     "latitude":"54.83311460",
     "longitude":"112.40605300"
  },
  {
     "name":"Murmansk Oblast",
     "isoCode":"MUR",
     "countryCode":"RU",
     "latitude":"67.84426740",
     "longitude":"35.08841020"
  },
  {
     "name":"Kaluga Oblast",
     "isoCode":"KLU",
     "countryCode":"RU",
     "latitude":"54.38726660",
     "longitude":"35.18890940"
  },
  {
     "name":"Chelyabinsk Oblast",
     "isoCode":"CHE",
     "countryCode":"RU",
     "latitude":"54.43194220",
     "longitude":"60.87889630"
  },
  {
     "name":"Omsk Oblast",
     "isoCode":"OMS",
     "countryCode":"RU",
     "latitude":"55.05546690",
     "longitude":"73.31673420"
  },
  {
     "name":"Yamalo-Nenets Autonomous Okrug",
     "isoCode":"YAN",
     "countryCode":"RU",
     "latitude":"66.06530570",
     "longitude":"76.93451930"
  },
  {
     "name":"Sakha Republic",
     "isoCode":"SA",
     "countryCode":"RU",
     "latitude":"66.76134510",
     "longitude":"124.12375300"
  },
  {
     "name":"Arkhangelsk",
     "isoCode":"ARK",
     "countryCode":"RU",
     "latitude":"64.54585490",
     "longitude":"40.55057690"
  },
  {
     "name":"Republic of Dagestan",
     "isoCode":"DA",
     "countryCode":"RU",
     "latitude":"42.14318860",
     "longitude":"47.09497990"
  },
  {
     "name":"Yaroslavl Oblast",
     "isoCode":"YAR",
     "countryCode":"RU",
     "latitude":"57.89915230",
     "longitude":"38.83886330"
  },
  {
     "name":"Republic of Adygea",
     "isoCode":"AD",
     "countryCode":"RU",
     "latitude":"44.82291550",
     "longitude":"40.17544630"
  },
  {
     "name":"Republic of North Ossetia-Alania",
     "isoCode":"SE",
     "countryCode":"RU",
     "latitude":"43.04513020",
     "longitude":"44.28709720"
  },
  {
     "name":"Republic of Bashkortostan",
     "isoCode":"BA",
     "countryCode":"RU",
     "latitude":"54.23121720",
     "longitude":"56.16452570"
  },
  {
     "name":"Kursk Oblast",
     "isoCode":"KRS",
     "countryCode":"RU",
     "latitude":"51.76340260",
     "longitude":"35.38118120"
  },
  {
     "name":"Ulyanovsk Oblast",
     "isoCode":"ULY",
     "countryCode":"RU",
     "latitude":"53.97933570",
     "longitude":"47.77624250"
  },
  {
     "name":"Nizhny Novgorod Oblast",
     "isoCode":"NIZ",
     "countryCode":"RU",
     "latitude":"55.79951590",
     "longitude":"44.02967690"
  },
  {
     "name":"Amur Oblast",
     "isoCode":"AMU",
     "countryCode":"RU",
     "latitude":"54.60350650",
     "longitude":"127.48017210"
  },
  {
     "name":"Chukotka Autonomous Okrug",
     "isoCode":"CHU",
     "countryCode":"RU",
     "latitude":"65.62983550",
     "longitude":"171.69521590"
  },
  {
     "name":"Tver Oblast",
     "isoCode":"TVE",
     "countryCode":"RU",
     "latitude":"57.00216540",
     "longitude":"33.98531420"
  },
  {
     "name":"Republic of Tatarstan",
     "isoCode":"TA",
     "countryCode":"RU",
     "latitude":"55.18023640",
     "longitude":"50.72639450"
  },
  {
     "name":"Samara Oblast",
     "isoCode":"SAM",
     "countryCode":"RU",
     "latitude":"53.41838390",
     "longitude":"50.47255280"
  },
  {
     "name":"Pskov Oblast",
     "isoCode":"PSK",
     "countryCode":"RU",
     "latitude":"56.77085990",
     "longitude":"29.09400900"
  },
  {
     "name":"Ivanovo Oblast",
     "isoCode":"IVA",
     "countryCode":"RU",
     "latitude":"57.10568540",
     "longitude":"41.48300840"
  },
  {
     "name":"Kamchatka Krai",
     "isoCode":"KAM",
     "countryCode":"RU",
     "latitude":"61.43439810",
     "longitude":"166.78841310"
  },
  {
     "name":"Astrakhan Oblast",
     "isoCode":"AST",
     "countryCode":"RU",
     "latitude":"46.13211660",
     "longitude":"48.06101150"
  },
  {
     "name":"Bryansk Oblast",
     "isoCode":"BRY",
     "countryCode":"RU",
     "latitude":"53.04085990",
     "longitude":"33.26909000"
  },
  {
     "name":"Stavropol Krai",
     "isoCode":"STA",
     "countryCode":"RU",
     "latitude":"44.66809930",
     "longitude":"43.52021400"
  },
  {
     "name":"Karachay-Cherkess Republic",
     "isoCode":"KC",
     "countryCode":"RU",
     "latitude":"43.88451430",
     "longitude":"41.73039390"
  },
  {
     "name":"Mari El Republic",
     "isoCode":"ME",
     "countryCode":"RU",
     "latitude":"56.43845700",
     "longitude":"47.96077580"
  },
  {
     "name":"Perm Krai",
     "isoCode":"PER",
     "countryCode":"RU",
     "latitude":"58.82319290",
     "longitude":"56.58724810"
  },
  {
     "name":"Tomsk Oblast",
     "isoCode":"TOM",
     "countryCode":"RU",
     "latitude":"58.89698820",
     "longitude":"82.67655000"
  },
  {
     "name":"Khabarovsk Krai",
     "isoCode":"KHA",
     "countryCode":"RU",
     "latitude":"50.58884310",
     "longitude":"135.00000000"
  },
  {
     "name":"Vologda Oblast",
     "isoCode":"VLG",
     "countryCode":"RU",
     "latitude":"59.87067110",
     "longitude":"40.65554110"
  },
  {
     "name":"Sakhalin",
     "isoCode":"SAK",
     "countryCode":"RU",
     "latitude":"50.69098480",
     "longitude":"142.95056890"
  },
  {
     "name":"Altai Republic",
     "isoCode":"AL",
     "countryCode":"RU",
     "latitude":"50.61819240",
     "longitude":"86.21993080"
  },
  {
     "name":"Republic of Khakassia",
     "isoCode":"KK",
     "countryCode":"RU",
     "latitude":"53.04522810",
     "longitude":"90.39821450"
  },
  {
     "name":"Tambov Oblast",
     "isoCode":"TAM",
     "countryCode":"RU",
     "latitude":"52.64165890",
     "longitude":"41.42164510"
  },
  {
     "name":"Saint Petersburg",
     "isoCode":"SPE",
     "countryCode":"RU",
     "latitude":"59.93105840",
     "longitude":"30.36090960"
  },
  {
     "name":"Irkutsk",
     "isoCode":"IRK",
     "countryCode":"RU",
     "latitude":"52.28548340",
     "longitude":"104.28902220"
  },
  {
     "name":"Vladimir Oblast",
     "isoCode":"VLA",
     "countryCode":"RU",
     "latitude":"56.15534650",
     "longitude":"40.59266850"
  },
  {
     "name":"Moscow Oblast",
     "isoCode":"MOS",
     "countryCode":"RU",
     "latitude":"55.34039600",
     "longitude":"38.29176510"
  },
  {
     "name":"Republic of Kalmykia",
     "isoCode":"KL",
     "countryCode":"RU",
     "latitude":"46.18671760",
     "longitude":"45.00000000"
  },
  {
     "name":"Republic of Ingushetia",
     "isoCode":"IN",
     "countryCode":"RU",
     "latitude":"43.40516980",
     "longitude":"44.82029990"
  },
  {
     "name":"Smolensk Oblast",
     "isoCode":"SMO",
     "countryCode":"RU",
     "latitude":"54.98829940",
     "longitude":"32.66773780"
  },
  {
     "name":"Orenburg Oblast",
     "isoCode":"ORE",
     "countryCode":"RU",
     "latitude":"51.76340260",
     "longitude":"54.61881880"
  },
  {
     "name":"Saratov Oblast",
     "isoCode":"SAR",
     "countryCode":"RU",
     "latitude":"51.83692630",
     "longitude":"46.75393970"
  },
  {
     "name":"Novosibirsk",
     "isoCode":"NVS",
     "countryCode":"RU",
     "latitude":"54.98326930",
     "longitude":"82.89638310"
  },
  {
     "name":"Lipetsk Oblast",
     "isoCode":"LIP",
     "countryCode":"RU",
     "latitude":"52.52647020",
     "longitude":"39.20322690"
  },
  {
     "name":"Kirov Oblast",
     "isoCode":"KIR",
     "countryCode":"RU",
     "latitude":"58.41985290",
     "longitude":"50.20972480"
  },
  {
     "name":"Krasnodar Krai",
     "isoCode":"KDA",
     "countryCode":"RU",
     "latitude":"45.64152890",
     "longitude":"39.70559770"
  },
  {
     "name":"Kabardino-Balkar Republic",
     "isoCode":"KB",
     "countryCode":"RU",
     "latitude":"43.39324690",
     "longitude":"43.56284980"
  },
  {
     "name":"Chechen Republic",
     "isoCode":"CE",
     "countryCode":"RU",
     "latitude":"43.40233010",
     "longitude":"45.71874680"
  },
  {
     "name":"Sverdlovsk",
     "isoCode":"SVE",
     "countryCode":"RU",
     "latitude":"56.84309930",
     "longitude":"60.64540860"
  },
  {
     "name":"Tula Oblast",
     "isoCode":"TUL",
     "countryCode":"RU",
     "latitude":"54.16376800",
     "longitude":"37.56495070"
  },
  {
     "name":"Leningrad Oblast",
     "isoCode":"LEN",
     "countryCode":"RU",
     "latitude":"60.07932080",
     "longitude":"31.89266450"
  },
  {
     "name":"Kemerovo Oblast",
     "isoCode":"KEM",
     "countryCode":"RU",
     "latitude":"54.75746480",
     "longitude":"87.40552880"
  },
  {
     "name":"Republic of Mordovia",
     "isoCode":"MO",
     "countryCode":"RU",
     "latitude":"54.23694410",
     "longitude":"44.06839700"
  },
  {
     "name":"Komi Republic",
     "isoCode":"KO",
     "countryCode":"RU",
     "latitude":"63.86305390",
     "longitude":"54.83126900"
  },
  {
     "name":"Tuva Republic",
     "isoCode":"TY",
     "countryCode":"RU",
     "latitude":"51.88726690",
     "longitude":"95.62601720"
  },
  {
     "name":"Moscow",
     "isoCode":"MOW",
     "countryCode":"RU",
     "latitude":"55.75582600",
     "longitude":"37.61729990"
  },
  {
     "name":"Kaliningrad",
     "isoCode":"KGD",
     "countryCode":"RU",
     "latitude":"54.71042640",
     "longitude":"20.45221440"
  },
  {
     "name":"Belgorod Oblast",
     "isoCode":"BEL",
     "countryCode":"RU",
     "latitude":"50.71069260",
     "longitude":"37.75333770"
  },
  {
     "name":"Zabaykalsky Krai",
     "isoCode":"ZAB",
     "countryCode":"RU",
     "latitude":"53.09287710",
     "longitude":"116.96765610"
  },
  {
     "name":"Ryazan Oblast",
     "isoCode":"RYA",
     "countryCode":"RU",
     "latitude":"54.38759640",
     "longitude":"41.25956610"
  },
  {
     "name":"Voronezh Oblast",
     "isoCode":"VOR",
     "countryCode":"RU",
     "latitude":"50.85897130",
     "longitude":"39.86443740"
  },
  {
     "name":"Tyumen Oblast",
     "isoCode":"TYU",
     "countryCode":"RU",
     "latitude":"56.96343870",
     "longitude":"66.94827800"
  },
  {
     "name":"Oryol Oblast",
     "isoCode":"ORL",
     "countryCode":"RU",
     "latitude":"52.78564140",
     "longitude":"36.92423440"
  },
  {
     "name":"Penza Oblast",
     "isoCode":"PNZ",
     "countryCode":"RU",
     "latitude":"53.14121050",
     "longitude":"44.09400480"
  },
  {
     "name":"Kostroma Oblast",
     "isoCode":"KOS",
     "countryCode":"RU",
     "latitude":"58.55010690",
     "longitude":"43.95411020"
  },
  {
     "name":"Altai Krai",
     "isoCode":"ALT",
     "countryCode":"RU",
     "latitude":"51.79362980",
     "longitude":"82.67585960"
  },
  {
     "name":"Sevastopol",
     "isoCode":"UA-40",
     "countryCode":"RU",
     "latitude":"44.61665000",
     "longitude":"33.52536710"
  },
  {
     "name":"Udmurt Republic",
     "isoCode":"UD",
     "countryCode":"RU",
     "latitude":"57.06702180",
     "longitude":"53.02779480"
  },
  {
     "name":"Chuvash Republic",
     "isoCode":"CU",
     "countryCode":"RU",
     "latitude":"55.55959920",
     "longitude":"46.92835350"
  },
  {
     "name":"Kurgan Oblast",
     "isoCode":"KGN",
     "countryCode":"RU",
     "latitude":"55.44815480",
     "longitude":"65.11809750"
  },
  {
     "name":"Lomaiviti",
     "isoCode":"06",
     "countryCode":"FJ",
     "latitude":"-17.70900000",
     "longitude":"179.09100000"
  },
  {
     "name":"Ba",
     "isoCode":"01",
     "countryCode":"FJ",
     "latitude":"36.06138930",
     "longitude":"-95.80058720"
  },
  {
     "name":"Tailevu",
     "isoCode":"14",
     "countryCode":"FJ",
     "latitude":"-17.82691110",
     "longitude":"178.29324800"
  },
  {
     "name":"Nadroga-Navosa",
     "isoCode":"08",
     "countryCode":"FJ",
     "latitude":"-17.98652780",
     "longitude":"177.65811300"
  },
  {
     "name":"Rewa",
     "isoCode":"12",
     "countryCode":"FJ",
     "latitude":"34.79235170",
     "longitude":"-82.36092640"
  },
  {
     "name":"Northern Division",
     "isoCode":"N",
     "countryCode":"FJ",
     "latitude":"32.87687660",
     "longitude":"-117.21563450"
  },
  {
     "name":"Macuata",
     "isoCode":"07",
     "countryCode":"FJ",
     "latitude":"-16.48649220",
     "longitude":"179.28472510"
  },
  {
     "name":"Western Division",
     "isoCode":"W",
     "countryCode":"FJ",
     "latitude":"42.96621980",
     "longitude":"-78.70211340"
  },
  {
     "name":"Cakaudrove",
     "isoCode":"03",
     "countryCode":"FJ",
     "latitude":"-16.58141050",
     "longitude":"179.51200840"
  },
  {
     "name":"Serua",
     "isoCode":"13",
     "countryCode":"FJ",
     "latitude":"-18.18047490",
     "longitude":"178.05097900"
  },
  {
     "name":"Ra",
     "isoCode":"11",
     "countryCode":"FJ",
     "latitude":"37.10031530",
     "longitude":"-95.67442460"
  },
  {
     "name":"Naitasiri",
     "isoCode":"09",
     "countryCode":"FJ",
     "latitude":"-17.89757540",
     "longitude":"178.20715980"
  },
  {
     "name":"Namosi",
     "isoCode":"10",
     "countryCode":"FJ",
     "latitude":"-18.08641760",
     "longitude":"178.12913870"
  },
  {
     "name":"Central Division",
     "isoCode":"C",
     "countryCode":"FJ",
     "latitude":"34.04400660",
     "longitude":"-118.24727380"
  },
  {
     "name":"Bua",
     "isoCode":"02",
     "countryCode":"FJ",
     "latitude":"43.09645840",
     "longitude":"-89.50088000"
  },
  {
     "name":"Rotuma",
     "isoCode":"R",
     "countryCode":"FJ",
     "latitude":"-12.50250690",
     "longitude":"177.07241640"
  },
  {
     "name":"Eastern Division",
     "isoCode":"E",
     "countryCode":"FJ",
     "latitude":"32.80943050",
     "longitude":"-117.12899370"
  },
  {
     "name":"Lau",
     "isoCode":"05",
     "countryCode":"FJ",
     "latitude":"31.66870150",
     "longitude":"-106.39557630"
  },
  {
     "name":"Kadavu",
     "isoCode":"04",
     "countryCode":"FJ",
     "latitude":"-19.01271220",
     "longitude":"178.18766760"
  },
  {
     "name":"Labuan",
     "isoCode":"15",
     "countryCode":"MY",
     "latitude":"5.28314560",
     "longitude":"115.23082500"
  },
  {
     "name":"Sabah",
     "isoCode":"12",
     "countryCode":"MY",
     "latitude":"5.97883980",
     "longitude":"116.07531990"
  },
  {
     "name":"Sarawak",
     "isoCode":"13",
     "countryCode":"MY",
     "latitude":"1.55327830",
     "longitude":"110.35921270"
  },
  {
     "name":"Perlis",
     "isoCode":"09",
     "countryCode":"MY",
     "latitude":"29.92270940",
     "longitude":"-90.12285590"
  },
  {
     "name":"Penang",
     "isoCode":"07",
     "countryCode":"MY",
     "latitude":"5.41639350",
     "longitude":"100.33267860"
  },
  {
     "name":"Pahang",
     "isoCode":"06",
     "countryCode":"MY",
     "latitude":"3.81263180",
     "longitude":"103.32562040"
  },
  {
     "name":"Malacca",
     "isoCode":"04",
     "countryCode":"MY",
     "latitude":"2.18959400",
     "longitude":"102.25008680"
  },
  {
     "name":"Terengganu",
     "isoCode":"11",
     "countryCode":"MY",
     "latitude":"5.31169160",
     "longitude":"103.13241540"
  },
  {
     "name":"Perak",
     "isoCode":"08",
     "countryCode":"MY",
     "latitude":"4.59211260",
     "longitude":"101.09010900"
  },
  {
     "name":"Selangor",
     "isoCode":"10",
     "countryCode":"MY",
     "latitude":"3.07383790",
     "longitude":"101.51834690"
  },
  {
     "name":"Putrajaya",
     "isoCode":"16",
     "countryCode":"MY",
     "latitude":"2.92636100",
     "longitude":"101.69644500"
  },
  {
     "name":"Kelantan",
     "isoCode":"03",
     "countryCode":"MY",
     "latitude":"6.12539690",
     "longitude":"102.23807100"
  },
  {
     "name":"Kedah",
     "isoCode":"02",
     "countryCode":"MY",
     "latitude":"6.11839640",
     "longitude":"100.36845950"
  },
  {
     "name":"Negeri Sembilan",
     "isoCode":"05",
     "countryCode":"MY",
     "latitude":"2.72580580",
     "longitude":"101.94237820"
  },
  {
     "name":"Kuala Lumpur",
     "isoCode":"14",
     "countryCode":"MY",
     "latitude":"3.13900300",
     "longitude":"101.68685500"
  },
  {
     "name":"Johor",
     "isoCode":"01",
     "countryCode":"MY",
     "latitude":"1.48536820",
     "longitude":"103.76181540"
  },
  {
     "name":"Mashonaland East Province",
     "isoCode":"ME",
     "countryCode":"ZW",
     "latitude":"-18.58716420",
     "longitude":"31.26263660"
  },
  {
     "name":"Matabeleland South Province",
     "isoCode":"MS",
     "countryCode":"ZW",
     "latitude":"-21.05233700",
     "longitude":"29.04599270"
  },
  {
     "name":"Mashonaland West Province",
     "isoCode":"MW",
     "countryCode":"ZW",
     "latitude":"-17.48510290",
     "longitude":"29.78892480"
  },
  {
     "name":"Matabeleland North Province",
     "isoCode":"MN",
     "countryCode":"ZW",
     "latitude":"-18.53315660",
     "longitude":"27.54958460"
  },
  {
     "name":"Mashonaland Central Province",
     "isoCode":"MC",
     "countryCode":"ZW",
     "latitude":"-16.76442950",
     "longitude":"31.07937050"
  },
  {
     "name":"Bulawayo Province",
     "isoCode":"BU",
     "countryCode":"ZW",
     "latitude":"-20.14895050",
     "longitude":"28.53310380"
  },
  {
     "name":"Midlands Province",
     "isoCode":"MI",
     "countryCode":"ZW",
     "latitude":"-19.05520090",
     "longitude":"29.60354950"
  },
  {
     "name":"Harare Province",
     "isoCode":"HA",
     "countryCode":"ZW",
     "latitude":"-17.82162880",
     "longitude":"31.04922590"
  },
  {
     "name":"Manicaland",
     "isoCode":"MA",
     "countryCode":"ZW",
     "latitude":"-18.92163860",
     "longitude":"32.17460500"
  },
  {
     "name":"Masvingo Province",
     "isoCode":"MV",
     "countryCode":"ZW",
     "latitude":"-20.62415090",
     "longitude":"31.26263660"
  },
  {
     "name":"Bulgan Province",
     "isoCode":"067",
     "countryCode":"MN",
     "latitude":"48.96909130",
     "longitude":"102.88317230"
  },
  {
     "name":"Darkhan-Uul Province",
     "isoCode":"037",
     "countryCode":"MN",
     "latitude":"49.46484340",
     "longitude":"105.97459190"
  },
  {
     "name":"Dornod Province",
     "isoCode":"061",
     "countryCode":"MN",
     "latitude":"47.46581540",
     "longitude":"115.39271200"
  },
  {
     "name":"Khovd Province",
     "isoCode":"043",
     "countryCode":"MN",
     "latitude":"47.11296540",
     "longitude":"92.31107520"
  },
  {
     "name":"Övörkhangai Province",
     "isoCode":"055",
     "countryCode":"MN",
     "latitude":"45.76243920",
     "longitude":"103.09170320"
  },
  {
     "name":"Orkhon Province",
     "isoCode":"035",
     "countryCode":"MN",
     "latitude":"49.00470500",
     "longitude":"104.30165270"
  },
  {
     "name":"Ömnögovi Province",
     "isoCode":"053",
     "countryCode":"MN",
     "latitude":"43.50002400",
     "longitude":"104.28611160"
  },
  {
     "name":"Töv Province",
     "isoCode":"047",
     "countryCode":"MN",
     "latitude":"47.21240560",
     "longitude":"106.41541000"
  },
  {
     "name":"Bayan-Ölgii Province",
     "isoCode":"071",
     "countryCode":"MN",
     "latitude":"48.39832540",
     "longitude":"89.66259150"
  },
  {
     "name":"Dundgovi Province",
     "isoCode":"059",
     "countryCode":"MN",
     "latitude":"45.58227860",
     "longitude":"106.76442090"
  },
  {
     "name":"Uvs Province",
     "isoCode":"046",
     "countryCode":"MN",
     "latitude":"49.64497070",
     "longitude":"93.27365760"
  },
  {
     "name":"Govi-Altai Province",
     "isoCode":"065",
     "countryCode":"MN",
     "latitude":"45.45112270",
     "longitude":"95.85057660"
  },
  {
     "name":"Arkhangai Province",
     "isoCode":"073",
     "countryCode":"MN",
     "latitude":"47.89711010",
     "longitude":"100.72401650"
  },
  {
     "name":"Khentii Province",
     "isoCode":"039",
     "countryCode":"MN",
     "latitude":"47.60812090",
     "longitude":"109.93728560"
  },
  {
     "name":"Khövsgöl Province",
     "isoCode":"041",
     "countryCode":"MN",
     "latitude":"50.22044840",
     "longitude":"100.32137680"
  },
  {
     "name":"Bayankhongor Province",
     "isoCode":"069",
     "countryCode":"MN",
     "latitude":"45.15267070",
     "longitude":"100.10736670"
  },
  {
     "name":"Sükhbaatar Province",
     "isoCode":"051",
     "countryCode":"MN",
     "latitude":"46.56531630",
     "longitude":"113.53808360"
  },
  {
     "name":"Govisümber Province",
     "isoCode":"064",
     "countryCode":"MN",
     "latitude":"46.47627540",
     "longitude":"108.55706270"
  },
  {
     "name":"Zavkhan Province",
     "isoCode":"057",
     "countryCode":"MN",
     "latitude":"48.23881470",
     "longitude":"96.07030190"
  },
  {
     "name":"Selenge Province",
     "isoCode":"049",
     "countryCode":"MN",
     "latitude":"50.00592730",
     "longitude":"106.44341080"
  },
  {
     "name":"Dornogovi Province",
     "isoCode":"063",
     "countryCode":"MN",
     "latitude":"43.96538890",
     "longitude":"109.17734590"
  },
  {
     "name":"Northern Province",
     "isoCode":"05",
     "countryCode":"ZM",
     "latitude":"8.88550270",
     "longitude":"80.27673270"
  },
  {
     "name":"Western Province",
     "isoCode":"01",
     "countryCode":"ZM",
     "latitude":"6.90160860",
     "longitude":"80.00877460"
  },
  {
     "name":"Copperbelt Province",
     "isoCode":"08",
     "countryCode":"ZM",
     "latitude":"-13.05700730",
     "longitude":"27.54958460"
  },
  {
     "name":"Northwestern Province",
     "isoCode":"06",
     "countryCode":"ZM",
     "latitude":"-13.00502580",
     "longitude":"24.90422080"
  },
  {
     "name":"Central Province",
     "isoCode":"02",
     "countryCode":"ZM",
     "latitude":"7.25649960",
     "longitude":"80.72144170"
  },
  {
     "name":"Luapula Province",
     "isoCode":"04",
     "countryCode":"ZM",
     "latitude":"-11.56483100",
     "longitude":"29.04599270"
  },
  {
     "name":"Lusaka Province",
     "isoCode":"09",
     "countryCode":"ZM",
     "latitude":"-15.36571290",
     "longitude":"29.23207840"
  },
  {
     "name":"Muchinga Province",
     "isoCode":"10",
     "countryCode":"ZM",
     "latitude":"-15.38219300",
     "longitude":"28.26158000"
  },
  {
     "name":"Southern Province",
     "isoCode":"07",
     "countryCode":"ZM",
     "latitude":"6.23737500",
     "longitude":"80.54384500"
  },
  {
     "name":"Eastern Province",
     "isoCode":"03",
     "countryCode":"ZM",
     "latitude":"23.16696880",
     "longitude":"49.36531490"
  },
  {
     "name":"Capital Governorate",
     "isoCode":"13",
     "countryCode":"BH",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Southern Governorate",
     "isoCode":"14",
     "countryCode":"BH",
     "latitude":"25.93810180",
     "longitude":"50.57568870"
  },
  {
     "name":"Northern Governorate",
     "isoCode":"17",
     "countryCode":"BH",
     "latitude":"26.15519140",
     "longitude":"50.48251730"
  },
  {
     "name":"Muharraq Governorate",
     "isoCode":"15",
     "countryCode":"BH",
     "latitude":"26.26856530",
     "longitude":"50.64825170"
  },
  {
     "name":"Central Governorate",
     "isoCode":"16",
     "countryCode":"BH",
     "latitude":"26.14260930",
     "longitude":"50.56532940"
  },
  {
     "name":"Rio de Janeiro",
     "isoCode":"RJ",
     "countryCode":"BR",
     "latitude":"-22.90684670",
     "longitude":"-43.17289650"
  },
  {
     "name":"Minas Gerais",
     "isoCode":"MG",
     "countryCode":"BR",
     "latitude":"-18.51217800",
     "longitude":"-44.55503080"
  },
  {
     "name":"Amapá",
     "isoCode":"AP",
     "countryCode":"BR",
     "latitude":"0.90199250",
     "longitude":"-52.00295650"
  },
  {
     "name":"Goiás",
     "isoCode":"GO",
     "countryCode":"BR",
     "latitude":"-15.82703690",
     "longitude":"-49.83622370"
  },
  {
     "name":"Rio Grande do Sul",
     "isoCode":"RS",
     "countryCode":"BR",
     "latitude":"-30.03463160",
     "longitude":"-51.21769860"
  },
  {
     "name":"Bahia",
     "isoCode":"BA",
     "countryCode":"BR",
     "latitude":"26.11353900",
     "longitude":"-80.10620400"
  },
  {
     "name":"Sergipe",
     "isoCode":"SE",
     "countryCode":"BR",
     "latitude":"-10.57409340",
     "longitude":"-37.38565810"
  },
  {
     "name":"Amazonas",
     "isoCode":"AM",
     "countryCode":"BR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Paraíba",
     "isoCode":"PB",
     "countryCode":"BR",
     "latitude":"-7.23996090",
     "longitude":"-36.78195050"
  },
  {
     "name":"Pernambuco",
     "isoCode":"PE",
     "countryCode":"BR",
     "latitude":"-8.81371730",
     "longitude":"-36.95410700"
  },
  {
     "name":"Alagoas",
     "isoCode":"AL",
     "countryCode":"BR",
     "latitude":"-9.57130580",
     "longitude":"-36.78195050"
  },
  {
     "name":"Piauí",
     "isoCode":"PI",
     "countryCode":"BR",
     "latitude":"-7.71834010",
     "longitude":"-42.72892360"
  },
  {
     "name":"Pará",
     "isoCode":"PA",
     "countryCode":"BR",
     "latitude":"-1.99812710",
     "longitude":"-54.93061520"
  },
  {
     "name":"Mato Grosso do Sul",
     "isoCode":"MS",
     "countryCode":"BR",
     "latitude":"-20.77222950",
     "longitude":"-54.78515310"
  },
  {
     "name":"Mato Grosso",
     "isoCode":"MT",
     "countryCode":"BR",
     "latitude":"-12.68187120",
     "longitude":"-56.92109900"
  },
  {
     "name":"Acre",
     "isoCode":"AC",
     "countryCode":"BR",
     "latitude":"32.93305200",
     "longitude":"35.08267800"
  },
  {
     "name":"Rondônia",
     "isoCode":"RO",
     "countryCode":"BR",
     "latitude":"-11.50573410",
     "longitude":"-63.58061100"
  },
  {
     "name":"Santa Catarina",
     "isoCode":"SC",
     "countryCode":"BR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Maranhão",
     "isoCode":"MA",
     "countryCode":"BR",
     "latitude":"-4.96094980",
     "longitude":"-45.27441590"
  },
  {
     "name":"Ceará",
     "isoCode":"CE",
     "countryCode":"BR",
     "latitude":"-5.49839770",
     "longitude":"-39.32062410"
  },
  {
     "name":"Federal District",
     "isoCode":"DF",
     "countryCode":"BR",
     "latitude":"-15.79976540",
     "longitude":"-47.86447150"
  },
  {
     "name":"Espírito Santo",
     "isoCode":"ES",
     "countryCode":"BR",
     "latitude":"-19.18342290",
     "longitude":"-40.30886260"
  },
  {
     "name":"Rio Grande do Norte",
     "isoCode":"RN",
     "countryCode":"BR",
     "latitude":"-5.40258030",
     "longitude":"-36.95410700"
  },
  {
     "name":"Tocantins",
     "isoCode":"TO",
     "countryCode":"BR",
     "latitude":"-10.17528000",
     "longitude":"-48.29824740"
  },
  {
     "name":"São Paulo",
     "isoCode":"SP",
     "countryCode":"BR",
     "latitude":"-23.55051990",
     "longitude":"-46.63330940"
  },
  {
     "name":"Paraná",
     "isoCode":"PR",
     "countryCode":"BR",
     "latitude":"-25.25208880",
     "longitude":"-52.02154150"
  },
  {
     "name":"Aragatsotn Region",
     "isoCode":"AG",
     "countryCode":"AM",
     "latitude":"40.33473010",
     "longitude":"44.37482960"
  },
  {
     "name":"Ararat Province",
     "isoCode":"AR",
     "countryCode":"AM",
     "latitude":"39.91394150",
     "longitude":"44.72000040"
  },
  {
     "name":"Vayots Dzor Region",
     "isoCode":"VD",
     "countryCode":"AM",
     "latitude":"39.76419960",
     "longitude":"45.33375280"
  },
  {
     "name":"Armavir Region",
     "isoCode":"AV",
     "countryCode":"AM",
     "latitude":"40.15546310",
     "longitude":"44.03724460"
  },
  {
     "name":"Syunik Province",
     "isoCode":"SU",
     "countryCode":"AM",
     "latitude":"39.51331120",
     "longitude":"46.33932340"
  },
  {
     "name":"Gegharkunik Province",
     "isoCode":"GR",
     "countryCode":"AM",
     "latitude":"40.35264260",
     "longitude":"45.12604140"
  },
  {
     "name":"Lori Region",
     "isoCode":"LO",
     "countryCode":"AM",
     "latitude":"40.96984520",
     "longitude":"44.49001380"
  },
  {
     "name":"Yerevan",
     "isoCode":"ER",
     "countryCode":"AM",
     "latitude":"40.18720230",
     "longitude":"44.51520900"
  },
  {
     "name":"Shirak Region",
     "isoCode":"SH",
     "countryCode":"AM",
     "latitude":"40.96308140",
     "longitude":"43.81024610"
  },
  {
     "name":"Tavush Region",
     "isoCode":"TV",
     "countryCode":"AM",
     "latitude":"40.88662960",
     "longitude":"45.33934900"
  },
  {
     "name":"Kotayk Region",
     "isoCode":"KT",
     "countryCode":"AM",
     "latitude":"40.54102140",
     "longitude":"44.76901480"
  },
  {
     "name":"Cojedes",
     "isoCode":"H",
     "countryCode":"VE",
     "latitude":"9.38166820",
     "longitude":"-68.33392750"
  },
  {
     "name":"Falcón",
     "isoCode":"I",
     "countryCode":"VE",
     "latitude":"11.18106740",
     "longitude":"-69.85974060"
  },
  {
     "name":"Portuguesa",
     "isoCode":"P",
     "countryCode":"VE",
     "latitude":"9.09439990",
     "longitude":"-69.09702300"
  },
  {
     "name":"Miranda",
     "isoCode":"M",
     "countryCode":"VE",
     "latitude":"42.35193830",
     "longitude":"-71.52907660"
  },
  {
     "name":"Lara",
     "isoCode":"K",
     "countryCode":"VE",
     "latitude":"33.98221650",
     "longitude":"-118.13227470"
  },
  {
     "name":"Bolívar",
     "isoCode":"F",
     "countryCode":"VE",
     "latitude":"37.61448380",
     "longitude":"-93.41047490"
  },
  {
     "name":"Carabobo",
     "isoCode":"G",
     "countryCode":"VE",
     "latitude":"10.11764330",
     "longitude":"-68.04775090"
  },
  {
     "name":"Yaracuy",
     "isoCode":"U",
     "countryCode":"VE",
     "latitude":"10.33938900",
     "longitude":"-68.81088490"
  },
  {
     "name":"Zulia",
     "isoCode":"V",
     "countryCode":"VE",
     "latitude":"10.29102370",
     "longitude":"-72.14161320"
  },
  {
     "name":"Trujillo",
     "isoCode":"T",
     "countryCode":"VE",
     "latitude":"36.67343430",
     "longitude":"-121.62875880"
  },
  {
     "name":"Amazonas",
     "isoCode":"Z",
     "countryCode":"VE",
     "latitude":"-3.41684270",
     "longitude":"-65.85606460"
  },
  {
     "name":"Guárico",
     "isoCode":"J",
     "countryCode":"VE",
     "latitude":"8.74893090",
     "longitude":"-66.23671720"
  },
  {
     "name":"Federal Dependencies of Venezuela",
     "isoCode":"W",
     "countryCode":"VE",
     "latitude":"10.93770530",
     "longitude":"-65.35695730"
  },
  {
     "name":"Aragua",
     "isoCode":"D",
     "countryCode":"VE",
     "latitude":"10.06357580",
     "longitude":"-67.28478750"
  },
  {
     "name":"Táchira",
     "isoCode":"S",
     "countryCode":"VE",
     "latitude":"7.91370010",
     "longitude":"-72.14161320"
  },
  {
     "name":"Barinas",
     "isoCode":"E",
     "countryCode":"VE",
     "latitude":"8.62314980",
     "longitude":"-70.23710450"
  },
  {
     "name":"Anzoátegui",
     "isoCode":"B",
     "countryCode":"VE",
     "latitude":"8.59130730",
     "longitude":"-63.95861110"
  },
  {
     "name":"Delta Amacuro",
     "isoCode":"Y",
     "countryCode":"VE",
     "latitude":"8.84993070",
     "longitude":"-61.14031960"
  },
  {
     "name":"Nueva Esparta",
     "isoCode":"O",
     "countryCode":"VE",
     "latitude":"10.99707230",
     "longitude":"-63.91132960"
  },
  {
     "name":"Mérida",
     "isoCode":"L",
     "countryCode":"VE",
     "latitude":"20.96737020",
     "longitude":"-89.59258570"
  },
  {
     "name":"Monagas",
     "isoCode":"N",
     "countryCode":"VE",
     "latitude":"9.32416520",
     "longitude":"-63.01475780"
  },
  {
     "name":"Vargas",
     "isoCode":"X",
     "countryCode":"VE",
     "latitude":"29.30522680",
     "longitude":"-94.79138540"
  },
  {
     "name":"Sucre",
     "isoCode":"R",
     "countryCode":"VE",
     "latitude":"-19.03534500",
     "longitude":"-65.25921280"
  },
  {
     "name":"Carinthia",
     "isoCode":"2",
     "countryCode":"AT",
     "latitude":"46.72220300",
     "longitude":"14.18058820"
  },
  {
     "name":"Upper Austria",
     "isoCode":"4",
     "countryCode":"AT",
     "latitude":"48.02585400",
     "longitude":"13.97236650"
  },
  {
     "name":"Styria",
     "isoCode":"6",
     "countryCode":"AT",
     "latitude":"47.35934420",
     "longitude":"14.46998270"
  },
  {
     "name":"Vienna",
     "isoCode":"9",
     "countryCode":"AT",
     "latitude":"48.20817430",
     "longitude":"16.37381890"
  },
  {
     "name":"Salzburg",
     "isoCode":"5",
     "countryCode":"AT",
     "latitude":"47.80949000",
     "longitude":"13.05501000"
  },
  {
     "name":"Burgenland",
     "isoCode":"1",
     "countryCode":"AT",
     "latitude":"47.15371650",
     "longitude":"16.26887970"
  },
  {
     "name":"Vorarlberg",
     "isoCode":"8",
     "countryCode":"AT",
     "latitude":"47.24974270",
     "longitude":"9.97973730"
  },
  {
     "name":"Tyrol",
     "isoCode":"7",
     "countryCode":"AT",
     "latitude":"47.25374140",
     "longitude":"11.60148700"
  },
  {
     "name":"Lower Austria",
     "isoCode":"3",
     "countryCode":"AT",
     "latitude":"48.10807700",
     "longitude":"15.80495580"
  },
  {
     "name":"Mid-Western Region",
     "isoCode":"2",
     "countryCode":"NP",
     "latitude":"38.41118410",
     "longitude":"-90.38320980"
  },
  {
     "name":"Western Region",
     "isoCode":"3",
     "countryCode":"NP",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Far-Western Development Region",
     "isoCode":"5",
     "countryCode":"NP",
     "latitude":"29.29878710",
     "longitude":"80.98710740"
  },
  {
     "name":"Eastern Development Region",
     "isoCode":"4",
     "countryCode":"NP",
     "latitude":"27.33090720",
     "longitude":"87.06242610"
  },
  {
     "name":"Mechi Zone",
     "isoCode":"ME",
     "countryCode":"NP",
     "latitude":"26.87600070",
     "longitude":"87.93348030"
  },
  {
     "name":"Bheri Zone",
     "isoCode":"BH",
     "countryCode":"NP",
     "latitude":"28.51745600",
     "longitude":"81.77870210"
  },
  {
     "name":"Kosi Zone",
     "isoCode":"KO",
     "countryCode":"NP",
     "latitude":"27.05365240",
     "longitude":"87.30161320"
  },
  {
     "name":"Central Region",
     "isoCode":"1",
     "countryCode":"NP",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Lumbini Zone",
     "isoCode":"LU",
     "countryCode":"NP",
     "latitude":"27.45000000",
     "longitude":"83.25000000"
  },
  {
     "name":"Narayani Zone",
     "isoCode":"NA",
     "countryCode":"NP",
     "latitude":"27.36117660",
     "longitude":"84.85679320"
  },
  {
     "name":"Janakpur Zone",
     "isoCode":"JA",
     "countryCode":"NP",
     "latitude":"27.21108990",
     "longitude":"86.01215730"
  },
  {
     "name":"Rapti Zone",
     "isoCode":"RA",
     "countryCode":"NP",
     "latitude":"28.27434700",
     "longitude":"82.38857830"
  },
  {
     "name":"Seti Zone",
     "isoCode":"SE",
     "countryCode":"NP",
     "latitude":"29.69054270",
     "longitude":"81.33994140"
  },
  {
     "name":"Karnali Zone",
     "isoCode":"KA",
     "countryCode":"NP",
     "latitude":"29.38625550",
     "longitude":"82.38857830"
  },
  {
     "name":"Dhaulagiri Zone",
     "isoCode":"DH",
     "countryCode":"NP",
     "latitude":"28.61117600",
     "longitude":"83.50702030"
  },
  {
     "name":"Gandaki Zone",
     "isoCode":"GA",
     "countryCode":"NP",
     "latitude":"28.37320370",
     "longitude":"84.43827210"
  },
  {
     "name":"Bagmati Zone",
     "isoCode":"BA",
     "countryCode":"NP",
     "latitude":"28.03675770",
     "longitude":"85.43755740"
  },
  {
     "name":"Mahakali Zone",
     "isoCode":"MA",
     "countryCode":"NP",
     "latitude":"29.36010790",
     "longitude":"80.54384500"
  },
  {
     "name":"Sagarmatha Zone",
     "isoCode":"SA",
     "countryCode":"NP",
     "latitude":"27.32382630",
     "longitude":"86.74163740"
  },
  {
     "name":"Unity",
     "isoCode":"UY",
     "countryCode":"SS",
     "latitude":"37.78712760",
     "longitude":"-122.40340790"
  },
  {
     "name":"Upper Nile",
     "isoCode":"NU",
     "countryCode":"SS",
     "latitude":"9.88942020",
     "longitude":"32.71813750"
  },
  {
     "name":"Warrap",
     "isoCode":"WR",
     "countryCode":"SS",
     "latitude":"8.08862380",
     "longitude":"28.64106410"
  },
  {
     "name":"Northern Bahr el Ghazal",
     "isoCode":"BN",
     "countryCode":"SS",
     "latitude":"8.53604490",
     "longitude":"26.79678490"
  },
  {
     "name":"Western Equatoria",
     "isoCode":"EW",
     "countryCode":"SS",
     "latitude":"5.34717990",
     "longitude":"28.29943500"
  },
  {
     "name":"Lakes",
     "isoCode":"LK",
     "countryCode":"SS",
     "latitude":"37.16282550",
     "longitude":"-95.69116230"
  },
  {
     "name":"Western Bahr el Ghazal",
     "isoCode":"BW",
     "countryCode":"SS",
     "latitude":"8.64523990",
     "longitude":"25.28375850"
  },
  {
     "name":"Central Equatoria",
     "isoCode":"EC",
     "countryCode":"SS",
     "latitude":"4.61440630",
     "longitude":"31.26263660"
  },
  {
     "name":"Eastern Equatoria",
     "isoCode":"EE",
     "countryCode":"SS",
     "latitude":"5.06929950",
     "longitude":"33.43835300"
  },
  {
     "name":"Jonglei State",
     "isoCode":"JG",
     "countryCode":"SS",
     "latitude":"7.18196190",
     "longitude":"32.35609520"
  },
  {
     "name":"Karditsa Regional Unit",
     "isoCode":"41",
     "countryCode":"GR",
     "latitude":"39.36402580",
     "longitude":"21.92140490"
  },
  {
     "name":"West Greece Region",
     "isoCode":"G",
     "countryCode":"GR",
     "latitude":"38.51154960",
     "longitude":"21.57067860"
  },
  {
     "name":"Thessaloniki Regional Unit",
     "isoCode":"54",
     "countryCode":"GR",
     "latitude":"40.64006290",
     "longitude":"22.94441910"
  },
  {
     "name":"Arcadia Prefecture",
     "isoCode":"12",
     "countryCode":"GR",
     "latitude":"37.55578250",
     "longitude":"22.33377690"
  },
  {
     "name":"Imathia Regional Unit",
     "isoCode":"53",
     "countryCode":"GR",
     "latitude":"40.60600670",
     "longitude":"22.14302150"
  },
  {
     "name":"Kastoria Regional Unit",
     "isoCode":"56",
     "countryCode":"GR",
     "latitude":"40.51926910",
     "longitude":"21.26871710"
  },
  {
     "name":"Euboea",
     "isoCode":"04",
     "countryCode":"GR",
     "latitude":"38.52360360",
     "longitude":"23.85847370"
  },
  {
     "name":"Grevena Prefecture",
     "isoCode":"51",
     "countryCode":"GR",
     "latitude":"40.08376260",
     "longitude":"21.42732990"
  },
  {
     "name":"Preveza Prefecture",
     "isoCode":"34",
     "countryCode":"GR",
     "latitude":"38.95926490",
     "longitude":"20.75171550"
  },
  {
     "name":"Lefkada Regional Unit",
     "isoCode":"24",
     "countryCode":"GR",
     "latitude":"38.83336630",
     "longitude":"20.70691080"
  },
  {
     "name":"Argolis Regional Unit",
     "isoCode":"11",
     "countryCode":"GR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Laconia",
     "isoCode":"16",
     "countryCode":"GR",
     "latitude":"43.52785460",
     "longitude":"-71.47035090"
  },
  {
     "name":"Pella Regional Unit",
     "isoCode":"59",
     "countryCode":"GR",
     "latitude":"40.91480390",
     "longitude":"22.14302150"
  },
  {
     "name":"West Macedonia Region",
     "isoCode":"C",
     "countryCode":"GR",
     "latitude":"40.30040580",
     "longitude":"21.79035590"
  },
  {
     "name":"Crete Region",
     "isoCode":"M",
     "countryCode":"GR",
     "latitude":"35.24011700",
     "longitude":"24.80926910"
  },
  {
     "name":"Epirus Region",
     "isoCode":"D",
     "countryCode":"GR",
     "latitude":"39.57064130",
     "longitude":"20.76428430"
  },
  {
     "name":"Kilkis Regional Unit",
     "isoCode":"57",
     "countryCode":"GR",
     "latitude":"40.99370710",
     "longitude":"22.87536740"
  },
  {
     "name":"Kozani Prefecture",
     "isoCode":"58",
     "countryCode":"GR",
     "latitude":"40.30055860",
     "longitude":"21.78877370"
  },
  {
     "name":"Ioannina Regional Unit",
     "isoCode":"33",
     "countryCode":"GR",
     "latitude":"39.66502880",
     "longitude":"20.85374660"
  },
  {
     "name":"Phthiotis Prefecture",
     "isoCode":"06",
     "countryCode":"GR",
     "latitude":"38.99978500",
     "longitude":"22.33377690"
  },
  {
     "name":"Chania Regional Unit",
     "isoCode":"94",
     "countryCode":"GR",
     "latitude":"35.51382980",
     "longitude":"24.01803670"
  },
  {
     "name":"Achaea Regional Unit",
     "isoCode":"13",
     "countryCode":"GR",
     "latitude":"38.11587290",
     "longitude":"21.95224910"
  },
  {
     "name":"East Macedonia and Thrace",
     "isoCode":"A",
     "countryCode":"GR",
     "latitude":"41.12951260",
     "longitude":"24.88771910"
  },
  {
     "name":"South Aegean",
     "isoCode":"L",
     "countryCode":"GR",
     "latitude":"37.08553020",
     "longitude":"25.14892150"
  },
  {
     "name":"Peloponnese Region",
     "isoCode":"J",
     "countryCode":"GR",
     "latitude":"37.50794720",
     "longitude":"22.37349000"
  },
  {
     "name":"East Attica Regional Unit",
     "isoCode":"A2",
     "countryCode":"GR",
     "latitude":"38.20540930",
     "longitude":"23.85847370"
  },
  {
     "name":"Serres Prefecture",
     "isoCode":"62",
     "countryCode":"GR",
     "latitude":"41.08638540",
     "longitude":"23.54838190"
  },
  {
     "name":"Attica Region",
     "isoCode":"I",
     "countryCode":"GR",
     "latitude":"38.04575680",
     "longitude":"23.85847370"
  },
  {
     "name":"Aetolia-Acarnania Regional Unit",
     "isoCode":"01",
     "countryCode":"GR",
     "latitude":"38.70843860",
     "longitude":"21.37989280"
  },
  {
     "name":"Corfu Prefecture",
     "isoCode":"22",
     "countryCode":"GR",
     "latitude":"39.62498380",
     "longitude":"19.92234610"
  },
  {
     "name":"Central Macedonia",
     "isoCode":"B",
     "countryCode":"GR",
     "latitude":"40.62117300",
     "longitude":"23.19180210"
  },
  {
     "name":"Boeotia Regional Unit",
     "isoCode":"03",
     "countryCode":"GR",
     "latitude":"38.36636640",
     "longitude":"23.09650640"
  },
  {
     "name":"Kefalonia Prefecture",
     "isoCode":"23",
     "countryCode":"GR",
     "latitude":"38.17536750",
     "longitude":"20.56921790"
  },
  {
     "name":"Central Greece Region",
     "isoCode":"H",
     "countryCode":"GR",
     "latitude":"38.60439840",
     "longitude":"22.71521310"
  },
  {
     "name":"Corinthia Regional Unit",
     "isoCode":"15",
     "countryCode":"GR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Drama Regional Unit",
     "isoCode":"52",
     "countryCode":"GR",
     "latitude":"41.23400230",
     "longitude":"24.23904980"
  },
  {
     "name":"Ionian Islands Region",
     "isoCode":"F",
     "countryCode":"GR",
     "latitude":"37.96948980",
     "longitude":"21.38023720"
  },
  {
     "name":"Larissa Prefecture",
     "isoCode":"42",
     "countryCode":"GR",
     "latitude":"39.63902240",
     "longitude":"22.41912540"
  },
  {
     "name":"Kayin State",
     "isoCode":"13",
     "countryCode":"MM",
     "latitude":"16.94593460",
     "longitude":"97.95928630"
  },
  {
     "name":"Mandalay Region",
     "isoCode":"04",
     "countryCode":"MM",
     "latitude":"21.56190580",
     "longitude":"95.89871390"
  },
  {
     "name":"Yangon Region",
     "isoCode":"06",
     "countryCode":"MM",
     "latitude":"16.91434880",
     "longitude":"96.15269850"
  },
  {
     "name":"Magway Region",
     "isoCode":"03",
     "countryCode":"MM",
     "latitude":"19.88713860",
     "longitude":"94.72775280"
  },
  {
     "name":"Chin State",
     "isoCode":"14",
     "countryCode":"MM",
     "latitude":"22.00869780",
     "longitude":"93.58126920"
  },
  {
     "name":"Rakhine State",
     "isoCode":"16",
     "countryCode":"MM",
     "latitude":"20.10408180",
     "longitude":"93.58126920"
  },
  {
     "name":"Shan State",
     "isoCode":"17",
     "countryCode":"MM",
     "latitude":"22.03619850",
     "longitude":"98.13385580"
  },
  {
     "name":"Tanintharyi Region",
     "isoCode":"05",
     "countryCode":"MM",
     "latitude":"12.47068760",
     "longitude":"99.01289260"
  },
  {
     "name":"Bago",
     "isoCode":"02",
     "countryCode":"MM",
     "latitude":"17.32207110",
     "longitude":"96.46632860"
  },
  {
     "name":"Ayeyarwady Region",
     "isoCode":"07",
     "countryCode":"MM",
     "latitude":"17.03421250",
     "longitude":"95.22666750"
  },
  {
     "name":"Kachin State",
     "isoCode":"11",
     "countryCode":"MM",
     "latitude":"25.85090400",
     "longitude":"97.43813550"
  },
  {
     "name":"Kayah State",
     "isoCode":"12",
     "countryCode":"MM",
     "latitude":"19.23420610",
     "longitude":"97.26528580"
  },
  {
     "name":"Sagaing Region",
     "isoCode":"01",
     "countryCode":"MM",
     "latitude":"24.42838100",
     "longitude":"95.39395510"
  },
  {
     "name":"Naypyidaw Union Territory",
     "isoCode":"18",
     "countryCode":"MM",
     "latitude":"19.93862450",
     "longitude":"96.15269850"
  },
  {
     "name":"Mon State",
     "isoCode":"15",
     "countryCode":"MM",
     "latitude":"16.30031330",
     "longitude":"97.69822720"
  },
  {
     "name":"Bartın Province",
     "isoCode":"74",
     "countryCode":"TR",
     "latitude":"41.58105090",
     "longitude":"32.46097940"
  },
  {
     "name":"Kütahya Province",
     "isoCode":"43",
     "countryCode":"TR",
     "latitude":"39.35813700",
     "longitude":"29.60354950"
  },
  {
     "name":"Sakarya Province",
     "isoCode":"54",
     "countryCode":"TR",
     "latitude":"40.78885500",
     "longitude":"30.40595400"
  },
  {
     "name":"Edirne Province",
     "isoCode":"22",
     "countryCode":"TR",
     "latitude":"41.15172220",
     "longitude":"26.51379640"
  },
  {
     "name":"Van Province",
     "isoCode":"65",
     "countryCode":"TR",
     "latitude":"38.36794170",
     "longitude":"43.71827870"
  },
  {
     "name":"Bingöl Province",
     "isoCode":"12",
     "countryCode":"TR",
     "latitude":"39.06263540",
     "longitude":"40.76960950"
  },
  {
     "name":"Kilis Province",
     "isoCode":"79",
     "countryCode":"TR",
     "latitude":"36.82047750",
     "longitude":"37.16873390"
  },
  {
     "name":"Adıyaman Province",
     "isoCode":"02",
     "countryCode":"TR",
     "latitude":"37.90782910",
     "longitude":"38.48499230"
  },
  {
     "name":"Mersin Province",
     "isoCode":"33",
     "countryCode":"TR",
     "latitude":"36.81208580",
     "longitude":"34.64147500"
  },
  {
     "name":"Denizli Province",
     "isoCode":"20",
     "countryCode":"TR",
     "latitude":"37.61283950",
     "longitude":"29.23207840"
  },
  {
     "name":"Malatya Province",
     "isoCode":"44",
     "countryCode":"TR",
     "latitude":"38.40150570",
     "longitude":"37.95362980"
  },
  {
     "name":"Elazığ Province",
     "isoCode":"23",
     "countryCode":"TR",
     "latitude":"38.49648040",
     "longitude":"39.21990290"
  },
  {
     "name":"Erzincan Province",
     "isoCode":"24",
     "countryCode":"TR",
     "latitude":"39.76819140",
     "longitude":"39.05013060"
  },
  {
     "name":"Amasya Province",
     "isoCode":"05",
     "countryCode":"TR",
     "latitude":"40.65166080",
     "longitude":"35.90379660"
  },
  {
     "name":"Muş Province",
     "isoCode":"49",
     "countryCode":"TR",
     "latitude":"38.94618880",
     "longitude":"41.75389310"
  },
  {
     "name":"Bursa Province",
     "isoCode":"16",
     "countryCode":"TR",
     "latitude":"40.06554590",
     "longitude":"29.23207840"
  },
  {
     "name":"Eskişehir Province",
     "isoCode":"26",
     "countryCode":"TR",
     "latitude":"39.63296570",
     "longitude":"31.26263660"
  },
  {
     "name":"Erzurum Province",
     "isoCode":"25",
     "countryCode":"TR",
     "latitude":"40.07467990",
     "longitude":"41.66945620"
  },
  {
     "name":"Iğdır Province",
     "isoCode":"76",
     "countryCode":"TR",
     "latitude":"39.88798410",
     "longitude":"44.00483650"
  },
  {
     "name":"Tekirdağ Province",
     "isoCode":"59",
     "countryCode":"TR",
     "latitude":"41.11212270",
     "longitude":"27.26761160"
  },
  {
     "name":"Çankırı Province",
     "isoCode":"18",
     "countryCode":"TR",
     "latitude":"40.53690730",
     "longitude":"33.58838930"
  },
  {
     "name":"Antalya Province",
     "isoCode":"07",
     "countryCode":"TR",
     "latitude":"37.09516720",
     "longitude":"31.07937050"
  },
  {
     "name":"Istanbul Province",
     "isoCode":"34",
     "countryCode":"TR",
     "latitude":"41.16343020",
     "longitude":"28.76644080"
  },
  {
     "name":"Konya Province",
     "isoCode":"42",
     "countryCode":"TR",
     "latitude":"37.98381340",
     "longitude":"32.71813750"
  },
  {
     "name":"Bolu Province",
     "isoCode":"14",
     "countryCode":"TR",
     "latitude":"40.57597660",
     "longitude":"31.57880860"
  },
  {
     "name":"Çorum Province",
     "isoCode":"19",
     "countryCode":"TR",
     "latitude":"40.49982110",
     "longitude":"34.59862630"
  },
  {
     "name":"Ordu Province",
     "isoCode":"52",
     "countryCode":"TR",
     "latitude":"40.79905800",
     "longitude":"37.38990050"
  },
  {
     "name":"Balıkesir Province",
     "isoCode":"10",
     "countryCode":"TR",
     "latitude":"39.76167820",
     "longitude":"28.11226790"
  },
  {
     "name":"Kırklareli Province",
     "isoCode":"39",
     "countryCode":"TR",
     "latitude":"41.72597950",
     "longitude":"27.48383900"
  },
  {
     "name":"Bayburt Province",
     "isoCode":"69",
     "countryCode":"TR",
     "latitude":"40.26032000",
     "longitude":"40.22804800"
  },
  {
     "name":"Kırıkkale Province",
     "isoCode":"71",
     "countryCode":"TR",
     "latitude":"39.88768780",
     "longitude":"33.75552480"
  },
  {
     "name":"Afyonkarahisar Province",
     "isoCode":"03",
     "countryCode":"TR",
     "latitude":"38.73910990",
     "longitude":"30.71200230"
  },
  {
     "name":"Kırşehir Province",
     "isoCode":"40",
     "countryCode":"TR",
     "latitude":"39.22689050",
     "longitude":"33.97500180"
  },
  {
     "name":"Sivas Province",
     "isoCode":"58",
     "countryCode":"TR",
     "latitude":"39.44880390",
     "longitude":"37.12944970"
  },
  {
     "name":"Muğla Province",
     "isoCode":"48",
     "countryCode":"TR",
     "latitude":"37.18358190",
     "longitude":"28.48639630"
  },
  {
     "name":"Şanlıurfa Province",
     "isoCode":"63",
     "countryCode":"TR",
     "latitude":"37.35691020",
     "longitude":"39.15436770"
  },
  {
     "name":"Karaman Province",
     "isoCode":"70",
     "countryCode":"TR",
     "latitude":"37.24363360",
     "longitude":"33.61757700"
  },
  {
     "name":"Ardahan Province",
     "isoCode":"75",
     "countryCode":"TR",
     "latitude":"41.11129640",
     "longitude":"42.78316740"
  },
  {
     "name":"Giresun Province",
     "isoCode":"28",
     "countryCode":"TR",
     "latitude":"40.64616720",
     "longitude":"38.59355110"
  },
  {
     "name":"Aydın Province",
     "isoCode":"09",
     "countryCode":"TR",
     "latitude":"37.81170330",
     "longitude":"28.48639630"
  },
  {
     "name":"Yozgat Province",
     "isoCode":"66",
     "countryCode":"TR",
     "latitude":"39.72719790",
     "longitude":"35.10778580"
  },
  {
     "name":"Niğde Province",
     "isoCode":"51",
     "countryCode":"TR",
     "latitude":"38.09930860",
     "longitude":"34.68565090"
  },
  {
     "name":"Hakkâri Province",
     "isoCode":"30",
     "countryCode":"TR",
     "latitude":"37.44593190",
     "longitude":"43.74498410"
  },
  {
     "name":"Artvin Province",
     "isoCode":"08",
     "countryCode":"TR",
     "latitude":"41.07866400",
     "longitude":"41.76282230"
  },
  {
     "name":"Tunceli Province",
     "isoCode":"62",
     "countryCode":"TR",
     "latitude":"39.30735540",
     "longitude":"39.43877780"
  },
  {
     "name":"Ağrı Province",
     "isoCode":"04",
     "countryCode":"TR",
     "latitude":"39.62692180",
     "longitude":"43.02159650"
  },
  {
     "name":"Batman Province",
     "isoCode":"72",
     "countryCode":"TR",
     "latitude":"37.83624960",
     "longitude":"41.36057390"
  },
  {
     "name":"Kocaeli Province",
     "isoCode":"41",
     "countryCode":"TR",
     "latitude":"40.85327040",
     "longitude":"29.88152030"
  },
  {
     "name":"Nevşehir Province",
     "isoCode":"50",
     "countryCode":"TR",
     "latitude":"38.69393990",
     "longitude":"34.68565090"
  },
  {
     "name":"Kastamonu Province",
     "isoCode":"37",
     "countryCode":"TR",
     "latitude":"41.41038630",
     "longitude":"33.69983340"
  },
  {
     "name":"Manisa Province",
     "isoCode":"45",
     "countryCode":"TR",
     "latitude":"38.84193730",
     "longitude":"28.11226790"
  },
  {
     "name":"Tokat Province",
     "isoCode":"60",
     "countryCode":"TR",
     "latitude":"40.39027130",
     "longitude":"36.62518630"
  },
  {
     "name":"Kayseri Province",
     "isoCode":"38",
     "countryCode":"TR",
     "latitude":"38.62568540",
     "longitude":"35.74068820"
  },
  {
     "name":"Uşak Province",
     "isoCode":"64",
     "countryCode":"TR",
     "latitude":"38.54313190",
     "longitude":"29.23207840"
  },
  {
     "name":"Düzce Province",
     "isoCode":"81",
     "countryCode":"TR",
     "latitude":"40.87705310",
     "longitude":"31.31927130"
  },
  {
     "name":"Gaziantep Province",
     "isoCode":"27",
     "countryCode":"TR",
     "latitude":"37.07638820",
     "longitude":"37.38272340"
  },
  {
     "name":"Gümüşhane Province",
     "isoCode":"29",
     "countryCode":"TR",
     "latitude":"40.28036730",
     "longitude":"39.31432530"
  },
  {
     "name":"İzmir Province",
     "isoCode":"35",
     "countryCode":"TR",
     "latitude":"38.35916930",
     "longitude":"27.26761160"
  },
  {
     "name":"Trabzon Province",
     "isoCode":"61",
     "countryCode":"TR",
     "latitude":"40.79924100",
     "longitude":"39.58479440"
  },
  {
     "name":"Siirt Province",
     "isoCode":"56",
     "countryCode":"TR",
     "latitude":"37.86588620",
     "longitude":"42.14945230"
  },
  {
     "name":"Kars Province",
     "isoCode":"36",
     "countryCode":"TR",
     "latitude":"40.28076360",
     "longitude":"42.99195270"
  },
  {
     "name":"Burdur Province",
     "isoCode":"15",
     "countryCode":"TR",
     "latitude":"37.46126690",
     "longitude":"30.06652360"
  },
  {
     "name":"Aksaray Province",
     "isoCode":"68",
     "countryCode":"TR",
     "latitude":"38.33520430",
     "longitude":"33.97500180"
  },
  {
     "name":"Hatay Province",
     "isoCode":"31",
     "countryCode":"TR",
     "latitude":"36.40184880",
     "longitude":"36.34980970"
  },
  {
     "name":"Adana Province",
     "isoCode":"01",
     "countryCode":"TR",
     "latitude":"37.26123150",
     "longitude":"35.39050460"
  },
  {
     "name":"Zonguldak Province",
     "isoCode":"67",
     "countryCode":"TR",
     "latitude":"41.31249170",
     "longitude":"31.85982510"
  },
  {
     "name":"Osmaniye Province",
     "isoCode":"80",
     "countryCode":"TR",
     "latitude":"37.21302580",
     "longitude":"36.17626150"
  },
  {
     "name":"Bitlis Province",
     "isoCode":"13",
     "countryCode":"TR",
     "latitude":"38.65231330",
     "longitude":"42.42020280"
  },
  {
     "name":"Çanakkale Province",
     "isoCode":"17",
     "countryCode":"TR",
     "latitude":"40.05101040",
     "longitude":"26.98524220"
  },
  {
     "name":"Ankara Province",
     "isoCode":"06",
     "countryCode":"TR",
     "latitude":"39.78052450",
     "longitude":"32.71813750"
  },
  {
     "name":"Yalova Province",
     "isoCode":"77",
     "countryCode":"TR",
     "latitude":"40.57759860",
     "longitude":"29.20883030"
  },
  {
     "name":"Rize Province",
     "isoCode":"53",
     "countryCode":"TR",
     "latitude":"40.95814970",
     "longitude":"40.92269850"
  },
  {
     "name":"Samsun Province",
     "isoCode":"55",
     "countryCode":"TR",
     "latitude":"41.18648590",
     "longitude":"36.13226780"
  },
  {
     "name":"Bilecik Province",
     "isoCode":"11",
     "countryCode":"TR",
     "latitude":"40.05665550",
     "longitude":"30.06652360"
  },
  {
     "name":"Isparta Province",
     "isoCode":"32",
     "countryCode":"TR",
     "latitude":"38.02114640",
     "longitude":"31.07937050"
  },
  {
     "name":"Karabük Province",
     "isoCode":"78",
     "countryCode":"TR",
     "latitude":"41.18748900",
     "longitude":"32.74174190"
  },
  {
     "name":"Mardin Province",
     "isoCode":"47",
     "countryCode":"TR",
     "latitude":"37.34429290",
     "longitude":"40.61964870"
  },
  {
     "name":"Şırnak Province",
     "isoCode":"73",
     "countryCode":"TR",
     "latitude":"37.41874810",
     "longitude":"42.49183380"
  },
  {
     "name":"Diyarbakır Province",
     "isoCode":"21",
     "countryCode":"TR",
     "latitude":"38.10663720",
     "longitude":"40.54268960"
  },
  {
     "name":"Kahramanmaraş Province",
     "isoCode":"46",
     "countryCode":"TR",
     "latitude":"37.75030360",
     "longitude":"36.95410700"
  },
  {
     "name":"Lisbon District",
     "isoCode":"11",
     "countryCode":"PT",
     "latitude":"38.72232630",
     "longitude":"-9.13927140"
  },
  {
     "name":"Bragança District",
     "isoCode":"04",
     "countryCode":"PT",
     "latitude":"41.80616520",
     "longitude":"-6.75674270"
  },
  {
     "name":"Beja District",
     "isoCode":"02",
     "countryCode":"PT",
     "latitude":"37.96877860",
     "longitude":"-7.87216000"
  },
  {
     "name":"Madeira",
     "isoCode":"30",
     "countryCode":"PT",
     "latitude":"32.76070740",
     "longitude":"-16.95947230"
  },
  {
     "name":"Portalegre District",
     "isoCode":"12",
     "countryCode":"PT",
     "latitude":"39.29670860",
     "longitude":"-7.42847550"
  },
  {
     "name":"Azores",
     "isoCode":"20",
     "countryCode":"PT",
     "latitude":"37.74124880",
     "longitude":"-25.67559440"
  },
  {
     "name":"Vila Real District",
     "isoCode":"17",
     "countryCode":"PT",
     "latitude":"41.30035270",
     "longitude":"-7.74572740"
  },
  {
     "name":"Aveiro District",
     "isoCode":"01",
     "countryCode":"PT",
     "latitude":"40.72090230",
     "longitude":"-8.57210160"
  },
  {
     "name":"Évora District",
     "isoCode":"07",
     "countryCode":"PT",
     "latitude":"38.57444680",
     "longitude":"-7.90765530"
  },
  {
     "name":"Viseu District",
     "isoCode":"18",
     "countryCode":"PT",
     "latitude":"40.65884240",
     "longitude":"-7.91475600"
  },
  {
     "name":"Santarém District",
     "isoCode":"14",
     "countryCode":"PT",
     "latitude":"39.23666870",
     "longitude":"-8.68599440"
  },
  {
     "name":"Faro District",
     "isoCode":"08",
     "countryCode":"PT",
     "latitude":"37.01935480",
     "longitude":"-7.93043970"
  },
  {
     "name":"Leiria District",
     "isoCode":"10",
     "countryCode":"PT",
     "latitude":"39.77095320",
     "longitude":"-8.79218360"
  },
  {
     "name":"Castelo Branco District",
     "isoCode":"05",
     "countryCode":"PT",
     "latitude":"39.86313230",
     "longitude":"-7.48141630"
  },
  {
     "name":"Setúbal District",
     "isoCode":"15",
     "countryCode":"PT",
     "latitude":"38.52409330",
     "longitude":"-8.89258760"
  },
  {
     "name":"Porto District",
     "isoCode":"13",
     "countryCode":"PT",
     "latitude":"41.14766290",
     "longitude":"-8.60789730"
  },
  {
     "name":"Braga District",
     "isoCode":"03",
     "countryCode":"PT",
     "latitude":"41.55038800",
     "longitude":"-8.42613010"
  },
  {
     "name":"Viana do Castelo District",
     "isoCode":"16",
     "countryCode":"PT",
     "latitude":"41.69180460",
     "longitude":"-8.83445100"
  },
  {
     "name":"Coimbra District",
     "isoCode":"06",
     "countryCode":"PT",
     "latitude":"40.20579940",
     "longitude":"-8.41369000"
  },
  {
     "name":"Zhejiang",
     "isoCode":"ZJ",
     "countryCode":"CN",
     "latitude":"29.14164320",
     "longitude":"119.78892480"
  },
  {
     "name":"Fujian",
     "isoCode":"FJ",
     "countryCode":"CN",
     "latitude":"26.48368420",
     "longitude":"117.92490020"
  },
  {
     "name":"Shanghai",
     "isoCode":"SH",
     "countryCode":"CN",
     "latitude":"31.23041600",
     "longitude":"121.47370100"
  },
  {
     "name":"Jiangsu",
     "isoCode":"JS",
     "countryCode":"CN",
     "latitude":"33.14017150",
     "longitude":"119.78892480"
  },
  {
     "name":"Anhui",
     "isoCode":"AH",
     "countryCode":"CN",
     "latitude":"30.60067730",
     "longitude":"117.92490020"
  },
  {
     "name":"Shandong",
     "isoCode":"SD",
     "countryCode":"CN",
     "latitude":"37.80060640",
     "longitude":"-122.26999180"
  },
  {
     "name":"Jilin",
     "isoCode":"JL",
     "countryCode":"CN",
     "latitude":"43.83788300",
     "longitude":"126.54957200"
  },
  {
     "name":"Shanxi",
     "isoCode":"SX",
     "countryCode":"CN",
     "latitude":"37.24256490",
     "longitude":"111.85685860"
  },
  {
     "name":"Taiwan Province, People's Republic of China",
     "isoCode":"TW",
     "countryCode":"CN",
     "latitude":"23.69781000",
     "longitude":"120.96051500"
  },
  {
     "name":"Jiangxi",
     "isoCode":"JX",
     "countryCode":"CN",
     "latitude":"27.08745640",
     "longitude":"114.90422080"
  },
  {
     "name":"Beijing",
     "isoCode":"BJ",
     "countryCode":"CN",
     "latitude":"39.90419990",
     "longitude":"116.40739630"
  },
  {
     "name":"Hunan",
     "isoCode":"HN",
     "countryCode":"CN",
     "latitude":"36.73412940",
     "longitude":"-95.93449020"
  },
  {
     "name":"Henan",
     "isoCode":"HA",
     "countryCode":"CN",
     "latitude":"34.29043020",
     "longitude":"113.38235450"
  },
  {
     "name":"Yunnan",
     "isoCode":"YN",
     "countryCode":"CN",
     "latitude":"24.47528470",
     "longitude":"101.34310580"
  },
  {
     "name":"Guizhou",
     "isoCode":"GZ",
     "countryCode":"CN",
     "latitude":"26.84296450",
     "longitude":"107.29028390"
  },
  {
     "name":"Ningxia Hui Autonomous Region",
     "isoCode":"NX",
     "countryCode":"CN",
     "latitude":"37.19873100",
     "longitude":"106.15809370"
  },
  {
     "name":"Xinjiang",
     "isoCode":"XJ",
     "countryCode":"CN",
     "latitude":"42.52463570",
     "longitude":"87.53958550"
  },
  {
     "name":"Tibet Autonomous Region",
     "isoCode":"XZ",
     "countryCode":"CN",
     "latitude":"30.15336050",
     "longitude":"88.78786780"
  },
  {
     "name":"Heilongjiang",
     "isoCode":"HL",
     "countryCode":"CN",
     "latitude":"47.12164720",
     "longitude":"128.73823100"
  },
  {
     "name":"Macau",
     "isoCode":"MO",
     "countryCode":"CN",
     "latitude":"22.19874500",
     "longitude":"113.54387300"
  },
  {
     "name":"Hong Kong",
     "isoCode":"HK",
     "countryCode":"CN",
     "latitude":"22.31930390",
     "longitude":"114.16936110"
  },
  {
     "name":"Liaoning",
     "isoCode":"LN",
     "countryCode":"CN",
     "latitude":"41.94365430",
     "longitude":"122.52903760"
  },
  {
     "name":"Inner Mongolia",
     "isoCode":"NM",
     "countryCode":"CN",
     "latitude":"43.37822000",
     "longitude":"115.05948150"
  },
  {
     "name":"Qinghai",
     "isoCode":"QH",
     "countryCode":"CN",
     "latitude":"35.74479800",
     "longitude":"96.40773580"
  },
  {
     "name":"Chongqing",
     "isoCode":"CQ",
     "countryCode":"CN",
     "latitude":"29.43158610",
     "longitude":"106.91225100"
  },
  {
     "name":"Shaanxi",
     "isoCode":"SN",
     "countryCode":"CN",
     "latitude":"35.39399080",
     "longitude":"109.18800470"
  },
  {
     "name":"Hainan",
     "isoCode":"HI",
     "countryCode":"CN",
     "latitude":"19.56639470",
     "longitude":"109.94968600"
  },
  {
     "name":"Hubei",
     "isoCode":"HB",
     "countryCode":"CN",
     "latitude":"30.73781180",
     "longitude":"112.23840170"
  },
  {
     "name":"Gansu",
     "isoCode":"GS",
     "countryCode":"CN",
     "latitude":"35.75183260",
     "longitude":"104.28611160"
  },
  {
     "name":"Keelung",
     "isoCode":"TW-KEE",
     "countryCode":"CN",
     "latitude":"25.12760330",
     "longitude":"121.73918330"
  },
  {
     "name":"Sichuan",
     "isoCode":"SC",
     "countryCode":"CN",
     "latitude":"30.26380320",
     "longitude":"102.80547530"
  },
  {
     "name":"Guangxi Zhuang Autonomous Region",
     "isoCode":"GX",
     "countryCode":"CN",
     "latitude":"23.72475990",
     "longitude":"108.80761950"
  },
  {
     "name":"Guangdong",
     "isoCode":"GD",
     "countryCode":"CN",
     "latitude":"23.37903330",
     "longitude":"113.76328280"
  },
  {
     "name":"Hebei",
     "isoCode":"HE",
     "countryCode":"CN",
     "latitude":"37.89565940",
     "longitude":"114.90422080"
  },
  {
     "name":"South Governorate",
     "isoCode":"JA",
     "countryCode":"LB",
     "latitude":"33.27214790",
     "longitude":"35.20327780"
  },
  {
     "name":"Mount Lebanon Governorate",
     "isoCode":"JL",
     "countryCode":"LB",
     "latitude":"33.81008580",
     "longitude":"35.59731390"
  },
  {
     "name":"Baalbek-Hermel Governorate",
     "isoCode":"BH",
     "countryCode":"LB",
     "latitude":"34.26585560",
     "longitude":"36.34980970"
  },
  {
     "name":"North Governorate",
     "isoCode":"AS",
     "countryCode":"LB",
     "latitude":"34.43806250",
     "longitude":"35.83082330"
  },
  {
     "name":"Akkar Governorate",
     "isoCode":"AK",
     "countryCode":"LB",
     "latitude":"34.53287630",
     "longitude":"36.13281320"
  },
  {
     "name":"Beirut Governorate",
     "isoCode":"BA",
     "countryCode":"LB",
     "latitude":"33.88861060",
     "longitude":"35.49547720"
  },
  {
     "name":"Beqaa Governorate",
     "isoCode":"BI",
     "countryCode":"LB",
     "latitude":"33.84626620",
     "longitude":"35.90194890"
  },
  {
     "name":"Nabatieh Governorate",
     "isoCode":"NA",
     "countryCode":"LB",
     "latitude":"33.37716930",
     "longitude":"35.48382930"
  },
  {
     "name":"Isle of Wight",
     "isoCode":"IOW",
     "countryCode":"GB",
     "latitude":"50.69384790",
     "longitude":"-1.30473400"
  },
  {
     "name":"St Helens",
     "isoCode":"SHN",
     "countryCode":"GB",
     "latitude":"45.85896100",
     "longitude":"-122.82123560"
  },
  {
     "name":"London Borough of Brent",
     "isoCode":"BEN",
     "countryCode":"GB",
     "latitude":"51.56728080",
     "longitude":"-0.27105680"
  },
  {
     "name":"Walsall",
     "isoCode":"WLL",
     "countryCode":"GB",
     "latitude":"52.58621400",
     "longitude":"-1.98291900"
  },
  {
     "name":"Trafford",
     "isoCode":"TRF",
     "countryCode":"GB",
     "latitude":"40.38562460",
     "longitude":"-79.75893470"
  },
  {
     "name":"City of Southampton",
     "isoCode":"STH",
     "countryCode":"GB",
     "latitude":"50.90970040",
     "longitude":"-1.40435090"
  },
  {
     "name":"Sheffield",
     "isoCode":"SHF",
     "countryCode":"GB",
     "latitude":"36.09507430",
     "longitude":"-80.27884660"
  },
  {
     "name":"West Sussex",
     "isoCode":"WSX",
     "countryCode":"GB",
     "latitude":"50.92801430",
     "longitude":"-0.46170750"
  },
  {
     "name":"City of Peterborough",
     "isoCode":"PTE",
     "countryCode":"GB",
     "latitude":"44.30936360",
     "longitude":"-78.32015300"
  },
  {
     "name":"Caerphilly County Borough",
     "isoCode":"CAY",
     "countryCode":"GB",
     "latitude":"51.66044650",
     "longitude":"-3.21787240"
  },
  {
     "name":"Vale of Glamorgan",
     "isoCode":"VGL",
     "countryCode":"GB",
     "latitude":"51.40959580",
     "longitude":"-3.48481670"
  },
  {
     "name":"Shetland Islands",
     "isoCode":"ZET",
     "countryCode":"GB",
     "latitude":"60.52965070",
     "longitude":"-1.26594090"
  },
  {
     "name":"Rhondda Cynon Taf",
     "isoCode":"RCT",
     "countryCode":"GB",
     "latitude":"51.64902070",
     "longitude":"-3.42886920"
  },
  {
     "name":"Poole",
     "isoCode":"POL",
     "countryCode":"GB",
     "latitude":"50.71505000",
     "longitude":"-1.98724800"
  },
  {
     "name":"Central Bedfordshire",
     "isoCode":"CBF",
     "countryCode":"GB",
     "latitude":"52.00297440",
     "longitude":"-0.46513890"
  },
  {
     "name":"Down District Council",
     "isoCode":"DOW",
     "countryCode":"GB",
     "latitude":"54.24342870",
     "longitude":"-5.95779590"
  },
  {
     "name":"City of Portsmouth",
     "isoCode":"POR",
     "countryCode":"GB",
     "latitude":"36.83291500",
     "longitude":"-76.29755490"
  },
  {
     "name":"London Borough of Haringey",
     "isoCode":"HRY",
     "countryCode":"GB",
     "latitude":"51.59061130",
     "longitude":"-0.11097090"
  },
  {
     "name":"London Borough of Bexley",
     "isoCode":"BEX",
     "countryCode":"GB",
     "latitude":"51.45190210",
     "longitude":"0.11717860"
  },
  {
     "name":"Rotherham",
     "isoCode":"ROT",
     "countryCode":"GB",
     "latitude":"53.43260350",
     "longitude":"-1.36350090"
  },
  {
     "name":"Hartlepool",
     "isoCode":"HPL",
     "countryCode":"GB",
     "latitude":"54.69174500",
     "longitude":"-1.21292600"
  },
  {
     "name":"Telford and Wrekin",
     "isoCode":"TFW",
     "countryCode":"GB",
     "latitude":"52.74099160",
     "longitude":"-2.48685860"
  },
  {
     "name":"Belfast district",
     "isoCode":"BFS",
     "countryCode":"GB",
     "latitude":"54.61703660",
     "longitude":"-5.95318610"
  },
  {
     "name":"Cornwall",
     "isoCode":"CON",
     "countryCode":"GB",
     "latitude":"50.26604710",
     "longitude":"-5.05271250"
  },
  {
     "name":"London Borough of Sutton",
     "isoCode":"STN",
     "countryCode":"GB",
     "latitude":"51.35737620",
     "longitude":"-0.17527960"
  },
  {
     "name":"Omagh District Council",
     "isoCode":"OMH",
     "countryCode":"GB",
     "latitude":"54.45135240",
     "longitude":"-7.71250180"
  },
  {
     "name":"Banbridge",
     "isoCode":"BNB",
     "countryCode":"GB",
     "latitude":"54.34872900",
     "longitude":"-6.27048030"
  },
  {
     "name":"Causeway Coast and Glens",
     "isoCode":"CCG",
     "countryCode":"GB",
     "latitude":"55.04318300",
     "longitude":"-6.67412880"
  },
  {
     "name":"Newtownabbey Borough Council",
     "isoCode":"NTA",
     "countryCode":"GB",
     "latitude":"54.67924220",
     "longitude":"-5.95911020"
  },
  {
     "name":"City of Leicester",
     "isoCode":"LCE",
     "countryCode":"GB",
     "latitude":"52.63687780",
     "longitude":"-1.13975920"
  },
  {
     "name":"London Borough of Islington",
     "isoCode":"ISL",
     "countryCode":"GB",
     "latitude":"51.54650630",
     "longitude":"-0.10580580"
  },
  {
     "name":"Metropolitan Borough of Wigan",
     "isoCode":"WGN",
     "countryCode":"GB",
     "latitude":"53.51348120",
     "longitude":"-2.61069990"
  },
  {
     "name":"Oxfordshire",
     "isoCode":"OXF",
     "countryCode":"GB",
     "latitude":"51.76120560",
     "longitude":"-1.24646740"
  },
  {
     "name":"Magherafelt District Council",
     "isoCode":"MFT",
     "countryCode":"GB",
     "latitude":"54.75532790",
     "longitude":"-6.60774870"
  },
  {
     "name":"Southend-on-Sea",
     "isoCode":"SOS",
     "countryCode":"GB",
     "latitude":"51.54592690",
     "longitude":"0.70771230"
  },
  {
     "name":"Armagh, Banbridge and Craigavon",
     "isoCode":"ABC",
     "countryCode":"GB",
     "latitude":"54.39325920",
     "longitude":"-6.45634010"
  },
  {
     "name":"Perth and Kinross",
     "isoCode":"PKN",
     "countryCode":"GB",
     "latitude":"56.39538170",
     "longitude":"-3.42835470"
  },
  {
     "name":"London Borough of Waltham Forest",
     "isoCode":"WFT",
     "countryCode":"GB",
     "latitude":"51.58863830",
     "longitude":"-0.01176250"
  },
  {
     "name":"Rochdale",
     "isoCode":"RCH",
     "countryCode":"GB",
     "latitude":"53.60971360",
     "longitude":"-2.15610000"
  },
  {
     "name":"Merthyr Tydfil County Borough",
     "isoCode":"MTY",
     "countryCode":"GB",
     "latitude":"51.74674740",
     "longitude":"-3.38132750"
  },
  {
     "name":"Blackburn with Darwen",
     "isoCode":"BBD",
     "countryCode":"GB",
     "latitude":"53.69575220",
     "longitude":"-2.46829850"
  },
  {
     "name":"Knowsley",
     "isoCode":"KWL",
     "countryCode":"GB",
     "latitude":"53.45459400",
     "longitude":"-2.85290700"
  },
  {
     "name":"Armagh City and District Council",
     "isoCode":"ARM",
     "countryCode":"GB",
     "latitude":"54.39325920",
     "longitude":"-6.45634010"
  },
  {
     "name":"Middlesbrough",
     "isoCode":"MDB",
     "countryCode":"GB",
     "latitude":"54.57422700",
     "longitude":"-1.23495600"
  },
  {
     "name":"East Renfrewshire",
     "isoCode":"ERW",
     "countryCode":"GB",
     "latitude":"55.77047350",
     "longitude":"-4.33598210"
  },
  {
     "name":"Cumbria",
     "isoCode":"CMA",
     "countryCode":"GB",
     "latitude":"54.57723230",
     "longitude":"-2.79748350"
  },
  {
     "name":"Scotland",
     "isoCode":"SCT",
     "countryCode":"GB",
     "latitude":"56.49067120",
     "longitude":"-4.20264580"
  },
  {
     "name":"England",
     "isoCode":"ENG",
     "countryCode":"GB",
     "latitude":"52.35551770",
     "longitude":"-1.17431970"
  },
  {
     "name":"Northern Ireland",
     "isoCode":"NIR",
     "countryCode":"GB",
     "latitude":"54.78771490",
     "longitude":"-6.49231450"
  },
  {
     "name":"Wales",
     "isoCode":"WLS",
     "countryCode":"GB",
     "latitude":"52.13066070",
     "longitude":"-3.78371170"
  },
  {
     "name":"Bath and North East Somerset",
     "isoCode":"BAS",
     "countryCode":"GB",
     "latitude":"51.32501020",
     "longitude":"-2.47662410"
  },
  {
     "name":"Liverpool",
     "isoCode":"LIV",
     "countryCode":"GB",
     "latitude":"32.65649810",
     "longitude":"-115.47632410"
  },
  {
     "name":"Sandwell",
     "isoCode":"SAW",
     "countryCode":"GB",
     "latitude":"52.53616740",
     "longitude":"-2.01079300"
  },
  {
     "name":"Bournemouth",
     "isoCode":"BMH",
     "countryCode":"GB",
     "latitude":"50.71916400",
     "longitude":"-1.88076900"
  },
  {
     "name":"Isles of Scilly",
     "isoCode":"IOS",
     "countryCode":"GB",
     "latitude":"49.92772610",
     "longitude":"-6.32749660"
  },
  {
     "name":"Falkirk",
     "isoCode":"FAL",
     "countryCode":"GB",
     "latitude":"56.00187750",
     "longitude":"-3.78391310"
  },
  {
     "name":"Dorset",
     "isoCode":"DOR",
     "countryCode":"GB",
     "latitude":"50.74876350",
     "longitude":"-2.34447860"
  },
  {
     "name":"Scottish Borders",
     "isoCode":"SCB",
     "countryCode":"GB",
     "latitude":"55.54856970",
     "longitude":"-2.78613880"
  },
  {
     "name":"London Borough of Havering",
     "isoCode":"HAV",
     "countryCode":"GB",
     "latitude":"51.57792400",
     "longitude":"0.21208290"
  },
  {
     "name":"Moyle District Council",
     "isoCode":"MYL",
     "countryCode":"GB",
     "latitude":"55.20473270",
     "longitude":"-6.25317400"
  },
  {
     "name":"London Borough of Camden",
     "isoCode":"CMD",
     "countryCode":"GB",
     "latitude":"51.54547360",
     "longitude":"-0.16279020"
  },
  {
     "name":"Newry and Mourne District Council",
     "isoCode":"NYM",
     "countryCode":"GB",
     "latitude":"54.17425050",
     "longitude":"-6.33919920"
  },
  {
     "name":"Neath Port Talbot County Borough",
     "isoCode":"NTL",
     "countryCode":"GB",
     "latitude":"51.59785190",
     "longitude":"-3.78396680"
  },
  {
     "name":"Conwy County Borough",
     "isoCode":"CWY",
     "countryCode":"GB",
     "latitude":"53.29350130",
     "longitude":"-3.72651610"
  },
  {
     "name":"Outer Hebrides",
     "isoCode":"ELS",
     "countryCode":"GB",
     "latitude":"57.75989180",
     "longitude":"-7.01940340"
  },
  {
     "name":"West Lothian",
     "isoCode":"WLN",
     "countryCode":"GB",
     "latitude":"55.90701980",
     "longitude":"-3.55171670"
  },
  {
     "name":"Lincolnshire",
     "isoCode":"LIN",
     "countryCode":"GB",
     "latitude":"52.94518890",
     "longitude":"-0.16012460"
  },
  {
     "name":"London Borough of Barking and Dagenham",
     "isoCode":"BDG",
     "countryCode":"GB",
     "latitude":"51.55406660",
     "longitude":"0.13401700"
  },
  {
     "name":"City of Westminster",
     "isoCode":"WSM",
     "countryCode":"GB",
     "latitude":"39.57659770",
     "longitude":"-76.99721260"
  },
  {
     "name":"London Borough of Lewisham",
     "isoCode":"LEW",
     "countryCode":"GB",
     "latitude":"51.44145790",
     "longitude":"-0.01170060"
  },
  {
     "name":"City of Nottingham",
     "isoCode":"NGM",
     "countryCode":"GB",
     "latitude":"52.95478320",
     "longitude":"-1.15810860"
  },
  {
     "name":"Moray",
     "isoCode":"MRY",
     "countryCode":"GB",
     "latitude":"57.64984760",
     "longitude":"-3.31680390"
  },
  {
     "name":"Ballymoney",
     "isoCode":"BLY",
     "countryCode":"GB",
     "latitude":"55.07048880",
     "longitude":"-6.51737080"
  },
  {
     "name":"South Lanarkshire",
     "isoCode":"SLK",
     "countryCode":"GB",
     "latitude":"55.67359090",
     "longitude":"-3.78196610"
  },
  {
     "name":"Ballymena Borough",
     "isoCode":"BLA",
     "countryCode":"GB",
     "latitude":"54.86426000",
     "longitude":"-6.27910740"
  },
  {
     "name":"Doncaster",
     "isoCode":"DNC",
     "countryCode":"GB",
     "latitude":"53.52282000",
     "longitude":"-1.12846200"
  },
  {
     "name":"Northumberland",
     "isoCode":"NBL",
     "countryCode":"GB",
     "latitude":"55.20825420",
     "longitude":"-2.07841380"
  },
  {
     "name":"Fermanagh and Omagh",
     "isoCode":"FMO",
     "countryCode":"GB",
     "latitude":"54.45135240",
     "longitude":"-7.71250180"
  },
  {
     "name":"Tameside",
     "isoCode":"TAM",
     "countryCode":"GB",
     "latitude":"53.48058280",
     "longitude":"-2.08098910"
  },
  {
     "name":"Royal Borough of Kensington and Chelsea",
     "isoCode":"KEC",
     "countryCode":"GB",
     "latitude":"51.49908050",
     "longitude":"-0.19382530"
  },
  {
     "name":"Hertfordshire",
     "isoCode":"HRT",
     "countryCode":"GB",
     "latitude":"51.80978230",
     "longitude":"-0.23767440"
  },
  {
     "name":"East Riding of Yorkshire",
     "isoCode":"ERY",
     "countryCode":"GB",
     "latitude":"53.84161680",
     "longitude":"-0.43441060"
  },
  {
     "name":"Kirklees",
     "isoCode":"KIR",
     "countryCode":"GB",
     "latitude":"53.59334320",
     "longitude":"-1.80095090"
  },
  {
     "name":"City of Sunderland",
     "isoCode":"SND",
     "countryCode":"GB",
     "latitude":"54.88614890",
     "longitude":"-1.47857970"
  },
  {
     "name":"Gloucestershire",
     "isoCode":"GLS",
     "countryCode":"GB",
     "latitude":"51.86421120",
     "longitude":"-2.23803350"
  },
  {
     "name":"East Ayrshire",
     "isoCode":"EAY",
     "countryCode":"GB",
     "latitude":"55.45184960",
     "longitude":"-4.26444780"
  },
  {
     "name":"United Kingdom",
     "isoCode":"UKM",
     "countryCode":"GB",
     "latitude":"55.37805100",
     "longitude":"-3.43597300"
  },
  {
     "name":"London Borough of Hillingdon",
     "isoCode":"HIL",
     "countryCode":"GB",
     "latitude":"51.53518320",
     "longitude":"-0.44813780"
  },
  {
     "name":"South Ayrshire",
     "isoCode":"SAY",
     "countryCode":"GB",
     "latitude":"55.45889880",
     "longitude":"-4.62919940"
  },
  {
     "name":"Ascension Island",
     "isoCode":"SH-AC",
     "countryCode":"GB",
     "latitude":"-7.94671660",
     "longitude":"-14.35591580"
  },
  {
     "name":"Gwynedd",
     "isoCode":"GWN",
     "countryCode":"GB",
     "latitude":"52.92772660",
     "longitude":"-4.13348360"
  },
  {
     "name":"London Borough of Hounslow",
     "isoCode":"HNS",
     "countryCode":"GB",
     "latitude":"51.48283580",
     "longitude":"-0.38820620"
  },
  {
     "name":"Medway",
     "isoCode":"MDW",
     "countryCode":"GB",
     "latitude":"42.14176410",
     "longitude":"-71.39672560"
  },
  {
     "name":"Limavady Borough Council",
     "isoCode":"LMV",
     "countryCode":"GB",
     "latitude":"55.05168200",
     "longitude":"-6.94919440"
  },
  {
     "name":"Highland",
     "isoCode":"HLD",
     "countryCode":"GB",
     "latitude":"36.29675080",
     "longitude":"-95.83803660"
  },
  {
     "name":"North East Lincolnshire",
     "isoCode":"NEL",
     "countryCode":"GB",
     "latitude":"53.56682010",
     "longitude":"-0.08150660"
  },
  {
     "name":"London Borough of Harrow",
     "isoCode":"HRW",
     "countryCode":"GB",
     "latitude":"51.58816270",
     "longitude":"-0.34228510"
  },
  {
     "name":"Somerset",
     "isoCode":"SOM",
     "countryCode":"GB",
     "latitude":"51.10509700",
     "longitude":"-2.92623070"
  },
  {
     "name":"Angus",
     "isoCode":"ANS",
     "countryCode":"GB",
     "latitude":"37.27578860",
     "longitude":"-95.65010330"
  },
  {
     "name":"Inverclyde",
     "isoCode":"IVC",
     "countryCode":"GB",
     "latitude":"55.93165690",
     "longitude":"-4.68001580"
  },
  {
     "name":"Darlington",
     "isoCode":"DAL",
     "countryCode":"GB",
     "latitude":"34.29987620",
     "longitude":"-79.87617410"
  },
  {
     "name":"London Borough of Tower Hamlets",
     "isoCode":"TWH",
     "countryCode":"GB",
     "latitude":"51.52026070",
     "longitude":"-0.02933960"
  },
  {
     "name":"Wiltshire",
     "isoCode":"WIL",
     "countryCode":"GB",
     "latitude":"51.34919960",
     "longitude":"-1.99271050"
  },
  {
     "name":"Argyll and Bute",
     "isoCode":"AGB",
     "countryCode":"GB",
     "latitude":"56.40062140",
     "longitude":"-5.48074800"
  },
  {
     "name":"Strabane District Council",
     "isoCode":"STB",
     "countryCode":"GB",
     "latitude":"54.82738650",
     "longitude":"-7.46331030"
  },
  {
     "name":"Stockport",
     "isoCode":"SKP",
     "countryCode":"GB",
     "latitude":"53.41063160",
     "longitude":"-2.15753320"
  },
  {
     "name":"Brighton and Hove",
     "isoCode":"BNH",
     "countryCode":"GB",
     "latitude":"50.82262880",
     "longitude":"-0.13704700"
  },
  {
     "name":"London Borough of Lambeth",
     "isoCode":"LBH",
     "countryCode":"GB",
     "latitude":"51.45714770",
     "longitude":"-0.12306810"
  },
  {
     "name":"London Borough of Redbridge",
     "isoCode":"RDB",
     "countryCode":"GB",
     "latitude":"51.58861210",
     "longitude":"0.08239820"
  },
  {
     "name":"Manchester",
     "isoCode":"MAN",
     "countryCode":"GB",
     "latitude":"53.48075930",
     "longitude":"-2.24263050"
  },
  {
     "name":"Mid Ulster",
     "isoCode":"MUL",
     "countryCode":"GB",
     "latitude":"54.64113010",
     "longitude":"-6.75225490"
  },
  {
     "name":"South Gloucestershire",
     "isoCode":"SGC",
     "countryCode":"GB",
     "latitude":"51.52643610",
     "longitude":"-2.47284870"
  },
  {
     "name":"Aberdeenshire",
     "isoCode":"ABD",
     "countryCode":"GB",
     "latitude":"57.28687230",
     "longitude":"-2.38156840"
  },
  {
     "name":"Monmouthshire",
     "isoCode":"MON",
     "countryCode":"GB",
     "latitude":"51.81161000",
     "longitude":"-2.71634170"
  },
  {
     "name":"Derbyshire",
     "isoCode":"DBY",
     "countryCode":"GB",
     "latitude":"53.10467820",
     "longitude":"-1.56238850"
  },
  {
     "name":"Glasgow",
     "isoCode":"GLG",
     "countryCode":"GB",
     "latitude":"55.86423700",
     "longitude":"-4.25180600"
  },
  {
     "name":"Buckinghamshire",
     "isoCode":"BKM",
     "countryCode":"GB",
     "latitude":"51.80722040",
     "longitude":"-0.81276640"
  },
  {
     "name":"County Durham",
     "isoCode":"DUR",
     "countryCode":"GB",
     "latitude":"54.72940990",
     "longitude":"-1.88115980"
  },
  {
     "name":"Shropshire",
     "isoCode":"SHR",
     "countryCode":"GB",
     "latitude":"52.70636570",
     "longitude":"-2.74178490"
  },
  {
     "name":"Wirral",
     "isoCode":"WRL",
     "countryCode":"GB",
     "latitude":"53.37271810",
     "longitude":"-3.07375400"
  },
  {
     "name":"South Tyneside",
     "isoCode":"STY",
     "countryCode":"GB",
     "latitude":"54.96366930",
     "longitude":"-1.44186340"
  },
  {
     "name":"Essex",
     "isoCode":"ESS",
     "countryCode":"GB",
     "latitude":"51.57424470",
     "longitude":"0.48567810"
  },
  {
     "name":"London Borough of Hackney",
     "isoCode":"HCK",
     "countryCode":"GB",
     "latitude":"51.57344500",
     "longitude":"-0.07243760"
  },
  {
     "name":"Antrim and Newtownabbey",
     "isoCode":"ANN",
     "countryCode":"GB",
     "latitude":"54.69568870",
     "longitude":"-5.94810690"
  },
  {
     "name":"City of Bristol",
     "isoCode":"BST",
     "countryCode":"GB",
     "latitude":"41.67352200",
     "longitude":"-72.94653750"
  },
  {
     "name":"East Sussex",
     "isoCode":"ESX",
     "countryCode":"GB",
     "latitude":"50.90859550",
     "longitude":"0.24941660"
  },
  {
     "name":"Dumfries and Galloway",
     "isoCode":"DGY",
     "countryCode":"GB",
     "latitude":"55.07010730",
     "longitude":"-3.60525810"
  },
  {
     "name":"Milton Keynes",
     "isoCode":"MIK",
     "countryCode":"GB",
     "latitude":"52.08520380",
     "longitude":"-0.73331330"
  },
  {
     "name":"Derry City Council",
     "isoCode":"DRY",
     "countryCode":"GB",
     "latitude":"54.96907780",
     "longitude":"-7.19583510"
  },
  {
     "name":"London Borough of Newham",
     "isoCode":"NWM",
     "countryCode":"GB",
     "latitude":"51.52551620",
     "longitude":"0.03521630"
  },
  {
     "name":"Wokingham",
     "isoCode":"WOK",
     "countryCode":"GB",
     "latitude":"51.41045700",
     "longitude":"-0.83386100"
  },
  {
     "name":"Warrington",
     "isoCode":"WRT",
     "countryCode":"GB",
     "latitude":"40.24927410",
     "longitude":"-75.13406040"
  },
  {
     "name":"Stockton-on-Tees",
     "isoCode":"STT",
     "countryCode":"GB",
     "latitude":"54.57045510",
     "longitude":"-1.32898210"
  },
  {
     "name":"Swindon",
     "isoCode":"SWD",
     "countryCode":"GB",
     "latitude":"51.55577390",
     "longitude":"-1.77971760"
  },
  {
     "name":"Cambridgeshire",
     "isoCode":"CAM",
     "countryCode":"GB",
     "latitude":"52.20529730",
     "longitude":"0.12181950"
  },
  {
     "name":"City of London",
     "isoCode":"LND",
     "countryCode":"GB",
     "latitude":"51.51234430",
     "longitude":"-0.09098520"
  },
  {
     "name":"Birmingham",
     "isoCode":"BIR",
     "countryCode":"GB",
     "latitude":"33.51858920",
     "longitude":"-86.81035670"
  },
  {
     "name":"City of York",
     "isoCode":"YOR",
     "countryCode":"GB",
     "latitude":"53.95996510",
     "longitude":"-1.08729790"
  },
  {
     "name":"Slough",
     "isoCode":"SLG",
     "countryCode":"GB",
     "latitude":"51.51053840",
     "longitude":"-0.59504060"
  },
  {
     "name":"Edinburgh",
     "isoCode":"EDH",
     "countryCode":"GB",
     "latitude":"55.95325200",
     "longitude":"-3.18826700"
  },
  {
     "name":"Mid and East Antrim",
     "isoCode":"MEA",
     "countryCode":"GB",
     "latitude":"54.93993410",
     "longitude":"-6.11374230"
  },
  {
     "name":"North Somerset",
     "isoCode":"NSM",
     "countryCode":"GB",
     "latitude":"51.38790280",
     "longitude":"-2.77810910"
  },
  {
     "name":"Gateshead",
     "isoCode":"GAT",
     "countryCode":"GB",
     "latitude":"54.95268000",
     "longitude":"-1.60341100"
  },
  {
     "name":"London Borough of Southwark",
     "isoCode":"SWK",
     "countryCode":"GB",
     "latitude":"51.48805720",
     "longitude":"-0.07628380"
  },
  {
     "name":"City and County of Swansea",
     "isoCode":"SWA",
     "countryCode":"GB",
     "latitude":"51.62144000",
     "longitude":"-3.94364600"
  },
  {
     "name":"London Borough of Wandsworth",
     "isoCode":"WND",
     "countryCode":"GB",
     "latitude":"51.45682740",
     "longitude":"-0.18966380"
  },
  {
     "name":"Hampshire",
     "isoCode":"HAM",
     "countryCode":"GB",
     "latitude":"51.05769480",
     "longitude":"-1.30806290"
  },
  {
     "name":"Wrexham County Borough",
     "isoCode":"WRX",
     "countryCode":"GB",
     "latitude":"53.03013780",
     "longitude":"-3.02614870"
  },
  {
     "name":"Flintshire",
     "isoCode":"FLN",
     "countryCode":"GB",
     "latitude":"53.16686580",
     "longitude":"-3.14189080"
  },
  {
     "name":"Coventry",
     "isoCode":"COV",
     "countryCode":"GB",
     "latitude":"52.40682200",
     "longitude":"-1.51969300"
  },
  {
     "name":"Carrickfergus Borough Council",
     "isoCode":"CKF",
     "countryCode":"GB",
     "latitude":"54.72568430",
     "longitude":"-5.80937190"
  },
  {
     "name":"West Dunbartonshire",
     "isoCode":"WDU",
     "countryCode":"GB",
     "latitude":"55.94509250",
     "longitude":"-4.56462590"
  },
  {
     "name":"Powys",
     "isoCode":"POW",
     "countryCode":"GB",
     "latitude":"52.64642490",
     "longitude":"-3.32609040"
  },
  {
     "name":"Cheshire West and Chester",
     "isoCode":"CHW",
     "countryCode":"GB",
     "latitude":"53.23029740",
     "longitude":"-2.71511170"
  },
  {
     "name":"Renfrewshire",
     "isoCode":"RFW",
     "countryCode":"GB",
     "latitude":"55.84665400",
     "longitude":"-4.53312590"
  },
  {
     "name":"Cheshire East",
     "isoCode":"CHE",
     "countryCode":"GB",
     "latitude":"53.16104460",
     "longitude":"-2.21859320"
  },
  {
     "name":"Cookstown District Council",
     "isoCode":"CKT",
     "countryCode":"GB",
     "latitude":"54.64181580",
     "longitude":"-6.74438950"
  },
  {
     "name":"Derry City and Strabane",
     "isoCode":"DRS",
     "countryCode":"GB",
     "latitude":"55.00474430",
     "longitude":"-7.32092220"
  },
  {
     "name":"Staffordshire",
     "isoCode":"STS",
     "countryCode":"GB",
     "latitude":"52.87927450",
     "longitude":"-2.05718680"
  },
  {
     "name":"London Borough of Hammersmith and Fulham",
     "isoCode":"HMF",
     "countryCode":"GB",
     "latitude":"51.49901560",
     "longitude":"-0.22915000"
  },
  {
     "name":"Craigavon Borough Council",
     "isoCode":"CGV",
     "countryCode":"GB",
     "latitude":"54.39325920",
     "longitude":"-6.45634010"
  },
  {
     "name":"Clackmannanshire",
     "isoCode":"CLK",
     "countryCode":"GB",
     "latitude":"56.10753510",
     "longitude":"-3.75294090"
  },
  {
     "name":"Blackpool",
     "isoCode":"BPL",
     "countryCode":"GB",
     "latitude":"53.81750530",
     "longitude":"-3.03567480"
  },
  {
     "name":"Bridgend County Borough",
     "isoCode":"BGE",
     "countryCode":"GB",
     "latitude":"51.50831990",
     "longitude":"-3.58120750"
  },
  {
     "name":"North Lincolnshire",
     "isoCode":"NLN",
     "countryCode":"GB",
     "latitude":"53.60555920",
     "longitude":"-0.55965820"
  },
  {
     "name":"East Dunbartonshire",
     "isoCode":"EDU",
     "countryCode":"GB",
     "latitude":"55.97431620",
     "longitude":"-4.20229800"
  },
  {
     "name":"Reading",
     "isoCode":"RDG",
     "countryCode":"GB",
     "latitude":"36.14866590",
     "longitude":"-95.98400120"
  },
  {
     "name":"Nottinghamshire",
     "isoCode":"NTT",
     "countryCode":"GB",
     "latitude":"53.10031900",
     "longitude":"-0.99363060"
  },
  {
     "name":"Dudley",
     "isoCode":"DUD",
     "countryCode":"GB",
     "latitude":"42.04336610",
     "longitude":"-71.92760330"
  },
  {
     "name":"Newcastle upon Tyne",
     "isoCode":"NET",
     "countryCode":"GB",
     "latitude":"54.97825200",
     "longitude":"-1.61778000"
  },
  {
     "name":"Bury",
     "isoCode":"BUR",
     "countryCode":"GB",
     "latitude":"53.59334980",
     "longitude":"-2.29660540"
  },
  {
     "name":"Lisburn and Castlereagh",
     "isoCode":"LBC",
     "countryCode":"GB",
     "latitude":"54.49815840",
     "longitude":"-6.13067910"
  },
  {
     "name":"Coleraine Borough Council",
     "isoCode":"CLR",
     "countryCode":"GB",
     "latitude":"55.14515700",
     "longitude":"-6.67598140"
  },
  {
     "name":"East Lothian",
     "isoCode":"ELN",
     "countryCode":"GB",
     "latitude":"55.94933830",
     "longitude":"-2.77044640"
  },
  {
     "name":"Aberdeen",
     "isoCode":"ABE",
     "countryCode":"GB",
     "latitude":"57.14971700",
     "longitude":"-2.09427800"
  },
  {
     "name":"Kent",
     "isoCode":"KEN",
     "countryCode":"GB",
     "latitude":"41.15366740",
     "longitude":"-81.35788590"
  },
  {
     "name":"Wakefield",
     "isoCode":"WKF",
     "countryCode":"GB",
     "latitude":"42.50393950",
     "longitude":"-71.07233910"
  },
  {
     "name":"Halton",
     "isoCode":"HAL",
     "countryCode":"GB",
     "latitude":"43.53253720",
     "longitude":"-79.87448360"
  },
  {
     "name":"Suffolk",
     "isoCode":"SFK",
     "countryCode":"GB",
     "latitude":"52.18724720",
     "longitude":"0.97078010"
  },
  {
     "name":"Thurrock",
     "isoCode":"THR",
     "countryCode":"GB",
     "latitude":"51.49345570",
     "longitude":"0.35291970"
  },
  {
     "name":"Solihull",
     "isoCode":"SOL",
     "countryCode":"GB",
     "latitude":"52.41181100",
     "longitude":"-1.77761000"
  },
  {
     "name":"Bracknell Forest",
     "isoCode":"BRC",
     "countryCode":"GB",
     "latitude":"51.41538280",
     "longitude":"-0.75364950"
  },
  {
     "name":"West Berkshire",
     "isoCode":"WBK",
     "countryCode":"GB",
     "latitude":"51.43082550",
     "longitude":"-1.14449270"
  },
  {
     "name":"Rutland",
     "isoCode":"RUT",
     "countryCode":"GB",
     "latitude":"43.61062370",
     "longitude":"-72.97260650"
  },
  {
     "name":"Norfolk",
     "isoCode":"NFK",
     "countryCode":"GB",
     "latitude":"36.85076890",
     "longitude":"-76.28587260"
  },
  {
     "name":"Orkney Islands",
     "isoCode":"ORK",
     "countryCode":"GB",
     "latitude":"58.98094010",
     "longitude":"-2.96052060"
  },
  {
     "name":"City of Kingston upon Hull",
     "isoCode":"KHL",
     "countryCode":"GB",
     "latitude":"53.76762360",
     "longitude":"-0.32741980"
  },
  {
     "name":"London Borough of Enfield",
     "isoCode":"ENF",
     "countryCode":"GB",
     "latitude":"51.66229090",
     "longitude":"-0.11806510"
  },
  {
     "name":"Oldham",
     "isoCode":"OLD",
     "countryCode":"GB",
     "latitude":"42.20405980",
     "longitude":"-71.20481190"
  },
  {
     "name":"Torbay",
     "isoCode":"TOB",
     "countryCode":"GB",
     "latitude":"50.43923290",
     "longitude":"-3.53698990"
  },
  {
     "name":"Fife",
     "isoCode":"FIF",
     "countryCode":"GB",
     "latitude":"56.20820780",
     "longitude":"-3.14951750"
  },
  {
     "name":"Northamptonshire",
     "isoCode":"NTH",
     "countryCode":"GB",
     "latitude":"52.27299440",
     "longitude":"-0.87555150"
  },
  {
     "name":"Royal Borough of Kingston upon Thames",
     "isoCode":"KTT",
     "countryCode":"GB",
     "latitude":"51.37811700",
     "longitude":"-0.29270900"
  },
  {
     "name":"Windsor and Maidenhead",
     "isoCode":"WNM",
     "countryCode":"GB",
     "latitude":"51.47997120",
     "longitude":"-0.62425650"
  },
  {
     "name":"London Borough of Merton",
     "isoCode":"MRT",
     "countryCode":"GB",
     "latitude":"51.40977420",
     "longitude":"-0.21080840"
  },
  {
     "name":"Carmarthenshire",
     "isoCode":"CMN",
     "countryCode":"GB",
     "latitude":"51.85723090",
     "longitude":"-4.31159590"
  },
  {
     "name":"City of Derby",
     "isoCode":"DER",
     "countryCode":"GB",
     "latitude":"37.54837550",
     "longitude":"-97.24851910"
  },
  {
     "name":"Pembrokeshire",
     "isoCode":"PEM",
     "countryCode":"GB",
     "latitude":"51.67407800",
     "longitude":"-4.90887850"
  },
  {
     "name":"North Lanarkshire",
     "isoCode":"NLK",
     "countryCode":"GB",
     "latitude":"55.86624320",
     "longitude":"-3.96131440"
  },
  {
     "name":"Stirling",
     "isoCode":"STG",
     "countryCode":"GB",
     "latitude":"56.11652270",
     "longitude":"-3.93690290"
  },
  {
     "name":"City of Wolverhampton",
     "isoCode":"WLV",
     "countryCode":"GB",
     "latitude":"52.58891200",
     "longitude":"-2.15646300"
  },
  {
     "name":"London Borough of Bromley",
     "isoCode":"BRY",
     "countryCode":"GB",
     "latitude":"51.36797050",
     "longitude":"0.07006200"
  },
  {
     "name":"Devon",
     "isoCode":"DEV",
     "countryCode":"GB",
     "latitude":"50.71555910",
     "longitude":"-3.53087500"
  },
  {
     "name":"Royal Borough of Greenwich",
     "isoCode":"GRE",
     "countryCode":"GB",
     "latitude":"51.48346270",
     "longitude":"0.05862020"
  },
  {
     "name":"Salford",
     "isoCode":"SLF",
     "countryCode":"GB",
     "latitude":"53.48752350",
     "longitude":"-2.29012640"
  },
  {
     "name":"Lisburn City Council",
     "isoCode":"LSB",
     "countryCode":"GB",
     "latitude":"54.49815840",
     "longitude":"-6.13067910"
  },
  {
     "name":"Lancashire",
     "isoCode":"LAN",
     "countryCode":"GB",
     "latitude":"53.76322540",
     "longitude":"-2.70440520"
  },
  {
     "name":"Torfaen",
     "isoCode":"TOF",
     "countryCode":"GB",
     "latitude":"51.70022530",
     "longitude":"-3.04460150"
  },
  {
     "name":"Denbighshire",
     "isoCode":"DEN",
     "countryCode":"GB",
     "latitude":"53.18422880",
     "longitude":"-3.42249850"
  },
  {
     "name":"Ards",
     "isoCode":"ARD",
     "countryCode":"GB",
     "latitude":"42.13918510",
     "longitude":"-87.86149720"
  },
  {
     "name":"Barnsley",
     "isoCode":"BNS",
     "countryCode":"GB",
     "latitude":"34.29949560",
     "longitude":"-84.98458090"
  },
  {
     "name":"Herefordshire",
     "isoCode":"HEF",
     "countryCode":"GB",
     "latitude":"52.07651640",
     "longitude":"-2.65441820"
  },
  {
     "name":"London Borough of Richmond upon Thames",
     "isoCode":"RIC",
     "countryCode":"GB",
     "latitude":"51.46130540",
     "longitude":"-0.30377090"
  },
  {
     "name":"Saint Helena",
     "isoCode":"SH-HL",
     "countryCode":"GB",
     "latitude":"-15.96501040",
     "longitude":"-5.70892410"
  },
  {
     "name":"Leeds",
     "isoCode":"LDS",
     "countryCode":"GB",
     "latitude":"53.80075540",
     "longitude":"-1.54907740"
  },
  {
     "name":"Bolton",
     "isoCode":"BOL",
     "countryCode":"GB",
     "latitude":"44.37264760",
     "longitude":"-72.87876250"
  },
  {
     "name":"Warwickshire",
     "isoCode":"WAR",
     "countryCode":"GB",
     "latitude":"52.26713530",
     "longitude":"-1.46752160"
  },
  {
     "name":"City of Stoke-on-Trent",
     "isoCode":"STE",
     "countryCode":"GB",
     "latitude":"53.00266800",
     "longitude":"-2.17940400"
  },
  {
     "name":"Bedford",
     "isoCode":"BDF",
     "countryCode":"GB",
     "latitude":"32.84401700",
     "longitude":"-97.14306710"
  },
  {
     "name":"Dungannon and South Tyrone Borough Council",
     "isoCode":"DGN",
     "countryCode":"GB",
     "latitude":"54.50826840",
     "longitude":"-6.76658910"
  },
  {
     "name":"Ceredigion",
     "isoCode":"CGN",
     "countryCode":"GB",
     "latitude":"52.21914290",
     "longitude":"-3.93212560"
  },
  {
     "name":"Worcestershire",
     "isoCode":"WOR",
     "countryCode":"GB",
     "latitude":"52.25452250",
     "longitude":"-2.26683820"
  },
  {
     "name":"Dundee",
     "isoCode":"DND",
     "countryCode":"GB",
     "latitude":"56.46201800",
     "longitude":"-2.97072100"
  },
  {
     "name":"London Borough of Croydon",
     "isoCode":"CRY",
     "countryCode":"GB",
     "latitude":"51.38274460",
     "longitude":"-0.09851630"
  },
  {
     "name":"North Down Borough Council",
     "isoCode":"NDN",
     "countryCode":"GB",
     "latitude":"54.65362970",
     "longitude":"-5.67249250"
  },
  {
     "name":"City of Plymouth",
     "isoCode":"PLY",
     "countryCode":"GB",
     "latitude":"42.37089410",
     "longitude":"-83.46971410"
  },
  {
     "name":"Larne Borough Council",
     "isoCode":"LRN",
     "countryCode":"GB",
     "latitude":"54.85780030",
     "longitude":"-5.82362240"
  },
  {
     "name":"Leicestershire",
     "isoCode":"LEC",
     "countryCode":"GB",
     "latitude":"52.77257100",
     "longitude":"-1.20521260"
  },
  {
     "name":"Calderdale",
     "isoCode":"CLD",
     "countryCode":"GB",
     "latitude":"53.72478450",
     "longitude":"-1.86583570"
  },
  {
     "name":"Sefton",
     "isoCode":"SFT",
     "countryCode":"GB",
     "latitude":"53.50344490",
     "longitude":"-2.97035900"
  },
  {
     "name":"Midlothian",
     "isoCode":"MLN",
     "countryCode":"GB",
     "latitude":"32.47533500",
     "longitude":"-97.01031810"
  },
  {
     "name":"London Borough of Barnet",
     "isoCode":"BNE",
     "countryCode":"GB",
     "latitude":"51.60496730",
     "longitude":"-0.20762950"
  },
  {
     "name":"North Tyneside",
     "isoCode":"NTY",
     "countryCode":"GB",
     "latitude":"55.01823990",
     "longitude":"-1.48584360"
  },
  {
     "name":"North Yorkshire",
     "isoCode":"NYK",
     "countryCode":"GB",
     "latitude":"53.99150280",
     "longitude":"-1.54120150"
  },
  {
     "name":"Ards and North Down",
     "isoCode":"AND",
     "countryCode":"GB",
     "latitude":"54.58996450",
     "longitude":"-5.59849720"
  },
  {
     "name":"Newport",
     "isoCode":"NWP",
     "countryCode":"GB",
     "latitude":"37.52782340",
     "longitude":"-94.10438760"
  },
  {
     "name":"Castlereagh",
     "isoCode":"CSR",
     "countryCode":"GB",
     "latitude":"54.57567900",
     "longitude":"-5.88840280"
  },
  {
     "name":"Surrey",
     "isoCode":"SRY",
     "countryCode":"GB",
     "latitude":"51.31475930",
     "longitude":"-0.55995010"
  },
  {
     "name":"Redcar and Cleveland",
     "isoCode":"RCC",
     "countryCode":"GB",
     "latitude":"54.59713440",
     "longitude":"-1.07759970"
  },
  {
     "name":"City and County of Cardiff",
     "isoCode":"CRF",
     "countryCode":"GB",
     "latitude":"51.48158100",
     "longitude":"-3.17909000"
  },
  {
     "name":"Bradford",
     "isoCode":"BRD",
     "countryCode":"GB",
     "latitude":"53.79598400",
     "longitude":"-1.75939800"
  },
  {
     "name":"Blaenau Gwent County Borough",
     "isoCode":"BGW",
     "countryCode":"GB",
     "latitude":"51.78757790",
     "longitude":"-3.20439310"
  },
  {
     "name":"Fermanagh District Council",
     "isoCode":"FER",
     "countryCode":"GB",
     "latitude":"54.34479780",
     "longitude":"-7.63842180"
  },
  {
     "name":"London Borough of Ealing",
     "isoCode":"EAL",
     "countryCode":"GB",
     "latitude":"51.52503660",
     "longitude":"-0.34139650"
  },
  {
     "name":"Antrim",
     "isoCode":"ANT",
     "countryCode":"GB",
     "latitude":"54.71953380",
     "longitude":"-6.20724980"
  },
  {
     "name":"Newry, Mourne and Down",
     "isoCode":"NMD",
     "countryCode":"GB",
     "latitude":"54.24342870",
     "longitude":"-5.95779590"
  },
  {
     "name":"North Ayrshire",
     "isoCode":"NAY",
     "countryCode":"GB",
     "latitude":"55.64167310",
     "longitude":"-4.75946000"
  },
  {
     "name":"Tashkent",
     "isoCode":"TK",
     "countryCode":"UZ",
     "latitude":"41.29949580",
     "longitude":"69.24007340"
  },
  {
     "name":"Namangan Region",
     "isoCode":"NG",
     "countryCode":"UZ",
     "latitude":"41.05100370",
     "longitude":"71.09731700"
  },
  {
     "name":"Fergana Region",
     "isoCode":"FA",
     "countryCode":"UZ",
     "latitude":"40.45680810",
     "longitude":"71.28742090"
  },
  {
     "name":"Xorazm Region",
     "isoCode":"XO",
     "countryCode":"UZ",
     "latitude":"41.35653360",
     "longitude":"60.85666860"
  },
  {
     "name":"Andijan Region",
     "isoCode":"AN",
     "countryCode":"UZ",
     "latitude":"40.76859410",
     "longitude":"72.23637900"
  },
  {
     "name":"Bukhara Region",
     "isoCode":"BU",
     "countryCode":"UZ",
     "latitude":"40.25041620",
     "longitude":"63.20321510"
  },
  {
     "name":"Navoiy Region",
     "isoCode":"NW",
     "countryCode":"UZ",
     "latitude":"42.69885750",
     "longitude":"64.63376850"
  },
  {
     "name":"Qashqadaryo Region",
     "isoCode":"QA",
     "countryCode":"UZ",
     "latitude":"38.89862310",
     "longitude":"66.04635340"
  },
  {
     "name":"Samarqand Region",
     "isoCode":"SA",
     "countryCode":"UZ",
     "latitude":"39.62701200",
     "longitude":"66.97497310"
  },
  {
     "name":"Jizzakh Region",
     "isoCode":"JI",
     "countryCode":"UZ",
     "latitude":"40.47064150",
     "longitude":"67.57085360"
  },
  {
     "name":"Surxondaryo Region",
     "isoCode":"SU",
     "countryCode":"UZ",
     "latitude":"37.94090050",
     "longitude":"67.57085360"
  },
  {
     "name":"Sirdaryo Region",
     "isoCode":"SI",
     "countryCode":"UZ",
     "latitude":"40.38638080",
     "longitude":"68.71549750"
  },
  {
     "name":"Karakalpakstan",
     "isoCode":"QR",
     "countryCode":"UZ",
     "latitude":"43.80413340",
     "longitude":"59.44579880"
  },
  {
     "name":"Tashkent Region",
     "isoCode":"TO",
     "countryCode":"UZ",
     "latitude":"41.22132340",
     "longitude":"69.85974060"
  },
  {
     "name":"Ariana Governorate",
     "isoCode":"12",
     "countryCode":"TN",
     "latitude":"36.99227510",
     "longitude":"10.12551640"
  },
  {
     "name":"Bizerte Governorate",
     "isoCode":"23",
     "countryCode":"TN",
     "latitude":"37.16093970",
     "longitude":"9.63413500"
  },
  {
     "name":"Jendouba Governorate",
     "isoCode":"32",
     "countryCode":"TN",
     "latitude":"36.71818620",
     "longitude":"8.74811670"
  },
  {
     "name":"Monastir Governorate",
     "isoCode":"52",
     "countryCode":"TN",
     "latitude":"35.76425150",
     "longitude":"10.81128850"
  },
  {
     "name":"Tunis Governorate",
     "isoCode":"11",
     "countryCode":"TN",
     "latitude":"36.83749460",
     "longitude":"10.19273890"
  },
  {
     "name":"Manouba Governorate",
     "isoCode":"14",
     "countryCode":"TN",
     "latitude":"36.84465040",
     "longitude":"9.85714160"
  },
  {
     "name":"Gafsa Governorate",
     "isoCode":"71",
     "countryCode":"TN",
     "latitude":"34.37885050",
     "longitude":"8.66005860"
  },
  {
     "name":"Sfax Governorate",
     "isoCode":"61",
     "countryCode":"TN",
     "latitude":"34.86065810",
     "longitude":"10.34978950"
  },
  {
     "name":"Gabès Governorate",
     "isoCode":"81",
     "countryCode":"TN",
     "latitude":"33.94596480",
     "longitude":"9.72326730"
  },
  {
     "name":"Tataouine Governorate",
     "isoCode":"83",
     "countryCode":"TN",
     "latitude":"32.13441220",
     "longitude":"10.08072980"
  },
  {
     "name":"Medenine Governorate",
     "isoCode":"82",
     "countryCode":"TN",
     "latitude":"33.22805650",
     "longitude":"10.89030990"
  },
  {
     "name":"Kef Governorate",
     "isoCode":"33",
     "countryCode":"TN",
     "latitude":"36.12305120",
     "longitude":"8.66005860"
  },
  {
     "name":"Kebili Governorate",
     "isoCode":"73",
     "countryCode":"TN",
     "latitude":"33.70715510",
     "longitude":"8.97146230"
  },
  {
     "name":"Siliana Governorate",
     "isoCode":"34",
     "countryCode":"TN",
     "latitude":"36.08872080",
     "longitude":"9.36453350"
  },
  {
     "name":"Kairouan Governorate",
     "isoCode":"41",
     "countryCode":"TN",
     "latitude":"35.67116630",
     "longitude":"10.10054690"
  },
  {
     "name":"Zaghouan Governorate",
     "isoCode":"22",
     "countryCode":"TN",
     "latitude":"36.40911880",
     "longitude":"10.14231720"
  },
  {
     "name":"Ben Arous Governorate",
     "isoCode":"13",
     "countryCode":"TN",
     "latitude":"36.64356060",
     "longitude":"10.21515780"
  },
  {
     "name":"Sidi Bouzid Governorate",
     "isoCode":"43",
     "countryCode":"TN",
     "latitude":"35.03543860",
     "longitude":"9.48393920"
  },
  {
     "name":"Mahdia Governorate",
     "isoCode":"53",
     "countryCode":"TN",
     "latitude":"35.33525580",
     "longitude":"10.89030990"
  },
  {
     "name":"Tozeur Governorate",
     "isoCode":"72",
     "countryCode":"TN",
     "latitude":"33.97894910",
     "longitude":"8.04651850"
  },
  {
     "name":"Kasserine Governorate",
     "isoCode":"42",
     "countryCode":"TN",
     "latitude":"35.08091480",
     "longitude":"8.66005860"
  },
  {
     "name":"Sousse Governorate",
     "isoCode":"51",
     "countryCode":"TN",
     "latitude":"35.90222670",
     "longitude":"10.34978950"
  },
  {
     "name":"Kassrine",
     "isoCode":"31",
     "countryCode":"TN",
     "latitude":"35.17227160",
     "longitude":"8.83076260"
  },
  {
     "name":"Ratak Chain",
     "isoCode":"T",
     "countryCode":"MH",
     "latitude":"10.27632760",
     "longitude":"170.55009370"
  },
  {
     "name":"Ralik Chain",
     "isoCode":"L",
     "countryCode":"MH",
     "latitude":"8.13614600",
     "longitude":"164.88679560"
  },
  {
     "name":"Centrale Region",
     "isoCode":"C",
     "countryCode":"TG",
     "latitude":"8.65860290",
     "longitude":"1.05861350"
  },
  {
     "name":"Maritime",
     "isoCode":"M",
     "countryCode":"TG",
     "latitude":"41.65514930",
     "longitude":"-83.52784670"
  },
  {
     "name":"Plateaux Region",
     "isoCode":"P",
     "countryCode":"TG",
     "latitude":"7.61013780",
     "longitude":"1.05861350"
  },
  {
     "name":"Savanes Region",
     "isoCode":"S",
     "countryCode":"TG",
     "latitude":"10.52917810",
     "longitude":"0.52578230"
  },
  {
     "name":"Kara Region",
     "isoCode":"K",
     "countryCode":"TG",
     "latitude":"9.72163930",
     "longitude":"1.05861350"
  },
  {
     "name":"Chuuk State",
     "isoCode":"TRK",
     "countryCode":"FM",
     "latitude":"7.13867590",
     "longitude":"151.55930650"
  },
  {
     "name":"Pohnpei State",
     "isoCode":"PNI",
     "countryCode":"FM",
     "latitude":"6.85412540",
     "longitude":"158.26238220"
  },
  {
     "name":"Yap State",
     "isoCode":"YAP",
     "countryCode":"FM",
     "latitude":"8.67164900",
     "longitude":"142.84393350"
  },
  {
     "name":"Kosrae State",
     "isoCode":"KSA",
     "countryCode":"FM",
     "latitude":"5.30956180",
     "longitude":"162.98148770"
  },
  {
     "name":"Vaavu Atoll",
     "isoCode":"04",
     "countryCode":"MV",
     "latitude":"3.39554380",
     "longitude":"73.51229280"
  },
  {
     "name":"Shaviyani Atoll",
     "isoCode":"24",
     "countryCode":"MV",
     "latitude":"6.17511000",
     "longitude":"73.13496050"
  },
  {
     "name":"Haa Alif Atoll",
     "isoCode":"07",
     "countryCode":"MV",
     "latitude":"6.99034880",
     "longitude":"72.94605660"
  },
  {
     "name":"Alif Alif Atoll",
     "isoCode":"02",
     "countryCode":"MV",
     "latitude":"4.08500000",
     "longitude":"72.85154790"
  },
  {
     "name":"North Province",
     "isoCode":"NO",
     "countryCode":"MV",
     "latitude":"8.88550270",
     "longitude":"80.27673270"
  },
  {
     "name":"North Central Province",
     "isoCode":"NC",
     "countryCode":"MV",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Dhaalu Atoll",
     "isoCode":"17",
     "countryCode":"MV",
     "latitude":"2.84685020",
     "longitude":"72.94605660"
  },
  {
     "name":"Thaa Atoll",
     "isoCode":"08",
     "countryCode":"MV",
     "latitude":"2.43111610",
     "longitude":"73.18216230"
  },
  {
     "name":"Noonu Atoll",
     "isoCode":"25",
     "countryCode":"MV",
     "latitude":"5.85512760",
     "longitude":"73.32370800"
  },
  {
     "name":"Upper South Province",
     "isoCode":"US",
     "countryCode":"MV",
     "latitude":"0.23070000",
     "longitude":"73.27948460"
  },
  {
     "name":"Addu Atoll",
     "isoCode":"01",
     "countryCode":"MV",
     "latitude":"-0.63009950",
     "longitude":"73.15856260"
  },
  {
     "name":"Gnaviyani Atoll",
     "isoCode":"29",
     "countryCode":"MV",
     "latitude":"-0.30064250",
     "longitude":"73.42391430"
  },
  {
     "name":"Kaafu Atoll",
     "isoCode":"26",
     "countryCode":"MV",
     "latitude":"4.45589790",
     "longitude":"73.55941280"
  },
  {
     "name":"Haa Dhaalu Atoll",
     "isoCode":"23",
     "countryCode":"MV",
     "latitude":"6.57827170",
     "longitude":"72.94605660"
  },
  {
     "name":"Gaafu Alif Atoll",
     "isoCode":"27",
     "countryCode":"MV",
     "latitude":"0.61248130",
     "longitude":"73.32370800"
  },
  {
     "name":"Faafu Atoll",
     "isoCode":"14",
     "countryCode":"MV",
     "latitude":"3.23094090",
     "longitude":"72.94605660"
  },
  {
     "name":"Alif Dhaal Atoll",
     "isoCode":"00",
     "countryCode":"MV",
     "latitude":"3.65433020",
     "longitude":"72.80427970"
  },
  {
     "name":"Laamu Atoll",
     "isoCode":"05",
     "countryCode":"MV",
     "latitude":"1.94307370",
     "longitude":"73.41802110"
  },
  {
     "name":"Raa Atoll",
     "isoCode":"13",
     "countryCode":"MV",
     "latitude":"5.60064570",
     "longitude":"72.94605660"
  },
  {
     "name":"Gaafu Dhaalu Atoll",
     "isoCode":"28",
     "countryCode":"MV",
     "latitude":"0.35880400",
     "longitude":"73.18216230"
  },
  {
     "name":"Central Province",
     "isoCode":"CE",
     "countryCode":"MV",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"South Province",
     "isoCode":"SU",
     "countryCode":"MV",
     "latitude":"-21.74820060",
     "longitude":"166.17837390"
  },
  {
     "name":"South Central Province",
     "isoCode":"SC",
     "countryCode":"MV",
     "latitude":"7.25649960",
     "longitude":"80.72144170"
  },
  {
     "name":"Lhaviyani Atoll",
     "isoCode":"03",
     "countryCode":"MV",
     "latitude":"5.37470210",
     "longitude":"73.51229280"
  },
  {
     "name":"Meemu Atoll",
     "isoCode":"12",
     "countryCode":"MV",
     "latitude":"3.00903450",
     "longitude":"73.51229280"
  },
  {
     "name":"Malé",
     "isoCode":"MLE",
     "countryCode":"MV",
     "latitude":"46.34888670",
     "longitude":"10.90724890"
  },
  {
     "name":"Utrecht",
     "isoCode":"UT",
     "countryCode":"NL",
     "latitude":"52.09073740",
     "longitude":"5.12142010"
  },
  {
     "name":"Gelderland",
     "isoCode":"GE",
     "countryCode":"NL",
     "latitude":"52.04515500",
     "longitude":"5.87182350"
  },
  {
     "name":"North Holland",
     "isoCode":"NH",
     "countryCode":"NL",
     "latitude":"52.52058690",
     "longitude":"4.78847400"
  },
  {
     "name":"Drenthe",
     "isoCode":"DR",
     "countryCode":"NL",
     "latitude":"52.94760120",
     "longitude":"6.62305860"
  },
  {
     "name":"South Holland",
     "isoCode":"ZH",
     "countryCode":"NL",
     "latitude":"41.60086810",
     "longitude":"-87.60698940"
  },
  {
     "name":"Limburg",
     "isoCode":"LI",
     "countryCode":"NL",
     "latitude":"51.44272380",
     "longitude":"6.06087260"
  },
  {
     "name":"Sint Eustatius",
     "isoCode":"BQ3",
     "countryCode":"NL",
     "latitude":"17.48903060",
     "longitude":"-62.97355500"
  },
  {
     "name":"Groningen",
     "isoCode":"GR",
     "countryCode":"NL",
     "latitude":"53.21938350",
     "longitude":"6.56650170"
  },
  {
     "name":"Overijssel",
     "isoCode":"OV",
     "countryCode":"NL",
     "latitude":"52.43878140",
     "longitude":"6.50164110"
  },
  {
     "name":"Flevoland",
     "isoCode":"FL",
     "countryCode":"NL",
     "latitude":"52.52797810",
     "longitude":"5.59535080"
  },
  {
     "name":"Zeeland",
     "isoCode":"ZE",
     "countryCode":"NL",
     "latitude":"51.49403090",
     "longitude":"3.84968150"
  },
  {
     "name":"Saba",
     "isoCode":"BQ2",
     "countryCode":"NL",
     "latitude":"17.63546420",
     "longitude":"-63.23267630"
  },
  {
     "name":"Friesland",
     "isoCode":"FR",
     "countryCode":"NL",
     "latitude":"53.16416420",
     "longitude":"5.78175420"
  },
  {
     "name":"North Brabant",
     "isoCode":"NB",
     "countryCode":"NL",
     "latitude":"51.48265370",
     "longitude":"5.23216870"
  },
  {
     "name":"Bonaire",
     "isoCode":"BQ1",
     "countryCode":"NL",
     "latitude":"12.20189020",
     "longitude":"-68.26238220"
  },
  {
     "name":"Savanes Region",
     "isoCode":"03",
     "countryCode":"CI",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Agnéby",
     "isoCode":"16",
     "countryCode":"CI",
     "latitude":"5.32245030",
     "longitude":"-4.34495290"
  },
  {
     "name":"Lagunes District",
     "isoCode":"LG",
     "countryCode":"CI",
     "latitude":"5.88273340",
     "longitude":"-4.23333550"
  },
  {
     "name":"Sud-Bandama",
     "isoCode":"15",
     "countryCode":"CI",
     "latitude":"5.53570830",
     "longitude":"-5.56172790"
  },
  {
     "name":"Montagnes District",
     "isoCode":"MG",
     "countryCode":"CI",
     "latitude":"7.37623730",
     "longitude":"-7.43813550"
  },
  {
     "name":"Moyen-Comoé",
     "isoCode":"05",
     "countryCode":"CI",
     "latitude":"6.65149170",
     "longitude":"-3.50034540"
  },
  {
     "name":"Marahoué Region",
     "isoCode":"12",
     "countryCode":"CI",
     "latitude":"6.88462070",
     "longitude":"-5.89871390"
  },
  {
     "name":"Lacs District",
     "isoCode":"LC",
     "countryCode":"CI",
     "latitude":"48.19801690",
     "longitude":"-80.45644120"
  },
  {
     "name":"Fromager",
     "isoCode":"18",
     "countryCode":"CI",
     "latitude":"45.54502130",
     "longitude":"-73.60462230"
  },
  {
     "name":"Abidjan",
     "isoCode":"AB",
     "countryCode":"CI",
     "latitude":"5.35995170",
     "longitude":"-4.00825630"
  },
  {
     "name":"Bas-Sassandra Region",
     "isoCode":"09",
     "countryCode":"CI",
     "latitude":"5.35679160",
     "longitude":"-6.74939930"
  },
  {
     "name":"Bafing Region",
     "isoCode":"17",
     "countryCode":"CI",
     "latitude":"8.32520470",
     "longitude":"-7.52472430"
  },
  {
     "name":"Vallée du Bandama District",
     "isoCode":"VB",
     "countryCode":"CI",
     "latitude":"8.27897800",
     "longitude":"-4.89356270"
  },
  {
     "name":"Haut-Sassandra",
     "isoCode":"02",
     "countryCode":"CI",
     "latitude":"6.87578480",
     "longitude":"-6.57833870"
  },
  {
     "name":"Lagunes region",
     "isoCode":"01",
     "countryCode":"CI",
     "latitude":"5.88273340",
     "longitude":"-4.23333550"
  },
  {
     "name":"Lacs Region",
     "isoCode":"07",
     "countryCode":"CI",
     "latitude":"47.73958660",
     "longitude":"-70.41866520"
  },
  {
     "name":"Zanzan Region",
     "isoCode":"ZZ",
     "countryCode":"CI",
     "latitude":"8.82079040",
     "longitude":"-3.41955270"
  },
  {
     "name":"Denguélé Region",
     "isoCode":"10",
     "countryCode":"CI",
     "latitude":"9.46623720",
     "longitude":"-7.43813550"
  },
  {
     "name":"Bas-Sassandra District",
     "isoCode":"BS",
     "countryCode":"CI",
     "latitude":"5.27983560",
     "longitude":"-6.15269850"
  },
  {
     "name":"Denguélé District",
     "isoCode":"DN",
     "countryCode":"CI",
     "latitude":"48.07077630",
     "longitude":"-68.56093410"
  },
  {
     "name":"Dix-Huit Montagnes",
     "isoCode":"06",
     "countryCode":"CI",
     "latitude":"7.37623730",
     "longitude":"-7.43813550"
  },
  {
     "name":"Moyen-Cavally",
     "isoCode":"19",
     "countryCode":"CI",
     "latitude":"6.52087930",
     "longitude":"-7.61142170"
  },
  {
     "name":"Vallée du Bandama Region",
     "isoCode":"04",
     "countryCode":"CI",
     "latitude":"8.27897800",
     "longitude":"-4.89356270"
  },
  {
     "name":"Sassandra-Marahoué District",
     "isoCode":"SM",
     "countryCode":"CI",
     "latitude":"6.88033480",
     "longitude":"-6.23759470"
  },
  {
     "name":"Worodougou",
     "isoCode":"14",
     "countryCode":"CI",
     "latitude":"8.25489620",
     "longitude":"-6.57833870"
  },
  {
     "name":"Woroba District",
     "isoCode":"WR",
     "countryCode":"CI",
     "latitude":"8.24913720",
     "longitude":"-6.92091350"
  },
  {
     "name":"Gôh-Djiboua District",
     "isoCode":"GD",
     "countryCode":"CI",
     "latitude":"5.87113930",
     "longitude":"-5.56172790"
  },
  {
     "name":"Sud-Comoé",
     "isoCode":"13",
     "countryCode":"CI",
     "latitude":"5.55279300",
     "longitude":"-3.25836260"
  },
  {
     "name":"Yamoussoukro",
     "isoCode":"YM",
     "countryCode":"CI",
     "latitude":"6.82762280",
     "longitude":"-5.28934330"
  },
  {
     "name":"Comoé District",
     "isoCode":"CM",
     "countryCode":"CI",
     "latitude":"5.55279300",
     "longitude":"-3.25836260"
  },
  {
     "name":"N'zi-Comoé",
     "isoCode":"11",
     "countryCode":"CI",
     "latitude":"7.24567490",
     "longitude":"-4.23333550"
  },
  {
     "name":"Far North",
     "isoCode":"EN",
     "countryCode":"CM",
     "latitude":"66.76134510",
     "longitude":"124.12375300"
  },
  {
     "name":"Northwest",
     "isoCode":"NW",
     "countryCode":"CM",
     "latitude":"36.37118570",
     "longitude":"-94.19346060"
  },
  {
     "name":"Southwest",
     "isoCode":"SW",
     "countryCode":"CM",
     "latitude":"36.19088130",
     "longitude":"-95.88974480"
  },
  {
     "name":"South",
     "isoCode":"SU",
     "countryCode":"CM",
     "latitude":"37.63159500",
     "longitude":"-97.34584090"
  },
  {
     "name":"Centre",
     "isoCode":"CE",
     "countryCode":"CM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"East",
     "isoCode":"ES",
     "countryCode":"CM",
     "latitude":"39.01853360",
     "longitude":"-94.27924110"
  },
  {
     "name":"Littoral",
     "isoCode":"LT",
     "countryCode":"CM",
     "latitude":"48.46227570",
     "longitude":"-68.51780710"
  },
  {
     "name":"Adamawa",
     "isoCode":"AD",
     "countryCode":"CM",
     "latitude":"9.32647510",
     "longitude":"12.39838530"
  },
  {
     "name":"West",
     "isoCode":"OU",
     "countryCode":"CM",
     "latitude":"37.03649890",
     "longitude":"-95.67059870"
  },
  {
     "name":"North",
     "isoCode":"NO",
     "countryCode":"CM",
     "latitude":"37.09024000",
     "longitude":"-95.71289100"
  },
  {
     "name":"Banjul",
     "isoCode":"B",
     "countryCode":"GM",
     "latitude":"13.45487610",
     "longitude":"-16.57903230"
  },
  {
     "name":"West Coast Division",
     "isoCode":"W",
     "countryCode":"GM",
     "latitude":"5.97727980",
     "longitude":"116.07542880"
  },
  {
     "name":"Upper River Division",
     "isoCode":"U",
     "countryCode":"GM",
     "latitude":"13.42573660",
     "longitude":"-14.00723480"
  },
  {
     "name":"Central River Division",
     "isoCode":"M",
     "countryCode":"GM",
     "latitude":"13.59944690",
     "longitude":"-14.89216680"
  },
  {
     "name":"Lower River Division",
     "isoCode":"L",
     "countryCode":"GM",
     "latitude":"13.35533060",
     "longitude":"-15.92299000"
  },
  {
     "name":"North Bank Division",
     "isoCode":"N",
     "countryCode":"GM",
     "latitude":"13.52854360",
     "longitude":"-16.01699710"
  },
  {
     "name":"Beyla Prefecture",
     "isoCode":"BE",
     "countryCode":"GN",
     "latitude":"8.91981780",
     "longitude":"-8.30884410"
  },
  {
     "name":"Mandiana Prefecture",
     "isoCode":"MD",
     "countryCode":"GN",
     "latitude":"10.61728270",
     "longitude":"-8.69857160"
  },
  {
     "name":"Yomou Prefecture",
     "isoCode":"YO",
     "countryCode":"GN",
     "latitude":"7.56962790",
     "longitude":"-9.25915710"
  },
  {
     "name":"Fria Prefecture",
     "isoCode":"FR",
     "countryCode":"GN",
     "latitude":"10.36745430",
     "longitude":"-13.58418710"
  },
  {
     "name":"Boké Region",
     "isoCode":"B",
     "countryCode":"GN",
     "latitude":"11.18646720",
     "longitude":"-14.10013260"
  },
  {
     "name":"Labé Region",
     "isoCode":"L",
     "countryCode":"GN",
     "latitude":"11.32320420",
     "longitude":"-12.28913140"
  },
  {
     "name":"Nzérékoré Prefecture",
     "isoCode":"NZ",
     "countryCode":"GN",
     "latitude":"7.74783590",
     "longitude":"-8.82525020"
  },
  {
     "name":"Dabola Prefecture",
     "isoCode":"DB",
     "countryCode":"GN",
     "latitude":"10.72978060",
     "longitude":"-11.11078540"
  },
  {
     "name":"Labé Prefecture",
     "isoCode":"LA",
     "countryCode":"GN",
     "latitude":"11.35419390",
     "longitude":"-12.34638750"
  },
  {
     "name":"Dubréka Prefecture",
     "isoCode":"DU",
     "countryCode":"GN",
     "latitude":"9.79073480",
     "longitude":"-13.51477350"
  },
  {
     "name":"Faranah Prefecture",
     "isoCode":"FA",
     "countryCode":"GN",
     "latitude":"9.90573990",
     "longitude":"-10.80000510"
  },
  {
     "name":"Forécariah Prefecture",
     "isoCode":"FO",
     "countryCode":"GN",
     "latitude":"9.38861870",
     "longitude":"-13.08179030"
  },
  {
     "name":"Nzérékoré Region",
     "isoCode":"N",
     "countryCode":"GN",
     "latitude":"8.03858700",
     "longitude":"-8.83627550"
  },
  {
     "name":"Gaoual Prefecture",
     "isoCode":"GA",
     "countryCode":"GN",
     "latitude":"11.57628040",
     "longitude":"-13.35872880"
  },
  {
     "name":"Conakry",
     "isoCode":"C",
     "countryCode":"GN",
     "latitude":"9.64118550",
     "longitude":"-13.57840120"
  },
  {
     "name":"Télimélé Prefecture",
     "isoCode":"TE",
     "countryCode":"GN",
     "latitude":"10.90893640",
     "longitude":"-13.02993310"
  },
  {
     "name":"Dinguiraye Prefecture",
     "isoCode":"DI",
     "countryCode":"GN",
     "latitude":"11.68442220",
     "longitude":"-10.80000510"
  },
  {
     "name":"Mamou Prefecture",
     "isoCode":"MM",
     "countryCode":"GN",
     "latitude":"10.57360240",
     "longitude":"-11.88917210"
  },
  {
     "name":"Lélouma Prefecture",
     "isoCode":"LE",
     "countryCode":"GN",
     "latitude":"11.18333300",
     "longitude":"-12.93333300"
  },
  {
     "name":"Kissidougou Prefecture",
     "isoCode":"KS",
     "countryCode":"GN",
     "latitude":"9.22520220",
     "longitude":"-10.08072980"
  },
  {
     "name":"Koubia Prefecture",
     "isoCode":"KB",
     "countryCode":"GN",
     "latitude":"11.58235400",
     "longitude":"-11.89202370"
  },
  {
     "name":"Kindia Prefecture",
     "isoCode":"KD",
     "countryCode":"GN",
     "latitude":"10.10132920",
     "longitude":"-12.71351210"
  },
  {
     "name":"Pita Prefecture",
     "isoCode":"PI",
     "countryCode":"GN",
     "latitude":"10.80620860",
     "longitude":"-12.71351210"
  },
  {
     "name":"Kouroussa Prefecture",
     "isoCode":"KO",
     "countryCode":"GN",
     "latitude":"10.64892290",
     "longitude":"-9.88505860"
  },
  {
     "name":"Tougué Prefecture",
     "isoCode":"TO",
     "countryCode":"GN",
     "latitude":"11.38415830",
     "longitude":"-11.61577730"
  },
  {
     "name":"Kankan Region",
     "isoCode":"K",
     "countryCode":"GN",
     "latitude":"10.12092300",
     "longitude":"-9.54509740"
  },
  {
     "name":"Mamou Region",
     "isoCode":"M",
     "countryCode":"GN",
     "latitude":"10.57360240",
     "longitude":"-11.88917210"
  },
  {
     "name":"Boffa Prefecture",
     "isoCode":"BF",
     "countryCode":"GN",
     "latitude":"10.18082540",
     "longitude":"-14.03916150"
  },
  {
     "name":"Mali Prefecture",
     "isoCode":"ML",
     "countryCode":"GN",
     "latitude":"11.98370900",
     "longitude":"-12.25479190"
  },
  {
     "name":"Kindia Region",
     "isoCode":"D",
     "countryCode":"GN",
     "latitude":"10.17816940",
     "longitude":"-12.98961500"
  },
  {
     "name":"Macenta Prefecture",
     "isoCode":"MC",
     "countryCode":"GN",
     "latitude":"8.46157950",
     "longitude":"-9.27855830"
  },
  {
     "name":"Koundara Prefecture",
     "isoCode":"KN",
     "countryCode":"GN",
     "latitude":"12.48940210",
     "longitude":"-13.30675620"
  },
  {
     "name":"Kankan Prefecture",
     "isoCode":"KA",
     "countryCode":"GN",
     "latitude":"10.30344650",
     "longitude":"-9.36730840"
  },
  {
     "name":"Coyah Prefecture",
     "isoCode":"CO",
     "countryCode":"GN",
     "latitude":"9.77155350",
     "longitude":"-13.31252990"
  },
  {
     "name":"Dalaba Prefecture",
     "isoCode":"DL",
     "countryCode":"GN",
     "latitude":"10.68681760",
     "longitude":"-12.24906970"
  },
  {
     "name":"Siguiri Prefecture",
     "isoCode":"SI",
     "countryCode":"GN",
     "latitude":"11.41481130",
     "longitude":"-9.17883040"
  },
  {
     "name":"Lola Prefecture",
     "isoCode":"LO",
     "countryCode":"GN",
     "latitude":"7.96138180",
     "longitude":"-8.39649380"
  },
  {
     "name":"Boké Prefecture",
     "isoCode":"BK",
     "countryCode":"GN",
     "latitude":"11.08473790",
     "longitude":"-14.37919120"
  },
  {
     "name":"Kérouané Prefecture",
     "isoCode":"KE",
     "countryCode":"GN",
     "latitude":"9.25366430",
     "longitude":"-9.01289260"
  },
  {
     "name":"Guéckédou Prefecture",
     "isoCode":"GU",
     "countryCode":"GN",
     "latitude":"8.56496880",
     "longitude":"-10.13111630"
  },
  {
     "name":"Tombali Region",
     "isoCode":"TO",
     "countryCode":"GW",
     "latitude":"11.36326960",
     "longitude":"-14.98561760"
  },
  {
     "name":"Cacheu Region",
     "isoCode":"CA",
     "countryCode":"GW",
     "latitude":"12.05514160",
     "longitude":"-16.06401790"
  },
  {
     "name":"Biombo Region",
     "isoCode":"BM",
     "countryCode":"GW",
     "latitude":"11.85290610",
     "longitude":"-15.73511710"
  },
  {
     "name":"Quinara Region",
     "isoCode":"QU",
     "countryCode":"GW",
     "latitude":"11.79556200",
     "longitude":"-15.17268160"
  },
  {
     "name":"Sul Province",
     "isoCode":"S",
     "countryCode":"GW",
     "latitude":"-10.28665780",
     "longitude":"20.71224650"
  },
  {
     "name":"Norte Province",
     "isoCode":"N",
     "countryCode":"GW",
     "latitude":"7.87218110",
     "longitude":"123.88577470"
  },
  {
     "name":"Oio Region",
     "isoCode":"OI",
     "countryCode":"GW",
     "latitude":"12.27607090",
     "longitude":"-15.31311850"
  },
  {
     "name":"Gabú Region",
     "isoCode":"GA",
     "countryCode":"GW",
     "latitude":"11.89624880",
     "longitude":"-14.10013260"
  },
  {
     "name":"Bafatá",
     "isoCode":"BA",
     "countryCode":"GW",
     "latitude":"12.17352430",
     "longitude":"-14.65295200"
  },
  {
     "name":"Leste Province",
     "isoCode":"L",
     "countryCode":"GW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Bolama Region",
     "isoCode":"BL",
     "countryCode":"GW",
     "latitude":"11.14805910",
     "longitude":"-16.13457050"
  },
  {
     "name":"Woleu-Ntem Province",
     "isoCode":"9",
     "countryCode":"GA",
     "latitude":"2.29898270",
     "longitude":"11.44669140"
  },
  {
     "name":"Ogooué-Ivindo Province",
     "isoCode":"6",
     "countryCode":"GA",
     "latitude":"0.88183110",
     "longitude":"13.17403480"
  },
  {
     "name":"Nyanga Province",
     "isoCode":"5",
     "countryCode":"GA",
     "latitude":"-2.88210330",
     "longitude":"11.16173560"
  },
  {
     "name":"Haut-Ogooué Province",
     "isoCode":"2",
     "countryCode":"GA",
     "latitude":"-1.47625440",
     "longitude":"13.91439900"
  },
  {
     "name":"Estuaire Province",
     "isoCode":"1",
     "countryCode":"GA",
     "latitude":"0.44328640",
     "longitude":"10.08072980"
  },
  {
     "name":"Ogooué-Maritime Province",
     "isoCode":"8",
     "countryCode":"GA",
     "latitude":"-1.34659750",
     "longitude":"9.72326730"
  },
  {
     "name":"Ogooué-Lolo Province",
     "isoCode":"7",
     "countryCode":"GA",
     "latitude":"-0.88440930",
     "longitude":"12.43805810"
  },
  {
     "name":"Moyen-Ogooué Province",
     "isoCode":"3",
     "countryCode":"GA",
     "latitude":"-0.44278400",
     "longitude":"10.43965600"
  },
  {
     "name":"Ngounié Province",
     "isoCode":"4",
     "countryCode":"GA",
     "latitude":"-1.49303030",
     "longitude":"10.98070030"
  },
  {
     "name":"Tshuapa District",
     "isoCode":"TU",
     "countryCode":"CD",
     "latitude":"-0.99030230",
     "longitude":"23.02888440"
  },
  {
     "name":"Tanganyika Province",
     "isoCode":"TA",
     "countryCode":"CD",
     "latitude":"-6.27401180",
     "longitude":"27.92490020"
  },
  {
     "name":"Haut-Uele",
     "isoCode":"HU",
     "countryCode":"CD",
     "latitude":"3.58451540",
     "longitude":"28.29943500"
  },
  {
     "name":"Kasaï-Oriental",
     "isoCode":"KE",
     "countryCode":"CD",
     "latitude":"-6.03362300",
     "longitude":"23.57285010"
  },
  {
     "name":"Orientale Province",
     "isoCode":"OR",
     "countryCode":"CD",
     "latitude":"1.64062960",
     "longitude":"26.41938900"
  },
  {
     "name":"Kasaï-Occidental",
     "isoCode":"KW",
     "countryCode":"CD",
     "latitude":"-5.32054730",
     "longitude":"21.85685860"
  },
  {
     "name":"South Kivu",
     "isoCode":"SK",
     "countryCode":"CD",
     "latitude":"-3.01165800",
     "longitude":"28.29943500"
  },
  {
     "name":"Nord-Ubangi District",
     "isoCode":"NU",
     "countryCode":"CD",
     "latitude":"3.78787260",
     "longitude":"21.47528510"
  },
  {
     "name":"Kwango District",
     "isoCode":"KG",
     "countryCode":"CD",
     "latitude":"-6.43374090",
     "longitude":"17.66888700"
  },
  {
     "name":"Kinshasa",
     "isoCode":"KN",
     "countryCode":"CD",
     "latitude":"-4.44193110",
     "longitude":"15.26629310"
  },
  {
     "name":"Katanga Province",
     "isoCode":"KA",
     "countryCode":"CD",
     "latitude":"-8.88511450",
     "longitude":"26.41938900"
  },
  {
     "name":"Sankuru District",
     "isoCode":"SA",
     "countryCode":"CD",
     "latitude":"-2.84374530",
     "longitude":"23.38235450"
  },
  {
     "name":"Équateur",
     "isoCode":"EQ",
     "countryCode":"CD",
     "latitude":"-1.83123900",
     "longitude":"-78.18340600"
  },
  {
     "name":"Maniema",
     "isoCode":"MA",
     "countryCode":"CD",
     "latitude":"-3.07309290",
     "longitude":"26.04138890"
  },
  {
     "name":"Bas-Congo province",
     "isoCode":"BC",
     "countryCode":"CD",
     "latitude":"-5.23656850",
     "longitude":"13.91439900"
  },
  {
     "name":"Lomami Province",
     "isoCode":"LO",
     "countryCode":"CD",
     "latitude":"-6.14539310",
     "longitude":"24.52426400"
  },
  {
     "name":"Sud-Ubangi",
     "isoCode":"SU",
     "countryCode":"CD",
     "latitude":"3.22999420",
     "longitude":"19.18800470"
  },
  {
     "name":"North Kivu",
     "isoCode":"NK",
     "countryCode":"CD",
     "latitude":"-0.79177290",
     "longitude":"29.04599270"
  },
  {
     "name":"Haut-Katanga Province",
     "isoCode":"HK",
     "countryCode":"CD",
     "latitude":"-10.41020750",
     "longitude":"27.54958460"
  },
  {
     "name":"Ituri Interim Administration",
     "isoCode":"IT",
     "countryCode":"CD",
     "latitude":"1.59576820",
     "longitude":"29.41793240"
  },
  {
     "name":"Mongala District",
     "isoCode":"MO",
     "countryCode":"CD",
     "latitude":"1.99623240",
     "longitude":"21.47528510"
  },
  {
     "name":"Bas-Uele",
     "isoCode":"BU",
     "countryCode":"CD",
     "latitude":"3.99010090",
     "longitude":"24.90422080"
  },
  {
     "name":"Bandundu Province",
     "isoCode":"BN",
     "countryCode":"CD",
     "latitude":"-4.16367900",
     "longitude":"18.80761950"
  },
  {
     "name":"Mai-Ndombe Province",
     "isoCode":"MN",
     "countryCode":"CD",
     "latitude":"-2.63574340",
     "longitude":"18.42760470"
  },
  {
     "name":"Tshopo District",
     "isoCode":"TO",
     "countryCode":"CD",
     "latitude":"0.54554620",
     "longitude":"24.90422080"
  },
  {
     "name":"Kasaï District",
     "isoCode":"KS",
     "countryCode":"CD",
     "latitude":"-5.04719790",
     "longitude":"20.71224650"
  },
  {
     "name":"Haut-Lomami District",
     "isoCode":"HL",
     "countryCode":"CD",
     "latitude":"-7.70527520",
     "longitude":"24.90422080"
  },
  {
     "name":"Kwilu District",
     "isoCode":"KL",
     "countryCode":"CD",
     "latitude":"-5.11888250",
     "longitude":"18.42760470"
  },
  {
     "name":"Cuyuni-Mazaruni",
     "isoCode":"CU",
     "countryCode":"GY",
     "latitude":"6.46421410",
     "longitude":"-60.21107520"
  },
  {
     "name":"Potaro-Siparuni",
     "isoCode":"PT",
     "countryCode":"GY",
     "latitude":"4.78558530",
     "longitude":"-59.28799770"
  },
  {
     "name":"Mahaica-Berbice",
     "isoCode":"MA",
     "countryCode":"GY",
     "latitude":"6.23849600",
     "longitude":"-57.91625550"
  },
  {
     "name":"Upper Demerara-Berbice",
     "isoCode":"UD",
     "countryCode":"GY",
     "latitude":"5.30648790",
     "longitude":"-58.18929210"
  },
  {
     "name":"Barima-Waini",
     "isoCode":"BA",
     "countryCode":"GY",
     "latitude":"7.48824190",
     "longitude":"-59.65644940"
  },
  {
     "name":"Pomeroon-Supenaam",
     "isoCode":"PM",
     "countryCode":"GY",
     "latitude":"7.12941660",
     "longitude":"-58.92062950"
  },
  {
     "name":"East Berbice-Corentyne",
     "isoCode":"EB",
     "countryCode":"GY",
     "latitude":"2.74779220",
     "longitude":"-57.46272590"
  },
  {
     "name":"Demerara-Mahaica",
     "isoCode":"DE",
     "countryCode":"GY",
     "latitude":"6.54642600",
     "longitude":"-58.09820460"
  },
  {
     "name":"Essequibo Islands-West Demerara",
     "isoCode":"ES",
     "countryCode":"GY",
     "latitude":"6.57201320",
     "longitude":"-58.46299970"
  },
  {
     "name":"Upper Takutu-Upper Essequibo",
     "isoCode":"UT",
     "countryCode":"GY",
     "latitude":"2.92395950",
     "longitude":"-58.73736340"
  },
  {
     "name":"Presidente Hayes Department",
     "isoCode":"15",
     "countryCode":"PY",
     "latitude":"-23.35126050",
     "longitude":"-58.73736340"
  },
  {
     "name":"Canindeyú",
     "isoCode":"14",
     "countryCode":"PY",
     "latitude":"-24.13787350",
     "longitude":"-55.66896360"
  },
  {
     "name":"Guairá Department",
     "isoCode":"4",
     "countryCode":"PY",
     "latitude":"-25.88109320",
     "longitude":"-56.29293810"
  },
  {
     "name":"Caaguazú",
     "isoCode":"5",
     "countryCode":"PY",
     "latitude":"-25.46458180",
     "longitude":"-56.01385100"
  },
  {
     "name":"Paraguarí Department",
     "isoCode":"9",
     "countryCode":"PY",
     "latitude":"-25.62621740",
     "longitude":"-57.15206420"
  },
  {
     "name":"Caazapá",
     "isoCode":"6",
     "countryCode":"PY",
     "latitude":"-26.18277130",
     "longitude":"-56.37123270"
  },
  {
     "name":"San Pedro Department",
     "isoCode":"2",
     "countryCode":"PY",
     "latitude":"-24.19486680",
     "longitude":"-56.56164700"
  },
  {
     "name":"Central Department",
     "isoCode":"11",
     "countryCode":"PY",
     "latitude":"36.15592290",
     "longitude":"-95.96620750"
  },
  {
     "name":"Itapúa",
     "isoCode":"7",
     "countryCode":"PY",
     "latitude":"-26.79236230",
     "longitude":"-55.66896360"
  },
  {
     "name":"Concepción Department",
     "isoCode":"1",
     "countryCode":"PY",
     "latitude":"-23.42142640",
     "longitude":"-57.43444510"
  },
  {
     "name":"Boquerón Department",
     "isoCode":"19",
     "countryCode":"PY",
     "latitude":"-21.74492540",
     "longitude":"-60.95400730"
  },
  {
     "name":"Ñeembucú Department",
     "isoCode":"12",
     "countryCode":"PY",
     "latitude":"-27.02991140",
     "longitude":"-57.82539500"
  },
  {
     "name":"Amambay Department",
     "isoCode":"13",
     "countryCode":"PY",
     "latitude":"-22.55902720",
     "longitude":"-56.02499820"
  },
  {
     "name":"Cordillera Department",
     "isoCode":"3",
     "countryCode":"PY",
     "latitude":"-25.22894910",
     "longitude":"-57.01116810"
  },
  {
     "name":"Alto Paraná Department",
     "isoCode":"10",
     "countryCode":"PY",
     "latitude":"-25.60755460",
     "longitude":"-54.96118360"
  },
  {
     "name":"Alto Paraguay Department",
     "isoCode":"16",
     "countryCode":"PY",
     "latitude":"-20.08525080",
     "longitude":"-59.47209040"
  },
  {
     "name":"Misiones Department",
     "isoCode":"8",
     "countryCode":"PY",
     "latitude":"-26.84335120",
     "longitude":"-57.10131880"
  },
  {
     "name":"Jaffna District",
     "isoCode":"41",
     "countryCode":"LK",
     "latitude":"9.69304680",
     "longitude":"80.16518540"
  },
  {
     "name":"Kandy District",
     "isoCode":"21",
     "countryCode":"LK",
     "latitude":"7.29315880",
     "longitude":"80.63501070"
  },
  {
     "name":"Kalutara District",
     "isoCode":"13",
     "countryCode":"LK",
     "latitude":"6.60846860",
     "longitude":"80.14285840"
  },
  {
     "name":"Badulla District",
     "isoCode":"81",
     "countryCode":"LK",
     "latitude":"6.99340090",
     "longitude":"81.05498150"
  },
  {
     "name":"Hambantota District",
     "isoCode":"33",
     "countryCode":"LK",
     "latitude":"6.15358160",
     "longitude":"81.12714900"
  },
  {
     "name":"Galle District",
     "isoCode":"31",
     "countryCode":"LK",
     "latitude":"6.05774900",
     "longitude":"80.21755720"
  },
  {
     "name":"Kilinochchi District",
     "isoCode":"42",
     "countryCode":"LK",
     "latitude":"9.36779710",
     "longitude":"80.32131060"
  },
  {
     "name":"Nuwara Eliya District",
     "isoCode":"23",
     "countryCode":"LK",
     "latitude":"6.96065320",
     "longitude":"80.76927580"
  },
  {
     "name":"Trincomalee District",
     "isoCode":"53",
     "countryCode":"LK",
     "latitude":"8.60130690",
     "longitude":"81.11960750"
  },
  {
     "name":"Puttalam District",
     "isoCode":"62",
     "countryCode":"LK",
     "latitude":"8.02599150",
     "longitude":"79.84712720"
  },
  {
     "name":"Kegalle District",
     "isoCode":"92",
     "countryCode":"LK",
     "latitude":"7.12040530",
     "longitude":"80.32131060"
  },
  {
     "name":"Central Province",
     "isoCode":"2",
     "countryCode":"LK",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Ampara District",
     "isoCode":"52",
     "countryCode":"LK",
     "latitude":"7.29116850",
     "longitude":"81.67237610"
  },
  {
     "name":"North Central Province",
     "isoCode":"7",
     "countryCode":"LK",
     "latitude":"8.19956380",
     "longitude":"80.63269160"
  },
  {
     "name":"Southern Province",
     "isoCode":"3",
     "countryCode":"LK",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Western Province",
     "isoCode":"1",
     "countryCode":"LK",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Sabaragamuwa Province",
     "isoCode":"9",
     "countryCode":"LK",
     "latitude":"6.73959410",
     "longitude":"80.36586500"
  },
  {
     "name":"Gampaha District",
     "isoCode":"12",
     "countryCode":"LK",
     "latitude":"7.07126190",
     "longitude":"80.00877460"
  },
  {
     "name":"Mannar District",
     "isoCode":"43",
     "countryCode":"LK",
     "latitude":"8.98095310",
     "longitude":"79.90439750"
  },
  {
     "name":"Matara District",
     "isoCode":"32",
     "countryCode":"LK",
     "latitude":"5.94493480",
     "longitude":"80.54879970"
  },
  {
     "name":"Ratnapura district",
     "isoCode":"91",
     "countryCode":"LK",
     "latitude":"6.70551680",
     "longitude":"80.38483890"
  },
  {
     "name":"Eastern Province",
     "isoCode":"5",
     "countryCode":"LK",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Vavuniya District",
     "isoCode":"44",
     "countryCode":"LK",
     "latitude":"8.75947390",
     "longitude":"80.50003340"
  },
  {
     "name":"Matale District",
     "isoCode":"22",
     "countryCode":"LK",
     "latitude":"7.46596460",
     "longitude":"80.62342590"
  },
  {
     "name":"Uva Province",
     "isoCode":"8",
     "countryCode":"LK",
     "latitude":"6.84276120",
     "longitude":"81.33994140"
  },
  {
     "name":"Polonnaruwa District",
     "isoCode":"72",
     "countryCode":"LK",
     "latitude":"7.93955670",
     "longitude":"81.00034030"
  },
  {
     "name":"Northern Province",
     "isoCode":"4",
     "countryCode":"LK",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Mullaitivu District",
     "isoCode":"45",
     "countryCode":"LK",
     "latitude":"9.26753880",
     "longitude":"80.81282540"
  },
  {
     "name":"Colombo District",
     "isoCode":"11",
     "countryCode":"LK",
     "latitude":"6.92695570",
     "longitude":"79.86173060"
  },
  {
     "name":"Anuradhapura District",
     "isoCode":"71",
     "countryCode":"LK",
     "latitude":"8.33183050",
     "longitude":"80.40290170"
  },
  {
     "name":"North Western Province",
     "isoCode":"6",
     "countryCode":"LK",
     "latitude":"7.75840910",
     "longitude":"80.18750650"
  },
  {
     "name":"Batticaloa District",
     "isoCode":"51",
     "countryCode":"LK",
     "latitude":"7.82927810",
     "longitude":"81.47183870"
  },
  {
     "name":"Monaragala District",
     "isoCode":"82",
     "countryCode":"LK",
     "latitude":"6.87277810",
     "longitude":"81.35068320"
  },
  {
     "name":"Mohéli",
     "isoCode":"M",
     "countryCode":"KM",
     "latitude":"-12.33773760",
     "longitude":"43.73340890"
  },
  {
     "name":"Anjouan",
     "isoCode":"A",
     "countryCode":"KM",
     "latitude":"-12.21381450",
     "longitude":"44.43706060"
  },
  {
     "name":"Grande Comore",
     "isoCode":"G",
     "countryCode":"KM",
     "latitude":"-11.71673380",
     "longitude":"43.36807880"
  },
  {
     "name":"Atacama Region",
     "isoCode":"AT",
     "countryCode":"CL",
     "latitude":"-27.56605580",
     "longitude":"-70.05031400"
  },
  {
     "name":"Santiago Metropolitan Region",
     "isoCode":"RM",
     "countryCode":"CL",
     "latitude":"-33.43755450",
     "longitude":"-70.65048960"
  },
  {
     "name":"Coquimbo Region",
     "isoCode":"CO",
     "countryCode":"CL",
     "latitude":"-30.54018100",
     "longitude":"-70.81199530"
  },
  {
     "name":"Araucanía Region",
     "isoCode":"AR",
     "countryCode":"CL",
     "latitude":"-38.94892100",
     "longitude":"-72.33111300"
  },
  {
     "name":"Bío Bío Region",
     "isoCode":"BI",
     "countryCode":"CL",
     "latitude":"-37.44644280",
     "longitude":"-72.14161320"
  },
  {
     "name":"Aysén Region",
     "isoCode":"AI",
     "countryCode":"CL",
     "latitude":"-46.37834500",
     "longitude":"-72.30076230"
  },
  {
     "name":"Arica y Parinacota Region",
     "isoCode":"AP",
     "countryCode":"CL",
     "latitude":"-18.59404850",
     "longitude":"-69.47845410"
  },
  {
     "name":"Valparaíso",
     "isoCode":"VS",
     "countryCode":"CL",
     "latitude":"-33.04723800",
     "longitude":"-71.61268850"
  },
  {
     "name":"Ñuble Region",
     "isoCode":"NB",
     "countryCode":"CL",
     "latitude":"-36.72257430",
     "longitude":"-71.76224810"
  },
  {
     "name":"Antofagasta Region",
     "isoCode":"AN",
     "countryCode":"CL",
     "latitude":"-23.83691040",
     "longitude":"-69.28775350"
  },
  {
     "name":"Maule Region",
     "isoCode":"ML",
     "countryCode":"CL",
     "latitude":"-35.51636030",
     "longitude":"-71.57239530"
  },
  {
     "name":"Los Ríos Region",
     "isoCode":"LR",
     "countryCode":"CL",
     "latitude":"-40.23102170",
     "longitude":"-72.33111300"
  },
  {
     "name":"Los Lagos Region",
     "isoCode":"LL",
     "countryCode":"CL",
     "latitude":"-41.91977790",
     "longitude":"-72.14161320"
  },
  {
     "name":"Magellan and the Chilean Antarctic Region",
     "isoCode":"MA",
     "countryCode":"CL",
     "latitude":"-52.20643160",
     "longitude":"-72.16850010"
  },
  {
     "name":"Tarapacá Region",
     "isoCode":"TA",
     "countryCode":"CL",
     "latitude":"-20.20287990",
     "longitude":"-69.28775350"
  },
  {
     "name":"O'Higgins",
     "isoCode":"LI",
     "countryCode":"CL",
     "latitude":"-34.57553740",
     "longitude":"-71.00223110"
  },
  {
     "name":"Commewijne District",
     "isoCode":"CM",
     "countryCode":"SR",
     "latitude":"5.74021100",
     "longitude":"-54.87312190"
  },
  {
     "name":"Nickerie District",
     "isoCode":"NI",
     "countryCode":"SR",
     "latitude":"5.58554690",
     "longitude":"-56.83111170"
  },
  {
     "name":"Para District",
     "isoCode":"PR",
     "countryCode":"SR",
     "latitude":"5.48173180",
     "longitude":"-55.22592070"
  },
  {
     "name":"Coronie District",
     "isoCode":"CR",
     "countryCode":"SR",
     "latitude":"5.69432710",
     "longitude":"-56.29293810"
  },
  {
     "name":"Paramaribo District",
     "isoCode":"PM",
     "countryCode":"SR",
     "latitude":"5.85203550",
     "longitude":"-55.20382780"
  },
  {
     "name":"Wanica District",
     "isoCode":"WA",
     "countryCode":"SR",
     "latitude":"5.73237620",
     "longitude":"-55.27012350"
  },
  {
     "name":"Marowijne District",
     "isoCode":"MA",
     "countryCode":"SR",
     "latitude":"5.62681280",
     "longitude":"-54.25931180"
  },
  {
     "name":"Brokopondo District",
     "isoCode":"BR",
     "countryCode":"SR",
     "latitude":"4.77102470",
     "longitude":"-55.04933750"
  },
  {
     "name":"Sipaliwini District",
     "isoCode":"SI",
     "countryCode":"SR",
     "latitude":"3.65673820",
     "longitude":"-56.20353870"
  },
  {
     "name":"Saramacca District",
     "isoCode":"SA",
     "countryCode":"SR",
     "latitude":"5.72408130",
     "longitude":"-55.66896360"
  },
  {
     "name":"Riyadh Region",
     "isoCode":"01",
     "countryCode":"SA",
     "latitude":"22.75543850",
     "longitude":"46.20915470"
  },
  {
     "name":"Makkah Region",
     "isoCode":"02",
     "countryCode":"SA",
     "latitude":"21.52355840",
     "longitude":"41.91964710"
  },
  {
     "name":"Al Madinah Region",
     "isoCode":"03",
     "countryCode":"SA",
     "latitude":"24.84039770",
     "longitude":"39.32062410"
  },
  {
     "name":"Tabuk Region",
     "isoCode":"07",
     "countryCode":"SA",
     "latitude":"28.24533350",
     "longitude":"37.63866220"
  },
  {
     "name":"'Asir Region",
     "isoCode":"14",
     "countryCode":"SA",
     "latitude":"19.09690620",
     "longitude":"42.86378750"
  },
  {
     "name":"Northern Borders Region",
     "isoCode":"08",
     "countryCode":"SA",
     "latitude":"30.07991620",
     "longitude":"42.86378750"
  },
  {
     "name":"Ha'il Region",
     "isoCode":"06",
     "countryCode":"SA",
     "latitude":"27.70761430",
     "longitude":"41.91964710"
  },
  {
     "name":"Eastern Province",
     "isoCode":"04",
     "countryCode":"SA",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Al Jawf Region",
     "isoCode":"12",
     "countryCode":"SA",
     "latitude":"29.88735600",
     "longitude":"39.32062410"
  },
  {
     "name":"Jizan Region",
     "isoCode":"09",
     "countryCode":"SA",
     "latitude":"17.17381760",
     "longitude":"42.70761070"
  },
  {
     "name":"Al Bahah Region",
     "isoCode":"11",
     "countryCode":"SA",
     "latitude":"20.27227390",
     "longitude":"41.44125100"
  },
  {
     "name":"Najran Region",
     "isoCode":"10",
     "countryCode":"SA",
     "latitude":"18.35146640",
     "longitude":"45.60071080"
  },
  {
     "name":"Al-Qassim Region",
     "isoCode":"05",
     "countryCode":"SA",
     "latitude":"26.20782600",
     "longitude":"43.48373800"
  },
  {
     "name":"Plateaux Department",
     "isoCode":"14",
     "countryCode":"CG",
     "latitude":"-2.06800880",
     "longitude":"15.40680790"
  },
  {
     "name":"Pointe-Noire",
     "isoCode":"16",
     "countryCode":"CG",
     "latitude":"-4.76916230",
     "longitude":"11.86636200"
  },
  {
     "name":"Cuvette Department",
     "isoCode":"8",
     "countryCode":"CG",
     "latitude":"-0.28774460",
     "longitude":"16.15809370"
  },
  {
     "name":"Likouala Department",
     "isoCode":"7",
     "countryCode":"CG",
     "latitude":"2.04392400",
     "longitude":"17.66888700"
  },
  {
     "name":"Bouenza Department",
     "isoCode":"11",
     "countryCode":"CG",
     "latitude":"-4.11280790",
     "longitude":"13.72891670"
  },
  {
     "name":"Kouilou Department",
     "isoCode":"5",
     "countryCode":"CG",
     "latitude":"-4.14284130",
     "longitude":"11.88917210"
  },
  {
     "name":"Lékoumou Department",
     "isoCode":"2",
     "countryCode":"CG",
     "latitude":"-3.17038200",
     "longitude":"13.35872880"
  },
  {
     "name":"Cuvette-Ouest Department",
     "isoCode":"15",
     "countryCode":"CG",
     "latitude":"0.14475500",
     "longitude":"14.47233010"
  },
  {
     "name":"Brazzaville",
     "isoCode":"BZV",
     "countryCode":"CG",
     "latitude":"-4.26335970",
     "longitude":"15.24288530"
  },
  {
     "name":"Sangha Department",
     "isoCode":"13",
     "countryCode":"CG",
     "latitude":"1.46623280",
     "longitude":"15.40680790"
  },
  {
     "name":"Niari Department",
     "isoCode":"9",
     "countryCode":"CG",
     "latitude":"-3.18427000",
     "longitude":"12.25479190"
  },
  {
     "name":"Pool Department",
     "isoCode":"12",
     "countryCode":"CG",
     "latitude":"-3.77626280",
     "longitude":"14.84546190"
  },
  {
     "name":"Quindío Department",
     "isoCode":"QUI",
     "countryCode":"CO",
     "latitude":"4.46101910",
     "longitude":"-75.66735600"
  },
  {
     "name":"Cundinamarca Department",
     "isoCode":"CUN",
     "countryCode":"CO",
     "latitude":"5.02600300",
     "longitude":"-74.03001220"
  },
  {
     "name":"Chocó Department",
     "isoCode":"CHO",
     "countryCode":"CO",
     "latitude":"5.25280330",
     "longitude":"-76.82596520"
  },
  {
     "name":"Norte de Santander Department",
     "isoCode":"NSA",
     "countryCode":"CO",
     "latitude":"7.94628310",
     "longitude":"-72.89880690"
  },
  {
     "name":"Meta",
     "isoCode":"MET",
     "countryCode":"CO",
     "latitude":"39.76732580",
     "longitude":"-104.97535950"
  },
  {
     "name":"Risaralda Department",
     "isoCode":"RIS",
     "countryCode":"CO",
     "latitude":"5.31584750",
     "longitude":"-75.99276520"
  },
  {
     "name":"Atlántico Department",
     "isoCode":"ATL",
     "countryCode":"CO",
     "latitude":"10.69661590",
     "longitude":"-74.87410450"
  },
  {
     "name":"Arauca Department",
     "isoCode":"ARA",
     "countryCode":"CO",
     "latitude":"6.54730600",
     "longitude":"-71.00223110"
  },
  {
     "name":"Guainía Department",
     "isoCode":"GUA",
     "countryCode":"CO",
     "latitude":"2.58539300",
     "longitude":"-68.52471490"
  },
  {
     "name":"Tolima Department",
     "isoCode":"TOL",
     "countryCode":"CO",
     "latitude":"4.09251680",
     "longitude":"-75.15453810"
  },
  {
     "name":"Cauca Department",
     "isoCode":"CAU",
     "countryCode":"CO",
     "latitude":"2.70498130",
     "longitude":"-76.82596520"
  },
  {
     "name":"Vaupés Department",
     "isoCode":"VAU",
     "countryCode":"CO",
     "latitude":"0.85535610",
     "longitude":"-70.81199530"
  },
  {
     "name":"Magdalena Department",
     "isoCode":"MAG",
     "countryCode":"CO",
     "latitude":"10.41130140",
     "longitude":"-74.40566120"
  },
  {
     "name":"Caldas Department",
     "isoCode":"CAL",
     "countryCode":"CO",
     "latitude":"5.29826000",
     "longitude":"-75.24790610"
  },
  {
     "name":"Guaviare Department",
     "isoCode":"GUV",
     "countryCode":"CO",
     "latitude":"2.04392400",
     "longitude":"-72.33111300"
  },
  {
     "name":"La Guajira Department",
     "isoCode":"LAG",
     "countryCode":"CO",
     "latitude":"11.35477430",
     "longitude":"-72.52048270"
  },
  {
     "name":"Antioquia Department",
     "isoCode":"ANT",
     "countryCode":"CO",
     "latitude":"7.19860640",
     "longitude":"-75.34121790"
  },
  {
     "name":"Caquetá Department",
     "isoCode":"CAQ",
     "countryCode":"CO",
     "latitude":"0.86989200",
     "longitude":"-73.84190630"
  },
  {
     "name":"Casanare Department",
     "isoCode":"CAS",
     "countryCode":"CO",
     "latitude":"5.75892690",
     "longitude":"-71.57239530"
  },
  {
     "name":"Bolívar Department",
     "isoCode":"BOL",
     "countryCode":"CO",
     "latitude":"8.67043820",
     "longitude":"-74.03001220"
  },
  {
     "name":"Vichada Department",
     "isoCode":"VID",
     "countryCode":"CO",
     "latitude":"4.42344520",
     "longitude":"-69.28775350"
  },
  {
     "name":"Amazonas Department",
     "isoCode":"AMA",
     "countryCode":"CO",
     "latitude":"-1.44291230",
     "longitude":"-71.57239530"
  },
  {
     "name":"Putumayo Department",
     "isoCode":"PUT",
     "countryCode":"CO",
     "latitude":"0.43595060",
     "longitude":"-75.52766990"
  },
  {
     "name":"Nariño Department",
     "isoCode":"NAR",
     "countryCode":"CO",
     "latitude":"1.28915100",
     "longitude":"-77.35794000"
  },
  {
     "name":"Córdoba Department",
     "isoCode":"COR",
     "countryCode":"CO",
     "latitude":"8.04929300",
     "longitude":"-75.57405000"
  },
  {
     "name":"Cesar Department",
     "isoCode":"CES",
     "countryCode":"CO",
     "latitude":"9.33729480",
     "longitude":"-73.65362090"
  },
  {
     "name":"Archipelago of Saint Andréws, Providence and Saint Catalina",
     "isoCode":"SAP",
     "countryCode":"CO",
     "latitude":"12.55673240",
     "longitude":"-81.71852530"
  },
  {
     "name":"Santander Department",
     "isoCode":"SAN",
     "countryCode":"CO",
     "latitude":"6.64370760",
     "longitude":"-73.65362090"
  },
  {
     "name":"Sucre Department",
     "isoCode":"SUC",
     "countryCode":"CO",
     "latitude":"8.81397700",
     "longitude":"-74.72328300"
  },
  {
     "name":"Boyacá Department",
     "isoCode":"BOY",
     "countryCode":"CO",
     "latitude":"5.45451100",
     "longitude":"-73.36200300"
  },
  {
     "name":"Valle del Cauca Department",
     "isoCode":"VAC",
     "countryCode":"CO",
     "latitude":"3.80088930",
     "longitude":"-76.64127120"
  },
  {
     "name":"Galápagos Province",
     "isoCode":"W",
     "countryCode":"EC",
     "latitude":"-0.95376910",
     "longitude":"-90.96560190"
  },
  {
     "name":"Sucumbíos Province",
     "isoCode":"U",
     "countryCode":"EC",
     "latitude":"0.08892310",
     "longitude":"-76.88975570"
  },
  {
     "name":"Pastaza Province",
     "isoCode":"Y",
     "countryCode":"EC",
     "latitude":"-1.48822650",
     "longitude":"-78.00310570"
  },
  {
     "name":"Tungurahua Province",
     "isoCode":"T",
     "countryCode":"EC",
     "latitude":"-1.26352840",
     "longitude":"-78.56608520"
  },
  {
     "name":"Zamora-Chinchipe Province",
     "isoCode":"Z",
     "countryCode":"EC",
     "latitude":"-4.06558920",
     "longitude":"-78.95035250"
  },
  {
     "name":"Los Ríos Province",
     "isoCode":"R",
     "countryCode":"EC",
     "latitude":"-1.02306070",
     "longitude":"-79.46088970"
  },
  {
     "name":"Imbabura Province",
     "isoCode":"I",
     "countryCode":"EC",
     "latitude":"0.34997680",
     "longitude":"-78.12601290"
  },
  {
     "name":"Santa Elena Province",
     "isoCode":"SE",
     "countryCode":"EC",
     "latitude":"-2.22671050",
     "longitude":"-80.85949900"
  },
  {
     "name":"Manabí Province",
     "isoCode":"M",
     "countryCode":"EC",
     "latitude":"-1.05434340",
     "longitude":"-80.45264400"
  },
  {
     "name":"Guayas Province",
     "isoCode":"G",
     "countryCode":"EC",
     "latitude":"-1.95748390",
     "longitude":"-79.91927020"
  },
  {
     "name":"Carchi Province",
     "isoCode":"C",
     "countryCode":"EC",
     "latitude":"0.50269120",
     "longitude":"-77.90425210"
  },
  {
     "name":"Napo Province",
     "isoCode":"N",
     "countryCode":"EC",
     "latitude":"-0.99559640",
     "longitude":"-77.81296840"
  },
  {
     "name":"Cañar Province",
     "isoCode":"F",
     "countryCode":"EC",
     "latitude":"-2.55893150",
     "longitude":"-78.93881910"
  },
  {
     "name":"Morona-Santiago Province",
     "isoCode":"S",
     "countryCode":"EC",
     "latitude":"-2.30510620",
     "longitude":"-78.11468660"
  },
  {
     "name":"Santo Domingo de los Tsáchilas Province",
     "isoCode":"SD",
     "countryCode":"EC",
     "latitude":"-0.25218820",
     "longitude":"-79.18793830"
  },
  {
     "name":"Bolívar Province",
     "isoCode":"B",
     "countryCode":"EC",
     "latitude":"-1.70958280",
     "longitude":"-79.04504290"
  },
  {
     "name":"Cotopaxi Province",
     "isoCode":"X",
     "countryCode":"EC",
     "latitude":"-0.83842060",
     "longitude":"-78.66626780"
  },
  {
     "name":"Esmeraldas",
     "isoCode":"E",
     "countryCode":"EC",
     "latitude":"0.96817890",
     "longitude":"-79.65172020"
  },
  {
     "name":"Azuay Province",
     "isoCode":"A",
     "countryCode":"EC",
     "latitude":"-2.89430680",
     "longitude":"-78.99683440"
  },
  {
     "name":"El Oro Province",
     "isoCode":"O",
     "countryCode":"EC",
     "latitude":"-3.25924130",
     "longitude":"-79.95835410"
  },
  {
     "name":"Chimborazo Province",
     "isoCode":"H",
     "countryCode":"EC",
     "latitude":"-1.66479950",
     "longitude":"-78.65432550"
  },
  {
     "name":"Orellana Province",
     "isoCode":"D",
     "countryCode":"EC",
     "latitude":"-0.45451630",
     "longitude":"-76.99502860"
  },
  {
     "name":"Pichincha Province",
     "isoCode":"P",
     "countryCode":"EC",
     "latitude":"-0.14648470",
     "longitude":"-78.47519450"
  },
  {
     "name":"Obock Region",
     "isoCode":"OB",
     "countryCode":"DJ",
     "latitude":"12.38956910",
     "longitude":"43.01948970"
  },
  {
     "name":"Djibouti",
     "isoCode":"DJ",
     "countryCode":"DJ",
     "latitude":"11.82513800",
     "longitude":"42.59027500"
  },
  {
     "name":"Dikhil Region",
     "isoCode":"DI",
     "countryCode":"DJ",
     "latitude":"11.10543360",
     "longitude":"42.37047440"
  },
  {
     "name":"Tadjourah Region",
     "isoCode":"TA",
     "countryCode":"DJ",
     "latitude":"11.93388850",
     "longitude":"42.39383750"
  },
  {
     "name":"Arta Region",
     "isoCode":"AR",
     "countryCode":"DJ",
     "latitude":"11.52555280",
     "longitude":"42.84794740"
  },
  {
     "name":"Ali Sabieh Region",
     "isoCode":"AS",
     "countryCode":"DJ",
     "latitude":"11.19289730",
     "longitude":"42.94169800"
  },
  {
     "name":"Hama Governorate",
     "isoCode":"HM",
     "countryCode":"SY",
     "latitude":"35.18878650",
     "longitude":"37.21158290"
  },
  {
     "name":"Rif Dimashq Governorate",
     "isoCode":"RD",
     "countryCode":"SY",
     "latitude":"33.51672890",
     "longitude":"36.95410700"
  },
  {
     "name":"As-Suwayda Governorate",
     "isoCode":"SU",
     "countryCode":"SY",
     "latitude":"32.79891560",
     "longitude":"36.78195050"
  },
  {
     "name":"Deir ez-Zor Governorate",
     "isoCode":"DY",
     "countryCode":"SY",
     "latitude":"35.28797980",
     "longitude":"40.30886260"
  },
  {
     "name":"Latakia Governorate",
     "isoCode":"LA",
     "countryCode":"SY",
     "latitude":"35.61297910",
     "longitude":"36.00232250"
  },
  {
     "name":"Damascus Governorate",
     "isoCode":"DI",
     "countryCode":"SY",
     "latitude":"33.51514440",
     "longitude":"36.39313540"
  },
  {
     "name":"Idlib Governorate",
     "isoCode":"ID",
     "countryCode":"SY",
     "latitude":"35.82687980",
     "longitude":"36.69572160"
  },
  {
     "name":"Al-Hasakah Governorate",
     "isoCode":"HA",
     "countryCode":"SY",
     "latitude":"36.40551500",
     "longitude":"40.79691490"
  },
  {
     "name":"Homs Governorate",
     "isoCode":"HI",
     "countryCode":"SY",
     "latitude":"34.25671230",
     "longitude":"38.31657250"
  },
  {
     "name":"Quneitra Governorate",
     "isoCode":"QU",
     "countryCode":"SY",
     "latitude":"33.07763180",
     "longitude":"35.89341360"
  },
  {
     "name":"Al-Raqqah Governorate",
     "isoCode":"RA",
     "countryCode":"SY",
     "latitude":"35.95941060",
     "longitude":"38.99810520"
  },
  {
     "name":"Daraa Governorate",
     "isoCode":"DR",
     "countryCode":"SY",
     "latitude":"32.92488130",
     "longitude":"36.17626150"
  },
  {
     "name":"Aleppo Governorate",
     "isoCode":"HL",
     "countryCode":"SY",
     "latitude":"36.22623930",
     "longitude":"37.46813960"
  },
  {
     "name":"Tartus Governorate",
     "isoCode":"TA",
     "countryCode":"SY",
     "latitude":"35.00066520",
     "longitude":"36.00232250"
  },
  {
     "name":"Fianarantsoa Province",
     "isoCode":"F",
     "countryCode":"MG",
     "latitude":"-22.35362400",
     "longitude":"46.82528380"
  },
  {
     "name":"Toliara Province",
     "isoCode":"U",
     "countryCode":"MG",
     "latitude":"-23.35161910",
     "longitude":"43.68549360"
  },
  {
     "name":"Antsiranana Province",
     "isoCode":"D",
     "countryCode":"MG",
     "latitude":"-13.77153900",
     "longitude":"49.52799960"
  },
  {
     "name":"Antananarivo Province",
     "isoCode":"T",
     "countryCode":"MG",
     "latitude":"-18.70514740",
     "longitude":"46.82528380"
  },
  {
     "name":"Toamasina Province",
     "isoCode":"A",
     "countryCode":"MG",
     "latitude":"-18.14428110",
     "longitude":"49.39578360"
  },
  {
     "name":"Mahajanga Province",
     "isoCode":"M",
     "countryCode":"MG",
     "latitude":"-16.52388300",
     "longitude":"46.51626200"
  },
  {
     "name":"Mogilev Region",
     "isoCode":"MA",
     "countryCode":"BY",
     "latitude":"53.51017910",
     "longitude":"30.40064440"
  },
  {
     "name":"Gomel Region",
     "isoCode":"HO",
     "countryCode":"BY",
     "latitude":"52.16487540",
     "longitude":"29.13332510"
  },
  {
     "name":"Grodno Region",
     "isoCode":"HR",
     "countryCode":"BY",
     "latitude":"53.65999450",
     "longitude":"25.34485710"
  },
  {
     "name":"Minsk Region",
     "isoCode":"MI",
     "countryCode":"BY",
     "latitude":"54.10678890",
     "longitude":"27.41292450"
  },
  {
     "name":"Minsk",
     "isoCode":"HM",
     "countryCode":"BY",
     "latitude":"53.90060110",
     "longitude":"27.55897200"
  },
  {
     "name":"Brest Region",
     "isoCode":"BR",
     "countryCode":"BY",
     "latitude":"52.52966410",
     "longitude":"25.46064800"
  },
  {
     "name":"Vitebsk Region",
     "isoCode":"VI",
     "countryCode":"BY",
     "latitude":"55.29598330",
     "longitude":"28.75836270"
  },
  {
     "name":"Murqub",
     "isoCode":"MB",
     "countryCode":"LY",
     "latitude":"32.45996770",
     "longitude":"14.10013260"
  },
  {
     "name":"Nuqat al Khams",
     "isoCode":"NQ",
     "countryCode":"LY",
     "latitude":"32.69149090",
     "longitude":"11.88917210"
  },
  {
     "name":"Zawiya District",
     "isoCode":"ZA",
     "countryCode":"LY",
     "latitude":"32.76302820",
     "longitude":"12.73649620"
  },
  {
     "name":"Al Wahat District",
     "isoCode":"WA",
     "countryCode":"LY",
     "latitude":"29.04668080",
     "longitude":"21.85685860"
  },
  {
     "name":"Sabha District",
     "isoCode":"SB",
     "countryCode":"LY",
     "latitude":"27.03654060",
     "longitude":"14.42902360"
  },
  {
     "name":"Derna District",
     "isoCode":"DR",
     "countryCode":"LY",
     "latitude":"32.75561300",
     "longitude":"22.63774320"
  },
  {
     "name":"Murzuq District",
     "isoCode":"MQ",
     "countryCode":"LY",
     "latitude":"25.91822620",
     "longitude":"13.92600010"
  },
  {
     "name":"Marj District",
     "isoCode":"MJ",
     "countryCode":"LY",
     "latitude":"32.05503630",
     "longitude":"21.18911510"
  },
  {
     "name":"Ghat District",
     "isoCode":"GT",
     "countryCode":"LY",
     "latitude":"24.96403710",
     "longitude":"10.17592850"
  },
  {
     "name":"Jufra",
     "isoCode":"JU",
     "countryCode":"LY",
     "latitude":"27.98351350",
     "longitude":"16.91225100"
  },
  {
     "name":"Tripoli District",
     "isoCode":"TB",
     "countryCode":"LY",
     "latitude":"32.64080210",
     "longitude":"13.26634790"
  },
  {
     "name":"Kufra District",
     "isoCode":"KF",
     "countryCode":"LY",
     "latitude":"23.31123890",
     "longitude":"21.85685860"
  },
  {
     "name":"Wadi al Hayaa District",
     "isoCode":"WD",
     "countryCode":"LY",
     "latitude":"26.42259260",
     "longitude":"12.62162110"
  },
  {
     "name":"Jabal al Gharbi District",
     "isoCode":"JG",
     "countryCode":"LY",
     "latitude":"30.26380320",
     "longitude":"12.80547530"
  },
  {
     "name":"Wadi al Shatii District",
     "isoCode":"WS",
     "countryCode":"LY",
     "latitude":"27.73514680",
     "longitude":"12.43805810"
  },
  {
     "name":"Nalut District",
     "isoCode":"NL",
     "countryCode":"LY",
     "latitude":"31.87423480",
     "longitude":"10.97504840"
  },
  {
     "name":"Sirte District",
     "isoCode":"SR",
     "countryCode":"LY",
     "latitude":"31.18968900",
     "longitude":"16.57019270"
  },
  {
     "name":"Misrata District",
     "isoCode":"MI",
     "countryCode":"LY",
     "latitude":"32.32558840",
     "longitude":"15.09925560"
  },
  {
     "name":"Jafara",
     "isoCode":"JI",
     "countryCode":"LY",
     "latitude":"32.45259040",
     "longitude":"12.94355360"
  },
  {
     "name":"Jabal al Akhdar",
     "isoCode":"JA",
     "countryCode":"LY",
     "latitude":"23.18560810",
     "longitude":"57.37138790"
  },
  {
     "name":"Benghazi",
     "isoCode":"BA",
     "countryCode":"LY",
     "latitude":"32.11942420",
     "longitude":"20.08679090"
  },
  {
     "name":"Ribeira Brava Municipality",
     "isoCode":"RB",
     "countryCode":"CV",
     "latitude":"16.60707390",
     "longitude":"-24.20338430"
  },
  {
     "name":"Tarrafal",
     "isoCode":"TA",
     "countryCode":"CV",
     "latitude":"15.27605780",
     "longitude":"-23.74840770"
  },
  {
     "name":"Ribeira Grande de Santiago",
     "isoCode":"RS",
     "countryCode":"CV",
     "latitude":"14.98302980",
     "longitude":"-23.65617250"
  },
  {
     "name":"Santa Catarina",
     "isoCode":"CA",
     "countryCode":"CV",
     "latitude":"-27.24233920",
     "longitude":"-50.21885560"
  },
  {
     "name":"São Domingos",
     "isoCode":"SD",
     "countryCode":"CV",
     "latitude":"15.02861650",
     "longitude":"-23.56392200"
  },
  {
     "name":"Mosteiros",
     "isoCode":"MO",
     "countryCode":"CV",
     "latitude":"37.89043480",
     "longitude":"-25.82075560"
  },
  {
     "name":"Praia",
     "isoCode":"PR",
     "countryCode":"CV",
     "latitude":"14.93305000",
     "longitude":"-23.51332670"
  },
  {
     "name":"Porto Novo",
     "isoCode":"PN",
     "countryCode":"CV",
     "latitude":"6.49685740",
     "longitude":"2.62885230"
  },
  {
     "name":"São Miguel",
     "isoCode":"SM",
     "countryCode":"CV",
     "latitude":"37.78041100",
     "longitude":"-25.49704660"
  },
  {
     "name":"Maio Municipality",
     "isoCode":"MA",
     "countryCode":"CV",
     "latitude":"15.20030980",
     "longitude":"-23.16797930"
  },
  {
     "name":"Sotavento Islands",
     "isoCode":"S",
     "countryCode":"CV",
     "latitude":"15.00000000",
     "longitude":"-24.00000000"
  },
  {
     "name":"São Lourenço dos Órgãos",
     "isoCode":"SO",
     "countryCode":"CV",
     "latitude":"15.05378410",
     "longitude":"-23.60856120"
  },
  {
     "name":"Barlavento Islands",
     "isoCode":"B",
     "countryCode":"CV",
     "latitude":"16.82368450",
     "longitude":"-23.99348810"
  },
  {
     "name":"Santa Catarina do Fogo",
     "isoCode":"CF",
     "countryCode":"CV",
     "latitude":"14.93091040",
     "longitude":"-24.32225770"
  },
  {
     "name":"Brava",
     "isoCode":"BR",
     "countryCode":"CV",
     "latitude":"40.98977780",
     "longitude":"-73.68357150"
  },
  {
     "name":"Paul",
     "isoCode":"PA",
     "countryCode":"CV",
     "latitude":"37.06250000",
     "longitude":"-95.67706800"
  },
  {
     "name":"Sal",
     "isoCode":"SL",
     "countryCode":"CV",
     "latitude":"26.59581220",
     "longitude":"-80.20450830"
  },
  {
     "name":"Boa Vista",
     "isoCode":"BV",
     "countryCode":"CV",
     "latitude":"38.74346600",
     "longitude":"-120.73042970"
  },
  {
     "name":"São Filipe",
     "isoCode":"SF",
     "countryCode":"CV",
     "latitude":"14.89516790",
     "longitude":"-24.49456360"
  },
  {
     "name":"São Vicente",
     "isoCode":"SV",
     "countryCode":"CV",
     "latitude":"-23.96071570",
     "longitude":"-46.39620220"
  },
  {
     "name":"Ribeira Grande",
     "isoCode":"RG",
     "countryCode":"CV",
     "latitude":"37.82103690",
     "longitude":"-25.51481370"
  },
  {
     "name":"Tarrafal de São Nicolau",
     "isoCode":"TS",
     "countryCode":"CV",
     "latitude":"16.56364980",
     "longitude":"-24.35494200"
  },
  {
     "name":"Santa Cruz",
     "isoCode":"CR",
     "countryCode":"CV",
     "latitude":"36.97411710",
     "longitude":"-122.03079630"
  },
  {
     "name":"Schleswig-Holstein",
     "isoCode":"SH",
     "countryCode":"DE",
     "latitude":"54.21936720",
     "longitude":"9.69611670"
  },
  {
     "name":"Baden-Württemberg",
     "isoCode":"BW",
     "countryCode":"DE",
     "latitude":"48.66160370",
     "longitude":"9.35013360"
  },
  {
     "name":"Mecklenburg-Vorpommern",
     "isoCode":"MV",
     "countryCode":"DE",
     "latitude":"53.61265050",
     "longitude":"12.42959530"
  },
  {
     "name":"Lower Saxony",
     "isoCode":"NI",
     "countryCode":"DE",
     "latitude":"52.63670360",
     "longitude":"9.84507660"
  },
  {
     "name":"Bavaria",
     "isoCode":"BY",
     "countryCode":"DE",
     "latitude":"48.79044720",
     "longitude":"11.49788950"
  },
  {
     "name":"Berlin",
     "isoCode":"BE",
     "countryCode":"DE",
     "latitude":"52.52000660",
     "longitude":"13.40495400"
  },
  {
     "name":"Saxony-Anhalt",
     "isoCode":"ST",
     "countryCode":"DE",
     "latitude":"51.95026490",
     "longitude":"11.69227340"
  },
  {
     "name":"Brandenburg",
     "isoCode":"BB",
     "countryCode":"DE",
     "latitude":"52.41252870",
     "longitude":"12.53164440"
  },
  {
     "name":"Bremen",
     "isoCode":"HB",
     "countryCode":"DE",
     "latitude":"53.07929620",
     "longitude":"8.80169360"
  },
  {
     "name":"Thuringia",
     "isoCode":"TH",
     "countryCode":"DE",
     "latitude":"51.01098920",
     "longitude":"10.84534600"
  },
  {
     "name":"Hamburg",
     "isoCode":"HH",
     "countryCode":"DE",
     "latitude":"53.55108460",
     "longitude":"9.99368190"
  },
  {
     "name":"North Rhine-Westphalia",
     "isoCode":"NW",
     "countryCode":"DE",
     "latitude":"51.43323670",
     "longitude":"7.66159380"
  },
  {
     "name":"Hesse",
     "isoCode":"HE",
     "countryCode":"DE",
     "latitude":"50.65205150",
     "longitude":"9.16243760"
  },
  {
     "name":"Rhineland-Palatinate",
     "isoCode":"RP",
     "countryCode":"DE",
     "latitude":"50.11834600",
     "longitude":"7.30895270"
  },
  {
     "name":"Saarland",
     "isoCode":"SL",
     "countryCode":"DE",
     "latitude":"49.39642340",
     "longitude":"7.02296070"
  },
  {
     "name":"Saxony",
     "isoCode":"SN",
     "countryCode":"DE",
     "latitude":"51.10454070",
     "longitude":"13.20173840"
  },
  {
     "name":"Mafeteng District",
     "isoCode":"E",
     "countryCode":"LS",
     "latitude":"-29.80410080",
     "longitude":"27.50261740"
  },
  {
     "name":"Mohale's Hoek District",
     "isoCode":"F",
     "countryCode":"LS",
     "latitude":"-30.14259170",
     "longitude":"27.46738450"
  },
  {
     "name":"Mokhotlong District",
     "isoCode":"J",
     "countryCode":"LS",
     "latitude":"-29.25731930",
     "longitude":"28.95286450"
  },
  {
     "name":"Qacha's Nek District",
     "isoCode":"H",
     "countryCode":"LS",
     "latitude":"-30.11145650",
     "longitude":"28.67897900"
  },
  {
     "name":"Leribe District",
     "isoCode":"C",
     "countryCode":"LS",
     "latitude":"-28.86380650",
     "longitude":"28.04788260"
  },
  {
     "name":"Quthing District",
     "isoCode":"G",
     "countryCode":"LS",
     "latitude":"-30.40156870",
     "longitude":"27.70801330"
  },
  {
     "name":"Maseru District",
     "isoCode":"A",
     "countryCode":"LS",
     "latitude":"-29.51656500",
     "longitude":"27.83114280"
  },
  {
     "name":"Butha-Buthe District",
     "isoCode":"B",
     "countryCode":"LS",
     "latitude":"-28.76537540",
     "longitude":"28.24681480"
  },
  {
     "name":"Berea District",
     "isoCode":"D",
     "countryCode":"LS",
     "latitude":"41.36616140",
     "longitude":"-81.85430260"
  },
  {
     "name":"Thaba-Tseka District",
     "isoCode":"K",
     "countryCode":"LS",
     "latitude":"-29.52389750",
     "longitude":"28.60897520"
  },
  {
     "name":"Montserrado County",
     "isoCode":"MO",
     "countryCode":"LR",
     "latitude":"6.55258150",
     "longitude":"-10.52961150"
  },
  {
     "name":"River Cess County",
     "isoCode":"RI",
     "countryCode":"LR",
     "latitude":"5.90253280",
     "longitude":"-9.45615500"
  },
  {
     "name":"Bong County",
     "isoCode":"BG",
     "countryCode":"LR",
     "latitude":"6.82950190",
     "longitude":"-9.36730840"
  },
  {
     "name":"Sinoe County",
     "isoCode":"SI",
     "countryCode":"LR",
     "latitude":"5.49871000",
     "longitude":"-8.66005860"
  },
  {
     "name":"Grand Cape Mount County",
     "isoCode":"CM",
     "countryCode":"LR",
     "latitude":"7.04677580",
     "longitude":"-11.07117580"
  },
  {
     "name":"Lofa County",
     "isoCode":"LO",
     "countryCode":"LR",
     "latitude":"8.19111840",
     "longitude":"-9.72326730"
  },
  {
     "name":"River Gee County",
     "isoCode":"RG",
     "countryCode":"LR",
     "latitude":"5.26048940",
     "longitude":"-7.87216000"
  },
  {
     "name":"Grand Gedeh County",
     "isoCode":"GG",
     "countryCode":"LR",
     "latitude":"5.92220780",
     "longitude":"-8.22129790"
  },
  {
     "name":"Grand Bassa County",
     "isoCode":"GB",
     "countryCode":"LR",
     "latitude":"6.23084520",
     "longitude":"-9.81249350"
  },
  {
     "name":"Bomi County",
     "isoCode":"BM",
     "countryCode":"LR",
     "latitude":"6.75629260",
     "longitude":"-10.84514670"
  },
  {
     "name":"Maryland County",
     "isoCode":"MY",
     "countryCode":"LR",
     "latitude":"39.04575490",
     "longitude":"-76.64127120"
  },
  {
     "name":"Margibi County",
     "isoCode":"MG",
     "countryCode":"LR",
     "latitude":"6.51518750",
     "longitude":"-10.30488970"
  },
  {
     "name":"Gbarpolu County",
     "isoCode":"GP",
     "countryCode":"LR",
     "latitude":"7.49526370",
     "longitude":"-10.08072980"
  },
  {
     "name":"Grand Kru County",
     "isoCode":"GK",
     "countryCode":"LR",
     "latitude":"4.76138620",
     "longitude":"-8.22129790"
  },
  {
     "name":"Nimba",
     "isoCode":"NI",
     "countryCode":"LR",
     "latitude":"7.61666670",
     "longitude":"-8.41666670"
  },
  {
     "name":"Ad Dhahirah Governorate",
     "isoCode":"ZA",
     "countryCode":"OM",
     "latitude":"23.21616740",
     "longitude":"56.49074440"
  },
  {
     "name":"Al Batinah North Governorate",
     "isoCode":"BS",
     "countryCode":"OM",
     "latitude":"24.34198460",
     "longitude":"56.72989040"
  },
  {
     "name":"Al Batinah South Governorate",
     "isoCode":"BJ",
     "countryCode":"OM",
     "latitude":"23.43149030",
     "longitude":"57.42397960"
  },
  {
     "name":"Al Batinah Region",
     "isoCode":"BA",
     "countryCode":"OM",
     "latitude":"24.34198460",
     "longitude":"56.72989040"
  },
  {
     "name":"Ash Sharqiyah Region",
     "isoCode":"SH",
     "countryCode":"OM",
     "latitude":"22.71411960",
     "longitude":"58.53080640"
  },
  {
     "name":"Musandam Governorate",
     "isoCode":"MU",
     "countryCode":"OM",
     "latitude":"26.19861440",
     "longitude":"56.24609490"
  },
  {
     "name":"Ash Sharqiyah North Governorate",
     "isoCode":"SS",
     "countryCode":"OM",
     "latitude":"22.71411960",
     "longitude":"58.53080640"
  },
  {
     "name":"Ash Sharqiyah South Governorate",
     "isoCode":"SJ",
     "countryCode":"OM",
     "latitude":"22.01582490",
     "longitude":"59.32519220"
  },
  {
     "name":"Muscat Governorate",
     "isoCode":"MA",
     "countryCode":"OM",
     "latitude":"23.58803070",
     "longitude":"58.38287170"
  },
  {
     "name":"Al Wusta Governorate",
     "isoCode":"WU",
     "countryCode":"OM",
     "latitude":"19.95710780",
     "longitude":"56.27568460"
  },
  {
     "name":"Dhofar Governorate",
     "isoCode":"ZU",
     "countryCode":"OM",
     "latitude":"17.03221210",
     "longitude":"54.14252140"
  },
  {
     "name":"Ad Dakhiliyah Governorate",
     "isoCode":"DA",
     "countryCode":"OM",
     "latitude":"22.85887580",
     "longitude":"57.53943560"
  },
  {
     "name":"Al Buraimi Governorate",
     "isoCode":"BU",
     "countryCode":"OM",
     "latitude":"24.16714130",
     "longitude":"56.11422530"
  },
  {
     "name":"Ngamiland",
     "isoCode":"NG",
     "countryCode":"BW",
     "latitude":"-19.19053210",
     "longitude":"23.00119890"
  },
  {
     "name":"Ghanzi District",
     "isoCode":"GH",
     "countryCode":"BW",
     "latitude":"-21.86523140",
     "longitude":"21.85685860"
  },
  {
     "name":"Kgatleng District",
     "isoCode":"KL",
     "countryCode":"BW",
     "latitude":"-24.19704450",
     "longitude":"26.23046160"
  },
  {
     "name":"Southern District",
     "isoCode":"SO",
     "countryCode":"BW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"South-East District",
     "isoCode":"SE",
     "countryCode":"BW",
     "latitude":"31.21637980",
     "longitude":"-82.35270440"
  },
  {
     "name":"North-West District",
     "isoCode":"NW",
     "countryCode":"BW",
     "latitude":"39.34463070",
     "longitude":"-76.68542830"
  },
  {
     "name":"Kgalagadi District",
     "isoCode":"KG",
     "countryCode":"BW",
     "latitude":"-24.75502850",
     "longitude":"21.85685860"
  },
  {
     "name":"Central District",
     "isoCode":"CE",
     "countryCode":"BW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"North-East District",
     "isoCode":"NE",
     "countryCode":"BW",
     "latitude":"37.58844610",
     "longitude":"-94.68637820"
  },
  {
     "name":"Kweneng District",
     "isoCode":"KW",
     "countryCode":"BW",
     "latitude":"-23.83672490",
     "longitude":"25.28375850"
  },
  {
     "name":"Collines Department",
     "isoCode":"CO",
     "countryCode":"BJ",
     "latitude":"8.30222970",
     "longitude":"2.30244600"
  },
  {
     "name":"Kouffo Department",
     "isoCode":"KO",
     "countryCode":"BJ",
     "latitude":"7.00358940",
     "longitude":"1.75388170"
  },
  {
     "name":"Donga Department",
     "isoCode":"DO",
     "countryCode":"BJ",
     "latitude":"9.71918670",
     "longitude":"1.67606910"
  },
  {
     "name":"Zou Department",
     "isoCode":"ZO",
     "countryCode":"BJ",
     "latitude":"7.34692680",
     "longitude":"2.06651970"
  },
  {
     "name":"Plateau Department",
     "isoCode":"PL",
     "countryCode":"BJ",
     "latitude":"7.34451410",
     "longitude":"2.53960300"
  },
  {
     "name":"Mono Department",
     "isoCode":"MO",
     "countryCode":"BJ",
     "latitude":"37.92186080",
     "longitude":"-118.95286450"
  },
  {
     "name":"Atakora Department",
     "isoCode":"AK",
     "countryCode":"BJ",
     "latitude":"10.79549310",
     "longitude":"1.67606910"
  },
  {
     "name":"Alibori Department",
     "isoCode":"AL",
     "countryCode":"BJ",
     "latitude":"10.96810930",
     "longitude":"2.77798130"
  },
  {
     "name":"Borgou Department",
     "isoCode":"BO",
     "countryCode":"BJ",
     "latitude":"9.53408640",
     "longitude":"2.77798130"
  },
  {
     "name":"Atlantique Department",
     "isoCode":"AQ",
     "countryCode":"BJ",
     "latitude":"6.65883910",
     "longitude":"2.22366670"
  },
  {
     "name":"Ouémé Department",
     "isoCode":"OU",
     "countryCode":"BJ",
     "latitude":"6.61481520",
     "longitude":"2.49999180"
  },
  {
     "name":"Littoral Department",
     "isoCode":"LI",
     "countryCode":"BJ",
     "latitude":"6.38069730",
     "longitude":"2.44063870"
  },
  {
     "name":"Machinga District",
     "isoCode":"MH",
     "countryCode":"MW",
     "latitude":"-14.94072630",
     "longitude":"35.47819260"
  },
  {
     "name":"Zomba District",
     "isoCode":"ZO",
     "countryCode":"MW",
     "latitude":"-15.37658570",
     "longitude":"35.33565180"
  },
  {
     "name":"Mwanza District",
     "isoCode":"MW",
     "countryCode":"MW",
     "latitude":"-2.46711970",
     "longitude":"32.89868120"
  },
  {
     "name":"Nsanje District",
     "isoCode":"NS",
     "countryCode":"MW",
     "latitude":"-16.72882020",
     "longitude":"35.17087410"
  },
  {
     "name":"Salima District",
     "isoCode":"SA",
     "countryCode":"MW",
     "latitude":"-13.68095860",
     "longitude":"34.41982430"
  },
  {
     "name":"Chitipa district",
     "isoCode":"CT",
     "countryCode":"MW",
     "latitude":"-9.70376550",
     "longitude":"33.27002530"
  },
  {
     "name":"Ntcheu District",
     "isoCode":"NU",
     "countryCode":"MW",
     "latitude":"-14.90375380",
     "longitude":"34.77407930"
  },
  {
     "name":"Rumphi District",
     "isoCode":"RU",
     "countryCode":"MW",
     "latitude":"-10.78515370",
     "longitude":"34.33103640"
  },
  {
     "name":"Dowa District",
     "isoCode":"DO",
     "countryCode":"MW",
     "latitude":"-13.60412560",
     "longitude":"33.88577470"
  },
  {
     "name":"Karonga District",
     "isoCode":"KR",
     "countryCode":"MW",
     "latitude":"-9.90363650",
     "longitude":"33.97500180"
  },
  {
     "name":"Central Region",
     "isoCode":"C",
     "countryCode":"MW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Likoma District",
     "isoCode":"LK",
     "countryCode":"MW",
     "latitude":"-12.05840050",
     "longitude":"34.73540310"
  },
  {
     "name":"Kasungu District",
     "isoCode":"KS",
     "countryCode":"MW",
     "latitude":"-13.13670650",
     "longitude":"33.25879300"
  },
  {
     "name":"Nkhata Bay District",
     "isoCode":"NB",
     "countryCode":"MW",
     "latitude":"-11.71850420",
     "longitude":"34.33103640"
  },
  {
     "name":"Balaka District",
     "isoCode":"BA",
     "countryCode":"MW",
     "latitude":"-15.05065950",
     "longitude":"35.08285880"
  },
  {
     "name":"Dedza District",
     "isoCode":"DE",
     "countryCode":"MW",
     "latitude":"-14.18945850",
     "longitude":"34.24215970"
  },
  {
     "name":"Thyolo District",
     "isoCode":"TH",
     "countryCode":"MW",
     "latitude":"-16.12991770",
     "longitude":"35.12687810"
  },
  {
     "name":"Mchinji District",
     "isoCode":"MC",
     "countryCode":"MW",
     "latitude":"-13.74015250",
     "longitude":"32.98883190"
  },
  {
     "name":"Nkhotakota District",
     "isoCode":"NK",
     "countryCode":"MW",
     "latitude":"-12.75419610",
     "longitude":"34.24215970"
  },
  {
     "name":"Lilongwe District",
     "isoCode":"LI",
     "countryCode":"MW",
     "latitude":"-14.04752280",
     "longitude":"33.61757700"
  },
  {
     "name":"Blantyre District",
     "isoCode":"BL",
     "countryCode":"MW",
     "latitude":"-15.67785410",
     "longitude":"34.95066250"
  },
  {
     "name":"Mulanje District",
     "isoCode":"MU",
     "countryCode":"MW",
     "latitude":"-15.93464340",
     "longitude":"35.52200120"
  },
  {
     "name":"Mzimba District",
     "isoCode":"MZ",
     "countryCode":"MW",
     "latitude":"-11.74754520",
     "longitude":"33.52800720"
  },
  {
     "name":"Northern Region",
     "isoCode":"N",
     "countryCode":"MW",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Southern Region",
     "isoCode":"S",
     "countryCode":"MW",
     "latitude":"32.75049570",
     "longitude":"-97.33154760"
  },
  {
     "name":"Chikwawa District",
     "isoCode":"CK",
     "countryCode":"MW",
     "latitude":"-16.19584460",
     "longitude":"34.77407930"
  },
  {
     "name":"Phalombe District",
     "isoCode":"PH",
     "countryCode":"MW",
     "latitude":"-15.70920380",
     "longitude":"35.65328480"
  },
  {
     "name":"Chiradzulu District",
     "isoCode":"CR",
     "countryCode":"MW",
     "latitude":"-15.74231510",
     "longitude":"35.25879640"
  },
  {
     "name":"Mangochi District",
     "isoCode":"MG",
     "countryCode":"MW",
     "latitude":"-14.13882480",
     "longitude":"35.03881640"
  },
  {
     "name":"Ntchisi District",
     "isoCode":"NI",
     "countryCode":"MW",
     "latitude":"-13.28419920",
     "longitude":"33.88577470"
  },
  {
     "name":"Kénédougou Province",
     "isoCode":"KEN",
     "countryCode":"BF",
     "latitude":"11.39193950",
     "longitude":"-4.97665400"
  },
  {
     "name":"Namentenga Province",
     "isoCode":"NAM",
     "countryCode":"BF",
     "latitude":"13.08125840",
     "longitude":"-0.52578230"
  },
  {
     "name":"Sahel Region",
     "isoCode":"12",
     "countryCode":"BF",
     "latitude":"14.10008650",
     "longitude":"-0.14949880"
  },
  {
     "name":"Centre-Ouest Region",
     "isoCode":"06",
     "countryCode":"BF",
     "latitude":"11.87984660",
     "longitude":"-2.30244600"
  },
  {
     "name":"Nahouri Province",
     "isoCode":"NAO",
     "countryCode":"BF",
     "latitude":"11.25022670",
     "longitude":"-1.13530200"
  },
  {
     "name":"Passoré Province",
     "isoCode":"PAS",
     "countryCode":"BF",
     "latitude":"12.88812210",
     "longitude":"-2.22366670"
  },
  {
     "name":"Zoundwéogo Province",
     "isoCode":"ZOU",
     "countryCode":"BF",
     "latitude":"11.61411740",
     "longitude":"-0.98206680"
  },
  {
     "name":"Sissili Province",
     "isoCode":"SIS",
     "countryCode":"BF",
     "latitude":"11.24412190",
     "longitude":"-2.22366670"
  },
  {
     "name":"Banwa Province",
     "isoCode":"BAN",
     "countryCode":"BF",
     "latitude":"12.13230530",
     "longitude":"-4.15137640"
  },
  {
     "name":"Bougouriba Province",
     "isoCode":"BGR",
     "countryCode":"BF",
     "latitude":"10.87226460",
     "longitude":"-3.33889170"
  },
  {
     "name":"Gnagna Province",
     "isoCode":"GNA",
     "countryCode":"BF",
     "latitude":"12.89749920",
     "longitude":"0.07467670"
  },
  {
     "name":"Mouhoun",
     "isoCode":"MOU",
     "countryCode":"BF",
     "latitude":"12.14323810",
     "longitude":"-3.33889170"
  },
  {
     "name":"Yagha Province",
     "isoCode":"YAG",
     "countryCode":"BF",
     "latitude":"13.35761570",
     "longitude":"0.75328090"
  },
  {
     "name":"Plateau-Central Region",
     "isoCode":"11",
     "countryCode":"BF",
     "latitude":"12.25376480",
     "longitude":"-0.75328090"
  },
  {
     "name":"Sanmatenga Province",
     "isoCode":"SMT",
     "countryCode":"BF",
     "latitude":"13.35653040",
     "longitude":"-1.05861350"
  },
  {
     "name":"Centre-Nord Region",
     "isoCode":"05",
     "countryCode":"BF",
     "latitude":"13.17244640",
     "longitude":"-0.90566230"
  },
  {
     "name":"Tapoa Province",
     "isoCode":"TAP",
     "countryCode":"BF",
     "latitude":"12.24970720",
     "longitude":"1.67606910"
  },
  {
     "name":"Houet Province",
     "isoCode":"HOU",
     "countryCode":"BF",
     "latitude":"11.13204470",
     "longitude":"-4.23333550"
  },
  {
     "name":"Zondoma Province",
     "isoCode":"ZON",
     "countryCode":"BF",
     "latitude":"13.11659260",
     "longitude":"-2.42087130"
  },
  {
     "name":"Boulgou",
     "isoCode":"BLG",
     "countryCode":"BF",
     "latitude":"11.43367660",
     "longitude":"-0.37483540"
  },
  {
     "name":"Komondjari Province",
     "isoCode":"KMD",
     "countryCode":"BF",
     "latitude":"12.71265270",
     "longitude":"0.67730460"
  },
  {
     "name":"Koulpélogo Province",
     "isoCode":"KOP",
     "countryCode":"BF",
     "latitude":"11.52476740",
     "longitude":"0.14949880"
  },
  {
     "name":"Tuy Province",
     "isoCode":"TUI",
     "countryCode":"BF",
     "latitude":"38.88868400",
     "longitude":"-77.00471900"
  },
  {
     "name":"Ioba Province",
     "isoCode":"IOB",
     "countryCode":"BF",
     "latitude":"11.05620340",
     "longitude":"-3.01757120"
  },
  {
     "name":"Centre",
     "isoCode":"03",
     "countryCode":"BF",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Sourou Province",
     "isoCode":"SOR",
     "countryCode":"BF",
     "latitude":"13.34180300",
     "longitude":"-2.93757390"
  },
  {
     "name":"Boucle du Mouhoun Region",
     "isoCode":"01",
     "countryCode":"BF",
     "latitude":"12.41660000",
     "longitude":"-3.41955270"
  },
  {
     "name":"Séno Province",
     "isoCode":"SEN",
     "countryCode":"BF",
     "latitude":"14.00722340",
     "longitude":"-0.07467670"
  },
  {
     "name":"Sud-Ouest Region",
     "isoCode":"13",
     "countryCode":"BF",
     "latitude":"10.42314930",
     "longitude":"-3.25836260"
  },
  {
     "name":"Oubritenga Province",
     "isoCode":"OUB",
     "countryCode":"BF",
     "latitude":"12.70960870",
     "longitude":"-1.44346900"
  },
  {
     "name":"Nayala Province",
     "isoCode":"NAY",
     "countryCode":"BF",
     "latitude":"12.69645580",
     "longitude":"-3.01757120"
  },
  {
     "name":"Gourma Province",
     "isoCode":"GOU",
     "countryCode":"BF",
     "latitude":"12.16244730",
     "longitude":"0.67730460"
  },
  {
     "name":"Oudalan Province",
     "isoCode":"OUD",
     "countryCode":"BF",
     "latitude":"14.47190200",
     "longitude":"-0.45023680"
  },
  {
     "name":"Ziro Province",
     "isoCode":"ZIR",
     "countryCode":"BF",
     "latitude":"11.60949950",
     "longitude":"-1.90992380"
  },
  {
     "name":"Kossi Province",
     "isoCode":"KOS",
     "countryCode":"BF",
     "latitude":"12.96045800",
     "longitude":"-3.90626880"
  },
  {
     "name":"Kourwéogo Province",
     "isoCode":"KOW",
     "countryCode":"BF",
     "latitude":"12.70774950",
     "longitude":"-1.75388170"
  },
  {
     "name":"Ganzourgou Province",
     "isoCode":"GAN",
     "countryCode":"BF",
     "latitude":"12.25376480",
     "longitude":"-0.75328090"
  },
  {
     "name":"Centre-Sud Region",
     "isoCode":"07",
     "countryCode":"BF",
     "latitude":"11.52289110",
     "longitude":"-1.05861350"
  },
  {
     "name":"Yatenga Province",
     "isoCode":"YAT",
     "countryCode":"BF",
     "latitude":"13.62493440",
     "longitude":"-2.38136210"
  },
  {
     "name":"Loroum Province",
     "isoCode":"LOR",
     "countryCode":"BF",
     "latitude":"13.81298140",
     "longitude":"-2.06651970"
  },
  {
     "name":"Bazèga Province",
     "isoCode":"BAZ",
     "countryCode":"BF",
     "latitude":"11.97676920",
     "longitude":"-1.44346900"
  },
  {
     "name":"Cascades Region",
     "isoCode":"02",
     "countryCode":"BF",
     "latitude":"10.40729920",
     "longitude":"-4.56244260"
  },
  {
     "name":"Sanguié Province",
     "isoCode":"SNG",
     "countryCode":"BF",
     "latitude":"12.15018610",
     "longitude":"-2.69838680"
  },
  {
     "name":"Bam Province",
     "isoCode":"BAM",
     "countryCode":"BF",
     "latitude":"13.44613300",
     "longitude":"-1.59839590"
  },
  {
     "name":"Noumbiel Province",
     "isoCode":"NOU",
     "countryCode":"BF",
     "latitude":"9.84409460",
     "longitude":"-2.97755580"
  },
  {
     "name":"Kompienga Province",
     "isoCode":"KMP",
     "countryCode":"BF",
     "latitude":"11.52383620",
     "longitude":"0.75328090"
  },
  {
     "name":"Est Region",
     "isoCode":"08",
     "countryCode":"BF",
     "latitude":"12.43655260",
     "longitude":"0.90566230"
  },
  {
     "name":"Léraba Province",
     "isoCode":"LER",
     "countryCode":"BF",
     "latitude":"10.66487850",
     "longitude":"-5.31025050"
  },
  {
     "name":"Balé Province",
     "isoCode":"BAL",
     "countryCode":"BF",
     "latitude":"11.78206020",
     "longitude":"-3.01757120"
  },
  {
     "name":"Kouritenga Province",
     "isoCode":"KOT",
     "countryCode":"BF",
     "latitude":"12.16318130",
     "longitude":"-0.22446620"
  },
  {
     "name":"Centre-Est Region",
     "isoCode":"04",
     "countryCode":"BF",
     "latitude":"11.52476740",
     "longitude":"-0.14949880"
  },
  {
     "name":"Poni Province",
     "isoCode":"PON",
     "countryCode":"BF",
     "latitude":"10.33259960",
     "longitude":"-3.33889170"
  },
  {
     "name":"Nord Region, Burkina Faso",
     "isoCode":"10",
     "countryCode":"BF",
     "latitude":"13.71825200",
     "longitude":"-2.30244600"
  },
  {
     "name":"Hauts-Bassins Region",
     "isoCode":"09",
     "countryCode":"BF",
     "latitude":"11.49420030",
     "longitude":"-4.23333550"
  },
  {
     "name":"Soum Province",
     "isoCode":"SOM",
     "countryCode":"BF",
     "latitude":"14.09628410",
     "longitude":"-1.36621600"
  },
  {
     "name":"Comoé Province",
     "isoCode":"COM",
     "countryCode":"BF",
     "latitude":"10.40729920",
     "longitude":"-4.56244260"
  },
  {
     "name":"Kadiogo Province",
     "isoCode":"KAD",
     "countryCode":"BF",
     "latitude":"12.34258970",
     "longitude":"-1.44346900"
  },
  {
     "name":"Islamabad Capital Territory",
     "isoCode":"IS",
     "countryCode":"PK",
     "latitude":"33.72049970",
     "longitude":"73.04052770"
  },
  {
     "name":"Gilgit-Baltistan",
     "isoCode":"GB",
     "countryCode":"PK",
     "latitude":"35.80256670",
     "longitude":"74.98318080"
  },
  {
     "name":"Khyber Pakhtunkhwa",
     "isoCode":"KP",
     "countryCode":"PK",
     "latitude":"34.95262050",
     "longitude":"72.33111300"
  },
  {
     "name":"Azad Kashmir",
     "isoCode":"JK",
     "countryCode":"PK",
     "latitude":"33.92590550",
     "longitude":"73.78103340"
  },
  {
     "name":"Federally Administered Tribal Areas",
     "isoCode":"TA",
     "countryCode":"PK",
     "latitude":"32.66747600",
     "longitude":"69.85974060"
  },
  {
     "name":"Balochistan",
     "isoCode":"BA",
     "countryCode":"PK",
     "latitude":"28.49073320",
     "longitude":"65.09577920"
  },
  {
     "name":"Sindh",
     "isoCode":"SD",
     "countryCode":"PK",
     "latitude":"25.89430180",
     "longitude":"68.52471490"
  },
  {
     "name":"Punjab",
     "isoCode":"PB",
     "countryCode":"PK",
     "latitude":"31.14713050",
     "longitude":"75.34121790"
  },
  {
     "name":"Al Rayyan Municipality",
     "isoCode":"RA",
     "countryCode":"QA",
     "latitude":"25.25225510",
     "longitude":"51.43887130"
  },
  {
     "name":"Al-Shahaniya",
     "isoCode":"SH",
     "countryCode":"QA",
     "latitude":"25.41063860",
     "longitude":"51.18460250"
  },
  {
     "name":"Al Wakrah",
     "isoCode":"WA",
     "countryCode":"QA",
     "latitude":"25.16593140",
     "longitude":"51.59755240"
  },
  {
     "name":"Madinat ash Shamal",
     "isoCode":"MS",
     "countryCode":"QA",
     "latitude":"26.11827430",
     "longitude":"51.21572650"
  },
  {
     "name":"Doha",
     "isoCode":"DA",
     "countryCode":"QA",
     "latitude":"25.28544730",
     "longitude":"51.53103980"
  },
  {
     "name":"Al Daayen",
     "isoCode":"ZA",
     "countryCode":"QA",
     "latitude":"25.57845590",
     "longitude":"51.48213870"
  },
  {
     "name":"Al Khor",
     "isoCode":"KH",
     "countryCode":"QA",
     "latitude":"25.68040780",
     "longitude":"51.49685020"
  },
  {
     "name":"Umm Salal Municipality",
     "isoCode":"US",
     "countryCode":"QA",
     "latitude":"25.48752420",
     "longitude":"51.39656800"
  },
  {
     "name":"Rumonge Province",
     "isoCode":"RM",
     "countryCode":"BI",
     "latitude":"-3.97540490",
     "longitude":"29.43880140"
  },
  {
     "name":"Muyinga Province",
     "isoCode":"MY",
     "countryCode":"BI",
     "latitude":"-2.77935110",
     "longitude":"30.29741990"
  },
  {
     "name":"Mwaro Province",
     "isoCode":"MW",
     "countryCode":"BI",
     "latitude":"-3.50259180",
     "longitude":"29.64991620"
  },
  {
     "name":"Makamba Province",
     "isoCode":"MA",
     "countryCode":"BI",
     "latitude":"-4.32570620",
     "longitude":"29.69626770"
  },
  {
     "name":"Rutana Province",
     "isoCode":"RT",
     "countryCode":"BI",
     "latitude":"-3.87915230",
     "longitude":"30.06652360"
  },
  {
     "name":"Cibitoke Province",
     "isoCode":"CI",
     "countryCode":"BI",
     "latitude":"-2.81028970",
     "longitude":"29.18557850"
  },
  {
     "name":"Ruyigi Province",
     "isoCode":"RY",
     "countryCode":"BI",
     "latitude":"-3.44620700",
     "longitude":"30.25127280"
  },
  {
     "name":"Kayanza Province",
     "isoCode":"KY",
     "countryCode":"BI",
     "latitude":"-3.00779810",
     "longitude":"29.64991620"
  },
  {
     "name":"Muramvya Province",
     "isoCode":"MU",
     "countryCode":"BI",
     "latitude":"-3.28983980",
     "longitude":"29.64991620"
  },
  {
     "name":"Karuzi Province",
     "isoCode":"KR",
     "countryCode":"BI",
     "latitude":"-3.13403470",
     "longitude":"30.11273500"
  },
  {
     "name":"Kirundo Province",
     "isoCode":"KI",
     "countryCode":"BI",
     "latitude":"-2.57628820",
     "longitude":"30.11273500"
  },
  {
     "name":"Bubanza Province",
     "isoCode":"BB",
     "countryCode":"BI",
     "latitude":"-3.15724030",
     "longitude":"29.37149090"
  },
  {
     "name":"Gitega Province",
     "isoCode":"GI",
     "countryCode":"BI",
     "latitude":"-3.49290510",
     "longitude":"29.92779470"
  },
  {
     "name":"Bujumbura Mairie Province",
     "isoCode":"BM",
     "countryCode":"BI",
     "latitude":"-3.38841410",
     "longitude":"29.34826460"
  },
  {
     "name":"Ngozi Province",
     "isoCode":"NG",
     "countryCode":"BI",
     "latitude":"-2.89582430",
     "longitude":"29.88152030"
  },
  {
     "name":"Bujumbura Rural Province",
     "isoCode":"BL",
     "countryCode":"BI",
     "latitude":"-3.50901440",
     "longitude":"29.46435900"
  },
  {
     "name":"Cankuzo Province",
     "isoCode":"CA",
     "countryCode":"BI",
     "latitude":"-3.15277880",
     "longitude":"30.61998950"
  },
  {
     "name":"Bururi Province",
     "isoCode":"BR",
     "countryCode":"BI",
     "latitude":"-3.90068510",
     "longitude":"29.51077080"
  },
  {
     "name":"Flores Department",
     "isoCode":"FS",
     "countryCode":"UY",
     "latitude":"-33.57337530",
     "longitude":"-56.89450280"
  },
  {
     "name":"San José Department",
     "isoCode":"SJ",
     "countryCode":"UY",
     "latitude":"37.34929680",
     "longitude":"-121.90560490"
  },
  {
     "name":"Artigas Department",
     "isoCode":"AR",
     "countryCode":"UY",
     "latitude":"-30.61751120",
     "longitude":"-56.95945590"
  },
  {
     "name":"Maldonado Department",
     "isoCode":"MA",
     "countryCode":"UY",
     "latitude":"-34.55979320",
     "longitude":"-54.86285520"
  },
  {
     "name":"Rivera Department",
     "isoCode":"RV",
     "countryCode":"UY",
     "latitude":"-31.48174210",
     "longitude":"-55.24357590"
  },
  {
     "name":"Colonia Department",
     "isoCode":"CO",
     "countryCode":"UY",
     "latitude":"-34.12946780",
     "longitude":"-57.66051840"
  },
  {
     "name":"Durazno Department",
     "isoCode":"DU",
     "countryCode":"UY",
     "latitude":"-33.02324540",
     "longitude":"-56.02846440"
  },
  {
     "name":"Río Negro Department",
     "isoCode":"RN",
     "countryCode":"UY",
     "latitude":"-32.76763560",
     "longitude":"-57.42952070"
  },
  {
     "name":"Cerro Largo Department",
     "isoCode":"CL",
     "countryCode":"UY",
     "latitude":"-32.44110320",
     "longitude":"-54.35217530"
  },
  {
     "name":"Paysandú Department",
     "isoCode":"PA",
     "countryCode":"UY",
     "latitude":"-32.06673660",
     "longitude":"-57.33647890"
  },
  {
     "name":"Canelones Department",
     "isoCode":"CA",
     "countryCode":"UY",
     "latitude":"-34.54087170",
     "longitude":"-55.93076000"
  },
  {
     "name":"Treinta y Tres Department",
     "isoCode":"TT",
     "countryCode":"UY",
     "latitude":"-33.06850860",
     "longitude":"-54.28586270"
  },
  {
     "name":"Lavalleja Department",
     "isoCode":"LA",
     "countryCode":"UY",
     "latitude":"-33.92261750",
     "longitude":"-54.97657940"
  },
  {
     "name":"Rocha Department",
     "isoCode":"RO",
     "countryCode":"UY",
     "latitude":"-33.96900810",
     "longitude":"-54.02148500"
  },
  {
     "name":"Florida Department",
     "isoCode":"FD",
     "countryCode":"UY",
     "latitude":"28.03594950",
     "longitude":"-82.45792890"
  },
  {
     "name":"Montevideo Department",
     "isoCode":"MO",
     "countryCode":"UY",
     "latitude":"-34.81815870",
     "longitude":"-56.21382560"
  },
  {
     "name":"Soriano Department",
     "isoCode":"SO",
     "countryCode":"UY",
     "latitude":"-33.51027920",
     "longitude":"-57.74981030"
  },
  {
     "name":"Salto Department",
     "isoCode":"SA",
     "countryCode":"UY",
     "latitude":"-31.38802800",
     "longitude":"-57.96124550"
  },
  {
     "name":"Tacuarembó Department",
     "isoCode":"TA",
     "countryCode":"UY",
     "latitude":"-31.72068370",
     "longitude":"-55.98598870"
  },
  {
     "name":"Kafr el-Sheikh Governorate",
     "isoCode":"KFS",
     "countryCode":"EG",
     "latitude":"31.30854440",
     "longitude":"30.80394740"
  },
  {
     "name":"Cairo Governorate",
     "isoCode":"C",
     "countryCode":"EG",
     "latitude":"29.95375640",
     "longitude":"31.53700030"
  },
  {
     "name":"Damietta Governorate",
     "isoCode":"DT",
     "countryCode":"EG",
     "latitude":"31.36257990",
     "longitude":"31.67393710"
  },
  {
     "name":"Aswan Governorate",
     "isoCode":"ASN",
     "countryCode":"EG",
     "latitude":"23.69664980",
     "longitude":"32.71813750"
  },
  {
     "name":"Sohag Governorate",
     "isoCode":"SHG",
     "countryCode":"EG",
     "latitude":"26.69383400",
     "longitude":"32.17460500"
  },
  {
     "name":"North Sinai Governorate",
     "isoCode":"SIN",
     "countryCode":"EG",
     "latitude":"30.28236500",
     "longitude":"33.61757700"
  },
  {
     "name":"Monufia Governorate",
     "isoCode":"MNF",
     "countryCode":"EG",
     "latitude":"30.59724550",
     "longitude":"30.98763210"
  },
  {
     "name":"Port Said Governorate",
     "isoCode":"PTS",
     "countryCode":"EG",
     "latitude":"31.07586060",
     "longitude":"32.26538870"
  },
  {
     "name":"Beni Suef Governorate",
     "isoCode":"BNS",
     "countryCode":"EG",
     "latitude":"28.89388370",
     "longitude":"31.44561790"
  },
  {
     "name":"Matrouh Governorate",
     "isoCode":"MT",
     "countryCode":"EG",
     "latitude":"29.56963500",
     "longitude":"26.41938900"
  },
  {
     "name":"Qalyubia Governorate",
     "isoCode":"KB",
     "countryCode":"EG",
     "latitude":"30.32923680",
     "longitude":"31.21684660"
  },
  {
     "name":"Suez Governorate",
     "isoCode":"SUZ",
     "countryCode":"EG",
     "latitude":"29.36822550",
     "longitude":"32.17460500"
  },
  {
     "name":"Gharbia Governorate",
     "isoCode":"GH",
     "countryCode":"EG",
     "latitude":"30.87535560",
     "longitude":"31.03351000"
  },
  {
     "name":"Alexandria Governorate",
     "isoCode":"ALX",
     "countryCode":"EG",
     "latitude":"30.87605680",
     "longitude":"29.74260400"
  },
  {
     "name":"Asyut Governorate",
     "isoCode":"AST",
     "countryCode":"EG",
     "latitude":"27.21338310",
     "longitude":"31.44561790"
  },
  {
     "name":"South Sinai Governorate",
     "isoCode":"JS",
     "countryCode":"EG",
     "latitude":"29.31018280",
     "longitude":"34.15319470"
  },
  {
     "name":"Faiyum Governorate",
     "isoCode":"FYM",
     "countryCode":"EG",
     "latitude":"29.30840210",
     "longitude":"30.84284970"
  },
  {
     "name":"Giza Governorate",
     "isoCode":"GZ",
     "countryCode":"EG",
     "latitude":"28.76662160",
     "longitude":"29.23207840"
  },
  {
     "name":"Red Sea Governorate",
     "isoCode":"BA",
     "countryCode":"EG",
     "latitude":"24.68263160",
     "longitude":"34.15319470"
  },
  {
     "name":"Beheira Governorate",
     "isoCode":"BH",
     "countryCode":"EG",
     "latitude":"30.84809860",
     "longitude":"30.34355060"
  },
  {
     "name":"Luxor Governorate",
     "isoCode":"LX",
     "countryCode":"EG",
     "latitude":"25.39444440",
     "longitude":"32.49200880"
  },
  {
     "name":"Minya Governorate",
     "isoCode":"MN",
     "countryCode":"EG",
     "latitude":"28.28472900",
     "longitude":"30.52790960"
  },
  {
     "name":"Ismailia Governorate",
     "isoCode":"IS",
     "countryCode":"EG",
     "latitude":"30.58309340",
     "longitude":"32.26538870"
  },
  {
     "name":"Dakahlia Governorate",
     "isoCode":"DK",
     "countryCode":"EG",
     "latitude":"31.16560440",
     "longitude":"31.49131820"
  },
  {
     "name":"New Valley Governorate",
     "isoCode":"WAD",
     "countryCode":"EG",
     "latitude":"24.54556380",
     "longitude":"27.17353160"
  },
  {
     "name":"Qena Governorate",
     "isoCode":"KN",
     "countryCode":"EG",
     "latitude":"26.23460330",
     "longitude":"32.98883190"
  },
  {
     "name":"Agaléga",
     "isoCode":"AG",
     "countryCode":"MU",
     "latitude":"-10.40000000",
     "longitude":"56.61666670"
  },
  {
     "name":"Rodrigues",
     "isoCode":"RO",
     "countryCode":"MU",
     "latitude":"-19.72453850",
     "longitude":"63.42721850"
  },
  {
     "name":"Pamplemousses District",
     "isoCode":"PA",
     "countryCode":"MU",
     "latitude":"-20.11360080",
     "longitude":"57.57592600"
  },
  {
     "name":"Cargados Carajos",
     "isoCode":"CC",
     "countryCode":"MU",
     "latitude":"-16.58333300",
     "longitude":"59.61666700"
  },
  {
     "name":"Vacoas-Phoenix",
     "isoCode":"VP",
     "countryCode":"MU",
     "latitude":"-20.29840260",
     "longitude":"57.49383550"
  },
  {
     "name":"Moka District",
     "isoCode":"MO",
     "countryCode":"MU",
     "latitude":"-20.23997820",
     "longitude":"57.57592600"
  },
  {
     "name":"Flacq District",
     "isoCode":"FL",
     "countryCode":"MU",
     "latitude":"-20.22578360",
     "longitude":"57.71192740"
  },
  {
     "name":"Curepipe",
     "isoCode":"CU",
     "countryCode":"MU",
     "latitude":"-20.31708720",
     "longitude":"57.52652890"
  },
  {
     "name":"Port Louis",
     "isoCode":"PU",
     "countryCode":"MU",
     "latitude":"-20.16089120",
     "longitude":"57.50122220"
  },
  {
     "name":"Savanne District",
     "isoCode":"SA",
     "countryCode":"MU",
     "latitude":"-20.47395300",
     "longitude":"57.48535610"
  },
  {
     "name":"Quatre Bornes",
     "isoCode":"QB",
     "countryCode":"MU",
     "latitude":"-20.26747180",
     "longitude":"57.47969810"
  },
  {
     "name":"Rivière Noire District",
     "isoCode":"BL",
     "countryCode":"MU",
     "latitude":"-20.37084920",
     "longitude":"57.39486490"
  },
  {
     "name":"Port Louis District",
     "isoCode":"PL",
     "countryCode":"MU",
     "latitude":"-20.16089120",
     "longitude":"57.50122220"
  },
  {
     "name":"Rivière du Rempart District",
     "isoCode":"RR",
     "countryCode":"MU",
     "latitude":"-20.05609830",
     "longitude":"57.65523890"
  },
  {
     "name":"Beau Bassin-Rose Hill",
     "isoCode":"BR",
     "countryCode":"MU",
     "latitude":"-20.22303050",
     "longitude":"57.46838300"
  },
  {
     "name":"Plaines Wilhems District",
     "isoCode":"PW",
     "countryCode":"MU",
     "latitude":"-20.30548720",
     "longitude":"57.48535610"
  },
  {
     "name":"Grand Port District",
     "isoCode":"GP",
     "countryCode":"MU",
     "latitude":"-20.38515460",
     "longitude":"57.66657420"
  },
  {
     "name":"Guelmim Province",
     "isoCode":"GUE",
     "countryCode":"MA",
     "latitude":"28.98836590",
     "longitude":"-10.05274980"
  },
  {
     "name":"Aousserd Province",
     "isoCode":"AOU",
     "countryCode":"MA",
     "latitude":"22.55215380",
     "longitude":"-14.32973530"
  },
  {
     "name":"Al Hoceïma Province",
     "isoCode":"HOC",
     "countryCode":"MA",
     "latitude":"35.24455890",
     "longitude":"-3.93174680"
  },
  {
     "name":"Larache Province",
     "isoCode":"LAR",
     "countryCode":"MA",
     "latitude":"35.17442710",
     "longitude":"-6.14739640"
  },
  {
     "name":"Ouarzazate Province",
     "isoCode":"OUA",
     "countryCode":"MA",
     "latitude":"30.93354360",
     "longitude":"-6.93701600"
  },
  {
     "name":"Boulemane Province",
     "isoCode":"BOM",
     "countryCode":"MA",
     "latitude":"33.36251590",
     "longitude":"-4.73033970"
  },
  {
     "name":"Oriental",
     "isoCode":"02",
     "countryCode":"MA",
     "latitude":"37.06968300",
     "longitude":"-94.51227700"
  },
  {
     "name":"Béni-Mellal Province",
     "isoCode":"BEM",
     "countryCode":"MA",
     "latitude":"32.34244300",
     "longitude":"-6.37579900"
  },
  {
     "name":"Sidi Youssef Ben Ali",
     "isoCode":"SYB",
     "countryCode":"MA",
     "latitude":"31.60843730",
     "longitude":"-7.96530670"
  },
  {
     "name":"Chichaoua Province",
     "isoCode":"CHI",
     "countryCode":"MA",
     "latitude":"31.53835810",
     "longitude":"-8.76463880"
  },
  {
     "name":"Boujdour Province",
     "isoCode":"BOD",
     "countryCode":"MA",
     "latitude":"26.12524930",
     "longitude":"-14.48473470"
  },
  {
     "name":"Khémisset Province",
     "isoCode":"KHE",
     "countryCode":"MA",
     "latitude":"33.81537040",
     "longitude":"-6.05733020"
  },
  {
     "name":"Tiznit Province",
     "isoCode":"TIZ",
     "countryCode":"MA",
     "latitude":"29.69339200",
     "longitude":"-9.73215700"
  },
  {
     "name":"Béni Mellal-Khénifra",
     "isoCode":"05",
     "countryCode":"MA",
     "latitude":"32.57191840",
     "longitude":"-6.06791940"
  },
  {
     "name":"Sidi Kacem Province",
     "isoCode":"SIK",
     "countryCode":"MA",
     "latitude":"34.22601720",
     "longitude":"-5.71291640"
  },
  {
     "name":"El Jadida Province",
     "isoCode":"JDI",
     "countryCode":"MA",
     "latitude":"33.23163260",
     "longitude":"-8.50071160"
  },
  {
     "name":"Nador Province",
     "isoCode":"NAD",
     "countryCode":"MA",
     "latitude":"34.91719260",
     "longitude":"-2.85771050"
  },
  {
     "name":"Settat Province",
     "isoCode":"SET",
     "countryCode":"MA",
     "latitude":"32.99242420",
     "longitude":"-7.62226650"
  },
  {
     "name":"Zagora Province",
     "isoCode":"ZAG",
     "countryCode":"MA",
     "latitude":"30.57860930",
     "longitude":"-5.89871390"
  },
  {
     "name":"Mediouna Province",
     "isoCode":"MED",
     "countryCode":"MA",
     "latitude":"33.45409390",
     "longitude":"-7.51660200"
  },
  {
     "name":"Berkane Province",
     "isoCode":"BER",
     "countryCode":"MA",
     "latitude":"34.88408760",
     "longitude":"-2.34188700"
  },
  {
     "name":"Tan-Tan Province",
     "isoCode":"TNT",
     "countryCode":"MA",
     "latitude":"28.03012000",
     "longitude":"-11.16173560"
  },
  {
     "name":"Nouaceur Province",
     "isoCode":"NOU",
     "countryCode":"MA",
     "latitude":"33.36703930",
     "longitude":"-7.57325370"
  },
  {
     "name":"Marrakesh-Safi",
     "isoCode":"07",
     "countryCode":"MA",
     "latitude":"31.73308330",
     "longitude":"-8.13385580"
  },
  {
     "name":"Sefrou Province",
     "isoCode":"SEF",
     "countryCode":"MA",
     "latitude":"33.83052440",
     "longitude":"-4.83531540"
  },
  {
     "name":"Drâa-Tafilalet",
     "isoCode":"08",
     "countryCode":"MA",
     "latitude":"31.14995380",
     "longitude":"-5.39395510"
  },
  {
     "name":"El Hajeb Province",
     "isoCode":"HAJ",
     "countryCode":"MA",
     "latitude":"33.68573500",
     "longitude":"-5.36778440"
  },
  {
     "name":"Es Semara Province",
     "isoCode":"ESM",
     "countryCode":"MA",
     "latitude":"26.74185600",
     "longitude":"-11.67836710"
  },
  {
     "name":"Laâyoune Province",
     "isoCode":"LAA",
     "countryCode":"MA",
     "latitude":"27.15003840",
     "longitude":"-13.19907580"
  },
  {
     "name":"Inezgane-Aït Melloul Prefecture",
     "isoCode":"INE",
     "countryCode":"MA",
     "latitude":"30.35090980",
     "longitude":"-9.38951100"
  },
  {
     "name":"Souss-Massa",
     "isoCode":"09",
     "countryCode":"MA",
     "latitude":"30.27506110",
     "longitude":"-8.13385580"
  },
  {
     "name":"Taza Province",
     "isoCode":"TAZ",
     "countryCode":"MA",
     "latitude":"34.27889530",
     "longitude":"-3.58126920"
  },
  {
     "name":"Assa-Zag Province",
     "isoCode":"ASZ",
     "countryCode":"MA",
     "latitude":"28.14023950",
     "longitude":"-9.72326730"
  },
  {
     "name":"Laâyoune-Sakia El Hamra",
     "isoCode":"11",
     "countryCode":"MA",
     "latitude":"27.86831940",
     "longitude":"-11.98046130"
  },
  {
     "name":"Errachidia Province",
     "isoCode":"ERR",
     "countryCode":"MA",
     "latitude":"31.90512750",
     "longitude":"-4.72775280"
  },
  {
     "name":"Fahs Anjra Province",
     "isoCode":"FAH",
     "countryCode":"MA",
     "latitude":"35.76019920",
     "longitude":"-5.66683060"
  },
  {
     "name":"Figuig Province",
     "isoCode":"FIG",
     "countryCode":"MA",
     "latitude":"32.10926130",
     "longitude":"-1.22980600"
  },
  {
     "name":"Shtouka Ait Baha Province",
     "isoCode":"CHT",
     "countryCode":"MA",
     "latitude":"30.10724220",
     "longitude":"-9.27855830"
  },
  {
     "name":"Casablanca-Settat",
     "isoCode":"06",
     "countryCode":"MA",
     "latitude":"33.21608720",
     "longitude":"-7.43813550"
  },
  {
     "name":"Ben Slimane Province",
     "isoCode":"BES",
     "countryCode":"MA",
     "latitude":"33.61896980",
     "longitude":"-7.13055360"
  },
  {
     "name":"Guelmim-Oued Noun",
     "isoCode":"10",
     "countryCode":"MA",
     "latitude":"28.48442810",
     "longitude":"-10.08072980"
  },
  {
     "name":"Dakhla-Oued Ed-Dahab",
     "isoCode":"12",
     "countryCode":"MA",
     "latitude":"22.73378920",
     "longitude":"-14.28611160"
  },
  {
     "name":"Jerada Province",
     "isoCode":"JRA",
     "countryCode":"MA",
     "latitude":"34.30617910",
     "longitude":"-2.17941360"
  },
  {
     "name":"Kénitra Province",
     "isoCode":"KEN",
     "countryCode":"MA",
     "latitude":"34.25405030",
     "longitude":"-6.58901660"
  },
  {
     "name":"Kelaat Sraghna Province",
     "isoCode":"KES",
     "countryCode":"MA",
     "latitude":"32.05227670",
     "longitude":"-7.35165580"
  },
  {
     "name":"Chefchaouen Province",
     "isoCode":"CHE",
     "countryCode":"MA",
     "latitude":"35.01817200",
     "longitude":"-5.14320680"
  },
  {
     "name":"Safi Province",
     "isoCode":"SAF",
     "countryCode":"MA",
     "latitude":"32.29898720",
     "longitude":"-9.10134980"
  },
  {
     "name":"Tata Province",
     "isoCode":"TAT",
     "countryCode":"MA",
     "latitude":"29.75087700",
     "longitude":"-7.97563430"
  },
  {
     "name":"Fès-Meknès",
     "isoCode":"03",
     "countryCode":"MA",
     "latitude":"34.06252900",
     "longitude":"-4.72775280"
  },
  {
     "name":"Taroudant Province",
     "isoCode":"TAR",
     "countryCode":"MA",
     "latitude":"30.47271260",
     "longitude":"-8.87487650"
  },
  {
     "name":"Moulay Yacoub Province",
     "isoCode":"MOU",
     "countryCode":"MA",
     "latitude":"34.08744790",
     "longitude":"-5.17840190"
  },
  {
     "name":"Essaouira Province",
     "isoCode":"ESI",
     "countryCode":"MA",
     "latitude":"31.50849260",
     "longitude":"-9.75950410"
  },
  {
     "name":"Khénifra Province",
     "isoCode":"KHN",
     "countryCode":"MA",
     "latitude":"32.93404710",
     "longitude":"-5.66157100"
  },
  {
     "name":"Tétouan Province",
     "isoCode":"TET",
     "countryCode":"MA",
     "latitude":"35.58889950",
     "longitude":"-5.36255160"
  },
  {
     "name":"Oued Ed-Dahab Province",
     "isoCode":"OUD",
     "countryCode":"MA",
     "latitude":"22.73378920",
     "longitude":"-14.28611160"
  },
  {
     "name":"Al Haouz Province",
     "isoCode":"HAO",
     "countryCode":"MA",
     "latitude":"31.29567290",
     "longitude":"-7.87216000"
  },
  {
     "name":"Azilal Province",
     "isoCode":"AZI",
     "countryCode":"MA",
     "latitude":"32.00426200",
     "longitude":"-6.57833870"
  },
  {
     "name":"Taourirt Province",
     "isoCode":"TAI",
     "countryCode":"MA",
     "latitude":"34.21259800",
     "longitude":"-2.69838680"
  },
  {
     "name":"Taounate Province",
     "isoCode":"TAO",
     "countryCode":"MA",
     "latitude":"34.53691700",
     "longitude":"-4.63986930"
  },
  {
     "name":"Tanger-Tétouan-Al Hoceïma",
     "isoCode":"01",
     "countryCode":"MA",
     "latitude":"35.26295580",
     "longitude":"-5.56172790"
  },
  {
     "name":"Ifrane Province",
     "isoCode":"IFR",
     "countryCode":"MA",
     "latitude":"33.52280620",
     "longitude":"-5.11095520"
  },
  {
     "name":"Khouribga Province",
     "isoCode":"KHO",
     "countryCode":"MA",
     "latitude":"32.88602300",
     "longitude":"-6.92086550"
  },
  {
     "name":"Cabo Delgado Province",
     "isoCode":"P",
     "countryCode":"MZ",
     "latitude":"-12.33354740",
     "longitude":"39.32062410"
  },
  {
     "name":"Zambezia Province",
     "isoCode":"Q",
     "countryCode":"MZ",
     "latitude":"-16.56389870",
     "longitude":"36.60939260"
  },
  {
     "name":"Gaza Province",
     "isoCode":"G",
     "countryCode":"MZ",
     "latitude":"-23.02219280",
     "longitude":"32.71813750"
  },
  {
     "name":"Inhambane Province",
     "isoCode":"I",
     "countryCode":"MZ",
     "latitude":"-22.85279970",
     "longitude":"34.55087580"
  },
  {
     "name":"Sofala Province",
     "isoCode":"S",
     "countryCode":"MZ",
     "latitude":"-19.20390730",
     "longitude":"34.86241660"
  },
  {
     "name":"Maputo Province",
     "isoCode":"L",
     "countryCode":"MZ",
     "latitude":"-25.25698760",
     "longitude":"32.53727410"
  },
  {
     "name":"Niassa Province",
     "isoCode":"A",
     "countryCode":"MZ",
     "latitude":"-12.78262020",
     "longitude":"36.60939260"
  },
  {
     "name":"Tete Province",
     "isoCode":"T",
     "countryCode":"MZ",
     "latitude":"-15.65960560",
     "longitude":"32.71813750"
  },
  {
     "name":"Maputo",
     "isoCode":"MPM",
     "countryCode":"MZ",
     "latitude":"-25.96924800",
     "longitude":"32.57317460"
  },
  {
     "name":"Nampula Province",
     "isoCode":"N",
     "countryCode":"MZ",
     "latitude":"-14.76049310",
     "longitude":"39.32062410"
  },
  {
     "name":"Manica Province",
     "isoCode":"B",
     "countryCode":"MZ",
     "latitude":"-19.50597870",
     "longitude":"33.43835300"
  },
  {
     "name":"Hodh Ech Chargui Region",
     "isoCode":"01",
     "countryCode":"MR",
     "latitude":"18.67370260",
     "longitude":"-7.09287700"
  },
  {
     "name":"Brakna Region",
     "isoCode":"05",
     "countryCode":"MR",
     "latitude":"17.23175610",
     "longitude":"-13.17403480"
  },
  {
     "name":"Tiris Zemmour Region",
     "isoCode":"11",
     "countryCode":"MR",
     "latitude":"24.57737640",
     "longitude":"-9.90181310"
  },
  {
     "name":"Gorgol Region",
     "isoCode":"04",
     "countryCode":"MR",
     "latitude":"15.97173570",
     "longitude":"-12.62162110"
  },
  {
     "name":"Inchiri Region",
     "isoCode":"12",
     "countryCode":"MR",
     "latitude":"20.02805610",
     "longitude":"-15.40680790"
  },
  {
     "name":"Nouakchott-Nord Region",
     "isoCode":"14",
     "countryCode":"MR",
     "latitude":"18.11302050",
     "longitude":"-15.89949560"
  },
  {
     "name":"Adrar Region",
     "isoCode":"07",
     "countryCode":"MR",
     "latitude":"19.86521760",
     "longitude":"-12.80547530"
  },
  {
     "name":"Tagant Region",
     "isoCode":"09",
     "countryCode":"MR",
     "latitude":"18.54675270",
     "longitude":"-9.90181310"
  },
  {
     "name":"Dakhlet Nouadhibou",
     "isoCode":"08",
     "countryCode":"MR",
     "latitude":"20.59855880",
     "longitude":"-16.25221430"
  },
  {
     "name":"Nouakchott-Sud Region",
     "isoCode":"15",
     "countryCode":"MR",
     "latitude":"17.97092880",
     "longitude":"-15.94648740"
  },
  {
     "name":"Trarza Region",
     "isoCode":"06",
     "countryCode":"MR",
     "latitude":"17.86649640",
     "longitude":"-14.65878210"
  },
  {
     "name":"Assaba Region",
     "isoCode":"03",
     "countryCode":"MR",
     "latitude":"16.77595580",
     "longitude":"-11.52480550"
  },
  {
     "name":"Guidimaka Region",
     "isoCode":"10",
     "countryCode":"MR",
     "latitude":"15.25573310",
     "longitude":"-12.25479190"
  },
  {
     "name":"Hodh El Gharbi Region",
     "isoCode":"02",
     "countryCode":"MR",
     "latitude":"16.69121490",
     "longitude":"-9.54509740"
  },
  {
     "name":"Nouakchott-Ouest Region",
     "isoCode":"13",
     "countryCode":"MR",
     "latitude":"18.15113570",
     "longitude":"-15.99349100"
  },
  {
     "name":"Western Tobago",
     "isoCode":"WTO",
     "countryCode":"TT",
     "latitude":"11.18970720",
     "longitude":"-60.77954520"
  },
  {
     "name":"Couva-Tabaquite-Talparo Regional Corporation",
     "isoCode":"CTT",
     "countryCode":"TT",
     "latitude":"10.42971450",
     "longitude":"-61.37352100"
  },
  {
     "name":"Eastern Tobago",
     "isoCode":"ETO",
     "countryCode":"TT",
     "latitude":"11.29793480",
     "longitude":"-60.55885240"
  },
  {
     "name":"Rio Claro-Mayaro Regional Corporation",
     "isoCode":"MRC",
     "countryCode":"TT",
     "latitude":"10.24128320",
     "longitude":"-61.09372060"
  },
  {
     "name":"San Juan-Laventille Regional Corporation",
     "isoCode":"SJL",
     "countryCode":"TT",
     "latitude":"10.69085780",
     "longitude":"-61.45522130"
  },
  {
     "name":"Tunapuna-Piarco Regional Corporation",
     "isoCode":"TUP",
     "countryCode":"TT",
     "latitude":"10.68590960",
     "longitude":"-61.30352480"
  },
  {
     "name":"San Fernando",
     "isoCode":"SFO",
     "countryCode":"TT",
     "latitude":"34.28194610",
     "longitude":"-118.43897190"
  },
  {
     "name":"Point Fortin",
     "isoCode":"PTF",
     "countryCode":"TT",
     "latitude":"10.17027370",
     "longitude":"-61.67133860"
  },
  {
     "name":"Sangre Grande Regional Corporation",
     "isoCode":"SGE",
     "countryCode":"TT",
     "latitude":"10.58529390",
     "longitude":"-61.13158130"
  },
  {
     "name":"Arima",
     "isoCode":"ARI",
     "countryCode":"TT",
     "latitude":"46.79316040",
     "longitude":"-71.25843110"
  },
  {
     "name":"Port of Spain",
     "isoCode":"POS",
     "countryCode":"TT",
     "latitude":"10.66031960",
     "longitude":"-61.50856250"
  },
  {
     "name":"Siparia Regional Corporation",
     "isoCode":"SIP",
     "countryCode":"TT",
     "latitude":"10.12456260",
     "longitude":"-61.56032440"
  },
  {
     "name":"Penal-Debe Regional Corporation",
     "isoCode":"PED",
     "countryCode":"TT",
     "latitude":"10.13374020",
     "longitude":"-61.44354740"
  },
  {
     "name":"Chaguanas",
     "isoCode":"CHA",
     "countryCode":"TT",
     "latitude":"10.51683870",
     "longitude":"-61.41144820"
  },
  {
     "name":"Diego Martin Regional Corporation",
     "isoCode":"DMN",
     "countryCode":"TT",
     "latitude":"10.73622860",
     "longitude":"-61.55448360"
  },
  {
     "name":"Princes Town Regional Corporation",
     "isoCode":"PRT",
     "countryCode":"TT",
     "latitude":"10.17867460",
     "longitude":"-61.28019960"
  },
  {
     "name":"Mary Region",
     "isoCode":"M",
     "countryCode":"TM",
     "latitude":"36.94816230",
     "longitude":"62.45041540"
  },
  {
     "name":"Lebap Region",
     "isoCode":"L",
     "countryCode":"TM",
     "latitude":"38.12724620",
     "longitude":"64.71624150"
  },
  {
     "name":"Ashgabat",
     "isoCode":"S",
     "countryCode":"TM",
     "latitude":"37.96007660",
     "longitude":"58.32606290"
  },
  {
     "name":"Balkan Region",
     "isoCode":"B",
     "countryCode":"TM",
     "latitude":"41.81014720",
     "longitude":"21.09373110"
  },
  {
     "name":"Daşoguz Region",
     "isoCode":"D",
     "countryCode":"TM",
     "latitude":"41.83687370",
     "longitude":"59.96519040"
  },
  {
     "name":"Ahal Region",
     "isoCode":"A",
     "countryCode":"TM",
     "latitude":"38.63993980",
     "longitude":"59.47209040"
  },
  {
     "name":"Beni Department",
     "isoCode":"B",
     "countryCode":"BO",
     "latitude":"-14.37827470",
     "longitude":"-65.09577920"
  },
  {
     "name":"Oruro Department",
     "isoCode":"O",
     "countryCode":"BO",
     "latitude":"-18.57115790",
     "longitude":"-67.76159830"
  },
  {
     "name":"Santa Cruz Department",
     "isoCode":"S",
     "countryCode":"BO",
     "latitude":"-16.74760370",
     "longitude":"-62.07509980"
  },
  {
     "name":"Tarija Department",
     "isoCode":"T",
     "countryCode":"BO",
     "latitude":"-21.58315950",
     "longitude":"-63.95861110"
  },
  {
     "name":"Pando Department",
     "isoCode":"N",
     "countryCode":"BO",
     "latitude":"-10.79889010",
     "longitude":"-66.99880110"
  },
  {
     "name":"La Paz Department",
     "isoCode":"L",
     "countryCode":"BO",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Cochabamba Department",
     "isoCode":"C",
     "countryCode":"BO",
     "latitude":"-17.56816750",
     "longitude":"-65.47573600"
  },
  {
     "name":"Chuquisaca Department",
     "isoCode":"H",
     "countryCode":"BO",
     "latitude":"-20.02491440",
     "longitude":"-64.14782360"
  },
  {
     "name":"Potosí Department",
     "isoCode":"P",
     "countryCode":"BO",
     "latitude":"-20.62471300",
     "longitude":"-66.99880110"
  },
  {
     "name":"Saint George Parish",
     "isoCode":"04",
     "countryCode":"VC",
     "latitude":"42.95760900",
     "longitude":"-81.32670500"
  },
  {
     "name":"Saint Patrick Parish",
     "isoCode":"05",
     "countryCode":"VC",
     "latitude":"39.75091860",
     "longitude":"-94.84505560"
  },
  {
     "name":"Saint Andrew Parish",
     "isoCode":"02",
     "countryCode":"VC",
     "latitude":"43.02429990",
     "longitude":"-81.20250000"
  },
  {
     "name":"Saint David Parish",
     "isoCode":"03",
     "countryCode":"VC",
     "latitude":"43.85230950",
     "longitude":"-79.52366540"
  },
  {
     "name":"Grenadines Parish",
     "isoCode":"06",
     "countryCode":"VC",
     "latitude":"13.01229650",
     "longitude":"-61.22773010"
  },
  {
     "name":"Charlotte Parish",
     "isoCode":"01",
     "countryCode":"VC",
     "latitude":"13.21754510",
     "longitude":"-61.16362440"
  },
  {
     "name":"Sharjah Emirate",
     "isoCode":"SH",
     "countryCode":"AE",
     "latitude":"25.07539740",
     "longitude":"55.75784030"
  },
  {
     "name":"Dubai",
     "isoCode":"DU",
     "countryCode":"AE",
     "latitude":"25.20484930",
     "longitude":"55.27078280"
  },
  {
     "name":"Umm al-Quwain",
     "isoCode":"UQ",
     "countryCode":"AE",
     "latitude":"25.54263240",
     "longitude":"55.54753480"
  },
  {
     "name":"Fujairah",
     "isoCode":"FU",
     "countryCode":"AE",
     "latitude":"25.12880990",
     "longitude":"56.32648490"
  },
  {
     "name":"Ras al-Khaimah",
     "isoCode":"RK",
     "countryCode":"AE",
     "latitude":"25.67413430",
     "longitude":"55.98041730"
  },
  {
     "name":"Ajman Emirate",
     "isoCode":"AJ",
     "countryCode":"AE",
     "latitude":"25.40521650",
     "longitude":"55.51364330"
  },
  {
     "name":"Abu Dhabi Emirate",
     "isoCode":"AZ",
     "countryCode":"AE",
     "latitude":"24.45388400",
     "longitude":"54.37734380"
  },
  {
     "name":"districts of Republican Subordination",
     "isoCode":"RA",
     "countryCode":"TJ",
     "latitude":"39.08579020",
     "longitude":"70.24083250"
  },
  {
     "name":"Khatlon Province",
     "isoCode":"KT",
     "countryCode":"TJ",
     "latitude":"37.91135620",
     "longitude":"69.09702300"
  },
  {
     "name":"Gorno-Badakhshan Autonomous Province",
     "isoCode":"GB",
     "countryCode":"TJ",
     "latitude":"38.41273200",
     "longitude":"73.08774900"
  },
  {
     "name":"Sughd Province",
     "isoCode":"SU",
     "countryCode":"TJ",
     "latitude":"39.51553260",
     "longitude":"69.09702300"
  },
  {
     "name":"Tainan County",
     "isoCode":"TNQ",
     "countryCode":"TW",
     "latitude":"22.99972810",
     "longitude":"120.22702770"
  },
  {
     "name":"Yilan County",
     "isoCode":"ILA",
     "countryCode":"TW",
     "latitude":"24.70210730",
     "longitude":"121.73775020"
  },
  {
     "name":"Penghu County",
     "isoCode":"PEN",
     "countryCode":"TW",
     "latitude":"23.57118990",
     "longitude":"119.57931570"
  },
  {
     "name":"Changhua County",
     "isoCode":"CHA",
     "countryCode":"TW",
     "latitude":"24.05179630",
     "longitude":"120.51613520"
  },
  {
     "name":"Pingtung County",
     "isoCode":"PIF",
     "countryCode":"TW",
     "latitude":"22.55197590",
     "longitude":"120.54875970"
  },
  {
     "name":"Taichung",
     "isoCode":"TXG",
     "countryCode":"TW",
     "latitude":"24.14773580",
     "longitude":"120.67364820"
  },
  {
     "name":"Nantou County",
     "isoCode":"NAN",
     "countryCode":"TW",
     "latitude":"23.96099810",
     "longitude":"120.97186380"
  },
  {
     "name":"Chiayi County",
     "isoCode":"CYI",
     "countryCode":"TW",
     "latitude":"23.45184280",
     "longitude":"120.25546150"
  },
  {
     "name":"Kaohsiung County",
     "isoCode":"KHQ",
     "countryCode":"TW",
     "latitude":"22.62727840",
     "longitude":"120.30143530"
  },
  {
     "name":"Taitung County",
     "isoCode":"TTT",
     "countryCode":"TW",
     "latitude":"22.79724470",
     "longitude":"121.07137020"
  },
  {
     "name":"Hualien County",
     "isoCode":"HUA",
     "countryCode":"TW",
     "latitude":"23.98715890",
     "longitude":"121.60157140"
  },
  {
     "name":"Kaohsiung",
     "isoCode":"KHH",
     "countryCode":"TW",
     "latitude":"22.62727840",
     "longitude":"120.30143530"
  },
  {
     "name":"Miaoli County",
     "isoCode":"MIA",
     "countryCode":"TW",
     "latitude":"24.56015900",
     "longitude":"120.82142650"
  },
  {
     "name":"Taichung County",
     "isoCode":"TXQ",
     "countryCode":"TW",
     "latitude":"24.14773580",
     "longitude":"120.67364820"
  },
  {
     "name":"Kinmen",
     "isoCode":"KIN",
     "countryCode":"TW",
     "latitude":"24.34877920",
     "longitude":"118.32856440"
  },
  {
     "name":"Yunlin County",
     "isoCode":"YUN",
     "countryCode":"TW",
     "latitude":"23.70920330",
     "longitude":"120.43133730"
  },
  {
     "name":"Hsinchu",
     "isoCode":"HSZ",
     "countryCode":"TW",
     "latitude":"24.81382870",
     "longitude":"120.96747980"
  },
  {
     "name":"Chiayi City",
     "isoCode":"CYQ",
     "countryCode":"TW",
     "latitude":"23.48007510",
     "longitude":"120.44911130"
  },
  {
     "name":"Taoyuan City",
     "isoCode":"TAO",
     "countryCode":"TW",
     "latitude":"24.99362810",
     "longitude":"121.30097980"
  },
  {
     "name":"Lienchiang County",
     "isoCode":"LIE",
     "countryCode":"TW",
     "latitude":"26.15055560",
     "longitude":"119.92888890"
  },
  {
     "name":"Tainan",
     "isoCode":"TNN",
     "countryCode":"TW",
     "latitude":"22.99972810",
     "longitude":"120.22702770"
  },
  {
     "name":"Taipei",
     "isoCode":"TPE",
     "countryCode":"TW",
     "latitude":"25.03296940",
     "longitude":"121.56541770"
  },
  {
     "name":"Hsinchu County",
     "isoCode":"HSQ",
     "countryCode":"TW",
     "latitude":"24.83872260",
     "longitude":"121.01772460"
  },
  {
     "name":"Northern Red Sea Region",
     "isoCode":"SK",
     "countryCode":"ER",
     "latitude":"16.25839970",
     "longitude":"38.82054540"
  },
  {
     "name":"Anseba Region",
     "isoCode":"AN",
     "countryCode":"ER",
     "latitude":"16.47455310",
     "longitude":"37.80876930"
  },
  {
     "name":"Maekel Region",
     "isoCode":"MA",
     "countryCode":"ER",
     "latitude":"15.35514090",
     "longitude":"38.86236830"
  },
  {
     "name":"Debub Region",
     "isoCode":"DU",
     "countryCode":"ER",
     "latitude":"14.94786920",
     "longitude":"39.15436770"
  },
  {
     "name":"Gash-Barka Region",
     "isoCode":"GB",
     "countryCode":"ER",
     "latitude":"15.40688250",
     "longitude":"37.63866220"
  },
  {
     "name":"Southern Red Sea Region",
     "isoCode":"DK",
     "countryCode":"ER",
     "latitude":"13.51371030",
     "longitude":"41.76064720"
  },
  {
     "name":"Southern Peninsula Region",
     "isoCode":"2",
     "countryCode":"IS",
     "latitude":"63.91548030",
     "longitude":"-22.36496670"
  },
  {
     "name":"Capital Region",
     "isoCode":"1",
     "countryCode":"IS",
     "latitude":"38.56569570",
     "longitude":"-92.18169490"
  },
  {
     "name":"Westfjords",
     "isoCode":"4",
     "countryCode":"IS",
     "latitude":"65.91961500",
     "longitude":"-21.88117640"
  },
  {
     "name":"Eastern Region",
     "isoCode":"7",
     "countryCode":"IS",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Southern Region",
     "isoCode":"8",
     "countryCode":"IS",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Northwestern Region",
     "isoCode":"5",
     "countryCode":"IS",
     "latitude":"41.91339320",
     "longitude":"-73.04716880"
  },
  {
     "name":"Western Region",
     "isoCode":"3",
     "countryCode":"IS",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Northeastern Region",
     "isoCode":"6",
     "countryCode":"IS",
     "latitude":"43.29942850",
     "longitude":"-74.21793260"
  },
  {
     "name":"Río Muni",
     "isoCode":"C",
     "countryCode":"GQ",
     "latitude":"1.46106060",
     "longitude":"9.67868940"
  },
  {
     "name":"Kié-Ntem Province",
     "isoCode":"KN",
     "countryCode":"GQ",
     "latitude":"2.02809300",
     "longitude":"11.07117580"
  },
  {
     "name":"Wele-Nzas Province",
     "isoCode":"WN",
     "countryCode":"GQ",
     "latitude":"1.41661620",
     "longitude":"11.07117580"
  },
  {
     "name":"Litoral Province",
     "isoCode":"LI",
     "countryCode":"GQ",
     "latitude":"1.57502440",
     "longitude":"9.81249350"
  },
  {
     "name":"Insular Region",
     "isoCode":"I",
     "countryCode":"GQ",
     "latitude":"37.09024000",
     "longitude":"-95.71289100"
  },
  {
     "name":"Bioko Sur Province",
     "isoCode":"BS",
     "countryCode":"GQ",
     "latitude":"3.42097850",
     "longitude":"8.61606740"
  },
  {
     "name":"Annobón Province",
     "isoCode":"AN",
     "countryCode":"GQ",
     "latitude":"-1.42687820",
     "longitude":"5.63528010"
  },
  {
     "name":"Centro Sur Province",
     "isoCode":"CS",
     "countryCode":"GQ",
     "latitude":"1.34360840",
     "longitude":"10.43965600"
  },
  {
     "name":"Bioko Norte Province",
     "isoCode":"BN",
     "countryCode":"GQ",
     "latitude":"3.65950720",
     "longitude":"8.79218360"
  },
  {
     "name":"Chihuahua",
     "isoCode":"CHH",
     "countryCode":"MX",
     "latitude":"28.63299570",
     "longitude":"-106.06910040"
  },
  {
     "name":"Oaxaca",
     "isoCode":"OAX",
     "countryCode":"MX",
     "latitude":"17.07318420",
     "longitude":"-96.72658890"
  },
  {
     "name":"Sinaloa",
     "isoCode":"SIN",
     "countryCode":"MX",
     "latitude":"25.17210910",
     "longitude":"-107.47951730"
  },
  {
     "name":"México",
     "isoCode":"MEX",
     "countryCode":"MX",
     "latitude":"23.63450100",
     "longitude":"-102.55278400"
  },
  {
     "name":"Chiapas",
     "isoCode":"CHP",
     "countryCode":"MX",
     "latitude":"16.75693180",
     "longitude":"-93.12923530"
  },
  {
     "name":"Nuevo León",
     "isoCode":"NLE",
     "countryCode":"MX",
     "latitude":"25.59217200",
     "longitude":"-99.99619470"
  },
  {
     "name":"Durango",
     "isoCode":"DUR",
     "countryCode":"MX",
     "latitude":"37.27528000",
     "longitude":"-107.88006670"
  },
  {
     "name":"Tabasco",
     "isoCode":"TAB",
     "countryCode":"MX",
     "latitude":"17.84091730",
     "longitude":"-92.61892730"
  },
  {
     "name":"Querétaro",
     "isoCode":"QUE",
     "countryCode":"MX",
     "latitude":"20.58879320",
     "longitude":"-100.38988810"
  },
  {
     "name":"Aguascalientes",
     "isoCode":"AGU",
     "countryCode":"MX",
     "latitude":"21.88525620",
     "longitude":"-102.29156770"
  },
  {
     "name":"Baja California",
     "isoCode":"BCN",
     "countryCode":"MX",
     "latitude":"30.84063380",
     "longitude":"-115.28375850"
  },
  {
     "name":"Tlaxcala",
     "isoCode":"TLA",
     "countryCode":"MX",
     "latitude":"19.31815400",
     "longitude":"-98.23749540"
  },
  {
     "name":"Guerrero",
     "isoCode":"GRO",
     "countryCode":"MX",
     "latitude":"17.43919260",
     "longitude":"-99.54509740"
  },
  {
     "name":"Baja California Sur",
     "isoCode":"BCS",
     "countryCode":"MX",
     "latitude":"26.04444460",
     "longitude":"-111.66607250"
  },
  {
     "name":"San Luis Potosí",
     "isoCode":"SLP",
     "countryCode":"MX",
     "latitude":"22.15646990",
     "longitude":"-100.98554090"
  },
  {
     "name":"Zacatecas",
     "isoCode":"ZAC",
     "countryCode":"MX",
     "latitude":"22.77085550",
     "longitude":"-102.58324260"
  },
  {
     "name":"Tamaulipas",
     "isoCode":"TAM",
     "countryCode":"MX",
     "latitude":"24.26694000",
     "longitude":"-98.83627550"
  },
  {
     "name":"Veracruz",
     "isoCode":"VER",
     "countryCode":"MX",
     "latitude":"19.17377300",
     "longitude":"-96.13422410"
  },
  {
     "name":"Morelos",
     "isoCode":"MOR",
     "countryCode":"MX",
     "latitude":"18.68130490",
     "longitude":"-99.10134980"
  },
  {
     "name":"Yucatán",
     "isoCode":"YUC",
     "countryCode":"MX",
     "latitude":"20.70987860",
     "longitude":"-89.09433770"
  },
  {
     "name":"Quintana Roo",
     "isoCode":"ROO",
     "countryCode":"MX",
     "latitude":"19.18173930",
     "longitude":"-88.47913760"
  },
  {
     "name":"Sonora",
     "isoCode":"SON",
     "countryCode":"MX",
     "latitude":"37.98294960",
     "longitude":"-120.38217240"
  },
  {
     "name":"Guanajuato",
     "isoCode":"GUA",
     "countryCode":"MX",
     "latitude":"21.01901450",
     "longitude":"-101.25735860"
  },
  {
     "name":"Hidalgo",
     "isoCode":"HID",
     "countryCode":"MX",
     "latitude":"26.10035470",
     "longitude":"-98.26306840"
  },
  {
     "name":"Coahuila",
     "isoCode":"COA",
     "countryCode":"MX",
     "latitude":"27.05867600",
     "longitude":"-101.70682940"
  },
  {
     "name":"Colima",
     "isoCode":"COL",
     "countryCode":"MX",
     "latitude":"19.24523420",
     "longitude":"-103.72408680"
  },
  {
     "name":"Mexico City",
     "isoCode":"CMX",
     "countryCode":"MX",
     "latitude":"19.43260770",
     "longitude":"-99.13320800"
  },
  {
     "name":"Michoacán",
     "isoCode":"MIC",
     "countryCode":"MX",
     "latitude":"19.56651920",
     "longitude":"-101.70682940"
  },
  {
     "name":"Campeche",
     "isoCode":"CAM",
     "countryCode":"MX",
     "latitude":"19.83012510",
     "longitude":"-90.53490870"
  },
  {
     "name":"Puebla",
     "isoCode":"PUE",
     "countryCode":"MX",
     "latitude":"19.04143980",
     "longitude":"-98.20627270"
  },
  {
     "name":"Nayarit",
     "isoCode":"NAY",
     "countryCode":"MX",
     "latitude":"21.75138440",
     "longitude":"-104.84546190"
  },
  {
     "name":"Krabi",
     "isoCode":"81",
     "countryCode":"TH",
     "latitude":"8.08629970",
     "longitude":"98.90628350"
  },
  {
     "name":"Ranong",
     "isoCode":"85",
     "countryCode":"TH",
     "latitude":"9.95287020",
     "longitude":"98.60846410"
  },
  {
     "name":"Nong Bua Lam Phu",
     "isoCode":"39",
     "countryCode":"TH",
     "latitude":"17.22182470",
     "longitude":"102.42603680"
  },
  {
     "name":"Samut Prakan",
     "isoCode":"11",
     "countryCode":"TH",
     "latitude":"13.59909610",
     "longitude":"100.59983190"
  },
  {
     "name":"Surat Thani",
     "isoCode":"84",
     "countryCode":"TH",
     "latitude":"9.13419490",
     "longitude":"99.33341980"
  },
  {
     "name":"Lamphun",
     "isoCode":"51",
     "countryCode":"TH",
     "latitude":"18.57446060",
     "longitude":"99.00872210"
  },
  {
     "name":"Nong Khai",
     "isoCode":"43",
     "countryCode":"TH",
     "latitude":"17.87828030",
     "longitude":"102.74126380"
  },
  {
     "name":"Khon Kaen",
     "isoCode":"40",
     "countryCode":"TH",
     "latitude":"16.43219380",
     "longitude":"102.82362140"
  },
  {
     "name":"Chanthaburi",
     "isoCode":"22",
     "countryCode":"TH",
     "latitude":"12.61124850",
     "longitude":"102.10378060"
  },
  {
     "name":"Saraburi",
     "isoCode":"19",
     "countryCode":"TH",
     "latitude":"14.52891540",
     "longitude":"100.91014210"
  },
  {
     "name":"Phatthalung",
     "isoCode":"93",
     "countryCode":"TH",
     "latitude":"7.61668230",
     "longitude":"100.07402310"
  },
  {
     "name":"Uttaradit",
     "isoCode":"53",
     "countryCode":"TH",
     "latitude":"17.62008860",
     "longitude":"100.09929420"
  },
  {
     "name":"Sing Buri",
     "isoCode":"17",
     "countryCode":"TH",
     "latitude":"14.89362530",
     "longitude":"100.39673140"
  },
  {
     "name":"Chiang Mai",
     "isoCode":"50",
     "countryCode":"TH",
     "latitude":"18.78834390",
     "longitude":"98.98530080"
  },
  {
     "name":"Nakhon Sawan",
     "isoCode":"60",
     "countryCode":"TH",
     "latitude":"15.69873820",
     "longitude":"100.11996000"
  },
  {
     "name":"Yala",
     "isoCode":"95",
     "countryCode":"TH",
     "latitude":"44.05791170",
     "longitude":"-123.16538480"
  },
  {
     "name":"Phra Nakhon Si Ayutthaya",
     "isoCode":"14",
     "countryCode":"TH",
     "latitude":"14.36923250",
     "longitude":"100.58766340"
  },
  {
     "name":"Nonthaburi",
     "isoCode":"12",
     "countryCode":"TH",
     "latitude":"13.85910840",
     "longitude":"100.52165080"
  },
  {
     "name":"Trat",
     "isoCode":"23",
     "countryCode":"TH",
     "latitude":"12.24275630",
     "longitude":"102.51747340"
  },
  {
     "name":"Nakhon Ratchasima",
     "isoCode":"30",
     "countryCode":"TH",
     "latitude":"14.97384930",
     "longitude":"102.08365200"
  },
  {
     "name":"Chiang Rai",
     "isoCode":"57",
     "countryCode":"TH",
     "latitude":"19.91047980",
     "longitude":"99.84057600"
  },
  {
     "name":"Ratchaburi",
     "isoCode":"70",
     "countryCode":"TH",
     "latitude":"13.52828930",
     "longitude":"99.81342110"
  },
  {
     "name":"Pathum Thani",
     "isoCode":"13",
     "countryCode":"TH",
     "latitude":"14.02083910",
     "longitude":"100.52502760"
  },
  {
     "name":"Sakon Nakhon",
     "isoCode":"47",
     "countryCode":"TH",
     "latitude":"17.16642110",
     "longitude":"104.14860550"
  },
  {
     "name":"Samut Songkhram",
     "isoCode":"75",
     "countryCode":"TH",
     "latitude":"13.40982170",
     "longitude":"100.00226450"
  },
  {
     "name":"Nakhon Pathom",
     "isoCode":"73",
     "countryCode":"TH",
     "latitude":"13.81402930",
     "longitude":"100.03729290"
  },
  {
     "name":"Samut Sakhon",
     "isoCode":"74",
     "countryCode":"TH",
     "latitude":"13.54752160",
     "longitude":"100.27439560"
  },
  {
     "name":"Mae Hong Son",
     "isoCode":"58",
     "countryCode":"TH",
     "latitude":"19.30202960",
     "longitude":"97.96543680"
  },
  {
     "name":"Phitsanulok",
     "isoCode":"65",
     "countryCode":"TH",
     "latitude":"16.82112380",
     "longitude":"100.26585160"
  },
  {
     "name":"Pattaya",
     "isoCode":"S",
     "countryCode":"TH",
     "latitude":"12.92355570",
     "longitude":"100.88245510"
  },
  {
     "name":"Prachuap Khiri Khan",
     "isoCode":"77",
     "countryCode":"TH",
     "latitude":"11.79383890",
     "longitude":"99.79575640"
  },
  {
     "name":"Loei",
     "isoCode":"42",
     "countryCode":"TH",
     "latitude":"17.48602320",
     "longitude":"101.72230020"
  },
  {
     "name":"Roi Et",
     "isoCode":"45",
     "countryCode":"TH",
     "latitude":"16.05381960",
     "longitude":"103.65200360"
  },
  {
     "name":"Kanchanaburi",
     "isoCode":"71",
     "countryCode":"TH",
     "latitude":"14.10113930",
     "longitude":"99.41794310"
  },
  {
     "name":"Ubon Ratchathani",
     "isoCode":"34",
     "countryCode":"TH",
     "latitude":"15.24484530",
     "longitude":"104.84729950"
  },
  {
     "name":"Chon Buri",
     "isoCode":"20",
     "countryCode":"TH",
     "latitude":"13.36114310",
     "longitude":"100.98467170"
  },
  {
     "name":"Phichit",
     "isoCode":"66",
     "countryCode":"TH",
     "latitude":"16.27408760",
     "longitude":"100.33469910"
  },
  {
     "name":"Phetchabun",
     "isoCode":"67",
     "countryCode":"TH",
     "latitude":"16.30166900",
     "longitude":"101.11928040"
  },
  {
     "name":"Kamphaeng Phet",
     "isoCode":"62",
     "countryCode":"TH",
     "latitude":"16.48277980",
     "longitude":"99.52266180"
  },
  {
     "name":"Maha Sarakham",
     "isoCode":"44",
     "countryCode":"TH",
     "latitude":"16.01320150",
     "longitude":"103.16151690"
  },
  {
     "name":"Rayong",
     "isoCode":"21",
     "countryCode":"TH",
     "latitude":"12.68139570",
     "longitude":"101.28162610"
  },
  {
     "name":"Ang Thong",
     "isoCode":"15",
     "countryCode":"TH",
     "latitude":"14.58960540",
     "longitude":"100.45505200"
  },
  {
     "name":"Nakhon Si Thammarat",
     "isoCode":"80",
     "countryCode":"TH",
     "latitude":"8.43248310",
     "longitude":"99.95990330"
  },
  {
     "name":"Yasothon",
     "isoCode":"35",
     "countryCode":"TH",
     "latitude":"15.79264100",
     "longitude":"104.14528270"
  },
  {
     "name":"Chai Nat",
     "isoCode":"18",
     "countryCode":"TH",
     "latitude":"15.18519710",
     "longitude":"100.12512500"
  },
  {
     "name":"Amnat Charoen",
     "isoCode":"37",
     "countryCode":"TH",
     "latitude":"15.86567830",
     "longitude":"104.62577740"
  },
  {
     "name":"Suphanburi",
     "isoCode":"72",
     "countryCode":"TH",
     "latitude":"14.47448920",
     "longitude":"100.11771280"
  },
  {
     "name":"Tak",
     "isoCode":"63",
     "countryCode":"TH",
     "latitude":"45.02996460",
     "longitude":"-93.10498150"
  },
  {
     "name":"Chumphon",
     "isoCode":"86",
     "countryCode":"TH",
     "latitude":"10.49304960",
     "longitude":"99.18001990"
  },
  {
     "name":"Udon Thani",
     "isoCode":"41",
     "countryCode":"TH",
     "latitude":"17.36469690",
     "longitude":"102.81589240"
  },
  {
     "name":"Phrae",
     "isoCode":"54",
     "countryCode":"TH",
     "latitude":"18.14457740",
     "longitude":"100.14028310"
  },
  {
     "name":"Sa Kaeo",
     "isoCode":"27",
     "countryCode":"TH",
     "latitude":"13.82403800",
     "longitude":"102.06458390"
  },
  {
     "name":"Nan",
     "isoCode":"55",
     "countryCode":"TH",
     "latitude":"45.52220800",
     "longitude":"-122.98632810"
  },
  {
     "name":"Surin",
     "isoCode":"32",
     "countryCode":"TH",
     "latitude":"37.03582710",
     "longitude":"-95.62763670"
  },
  {
     "name":"Phetchaburi",
     "isoCode":"76",
     "countryCode":"TH",
     "latitude":"12.96492150",
     "longitude":"99.64258830"
  },
  {
     "name":"Bueng Kan",
     "isoCode":"38",
     "countryCode":"TH",
     "latitude":"18.36091040",
     "longitude":"103.64644630"
  },
  {
     "name":"Buri Ram",
     "isoCode":"31",
     "countryCode":"TH",
     "latitude":"14.99510030",
     "longitude":"103.11159150"
  },
  {
     "name":"Nakhon Nayok",
     "isoCode":"26",
     "countryCode":"TH",
     "latitude":"14.20694660",
     "longitude":"101.21305110"
  },
  {
     "name":"Phuket",
     "isoCode":"83",
     "countryCode":"TH",
     "latitude":"7.88044790",
     "longitude":"98.39225040"
  },
  {
     "name":"Satun",
     "isoCode":"91",
     "countryCode":"TH",
     "latitude":"6.62381580",
     "longitude":"100.06737440"
  },
  {
     "name":"Phayao",
     "isoCode":"56",
     "countryCode":"TH",
     "latitude":"19.21543670",
     "longitude":"100.20236920"
  },
  {
     "name":"Songkhla",
     "isoCode":"90",
     "countryCode":"TH",
     "latitude":"7.18976590",
     "longitude":"100.59538130"
  },
  {
     "name":"Pattani",
     "isoCode":"94",
     "countryCode":"TH",
     "latitude":"6.76183080",
     "longitude":"101.32325490"
  },
  {
     "name":"Trang",
     "isoCode":"92",
     "countryCode":"TH",
     "latitude":"7.56448330",
     "longitude":"99.62393340"
  },
  {
     "name":"Prachin Buri",
     "isoCode":"25",
     "countryCode":"TH",
     "latitude":"14.04206990",
     "longitude":"101.66008740"
  },
  {
     "name":"Lopburi",
     "isoCode":"16",
     "countryCode":"TH",
     "latitude":"14.79950810",
     "longitude":"100.65337060"
  },
  {
     "name":"Lampang",
     "isoCode":"52",
     "countryCode":"TH",
     "latitude":"18.28553950",
     "longitude":"99.51278950"
  },
  {
     "name":"Sukhothai",
     "isoCode":"64",
     "countryCode":"TH",
     "latitude":"43.64855560",
     "longitude":"-79.37466390"
  },
  {
     "name":"Mukdahan",
     "isoCode":"49",
     "countryCode":"TH",
     "latitude":"16.54359140",
     "longitude":"104.70241210"
  },
  {
     "name":"Si Sa Ket",
     "isoCode":"33",
     "countryCode":"TH",
     "latitude":"15.11860090",
     "longitude":"104.32200950"
  },
  {
     "name":"Nakhon Phanom",
     "isoCode":"48",
     "countryCode":"TH",
     "latitude":"17.39203900",
     "longitude":"104.76955080"
  },
  {
     "name":"Phang Nga",
     "isoCode":"82",
     "countryCode":"TH",
     "latitude":"8.45014140",
     "longitude":"98.52553170"
  },
  {
     "name":"Kalasin",
     "isoCode":"46",
     "countryCode":"TH",
     "latitude":"16.43850800",
     "longitude":"103.50609940"
  },
  {
     "name":"Uthai Thani",
     "isoCode":"61",
     "countryCode":"TH",
     "latitude":"15.38350010",
     "longitude":"100.02455270"
  },
  {
     "name":"Chachoengsao",
     "isoCode":"24",
     "countryCode":"TH",
     "latitude":"13.69041940",
     "longitude":"101.07795960"
  },
  {
     "name":"Narathiwat",
     "isoCode":"96",
     "countryCode":"TH",
     "latitude":"6.42546070",
     "longitude":"101.82531430"
  },
  {
     "name":"Bangkok",
     "isoCode":"10",
     "countryCode":"TH",
     "latitude":"13.75633090",
     "longitude":"100.50176510"
  },
  {
     "name":"Hiiu County",
     "isoCode":"39",
     "countryCode":"EE",
     "latitude":"58.92395530",
     "longitude":"22.59194680"
  },
  {
     "name":"Viljandi County",
     "isoCode":"84",
     "countryCode":"EE",
     "latitude":"58.28217460",
     "longitude":"25.57522330"
  },
  {
     "name":"Tartu County",
     "isoCode":"78",
     "countryCode":"EE",
     "latitude":"58.40571280",
     "longitude":"26.80157600"
  },
  {
     "name":"Valga County",
     "isoCode":"82",
     "countryCode":"EE",
     "latitude":"57.91034410",
     "longitude":"26.16018190"
  },
  {
     "name":"Rapla County",
     "isoCode":"70",
     "countryCode":"EE",
     "latitude":"58.84926250",
     "longitude":"24.73465690"
  },
  {
     "name":"Võru County",
     "isoCode":"86",
     "countryCode":"EE",
     "latitude":"57.73773720",
     "longitude":"27.13989380"
  },
  {
     "name":"Saare County",
     "isoCode":"74",
     "countryCode":"EE",
     "latitude":"58.48497210",
     "longitude":"22.61364080"
  },
  {
     "name":"Pärnu County",
     "isoCode":"67",
     "countryCode":"EE",
     "latitude":"58.52619520",
     "longitude":"24.40201590"
  },
  {
     "name":"Põlva County",
     "isoCode":"65",
     "countryCode":"EE",
     "latitude":"58.11606220",
     "longitude":"27.20663940"
  },
  {
     "name":"Lääne-Viru County",
     "isoCode":"59",
     "countryCode":"EE",
     "latitude":"59.30188160",
     "longitude":"26.32803120"
  },
  {
     "name":"Jõgeva County",
     "isoCode":"49",
     "countryCode":"EE",
     "latitude":"58.75061430",
     "longitude":"26.36048780"
  },
  {
     "name":"Järva County",
     "isoCode":"51",
     "countryCode":"EE",
     "latitude":"58.88667130",
     "longitude":"25.50006240"
  },
  {
     "name":"Harju County",
     "isoCode":"37",
     "countryCode":"EE",
     "latitude":"59.33342390",
     "longitude":"25.24669740"
  },
  {
     "name":"Lääne County",
     "isoCode":"57",
     "countryCode":"EE",
     "latitude":"58.97227420",
     "longitude":"23.87408340"
  },
  {
     "name":"Ida-Viru County",
     "isoCode":"44",
     "countryCode":"EE",
     "latitude":"59.25926630",
     "longitude":"27.41365350"
  },
  {
     "name":"Moyen-Chari Region",
     "isoCode":"MC",
     "countryCode":"TD",
     "latitude":"9.06399980",
     "longitude":"18.42760470"
  },
  {
     "name":"Mayo-Kebbi Ouest Region",
     "isoCode":"MO",
     "countryCode":"TD",
     "latitude":"10.41130140",
     "longitude":"15.59433880"
  },
  {
     "name":"Sila Region",
     "isoCode":"SI",
     "countryCode":"TD",
     "latitude":"12.13074000",
     "longitude":"21.28450250"
  },
  {
     "name":"Hadjer-Lamis",
     "isoCode":"HL",
     "countryCode":"TD",
     "latitude":"12.45772730",
     "longitude":"16.72346390"
  },
  {
     "name":"Borkou",
     "isoCode":"BO",
     "countryCode":"TD",
     "latitude":"17.86888450",
     "longitude":"18.80761950"
  },
  {
     "name":"Ennedi-Est",
     "isoCode":"EE",
     "countryCode":"TD",
     "latitude":"16.34204960",
     "longitude":"23.00119890"
  },
  {
     "name":"Guéra Region",
     "isoCode":"GR",
     "countryCode":"TD",
     "latitude":"11.12190150",
     "longitude":"18.42760470"
  },
  {
     "name":"Lac Region",
     "isoCode":"LC",
     "countryCode":"TD",
     "latitude":"13.69153770",
     "longitude":"14.10013260"
  },
  {
     "name":"Ennedi Region",
     "isoCode":"EN",
     "countryCode":"TD",
     "latitude":"17.54145780",
     "longitude":"21.85685860"
  },
  {
     "name":"Tandjilé Region",
     "isoCode":"TA",
     "countryCode":"TD",
     "latitude":"9.66257290",
     "longitude":"16.72346390"
  },
  {
     "name":"Mayo-Kebbi Est Region",
     "isoCode":"ME",
     "countryCode":"TD",
     "latitude":"9.40460390",
     "longitude":"14.84546190"
  },
  {
     "name":"Wadi Fira Region",
     "isoCode":"WF",
     "countryCode":"TD",
     "latitude":"15.08924160",
     "longitude":"21.47528510"
  },
  {
     "name":"Ouaddaï Region",
     "isoCode":"OD",
     "countryCode":"TD",
     "latitude":"13.74847600",
     "longitude":"20.71224650"
  },
  {
     "name":"Bahr el Gazel",
     "isoCode":"BG",
     "countryCode":"TD",
     "latitude":"14.77022660",
     "longitude":"16.91225100"
  },
  {
     "name":"Ennedi-Ouest",
     "isoCode":"EO",
     "countryCode":"TD",
     "latitude":"18.97756300",
     "longitude":"21.85685860"
  },
  {
     "name":"Logone Occidental Region",
     "isoCode":"LO",
     "countryCode":"TD",
     "latitude":"8.75967600",
     "longitude":"15.87600400"
  },
  {
     "name":"N'Djamena",
     "isoCode":"ND",
     "countryCode":"TD",
     "latitude":"12.13484570",
     "longitude":"15.05574150"
  },
  {
     "name":"Tibesti Region",
     "isoCode":"TI",
     "countryCode":"TD",
     "latitude":"21.36500310",
     "longitude":"16.91225100"
  },
  {
     "name":"Kanem Region",
     "isoCode":"KA",
     "countryCode":"TD",
     "latitude":"14.87812620",
     "longitude":"15.40680790"
  },
  {
     "name":"Mandoul Region",
     "isoCode":"MA",
     "countryCode":"TD",
     "latitude":"8.60309100",
     "longitude":"17.47951730"
  },
  {
     "name":"Batha Region",
     "isoCode":"BA",
     "countryCode":"TD",
     "latitude":"13.93717750",
     "longitude":"18.42760470"
  },
  {
     "name":"Logone Oriental Region",
     "isoCode":"LR",
     "countryCode":"TD",
     "latitude":"8.31499490",
     "longitude":"16.34637910"
  },
  {
     "name":"Salamat Region",
     "isoCode":"SA",
     "countryCode":"TD",
     "latitude":"10.96916010",
     "longitude":"20.71224650"
  },
  {
     "name":"Berry Islands",
     "isoCode":"BY",
     "countryCode":"BS",
     "latitude":"25.62500420",
     "longitude":"-77.82522030"
  },
  {
     "name":"Nichollstown and Berry Islands",
     "isoCode":"NB",
     "countryCode":"BS",
     "latitude":"25.72362340",
     "longitude":"-77.83101040"
  },
  {
     "name":"Green Turtle Cay",
     "isoCode":"GT",
     "countryCode":"BS",
     "latitude":"26.77471070",
     "longitude":"-77.32957080"
  },
  {
     "name":"Central Eleuthera",
     "isoCode":"CE",
     "countryCode":"BS",
     "latitude":"25.13620370",
     "longitude":"-76.14359150"
  },
  {
     "name":"Governor's Harbour",
     "isoCode":"GH",
     "countryCode":"BS",
     "latitude":"25.19480960",
     "longitude":"-76.24396220"
  },
  {
     "name":"High Rock",
     "isoCode":"HR",
     "countryCode":"BS",
     "latitude":"46.68434150",
     "longitude":"-121.90174610"
  },
  {
     "name":"West Grand Bahama",
     "isoCode":"WG",
     "countryCode":"BS",
     "latitude":"26.65944700",
     "longitude":"-78.52065000"
  },
  {
     "name":"Rum Cay District",
     "isoCode":"RC",
     "countryCode":"BS",
     "latitude":"23.68546760",
     "longitude":"-74.83901620"
  },
  {
     "name":"Acklins",
     "isoCode":"AK",
     "countryCode":"BS",
     "latitude":"22.36577080",
     "longitude":"-74.05351260"
  },
  {
     "name":"North Eleuthera",
     "isoCode":"NE",
     "countryCode":"BS",
     "latitude":"25.46475170",
     "longitude":"-76.67592200"
  },
  {
     "name":"Central Abaco",
     "isoCode":"CO",
     "countryCode":"BS",
     "latitude":"26.35550290",
     "longitude":"-77.14851630"
  },
  {
     "name":"Marsh Harbour",
     "isoCode":"MH",
     "countryCode":"BS",
     "latitude":"26.52416530",
     "longitude":"-77.09098090"
  },
  {
     "name":"Black Point",
     "isoCode":"BP",
     "countryCode":"BS",
     "latitude":"41.39510240",
     "longitude":"-71.46505560"
  },
  {
     "name":"Sandy Point",
     "isoCode":"SP",
     "countryCode":"BS",
     "latitude":"39.01454640",
     "longitude":"-76.39989250"
  },
  {
     "name":"South Eleuthera",
     "isoCode":"SE",
     "countryCode":"BS",
     "latitude":"24.77085620",
     "longitude":"-76.21314740"
  },
  {
     "name":"South Abaco",
     "isoCode":"SO",
     "countryCode":"BS",
     "latitude":"26.06405910",
     "longitude":"-77.26350380"
  },
  {
     "name":"Inagua",
     "isoCode":"IN",
     "countryCode":"BS",
     "latitude":"21.06560660",
     "longitude":"-73.32370800"
  },
  {
     "name":"Long Island",
     "isoCode":"LI",
     "countryCode":"BS",
     "latitude":"40.78914200",
     "longitude":"-73.13496100"
  },
  {
     "name":"Cat Island",
     "isoCode":"CI",
     "countryCode":"BS",
     "latitude":"30.22801360",
     "longitude":"-89.10149330"
  },
  {
     "name":"Exuma",
     "isoCode":"EX",
     "countryCode":"BS",
     "latitude":"23.61925980",
     "longitude":"-75.96954650"
  },
  {
     "name":"Harbour Island",
     "isoCode":"HI",
     "countryCode":"BS",
     "latitude":"25.50011000",
     "longitude":"-76.63405110"
  },
  {
     "name":"East Grand Bahama",
     "isoCode":"EG",
     "countryCode":"BS",
     "latitude":"26.65828230",
     "longitude":"-78.22482910"
  },
  {
     "name":"Ragged Island",
     "isoCode":"RI",
     "countryCode":"BS",
     "latitude":"41.59743100",
     "longitude":"-71.26020200"
  },
  {
     "name":"North Abaco",
     "isoCode":"NO",
     "countryCode":"BS",
     "latitude":"26.78716970",
     "longitude":"-77.43577390"
  },
  {
     "name":"North Andros",
     "isoCode":"NS",
     "countryCode":"BS",
     "latitude":"24.70638050",
     "longitude":"-78.01953870"
  },
  {
     "name":"Kemps Bay",
     "isoCode":"KB",
     "countryCode":"BS",
     "latitude":"24.02364000",
     "longitude":"-77.54534900"
  },
  {
     "name":"Fresh Creek",
     "isoCode":"FC",
     "countryCode":"BS",
     "latitude":"40.65437560",
     "longitude":"-73.89479390"
  },
  {
     "name":"San Salvador and Rum Cay",
     "isoCode":"SR",
     "countryCode":"BS",
     "latitude":"23.68546760",
     "longitude":"-74.83901620"
  },
  {
     "name":"Crooked Island",
     "isoCode":"CK",
     "countryCode":"BS",
     "latitude":"22.63909820",
     "longitude":"-74.00650900"
  },
  {
     "name":"South Andros",
     "isoCode":"SA",
     "countryCode":"BS",
     "latitude":"23.97135560",
     "longitude":"-77.60778650"
  },
  {
     "name":"Rock Sound",
     "isoCode":"RS",
     "countryCode":"BS",
     "latitude":"39.01424430",
     "longitude":"-95.67089890"
  },
  {
     "name":"Hope Town",
     "isoCode":"HT",
     "countryCode":"BS",
     "latitude":"26.50095040",
     "longitude":"-76.99598720"
  },
  {
     "name":"Mangrove Cay",
     "isoCode":"MC",
     "countryCode":"BS",
     "latitude":"24.14814250",
     "longitude":"-77.76809520"
  },
  {
     "name":"Freeport",
     "isoCode":"FP",
     "countryCode":"BS",
     "latitude":"42.29668610",
     "longitude":"-89.62122710"
  },
  {
     "name":"San Salvador Island",
     "isoCode":"SS",
     "countryCode":"BS",
     "latitude":"24.07755460",
     "longitude":"-74.47600880"
  },
  {
     "name":"Acklins and Crooked Islands",
     "isoCode":"AC",
     "countryCode":"BS",
     "latitude":"22.36577080",
     "longitude":"-74.05351260"
  },
  {
     "name":"Bimini",
     "isoCode":"BI",
     "countryCode":"BS",
     "latitude":"24.64153250",
     "longitude":"-79.85062260"
  },
  {
     "name":"Spanish Wells",
     "isoCode":"SW",
     "countryCode":"BS",
     "latitude":"26.32505990",
     "longitude":"-81.79803280"
  },
  {
     "name":"Central Andros",
     "isoCode":"CS",
     "countryCode":"BS",
     "latitude":"24.46884820",
     "longitude":"-77.97386500"
  },
  {
     "name":"Grand Cay",
     "isoCode":"GC",
     "countryCode":"BS",
     "latitude":"27.21626150",
     "longitude":"-78.32305590"
  },
  {
     "name":"Mayaguana District",
     "isoCode":"MG",
     "countryCode":"BS",
     "latitude":"22.40177140",
     "longitude":"-73.06413960"
  },
  {
     "name":"San Juan Province",
     "isoCode":"J",
     "countryCode":"AR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Santiago del Estero Province",
     "isoCode":"G",
     "countryCode":"AR",
     "latitude":"-27.78335740",
     "longitude":"-64.26416700"
  },
  {
     "name":"San Luis Province",
     "isoCode":"D",
     "countryCode":"AR",
     "latitude":"-33.29620420",
     "longitude":"-66.32949480"
  },
  {
     "name":"Tucumán Province",
     "isoCode":"T",
     "countryCode":"AR",
     "latitude":"-26.82211270",
     "longitude":"-65.21929030"
  },
  {
     "name":"Corrientes",
     "isoCode":"W",
     "countryCode":"AR",
     "latitude":"-27.46921310",
     "longitude":"-58.83063490"
  },
  {
     "name":"Río Negro Province",
     "isoCode":"R",
     "countryCode":"AR",
     "latitude":"-40.82614340",
     "longitude":"-63.02663390"
  },
  {
     "name":"Chaco Province",
     "isoCode":"H",
     "countryCode":"AR",
     "latitude":"-27.42571750",
     "longitude":"-59.02437840"
  },
  {
     "name":"Santa Fe Province",
     "isoCode":"S",
     "countryCode":"AR",
     "latitude":"-31.58551090",
     "longitude":"-60.72380160"
  },
  {
     "name":"Córdoba Province",
     "isoCode":"X",
     "countryCode":"AR",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Salta Province",
     "isoCode":"A",
     "countryCode":"AR",
     "latitude":"-24.79976880",
     "longitude":"-65.41503670"
  },
  {
     "name":"Misiones Province",
     "isoCode":"N",
     "countryCode":"AR",
     "latitude":"-27.42692550",
     "longitude":"-55.94670760"
  },
  {
     "name":"Jujuy Province",
     "isoCode":"Y",
     "countryCode":"AR",
     "latitude":"-24.18433970",
     "longitude":"-65.30217700"
  },
  {
     "name":"Mendoza",
     "isoCode":"M",
     "countryCode":"AR",
     "latitude":"-32.88945870",
     "longitude":"-68.84583860"
  },
  {
     "name":"Catamarca Province",
     "isoCode":"K",
     "countryCode":"AR",
     "latitude":"-28.47158770",
     "longitude":"-65.78772090"
  },
  {
     "name":"Neuquén Province",
     "isoCode":"Q",
     "countryCode":"AR",
     "latitude":"-38.94587000",
     "longitude":"-68.07309250"
  },
  {
     "name":"Santa Cruz Province",
     "isoCode":"Z",
     "countryCode":"AR",
     "latitude":"-51.63528210",
     "longitude":"-69.24743530"
  },
  {
     "name":"Tierra del Fuego Province",
     "isoCode":"V",
     "countryCode":"AR",
     "latitude":"-54.80539980",
     "longitude":"-68.32420610"
  },
  {
     "name":"Chubut Province",
     "isoCode":"U",
     "countryCode":"AR",
     "latitude":"-43.29342460",
     "longitude":"-65.11148180"
  },
  {
     "name":"Formosa Province",
     "isoCode":"P",
     "countryCode":"AR",
     "latitude":"-26.18948040",
     "longitude":"-58.22428060"
  },
  {
     "name":"La Rioja Province",
     "isoCode":"F",
     "countryCode":"AR",
     "latitude":"-29.41937930",
     "longitude":"-66.85599320"
  },
  {
     "name":"Entre Ríos Province",
     "isoCode":"E",
     "countryCode":"AR",
     "latitude":"-31.77466540",
     "longitude":"-60.49564610"
  },
  {
     "name":"La Pampa",
     "isoCode":"L",
     "countryCode":"AR",
     "latitude":"-36.61475730",
     "longitude":"-64.28392090"
  },
  {
     "name":"Buenos Aires Province",
     "isoCode":"B",
     "countryCode":"AR",
     "latitude":"-37.20172850",
     "longitude":"-59.84106970"
  },
  {
     "name":"Quiché Department",
     "isoCode":"QC",
     "countryCode":"GT",
     "latitude":"15.49838080",
     "longitude":"-90.98206680"
  },
  {
     "name":"Jalapa Department",
     "isoCode":"JA",
     "countryCode":"GT",
     "latitude":"14.61214460",
     "longitude":"-89.96267990"
  },
  {
     "name":"Izabal Department",
     "isoCode":"IZ",
     "countryCode":"GT",
     "latitude":"15.49765170",
     "longitude":"-88.86469800"
  },
  {
     "name":"Suchitepéquez Department",
     "isoCode":"SU",
     "countryCode":"GT",
     "latitude":"14.42159820",
     "longitude":"-91.40482490"
  },
  {
     "name":"Sololá Department",
     "isoCode":"SO",
     "countryCode":"GT",
     "latitude":"14.74852300",
     "longitude":"-91.28910360"
  },
  {
     "name":"El Progreso Department",
     "isoCode":"PR",
     "countryCode":"GT",
     "latitude":"14.93887320",
     "longitude":"-90.07467670"
  },
  {
     "name":"Totonicapán Department",
     "isoCode":"TO",
     "countryCode":"GT",
     "latitude":"14.91734020",
     "longitude":"-91.36139230"
  },
  {
     "name":"Retalhuleu Department",
     "isoCode":"RE",
     "countryCode":"GT",
     "latitude":"14.52454850",
     "longitude":"-91.68578800"
  },
  {
     "name":"Santa Rosa Department",
     "isoCode":"SR",
     "countryCode":"GT",
     "latitude":"38.44057590",
     "longitude":"-122.70375430"
  },
  {
     "name":"Chiquimula Department",
     "isoCode":"CQ",
     "countryCode":"GT",
     "latitude":"14.75149990",
     "longitude":"-89.47421770"
  },
  {
     "name":"San Marcos Department",
     "isoCode":"SM",
     "countryCode":"GT",
     "latitude":"14.93095690",
     "longitude":"-91.90992380"
  },
  {
     "name":"Quetzaltenango Department",
     "isoCode":"QZ",
     "countryCode":"GT",
     "latitude":"14.79243300",
     "longitude":"-91.71495800"
  },
  {
     "name":"Petén Department",
     "isoCode":"PE",
     "countryCode":"GT",
     "latitude":"16.91203300",
     "longitude":"-90.29957850"
  },
  {
     "name":"Huehuetenango Department",
     "isoCode":"HU",
     "countryCode":"GT",
     "latitude":"15.58799140",
     "longitude":"-91.67606910"
  },
  {
     "name":"Alta Verapaz Department",
     "isoCode":"AV",
     "countryCode":"GT",
     "latitude":"15.59428830",
     "longitude":"-90.14949880"
  },
  {
     "name":"Guatemala Department",
     "isoCode":"GU",
     "countryCode":"GT",
     "latitude":"14.56494010",
     "longitude":"-90.52578230"
  },
  {
     "name":"Jutiapa Department",
     "isoCode":"JU",
     "countryCode":"GT",
     "latitude":"14.19308020",
     "longitude":"-89.92532330"
  },
  {
     "name":"Baja Verapaz Department",
     "isoCode":"BV",
     "countryCode":"GT",
     "latitude":"15.12558670",
     "longitude":"-90.37483540"
  },
  {
     "name":"Chimaltenango Department",
     "isoCode":"CM",
     "countryCode":"GT",
     "latitude":"14.56347870",
     "longitude":"-90.98206680"
  },
  {
     "name":"Sacatepéquez Department",
     "isoCode":"SA",
     "countryCode":"GT",
     "latitude":"14.51783790",
     "longitude":"-90.71527490"
  },
  {
     "name":"Escuintla Department",
     "isoCode":"ES",
     "countryCode":"GT",
     "latitude":"14.19109120",
     "longitude":"-90.98206680"
  },
  {
     "name":"Madre de Dios",
     "isoCode":"MDD",
     "countryCode":"PE",
     "latitude":"-11.76687050",
     "longitude":"-70.81199530"
  },
  {
     "name":"Huancavelica",
     "isoCode":"HUV",
     "countryCode":"PE",
     "latitude":"-12.78619780",
     "longitude":"-74.97640240"
  },
  {
     "name":"Áncash",
     "isoCode":"ANC",
     "countryCode":"PE",
     "latitude":"-9.32504970",
     "longitude":"-77.56194190"
  },
  {
     "name":"Arequipa",
     "isoCode":"ARE",
     "countryCode":"PE",
     "latitude":"-16.40904740",
     "longitude":"-71.53745100"
  },
  {
     "name":"Puno",
     "isoCode":"PUN",
     "countryCode":"PE",
     "latitude":"-15.84022180",
     "longitude":"-70.02188050"
  },
  {
     "name":"La Libertad",
     "isoCode":"LAL",
     "countryCode":"PE",
     "latitude":"13.49069700",
     "longitude":"-89.30846070"
  },
  {
     "name":"Ucayali",
     "isoCode":"UCA",
     "countryCode":"PE",
     "latitude":"-9.82511830",
     "longitude":"-73.08774900"
  },
  {
     "name":"Amazonas",
     "isoCode":"AMA",
     "countryCode":"PE",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Pasco",
     "isoCode":"PAS",
     "countryCode":"PE",
     "latitude":"46.23050490",
     "longitude":"-119.09223160"
  },
  {
     "name":"Huanuco",
     "isoCode":"HUC",
     "countryCode":"PE",
     "latitude":"-9.92076480",
     "longitude":"-76.24108430"
  },
  {
     "name":"Cajamarca",
     "isoCode":"CAJ",
     "countryCode":"PE",
     "latitude":"-7.16174650",
     "longitude":"-78.51278550"
  },
  {
     "name":"Tumbes",
     "isoCode":"TUM",
     "countryCode":"PE",
     "latitude":"-3.55649210",
     "longitude":"-80.42708850"
  },
  {
     "name":"Cusco",
     "isoCode":"CUS",
     "countryCode":"PE",
     "latitude":"-13.53195000",
     "longitude":"-71.96746260"
  },
  {
     "name":"Ayacucho",
     "isoCode":"AYA",
     "countryCode":"PE",
     "latitude":"-13.16387370",
     "longitude":"-74.22356410"
  },
  {
     "name":"Junín",
     "isoCode":"JUN",
     "countryCode":"PE",
     "latitude":"-11.15819250",
     "longitude":"-75.99263060"
  },
  {
     "name":"San Martín",
     "isoCode":"SAM",
     "countryCode":"PE",
     "latitude":"37.08494640",
     "longitude":"-121.61022160"
  },
  {
     "name":"Lima",
     "isoCode":"LIM",
     "countryCode":"PE",
     "latitude":"-12.04637310",
     "longitude":"-77.04275400"
  },
  {
     "name":"Tacna",
     "isoCode":"TAC",
     "countryCode":"PE",
     "latitude":"-18.00656790",
     "longitude":"-70.24627410"
  },
  {
     "name":"Piura",
     "isoCode":"PIU",
     "countryCode":"PE",
     "latitude":"-5.17828840",
     "longitude":"-80.65488820"
  },
  {
     "name":"Moquegua",
     "isoCode":"MOQ",
     "countryCode":"PE",
     "latitude":"-17.19273610",
     "longitude":"-70.93281380"
  },
  {
     "name":"Apurímac",
     "isoCode":"APU",
     "countryCode":"PE",
     "latitude":"-14.05045330",
     "longitude":"-73.08774900"
  },
  {
     "name":"Ica",
     "isoCode":"ICA",
     "countryCode":"PE",
     "latitude":"42.35288320",
     "longitude":"-71.04300970"
  },
  {
     "name":"Callao",
     "isoCode":"CAL",
     "countryCode":"PE",
     "latitude":"-12.05084910",
     "longitude":"-77.12598430"
  },
  {
     "name":"Lambayeque",
     "isoCode":"LAM",
     "countryCode":"PE",
     "latitude":"-6.71976660",
     "longitude":"-79.90807570"
  },
  {
     "name":"Redonda",
     "isoCode":"11",
     "countryCode":"AG",
     "latitude":"16.93841600",
     "longitude":"-62.34551480"
  },
  {
     "name":"Saint Peter Parish",
     "isoCode":"07",
     "countryCode":"AG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Paul Parish",
     "isoCode":"06",
     "countryCode":"AG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint John Parish",
     "isoCode":"04",
     "countryCode":"AG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Mary Parish",
     "isoCode":"05",
     "countryCode":"AG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Barbuda",
     "isoCode":"10",
     "countryCode":"AG",
     "latitude":"17.62662420",
     "longitude":"-61.77130280"
  },
  {
     "name":"Saint George Parish",
     "isoCode":"03",
     "countryCode":"AG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Philip Parish",
     "isoCode":"08",
     "countryCode":"AG",
     "latitude":"40.43682580",
     "longitude":"-80.06855320"
  },
  {
     "name":"South Bačka District",
     "isoCode":"06",
     "countryCode":"RS",
     "latitude":"45.48903440",
     "longitude":"19.69761870"
  },
  {
     "name":"Pirot District",
     "isoCode":"22",
     "countryCode":"RS",
     "latitude":"43.08740360",
     "longitude":"22.59830440"
  },
  {
     "name":"South Banat District",
     "isoCode":"04",
     "countryCode":"RS",
     "latitude":"45.00274570",
     "longitude":"21.05425090"
  },
  {
     "name":"North Bačka District",
     "isoCode":"01",
     "countryCode":"RS",
     "latitude":"45.98033940",
     "longitude":"19.59070010"
  },
  {
     "name":"Jablanica District",
     "isoCode":"23",
     "countryCode":"RS",
     "latitude":"42.94815600",
     "longitude":"21.81293210"
  },
  {
     "name":"Central Banat District",
     "isoCode":"02",
     "countryCode":"RS",
     "latitude":"45.47884850",
     "longitude":"20.60825220"
  },
  {
     "name":"Bor District",
     "isoCode":"14",
     "countryCode":"RS",
     "latitude":"44.06989180",
     "longitude":"22.09850860"
  },
  {
     "name":"Toplica District",
     "isoCode":"21",
     "countryCode":"RS",
     "latitude":"43.19065920",
     "longitude":"21.34077620"
  },
  {
     "name":"Mačva District",
     "isoCode":"08",
     "countryCode":"RS",
     "latitude":"44.59253140",
     "longitude":"19.50822460"
  },
  {
     "name":"Rasina District",
     "isoCode":"19",
     "countryCode":"RS",
     "latitude":"43.52635250",
     "longitude":"21.15881780"
  },
  {
     "name":"Pčinja District",
     "isoCode":"24",
     "countryCode":"RS",
     "latitude":"42.58363620",
     "longitude":"22.14302150"
  },
  {
     "name":"Nišava District",
     "isoCode":"20",
     "countryCode":"RS",
     "latitude":"43.37389020",
     "longitude":"21.93223310"
  },
  {
     "name":"Prizren District",
     "isoCode":"XPR",
     "countryCode":"XK",
     "latitude":"42.21525220",
     "longitude":"20.74147720"
  },
  {
     "name":"Kolubara District",
     "isoCode":"09",
     "countryCode":"RS",
     "latitude":"44.35098110",
     "longitude":"20.00043050"
  },
  {
     "name":"Raška District",
     "isoCode":"18",
     "countryCode":"RS",
     "latitude":"43.33734610",
     "longitude":"20.57340050"
  },
  {
     "name":"West Bačka District",
     "isoCode":"05",
     "countryCode":"RS",
     "latitude":"45.73553850",
     "longitude":"19.18973640"
  },
  {
     "name":"Moravica District",
     "isoCode":"17",
     "countryCode":"RS",
     "latitude":"43.84147000",
     "longitude":"20.29049870"
  },
  {
     "name":"Belgrade",
     "isoCode":"00",
     "countryCode":"RS",
     "latitude":"44.78656800",
     "longitude":"20.44892160"
  },
  {
     "name":"Zlatibor District",
     "isoCode":"16",
     "countryCode":"RS",
     "latitude":"43.64541700",
     "longitude":"19.71014550"
  },
  {
     "name":"Zaječar District",
     "isoCode":"15",
     "countryCode":"RS",
     "latitude":"43.90150480",
     "longitude":"22.27380110"
  },
  {
     "name":"Braničevo District",
     "isoCode":"11",
     "countryCode":"RS",
     "latitude":"44.69822460",
     "longitude":"21.54467750"
  },
  {
     "name":"Vojvodina",
     "isoCode":"VO",
     "countryCode":"RS",
     "latitude":"45.26086510",
     "longitude":"19.83193380"
  },
  {
     "name":"Šumadija District",
     "isoCode":"12",
     "countryCode":"RS",
     "latitude":"44.20506780",
     "longitude":"20.78565650"
  },
  {
     "name":"North Banat District",
     "isoCode":"03",
     "countryCode":"RS",
     "latitude":"45.90683900",
     "longitude":"19.99934170"
  },
  {
     "name":"Pomoravlje District",
     "isoCode":"13",
     "countryCode":"RS",
     "latitude":"43.95913790",
     "longitude":"21.27135300"
  },
  {
     "name":"Peć District",
     "isoCode":"XPE",
     "countryCode":"XK",
     "latitude":"42.65921550",
     "longitude":"20.28876240"
  },
  {
     "name":"Srem District",
     "isoCode":"07",
     "countryCode":"RS",
     "latitude":"45.00291710",
     "longitude":"19.80137730"
  },
  {
     "name":"Podunavlje District",
     "isoCode":"10",
     "countryCode":"RS",
     "latitude":"44.47291560",
     "longitude":"20.99014260"
  },
  {
     "name":"Westmoreland Parish",
     "isoCode":"10",
     "countryCode":"JM",
     "latitude":"18.29443780",
     "longitude":"-78.15644320"
  },
  {
     "name":"Saint Elizabeth Parish",
     "isoCode":"11",
     "countryCode":"JM",
     "latitude":"38.99253080",
     "longitude":"-94.58992000"
  },
  {
     "name":"Saint Ann Parish",
     "isoCode":"06",
     "countryCode":"JM",
     "latitude":"37.28714520",
     "longitude":"-77.41035330"
  },
  {
     "name":"Saint James Parish",
     "isoCode":"08",
     "countryCode":"JM",
     "latitude":"30.01792920",
     "longitude":"-90.79132270"
  },
  {
     "name":"Saint Catherine Parish",
     "isoCode":"14",
     "countryCode":"JM",
     "latitude":"18.03641340",
     "longitude":"-77.05644640"
  },
  {
     "name":"Saint Mary Parish",
     "isoCode":"05",
     "countryCode":"JM",
     "latitude":"36.09252200",
     "longitude":"-95.97384400"
  },
  {
     "name":"Kingston Parish",
     "isoCode":"01",
     "countryCode":"JM",
     "latitude":"17.96832710",
     "longitude":"-76.78270200"
  },
  {
     "name":"Hanover Parish",
     "isoCode":"09",
     "countryCode":"JM",
     "latitude":"18.40977070",
     "longitude":"-78.13363800"
  },
  {
     "name":"Saint Thomas Parish",
     "isoCode":"03",
     "countryCode":"JM",
     "latitude":"41.44253890",
     "longitude":"-81.74022180"
  },
  {
     "name":"Saint Andrew",
     "isoCode":"02",
     "countryCode":"JM",
     "latitude":"37.22451030",
     "longitude":"-95.70211890"
  },
  {
     "name":"Portland Parish",
     "isoCode":"04",
     "countryCode":"JM",
     "latitude":"18.08442740",
     "longitude":"-76.41002670"
  },
  {
     "name":"Clarendon Parish",
     "isoCode":"13",
     "countryCode":"JM",
     "latitude":"17.95571830",
     "longitude":"-77.24051530"
  },
  {
     "name":"Manchester Parish",
     "isoCode":"12",
     "countryCode":"JM",
     "latitude":"18.06696540",
     "longitude":"-77.51607880"
  },
  {
     "name":"Trelawny Parish",
     "isoCode":"07",
     "countryCode":"JM",
     "latitude":"18.35261430",
     "longitude":"-77.60778650"
  },
  {
     "name":"Dennery Quarter",
     "isoCode":"05",
     "countryCode":"LC",
     "latitude":"13.92673930",
     "longitude":"-60.91909880"
  },
  {
     "name":"Anse la Raye Quarter",
     "isoCode":"01",
     "countryCode":"LC",
     "latitude":"13.94594240",
     "longitude":"-61.03694680"
  },
  {
     "name":"Castries Quarter",
     "isoCode":"02",
     "countryCode":"LC",
     "latitude":"14.01010940",
     "longitude":"-60.98746870"
  },
  {
     "name":"Laborie Quarter",
     "isoCode":"07",
     "countryCode":"LC",
     "latitude":"13.75227830",
     "longitude":"-60.99328890"
  },
  {
     "name":"Choiseul Quarter",
     "isoCode":"03",
     "countryCode":"LC",
     "latitude":"13.77501540",
     "longitude":"-61.04859100"
  },
  {
     "name":"Canaries",
     "isoCode":"12",
     "countryCode":"LC",
     "latitude":"28.29156370",
     "longitude":"-16.62913040"
  },
  {
     "name":"Micoud Quarter",
     "isoCode":"08",
     "countryCode":"LC",
     "latitude":"13.82118710",
     "longitude":"-60.90019340"
  },
  {
     "name":"Vieux Fort Quarter",
     "isoCode":"11",
     "countryCode":"LC",
     "latitude":"13.72060800",
     "longitude":"-60.94964330"
  },
  {
     "name":"Soufrière Quarter",
     "isoCode":"10",
     "countryCode":"LC",
     "latitude":"13.85709860",
     "longitude":"-61.05732480"
  },
  {
     "name":"Praslin Quarter",
     "isoCode":"09",
     "countryCode":"LC",
     "latitude":"13.87523920",
     "longitude":"-60.89946630"
  },
  {
     "name":"Gros Islet Quarter",
     "isoCode":"06",
     "countryCode":"LC",
     "latitude":"14.08435780",
     "longitude":"-60.94527940"
  },
  {
     "name":"Dauphin Quarter",
     "isoCode":"04",
     "countryCode":"LC",
     "latitude":"14.01033960",
     "longitude":"-60.91909880"
  },
  {
     "name":"Hưng Yên",
     "isoCode":"66",
     "countryCode":"VN",
     "latitude":"20.85257110",
     "longitude":"106.01699710"
  },
  {
     "name":"Đồng Tháp",
     "isoCode":"45",
     "countryCode":"VN",
     "latitude":"10.49379890",
     "longitude":"105.68817880"
  },
  {
     "name":"Bà Rịa-Vũng Tàu",
     "isoCode":"43",
     "countryCode":"VN",
     "latitude":"10.54173970",
     "longitude":"107.24299760"
  },
  {
     "name":"Thanh Hóa",
     "isoCode":"21",
     "countryCode":"VN",
     "latitude":"19.80669200",
     "longitude":"105.78518160"
  },
  {
     "name":"Kon Tum",
     "isoCode":"28",
     "countryCode":"VN",
     "latitude":"14.34974030",
     "longitude":"108.00046060"
  },
  {
     "name":"Điện Biên",
     "isoCode":"71",
     "countryCode":"VN",
     "latitude":"21.80423090",
     "longitude":"103.10765250"
  },
  {
     "name":"Vĩnh Phúc",
     "isoCode":"70",
     "countryCode":"VN",
     "latitude":"21.36088050",
     "longitude":"105.54743730"
  },
  {
     "name":"Thái Bình",
     "isoCode":"20",
     "countryCode":"VN",
     "latitude":"20.44634710",
     "longitude":"106.33658280"
  },
  {
     "name":"Quảng Nam",
     "isoCode":"27",
     "countryCode":"VN",
     "latitude":"15.53935380",
     "longitude":"108.01910200"
  },
  {
     "name":"Hậu Giang",
     "isoCode":"73",
     "countryCode":"VN",
     "latitude":"9.75789800",
     "longitude":"105.64125270"
  },
  {
     "name":"Cà Mau",
     "isoCode":"59",
     "countryCode":"VN",
     "latitude":"9.15267280",
     "longitude":"105.19607950"
  },
  {
     "name":"Hà Giang",
     "isoCode":"03",
     "countryCode":"VN",
     "latitude":"22.80255880",
     "longitude":"104.97844940"
  },
  {
     "name":"Nghệ An",
     "isoCode":"22",
     "countryCode":"VN",
     "latitude":"19.23424890",
     "longitude":"104.92003650"
  },
  {
     "name":"Tiền Giang",
     "isoCode":"46",
     "countryCode":"VN",
     "latitude":"10.44933240",
     "longitude":"106.34205040"
  },
  {
     "name":"Cao Bằng",
     "isoCode":"04",
     "countryCode":"VN",
     "latitude":"22.63568900",
     "longitude":"106.25221430"
  },
  {
     "name":"Haiphong",
     "isoCode":"HP",
     "countryCode":"VN",
     "latitude":"20.84491150",
     "longitude":"106.68808410"
  },
  {
     "name":"Yên Bái",
     "isoCode":"06",
     "countryCode":"VN",
     "latitude":"21.71676890",
     "longitude":"104.89858780"
  },
  {
     "name":"Bình Dương",
     "isoCode":"57",
     "countryCode":"VN",
     "latitude":"11.32540240",
     "longitude":"106.47701700"
  },
  {
     "name":"Ninh Bình",
     "isoCode":"18",
     "countryCode":"VN",
     "latitude":"20.25061490",
     "longitude":"105.97445360"
  },
  {
     "name":"Bình Thuận",
     "isoCode":"40",
     "countryCode":"VN",
     "latitude":"11.09037030",
     "longitude":"108.07207810"
  },
  {
     "name":"Ninh Thuận",
     "isoCode":"36",
     "countryCode":"VN",
     "latitude":"11.67387670",
     "longitude":"108.86295720"
  },
  {
     "name":"Nam Định",
     "isoCode":"67",
     "countryCode":"VN",
     "latitude":"20.43882250",
     "longitude":"106.16210530"
  },
  {
     "name":"Vĩnh Long",
     "isoCode":"49",
     "countryCode":"VN",
     "latitude":"10.23957400",
     "longitude":"105.95719280"
  },
  {
     "name":"Bắc Ninh",
     "isoCode":"56",
     "countryCode":"VN",
     "latitude":"21.12144400",
     "longitude":"106.11105010"
  },
  {
     "name":"Lạng Sơn",
     "isoCode":"09",
     "countryCode":"VN",
     "latitude":"21.85370800",
     "longitude":"106.76151900"
  },
  {
     "name":"Khánh Hòa",
     "isoCode":"34",
     "countryCode":"VN",
     "latitude":"12.25850980",
     "longitude":"109.05260760"
  },
  {
     "name":"An Giang",
     "isoCode":"44",
     "countryCode":"VN",
     "latitude":"10.52158360",
     "longitude":"105.12589550"
  },
  {
     "name":"Tuyên Quang",
     "isoCode":"07",
     "countryCode":"VN",
     "latitude":"21.77672460",
     "longitude":"105.22801960"
  },
  {
     "name":"Bến Tre",
     "isoCode":"50",
     "countryCode":"VN",
     "latitude":"10.24335560",
     "longitude":"106.37555100"
  },
  {
     "name":"Bình Phước",
     "isoCode":"58",
     "countryCode":"VN",
     "latitude":"11.75118940",
     "longitude":"106.72346390"
  },
  {
     "name":"Thừa Thiên-Huế",
     "isoCode":"26",
     "countryCode":"VN",
     "latitude":"16.46739700",
     "longitude":"107.59053260"
  },
  {
     "name":"Hòa Bình",
     "isoCode":"14",
     "countryCode":"VN",
     "latitude":"20.68612650",
     "longitude":"105.31311850"
  },
  {
     "name":"Kiên Giang",
     "isoCode":"47",
     "countryCode":"VN",
     "latitude":"9.82495870",
     "longitude":"105.12589550"
  },
  {
     "name":"Phú Thọ",
     "isoCode":"68",
     "countryCode":"VN",
     "latitude":"21.26844300",
     "longitude":"105.20455730"
  },
  {
     "name":"Hà Nam",
     "isoCode":"63",
     "countryCode":"VN",
     "latitude":"20.58351960",
     "longitude":"105.92299000"
  },
  {
     "name":"Quảng Trị",
     "isoCode":"25",
     "countryCode":"VN",
     "latitude":"16.74030740",
     "longitude":"107.18546790"
  },
  {
     "name":"Bạc Liêu",
     "isoCode":"55",
     "countryCode":"VN",
     "latitude":"9.29400270",
     "longitude":"105.72156630"
  },
  {
     "name":"Trà Vinh",
     "isoCode":"51",
     "countryCode":"VN",
     "latitude":"9.81274100",
     "longitude":"106.29929120"
  },
  {
     "name":"Da Nang",
     "isoCode":"DN",
     "countryCode":"VN",
     "latitude":"16.05440680",
     "longitude":"108.20216670"
  },
  {
     "name":"Thái Nguyên",
     "isoCode":"69",
     "countryCode":"VN",
     "latitude":"21.56715590",
     "longitude":"105.82520380"
  },
  {
     "name":"Long An",
     "isoCode":"41",
     "countryCode":"VN",
     "latitude":"10.56071680",
     "longitude":"106.64976230"
  },
  {
     "name":"Quảng Bình",
     "isoCode":"24",
     "countryCode":"VN",
     "latitude":"17.61027150",
     "longitude":"106.34874740"
  },
  {
     "name":"Hanoi",
     "isoCode":"HN",
     "countryCode":"VN",
     "latitude":"21.02776440",
     "longitude":"105.83415980"
  },
  {
     "name":"Ho Chi Minh City",
     "isoCode":"SG",
     "countryCode":"VN",
     "latitude":"10.82309890",
     "longitude":"106.62966380"
  },
  {
     "name":"Sơn La",
     "isoCode":"05",
     "countryCode":"VN",
     "latitude":"21.10222840",
     "longitude":"103.72891670"
  },
  {
     "name":"Gia Lai",
     "isoCode":"30",
     "countryCode":"VN",
     "latitude":"13.80789430",
     "longitude":"108.10937500"
  },
  {
     "name":"Quảng Ninh",
     "isoCode":"13",
     "countryCode":"VN",
     "latitude":"21.00638200",
     "longitude":"107.29251440"
  },
  {
     "name":"Bắc Giang",
     "isoCode":"54",
     "countryCode":"VN",
     "latitude":"21.28199210",
     "longitude":"106.19747690"
  },
  {
     "name":"Hà Tĩnh",
     "isoCode":"23",
     "countryCode":"VN",
     "latitude":"18.35595370",
     "longitude":"105.88774940"
  },
  {
     "name":"Lào Cai",
     "isoCode":"02",
     "countryCode":"VN",
     "latitude":"22.48094310",
     "longitude":"103.97549590"
  },
  {
     "name":"Lâm Đồng",
     "isoCode":"35",
     "countryCode":"VN",
     "latitude":"11.57527910",
     "longitude":"108.14286690"
  },
  {
     "name":"Sóc Trăng",
     "isoCode":"52",
     "countryCode":"VN",
     "latitude":"9.60252100",
     "longitude":"105.97390490"
  },
  {
     "name":"Hà Tây",
     "isoCode":"15",
     "countryCode":"VN",
     "latitude":"39.10956300",
     "longitude":"117.22337100"
  },
  {
     "name":"Đồng Nai",
     "isoCode":"39",
     "countryCode":"VN",
     "latitude":"11.06863050",
     "longitude":"107.16759760"
  },
  {
     "name":"Bắc Kạn",
     "isoCode":"53",
     "countryCode":"VN",
     "latitude":"22.30329230",
     "longitude":"105.87600400"
  },
  {
     "name":"Đắk Nông",
     "isoCode":"72",
     "countryCode":"VN",
     "latitude":"12.26464760",
     "longitude":"107.60980600"
  },
  {
     "name":"Phú Yên",
     "isoCode":"32",
     "countryCode":"VN",
     "latitude":"13.08818610",
     "longitude":"109.09287640"
  },
  {
     "name":"Lai Châu",
     "isoCode":"01",
     "countryCode":"VN",
     "latitude":"22.38622270",
     "longitude":"103.47026310"
  },
  {
     "name":"Tây Ninh",
     "isoCode":"37",
     "countryCode":"VN",
     "latitude":"11.33515540",
     "longitude":"106.10988540"
  },
  {
     "name":"Hải Dương",
     "isoCode":"61",
     "countryCode":"VN",
     "latitude":"20.93734130",
     "longitude":"106.31455420"
  },
  {
     "name":"Quảng Ngãi",
     "isoCode":"29",
     "countryCode":"VN",
     "latitude":"15.12138730",
     "longitude":"108.80441450"
  },
  {
     "name":"Đắk Lắk",
     "isoCode":"33",
     "countryCode":"VN",
     "latitude":"12.71001160",
     "longitude":"108.23775190"
  },
  {
     "name":"Bình Định",
     "isoCode":"31",
     "countryCode":"VN",
     "latitude":"14.16653240",
     "longitude":"108.90268300"
  },
  {
     "name":"Saint Peter Basseterre Parish",
     "isoCode":"11",
     "countryCode":"KN",
     "latitude":"17.31029110",
     "longitude":"-62.71475330"
  },
  {
     "name":"Nevis",
     "isoCode":"N",
     "countryCode":"KN",
     "latitude":"17.15535580",
     "longitude":"-62.57960260"
  },
  {
     "name":"Christ Church Nichola Town Parish",
     "isoCode":"01",
     "countryCode":"KN",
     "latitude":"17.36048120",
     "longitude":"-62.76178370"
  },
  {
     "name":"Saint Paul Capisterre Parish",
     "isoCode":"09",
     "countryCode":"KN",
     "latitude":"17.40166830",
     "longitude":"-62.82573320"
  },
  {
     "name":"Saint James Windward Parish",
     "isoCode":"05",
     "countryCode":"KN",
     "latitude":"17.17696330",
     "longitude":"-62.57960260"
  },
  {
     "name":"Saint Anne Sandy Point Parish",
     "isoCode":"02",
     "countryCode":"KN",
     "latitude":"17.37253330",
     "longitude":"-62.84411330"
  },
  {
     "name":"Saint George Gingerland Parish",
     "isoCode":"04",
     "countryCode":"KN",
     "latitude":"17.12577590",
     "longitude":"-62.56198110"
  },
  {
     "name":"Saint Paul Charlestown Parish",
     "isoCode":"10",
     "countryCode":"KN",
     "latitude":"17.13462970",
     "longitude":"-62.61338160"
  },
  {
     "name":"Saint Thomas Lowland Parish",
     "isoCode":"12",
     "countryCode":"KN",
     "latitude":"17.16505130",
     "longitude":"-62.60897530"
  },
  {
     "name":"Saint John Figtree Parish",
     "isoCode":"07",
     "countryCode":"KN",
     "latitude":"17.11557480",
     "longitude":"-62.60310040"
  },
  {
     "name":"Saint Kitts",
     "isoCode":"K",
     "countryCode":"KN",
     "latitude":"17.34337960",
     "longitude":"-62.75590430"
  },
  {
     "name":"Saint Thomas Middle Island Parish",
     "isoCode":"13",
     "countryCode":"KN",
     "latitude":"17.33488130",
     "longitude":"-62.80882510"
  },
  {
     "name":"Trinity Palmetto Point Parish",
     "isoCode":"15",
     "countryCode":"KN",
     "latitude":"17.30635190",
     "longitude":"-62.76178370"
  },
  {
     "name":"Saint Mary Cayon Parish",
     "isoCode":"08",
     "countryCode":"KN",
     "latitude":"17.34620710",
     "longitude":"-62.73826710"
  },
  {
     "name":"Saint John Capisterre Parish",
     "isoCode":"06",
     "countryCode":"KN",
     "latitude":"17.38103410",
     "longitude":"-62.79118330"
  },
  {
     "name":"Daegu",
     "isoCode":"27",
     "countryCode":"KR",
     "latitude":"35.87143540",
     "longitude":"128.60144500"
  },
  {
     "name":"Gyeonggi Province",
     "isoCode":"41",
     "countryCode":"KR",
     "latitude":"37.41380000",
     "longitude":"127.51830000"
  },
  {
     "name":"Incheon",
     "isoCode":"28",
     "countryCode":"KR",
     "latitude":"37.45625570",
     "longitude":"126.70520620"
  },
  {
     "name":"Seoul",
     "isoCode":"11",
     "countryCode":"KR",
     "latitude":"37.56653500",
     "longitude":"126.97796920"
  },
  {
     "name":"Daejeon",
     "isoCode":"30",
     "countryCode":"KR",
     "latitude":"36.35041190",
     "longitude":"127.38454750"
  },
  {
     "name":"North Jeolla Province",
     "isoCode":"45",
     "countryCode":"KR",
     "latitude":"35.71750000",
     "longitude":"127.15300000"
  },
  {
     "name":"Ulsan",
     "isoCode":"31",
     "countryCode":"KR",
     "latitude":"35.53837730",
     "longitude":"129.31135960"
  },
  {
     "name":"Jeju",
     "isoCode":"49",
     "countryCode":"KR",
     "latitude":"33.95682780",
     "longitude":"-84.13135000"
  },
  {
     "name":"North Chungcheong Province",
     "isoCode":"43",
     "countryCode":"KR",
     "latitude":"36.80000000",
     "longitude":"127.70000000"
  },
  {
     "name":"North Gyeongsang Province",
     "isoCode":"47",
     "countryCode":"KR",
     "latitude":"36.49190000",
     "longitude":"128.88890000"
  },
  {
     "name":"South Jeolla Province",
     "isoCode":"46",
     "countryCode":"KR",
     "latitude":"34.86790000",
     "longitude":"126.99100000"
  },
  {
     "name":"South Gyeongsang Province",
     "isoCode":"48",
     "countryCode":"KR",
     "latitude":"35.46060000",
     "longitude":"128.21320000"
  },
  {
     "name":"Gwangju",
     "isoCode":"29",
     "countryCode":"KR",
     "latitude":"35.15954540",
     "longitude":"126.85260120"
  },
  {
     "name":"South Chungcheong Province",
     "isoCode":"44",
     "countryCode":"KR",
     "latitude":"36.51840000",
     "longitude":"126.80000000"
  },
  {
     "name":"Busan",
     "isoCode":"26",
     "countryCode":"KR",
     "latitude":"35.17955430",
     "longitude":"129.07564160"
  },
  {
     "name":"Sejong City",
     "isoCode":"50",
     "countryCode":"KR",
     "latitude":"34.05233230",
     "longitude":"-118.30848970"
  },
  {
     "name":"Gangwon Province",
     "isoCode":"42",
     "countryCode":"KR",
     "latitude":"37.82280000",
     "longitude":"128.15550000"
  },
  {
     "name":"Saint Patrick Parish",
     "isoCode":"06",
     "countryCode":"GD",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint George Parish",
     "isoCode":"03",
     "countryCode":"GD",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Andrew Parish",
     "isoCode":"01",
     "countryCode":"GD",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Mark Parish",
     "isoCode":"05",
     "countryCode":"GD",
     "latitude":"40.58818630",
     "longitude":"-73.94957010"
  },
  {
     "name":"Carriacou and Petite Martinique",
     "isoCode":"10",
     "countryCode":"GD",
     "latitude":"12.47858880",
     "longitude":"-61.44938420"
  },
  {
     "name":"Saint John Parish",
     "isoCode":"04",
     "countryCode":"GD",
     "latitude":"30.11183310",
     "longitude":"-90.48799160"
  },
  {
     "name":"Saint David Parish",
     "isoCode":"02",
     "countryCode":"GD",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Ghazni",
     "isoCode":"GHA",
     "countryCode":"AF",
     "latitude":"33.54505870",
     "longitude":"68.41739720"
  },
  {
     "name":"Badghis",
     "isoCode":"BDG",
     "countryCode":"AF",
     "latitude":"35.16713390",
     "longitude":"63.76953840"
  },
  {
     "name":"Bamyan",
     "isoCode":"BAM",
     "countryCode":"AF",
     "latitude":"34.81000670",
     "longitude":"67.82121040"
  },
  {
     "name":"Helmand",
     "isoCode":"HEL",
     "countryCode":"AF",
     "latitude":"39.29893610",
     "longitude":"-76.61604720"
  },
  {
     "name":"Zabul",
     "isoCode":"ZAB",
     "countryCode":"AF",
     "latitude":"32.19187820",
     "longitude":"67.18944880"
  },
  {
     "name":"Baghlan",
     "isoCode":"BGL",
     "countryCode":"AF",
     "latitude":"36.17890260",
     "longitude":"68.74530640"
  },
  {
     "name":"Kunar",
     "isoCode":"KNR",
     "countryCode":"AF",
     "latitude":"34.84658930",
     "longitude":"71.09731700"
  },
  {
     "name":"Paktika",
     "isoCode":"PKA",
     "countryCode":"AF",
     "latitude":"32.26453860",
     "longitude":"68.52471490"
  },
  {
     "name":"Khost",
     "isoCode":"KHO",
     "countryCode":"AF",
     "latitude":"33.33384720",
     "longitude":"69.93716730"
  },
  {
     "name":"Kapisa",
     "isoCode":"KAP",
     "countryCode":"AF",
     "latitude":"34.98105720",
     "longitude":"69.62145620"
  },
  {
     "name":"Nuristan",
     "isoCode":"NUR",
     "countryCode":"AF",
     "latitude":"35.32502230",
     "longitude":"70.90712360"
  },
  {
     "name":"Panjshir",
     "isoCode":"PAN",
     "countryCode":"AF",
     "latitude":"38.88023910",
     "longitude":"-77.17172380"
  },
  {
     "name":"Nangarhar",
     "isoCode":"NAN",
     "countryCode":"AF",
     "latitude":"34.17183130",
     "longitude":"70.62167940"
  },
  {
     "name":"Samangan",
     "isoCode":"SAM",
     "countryCode":"AF",
     "latitude":"36.31555060",
     "longitude":"67.96428630"
  },
  {
     "name":"Balkh",
     "isoCode":"BAL",
     "countryCode":"AF",
     "latitude":"36.75506030",
     "longitude":"66.89753720"
  },
  {
     "name":"Sar-e Pol",
     "isoCode":"SAR",
     "countryCode":"AF",
     "latitude":"36.21662800",
     "longitude":"65.93336000"
  },
  {
     "name":"Jowzjan",
     "isoCode":"JOW",
     "countryCode":"AF",
     "latitude":"36.89696920",
     "longitude":"65.66585680"
  },
  {
     "name":"Herat",
     "isoCode":"HER",
     "countryCode":"AF",
     "latitude":"34.35286500",
     "longitude":"62.20402870"
  },
  {
     "name":"Ghōr",
     "isoCode":"GHO",
     "countryCode":"AF",
     "latitude":"34.09957760",
     "longitude":"64.90595500"
  },
  {
     "name":"Faryab",
     "isoCode":"FYB",
     "countryCode":"AF",
     "latitude":"36.07956130",
     "longitude":"64.90595500"
  },
  {
     "name":"Kandahar",
     "isoCode":"KAN",
     "countryCode":"AF",
     "latitude":"31.62887100",
     "longitude":"65.73717490"
  },
  {
     "name":"Laghman",
     "isoCode":"LAG",
     "countryCode":"AF",
     "latitude":"34.68976870",
     "longitude":"70.14558050"
  },
  {
     "name":"Daykundi",
     "isoCode":"DAY",
     "countryCode":"AF",
     "latitude":"33.66949500",
     "longitude":"66.04635340"
  },
  {
     "name":"Takhar",
     "isoCode":"TAK",
     "countryCode":"AF",
     "latitude":"36.66980130",
     "longitude":"69.47845410"
  },
  {
     "name":"Paktia",
     "isoCode":"PIA",
     "countryCode":"AF",
     "latitude":"33.70619900",
     "longitude":"69.38310790"
  },
  {
     "name":"Parwan",
     "isoCode":"PAR",
     "countryCode":"AF",
     "latitude":"34.96309770",
     "longitude":"68.81088490"
  },
  {
     "name":"Nimruz",
     "isoCode":"NIM",
     "countryCode":"AF",
     "latitude":"31.02614880",
     "longitude":"62.45041540"
  },
  {
     "name":"Logar",
     "isoCode":"LOG",
     "countryCode":"AF",
     "latitude":"34.01455180",
     "longitude":"69.19239160"
  },
  {
     "name":"Urozgan",
     "isoCode":"URU",
     "countryCode":"AF",
     "latitude":"32.92712870",
     "longitude":"66.14152630"
  },
  {
     "name":"Farah",
     "isoCode":"FRA",
     "countryCode":"AF",
     "latitude":"32.49532800",
     "longitude":"62.26266270"
  },
  {
     "name":"Kunduz Province",
     "isoCode":"KDZ",
     "countryCode":"AF",
     "latitude":"36.72855110",
     "longitude":"68.86789820"
  },
  {
     "name":"Badakhshan",
     "isoCode":"BDS",
     "countryCode":"AF",
     "latitude":"36.73477250",
     "longitude":"70.81199530"
  },
  {
     "name":"Kabul",
     "isoCode":"KAB",
     "countryCode":"AF",
     "latitude":"34.55534940",
     "longitude":"69.20748600"
  },
  {
     "name":"Victoria",
     "isoCode":"VIC",
     "countryCode":"AU",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"South Australia",
     "isoCode":"SA",
     "countryCode":"AU",
     "latitude":"-30.00023150",
     "longitude":"136.20915470"
  },
  {
     "name":"Queensland",
     "isoCode":"QLD",
     "countryCode":"AU",
     "latitude":"-20.91757380",
     "longitude":"142.70279560"
  },
  {
     "name":"Western Australia",
     "isoCode":"WA",
     "countryCode":"AU",
     "latitude":"-27.67281680",
     "longitude":"121.62830980"
  },
  {
     "name":"Australian Capital Territory",
     "isoCode":"ACT",
     "countryCode":"AU",
     "latitude":"-35.47346790",
     "longitude":"149.01236790"
  },
  {
     "name":"Tasmania",
     "isoCode":"TAS",
     "countryCode":"AU",
     "latitude":"-41.45451960",
     "longitude":"145.97066470"
  },
  {
     "name":"New South Wales",
     "isoCode":"NSW",
     "countryCode":"AU",
     "latitude":"-31.25321830",
     "longitude":"146.92109900"
  },
  {
     "name":"Northern Territory",
     "isoCode":"NT",
     "countryCode":"AU",
     "latitude":"-19.49141080",
     "longitude":"132.55096030"
  },
  {
     "name":"Vavaʻu",
     "isoCode":"05",
     "countryCode":"TO",
     "latitude":"-18.62275600",
     "longitude":"-173.99029820"
  },
  {
     "name":"Tongatapu",
     "isoCode":"04",
     "countryCode":"TO",
     "latitude":"-21.14659680",
     "longitude":"-175.25154820"
  },
  {
     "name":"Haʻapai",
     "isoCode":"02",
     "countryCode":"TO",
     "latitude":"-19.75000000",
     "longitude":"-174.36666700"
  },
  {
     "name":"Niuas",
     "isoCode":"03",
     "countryCode":"TO",
     "latitude":"-15.95940000",
     "longitude":"-173.78300000"
  },
  {
     "name":"ʻEua",
     "isoCode":"01",
     "countryCode":"TO",
     "latitude":"37.09024000",
     "longitude":"-95.71289100"
  },
  {
     "name":"Markazi Province",
     "isoCode":"00",
     "countryCode":"IR",
     "latitude":"34.61230500",
     "longitude":"49.85472660"
  },
  {
     "name":"Khuzestan Province",
     "isoCode":"06",
     "countryCode":"IR",
     "latitude":"31.43601490",
     "longitude":"49.04131200"
  },
  {
     "name":"Ilam Province",
     "isoCode":"16",
     "countryCode":"IR",
     "latitude":"33.29576180",
     "longitude":"46.67053400"
  },
  {
     "name":"Kermanshah Province",
     "isoCode":"05",
     "countryCode":"IR",
     "latitude":"34.45762330",
     "longitude":"46.67053400"
  },
  {
     "name":"Gilan Province",
     "isoCode":"01",
     "countryCode":"IR",
     "latitude":"37.28094550",
     "longitude":"49.59241340"
  },
  {
     "name":"Chaharmahal and Bakhtiari Province",
     "isoCode":"14",
     "countryCode":"IR",
     "latitude":"31.99704190",
     "longitude":"50.66138490"
  },
  {
     "name":"Qom Province",
     "isoCode":"25",
     "countryCode":"IR",
     "latitude":"34.64157640",
     "longitude":"50.87460350"
  },
  {
     "name":"Isfahan Province",
     "isoCode":"10",
     "countryCode":"IR",
     "latitude":"33.27710730",
     "longitude":"52.36133780"
  },
  {
     "name":"West Azarbaijan Province",
     "isoCode":"04",
     "countryCode":"IR",
     "latitude":"37.45500620",
     "longitude":"45.00000000"
  },
  {
     "name":"Zanjan Province",
     "isoCode":"19",
     "countryCode":"IR",
     "latitude":"36.50181850",
     "longitude":"48.39881860"
  },
  {
     "name":"Kohgiluyeh and Boyer-Ahmad Province",
     "isoCode":"17",
     "countryCode":"IR",
     "latitude":"30.72458600",
     "longitude":"50.84563230"
  },
  {
     "name":"Razavi Khorasan Province",
     "isoCode":"09",
     "countryCode":"IR",
     "latitude":"35.10202530",
     "longitude":"59.10417580"
  },
  {
     "name":"Lorestan Province",
     "isoCode":"15",
     "countryCode":"IR",
     "latitude":"33.58183940",
     "longitude":"48.39881860"
  },
  {
     "name":"Alborz Province",
     "isoCode":"30",
     "countryCode":"IR",
     "latitude":"35.99604670",
     "longitude":"50.92892460"
  },
  {
     "name":"South Khorasan Province",
     "isoCode":"29",
     "countryCode":"IR",
     "latitude":"32.51756430",
     "longitude":"59.10417580"
  },
  {
     "name":"Sistan and Baluchestan",
     "isoCode":"11",
     "countryCode":"IR",
     "latitude":"27.52999060",
     "longitude":"60.58206760"
  },
  {
     "name":"Bushehr Province",
     "isoCode":"18",
     "countryCode":"IR",
     "latitude":"28.76207390",
     "longitude":"51.51500770"
  },
  {
     "name":"Golestan Province",
     "isoCode":"27",
     "countryCode":"IR",
     "latitude":"37.28981230",
     "longitude":"55.13758340"
  },
  {
     "name":"Ardabil Province",
     "isoCode":"24",
     "countryCode":"IR",
     "latitude":"38.48532760",
     "longitude":"47.89112090"
  },
  {
     "name":"Kurdistan Province",
     "isoCode":"12",
     "countryCode":"IR",
     "latitude":"35.95535790",
     "longitude":"47.13621250"
  },
  {
     "name":"Yazd Province",
     "isoCode":"21",
     "countryCode":"IR",
     "latitude":"32.10063870",
     "longitude":"54.43421380"
  },
  {
     "name":"Hormozgan Province",
     "isoCode":"22",
     "countryCode":"IR",
     "latitude":"27.13872300",
     "longitude":"55.13758340"
  },
  {
     "name":"Mazandaran Province",
     "isoCode":"02",
     "countryCode":"IR",
     "latitude":"36.22623930",
     "longitude":"52.53186040"
  },
  {
     "name":"Fars Province",
     "isoCode":"07",
     "countryCode":"IR",
     "latitude":"29.10438130",
     "longitude":"53.04589300"
  },
  {
     "name":"Semnan Province",
     "isoCode":"20",
     "countryCode":"IR",
     "latitude":"35.22555850",
     "longitude":"54.43421380"
  },
  {
     "name":"Qazvin Province",
     "isoCode":"26",
     "countryCode":"IR",
     "latitude":"36.08813170",
     "longitude":"49.85472660"
  },
  {
     "name":"North Khorasan Province",
     "isoCode":"28",
     "countryCode":"IR",
     "latitude":"37.47103530",
     "longitude":"57.10131880"
  },
  {
     "name":"Kerman Province",
     "isoCode":"08",
     "countryCode":"IR",
     "latitude":"29.48500890",
     "longitude":"57.64390480"
  },
  {
     "name":"East Azerbaijan Province",
     "isoCode":"03",
     "countryCode":"IR",
     "latitude":"37.90357330",
     "longitude":"46.26821090"
  },
  {
     "name":"Tehran Province",
     "isoCode":"23",
     "countryCode":"IR",
     "latitude":"35.72484160",
     "longitude":"51.38165300"
  },
  {
     "name":"Niutao Island Council",
     "isoCode":"NIT",
     "countryCode":"TV",
     "latitude":"-6.10642580",
     "longitude":"177.34384290"
  },
  {
     "name":"Nanumanga",
     "isoCode":"NMG",
     "countryCode":"TV",
     "latitude":"-6.28580190",
     "longitude":"176.31992800"
  },
  {
     "name":"Nui",
     "isoCode":"NUI",
     "countryCode":"TV",
     "latitude":"-7.23887680",
     "longitude":"177.14852320"
  },
  {
     "name":"Nanumea",
     "isoCode":"NMA",
     "countryCode":"TV",
     "latitude":"-5.68816170",
     "longitude":"176.13701480"
  },
  {
     "name":"Vaitupu",
     "isoCode":"VAI",
     "countryCode":"TV",
     "latitude":"-7.47673270",
     "longitude":"178.67476750"
  },
  {
     "name":"Funafuti",
     "isoCode":"FUN",
     "countryCode":"TV",
     "latitude":"-8.52114710",
     "longitude":"179.19619260"
  },
  {
     "name":"Nukufetau",
     "isoCode":"NKF",
     "countryCode":"TV",
     "latitude":"-8.00000000",
     "longitude":"178.50000000"
  },
  {
     "name":"Nukulaelae",
     "isoCode":"NKL",
     "countryCode":"TV",
     "latitude":"-9.38111100",
     "longitude":"179.85222200"
  },
  {
     "name":"Dhi Qar Governorate",
     "isoCode":"DQ",
     "countryCode":"IQ",
     "latitude":"31.10422920",
     "longitude":"46.36246860"
  },
  {
     "name":"Babylon Governorate",
     "isoCode":"BB",
     "countryCode":"IQ",
     "latitude":"32.46819100",
     "longitude":"44.55019350"
  },
  {
     "name":"Al-Qādisiyyah Governorate",
     "isoCode":"QA",
     "countryCode":"IQ",
     "latitude":"32.04369100",
     "longitude":"45.14945050"
  },
  {
     "name":"Karbala Governorate",
     "isoCode":"KA",
     "countryCode":"IQ",
     "latitude":"32.40454930",
     "longitude":"43.86732220"
  },
  {
     "name":"Al Muthanna Governorate",
     "isoCode":"MU",
     "countryCode":"IQ",
     "latitude":"29.91331710",
     "longitude":"45.29938620"
  },
  {
     "name":"Baghdad Governorate",
     "isoCode":"BG",
     "countryCode":"IQ",
     "latitude":"33.31526180",
     "longitude":"44.36606530"
  },
  {
     "name":"Basra Governorate",
     "isoCode":"BA",
     "countryCode":"IQ",
     "latitude":"30.51142520",
     "longitude":"47.82962530"
  },
  {
     "name":"Saladin Governorate",
     "isoCode":"SD",
     "countryCode":"IQ",
     "latitude":"34.53375270",
     "longitude":"43.48373800"
  },
  {
     "name":"Najaf Governorate",
     "isoCode":"NA",
     "countryCode":"IQ",
     "latitude":"31.35174860",
     "longitude":"44.09603110"
  },
  {
     "name":"Nineveh Governorate",
     "isoCode":"NI",
     "countryCode":"IQ",
     "latitude":"36.22957400",
     "longitude":"42.23624350"
  },
  {
     "name":"Al Anbar Governorate",
     "isoCode":"AN",
     "countryCode":"IQ",
     "latitude":"32.55976140",
     "longitude":"41.91964710"
  },
  {
     "name":"Diyala Governorate",
     "isoCode":"DI",
     "countryCode":"IQ",
     "latitude":"33.77334870",
     "longitude":"45.14945050"
  },
  {
     "name":"Maysan Governorate",
     "isoCode":"MA",
     "countryCode":"IQ",
     "latitude":"31.87340020",
     "longitude":"47.13621250"
  },
  {
     "name":"Dohuk Governorate",
     "isoCode":"DA",
     "countryCode":"IQ",
     "latitude":"36.90772520",
     "longitude":"43.06316890"
  },
  {
     "name":"Erbil Governorate",
     "isoCode":"AR",
     "countryCode":"IQ",
     "latitude":"36.55706280",
     "longitude":"44.38512630"
  },
  {
     "name":"Sulaymaniyah Governorate",
     "isoCode":"SU",
     "countryCode":"IQ",
     "latitude":"35.54663480",
     "longitude":"45.30036830"
  },
  {
     "name":"Wasit Governorate",
     "isoCode":"WA",
     "countryCode":"IQ",
     "latitude":"32.60240940",
     "longitude":"45.75209850"
  },
  {
     "name":"Kirkuk Governorate",
     "isoCode":"KI",
     "countryCode":"IQ",
     "latitude":"35.32920140",
     "longitude":"43.94367880"
  },
  {
     "name":"Svay Rieng Province",
     "isoCode":"20",
     "countryCode":"KH",
     "latitude":"11.14272200",
     "longitude":"105.82902980"
  },
  {
     "name":"Preah Vihear Province",
     "isoCode":"13",
     "countryCode":"KH",
     "latitude":"14.00857970",
     "longitude":"104.84546190"
  },
  {
     "name":"Prey Veng Province",
     "isoCode":"14",
     "countryCode":"KH",
     "latitude":"11.38024420",
     "longitude":"105.50054830"
  },
  {
     "name":"Takéo Province",
     "isoCode":"21",
     "countryCode":"KH",
     "latitude":"10.93215190",
     "longitude":"104.79877100"
  },
  {
     "name":"Battambang Province",
     "isoCode":"2",
     "countryCode":"KH",
     "latitude":"13.02869710",
     "longitude":"102.98961500"
  },
  {
     "name":"Pursat Province",
     "isoCode":"15",
     "countryCode":"KH",
     "latitude":"12.27209560",
     "longitude":"103.72891670"
  },
  {
     "name":"Kep Province",
     "isoCode":"23",
     "countryCode":"KH",
     "latitude":"10.53608900",
     "longitude":"104.35591580"
  },
  {
     "name":"Kampong Chhnang Province",
     "isoCode":"4",
     "countryCode":"KH",
     "latitude":"12.13923520",
     "longitude":"104.56552730"
  },
  {
     "name":"Pailin Province",
     "isoCode":"24",
     "countryCode":"KH",
     "latitude":"12.90929620",
     "longitude":"102.66755750"
  },
  {
     "name":"Kampot Province",
     "isoCode":"7",
     "countryCode":"KH",
     "latitude":"10.73253510",
     "longitude":"104.37919120"
  },
  {
     "name":"Koh Kong Province",
     "isoCode":"9",
     "countryCode":"KH",
     "latitude":"11.57628040",
     "longitude":"103.35872880"
  },
  {
     "name":"Kandal Province",
     "isoCode":"8",
     "countryCode":"KH",
     "latitude":"11.22373830",
     "longitude":"105.12589550"
  },
  {
     "name":"Banteay Meanchey Province",
     "isoCode":"1",
     "countryCode":"KH",
     "latitude":"13.75319140",
     "longitude":"102.98961500"
  },
  {
     "name":"Mondulkiri Province",
     "isoCode":"11",
     "countryCode":"KH",
     "latitude":"12.78794270",
     "longitude":"107.10119310"
  },
  {
     "name":"Kratié Province",
     "isoCode":"10",
     "countryCode":"KH",
     "latitude":"12.50436080",
     "longitude":"105.96998780"
  },
  {
     "name":"Oddar Meanchey Province",
     "isoCode":"22",
     "countryCode":"KH",
     "latitude":"14.16097380",
     "longitude":"103.82162610"
  },
  {
     "name":"Kampong Speu Province",
     "isoCode":"5",
     "countryCode":"KH",
     "latitude":"11.61551090",
     "longitude":"104.37919120"
  },
  {
     "name":"Sihanoukville Province",
     "isoCode":"18",
     "countryCode":"KH",
     "latitude":"10.75818990",
     "longitude":"103.82162610"
  },
  {
     "name":"Ratanakiri Province",
     "isoCode":"16",
     "countryCode":"KH",
     "latitude":"13.85766070",
     "longitude":"107.10119310"
  },
  {
     "name":"Kampong Cham Province",
     "isoCode":"3",
     "countryCode":"KH",
     "latitude":"12.09829180",
     "longitude":"105.31311850"
  },
  {
     "name":"Siem Reap Province",
     "isoCode":"17",
     "countryCode":"KH",
     "latitude":"13.33026600",
     "longitude":"104.10013260"
  },
  {
     "name":"Stung Treng Province",
     "isoCode":"19",
     "countryCode":"KH",
     "latitude":"13.57647300",
     "longitude":"105.96998780"
  },
  {
     "name":"Phnom Penh",
     "isoCode":"12",
     "countryCode":"KH",
     "latitude":"11.55637380",
     "longitude":"104.92820990"
  },
  {
     "name":"North Hamgyong Province",
     "isoCode":"09",
     "countryCode":"KP",
     "latitude":"41.81487580",
     "longitude":"129.45819550"
  },
  {
     "name":"Ryanggang Province",
     "isoCode":"10",
     "countryCode":"KP",
     "latitude":"41.23189210",
     "longitude":"128.50763590"
  },
  {
     "name":"South Pyongan Province",
     "isoCode":"02",
     "countryCode":"KP",
     "latitude":"39.35391780",
     "longitude":"126.16827100"
  },
  {
     "name":"Chagang Province",
     "isoCode":"04",
     "countryCode":"KP",
     "latitude":"40.72028090",
     "longitude":"126.56211370"
  },
  {
     "name":"Kangwon Province",
     "isoCode":"07",
     "countryCode":"KP",
     "latitude":"38.84323930",
     "longitude":"127.55970670"
  },
  {
     "name":"South Hamgyong Province",
     "isoCode":"08",
     "countryCode":"KP",
     "latitude":"40.37253390",
     "longitude":"128.29888400"
  },
  {
     "name":"Rason",
     "isoCode":"13",
     "countryCode":"KP",
     "latitude":"42.25690630",
     "longitude":"130.29771860"
  },
  {
     "name":"North Pyongan Province",
     "isoCode":"03",
     "countryCode":"KP",
     "latitude":"39.92556180",
     "longitude":"125.39280250"
  },
  {
     "name":"South Hwanghae Province",
     "isoCode":"05",
     "countryCode":"KP",
     "latitude":"38.20072150",
     "longitude":"125.47819260"
  },
  {
     "name":"North Hwanghae Province",
     "isoCode":"06",
     "countryCode":"KP",
     "latitude":"38.37860850",
     "longitude":"126.43643630"
  },
  {
     "name":"Pyongyang",
     "isoCode":"01",
     "countryCode":"KP",
     "latitude":"39.03921930",
     "longitude":"125.76252410"
  },
  {
     "name":"Meghalaya",
     "isoCode":"ML",
     "countryCode":"IN",
     "latitude":"25.46703080",
     "longitude":"91.36621600"
  },
  {
     "name":"Haryana",
     "isoCode":"HR",
     "countryCode":"IN",
     "latitude":"29.05877570",
     "longitude":"76.08560100"
  },
  {
     "name":"Maharashtra",
     "isoCode":"MH",
     "countryCode":"IN",
     "latitude":"19.75147980",
     "longitude":"75.71388840"
  },
  {
     "name":"Goa",
     "isoCode":"GA",
     "countryCode":"IN",
     "latitude":"15.29932650",
     "longitude":"74.12399600"
  },
  {
     "name":"Manipur",
     "isoCode":"MN",
     "countryCode":"IN",
     "latitude":"24.66371730",
     "longitude":"93.90626880"
  },
  {
     "name":"Puducherry",
     "isoCode":"PY",
     "countryCode":"IN",
     "latitude":"11.94159150",
     "longitude":"79.80831330"
  },
  {
     "name":"Telangana",
     "isoCode":"TG",
     "countryCode":"IN",
     "latitude":"18.11243720",
     "longitude":"79.01929970"
  },
  {
     "name":"Odisha",
     "isoCode":"OR",
     "countryCode":"IN",
     "latitude":"20.95166580",
     "longitude":"85.09852360"
  },
  {
     "name":"Rajasthan",
     "isoCode":"RJ",
     "countryCode":"IN",
     "latitude":"27.02380360",
     "longitude":"74.21793260"
  },
  {
     "name":"Punjab",
     "isoCode":"PB",
     "countryCode":"IN",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Uttarakhand",
     "isoCode":"UT",
     "countryCode":"IN",
     "latitude":"30.06675300",
     "longitude":"79.01929970"
  },
  {
     "name":"Andhra Pradesh",
     "isoCode":"AP",
     "countryCode":"IN",
     "latitude":"15.91289980",
     "longitude":"79.73998750"
  },
  {
     "name":"Nagaland",
     "isoCode":"NL",
     "countryCode":"IN",
     "latitude":"26.15843540",
     "longitude":"94.56244260"
  },
  {
     "name":"Lakshadweep",
     "isoCode":"LD",
     "countryCode":"IN",
     "latitude":"10.32802650",
     "longitude":"72.78463360"
  },
  {
     "name":"Himachal Pradesh",
     "isoCode":"HP",
     "countryCode":"IN",
     "latitude":"31.10482940",
     "longitude":"77.17339010"
  },
  {
     "name":"Delhi",
     "isoCode":"DL",
     "countryCode":"IN",
     "latitude":"28.70405920",
     "longitude":"77.10249020"
  },
  {
     "name":"Uttar Pradesh",
     "isoCode":"UP",
     "countryCode":"IN",
     "latitude":"26.84670880",
     "longitude":"80.94615920"
  },
  {
     "name":"Andaman and Nicobar Islands",
     "isoCode":"AN",
     "countryCode":"IN",
     "latitude":"11.74008670",
     "longitude":"92.65864010"
  },
  {
     "name":"Arunachal Pradesh",
     "isoCode":"AR",
     "countryCode":"IN",
     "latitude":"28.21799940",
     "longitude":"94.72775280"
  },
  {
     "name":"Jharkhand",
     "isoCode":"JH",
     "countryCode":"IN",
     "latitude":"23.61018080",
     "longitude":"85.27993540"
  },
  {
     "name":"Karnataka",
     "isoCode":"KA",
     "countryCode":"IN",
     "latitude":"15.31727750",
     "longitude":"75.71388840"
  },
  {
     "name":"Assam",
     "isoCode":"AS",
     "countryCode":"IN",
     "latitude":"26.20060430",
     "longitude":"92.93757390"
  },
  {
     "name":"Kerala",
     "isoCode":"KL",
     "countryCode":"IN",
     "latitude":"10.85051590",
     "longitude":"76.27108330"
  },
  {
     "name":"Jammu and Kashmir",
     "isoCode":"JK",
     "countryCode":"IN",
     "latitude":"33.27783900",
     "longitude":"75.34121790"
  },
  {
     "name":"Gujarat",
     "isoCode":"GJ",
     "countryCode":"IN",
     "latitude":"22.25865200",
     "longitude":"71.19238050"
  },
  {
     "name":"Chandigarh",
     "isoCode":"CH",
     "countryCode":"IN",
     "latitude":"30.73331480",
     "longitude":"76.77941790"
  },
  {
     "name":"Dadra and Nagar Haveli and Daman and Diu",
     "isoCode":"DH",
     "countryCode":"IN",
     "latitude":"20.39737360",
     "longitude":"72.83279910"
  },
  {
     "name":"Sikkim",
     "isoCode":"SK",
     "countryCode":"IN",
     "latitude":"27.53297180",
     "longitude":"88.51221780"
  },
  {
     "name":"Tamil Nadu",
     "isoCode":"TN",
     "countryCode":"IN",
     "latitude":"11.12712250",
     "longitude":"78.65689420"
  },
  {
     "name":"Mizoram",
     "isoCode":"MZ",
     "countryCode":"IN",
     "latitude":"23.16454300",
     "longitude":"92.93757390"
  },
  {
     "name":"Bihar",
     "isoCode":"BR",
     "countryCode":"IN",
     "latitude":"25.09607420",
     "longitude":"85.31311940"
  },
  {
     "name":"Tripura",
     "isoCode":"TR",
     "countryCode":"IN",
     "latitude":"23.94084820",
     "longitude":"91.98815270"
  },
  {
     "name":"Madhya Pradesh",
     "isoCode":"MP",
     "countryCode":"IN",
     "latitude":"22.97342290",
     "longitude":"78.65689420"
  },
  {
     "name":"Chhattisgarh",
     "isoCode":"CT",
     "countryCode":"IN",
     "latitude":"21.27865670",
     "longitude":"81.86614420"
  },
  {
     "name":"Choluteca Department",
     "isoCode":"CH",
     "countryCode":"HN",
     "latitude":"13.25043250",
     "longitude":"-87.14228950"
  },
  {
     "name":"Comayagua Department",
     "isoCode":"CM",
     "countryCode":"HN",
     "latitude":"14.55348280",
     "longitude":"-87.61863790"
  },
  {
     "name":"El Paraíso Department",
     "isoCode":"EP",
     "countryCode":"HN",
     "latitude":"13.98212940",
     "longitude":"-86.49965460"
  },
  {
     "name":"Intibucá Department",
     "isoCode":"IN",
     "countryCode":"HN",
     "latitude":"14.37273400",
     "longitude":"-88.24611830"
  },
  {
     "name":"Bay Islands Department",
     "isoCode":"IB",
     "countryCode":"HN",
     "latitude":"16.48266140",
     "longitude":"-85.87932520"
  },
  {
     "name":"Cortés Department",
     "isoCode":"CR",
     "countryCode":"HN",
     "latitude":"15.49235080",
     "longitude":"-88.09007620"
  },
  {
     "name":"Atlántida Department",
     "isoCode":"AT",
     "countryCode":"HN",
     "latitude":"15.66962830",
     "longitude":"-87.14228950"
  },
  {
     "name":"Gracias a Dios Department",
     "isoCode":"GD",
     "countryCode":"HN",
     "latitude":"15.34180600",
     "longitude":"-84.60604490"
  },
  {
     "name":"Copán Department",
     "isoCode":"CP",
     "countryCode":"HN",
     "latitude":"14.93608380",
     "longitude":"-88.86469800"
  },
  {
     "name":"Olancho Department",
     "isoCode":"OL",
     "countryCode":"HN",
     "latitude":"14.80674060",
     "longitude":"-85.76666450"
  },
  {
     "name":"Colón Department",
     "isoCode":"CL",
     "countryCode":"HN",
     "latitude":"15.64259650",
     "longitude":"-85.52002400"
  },
  {
     "name":"Francisco Morazán Department",
     "isoCode":"FM",
     "countryCode":"HN",
     "latitude":"14.45411000",
     "longitude":"-87.06242610"
  },
  {
     "name":"Santa Bárbara Department",
     "isoCode":"SB",
     "countryCode":"HN",
     "latitude":"15.12027950",
     "longitude":"-88.40160410"
  },
  {
     "name":"Lempira Department",
     "isoCode":"LE",
     "countryCode":"HN",
     "latitude":"14.18876980",
     "longitude":"-88.55653100"
  },
  {
     "name":"Valle Department",
     "isoCode":"VA",
     "countryCode":"HN",
     "latitude":"13.57829360",
     "longitude":"-87.57912870"
  },
  {
     "name":"Ocotepeque Department",
     "isoCode":"OC",
     "countryCode":"HN",
     "latitude":"14.51703470",
     "longitude":"-89.05615320"
  },
  {
     "name":"Yoro Department",
     "isoCode":"YO",
     "countryCode":"HN",
     "latitude":"15.29496790",
     "longitude":"-87.14228950"
  },
  {
     "name":"La Paz Department",
     "isoCode":"LP",
     "countryCode":"HN",
     "latitude":"-15.08924160",
     "longitude":"-68.52471490"
  },
  {
     "name":"Northland Region",
     "isoCode":"NTL",
     "countryCode":"NZ",
     "latitude":"-35.41361720",
     "longitude":"173.93208060"
  },
  {
     "name":"Manawatu-Wanganui Region",
     "isoCode":"MWT",
     "countryCode":"NZ",
     "latitude":"-39.72733560",
     "longitude":"175.43755740"
  },
  {
     "name":"Waikato Region",
     "isoCode":"WKO",
     "countryCode":"NZ",
     "latitude":"-37.61908620",
     "longitude":"175.02334600"
  },
  {
     "name":"Otago Region",
     "isoCode":"OTA",
     "countryCode":"NZ",
     "latitude":"-45.47906710",
     "longitude":"170.15475670"
  },
  {
     "name":"Marlborough Region",
     "isoCode":"MBH",
     "countryCode":"NZ",
     "latitude":"-41.59168830",
     "longitude":"173.76240530"
  },
  {
     "name":"West Coast Region",
     "isoCode":"WTC",
     "countryCode":"NZ",
     "latitude":"62.41136340",
     "longitude":"-149.07297140"
  },
  {
     "name":"Wellington Region",
     "isoCode":"WGN",
     "countryCode":"NZ",
     "latitude":"-41.02993230",
     "longitude":"175.43755740"
  },
  {
     "name":"Canterbury Region",
     "isoCode":"CAN",
     "countryCode":"NZ",
     "latitude":"-43.75422750",
     "longitude":"171.16372450"
  },
  {
     "name":"Chatham Islands",
     "isoCode":"CIT",
     "countryCode":"NZ",
     "latitude":"-44.00575230",
     "longitude":"-176.54006740"
  },
  {
     "name":"Gisborne District",
     "isoCode":"GIS",
     "countryCode":"NZ",
     "latitude":"-38.13581740",
     "longitude":"178.32393090"
  },
  {
     "name":"Taranaki Region",
     "isoCode":"TKI",
     "countryCode":"NZ",
     "latitude":"-39.35381490",
     "longitude":"174.43827210"
  },
  {
     "name":"Nelson Region",
     "isoCode":"NSN",
     "countryCode":"NZ",
     "latitude":"-41.29853970",
     "longitude":"173.24414910"
  },
  {
     "name":"Southland Region",
     "isoCode":"STL",
     "countryCode":"NZ",
     "latitude":"-45.84891590",
     "longitude":"167.67553870"
  },
  {
     "name":"Auckland Region",
     "isoCode":"AUK",
     "countryCode":"NZ",
     "latitude":"-36.66753280",
     "longitude":"174.77333250"
  },
  {
     "name":"Tasman District",
     "isoCode":"TAS",
     "countryCode":"NZ",
     "latitude":"-41.45711840",
     "longitude":"172.82097400"
  },
  {
     "name":"Bay of Plenty Region",
     "isoCode":"BOP",
     "countryCode":"NZ",
     "latitude":"-37.42339170",
     "longitude":"176.74163740"
  },
  {
     "name":"Hawke's Bay Region",
     "isoCode":"HKB",
     "countryCode":"NZ",
     "latitude":"-39.60165970",
     "longitude":"176.58044730"
  },
  {
     "name":"Saint John Parish",
     "isoCode":"05",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Mark Parish",
     "isoCode":"08",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint David Parish",
     "isoCode":"03",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint George Parish",
     "isoCode":"04",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Patrick Parish",
     "isoCode":"09",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Peter Parish",
     "isoCode":"11",
     "countryCode":"DM",
     "latitude":"40.45241940",
     "longitude":"-80.00850560"
  },
  {
     "name":"Saint Andrew Parish",
     "isoCode":"02",
     "countryCode":"DM",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Saint Luke Parish",
     "isoCode":"07",
     "countryCode":"DM",
     "latitude":"42.10513630",
     "longitude":"-80.05707220"
  },
  {
     "name":"Saint Paul Parish",
     "isoCode":"10",
     "countryCode":"DM",
     "latitude":"38.86146000",
     "longitude":"-90.74356190"
  },
  {
     "name":"Saint Joseph Parish",
     "isoCode":"06",
     "countryCode":"DM",
     "latitude":"39.02227120",
     "longitude":"-94.71765040"
  },
  {
     "name":"El Seibo Province",
     "isoCode":"08",
     "countryCode":"DO",
     "latitude":"18.76584960",
     "longitude":"-69.04066800"
  },
  {
     "name":"La Romana Province",
     "isoCode":"12",
     "countryCode":"DO",
     "latitude":"18.43102710",
     "longitude":"-68.98373730"
  },
  {
     "name":"Sánchez Ramírez Province",
     "isoCode":"24",
     "countryCode":"DO",
     "latitude":"19.05270600",
     "longitude":"-70.14922640"
  },
  {
     "name":"Hermanas Mirabal Province",
     "isoCode":"19",
     "countryCode":"DO",
     "latitude":"19.37475590",
     "longitude":"-70.35132350"
  },
  {
     "name":"Barahona Province",
     "isoCode":"04",
     "countryCode":"DO",
     "latitude":"18.21390660",
     "longitude":"-71.10437590"
  },
  {
     "name":"San Cristóbal Province",
     "isoCode":"21",
     "countryCode":"DO",
     "latitude":"18.41804140",
     "longitude":"-70.10658490"
  },
  {
     "name":"Puerto Plata Province",
     "isoCode":"18",
     "countryCode":"DO",
     "latitude":"19.75432250",
     "longitude":"-70.83328470"
  },
  {
     "name":"Santo Domingo Province",
     "isoCode":"32",
     "countryCode":"DO",
     "latitude":"18.51042530",
     "longitude":"-69.84040540"
  },
  {
     "name":"María Trinidad Sánchez Province",
     "isoCode":"14",
     "countryCode":"DO",
     "latitude":"19.37345970",
     "longitude":"-69.85144390"
  },
  {
     "name":"Distrito Nacional",
     "isoCode":"01",
     "countryCode":"DO",
     "latitude":"18.48605750",
     "longitude":"-69.93121170"
  },
  {
     "name":"Peravia Province",
     "isoCode":"17",
     "countryCode":"DO",
     "latitude":"18.27865940",
     "longitude":"-70.33358870"
  },
  {
     "name":"Independencia",
     "isoCode":"10",
     "countryCode":"DO",
     "latitude":"32.63357480",
     "longitude":"-115.42892940"
  },
  {
     "name":"San Juan Province",
     "isoCode":"22",
     "countryCode":"DO",
     "latitude":"-31.52871270",
     "longitude":"-68.53604030"
  },
  {
     "name":"Monseñor Nouel Province",
     "isoCode":"28",
     "countryCode":"DO",
     "latitude":"18.92152340",
     "longitude":"-70.38368150"
  },
  {
     "name":"Santiago Rodríguez Province",
     "isoCode":"26",
     "countryCode":"DO",
     "latitude":"19.47131810",
     "longitude":"-71.33958010"
  },
  {
     "name":"Pedernales Province",
     "isoCode":"16",
     "countryCode":"DO",
     "latitude":"17.85376260",
     "longitude":"-71.33032090"
  },
  {
     "name":"Espaillat Province",
     "isoCode":"09",
     "countryCode":"DO",
     "latitude":"19.62776580",
     "longitude":"-70.27867750"
  },
  {
     "name":"Samaná Province",
     "isoCode":"20",
     "countryCode":"DO",
     "latitude":"19.20583710",
     "longitude":"-69.33629490"
  },
  {
     "name":"Valverde Province",
     "isoCode":"27",
     "countryCode":"DO",
     "latitude":"19.58812210",
     "longitude":"-70.98033100"
  },
  {
     "name":"Baoruco Province",
     "isoCode":"03",
     "countryCode":"DO",
     "latitude":"18.48798980",
     "longitude":"-71.41822490"
  },
  {
     "name":"Hato Mayor Province",
     "isoCode":"30",
     "countryCode":"DO",
     "latitude":"18.76357990",
     "longitude":"-69.25576370"
  },
  {
     "name":"Dajabón Province",
     "isoCode":"05",
     "countryCode":"DO",
     "latitude":"19.54992410",
     "longitude":"-71.70865140"
  },
  {
     "name":"Santiago Province",
     "isoCode":"25",
     "countryCode":"DO",
     "latitude":"-33.45000000",
     "longitude":"-70.66670000"
  },
  {
     "name":"La Altagracia Province",
     "isoCode":"11",
     "countryCode":"DO",
     "latitude":"18.58502360",
     "longitude":"-68.62010720"
  },
  {
     "name":"San Pedro de Macorís",
     "isoCode":"23",
     "countryCode":"DO",
     "latitude":"18.46266000",
     "longitude":"-69.30512340"
  },
  {
     "name":"Monte Plata Province",
     "isoCode":"29",
     "countryCode":"DO",
     "latitude":"18.80808780",
     "longitude":"-69.78691460"
  },
  {
     "name":"San José de Ocoa Province",
     "isoCode":"31",
     "countryCode":"DO",
     "latitude":"18.54385800",
     "longitude":"-70.50418160"
  },
  {
     "name":"Duarte Province",
     "isoCode":"06",
     "countryCode":"DO",
     "latitude":"19.20908230",
     "longitude":"-70.02700040"
  },
  {
     "name":"Azua Province",
     "isoCode":"02",
     "countryCode":"DO",
     "latitude":"18.45527090",
     "longitude":"-70.73809280"
  },
  {
     "name":"Monte Cristi Province",
     "isoCode":"15",
     "countryCode":"DO",
     "latitude":"19.73968990",
     "longitude":"-71.44339840"
  },
  {
     "name":"La Vega Province",
     "isoCode":"13",
     "countryCode":"DO",
     "latitude":"19.22115540",
     "longitude":"-70.52887530"
  },
  {
     "name":"Nord",
     "isoCode":"ND",
     "countryCode":"HT",
     "latitude":"43.19052600",
     "longitude":"-89.43792100"
  },
  {
     "name":"Nippes",
     "isoCode":"NI",
     "countryCode":"HT",
     "latitude":"18.39907350",
     "longitude":"-73.41802110"
  },
  {
     "name":"Grand'Anse",
     "isoCode":"GA",
     "countryCode":"HT",
     "latitude":"12.01666670",
     "longitude":"-61.76666670"
  },
  {
     "name":"Ouest",
     "isoCode":"OU",
     "countryCode":"HT",
     "latitude":"45.45472490",
     "longitude":"-73.65023650"
  },
  {
     "name":"Nord-Est",
     "isoCode":"NE",
     "countryCode":"HT",
     "latitude":"19.48897230",
     "longitude":"-71.85713310"
  },
  {
     "name":"Sud",
     "isoCode":"SD",
     "countryCode":"HT",
     "latitude":"29.92132480",
     "longitude":"-90.09737720"
  },
  {
     "name":"Artibonite",
     "isoCode":"AR",
     "countryCode":"HT",
     "latitude":"19.36290200",
     "longitude":"-72.42581450"
  },
  {
     "name":"Sud-Est",
     "isoCode":"SE",
     "countryCode":"HT",
     "latitude":"18.27835980",
     "longitude":"-72.35479150"
  },
  {
     "name":"Centre",
     "isoCode":"CE",
     "countryCode":"HT",
     "latitude":"32.83702510",
     "longitude":"-96.77738820"
  },
  {
     "name":"Nord-Ouest",
     "isoCode":"NO",
     "countryCode":"HT",
     "latitude":"19.83740090",
     "longitude":"-73.04052770"
  },
  {
     "name":"San Vicente Department",
     "isoCode":"SV",
     "countryCode":"SV",
     "latitude":"13.58685610",
     "longitude":"-88.74939980"
  },
  {
     "name":"Santa Ana Department",
     "isoCode":"SA",
     "countryCode":"SV",
     "latitude":"14.14611210",
     "longitude":"-89.51200840"
  },
  {
     "name":"Usulután Department",
     "isoCode":"US",
     "countryCode":"SV",
     "latitude":"13.44706340",
     "longitude":"-88.55653100"
  },
  {
     "name":"Morazán Department",
     "isoCode":"MO",
     "countryCode":"SV",
     "latitude":"13.76820000",
     "longitude":"-88.12913870"
  },
  {
     "name":"Chalatenango Department",
     "isoCode":"CH",
     "countryCode":"SV",
     "latitude":"14.19166480",
     "longitude":"-89.17059980"
  },
  {
     "name":"Cabañas Department",
     "isoCode":"CA",
     "countryCode":"SV",
     "latitude":"13.86482880",
     "longitude":"-88.74939980"
  },
  {
     "name":"San Salvador Department",
     "isoCode":"SS",
     "countryCode":"SV",
     "latitude":"13.77399970",
     "longitude":"-89.20867730"
  },
  {
     "name":"La Libertad Department",
     "isoCode":"LI",
     "countryCode":"SV",
     "latitude":"13.68176610",
     "longitude":"-89.36062980"
  },
  {
     "name":"San Miguel Department",
     "isoCode":"SM",
     "countryCode":"SV",
     "latitude":"13.44510410",
     "longitude":"-88.24611830"
  },
  {
     "name":"La Paz Department",
     "isoCode":"PA",
     "countryCode":"SV",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Cuscatlán Department",
     "isoCode":"CU",
     "countryCode":"SV",
     "latitude":"13.86619570",
     "longitude":"-89.05615320"
  },
  {
     "name":"La Unión Department",
     "isoCode":"UN",
     "countryCode":"SV",
     "latitude":"13.48864430",
     "longitude":"-87.89424510"
  },
  {
     "name":"Ahuachapán Department",
     "isoCode":"AH",
     "countryCode":"SV",
     "latitude":"13.82161480",
     "longitude":"-89.92532330"
  },
  {
     "name":"Sonsonate Department",
     "isoCode":"SO",
     "countryCode":"SV",
     "latitude":"13.68235800",
     "longitude":"-89.66281110"
  },
  {
     "name":"Braslovče Municipality",
     "isoCode":"151",
     "countryCode":"SI",
     "latitude":"46.28361920",
     "longitude":"15.04183200"
  },
  {
     "name":"Lenart Municipality",
     "isoCode":"058",
     "countryCode":"SI",
     "latitude":"46.58344240",
     "longitude":"15.82621250"
  },
  {
     "name":"Oplotnica",
     "isoCode":"171",
     "countryCode":"SI",
     "latitude":"46.38716300",
     "longitude":"15.44581310"
  },
  {
     "name":"Velike Lašče Municipality",
     "isoCode":"134",
     "countryCode":"SI",
     "latitude":"45.83365910",
     "longitude":"14.63623630"
  },
  {
     "name":"Hajdina Municipality",
     "isoCode":"159",
     "countryCode":"SI",
     "latitude":"46.41850140",
     "longitude":"15.82447220"
  },
  {
     "name":"Podčetrtek Municipality",
     "isoCode":"092",
     "countryCode":"SI",
     "latitude":"46.17395420",
     "longitude":"15.60138160"
  },
  {
     "name":"Cankova Municipality",
     "isoCode":"152",
     "countryCode":"SI",
     "latitude":"46.71823700",
     "longitude":"16.01972220"
  },
  {
     "name":"Vitanje Municipality",
     "isoCode":"137",
     "countryCode":"SI",
     "latitude":"46.38153230",
     "longitude":"15.29506870"
  },
  {
     "name":"Sežana Municipality",
     "isoCode":"111",
     "countryCode":"SI",
     "latitude":"45.72751090",
     "longitude":"13.86619310"
  },
  {
     "name":"Kidričevo Municipality",
     "isoCode":"045",
     "countryCode":"SI",
     "latitude":"46.39575720",
     "longitude":"15.79259060"
  },
  {
     "name":"Črenšovci Municipality",
     "isoCode":"015",
     "countryCode":"SI",
     "latitude":"46.57200290",
     "longitude":"16.28773460"
  },
  {
     "name":"Idrija Municipality",
     "isoCode":"036",
     "countryCode":"SI",
     "latitude":"46.00409390",
     "longitude":"13.97754930"
  },
  {
     "name":"Trnovska Vas Municipality",
     "isoCode":"185",
     "countryCode":"SI",
     "latitude":"46.52940350",
     "longitude":"15.88531180"
  },
  {
     "name":"Vodice Municipality",
     "isoCode":"138",
     "countryCode":"SI",
     "latitude":"46.18966430",
     "longitude":"14.49385390"
  },
  {
     "name":"Ravne na Koroškem Municipality",
     "isoCode":"103",
     "countryCode":"SI",
     "latitude":"46.55211940",
     "longitude":"14.95990840"
  },
  {
     "name":"Lovrenc na Pohorju Municipality",
     "isoCode":"167",
     "countryCode":"SI",
     "latitude":"46.54196380",
     "longitude":"15.40004430"
  },
  {
     "name":"Majšperk Municipality",
     "isoCode":"069",
     "countryCode":"SI",
     "latitude":"46.35030190",
     "longitude":"15.73405950"
  },
  {
     "name":"Loški Potok Municipality",
     "isoCode":"066",
     "countryCode":"SI",
     "latitude":"45.69096370",
     "longitude":"14.59859700"
  },
  {
     "name":"Domžale Municipality",
     "isoCode":"023",
     "countryCode":"SI",
     "latitude":"46.14382690",
     "longitude":"14.63752790"
  },
  {
     "name":"Rečica ob Savinji Municipality",
     "isoCode":"209",
     "countryCode":"SI",
     "latitude":"46.32337900",
     "longitude":"14.92236700"
  },
  {
     "name":"Podlehnik Municipality",
     "isoCode":"172",
     "countryCode":"SI",
     "latitude":"46.33107820",
     "longitude":"15.87858360"
  },
  {
     "name":"Cerknica Municipality",
     "isoCode":"013",
     "countryCode":"SI",
     "latitude":"45.79662550",
     "longitude":"14.39217700"
  },
  {
     "name":"Vransko Municipality",
     "isoCode":"189",
     "countryCode":"SI",
     "latitude":"46.23900600",
     "longitude":"14.95272490"
  },
  {
     "name":"Sveta Ana Municipality",
     "isoCode":"181",
     "countryCode":"SI",
     "latitude":"46.65000000",
     "longitude":"15.84527800"
  },
  {
     "name":"Brezovica Municipality",
     "isoCode":"008",
     "countryCode":"SI",
     "latitude":"45.95593510",
     "longitude":"14.43499520"
  },
  {
     "name":"Benedikt Municipality",
     "isoCode":"148",
     "countryCode":"SI",
     "latitude":"46.61558410",
     "longitude":"15.89572810"
  },
  {
     "name":"Divača Municipality",
     "isoCode":"019",
     "countryCode":"SI",
     "latitude":"45.68060690",
     "longitude":"13.97203120"
  },
  {
     "name":"Moravče Municipality",
     "isoCode":"077",
     "countryCode":"SI",
     "latitude":"46.13627810",
     "longitude":"14.74600100"
  },
  {
     "name":"Slovenj Gradec City Municipality",
     "isoCode":"112",
     "countryCode":"SI",
     "latitude":"46.48777180",
     "longitude":"15.07294780"
  },
  {
     "name":"Škocjan Municipality",
     "isoCode":"121",
     "countryCode":"SI",
     "latitude":"45.91754540",
     "longitude":"15.31017360"
  },
  {
     "name":"Šentjur Municipality",
     "isoCode":"120",
     "countryCode":"SI",
     "latitude":"46.26543390",
     "longitude":"15.40800000"
  },
  {
     "name":"Pesnica Municipality",
     "isoCode":"089",
     "countryCode":"SI",
     "latitude":"46.60887550",
     "longitude":"15.67570510"
  },
  {
     "name":"Dol pri Ljubljani Municipality",
     "isoCode":"022",
     "countryCode":"SI",
     "latitude":"46.08843860",
     "longitude":"14.64247920"
  },
  {
     "name":"Loška Dolina Municipality",
     "isoCode":"065",
     "countryCode":"SI",
     "latitude":"45.64779080",
     "longitude":"14.49731470"
  },
  {
     "name":"Hoče–Slivnica Municipality",
     "isoCode":"160",
     "countryCode":"SI",
     "latitude":"46.47785800",
     "longitude":"15.64760050"
  },
  {
     "name":"Cerkvenjak Municipality",
     "isoCode":"153",
     "countryCode":"SI",
     "latitude":"46.56707110",
     "longitude":"15.94297530"
  },
  {
     "name":"Naklo Municipality",
     "isoCode":"082",
     "countryCode":"SI",
     "latitude":"46.27186630",
     "longitude":"14.31569320"
  },
  {
     "name":"Cerkno Municipality",
     "isoCode":"014",
     "countryCode":"SI",
     "latitude":"46.12884140",
     "longitude":"13.98940270"
  },
  {
     "name":"Bistrica ob Sotli Municipality",
     "isoCode":"149",
     "countryCode":"SI",
     "latitude":"46.05655790",
     "longitude":"15.66259470"
  },
  {
     "name":"Kamnik Municipality",
     "isoCode":"043",
     "countryCode":"SI",
     "latitude":"46.22216660",
     "longitude":"14.60707270"
  },
  {
     "name":"Bovec Municipality",
     "isoCode":"006",
     "countryCode":"SI",
     "latitude":"46.33804950",
     "longitude":"13.55241740"
  },
  {
     "name":"Zavrč Municipality",
     "isoCode":"143",
     "countryCode":"SI",
     "latitude":"46.35713000",
     "longitude":"16.04777470"
  },
  {
     "name":"Ajdovščina Municipality",
     "isoCode":"001",
     "countryCode":"SI",
     "latitude":"45.88707760",
     "longitude":"13.90428180"
  },
  {
     "name":"Pivka Municipality",
     "isoCode":"091",
     "countryCode":"SI",
     "latitude":"45.67892960",
     "longitude":"14.25426890"
  },
  {
     "name":"Štore Municipality",
     "isoCode":"127",
     "countryCode":"SI",
     "latitude":"46.22225140",
     "longitude":"15.31261160"
  },
  {
     "name":"Kozje Municipality",
     "isoCode":"051",
     "countryCode":"SI",
     "latitude":"46.07332110",
     "longitude":"15.55967190"
  },
  {
     "name":"Municipality of Škofljica",
     "isoCode":"123",
     "countryCode":"SI",
     "latitude":"45.98409620",
     "longitude":"14.57466260"
  },
  {
     "name":"Prebold Municipality",
     "isoCode":"174",
     "countryCode":"SI",
     "latitude":"46.23591360",
     "longitude":"15.09369120"
  },
  {
     "name":"Dobrovnik Municipality",
     "isoCode":"156",
     "countryCode":"SI",
     "latitude":"46.65386620",
     "longitude":"16.35065940"
  },
  {
     "name":"Mozirje Municipality",
     "isoCode":"079",
     "countryCode":"SI",
     "latitude":"46.33943500",
     "longitude":"14.96024130"
  },
  {
     "name":"City Municipality of Celje",
     "isoCode":"011",
     "countryCode":"SI",
     "latitude":"46.23974950",
     "longitude":"15.26770630"
  },
  {
     "name":"Žiri Municipality",
     "isoCode":"147",
     "countryCode":"SI",
     "latitude":"46.04724990",
     "longitude":"14.10984510"
  },
  {
     "name":"Horjul Municipality",
     "isoCode":"162",
     "countryCode":"SI",
     "latitude":"46.02253780",
     "longitude":"14.29862690"
  },
  {
     "name":"Tabor Municipality",
     "isoCode":"184",
     "countryCode":"SI",
     "latitude":"46.21079210",
     "longitude":"15.01742490"
  },
  {
     "name":"Radeče Municipality",
     "isoCode":"099",
     "countryCode":"SI",
     "latitude":"46.06669540",
     "longitude":"15.18204380"
  },
  {
     "name":"Vipava Municipality",
     "isoCode":"136",
     "countryCode":"SI",
     "latitude":"45.84126740",
     "longitude":"13.96096130"
  },
  {
     "name":"Kungota",
     "isoCode":"055",
     "countryCode":"SI",
     "latitude":"46.64187930",
     "longitude":"15.60362880"
  },
  {
     "name":"Slovenske Konjice Municipality",
     "isoCode":"114",
     "countryCode":"SI",
     "latitude":"46.33691910",
     "longitude":"15.42147080"
  },
  {
     "name":"Osilnica Municipality",
     "isoCode":"088",
     "countryCode":"SI",
     "latitude":"45.54184670",
     "longitude":"14.71563030"
  },
  {
     "name":"Borovnica Municipality",
     "isoCode":"005",
     "countryCode":"SI",
     "latitude":"45.90445250",
     "longitude":"14.38241890"
  },
  {
     "name":"Piran Municipality",
     "isoCode":"090",
     "countryCode":"SI",
     "latitude":"45.52888560",
     "longitude":"13.56807350"
  },
  {
     "name":"Bled Municipality",
     "isoCode":"003",
     "countryCode":"SI",
     "latitude":"46.36832660",
     "longitude":"14.11457980"
  },
  {
     "name":"Jezersko Municipality",
     "isoCode":"163",
     "countryCode":"SI",
     "latitude":"46.39427940",
     "longitude":"14.49855590"
  },
  {
     "name":"Rače–Fram Municipality",
     "isoCode":"098",
     "countryCode":"SI",
     "latitude":"46.45420830",
     "longitude":"15.63294670"
  },
  {
     "name":"Nova Gorica City Municipality",
     "isoCode":"084",
     "countryCode":"SI",
     "latitude":"45.97627600",
     "longitude":"13.73088810"
  },
  {
     "name":"Razkrižje Municipality",
     "isoCode":"176",
     "countryCode":"SI",
     "latitude":"46.52263390",
     "longitude":"16.26686380"
  },
  {
     "name":"Ribnica na Pohorju Municipality",
     "isoCode":"177",
     "countryCode":"SI",
     "latitude":"46.53561450",
     "longitude":"15.26745380"
  },
  {
     "name":"Muta Municipality",
     "isoCode":"081",
     "countryCode":"SI",
     "latitude":"46.60973660",
     "longitude":"15.16299950"
  },
  {
     "name":"Rogatec Municipality",
     "isoCode":"107",
     "countryCode":"SI",
     "latitude":"46.22866260",
     "longitude":"15.69913380"
  },
  {
     "name":"Gorišnica Municipality",
     "isoCode":"028",
     "countryCode":"SI",
     "latitude":"46.41202710",
     "longitude":"16.01330890"
  },
  {
     "name":"Kuzma Municipality",
     "isoCode":"056",
     "countryCode":"SI",
     "latitude":"46.83510380",
     "longitude":"16.08071000"
  },
  {
     "name":"Mislinja Municipality",
     "isoCode":"076",
     "countryCode":"SI",
     "latitude":"46.44294030",
     "longitude":"15.19876780"
  },
  {
     "name":"Duplek Municipality",
     "isoCode":"026",
     "countryCode":"SI",
     "latitude":"46.50100160",
     "longitude":"15.75463070"
  },
  {
     "name":"Trebnje Municipality",
     "isoCode":"130",
     "countryCode":"SI",
     "latitude":"45.90801630",
     "longitude":"15.01319050"
  },
  {
     "name":"Brežice Municipality",
     "isoCode":"009",
     "countryCode":"SI",
     "latitude":"45.90410960",
     "longitude":"15.59436390"
  },
  {
     "name":"Dobrepolje Municipality",
     "isoCode":"020",
     "countryCode":"SI",
     "latitude":"45.85249510",
     "longitude":"14.70831090"
  },
  {
     "name":"Grad Municipality",
     "isoCode":"158",
     "countryCode":"SI",
     "latitude":"46.80873200",
     "longitude":"16.10920600"
  },
  {
     "name":"Moravske Toplice Municipality",
     "isoCode":"078",
     "countryCode":"SI",
     "latitude":"46.68569320",
     "longitude":"16.22245820"
  },
  {
     "name":"Luče Municipality",
     "isoCode":"067",
     "countryCode":"SI",
     "latitude":"46.35449250",
     "longitude":"14.74715040"
  },
  {
     "name":"Miren–Kostanjevica Municipality",
     "isoCode":"075",
     "countryCode":"SI",
     "latitude":"45.84360290",
     "longitude":"13.62766470"
  },
  {
     "name":"Ormož Municipality",
     "isoCode":"087",
     "countryCode":"SI",
     "latitude":"46.43533330",
     "longitude":"16.15437400"
  },
  {
     "name":"Šalovci Municipality",
     "isoCode":"033",
     "countryCode":"SI",
     "latitude":"46.85335680",
     "longitude":"16.25917910"
  },
  {
     "name":"Miklavž na Dravskem Polju Municipality",
     "isoCode":"169",
     "countryCode":"SI",
     "latitude":"46.50826280",
     "longitude":"15.69520650"
  },
  {
     "name":"Makole Municipality",
     "isoCode":"198",
     "countryCode":"SI",
     "latitude":"46.31686970",
     "longitude":"15.66641260"
  },
  {
     "name":"Lendava Municipality",
     "isoCode":"059",
     "countryCode":"SI",
     "latitude":"46.55134830",
     "longitude":"16.44198390"
  },
  {
     "name":"Vuzenica Municipality",
     "isoCode":"141",
     "countryCode":"SI",
     "latitude":"46.59808360",
     "longitude":"15.16572370"
  },
  {
     "name":"Kanal ob Soči Municipality",
     "isoCode":"044",
     "countryCode":"SI",
     "latitude":"46.06735300",
     "longitude":"13.62033500"
  },
  {
     "name":"Ptuj City Municipality",
     "isoCode":"096",
     "countryCode":"SI",
     "latitude":"46.41995350",
     "longitude":"15.86968840"
  },
  {
     "name":"Sveti Andraž v Slovenskih Goricah Municipality",
     "isoCode":"182",
     "countryCode":"SI",
     "latitude":"46.51897470",
     "longitude":"15.94982620"
  },
  {
     "name":"Selnica ob Dravi Municipality",
     "isoCode":"178",
     "countryCode":"SI",
     "latitude":"46.55139180",
     "longitude":"15.49294100"
  },
  {
     "name":"Radovljica Municipality",
     "isoCode":"102",
     "countryCode":"SI",
     "latitude":"46.33558270",
     "longitude":"14.20945340"
  },
  {
     "name":"Črna na Koroškem Municipality",
     "isoCode":"016",
     "countryCode":"SI",
     "latitude":"46.47045290",
     "longitude":"14.84999980"
  },
  {
     "name":"Rogaška Slatina Municipality",
     "isoCode":"106",
     "countryCode":"SI",
     "latitude":"46.24539730",
     "longitude":"15.62650140"
  },
  {
     "name":"Podvelka Municipality",
     "isoCode":"093",
     "countryCode":"SI",
     "latitude":"46.62219520",
     "longitude":"15.38899220"
  },
  {
     "name":"Ribnica Municipality",
     "isoCode":"104",
     "countryCode":"SI",
     "latitude":"45.74003030",
     "longitude":"14.72657820"
  },
  {
     "name":"City Municipality of Novo Mesto",
     "isoCode":"085",
     "countryCode":"SI",
     "latitude":"45.80108240",
     "longitude":"15.17100890"
  },
  {
     "name":"Mirna Peč Municipality",
     "isoCode":"170",
     "countryCode":"SI",
     "latitude":"45.84815740",
     "longitude":"15.08794500"
  },
  {
     "name":"Križevci Municipality",
     "isoCode":"166",
     "countryCode":"SI",
     "latitude":"46.57018210",
     "longitude":"16.10926530"
  },
  {
     "name":"Poljčane Municipality",
     "isoCode":"200",
     "countryCode":"SI",
     "latitude":"46.31398530",
     "longitude":"15.57847910"
  },
  {
     "name":"Brda Municipality",
     "isoCode":"007",
     "countryCode":"SI",
     "latitude":"45.99756520",
     "longitude":"13.52704740"
  },
  {
     "name":"Šentjernej Municipality",
     "isoCode":"119",
     "countryCode":"SI",
     "latitude":"45.84341300",
     "longitude":"15.33783120"
  },
  {
     "name":"Maribor City Municipality",
     "isoCode":"070",
     "countryCode":"SI",
     "latitude":"46.55064960",
     "longitude":"15.62054390"
  },
  {
     "name":"Kobarid Municipality",
     "isoCode":"046",
     "countryCode":"SI",
     "latitude":"46.24569710",
     "longitude":"13.57869490"
  },
  {
     "name":"Markovci Municipality",
     "isoCode":"168",
     "countryCode":"SI",
     "latitude":"46.38793090",
     "longitude":"15.95860140"
  },
  {
     "name":"Vojnik Municipality",
     "isoCode":"139",
     "countryCode":"SI",
     "latitude":"46.29205810",
     "longitude":"15.30205800"
  },
  {
     "name":"Trbovlje Municipality",
     "isoCode":"129",
     "countryCode":"SI",
     "latitude":"46.15035630",
     "longitude":"15.04531370"
  },
  {
     "name":"Tolmin Municipality",
     "isoCode":"128",
     "countryCode":"SI",
     "latitude":"46.18571880",
     "longitude":"13.73198380"
  },
  {
     "name":"Šoštanj Municipality",
     "isoCode":"126",
     "countryCode":"SI",
     "latitude":"46.37828360",
     "longitude":"15.04613780"
  },
  {
     "name":"Žetale Municipality",
     "isoCode":"191",
     "countryCode":"SI",
     "latitude":"46.27428330",
     "longitude":"15.79133590"
  },
  {
     "name":"Tržič Municipality",
     "isoCode":"131",
     "countryCode":"SI",
     "latitude":"46.35935140",
     "longitude":"14.30066230"
  },
  {
     "name":"Turnišče Municipality",
     "isoCode":"132",
     "countryCode":"SI",
     "latitude":"46.61375040",
     "longitude":"16.32021000"
  },
  {
     "name":"Dobrna Municipality",
     "isoCode":"155",
     "countryCode":"SI",
     "latitude":"46.33561410",
     "longitude":"15.22597320"
  },
  {
     "name":"Renče–Vogrsko Municipality",
     "isoCode":"201",
     "countryCode":"SI",
     "latitude":"45.89546170",
     "longitude":"13.67856730"
  },
  {
     "name":"Kostanjevica na Krki Municipality",
     "isoCode":"197",
     "countryCode":"SI",
     "latitude":"45.83166380",
     "longitude":"15.44119060"
  },
  {
     "name":"Sveti Jurij ob Ščavnici Municipality",
     "isoCode":"116",
     "countryCode":"SI",
     "latitude":"46.56874520",
     "longitude":"16.02225280"
  },
  {
     "name":"Železniki Municipality",
     "isoCode":"146",
     "countryCode":"SI",
     "latitude":"46.22563770",
     "longitude":"14.16936170"
  },
  {
     "name":"Veržej Municipality",
     "isoCode":"188",
     "countryCode":"SI",
     "latitude":"46.58411350",
     "longitude":"16.16208000"
  },
  {
     "name":"Žalec Municipality",
     "isoCode":"190",
     "countryCode":"SI",
     "latitude":"46.25197120",
     "longitude":"15.16500720"
  },
  {
     "name":"Starše Municipality",
     "isoCode":"115",
     "countryCode":"SI",
     "latitude":"46.46743310",
     "longitude":"15.76405460"
  },
  {
     "name":"Sveta Trojica v Slovenskih Goricah Municipality",
     "isoCode":"204",
     "countryCode":"SI",
     "latitude":"46.56808090",
     "longitude":"15.88230640"
  },
  {
     "name":"Solčava Municipality",
     "isoCode":"180",
     "countryCode":"SI",
     "latitude":"46.40235260",
     "longitude":"14.68023040"
  },
  {
     "name":"Vrhnika Municipality",
     "isoCode":"140",
     "countryCode":"SI",
     "latitude":"45.95027190",
     "longitude":"14.32764220"
  },
  {
     "name":"Središče ob Dravi",
     "isoCode":"202",
     "countryCode":"SI",
     "latitude":"46.39592820",
     "longitude":"16.27049150"
  },
  {
     "name":"Rogašovci Municipality",
     "isoCode":"105",
     "countryCode":"SI",
     "latitude":"46.80557850",
     "longitude":"16.03452370"
  },
  {
     "name":"Mežica Municipality",
     "isoCode":"074",
     "countryCode":"SI",
     "latitude":"46.52150270",
     "longitude":"14.85213400"
  },
  {
     "name":"Juršinci Municipality",
     "isoCode":"042",
     "countryCode":"SI",
     "latitude":"46.48986510",
     "longitude":"15.98092300"
  },
  {
     "name":"Velika Polana Municipality",
     "isoCode":"187",
     "countryCode":"SI",
     "latitude":"46.57317150",
     "longitude":"16.34441260"
  },
  {
     "name":"Sevnica Municipality",
     "isoCode":"110",
     "countryCode":"SI",
     "latitude":"46.00703170",
     "longitude":"15.30456790"
  },
  {
     "name":"Zagorje ob Savi Municipality",
     "isoCode":"142",
     "countryCode":"SI",
     "latitude":"46.13452020",
     "longitude":"14.99643840"
  },
  {
     "name":"Ljubljana City Municipality",
     "isoCode":"061",
     "countryCode":"SI",
     "latitude":"46.05694650",
     "longitude":"14.50575150"
  },
  {
     "name":"Gornji Petrovci Municipality",
     "isoCode":"031",
     "countryCode":"SI",
     "latitude":"46.80371280",
     "longitude":"16.21913790"
  },
  {
     "name":"Polzela Municipality",
     "isoCode":"173",
     "countryCode":"SI",
     "latitude":"46.28089700",
     "longitude":"15.07373210"
  },
  {
     "name":"Sveti Tomaž Municipality",
     "isoCode":"205",
     "countryCode":"SI",
     "latitude":"46.48352830",
     "longitude":"16.07944200"
  },
  {
     "name":"Prevalje Municipality",
     "isoCode":"175",
     "countryCode":"SI",
     "latitude":"46.56211460",
     "longitude":"14.88478610"
  },
  {
     "name":"Radlje ob Dravi Municipality",
     "isoCode":"101",
     "countryCode":"SI",
     "latitude":"46.61357320",
     "longitude":"15.23544380"
  },
  {
     "name":"Žirovnica Municipality",
     "isoCode":"192",
     "countryCode":"SI",
     "latitude":"46.39544030",
     "longitude":"14.15396320"
  },
  {
     "name":"Sodražica Municipality",
     "isoCode":"179",
     "countryCode":"SI",
     "latitude":"45.76165650",
     "longitude":"14.63528530"
  },
  {
     "name":"Bloke Municipality",
     "isoCode":"150",
     "countryCode":"SI",
     "latitude":"45.77281410",
     "longitude":"14.50634590"
  },
  {
     "name":"Šmartno pri Litiji Municipality",
     "isoCode":"194",
     "countryCode":"SI",
     "latitude":"46.04549710",
     "longitude":"14.84101330"
  },
  {
     "name":"Ruše Municipality",
     "isoCode":"108",
     "countryCode":"SI",
     "latitude":"46.52062650",
     "longitude":"15.48178690"
  },
  {
     "name":"Dolenjske Toplice Municipality",
     "isoCode":"157",
     "countryCode":"SI",
     "latitude":"45.73457110",
     "longitude":"15.01294930"
  },
  {
     "name":"Bohinj Municipality",
     "isoCode":"004",
     "countryCode":"SI",
     "latitude":"46.30056520",
     "longitude":"13.94271950"
  },
  {
     "name":"Komenda Municipality",
     "isoCode":"164",
     "countryCode":"SI",
     "latitude":"46.20648800",
     "longitude":"14.53824990"
  },
  {
     "name":"Gorje Municipality",
     "isoCode":"207",
     "countryCode":"SI",
     "latitude":"46.38024580",
     "longitude":"14.06853390"
  },
  {
     "name":"Šmarje pri Jelšah Municipality",
     "isoCode":"124",
     "countryCode":"SI",
     "latitude":"46.22870250",
     "longitude":"15.51903530"
  },
  {
     "name":"Ig Municipality",
     "isoCode":"037",
     "countryCode":"SI",
     "latitude":"45.95888680",
     "longitude":"14.52705280"
  },
  {
     "name":"Kranj City Municipality",
     "isoCode":"052",
     "countryCode":"SI",
     "latitude":"46.25850210",
     "longitude":"14.35435690"
  },
  {
     "name":"Puconci Municipality",
     "isoCode":"097",
     "countryCode":"SI",
     "latitude":"46.72004180",
     "longitude":"16.09977920"
  },
  {
     "name":"Šmarješke Toplice Municipality",
     "isoCode":"206",
     "countryCode":"SI",
     "latitude":"45.86803770",
     "longitude":"15.23474220"
  },
  {
     "name":"Dornava Municipality",
     "isoCode":"024",
     "countryCode":"SI",
     "latitude":"46.44435130",
     "longitude":"15.98891590"
  },
  {
     "name":"Črnomelj Municipality",
     "isoCode":"017",
     "countryCode":"SI",
     "latitude":"45.53612250",
     "longitude":"15.19441430"
  },
  {
     "name":"Radenci Municipality",
     "isoCode":"100",
     "countryCode":"SI",
     "latitude":"46.62311210",
     "longitude":"16.05069030"
  },
  {
     "name":"Gorenja Vas–Poljane Municipality",
     "isoCode":"027",
     "countryCode":"SI",
     "latitude":"46.11165820",
     "longitude":"14.11493480"
  },
  {
     "name":"Ljubno Municipality",
     "isoCode":"062",
     "countryCode":"SI",
     "latitude":"46.34431250",
     "longitude":"14.83354920"
  },
  {
     "name":"Dobje Municipality",
     "isoCode":"154",
     "countryCode":"SI",
     "latitude":"46.13700370",
     "longitude":"15.39412900"
  },
  {
     "name":"Šmartno ob Paki Municipality",
     "isoCode":"125",
     "countryCode":"SI",
     "latitude":"46.32903720",
     "longitude":"15.03339370"
  },
  {
     "name":"Mokronog–Trebelno Municipality",
     "isoCode":"199",
     "countryCode":"SI",
     "latitude":"45.90885290",
     "longitude":"15.15967360"
  },
  {
     "name":"Mirna Municipality",
     "isoCode":"212",
     "countryCode":"SI",
     "latitude":"45.95156470",
     "longitude":"15.06209770"
  },
  {
     "name":"Šenčur Municipality",
     "isoCode":"117",
     "countryCode":"SI",
     "latitude":"46.24336990",
     "longitude":"14.41922230"
  },
  {
     "name":"Videm Municipality",
     "isoCode":"135",
     "countryCode":"SI",
     "latitude":"46.36383300",
     "longitude":"15.87812120"
  },
  {
     "name":"Beltinci Municipality",
     "isoCode":"002",
     "countryCode":"SI",
     "latitude":"46.60791530",
     "longitude":"16.23651270"
  },
  {
     "name":"Lukovica Municipality",
     "isoCode":"068",
     "countryCode":"SI",
     "latitude":"46.16962930",
     "longitude":"14.69072590"
  },
  {
     "name":"Preddvor Municipality",
     "isoCode":"095",
     "countryCode":"SI",
     "latitude":"46.30171390",
     "longitude":"14.42181650"
  },
  {
     "name":"Destrnik Municipality",
     "isoCode":"018",
     "countryCode":"SI",
     "latitude":"46.49223680",
     "longitude":"15.87779560"
  },
  {
     "name":"Ivančna Gorica Municipality",
     "isoCode":"039",
     "countryCode":"SI",
     "latitude":"45.93958410",
     "longitude":"14.80476260"
  },
  {
     "name":"Log–Dragomer Municipality",
     "isoCode":"208",
     "countryCode":"SI",
     "latitude":"46.01787470",
     "longitude":"14.36877670"
  },
  {
     "name":"Žužemberk Municipality",
     "isoCode":"193",
     "countryCode":"SI",
     "latitude":"45.82003500",
     "longitude":"14.95359190"
  },
  {
     "name":"Dobrova–Polhov Gradec Municipality",
     "isoCode":"021",
     "countryCode":"SI",
     "latitude":"46.06488960",
     "longitude":"14.31681950"
  },
  {
     "name":"Municipality of Cirkulane",
     "isoCode":"196",
     "countryCode":"SI",
     "latitude":"46.32983220",
     "longitude":"15.99806660"
  },
  {
     "name":"Cerklje na Gorenjskem Municipality",
     "isoCode":"012",
     "countryCode":"SI",
     "latitude":"46.25170540",
     "longitude":"14.48579790"
  },
  {
     "name":"Šentrupert Municipality",
     "isoCode":"211",
     "countryCode":"SI",
     "latitude":"45.98731420",
     "longitude":"15.08297830"
  },
  {
     "name":"Tišina Municipality",
     "isoCode":"010",
     "countryCode":"SI",
     "latitude":"46.65418840",
     "longitude":"16.07547810"
  },
  {
     "name":"Murska Sobota City Municipality",
     "isoCode":"080",
     "countryCode":"SI",
     "latitude":"46.64321470",
     "longitude":"16.15157540"
  },
  {
     "name":"Municipality of Krško",
     "isoCode":"054",
     "countryCode":"SI",
     "latitude":"45.95896090",
     "longitude":"15.49235550"
  },
  {
     "name":"Komen Municipality",
     "isoCode":"049",
     "countryCode":"SI",
     "latitude":"45.81752350",
     "longitude":"13.74827110"
  },
  {
     "name":"Škofja Loka Municipality",
     "isoCode":"122",
     "countryCode":"SI",
     "latitude":"46.14098440",
     "longitude":"14.28118730"
  },
  {
     "name":"Šempeter–Vrtojba Municipality",
     "isoCode":"183",
     "countryCode":"SI",
     "latitude":"45.92900950",
     "longitude":"13.64155940"
  },
  {
     "name":"Municipality of Apače",
     "isoCode":"195",
     "countryCode":"SI",
     "latitude":"46.69746790",
     "longitude":"15.91025340"
  },
  {
     "name":"Koper City Municipality",
     "isoCode":"050",
     "countryCode":"SI",
     "latitude":"45.54805900",
     "longitude":"13.73018770"
  },
  {
     "name":"Odranci Municipality",
     "isoCode":"086",
     "countryCode":"SI",
     "latitude":"46.59010170",
     "longitude":"16.27881650"
  },
  {
     "name":"Hrpelje–Kozina Municipality",
     "isoCode":"035",
     "countryCode":"SI",
     "latitude":"45.60911920",
     "longitude":"13.93791480"
  },
  {
     "name":"Izola Municipality",
     "isoCode":"040",
     "countryCode":"SI",
     "latitude":"45.53135570",
     "longitude":"13.66646490"
  },
  {
     "name":"Metlika Municipality",
     "isoCode":"073",
     "countryCode":"SI",
     "latitude":"45.64807150",
     "longitude":"15.31778380"
  },
  {
     "name":"Šentilj Municipality",
     "isoCode":"118",
     "countryCode":"SI",
     "latitude":"46.68628390",
     "longitude":"15.71035670"
  },
  {
     "name":"Kobilje Municipality",
     "isoCode":"047",
     "countryCode":"SI",
     "latitude":"46.68518000",
     "longitude":"16.39367190"
  },
  {
     "name":"Ankaran Municipality",
     "isoCode":"213",
     "countryCode":"SI",
     "latitude":"45.57845100",
     "longitude":"13.73691740"
  },
  {
     "name":"Hodoš Municipality",
     "isoCode":"161",
     "countryCode":"SI",
     "latitude":"46.83141340",
     "longitude":"16.32106800"
  },
  {
     "name":"Sveti Jurij v Slovenskih Goricah Municipality",
     "isoCode":"210",
     "countryCode":"SI",
     "latitude":"46.61707910",
     "longitude":"15.78046770"
  },
  {
     "name":"Nazarje Municipality",
     "isoCode":"083",
     "countryCode":"SI",
     "latitude":"46.28217410",
     "longitude":"14.92256290"
  },
  {
     "name":"Postojna Municipality",
     "isoCode":"094",
     "countryCode":"SI",
     "latitude":"45.77493900",
     "longitude":"14.21342630"
  },
  {
     "name":"Kostel Municipality",
     "isoCode":"165",
     "countryCode":"SI",
     "latitude":"45.49282550",
     "longitude":"14.87082350"
  },
  {
     "name":"Slovenska Bistrica Municipality",
     "isoCode":"113",
     "countryCode":"SI",
     "latitude":"46.39198130",
     "longitude":"15.57278690"
  },
  {
     "name":"Straža Municipality",
     "isoCode":"203",
     "countryCode":"SI",
     "latitude":"45.77684280",
     "longitude":"15.09486940"
  },
  {
     "name":"Trzin Municipality",
     "isoCode":"186",
     "countryCode":"SI",
     "latitude":"46.12982410",
     "longitude":"14.55776370"
  },
  {
     "name":"Kočevje Municipality",
     "isoCode":"048",
     "countryCode":"SI",
     "latitude":"45.64280000",
     "longitude":"14.86158380"
  },
  {
     "name":"Grosuplje Municipality",
     "isoCode":"032",
     "countryCode":"SI",
     "latitude":"45.95576450",
     "longitude":"14.65889900"
  },
  {
     "name":"Jesenice Municipality",
     "isoCode":"041",
     "countryCode":"SI",
     "latitude":"46.43670470",
     "longitude":"14.05260570"
  },
  {
     "name":"Laško Municipality",
     "isoCode":"057",
     "countryCode":"SI",
     "latitude":"46.15422360",
     "longitude":"15.23614910"
  },
  {
     "name":"Gornji Grad Municipality",
     "isoCode":"030",
     "countryCode":"SI",
     "latitude":"46.29617120",
     "longitude":"14.80623470"
  },
  {
     "name":"Kranjska Gora Municipality",
     "isoCode":"053",
     "countryCode":"SI",
     "latitude":"46.48452930",
     "longitude":"13.78571450"
  },
  {
     "name":"Hrastnik Municipality",
     "isoCode":"034",
     "countryCode":"SI",
     "latitude":"46.14172880",
     "longitude":"15.08448940"
  },
  {
     "name":"Zreče Municipality",
     "isoCode":"144",
     "countryCode":"SI",
     "latitude":"46.41777860",
     "longitude":"15.37094310"
  },
  {
     "name":"Gornja Radgona Municipality",
     "isoCode":"029",
     "countryCode":"SI",
     "latitude":"46.67670990",
     "longitude":"15.99108470"
  },
  {
     "name":"Municipality of Ilirska Bistrica",
     "isoCode":"038",
     "countryCode":"SI",
     "latitude":"45.57913230",
     "longitude":"14.28097290"
  },
  {
     "name":"Dravograd Municipality",
     "isoCode":"025",
     "countryCode":"SI",
     "latitude":"46.58921900",
     "longitude":"15.02460210"
  },
  {
     "name":"Semič Municipality",
     "isoCode":"109",
     "countryCode":"SI",
     "latitude":"45.65205340",
     "longitude":"15.18207010"
  },
  {
     "name":"Litija Municipality",
     "isoCode":"060",
     "countryCode":"SI",
     "latitude":"46.05732260",
     "longitude":"14.83096360"
  },
  {
     "name":"Mengeš Municipality",
     "isoCode":"072",
     "countryCode":"SI",
     "latitude":"46.16591220",
     "longitude":"14.57196940"
  },
  {
     "name":"Medvode Municipality",
     "isoCode":"071",
     "countryCode":"SI",
     "latitude":"46.14190800",
     "longitude":"14.40325960"
  },
  {
     "name":"Logatec Municipality",
     "isoCode":"064",
     "countryCode":"SI",
     "latitude":"45.91761100",
     "longitude":"14.23514510"
  },
  {
     "name":"Ljutomer Municipality",
     "isoCode":"063",
     "countryCode":"SI",
     "latitude":"46.51908480",
     "longitude":"16.18932160"
  },
  {
     "name":"Banská Bystrica Region",
     "isoCode":"BC",
     "countryCode":"SK",
     "latitude":"48.53124990",
     "longitude":"19.38287400"
  },
  {
     "name":"Košice Region",
     "isoCode":"KI",
     "countryCode":"SK",
     "latitude":"48.63757370",
     "longitude":"21.08342250"
  },
  {
     "name":"Prešov Region",
     "isoCode":"PV",
     "countryCode":"SK",
     "latitude":"49.17167730",
     "longitude":"21.37420010"
  },
  {
     "name":"Trnava Region",
     "isoCode":"TA",
     "countryCode":"SK",
     "latitude":"48.39438980",
     "longitude":"17.72162050"
  },
  {
     "name":"Bratislava Region",
     "isoCode":"BL",
     "countryCode":"SK",
     "latitude":"48.31183040",
     "longitude":"17.19732990"
  },
  {
     "name":"Nitra Region",
     "isoCode":"NI",
     "countryCode":"SK",
     "latitude":"48.01437650",
     "longitude":"18.54165040"
  },
  {
     "name":"Trenčín Region",
     "isoCode":"TC",
     "countryCode":"SK",
     "latitude":"48.80867580",
     "longitude":"18.23240260"
  },
  {
     "name":"Žilina Region",
     "isoCode":"ZI",
     "countryCode":"SK",
     "latitude":"49.20314350",
     "longitude":"19.36457330"
  },
  {
     "name":"Cimișlia District",
     "isoCode":"CM",
     "countryCode":"MD",
     "latitude":"46.52508510",
     "longitude":"28.77218350"
  },
  {
     "name":"Orhei District",
     "isoCode":"OR",
     "countryCode":"MD",
     "latitude":"47.38604000",
     "longitude":"28.83030820"
  },
  {
     "name":"Bender Municipality",
     "isoCode":"BD",
     "countryCode":"MD",
     "latitude":"46.82275510",
     "longitude":"29.46201010"
  },
  {
     "name":"Nisporeni District",
     "isoCode":"NI",
     "countryCode":"MD",
     "latitude":"47.07513490",
     "longitude":"28.17681550"
  },
  {
     "name":"Sîngerei District",
     "isoCode":"SI",
     "countryCode":"MD",
     "latitude":"47.63891340",
     "longitude":"28.13718160"
  },
  {
     "name":"Căușeni District",
     "isoCode":"CS",
     "countryCode":"MD",
     "latitude":"46.65547150",
     "longitude":"29.40912220"
  },
  {
     "name":"Călărași District",
     "isoCode":"CL",
     "countryCode":"MD",
     "latitude":"47.28694600",
     "longitude":"28.27453100"
  },
  {
     "name":"Glodeni District",
     "isoCode":"GL",
     "countryCode":"MD",
     "latitude":"47.77901560",
     "longitude":"27.51680100"
  },
  {
     "name":"Anenii Noi District",
     "isoCode":"AN",
     "countryCode":"MD",
     "latitude":"46.87956630",
     "longitude":"29.23121750"
  },
  {
     "name":"Ialoveni District",
     "isoCode":"IA",
     "countryCode":"MD",
     "latitude":"46.86308600",
     "longitude":"28.82342180"
  },
  {
     "name":"Florești District",
     "isoCode":"FL",
     "countryCode":"MD",
     "latitude":"47.86678490",
     "longitude":"28.33918640"
  },
  {
     "name":"Telenești District",
     "isoCode":"TE",
     "countryCode":"MD",
     "latitude":"47.49839620",
     "longitude":"28.36760190"
  },
  {
     "name":"Taraclia District",
     "isoCode":"TA",
     "countryCode":"MD",
     "latitude":"45.89865100",
     "longitude":"28.66716440"
  },
  {
     "name":"Chișinău Municipality",
     "isoCode":"CU",
     "countryCode":"MD",
     "latitude":"47.01045290",
     "longitude":"28.86381020"
  },
  {
     "name":"Soroca District",
     "isoCode":"SO",
     "countryCode":"MD",
     "latitude":"48.15497430",
     "longitude":"28.28707830"
  },
  {
     "name":"Briceni District",
     "isoCode":"BR",
     "countryCode":"MD",
     "latitude":"48.36320220",
     "longitude":"27.07503980"
  },
  {
     "name":"Rîșcani District",
     "isoCode":"RI",
     "countryCode":"MD",
     "latitude":"47.90701530",
     "longitude":"27.53749960"
  },
  {
     "name":"Strășeni District",
     "isoCode":"ST",
     "countryCode":"MD",
     "latitude":"47.14502670",
     "longitude":"28.61367360"
  },
  {
     "name":"Ștefan Vodă District",
     "isoCode":"SV",
     "countryCode":"MD",
     "latitude":"46.55404880",
     "longitude":"29.70224200"
  },
  {
     "name":"Basarabeasca District",
     "isoCode":"BS",
     "countryCode":"MD",
     "latitude":"46.42370600",
     "longitude":"28.89354920"
  },
  {
     "name":"Cantemir District",
     "isoCode":"CT",
     "countryCode":"MD",
     "latitude":"46.27717420",
     "longitude":"28.20096530"
  },
  {
     "name":"Fălești District",
     "isoCode":"FA",
     "countryCode":"MD",
     "latitude":"47.56477250",
     "longitude":"27.72655930"
  },
  {
     "name":"Hîncești District",
     "isoCode":"HI",
     "countryCode":"MD",
     "latitude":"46.82811470",
     "longitude":"28.58508890"
  },
  {
     "name":"Dubăsari District",
     "isoCode":"DU",
     "countryCode":"MD",
     "latitude":"47.26439420",
     "longitude":"29.15503480"
  },
  {
     "name":"Dondușeni District",
     "isoCode":"DO",
     "countryCode":"MD",
     "latitude":"48.23383050",
     "longitude":"27.59980870"
  },
  {
     "name":"Gagauzia",
     "isoCode":"GA",
     "countryCode":"MD",
     "latitude":"46.09794350",
     "longitude":"28.63846450"
  },
  {
     "name":"Ungheni District",
     "isoCode":"UN",
     "countryCode":"MD",
     "latitude":"47.23057670",
     "longitude":"27.78926610"
  },
  {
     "name":"Edineț District",
     "isoCode":"ED",
     "countryCode":"MD",
     "latitude":"48.16789910",
     "longitude":"27.29361430"
  },
  {
     "name":"Șoldănești District",
     "isoCode":"SD",
     "countryCode":"MD",
     "latitude":"47.81473890",
     "longitude":"28.78895860"
  },
  {
     "name":"Ocnița District",
     "isoCode":"OC",
     "countryCode":"MD",
     "latitude":"48.41104350",
     "longitude":"27.47680920"
  },
  {
     "name":"Criuleni District",
     "isoCode":"CR",
     "countryCode":"MD",
     "latitude":"47.21361140",
     "longitude":"29.15575190"
  },
  {
     "name":"Cahul District",
     "isoCode":"CA",
     "countryCode":"MD",
     "latitude":"45.89394040",
     "longitude":"28.18902750"
  },
  {
     "name":"Drochia District",
     "isoCode":"DR",
     "countryCode":"MD",
     "latitude":"48.07977880",
     "longitude":"27.86041140"
  },
  {
     "name":"Bălți Municipality",
     "isoCode":"BA",
     "countryCode":"MD",
     "latitude":"47.75399470",
     "longitude":"27.91841480"
  },
  {
     "name":"Rezina District",
     "isoCode":"RE",
     "countryCode":"MD",
     "latitude":"47.71804470",
     "longitude":"28.88710240"
  },
  {
     "name":"Transnistria autonomous territorial unit",
     "isoCode":"SN",
     "countryCode":"MD",
     "latitude":"47.21529720",
     "longitude":"29.46380540"
  },
  {
     "name":"Salacgrīva Municipality",
     "isoCode":"086",
     "countryCode":"LV",
     "latitude":"57.75808830",
     "longitude":"24.35431810"
  },
  {
     "name":"Vecumnieki Municipality",
     "isoCode":"105",
     "countryCode":"LV",
     "latitude":"56.60623370",
     "longitude":"24.52218910"
  },
  {
     "name":"Naukšēni Municipality",
     "isoCode":"064",
     "countryCode":"LV",
     "latitude":"57.92953610",
     "longitude":"25.51192660"
  },
  {
     "name":"Ilūkste Municipality",
     "isoCode":"036",
     "countryCode":"LV",
     "latitude":"55.97825470",
     "longitude":"26.29650880"
  },
  {
     "name":"Gulbene Municipality",
     "isoCode":"033",
     "countryCode":"LV",
     "latitude":"57.21556450",
     "longitude":"26.64529550"
  },
  {
     "name":"Līvāni Municipality",
     "isoCode":"056",
     "countryCode":"LV",
     "latitude":"56.35509420",
     "longitude":"26.17251900"
  },
  {
     "name":"Salaspils Municipality",
     "isoCode":"087",
     "countryCode":"LV",
     "latitude":"56.86081520",
     "longitude":"24.34978810"
  },
  {
     "name":"Ventspils Municipality",
     "isoCode":"106",
     "countryCode":"LV",
     "latitude":"57.28336820",
     "longitude":"21.85875580"
  },
  {
     "name":"Rundāle Municipality",
     "isoCode":"083",
     "countryCode":"LV",
     "latitude":"56.40972100",
     "longitude":"24.01241390"
  },
  {
     "name":"Pļaviņas Municipality",
     "isoCode":"072",
     "countryCode":"LV",
     "latitude":"56.61773130",
     "longitude":"25.71940430"
  },
  {
     "name":"Vārkava Municipality",
     "isoCode":"103",
     "countryCode":"LV",
     "latitude":"56.24657440",
     "longitude":"26.56643710"
  },
  {
     "name":"Jaunpiebalga Municipality",
     "isoCode":"039",
     "countryCode":"LV",
     "latitude":"57.14334710",
     "longitude":"25.99518880"
  },
  {
     "name":"Sēja Municipality",
     "isoCode":"090",
     "countryCode":"LV",
     "latitude":"57.20069950",
     "longitude":"24.59228210"
  },
  {
     "name":"Tukums Municipality",
     "isoCode":"099",
     "countryCode":"LV",
     "latitude":"56.96728680",
     "longitude":"23.15243790"
  },
  {
     "name":"Cibla Municipality",
     "isoCode":"023",
     "countryCode":"LV",
     "latitude":"56.61023440",
     "longitude":"27.86965980"
  },
  {
     "name":"Burtnieki Municipality",
     "isoCode":"019",
     "countryCode":"LV",
     "latitude":"57.69490040",
     "longitude":"25.27647770"
  },
  {
     "name":"Ķegums Municipality",
     "isoCode":"051",
     "countryCode":"LV",
     "latitude":"56.74753570",
     "longitude":"24.71736450"
  },
  {
     "name":"Krustpils Municipality",
     "isoCode":"049",
     "countryCode":"LV",
     "latitude":"56.54155780",
     "longitude":"26.24463970"
  },
  {
     "name":"Cesvaine Municipality",
     "isoCode":"021",
     "countryCode":"LV",
     "latitude":"56.96792640",
     "longitude":"26.30831720"
  },
  {
     "name":"Skrīveri Municipality",
     "isoCode":"092",
     "countryCode":"LV",
     "latitude":"56.67613910",
     "longitude":"25.09788490"
  },
  {
     "name":"Ogre Municipality",
     "isoCode":"067",
     "countryCode":"LV",
     "latitude":"56.81473550",
     "longitude":"24.60445550"
  },
  {
     "name":"Olaine Municipality",
     "isoCode":"068",
     "countryCode":"LV",
     "latitude":"56.79523530",
     "longitude":"24.01535890"
  },
  {
     "name":"Limbaži Municipality",
     "isoCode":"054",
     "countryCode":"LV",
     "latitude":"57.54032270",
     "longitude":"24.71344510"
  },
  {
     "name":"Lubāna Municipality",
     "isoCode":"057",
     "countryCode":"LV",
     "latitude":"56.89992690",
     "longitude":"26.71987890"
  },
  {
     "name":"Kandava Municipality",
     "isoCode":"043",
     "countryCode":"LV",
     "latitude":"57.03406730",
     "longitude":"22.78018130"
  },
  {
     "name":"Ventspils",
     "isoCode":"VEN",
     "countryCode":"LV",
     "latitude":"57.39372160",
     "longitude":"21.56470660"
  },
  {
     "name":"Krimulda Municipality",
     "isoCode":"048",
     "countryCode":"LV",
     "latitude":"57.17912730",
     "longitude":"24.71401270"
  },
  {
     "name":"Rugāji Municipality",
     "isoCode":"082",
     "countryCode":"LV",
     "latitude":"57.00560230",
     "longitude":"27.13172030"
  },
  {
     "name":"Jelgava Municipality",
     "isoCode":"041",
     "countryCode":"LV",
     "latitude":"56.58956890",
     "longitude":"23.66104810"
  },
  {
     "name":"Valka Municipality",
     "isoCode":"101",
     "countryCode":"LV",
     "latitude":"57.77439000",
     "longitude":"26.01700500"
  },
  {
     "name":"Rūjiena Municipality",
     "isoCode":"084",
     "countryCode":"LV",
     "latitude":"57.89372910",
     "longitude":"25.33910080"
  },
  {
     "name":"Babīte Municipality",
     "isoCode":"012",
     "countryCode":"LV",
     "latitude":"56.95415500",
     "longitude":"23.94539900"
  },
  {
     "name":"Dundaga Municipality",
     "isoCode":"027",
     "countryCode":"LV",
     "latitude":"57.50491670",
     "longitude":"22.35051140"
  },
  {
     "name":"Priekule Municipality",
     "isoCode":"074",
     "countryCode":"LV",
     "latitude":"56.41794130",
     "longitude":"21.55033360"
  },
  {
     "name":"Zilupe Municipality",
     "isoCode":"110",
     "countryCode":"LV",
     "latitude":"56.30189850",
     "longitude":"28.13395900"
  },
  {
     "name":"Varakļāni Municipality",
     "isoCode":"102",
     "countryCode":"LV",
     "latitude":"56.66880060",
     "longitude":"26.56364140"
  },
  {
     "name":"Nereta Municipality",
     "isoCode":"065",
     "countryCode":"LV",
     "latitude":"56.19866550",
     "longitude":"25.32529690"
  },
  {
     "name":"Madona Municipality",
     "isoCode":"059",
     "countryCode":"LV",
     "latitude":"56.85989230",
     "longitude":"26.22762010"
  },
  {
     "name":"Sala Municipality",
     "isoCode":"085",
     "countryCode":"LV",
     "latitude":"59.96796130",
     "longitude":"16.49782170"
  },
  {
     "name":"Ķekava Municipality",
     "isoCode":"052",
     "countryCode":"LV",
     "latitude":"56.80643510",
     "longitude":"24.19394930"
  },
  {
     "name":"Nīca Municipality",
     "isoCode":"066",
     "countryCode":"LV",
     "latitude":"56.34649830",
     "longitude":"21.06549300"
  },
  {
     "name":"Dobele Municipality",
     "isoCode":"026",
     "countryCode":"LV",
     "latitude":"56.62630500",
     "longitude":"23.28090660"
  },
  {
     "name":"Jēkabpils Municipality",
     "isoCode":"042",
     "countryCode":"LV",
     "latitude":"56.29193200",
     "longitude":"25.98120170"
  },
  {
     "name":"Saldus Municipality",
     "isoCode":"088",
     "countryCode":"LV",
     "latitude":"56.66650880",
     "longitude":"22.49354930"
  },
  {
     "name":"Roja Municipality",
     "isoCode":"079",
     "countryCode":"LV",
     "latitude":"57.50467130",
     "longitude":"22.80121640"
  },
  {
     "name":"Iecava Municipality",
     "isoCode":"034",
     "countryCode":"LV",
     "latitude":"56.59867930",
     "longitude":"24.19962720"
  },
  {
     "name":"Ozolnieki Municipality",
     "isoCode":"069",
     "countryCode":"LV",
     "latitude":"56.67563050",
     "longitude":"23.89948160"
  },
  {
     "name":"Saulkrasti Municipality",
     "isoCode":"089",
     "countryCode":"LV",
     "latitude":"57.25794180",
     "longitude":"24.41831460"
  },
  {
     "name":"Ērgļi Municipality",
     "isoCode":"030",
     "countryCode":"LV",
     "latitude":"56.92370650",
     "longitude":"25.67538520"
  },
  {
     "name":"Aglona Municipality",
     "isoCode":"001",
     "countryCode":"LV",
     "latitude":"56.10890060",
     "longitude":"27.12862270"
  },
  {
     "name":"Jūrmala",
     "isoCode":"JUR",
     "countryCode":"LV",
     "latitude":"56.94707900",
     "longitude":"23.61684850"
  },
  {
     "name":"Skrunda Municipality",
     "isoCode":"093",
     "countryCode":"LV",
     "latitude":"56.66434580",
     "longitude":"22.00457290"
  },
  {
     "name":"Engure Municipality",
     "isoCode":"029",
     "countryCode":"LV",
     "latitude":"57.16235000",
     "longitude":"23.21966340"
  },
  {
     "name":"Inčukalns Municipality",
     "isoCode":"037",
     "countryCode":"LV",
     "latitude":"57.09943420",
     "longitude":"24.68555700"
  },
  {
     "name":"Mārupe Municipality",
     "isoCode":"062",
     "countryCode":"LV",
     "latitude":"56.89657170",
     "longitude":"24.04600490"
  },
  {
     "name":"Mērsrags Municipality",
     "isoCode":"063",
     "countryCode":"LV",
     "latitude":"57.33068810",
     "longitude":"23.10237070"
  },
  {
     "name":"Koknese Municipality",
     "isoCode":"046",
     "countryCode":"LV",
     "latitude":"56.72055600",
     "longitude":"25.48939090"
  },
  {
     "name":"Kārsava Municipality",
     "isoCode":"044",
     "countryCode":"LV",
     "latitude":"56.76458420",
     "longitude":"27.73582950"
  },
  {
     "name":"Carnikava Municipality",
     "isoCode":"020",
     "countryCode":"LV",
     "latitude":"57.10241210",
     "longitude":"24.21086620"
  },
  {
     "name":"Rēzekne Municipality",
     "isoCode":"077",
     "countryCode":"LV",
     "latitude":"56.32736380",
     "longitude":"27.32843310"
  },
  {
     "name":"Viesīte Municipality",
     "isoCode":"107",
     "countryCode":"LV",
     "latitude":"56.31130850",
     "longitude":"25.50704640"
  },
  {
     "name":"Ape Municipality",
     "isoCode":"009",
     "countryCode":"LV",
     "latitude":"57.53926970",
     "longitude":"26.69416490"
  },
  {
     "name":"Durbe Municipality",
     "isoCode":"028",
     "countryCode":"LV",
     "latitude":"56.62798570",
     "longitude":"21.49162450"
  },
  {
     "name":"Talsi Municipality",
     "isoCode":"097",
     "countryCode":"LV",
     "latitude":"57.34152080",
     "longitude":"22.57131250"
  },
  {
     "name":"Liepāja",
     "isoCode":"LPX",
     "countryCode":"LV",
     "latitude":"56.50466780",
     "longitude":"21.01080600"
  },
  {
     "name":"Mālpils Municipality",
     "isoCode":"061",
     "countryCode":"LV",
     "latitude":"57.00841190",
     "longitude":"24.95742780"
  },
  {
     "name":"Smiltene Municipality",
     "isoCode":"094",
     "countryCode":"LV",
     "latitude":"57.42303320",
     "longitude":"25.90027800"
  },
  {
     "name":"Daugavpils",
     "isoCode":"DGV",
     "countryCode":"LV",
     "latitude":"55.87473600",
     "longitude":"26.53617900"
  },
  {
     "name":"Jēkabpils",
     "isoCode":"JKB",
     "countryCode":"LV",
     "latitude":"56.50145500",
     "longitude":"25.87829900"
  },
  {
     "name":"Bauska Municipality",
     "isoCode":"016",
     "countryCode":"LV",
     "latitude":"56.41018680",
     "longitude":"24.20006890"
  },
  {
     "name":"Vecpiebalga Municipality",
     "isoCode":"104",
     "countryCode":"LV",
     "latitude":"57.06033560",
     "longitude":"25.81615920"
  },
  {
     "name":"Pāvilosta Municipality",
     "isoCode":"071",
     "countryCode":"LV",
     "latitude":"56.88654240",
     "longitude":"21.19468490"
  },
  {
     "name":"Brocēni Municipality",
     "isoCode":"018",
     "countryCode":"LV",
     "latitude":"56.73475410",
     "longitude":"22.63573710"
  },
  {
     "name":"Cēsis Municipality",
     "isoCode":"022",
     "countryCode":"LV",
     "latitude":"57.31028970",
     "longitude":"25.26761250"
  },
  {
     "name":"Grobiņa Municipality",
     "isoCode":"032",
     "countryCode":"LV",
     "latitude":"56.53963200",
     "longitude":"21.16689200"
  },
  {
     "name":"Beverīna Municipality",
     "isoCode":"017",
     "countryCode":"LV",
     "latitude":"57.51971090",
     "longitude":"25.60736540"
  },
  {
     "name":"Aizkraukle Municipality",
     "isoCode":"002",
     "countryCode":"LV",
     "latitude":"56.64610800",
     "longitude":"25.23708540"
  },
  {
     "name":"Valmiera",
     "isoCode":"VMR",
     "countryCode":"LV",
     "latitude":"57.53846590",
     "longitude":"25.42636180"
  },
  {
     "name":"Krāslava Municipality",
     "isoCode":"047",
     "countryCode":"LV",
     "latitude":"55.89514640",
     "longitude":"27.18145770"
  },
  {
     "name":"Jaunjelgava Municipality",
     "isoCode":"038",
     "countryCode":"LV",
     "latitude":"56.52836590",
     "longitude":"25.39214430"
  },
  {
     "name":"Sigulda Municipality",
     "isoCode":"091",
     "countryCode":"LV",
     "latitude":"57.10550920",
     "longitude":"24.83142590"
  },
  {
     "name":"Viļaka Municipality",
     "isoCode":"108",
     "countryCode":"LV",
     "latitude":"57.17222630",
     "longitude":"27.66731880"
  },
  {
     "name":"Stopiņi Municipality",
     "isoCode":"095",
     "countryCode":"LV",
     "latitude":"56.93644900",
     "longitude":"24.28729490"
  },
  {
     "name":"Rauna Municipality",
     "isoCode":"076",
     "countryCode":"LV",
     "latitude":"57.33169300",
     "longitude":"25.61003390"
  },
  {
     "name":"Tērvete Municipality",
     "isoCode":"098",
     "countryCode":"LV",
     "latitude":"56.41192010",
     "longitude":"23.31883320"
  },
  {
     "name":"Auce Municipality",
     "isoCode":"010",
     "countryCode":"LV",
     "latitude":"56.46016800",
     "longitude":"22.90547810"
  },
  {
     "name":"Baldone Municipality",
     "isoCode":"013",
     "countryCode":"LV",
     "latitude":"56.74246000",
     "longitude":"24.39115440"
  },
  {
     "name":"Preiļi Municipality",
     "isoCode":"073",
     "countryCode":"LV",
     "latitude":"56.15111570",
     "longitude":"26.74397670"
  },
  {
     "name":"Aloja Municipality",
     "isoCode":"005",
     "countryCode":"LV",
     "latitude":"57.76713600",
     "longitude":"24.87708390"
  },
  {
     "name":"Alsunga Municipality",
     "isoCode":"006",
     "countryCode":"LV",
     "latitude":"56.98285310",
     "longitude":"21.55559190"
  },
  {
     "name":"Viļāni Municipality",
     "isoCode":"109",
     "countryCode":"LV",
     "latitude":"56.54561710",
     "longitude":"26.91679270"
  },
  {
     "name":"Alūksne Municipality",
     "isoCode":"007",
     "countryCode":"LV",
     "latitude":"57.42544850",
     "longitude":"27.04249680"
  },
  {
     "name":"Līgatne Municipality",
     "isoCode":"055",
     "countryCode":"LV",
     "latitude":"57.19442040",
     "longitude":"25.09406810"
  },
  {
     "name":"Jaunpils Municipality",
     "isoCode":"040",
     "countryCode":"LV",
     "latitude":"56.73141940",
     "longitude":"23.01256160"
  },
  {
     "name":"Kuldīga Municipality",
     "isoCode":"050",
     "countryCode":"LV",
     "latitude":"56.96872570",
     "longitude":"21.96137390"
  },
  {
     "name":"Riga",
     "isoCode":"RIX",
     "countryCode":"LV",
     "latitude":"56.94964870",
     "longitude":"24.10518650"
  },
  {
     "name":"Daugavpils Municipality",
     "isoCode":"025",
     "countryCode":"LV",
     "latitude":"55.89917830",
     "longitude":"26.61020120"
  },
  {
     "name":"Ropaži Municipality",
     "isoCode":"080",
     "countryCode":"LV",
     "latitude":"56.96157860",
     "longitude":"24.60104760"
  },
  {
     "name":"Strenči Municipality",
     "isoCode":"096",
     "countryCode":"LV",
     "latitude":"57.62254710",
     "longitude":"25.80480860"
  },
  {
     "name":"Kocēni Municipality",
     "isoCode":"045",
     "countryCode":"LV",
     "latitude":"57.52262920",
     "longitude":"25.33495070"
  },
  {
     "name":"Aizpute Municipality",
     "isoCode":"003",
     "countryCode":"LV",
     "latitude":"56.71825960",
     "longitude":"21.60727590"
  },
  {
     "name":"Amata Municipality",
     "isoCode":"008",
     "countryCode":"LV",
     "latitude":"56.99387260",
     "longitude":"25.26276750"
  },
  {
     "name":"Baltinava Municipality",
     "isoCode":"014",
     "countryCode":"LV",
     "latitude":"56.94584680",
     "longitude":"27.64410660"
  },
  {
     "name":"Aknīste Municipality",
     "isoCode":"004",
     "countryCode":"LV",
     "latitude":"56.16130370",
     "longitude":"25.74848270"
  },
  {
     "name":"Jelgava",
     "isoCode":"JEL",
     "countryCode":"LV",
     "latitude":"56.65110910",
     "longitude":"23.72135410"
  },
  {
     "name":"Ludza Municipality",
     "isoCode":"058",
     "countryCode":"LV",
     "latitude":"56.54595900",
     "longitude":"27.71431990"
  },
  {
     "name":"Riebiņi Municipality",
     "isoCode":"078",
     "countryCode":"LV",
     "latitude":"56.34361900",
     "longitude":"26.80181380"
  },
  {
     "name":"Rucava Municipality",
     "isoCode":"081",
     "countryCode":"LV",
     "latitude":"56.15931240",
     "longitude":"21.16181210"
  },
  {
     "name":"Dagda Municipality",
     "isoCode":"024",
     "countryCode":"LV",
     "latitude":"56.09560890",
     "longitude":"27.53245900"
  },
  {
     "name":"Balvi Municipality",
     "isoCode":"015",
     "countryCode":"LV",
     "latitude":"57.13262400",
     "longitude":"27.26466850"
  },
  {
     "name":"Priekuļi Municipality",
     "isoCode":"075",
     "countryCode":"LV",
     "latitude":"57.36171380",
     "longitude":"25.44104230"
  },
  {
     "name":"Pārgauja Municipality",
     "isoCode":"070",
     "countryCode":"LV",
     "latitude":"57.36481220",
     "longitude":"24.98220450"
  },
  {
     "name":"Vaiņode Municipality",
     "isoCode":"100",
     "countryCode":"LV",
     "latitude":"56.41542710",
     "longitude":"21.85139840"
  },
  {
     "name":"Rēzekne",
     "isoCode":"REZ",
     "countryCode":"LV",
     "latitude":"56.50992230",
     "longitude":"27.33313570"
  },
  {
     "name":"Garkalne Municipality",
     "isoCode":"031",
     "countryCode":"LV",
     "latitude":"57.01903870",
     "longitude":"24.38261810"
  },
  {
     "name":"Ikšķile Municipality",
     "isoCode":"035",
     "countryCode":"LV",
     "latitude":"56.83736670",
     "longitude":"24.49747450"
  },
  {
     "name":"Lielvārde Municipality",
     "isoCode":"053",
     "countryCode":"LV",
     "latitude":"56.73929760",
     "longitude":"24.97116180"
  },
  {
     "name":"Mazsalaca Municipality",
     "isoCode":"060",
     "countryCode":"LV",
     "latitude":"57.92677490",
     "longitude":"25.06698950"
  },
  {
     "name":"Viqueque Municipality",
     "isoCode":"VI",
     "countryCode":"TL",
     "latitude":"-8.85979180",
     "longitude":"126.36335160"
  },
  {
     "name":"Liquiçá Municipality",
     "isoCode":"LI",
     "countryCode":"TL",
     "latitude":"-8.66740950",
     "longitude":"125.25879640"
  },
  {
     "name":"Ermera District",
     "isoCode":"ER",
     "countryCode":"TL",
     "latitude":"-8.75248020",
     "longitude":"125.39872940"
  },
  {
     "name":"Manatuto District",
     "isoCode":"MT",
     "countryCode":"TL",
     "latitude":"-8.51556080",
     "longitude":"126.01592550"
  },
  {
     "name":"Ainaro Municipality",
     "isoCode":"AN",
     "countryCode":"TL",
     "latitude":"-9.01131710",
     "longitude":"125.52200120"
  },
  {
     "name":"Manufahi Municipality",
     "isoCode":"MF",
     "countryCode":"TL",
     "latitude":"-9.01454950",
     "longitude":"125.82799590"
  },
  {
     "name":"Aileu municipality",
     "isoCode":"AL",
     "countryCode":"TL",
     "latitude":"-8.70439940",
     "longitude":"125.60954740"
  },
  {
     "name":"Baucau Municipality",
     "isoCode":"BA",
     "countryCode":"TL",
     "latitude":"-8.47143080",
     "longitude":"126.45759910"
  },
  {
     "name":"Cova Lima Municipality",
     "isoCode":"CO",
     "countryCode":"TL",
     "latitude":"-9.26503750",
     "longitude":"125.25879640"
  },
  {
     "name":"Lautém Municipality",
     "isoCode":"LA",
     "countryCode":"TL",
     "latitude":"-8.36423070",
     "longitude":"126.90438450"
  },
  {
     "name":"Dili municipality",
     "isoCode":"DI",
     "countryCode":"TL",
     "latitude":"-8.24496130",
     "longitude":"125.58766970"
  },
  {
     "name":"Bobonaro Municipality",
     "isoCode":"BO",
     "countryCode":"TL",
     "latitude":"-8.96554060",
     "longitude":"125.25879640"
  },
  {
     "name":"Peleliu",
     "isoCode":"350",
     "countryCode":"PW",
     "latitude":"7.00229060",
     "longitude":"134.24316280"
  },
  {
     "name":"Ngardmau",
     "isoCode":"222",
     "countryCode":"PW",
     "latitude":"7.58504860",
     "longitude":"134.55960890"
  },
  {
     "name":"Airai",
     "isoCode":"004",
     "countryCode":"PW",
     "latitude":"7.39661180",
     "longitude":"134.56902250"
  },
  {
     "name":"Hatohobei",
     "isoCode":"050",
     "countryCode":"PW",
     "latitude":"3.00706580",
     "longitude":"131.12377810"
  },
  {
     "name":"Melekeok",
     "isoCode":"212",
     "countryCode":"PW",
     "latitude":"7.51502860",
     "longitude":"134.59725180"
  },
  {
     "name":"Ngatpang",
     "isoCode":"224",
     "countryCode":"PW",
     "latitude":"7.47109940",
     "longitude":"134.52664660"
  },
  {
     "name":"Koror",
     "isoCode":"150",
     "countryCode":"PW",
     "latitude":"7.33756460",
     "longitude":"134.48894690"
  },
  {
     "name":"Ngarchelong",
     "isoCode":"218",
     "countryCode":"PW",
     "latitude":"7.71054690",
     "longitude":"134.63016460"
  },
  {
     "name":"Ngiwal",
     "isoCode":"228",
     "countryCode":"PW",
     "latitude":"7.56147640",
     "longitude":"134.61606190"
  },
  {
     "name":"Sonsorol",
     "isoCode":"370",
     "countryCode":"PW",
     "latitude":"5.32681190",
     "longitude":"132.22391170"
  },
  {
     "name":"Ngchesar",
     "isoCode":"226",
     "countryCode":"PW",
     "latitude":"7.45232800",
     "longitude":"134.57843420"
  },
  {
     "name":"Ngaraard",
     "isoCode":"214",
     "countryCode":"PW",
     "latitude":"7.60794000",
     "longitude":"134.63486450"
  },
  {
     "name":"Angaur",
     "isoCode":"010",
     "countryCode":"PW",
     "latitude":"6.90922300",
     "longitude":"134.13879340"
  },
  {
     "name":"Kayangel",
     "isoCode":"100",
     "countryCode":"PW",
     "latitude":"8.07000000",
     "longitude":"134.70277800"
  },
  {
     "name":"Aimeliik",
     "isoCode":"002",
     "countryCode":"PW",
     "latitude":"7.44558590",
     "longitude":"134.50308780"
  },
  {
     "name":"Ngeremlengui",
     "isoCode":"227",
     "countryCode":"PW",
     "latitude":"7.51983970",
     "longitude":"134.55960890"
  },
  {
     "name":"Břeclav District",
     "isoCode":"644",
     "countryCode":"CZ",
     "latitude":"48.75314000",
     "longitude":"16.88251690"
  },
  {
     "name":"Český Krumlov District",
     "isoCode":"312",
     "countryCode":"CZ",
     "latitude":"48.81273540",
     "longitude":"14.31746570"
  },
  {
     "name":"Plzeň-City District",
     "isoCode":"323",
     "countryCode":"CZ",
     "latitude":"49.73843140",
     "longitude":"13.37363710"
  },
  {
     "name":"Brno-Country District",
     "isoCode":"643",
     "countryCode":"CZ",
     "latitude":"49.12501380",
     "longitude":"16.45588240"
  },
  {
     "name":"Příbram District",
     "isoCode":"20B",
     "countryCode":"CZ",
     "latitude":"49.69479590",
     "longitude":"14.08238100"
  },
  {
     "name":"Pardubice District",
     "isoCode":"532",
     "countryCode":"CZ",
     "latitude":"49.94444790",
     "longitude":"16.28569160"
  },
  {
     "name":"Nový Jičín District",
     "isoCode":"804",
     "countryCode":"CZ",
     "latitude":"49.59432510",
     "longitude":"18.01353560"
  },
  {
     "name":"Prague 12",
     "isoCode":"112",
     "countryCode":"CZ",
     "latitude":"50.00393050",
     "longitude":"14.41718750"
  },
  {
     "name":"Náchod District",
     "isoCode":"523",
     "countryCode":"CZ",
     "latitude":"50.41457220",
     "longitude":"16.16563470"
  },
  {
     "name":"Prostějov District",
     "isoCode":"713",
     "countryCode":"CZ",
     "latitude":"49.44184010",
     "longitude":"17.12779040"
  },
  {
     "name":"Zlín Region",
     "isoCode":"72",
     "countryCode":"CZ",
     "latitude":"49.21622960",
     "longitude":"17.77203530"
  },
  {
     "name":"Chomutov District",
     "isoCode":"422",
     "countryCode":"CZ",
     "latitude":"50.45838720",
     "longitude":"13.30179100"
  },
  {
     "name":"Central Bohemian Region",
     "isoCode":"20",
     "countryCode":"CZ",
     "latitude":"49.87822230",
     "longitude":"14.93629550"
  },
  {
     "name":"Prague 13",
     "isoCode":"113",
     "countryCode":"CZ",
     "latitude":"50.05323400",
     "longitude":"14.30963920"
  },
  {
     "name":"České Budějovice District",
     "isoCode":"311",
     "countryCode":"CZ",
     "latitude":"48.97755530",
     "longitude":"14.51507470"
  },
  {
     "name":"Prague 5",
     "isoCode":"105",
     "countryCode":"CZ",
     "latitude":"50.06975890",
     "longitude":"14.37779830"
  },
  {
     "name":"Rakovník District",
     "isoCode":"20C",
     "countryCode":"CZ",
     "latitude":"50.10612300",
     "longitude":"13.73966230"
  },
  {
     "name":"Frýdek-Místek District",
     "isoCode":"802",
     "countryCode":"CZ",
     "latitude":"49.68193050",
     "longitude":"18.36732160"
  },
  {
     "name":"Písek District",
     "isoCode":"314",
     "countryCode":"CZ",
     "latitude":"49.34199380",
     "longitude":"14.24697600"
  },
  {
     "name":"Hodonín District",
     "isoCode":"645",
     "countryCode":"CZ",
     "latitude":"48.85293910",
     "longitude":"17.12600250"
  },
  {
     "name":"Prague 1",
     "isoCode":"101",
     "countryCode":"CZ",
     "latitude":"50.08871010",
     "longitude":"14.41559810"
  },
  {
     "name":"Zlín District",
     "isoCode":"724",
     "countryCode":"CZ",
     "latitude":"49.16960520",
     "longitude":"17.80252200"
  },
  {
     "name":"Plzeň-North District",
     "isoCode":"325",
     "countryCode":"CZ",
     "latitude":"49.87748930",
     "longitude":"13.25374280"
  },
  {
     "name":"Tábor District",
     "isoCode":"317",
     "countryCode":"CZ",
     "latitude":"49.36462930",
     "longitude":"14.71912930"
  },
  {
     "name":"Prague 9",
     "isoCode":"109",
     "countryCode":"CZ",
     "latitude":"50.11129700",
     "longitude":"14.50255070"
  },
  {
     "name":"Prague 16",
     "isoCode":"116",
     "countryCode":"CZ",
     "latitude":"49.98318670",
     "longitude":"14.36171330"
  },
  {
     "name":"Brno-City District",
     "isoCode":"642",
     "countryCode":"CZ",
     "latitude":"49.19506020",
     "longitude":"16.60683710"
  },
  {
     "name":"Prague 6",
     "isoCode":"106",
     "countryCode":"CZ",
     "latitude":"50.10057850",
     "longitude":"14.39543630"
  },
  {
     "name":"Prague 11",
     "isoCode":"111",
     "countryCode":"CZ",
     "latitude":"50.03116700",
     "longitude":"14.50708170"
  },
  {
     "name":"Svitavy District",
     "isoCode":"533",
     "countryCode":"CZ",
     "latitude":"49.75516290",
     "longitude":"16.46918610"
  },
  {
     "name":"Vsetín District",
     "isoCode":"723",
     "countryCode":"CZ",
     "latitude":"49.37932500",
     "longitude":"18.06181620"
  },
  {
     "name":"Cheb District",
     "isoCode":"411",
     "countryCode":"CZ",
     "latitude":"50.07953340",
     "longitude":"12.36986360"
  },
  {
     "name":"Olomouc District",
     "isoCode":"712",
     "countryCode":"CZ",
     "latitude":"49.59377800",
     "longitude":"17.25087870"
  },
  {
     "name":"Vysočina Region",
     "isoCode":"63",
     "countryCode":"CZ",
     "latitude":"49.44900520",
     "longitude":"15.64059340"
  },
  {
     "name":"Ústí nad Labem Region",
     "isoCode":"42",
     "countryCode":"CZ",
     "latitude":"50.61190370",
     "longitude":"13.78700860"
  },
  {
     "name":"Horní Počernice",
     "isoCode":"120",
     "countryCode":"CZ",
     "latitude":"50.11271670",
     "longitude":"14.59766850"
  },
  {
     "name":"Prachatice District",
     "isoCode":"315",
     "countryCode":"CZ",
     "latitude":"49.01091000",
     "longitude":"14.00000050"
  },
  {
     "name":"Trutnov District",
     "isoCode":"525",
     "countryCode":"CZ",
     "latitude":"50.56538380",
     "longitude":"15.90909230"
  },
  {
     "name":"Hradec Králové District",
     "isoCode":"521",
     "countryCode":"CZ",
     "latitude":"50.24148050",
     "longitude":"15.67430000"
  },
  {
     "name":"Karlovy Vary Region",
     "isoCode":"41",
     "countryCode":"CZ",
     "latitude":"50.14350000",
     "longitude":"12.75018990"
  },
  {
     "name":"Nymburk District",
     "isoCode":"208",
     "countryCode":"CZ",
     "latitude":"50.18558160",
     "longitude":"15.04366040"
  },
  {
     "name":"Rokycany District",
     "isoCode":"326",
     "countryCode":"CZ",
     "latitude":"49.82628270",
     "longitude":"13.68749430"
  },
  {
     "name":"Ostrava-City District",
     "isoCode":"806",
     "countryCode":"CZ",
     "latitude":"49.82092260",
     "longitude":"18.26252430"
  },
  {
     "name":"Prague 14",
     "isoCode":"114",
     "countryCode":"CZ",
     "latitude":"50.10600360",
     "longitude":"14.55718370"
  },
  {
     "name":"Karviná District",
     "isoCode":"803",
     "countryCode":"CZ",
     "latitude":"49.85665240",
     "longitude":"18.54321860"
  },
  {
     "name":"Prague 4",
     "isoCode":"104",
     "countryCode":"CZ",
     "latitude":"50.04330920",
     "longitude":"14.43887410"
  },
  {
     "name":"Pardubice Region",
     "isoCode":"53",
     "countryCode":"CZ",
     "latitude":"49.94444790",
     "longitude":"16.28569160"
  },
  {
     "name":"Olomouc Region",
     "isoCode":"71",
     "countryCode":"CZ",
     "latitude":"49.65865490",
     "longitude":"17.08114060"
  },
  {
     "name":"Liberec District",
     "isoCode":"513",
     "countryCode":"CZ",
     "latitude":"50.75641010",
     "longitude":"14.99650410"
  },
  {
     "name":"Klatovy District",
     "isoCode":"322",
     "countryCode":"CZ",
     "latitude":"49.39555490",
     "longitude":"13.29509370"
  },
  {
     "name":"Uherské Hradiště District",
     "isoCode":"722",
     "countryCode":"CZ",
     "latitude":"49.05979690",
     "longitude":"17.49585010"
  },
  {
     "name":"Kroměříž District",
     "isoCode":"721",
     "countryCode":"CZ",
     "latitude":"49.29165820",
     "longitude":"17.39938000"
  },
  {
     "name":"Prague 8",
     "isoCode":"108",
     "countryCode":"CZ",
     "latitude":"50.11765750",
     "longitude":"14.46706680"
  },
  {
     "name":"Sokolov District",
     "isoCode":"413",
     "countryCode":"CZ",
     "latitude":"50.20134340",
     "longitude":"12.60546360"
  },
  {
     "name":"Semily District",
     "isoCode":"514",
     "countryCode":"CZ",
     "latitude":"50.60515760",
     "longitude":"15.32814090"
  },
  {
     "name":"Třebíč District",
     "isoCode":"634",
     "countryCode":"CZ",
     "latitude":"49.21478690",
     "longitude":"15.87955160"
  },
  {
     "name":"Prague",
     "isoCode":"10",
     "countryCode":"CZ",
     "latitude":"50.07553810",
     "longitude":"14.43780050"
  },
  {
     "name":"Ústí nad Labem District",
     "isoCode":"427",
     "countryCode":"CZ",
     "latitude":"50.61190370",
     "longitude":"13.78700860"
  },
  {
     "name":"Moravian-Silesian Region",
     "isoCode":"80",
     "countryCode":"CZ",
     "latitude":"49.73053270",
     "longitude":"18.23326370"
  },
  {
     "name":"Liberec Region",
     "isoCode":"51",
     "countryCode":"CZ",
     "latitude":"50.65942400",
     "longitude":"14.76324240"
  },
  {
     "name":"South Moravian Region",
     "isoCode":"64",
     "countryCode":"CZ",
     "latitude":"48.95445280",
     "longitude":"16.76768990"
  },
  {
     "name":"Prague 10",
     "isoCode":"110",
     "countryCode":"CZ",
     "latitude":"50.07174660",
     "longitude":"14.48716560"
  },
  {
     "name":"Karlovy Vary District",
     "isoCode":"412",
     "countryCode":"CZ",
     "latitude":"50.14350000",
     "longitude":"12.75018990"
  },
  {
     "name":"Litoměřice District",
     "isoCode":"423",
     "countryCode":"CZ",
     "latitude":"50.53841970",
     "longitude":"14.13054580"
  },
  {
     "name":"Prague-East District",
     "isoCode":"209",
     "countryCode":"CZ",
     "latitude":"49.93893070",
     "longitude":"14.79244720"
  },
  {
     "name":"Plzeň Region",
     "isoCode":"32",
     "countryCode":"CZ",
     "latitude":"49.41348120",
     "longitude":"13.31572460"
  },
  {
     "name":"Plzeň-South District",
     "isoCode":"324",
     "countryCode":"CZ",
     "latitude":"49.59048850",
     "longitude":"13.57158610"
  },
  {
     "name":"Děčín District",
     "isoCode":"421",
     "countryCode":"CZ",
     "latitude":"50.77255630",
     "longitude":"14.21276120"
  },
  {
     "name":"Prague 7",
     "isoCode":"107",
     "countryCode":"CZ",
     "latitude":"50.10204900",
     "longitude":"14.43295190"
  },
  {
     "name":"Havlíčkův Brod District",
     "isoCode":"631",
     "countryCode":"CZ",
     "latitude":"49.60433640",
     "longitude":"15.57965520"
  },
  {
     "name":"Jablonec nad Nisou District",
     "isoCode":"512",
     "countryCode":"CZ",
     "latitude":"50.72205280",
     "longitude":"15.17031350"
  },
  {
     "name":"Jihlava District",
     "isoCode":"632",
     "countryCode":"CZ",
     "latitude":"49.39837820",
     "longitude":"15.58704150"
  },
  {
     "name":"Hradec Králové Region",
     "isoCode":"52",
     "countryCode":"CZ",
     "latitude":"50.35124840",
     "longitude":"15.79764590"
  },
  {
     "name":"Blansko District",
     "isoCode":"641",
     "countryCode":"CZ",
     "latitude":"49.36485020",
     "longitude":"16.64775520"
  },
  {
     "name":"Prague 2",
     "isoCode":"102",
     "countryCode":"CZ",
     "latitude":"50.07449580",
     "longitude":"14.43552470"
  },
  {
     "name":"Louny District",
     "isoCode":"424",
     "countryCode":"CZ",
     "latitude":"50.35398120",
     "longitude":"13.80335510"
  },
  {
     "name":"Kolín District",
     "isoCode":"204",
     "countryCode":"CZ",
     "latitude":"49.98832930",
     "longitude":"15.05519770"
  },
  {
     "name":"Prague-West District",
     "isoCode":"20A",
     "countryCode":"CZ",
     "latitude":"49.89352350",
     "longitude":"14.32937790"
  },
  {
     "name":"Beroun District",
     "isoCode":"202",
     "countryCode":"CZ",
     "latitude":"49.95734280",
     "longitude":"13.98407150"
  },
  {
     "name":"Teplice District",
     "isoCode":"426",
     "countryCode":"CZ",
     "latitude":"50.65846050",
     "longitude":"13.75132270"
  },
  {
     "name":"Vyškov District",
     "isoCode":"646",
     "countryCode":"CZ",
     "latitude":"49.21274450",
     "longitude":"16.98559270"
  },
  {
     "name":"Opava District",
     "isoCode":"805",
     "countryCode":"CZ",
     "latitude":"49.90837570",
     "longitude":"17.91633800"
  },
  {
     "name":"Jindřichův Hradec District",
     "isoCode":"313",
     "countryCode":"CZ",
     "latitude":"49.14448230",
     "longitude":"15.00613890"
  },
  {
     "name":"Jeseník District",
     "isoCode":"711",
     "countryCode":"CZ",
     "latitude":"50.22462490",
     "longitude":"17.19804710"
  },
  {
     "name":"Přerov District",
     "isoCode":"714",
     "countryCode":"CZ",
     "latitude":"49.46713560",
     "longitude":"17.50773320"
  },
  {
     "name":"Benešov District",
     "isoCode":"201",
     "countryCode":"CZ",
     "latitude":"49.69008280",
     "longitude":"14.77643990"
  },
  {
     "name":"Strakonice District",
     "isoCode":"316",
     "countryCode":"CZ",
     "latitude":"49.26040430",
     "longitude":"13.91030850"
  },
  {
     "name":"Most District",
     "isoCode":"425",
     "countryCode":"CZ",
     "latitude":"37.15540830",
     "longitude":"-94.29488840"
  },
  {
     "name":"Znojmo District",
     "isoCode":"647",
     "countryCode":"CZ",
     "latitude":"48.92723270",
     "longitude":"16.10378080"
  },
  {
     "name":"Kladno District",
     "isoCode":"203",
     "countryCode":"CZ",
     "latitude":"50.19402580",
     "longitude":"14.10436570"
  },
  {
     "name":"Prague 21",
     "isoCode":"121",
     "countryCode":"CZ",
     "latitude":"50.07455020",
     "longitude":"14.66918540"
  },
  {
     "name":"Česká Lípa District",
     "isoCode":"511",
     "countryCode":"CZ",
     "latitude":"50.67852010",
     "longitude":"14.53969910"
  },
  {
     "name":"Chrudim District",
     "isoCode":"531",
     "countryCode":"CZ",
     "latitude":"49.88302160",
     "longitude":"15.82908660"
  },
  {
     "name":"Prague 3",
     "isoCode":"103",
     "countryCode":"CZ",
     "latitude":"50.08437390",
     "longitude":"14.47072500"
  },
  {
     "name":"Rychnov nad Kněžnou District",
     "isoCode":"524",
     "countryCode":"CZ",
     "latitude":"50.16596510",
     "longitude":"16.27768420"
  },
  {
     "name":"Prague 15",
     "isoCode":"115",
     "countryCode":"CZ",
     "latitude":"50.05270830",
     "longitude":"14.53066470"
  },
  {
     "name":"Mělník District",
     "isoCode":"206",
     "countryCode":"CZ",
     "latitude":"50.31044150",
     "longitude":"14.51792230"
  },
  {
     "name":"South Bohemian Region",
     "isoCode":"31",
     "countryCode":"CZ",
     "latitude":"48.94577890",
     "longitude":"14.44160550"
  },
  {
     "name":"Jičín District",
     "isoCode":"522",
     "countryCode":"CZ",
     "latitude":"50.43533250",
     "longitude":"15.36104400"
  },
  {
     "name":"Domažlice District",
     "isoCode":"321",
     "countryCode":"CZ",
     "latitude":"49.43970270",
     "longitude":"12.93114350"
  },
  {
     "name":"Šumperk District",
     "isoCode":"715",
     "countryCode":"CZ",
     "latitude":"49.97784070",
     "longitude":"16.97177540"
  },
  {
     "name":"Mladá Boleslav District",
     "isoCode":"207",
     "countryCode":"CZ",
     "latitude":"50.42523170",
     "longitude":"14.93624770"
  },
  {
     "name":"Bruntál District",
     "isoCode":"801",
     "countryCode":"CZ",
     "latitude":"49.98817670",
     "longitude":"17.46369410"
  },
  {
     "name":"Pelhřimov District",
     "isoCode":"633",
     "countryCode":"CZ",
     "latitude":"49.43062070",
     "longitude":"15.22298300"
  },
  {
     "name":"Tachov District",
     "isoCode":"327",
     "countryCode":"CZ",
     "latitude":"49.79878030",
     "longitude":"12.63619210"
  },
  {
     "name":"Ústí nad Orlicí District",
     "isoCode":"534",
     "countryCode":"CZ",
     "latitude":"49.97218010",
     "longitude":"16.39966170"
  },
  {
     "name":"Žďár nad Sázavou District",
     "isoCode":"635",
     "countryCode":"CZ",
     "latitude":"49.56430120",
     "longitude":"15.93910300"
  },
  {
     "name":"North East Community Development Council",
     "isoCode":"02",
     "countryCode":"SG",
     "latitude":"45.01181130",
     "longitude":"-93.24681070"
  },
  {
     "name":"South East Community Development Council",
     "isoCode":"04",
     "countryCode":"SG",
     "latitude":"39.28630700",
     "longitude":"-76.56912370"
  },
  {
     "name":"Central Singapore Community Development Council",
     "isoCode":"01",
     "countryCode":"SG",
     "latitude":"1.28951400",
     "longitude":"103.81438790"
  },
  {
     "name":"South West Community Development Council",
     "isoCode":"05",
     "countryCode":"SG",
     "latitude":"39.92569100",
     "longitude":"-75.23105800"
  },
  {
     "name":"North West Community Development Council",
     "isoCode":"03",
     "countryCode":"SG",
     "latitude":"39.10709300",
     "longitude":"-94.45733600"
  },
  {
     "name":"Ewa District",
     "isoCode":"09",
     "countryCode":"NR",
     "latitude":"-0.50872410",
     "longitude":"166.93693840"
  },
  {
     "name":"Uaboe District",
     "isoCode":"13",
     "countryCode":"NR",
     "latitude":"-0.52022220",
     "longitude":"166.93117610"
  },
  {
     "name":"Aiwo District",
     "isoCode":"01",
     "countryCode":"NR",
     "latitude":"-0.53400120",
     "longitude":"166.91388730"
  },
  {
     "name":"Meneng District",
     "isoCode":"11",
     "countryCode":"NR",
     "latitude":"-0.54672400",
     "longitude":"166.93837900"
  },
  {
     "name":"Anabar District",
     "isoCode":"02",
     "countryCode":"NR",
     "latitude":"-0.51335170",
     "longitude":"166.94846240"
  },
  {
     "name":"Nibok District",
     "isoCode":"12",
     "countryCode":"NR",
     "latitude":"-0.51962080",
     "longitude":"166.91893010"
  },
  {
     "name":"Baiti District",
     "isoCode":"05",
     "countryCode":"NR",
     "latitude":"-0.51043100",
     "longitude":"166.92757440"
  },
  {
     "name":"Ijuw District",
     "isoCode":"10",
     "countryCode":"NR",
     "latitude":"-0.52027670",
     "longitude":"166.95710460"
  },
  {
     "name":"Buada District",
     "isoCode":"07",
     "countryCode":"NR",
     "latitude":"-0.53287770",
     "longitude":"166.92685410"
  },
  {
     "name":"Anibare District",
     "isoCode":"04",
     "countryCode":"NR",
     "latitude":"-0.52947580",
     "longitude":"166.95134320"
  },
  {
     "name":"Yaren District",
     "isoCode":"14",
     "countryCode":"NR",
     "latitude":"-0.54668570",
     "longitude":"166.92109130"
  },
  {
     "name":"Boe District",
     "isoCode":"06",
     "countryCode":"NR",
     "latitude":"39.07327760",
     "longitude":"-94.57104980"
  },
  {
     "name":"Denigomodu District",
     "isoCode":"08",
     "countryCode":"NR",
     "latitude":"-0.52479640",
     "longitude":"166.91676890"
  },
  {
     "name":"Anetan District",
     "isoCode":"03",
     "countryCode":"NR",
     "latitude":"-0.50643430",
     "longitude":"166.94270060"
  },
  {
     "name":"Zhytomyr Oblast",
     "isoCode":"18",
     "countryCode":"UA",
     "latitude":"50.25465000",
     "longitude":"28.65866690"
  },
  {
     "name":"Vinnytsia Oblast",
     "isoCode":"05",
     "countryCode":"UA",
     "latitude":"49.23308300",
     "longitude":"28.46821690"
  },
  {
     "name":"Zakarpattia Oblast",
     "isoCode":"21",
     "countryCode":"UA",
     "latitude":"48.62080000",
     "longitude":"22.28788300"
  },
  {
     "name":"Kyiv Oblast",
     "isoCode":"32",
     "countryCode":"UA",
     "latitude":"50.05295060",
     "longitude":"30.76671340"
  },
  {
     "name":"Lviv Oblast",
     "isoCode":"46",
     "countryCode":"UA",
     "latitude":"49.83968300",
     "longitude":"24.02971700"
  },
  {
     "name":"Luhansk Oblast",
     "isoCode":"09",
     "countryCode":"UA",
     "latitude":"48.57404100",
     "longitude":"39.30781500"
  },
  {
     "name":"Ternopil Oblast",
     "isoCode":"61",
     "countryCode":"UA",
     "latitude":"49.55351700",
     "longitude":"25.59476700"
  },
  {
     "name":"Dnipropetrovsk Oblast",
     "isoCode":"12",
     "countryCode":"UA",
     "latitude":"48.46471700",
     "longitude":"35.04618300"
  },
  {
     "name":"Kiev",
     "isoCode":"30",
     "countryCode":"UA",
     "latitude":"50.45010000",
     "longitude":"30.52340000"
  },
  {
     "name":"Kirovohrad Oblast",
     "isoCode":"35",
     "countryCode":"UA",
     "latitude":"48.50793300",
     "longitude":"32.26231700"
  },
  {
     "name":"Chernivtsi Oblast",
     "isoCode":"77",
     "countryCode":"UA",
     "latitude":"48.29168300",
     "longitude":"25.93521700"
  },
  {
     "name":"Mykolaiv Oblast",
     "isoCode":"48",
     "countryCode":"UA",
     "latitude":"46.97503300",
     "longitude":"31.99458290"
  },
  {
     "name":"Cherkasy Oblast",
     "isoCode":"71",
     "countryCode":"UA",
     "latitude":"49.44443300",
     "longitude":"32.05976700"
  },
  {
     "name":"Khmelnytsky Oblast",
     "isoCode":"68",
     "countryCode":"UA",
     "latitude":"49.42298300",
     "longitude":"26.98713310"
  },
  {
     "name":"Ivano-Frankivsk Oblast",
     "isoCode":"26",
     "countryCode":"UA",
     "latitude":"48.92263300",
     "longitude":"24.71111700"
  },
  {
     "name":"Rivne Oblast",
     "isoCode":"56",
     "countryCode":"UA",
     "latitude":"50.61990000",
     "longitude":"26.25161700"
  },
  {
     "name":"Kherson Oblast",
     "isoCode":"65",
     "countryCode":"UA",
     "latitude":"46.63541700",
     "longitude":"32.61686700"
  },
  {
     "name":"Sumy Oblast",
     "isoCode":"59",
     "countryCode":"UA",
     "latitude":"50.90770000",
     "longitude":"34.79810000"
  },
  {
     "name":"Kharkiv Oblast",
     "isoCode":"63",
     "countryCode":"UA",
     "latitude":"49.99350000",
     "longitude":"36.23038300"
  },
  {
     "name":"Zaporizhzhya Oblast",
     "isoCode":"23",
     "countryCode":"UA",
     "latitude":"47.83880000",
     "longitude":"35.13956700"
  },
  {
     "name":"Odessa Oblast",
     "isoCode":"51",
     "countryCode":"UA",
     "latitude":"46.48458300",
     "longitude":"30.73260000"
  },
  {
     "name":"Autonomous Republic of Crimea",
     "isoCode":"43",
     "countryCode":"UA",
     "latitude":"44.95211700",
     "longitude":"34.10241700"
  },
  {
     "name":"Volyn Oblast",
     "isoCode":"07",
     "countryCode":"UA",
     "latitude":"50.74723300",
     "longitude":"25.32538300"
  },
  {
     "name":"Donetsk Oblast",
     "isoCode":"14",
     "countryCode":"UA",
     "latitude":"48.01588300",
     "longitude":"37.80285000"
  },
  {
     "name":"Chernihiv Oblast",
     "isoCode":"74",
     "countryCode":"UA",
     "latitude":"51.49820000",
     "longitude":"31.28934990"
  },
  {
     "name":"Gabrovo Province",
     "isoCode":"07",
     "countryCode":"BG",
     "latitude":"42.86847000",
     "longitude":"25.31688900"
  },
  {
     "name":"Smolyan Province",
     "isoCode":"21",
     "countryCode":"BG",
     "latitude":"41.57741480",
     "longitude":"24.70108710"
  },
  {
     "name":"Pernik Province",
     "isoCode":"14",
     "countryCode":"BG",
     "latitude":"42.60519900",
     "longitude":"23.03779160"
  },
  {
     "name":"Montana Province",
     "isoCode":"12",
     "countryCode":"BG",
     "latitude":"43.40851480",
     "longitude":"23.22575890"
  },
  {
     "name":"Vidin Province",
     "isoCode":"05",
     "countryCode":"BG",
     "latitude":"43.99617390",
     "longitude":"22.86795150"
  },
  {
     "name":"Razgrad Province",
     "isoCode":"17",
     "countryCode":"BG",
     "latitude":"43.52717050",
     "longitude":"26.52412280"
  },
  {
     "name":"Blagoevgrad Province",
     "isoCode":"01",
     "countryCode":"BG",
     "latitude":"42.02086140",
     "longitude":"23.09433560"
  },
  {
     "name":"Sliven Province",
     "isoCode":"20",
     "countryCode":"BG",
     "latitude":"42.68167020",
     "longitude":"26.32285690"
  },
  {
     "name":"Plovdiv Province",
     "isoCode":"16",
     "countryCode":"BG",
     "latitude":"42.13540790",
     "longitude":"24.74529040"
  },
  {
     "name":"Kardzhali Province",
     "isoCode":"09",
     "countryCode":"BG",
     "latitude":"41.63384160",
     "longitude":"25.37766870"
  },
  {
     "name":"Kyustendil Province",
     "isoCode":"10",
     "countryCode":"BG",
     "latitude":"42.28687990",
     "longitude":"22.69396350"
  },
  {
     "name":"Haskovo Province",
     "isoCode":"26",
     "countryCode":"BG",
     "latitude":"41.93441780",
     "longitude":"25.55546720"
  },
  {
     "name":"Sofia City Province",
     "isoCode":"22",
     "countryCode":"BG",
     "latitude":"42.75701090",
     "longitude":"23.45046830"
  },
  {
     "name":"Pleven Province",
     "isoCode":"15",
     "countryCode":"BG",
     "latitude":"43.41701690",
     "longitude":"24.60667080"
  },
  {
     "name":"Stara Zagora Province",
     "isoCode":"24",
     "countryCode":"BG",
     "latitude":"42.42577090",
     "longitude":"25.63448550"
  },
  {
     "name":"Silistra Province",
     "isoCode":"19",
     "countryCode":"BG",
     "latitude":"44.11471010",
     "longitude":"27.26714540"
  },
  {
     "name":"Veliko Tarnovo Province",
     "isoCode":"04",
     "countryCode":"BG",
     "latitude":"43.07565390",
     "longitude":"25.61715000"
  },
  {
     "name":"Lovech Province",
     "isoCode":"11",
     "countryCode":"BG",
     "latitude":"43.13677980",
     "longitude":"24.71393350"
  },
  {
     "name":"Vratsa Province",
     "isoCode":"06",
     "countryCode":"BG",
     "latitude":"43.21018060",
     "longitude":"23.55292100"
  },
  {
     "name":"Pazardzhik Province",
     "isoCode":"13",
     "countryCode":"BG",
     "latitude":"42.19275670",
     "longitude":"24.33362260"
  },
  {
     "name":"Ruse Province",
     "isoCode":"18",
     "countryCode":"BG",
     "latitude":"43.83559640",
     "longitude":"25.96561440"
  },
  {
     "name":"Targovishte Province",
     "isoCode":"25",
     "countryCode":"BG",
     "latitude":"43.24623490",
     "longitude":"26.56912510"
  },
  {
     "name":"Burgas Province",
     "isoCode":"02",
     "countryCode":"BG",
     "latitude":"42.50480000",
     "longitude":"27.46260790"
  },
  {
     "name":"Yambol Province",
     "isoCode":"28",
     "countryCode":"BG",
     "latitude":"42.48414940",
     "longitude":"26.50352960"
  },
  {
     "name":"Varna Province",
     "isoCode":"03",
     "countryCode":"BG",
     "latitude":"43.20464770",
     "longitude":"27.91054880"
  },
  {
     "name":"Dobrich Province",
     "isoCode":"08",
     "countryCode":"BG",
     "latitude":"43.57278600",
     "longitude":"27.82728020"
  },
  {
     "name":"Sofia Province",
     "isoCode":"23",
     "countryCode":"BG",
     "latitude":"42.67344000",
     "longitude":"23.83349370"
  },
  {
     "name":"Suceava County",
     "isoCode":"SV",
     "countryCode":"RO",
     "latitude":"47.55055480",
     "longitude":"25.74106200"
  },
  {
     "name":"Hunedoara County",
     "isoCode":"HD",
     "countryCode":"RO",
     "latitude":"45.79363800",
     "longitude":"22.99759930"
  },
  {
     "name":"Arges",
     "isoCode":"AG",
     "countryCode":"RO",
     "latitude":"45.07225270",
     "longitude":"24.81427260"
  },
  {
     "name":"Bihor County",
     "isoCode":"BH",
     "countryCode":"RO",
     "latitude":"47.01575160",
     "longitude":"22.17226600"
  },
  {
     "name":"Alba",
     "isoCode":"AB",
     "countryCode":"RO",
     "latitude":"44.70091530",
     "longitude":"8.03569110"
  },
  {
     "name":"Ilfov County",
     "isoCode":"IF",
     "countryCode":"RO",
     "latitude":"44.53554800",
     "longitude":"26.23248860"
  },
  {
     "name":"Giurgiu County",
     "isoCode":"GR",
     "countryCode":"RO",
     "latitude":"43.90370760",
     "longitude":"25.96992650"
  },
  {
     "name":"Tulcea County",
     "isoCode":"TL",
     "countryCode":"RO",
     "latitude":"45.04505650",
     "longitude":"29.03249120"
  },
  {
     "name":"Teleorman County",
     "isoCode":"TR",
     "countryCode":"RO",
     "latitude":"44.01604910",
     "longitude":"25.29866280"
  },
  {
     "name":"Prahova County",
     "isoCode":"PH",
     "countryCode":"RO",
     "latitude":"45.08919060",
     "longitude":"26.08293130"
  },
  {
     "name":"Bucharest",
     "isoCode":"B",
     "countryCode":"RO",
     "latitude":"44.42676740",
     "longitude":"26.10253840"
  },
  {
     "name":"Neamț County",
     "isoCode":"NT",
     "countryCode":"RO",
     "latitude":"46.97586850",
     "longitude":"26.38187640"
  },
  {
     "name":"Călărași County",
     "isoCode":"CL",
     "countryCode":"RO",
     "latitude":"44.36587150",
     "longitude":"26.75486070"
  },
  {
     "name":"Bistrița-Năsăud County",
     "isoCode":"BN",
     "countryCode":"RO",
     "latitude":"47.24861070",
     "longitude":"24.53228140"
  },
  {
     "name":"Cluj County",
     "isoCode":"CJ",
     "countryCode":"RO",
     "latitude":"46.79417970",
     "longitude":"23.61214920"
  },
  {
     "name":"Iași County",
     "isoCode":"IS",
     "countryCode":"RO",
     "latitude":"47.26796530",
     "longitude":"27.21856620"
  },
  {
     "name":"Braila",
     "isoCode":"BR",
     "countryCode":"RO",
     "latitude":"45.26524630",
     "longitude":"27.95947140"
  },
  {
     "name":"Constanța County",
     "isoCode":"CT",
     "countryCode":"RO",
     "latitude":"44.21298700",
     "longitude":"28.25500550"
  },
  {
     "name":"Olt County",
     "isoCode":"OT",
     "countryCode":"RO",
     "latitude":"44.20079700",
     "longitude":"24.50229810"
  },
  {
     "name":"Arad County",
     "isoCode":"AR",
     "countryCode":"RO",
     "latitude":"46.22836510",
     "longitude":"21.65978190"
  },
  {
     "name":"Botoșani County",
     "isoCode":"BT",
     "countryCode":"RO",
     "latitude":"47.89240420",
     "longitude":"26.75917810"
  },
  {
     "name":"Sălaj County",
     "isoCode":"SJ",
     "countryCode":"RO",
     "latitude":"47.20908130",
     "longitude":"23.21219010"
  },
  {
     "name":"Dolj County",
     "isoCode":"DJ",
     "countryCode":"RO",
     "latitude":"44.16230220",
     "longitude":"23.63250540"
  },
  {
     "name":"Ialomița County",
     "isoCode":"IL",
     "countryCode":"RO",
     "latitude":"44.60313300",
     "longitude":"27.37899140"
  },
  {
     "name":"Bacău County",
     "isoCode":"BC",
     "countryCode":"RO",
     "latitude":"46.32581840",
     "longitude":"26.66237800"
  },
  {
     "name":"Dâmbovița County",
     "isoCode":"DB",
     "countryCode":"RO",
     "latitude":"44.92898930",
     "longitude":"25.42538500"
  },
  {
     "name":"Satu Mare County",
     "isoCode":"SM",
     "countryCode":"RO",
     "latitude":"47.76689050",
     "longitude":"22.92413770"
  },
  {
     "name":"Galați County",
     "isoCode":"GL",
     "countryCode":"RO",
     "latitude":"45.78005690",
     "longitude":"27.82515760"
  },
  {
     "name":"Timiș County",
     "isoCode":"TM",
     "countryCode":"RO",
     "latitude":"45.81389020",
     "longitude":"21.33310550"
  },
  {
     "name":"Harghita County",
     "isoCode":"HR",
     "countryCode":"RO",
     "latitude":"46.49285070",
     "longitude":"25.64566960"
  },
  {
     "name":"Gorj County",
     "isoCode":"GJ",
     "countryCode":"RO",
     "latitude":"44.94855950",
     "longitude":"23.24270790"
  },
  {
     "name":"Mehedinți County",
     "isoCode":"MH",
     "countryCode":"RO",
     "latitude":"44.55150530",
     "longitude":"22.90441570"
  },
  {
     "name":"Vaslui County",
     "isoCode":"VS",
     "countryCode":"RO",
     "latitude":"46.46310590",
     "longitude":"27.73980310"
  },
  {
     "name":"Caraș-Severin County",
     "isoCode":"CS",
     "countryCode":"RO",
     "latitude":"45.11396460",
     "longitude":"22.07409930"
  },
  {
     "name":"Covasna County",
     "isoCode":"CV",
     "countryCode":"RO",
     "latitude":"45.94263470",
     "longitude":"25.89189840"
  },
  {
     "name":"Sibiu County",
     "isoCode":"SB",
     "countryCode":"RO",
     "latitude":"45.92691060",
     "longitude":"24.22548070"
  },
  {
     "name":"Buzău County",
     "isoCode":"BZ",
     "countryCode":"RO",
     "latitude":"45.33509120",
     "longitude":"26.71075780"
  },
  {
     "name":"Vâlcea County",
     "isoCode":"VL",
     "countryCode":"RO",
     "latitude":"45.07980510",
     "longitude":"24.08352830"
  },
  {
     "name":"Vrancea County",
     "isoCode":"VN",
     "countryCode":"RO",
     "latitude":"45.81348760",
     "longitude":"27.06575310"
  },
  {
     "name":"Brașov County",
     "isoCode":"BV",
     "countryCode":"RO",
     "latitude":"45.77818440",
     "longitude":"25.22258000"
  },
  {
     "name":"Maramureș County",
     "isoCode":"MM",
     "countryCode":"RO",
     "latitude":"46.55699040",
     "longitude":"24.67232150"
  },
  {
     "name":"Aiga-i-le-Tai",
     "isoCode":"AL",
     "countryCode":"WS",
     "latitude":"-13.85137910",
     "longitude":"-172.03254010"
  },
  {
     "name":"Satupa'itea",
     "isoCode":"SA",
     "countryCode":"WS",
     "latitude":"-13.65382140",
     "longitude":"-172.61592710"
  },
  {
     "name":"A'ana",
     "isoCode":"AA",
     "countryCode":"WS",
     "latitude":"-13.89841800",
     "longitude":"-171.97529950"
  },
  {
     "name":"Fa'asaleleaga",
     "isoCode":"FA",
     "countryCode":"WS",
     "latitude":"-13.63076380",
     "longitude":"-172.23659810"
  },
  {
     "name":"Atua",
     "isoCode":"AT",
     "countryCode":"WS",
     "latitude":"-13.97870530",
     "longitude":"-171.62542830"
  },
  {
     "name":"Vaisigano",
     "isoCode":"VS",
     "countryCode":"WS",
     "latitude":"-13.54138270",
     "longitude":"-172.70233830"
  },
  {
     "name":"Palauli",
     "isoCode":"PA",
     "countryCode":"WS",
     "latitude":"-13.72945790",
     "longitude":"-172.45361150"
  },
  {
     "name":"Va'a-o-Fonoti",
     "isoCode":"VF",
     "countryCode":"WS",
     "latitude":"-13.94709030",
     "longitude":"-171.54318720"
  },
  {
     "name":"Gaga'emauga",
     "isoCode":"GE",
     "countryCode":"WS",
     "latitude":"-13.54286660",
     "longitude":"-172.36688700"
  },
  {
     "name":"Tuamasaga",
     "isoCode":"TU",
     "countryCode":"WS",
     "latitude":"-13.91635920",
     "longitude":"-171.82243620"
  },
  {
     "name":"Gaga'ifomauga",
     "isoCode":"GI",
     "countryCode":"WS",
     "latitude":"-13.54680070",
     "longitude":"-172.49693310"
  },
  {
     "name":"Torba",
     "isoCode":"TOB",
     "countryCode":"VU",
     "latitude":"37.07653000",
     "longitude":"27.45657300"
  },
  {
     "name":"Penama",
     "isoCode":"PAM",
     "countryCode":"VU",
     "latitude":"-15.37957580",
     "longitude":"167.90531820"
  },
  {
     "name":"Shefa",
     "isoCode":"SEE",
     "countryCode":"VU",
     "latitude":"32.80576500",
     "longitude":"35.16997100"
  },
  {
     "name":"Malampa",
     "isoCode":"MAP",
     "countryCode":"VU",
     "latitude":"-16.40114050",
     "longitude":"167.60778650"
  },
  {
     "name":"Sanma",
     "isoCode":"SAM",
     "countryCode":"VU",
     "latitude":"-15.48400170",
     "longitude":"166.91820970"
  },
  {
     "name":"Tafea",
     "isoCode":"TAE",
     "countryCode":"VU",
     "latitude":"-18.72378270",
     "longitude":"169.06450560"
  },
  {
     "name":"Honiara",
     "isoCode":"CT",
     "countryCode":"SB",
     "latitude":"-9.44563810",
     "longitude":"159.97289990"
  },
  {
     "name":"Temotu Province",
     "isoCode":"TE",
     "countryCode":"SB",
     "latitude":"-10.68692900",
     "longitude":"166.06239790"
  },
  {
     "name":"Isabel Province",
     "isoCode":"IS",
     "countryCode":"SB",
     "latitude":"-8.05923530",
     "longitude":"159.14470810"
  },
  {
     "name":"Choiseul Province",
     "isoCode":"CH",
     "countryCode":"SB",
     "latitude":"-7.05014940",
     "longitude":"156.95114590"
  },
  {
     "name":"Makira-Ulawa Province",
     "isoCode":"MK",
     "countryCode":"SB",
     "latitude":"-10.57374470",
     "longitude":"161.80969410"
  },
  {
     "name":"Malaita Province",
     "isoCode":"ML",
     "countryCode":"SB",
     "latitude":"-8.94461680",
     "longitude":"160.90712360"
  },
  {
     "name":"Central Province",
     "isoCode":"CE",
     "countryCode":"SB",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Guadalcanal Province",
     "isoCode":"GU",
     "countryCode":"SB",
     "latitude":"-9.57732840",
     "longitude":"160.14558050"
  },
  {
     "name":"Western Province",
     "isoCode":"WE",
     "countryCode":"SB",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Rennell and Bellona Province",
     "isoCode":"RB",
     "countryCode":"SB",
     "latitude":"-11.61314350",
     "longitude":"160.16939490"
  },
  {
     "name":"Burgundy",
     "isoCode":"D",
     "countryCode":"FR",
     "latitude":"47.05250470",
     "longitude":"4.38372150"
  },
  {
     "name":"Auvergne",
     "isoCode":"C",
     "countryCode":"FR",
     "latitude":"45.70326950",
     "longitude":"3.34485360"
  },
  {
     "name":"Picardy",
     "isoCode":"S",
     "countryCode":"FR",
     "latitude":"49.66361270",
     "longitude":"2.52807320"
  },
  {
     "name":"Champagne-Ardenne",
     "isoCode":"G",
     "countryCode":"FR",
     "latitude":"48.79340920",
     "longitude":"4.47252490"
  },
  {
     "name":"Limousin",
     "isoCode":"L",
     "countryCode":"FR",
     "latitude":"45.89322310",
     "longitude":"1.56960180"
  },
  {
     "name":"Nord-Pas-de-Calais",
     "isoCode":"O",
     "countryCode":"FR",
     "latitude":"50.48011530",
     "longitude":"2.79372650"
  },
  {
     "name":"Saint Barthélemy",
     "isoCode":"BL",
     "countryCode":"FR",
     "latitude":"17.90051340",
     "longitude":"-62.82058710"
  },
  {
     "name":"Nouvelle-Aquitaine",
     "isoCode":"NAQ",
     "countryCode":"FR",
     "latitude":"45.70871820",
     "longitude":"0.62689100"
  },
  {
     "name":"Île-de-France",
     "isoCode":"IDF",
     "countryCode":"FR",
     "latitude":"48.84991980",
     "longitude":"2.63704110"
  },
  {
     "name":"Mayotte",
     "isoCode":"YT",
     "countryCode":"FR",
     "latitude":"-12.82750000",
     "longitude":"45.16624400"
  },
  {
     "name":"Auvergne-Rhône-Alpes",
     "isoCode":"ARA",
     "countryCode":"FR",
     "latitude":"45.44714310",
     "longitude":"4.38525070"
  },
  {
     "name":"Occitania",
     "isoCode":"OCC",
     "countryCode":"FR",
     "latitude":"43.89272320",
     "longitude":"3.28276250"
  },
  {
     "name":"Alo",
     "isoCode":"WF-AL",
     "countryCode":"FR",
     "latitude":"40.71583620",
     "longitude":"-73.95963170"
  },
  {
     "name":"Lorraine",
     "isoCode":"M",
     "countryCode":"FR",
     "latitude":"48.87442330",
     "longitude":"6.20809320"
  },
  {
     "name":"Pays de la Loire",
     "isoCode":"PDL",
     "countryCode":"FR",
     "latitude":"47.76328360",
     "longitude":"-0.32996870"
  },
  {
     "name":"Languedoc-Roussillon",
     "isoCode":"K",
     "countryCode":"FR",
     "latitude":"43.59123560",
     "longitude":"3.25836260"
  },
  {
     "name":"Normandy",
     "isoCode":"NOR",
     "countryCode":"FR",
     "latitude":"48.87987040",
     "longitude":"0.17125290"
  },
  {
     "name":"Franche-Comté",
     "isoCode":"I",
     "countryCode":"FR",
     "latitude":"47.13432070",
     "longitude":"6.02230160"
  },
  {
     "name":"Corsica",
     "isoCode":"COR",
     "countryCode":"FR",
     "latitude":"42.03960420",
     "longitude":"9.01289260"
  },
  {
     "name":"Brittany",
     "isoCode":"BRE",
     "countryCode":"FR",
     "latitude":"48.20204710",
     "longitude":"-2.93264350"
  },
  {
     "name":"Aquitaine",
     "isoCode":"B",
     "countryCode":"FR",
     "latitude":"44.70022220",
     "longitude":"-0.29957850"
  },
  {
     "name":"Saint Martin",
     "isoCode":"MF",
     "countryCode":"FR",
     "latitude":"18.07082980",
     "longitude":"-63.05008090"
  },
  {
     "name":"Wallis and Futuna",
     "isoCode":"WF",
     "countryCode":"FR",
     "latitude":"-14.29380000",
     "longitude":"-178.11650000"
  },
  {
     "name":"Alsace",
     "isoCode":"A",
     "countryCode":"FR",
     "latitude":"48.31817950",
     "longitude":"7.44162410"
  },
  {
     "name":"Provence-Alpes-Côte d'Azur",
     "isoCode":"PAC",
     "countryCode":"FR",
     "latitude":"43.93516910",
     "longitude":"6.06791940"
  },
  {
     "name":"Rhône-Alpes",
     "isoCode":"V",
     "countryCode":"FR",
     "latitude":"45.64029050",
     "longitude":"5.68649940"
  },
  {
     "name":"Lower Normandy",
     "isoCode":"P",
     "countryCode":"FR",
     "latitude":"48.87884720",
     "longitude":"-0.51574920"
  },
  {
     "name":"Poitou-Charentes",
     "isoCode":"T",
     "countryCode":"FR",
     "latitude":"45.90355200",
     "longitude":"-0.30918370"
  },
  {
     "name":"Paris",
     "isoCode":"75",
     "countryCode":"FR",
     "latitude":"48.85661400",
     "longitude":"2.35222190"
  },
  {
     "name":"Uvea",
     "isoCode":"WF-UV",
     "countryCode":"FR",
     "latitude":"41.98329800",
     "longitude":"-87.66836200"
  },
  {
     "name":"Centre-Val de Loire",
     "isoCode":"CVL",
     "countryCode":"FR",
     "latitude":"47.75156860",
     "longitude":"1.67506310"
  },
  {
     "name":"Sigave",
     "isoCode":"WF-SG",
     "countryCode":"FR",
     "latitude":"-14.27325920",
     "longitude":"-178.16329000"
  },
  {
     "name":"Grand Est",
     "isoCode":"GES",
     "countryCode":"FR",
     "latitude":"48.69980300",
     "longitude":"6.18780740"
  },
  {
     "name":"Saint Pierre and Miquelon",
     "isoCode":"PM",
     "countryCode":"FR",
     "latitude":"46.88520000",
     "longitude":"-56.31590000"
  },
  {
     "name":"French Guiana",
     "isoCode":"GF",
     "countryCode":"FR",
     "latitude":"3.93388900",
     "longitude":"-53.12578200"
  },
  {
     "name":"Réunion",
     "isoCode":"RE",
     "countryCode":"FR",
     "latitude":"-21.11514100",
     "longitude":"55.53638400"
  },
  {
     "name":"French Polynesia",
     "isoCode":"PF",
     "countryCode":"FR",
     "latitude":"-17.67974200",
     "longitude":"-149.40684300"
  },
  {
     "name":"Bourgogne-Franche-Comté",
     "isoCode":"BFC",
     "countryCode":"FR",
     "latitude":"47.28051270",
     "longitude":"4.99943720"
  },
  {
     "name":"Upper Normandy",
     "isoCode":"Q",
     "countryCode":"FR",
     "latitude":"49.52464100",
     "longitude":"0.88283290"
  },
  {
     "name":"Martinique",
     "isoCode":"MQ",
     "countryCode":"FR",
     "latitude":"14.64152800",
     "longitude":"-61.02417400"
  },
  {
     "name":"Hauts-de-France",
     "isoCode":"HDF",
     "countryCode":"FR",
     "latitude":"50.48011530",
     "longitude":"2.79372650"
  },
  {
     "name":"Guadeloupe",
     "isoCode":"GP",
     "countryCode":"FR",
     "latitude":"16.26500000",
     "longitude":"-61.55100000"
  },
  {
     "name":"West New Britain Province",
     "isoCode":"WBK",
     "countryCode":"PG",
     "latitude":"-5.70474320",
     "longitude":"150.02594660"
  },
  {
     "name":"Bougainville",
     "isoCode":"NSB",
     "countryCode":"PG",
     "latitude":"-6.37539190",
     "longitude":"155.38071010"
  },
  {
     "name":"Jiwaka Province",
     "isoCode":"JWK",
     "countryCode":"PG",
     "latitude":"-5.86911540",
     "longitude":"144.69727740"
  },
  {
     "name":"Hela",
     "isoCode":"HLA",
     "countryCode":"PG",
     "latitude":"42.33295160",
     "longitude":"-83.04826180"
  },
  {
     "name":"East New Britain",
     "isoCode":"EBR",
     "countryCode":"PG",
     "latitude":"-4.61289430",
     "longitude":"151.88773210"
  },
  {
     "name":"Morobe Province",
     "isoCode":"MPL",
     "countryCode":"PG",
     "latitude":"-6.80137370",
     "longitude":"146.56164700"
  },
  {
     "name":"Sandaun Province",
     "isoCode":"SAN",
     "countryCode":"PG",
     "latitude":"-3.71261790",
     "longitude":"141.68342750"
  },
  {
     "name":"Port Moresby",
     "isoCode":"NCD",
     "countryCode":"PG",
     "latitude":"-9.44380040",
     "longitude":"147.18026710"
  },
  {
     "name":"Oro Province",
     "isoCode":"NPP",
     "countryCode":"PG",
     "latitude":"-8.89880630",
     "longitude":"148.18929210"
  },
  {
     "name":"Gulf",
     "isoCode":"GPK",
     "countryCode":"PG",
     "latitude":"37.05483150",
     "longitude":"-94.43704190"
  },
  {
     "name":"Western Highlands Province",
     "isoCode":"WHM",
     "countryCode":"PG",
     "latitude":"-5.62681280",
     "longitude":"144.25931180"
  },
  {
     "name":"New Ireland Province",
     "isoCode":"NIK",
     "countryCode":"PG",
     "latitude":"-4.28532560",
     "longitude":"152.92059180"
  },
  {
     "name":"Manus Province",
     "isoCode":"MRL",
     "countryCode":"PG",
     "latitude":"-2.09411690",
     "longitude":"146.87609510"
  },
  {
     "name":"Madang Province",
     "isoCode":"MPM",
     "countryCode":"PG",
     "latitude":"-4.98497330",
     "longitude":"145.13758340"
  },
  {
     "name":"Southern Highlands Province",
     "isoCode":"SHM",
     "countryCode":"PG",
     "latitude":"-6.41790830",
     "longitude":"143.56356370"
  },
  {
     "name":"Eastern Highlands Province",
     "isoCode":"EHG",
     "countryCode":"PG",
     "latitude":"-6.58616740",
     "longitude":"145.66896360"
  },
  {
     "name":"Chimbu Province",
     "isoCode":"CPK",
     "countryCode":"PG",
     "latitude":"-6.30876820",
     "longitude":"144.87312190"
  },
  {
     "name":"Central Province",
     "isoCode":"CPM",
     "countryCode":"PG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Enga Province",
     "isoCode":"EPW",
     "countryCode":"PG",
     "latitude":"-5.30058490",
     "longitude":"143.56356370"
  },
  {
     "name":"Milne Bay Province",
     "isoCode":"MBA",
     "countryCode":"PG",
     "latitude":"-9.52214510",
     "longitude":"150.67496530"
  },
  {
     "name":"Western Province",
     "isoCode":"WPD",
     "countryCode":"PG",
     "latitude":null,
     "longitude":null
  },
  {
     "name":"Ohio",
     "isoCode":"OH",
     "countryCode":"US",
     "latitude":"40.41728710",
     "longitude":"-82.90712300"
  },
  {
     "name":"Ladakh",
     "isoCode":"LA",
     "countryCode":"IN",
     "latitude":"34.22684750",
     "longitude":"77.56194190"
  },
  {
     "name":"West Bengal",
     "isoCode":"WB",
     "countryCode":"IN",
     "latitude":"22.98675690",
     "longitude":"87.85497550"
  },
  {
     "name":"Sinop Province",
     "isoCode":"57",
     "countryCode":"TR",
     "latitude":"41.55947490",
     "longitude":"34.85805320"
  },
  {
     "name":"Capital District",
     "isoCode":"A",
     "countryCode":"VE",
     "latitude":"41.26148460",
     "longitude":"-95.93108070"
  },
  {
     "name":"Apure",
     "isoCode":"C",
     "countryCode":"VE",
     "latitude":"6.92694830",
     "longitude":"-68.52471490"
  },
  {
     "name":"Jalisco",
     "isoCode":"JAL",
     "countryCode":"MX",
     "latitude":"20.65953820",
     "longitude":"-103.34943760"
  },
  {
     "name":"Roraima",
     "isoCode":"RR",
     "countryCode":"BR",
     "latitude":"2.73759710",
     "longitude":"-62.07509980"
  },
  {
     "name":"Guarda District",
     "isoCode":"09",
     "countryCode":"PT",
     "latitude":"40.53859720",
     "longitude":"-7.26757720"
  },
  {
     "name":"Devonshire Parish",
     "isoCode":"DEV",
     "countryCode":"BM",
     "latitude":"32.30380620",
     "longitude":"-64.76069540"
  },
  {
     "name":"Hamilton Parish",
     "isoCode":"HA",
     "countryCode":"BM",
     "latitude":"32.34494320",
     "longitude":"-64.72365000"
  },
  {
     "name":"Hamilton Municipality",
     "isoCode":"HAM",
     "countryCode":"BM",
     "latitude":"43.32394400",
     "longitude":"-79.90592190"
  },
  {
     "name":"Paget Parish",
     "isoCode":"PAG",
     "countryCode":"BM",
     "latitude":"32.28107400",
     "longitude":"-64.77847870"
  },
  {
     "name":"Pembroke Parish",
     "isoCode":"PEM",
     "countryCode":"BM",
     "latitude":"32.30076720",
     "longitude":"-64.79626300"
  },
  {
     "name":"Saint George's Municipality",
     "isoCode":"SG",
     "countryCode":"BM",
     "latitude":"37.09652780",
     "longitude":"-113.56841640"
  },
  {
     "name":"Saint George's Parish",
     "isoCode":"SGE",
     "countryCode":"BM",
     "latitude":"17.12577590",
     "longitude":"-62.56198110"
  },
  {
     "name":"Sandys Parish",
     "isoCode":"SAN",
     "countryCode":"BM",
     "latitude":"32.29995280",
     "longitude":"-64.86741030"
  },
  {
     "name":"Smith's Parish,",
     "isoCode":"SMI",
     "countryCode":"BM",
     "latitude":"32.31339660",
     "longitude":"-64.73105880"
  },
  {
     "name":"Southampton Parish",
     "isoCode":"SOU",
     "countryCode":"BM",
     "latitude":"32.25400950",
     "longitude":"-64.82590580"
  },
  {
     "name":"Warwick Parish",
     "isoCode":"WAR",
     "countryCode":"BM",
     "latitude":"32.26615340",
     "longitude":"-64.80811980"
  },
  {
     "name":"Huila Department",
     "isoCode":"HUI",
     "countryCode":"CO",
     "latitude":"2.53593490",
     "longitude":"-75.52766990"
  },
  {
     "name":"Uroševac District (Ferizaj)",
     "isoCode":"XUF",
     "countryCode":"XK",
     "latitude":"42.37018440",
     "longitude":"21.14832810"
  },
  {
     "name":"Đakovica District (Gjakove)",
     "isoCode":"XDG",
     "countryCode":"XK",
     "latitude":"42.43757560",
     "longitude":"20.37854380"
  },
  {
     "name":"Gjilan District",
     "isoCode":"XGJ",
     "countryCode":"XK",
     "latitude":"42.46352060",
     "longitude":"21.46940110"
  },
  {
     "name":"Kosovska Mitrovica District",
     "isoCode":"XKM",
     "countryCode":"XK",
     "latitude":"42.89139090",
     "longitude":"20.86599950"
  },
  {
     "name":"Pristina (Priştine)",
     "isoCode":"XPI",
     "countryCode":"XK",
     "latitude":"42.66291380",
     "longitude":"21.16550280"
  },
  {
     "name":"Autonomous City Of Buenos Aires",
     "isoCode":"C",
     "countryCode":"AR",
     "latitude":"-34.60368440",
     "longitude":"-58.38155910"
  },
  {
     "name":"New Providence",
     "isoCode":"NP",
     "countryCode":"BS",
     "latitude":"40.69843480",
     "longitude":"-74.40154050"
  },
  {
     "name":"Shumen",
     "isoCode":"27",
     "countryCode":"BG",
     "latitude":"43.27123980",
     "longitude":"26.93612860"
  },
  {
     "name":"Yuen Long District",
     "isoCode":"NYL",
     "countryCode":"HK",
     "latitude":"22.44559000",
     "longitude":"114.02218000"
  },
  {
     "name":"Tsuen Wan District",
     "isoCode":"NTW",
     "countryCode":"HK",
     "latitude":"22.36281000",
     "longitude":"114.12907000"
  },
  {
     "name":"Tai Po District",
     "isoCode":"NTP",
     "countryCode":"KH",
     "latitude":"22.45085000",
     "longitude":"114.16422000"
  },
  {
     "name":"Sai Kung District",
     "isoCode":"NSK",
     "countryCode":"HK",
     "latitude":"22.38143000",
     "longitude":"114.27052000"
  },
  {
     "name":"Islands District",
     "isoCode":"NIS",
     "countryCode":"HK",
     "latitude":"22.26114000",
     "longitude":"113.94608000"
  },
  {
     "name":"Central and Western District",
     "isoCode":"HCW",
     "countryCode":"HK",
     "latitude":"22.28666000",
     "longitude":"114.15497000"
  },
  {
     "name":"Wan Chai",
     "isoCode":"HWC",
     "countryCode":"HK",
     "latitude":"22.27968000",
     "longitude":"114.17168000"
  },
  {
     "name":"Eastern",
     "isoCode":"HEA",
     "countryCode":"HK",
     "latitude":"22.28411000",
     "longitude":"114.22414000"
  },
  {
     "name":"Southern",
     "isoCode":"HSO",
     "countryCode":"HK",
     "latitude":"22.24725000",
     "longitude":"114.15884000"
  },
  {
     "name":"Yau Tsim Mong",
     "isoCode":"KYT",
     "countryCode":"HK",
     "latitude":"22.32138000",
     "longitude":"114.17260000"
  },
  {
     "name":"Sham Shui Po",
     "isoCode":"KSS",
     "countryCode":"HK",
     "latitude":"22.33074000",
     "longitude":"114.16220000"
  },
  {
     "name":"Kowloon City",
     "isoCode":"KKC",
     "countryCode":"HK",
     "latitude":"22.32820000",
     "longitude":"114.19155000"
  },
  {
     "name":"Wong Tai Sin",
     "isoCode":"KWT",
     "countryCode":"HK",
     "latitude":"22.33353000",
     "longitude":"114.19686000"
  },
  {
     "name":"Kwun Tong",
     "isoCode":"KKT",
     "countryCode":"HK",
     "latitude":"22.31326000",
     "longitude":"114.22581000"
  },
  {
     "name":"Kwai Tsing",
     "isoCode":"NKT",
     "countryCode":"HK",
     "latitude":"22.35488000",
     "longitude":"114.08401000"
  },
  {
     "name":"Tuen Mun",
     "isoCode":"NTM",
     "countryCode":"HK",
     "latitude":"22.39163000",
     "longitude":"113.97708850"
  },
  {
     "name":"North",
     "isoCode":"NNO",
     "countryCode":"HK",
     "latitude":"22.49471000",
     "longitude":"114.13812000"
  },
  {
     "name":"Sha Tin",
     "isoCode":"NST",
     "countryCode":"HK",
     "latitude":"22.38715000",
     "longitude":"114.19534000"
  },
  {
     "name":"Sidi Bel Abbès",
     "isoCode":"22",
     "countryCode":"DZ",
     "latitude":"34.68060240",
     "longitude":"-1.09994950"
  },
  {
     "name":"El M'ghair",
     "isoCode":"49",
     "countryCode":"DZ",
     "latitude":"33.95405610",
     "longitude":"5.13464180"
  },
  {
     "name":"El Menia",
     "isoCode":"50",
     "countryCode":"DZ",
     "latitude":"31.36422500",
     "longitude":"2.57844950"
  },
  {
     "name":"Ouled Djellal",
     "isoCode":"51",
     "countryCode":"DZ",
     "latitude":"34.41782210",
     "longitude":"4.96858430"
  },
  {
     "name":"Bordj Baji Mokhtar",
     "isoCode":"52",
     "countryCode":"DZ",
     "latitude":"22.96633500",
     "longitude":"-3.94727320"
  },
  {
     "name":"Béni Abbès",
     "isoCode":"53",
     "countryCode":"DZ",
     "latitude":"30.08310420",
     "longitude":"-2.83450520"
  },
  {
     "name":"Timimoun",
     "isoCode":"54",
     "countryCode":"DZ",
     "latitude":"29.67890600",
     "longitude":"0.50046080"
  },
  {
     "name":"Touggourt",
     "isoCode":"55",
     "countryCode":"DZ",
     "latitude":"33.12484760",
     "longitude":"5.78327150"
  },
  {
     "name":"Djanet",
     "isoCode":"56",
     "countryCode":"DZ",
     "latitude":"23.83108720",
     "longitude":"8.70046720"
  },
  {
     "name":"In Salah",
     "isoCode":"57",
     "countryCode":"DZ",
     "latitude":"27.21492290",
     "longitude":"1.84843960"
  },
  {
     "name":"In Guezzam",
     "isoCode":"58",
     "countryCode":"DZ",
     "latitude":"20.38643230",
     "longitude":"4.77893940"
  },
  {
     "name":"Mureș County",
     "isoCode":"MS",
     "countryCode":"RO",
     "latitude":"46.55699040",
     "longitude":"24.67232150"
  },
  {
     "name":"Volgograd Oblast",
     "isoCode":"VGG",
     "countryCode":"RU",
     "latitude":"49.25873930",
     "longitude":"39.81544630"
  },
  {
     "name":"La Colle",
     "isoCode":"CL",
     "countryCode":"MC",
     "latitude":"43.73274650",
     "longitude":"7.41372760"
  },
  {
     "name":"La Condamine",
     "isoCode":"CO",
     "countryCode":"MC",
     "latitude":"43.73506650",
     "longitude":"7.41990600"
  },
  {
     "name":"Moneghetti",
     "isoCode":"MG",
     "countryCode":"MC",
     "latitude":"43.73649270",
     "longitude":"7.41533830"
  },
  {
     "name":"Hamadan Province",
     "isoCode":"13",
     "countryCode":"IR",
     "latitude":"34.91936070",
     "longitude":"47.48329250"
  }
]


export default StateListJson;
/* Copyright Information: Created by BuyGroup */