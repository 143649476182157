/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { City } from "country-state-city";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import PhoneInput from "react-phone-number-input/input";
import validationSchema from "../Suppliers/ValidationSchema";
import { toast } from "react-toastify";
import { editSupplier } from "../services/SupplierServices";
import { useHistory } from "react-router-dom";
const UpdateSupplieProfileForm = (props) => {
  let history = useHistory();
  const { initialValues, updateSupplierRow } = props;
  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(
    initialValues.billing_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  /* const "countriesValue" store all selected country values */
  const [
    businessAddressCountriesValue,
    setBusinessAddressCountriesValue,
  ] = useState();

  /* const "filterStates" store all state of selected country */
  const [
    businessAddressFilterStates,
    setBusinessAddressFilterStates,
  ] = useState([]);

  /* const "statesValues" store all selected state values */
  const [
    businessAddressStatesValues,
    setBusinessAddressStatesValues,
  ] = useState();

  /* const "filterCity" store all state of selected country */
  const [businessAddressFilterCity, setBusinessAddressFilterCity] = useState(
    []
  );

  /* const "cityValues" store all selected city Values  */
  const [businessAddressCityValues, setBusinessAddressCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [businessAddressPhoneNumber, setBusinessAddressPhoneNumber] = useState(
    initialValues.business_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [
    noDataBusinessAddressStateMessage,
    setNoDataBusinessAddressStateMessage,
  ] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [
    noDataBusinessAddressCityMessage,
    setNoDataBusinessAddressCityMessage,
  ] = useState(false);

  /* const "countriesValue" store all selected country values */
  const [
    shippingAddressCountriesValue,
    setShippingAddressCountriesValue,
  ] = useState();

  /* const "filterStates" store all state of selected country */
  const [
    shippingAddressFilterStates,
    setShippingAddressFilterStates,
  ] = useState([]);

  /* const "statesValues" store all selected state values */
  const [
    shippingAddressStatesValues,
    setShippingAddressStatesValues,
  ] = useState();

  /* const "businessAddressStreetValues" store businessAddress selected city Values  */
  const [
    businessAddressStreetValues,
    setBusinessAddressStreetValues,
  ] = useState(initialValues.business_address?.street);

  /* const "businessAddressCityValues" store businessAddress selected city Values  */
  const [
    businessAddressZipCodeValues,
    setBusinessAddressZipCodeValues,
  ] = useState(initialValues.business_address?.zip_code);

  /* const "filterCity" store all state of selected country */
  const [shippingAddressFilterCity, setShippingAddressFilterCity] = useState(
    []
  );

  /* const "cityValues" store all selected city Values  */
  const [shippingAddressCityValues, setShippingAddressCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [shippingAddressPhoneNumber, setShippingAddressPhoneNumber] = useState(
    initialValues.shipping_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [
    noDataShippingAddressStateMessage,
    setNoDataShippingAddressStateMessage,
  ] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [
    noDataShippingAddressCityMessage,
    setNoDataShippingAddressCityMessage,
  ] = useState(false);

  /* const "checkedValue" store checkbox value  */
  const [checkedValue, setcheckedValue] = useState(
    initialValues.business_address?.zip_code ===
      initialValues.shipping_address?.zip_code
      ? true
      : false
  );

    /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
    const [
      shippingAddressStreetValues,
      setShippingAddressStreetValues,
    ] = useState(initialValues.shipping_address?.street);
  
    /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
    const [
      shippingAddressZipCodeValues,
      setShippingAddressZipCodeValues,
    ] = useState(initialValues.shipping_address?.zip_code);
  
     /* const "billingAddressStreetValues" store shippingAddress  selected city Values  */
     const [
      billingAddressStreetValues,
      setBillingAddressStreetValues,
    ] = useState(initialValues.billing_address?.street);
  
    /* const "billingAddressZipCodeValues" store shippingAddress  selected city Values  */
    const [
      billingAddressZipCodeValues,
      setBillingAddressZipCodeValues,
    ] = useState(initialValues.billing_address?.zip_code);

  /* const "files" will store User Photo */
  const [files, setFiles] = useState([]);

  const [isLoadingButton, setisLoadingButton] = useState(false);

  useEffect(() => {
    getCountryList();
    getBillingAddressStateList();
    getBillingAddressCityList();
    if (initialValues.business_address?.phone_number) {
      getBusinessAddressStateList();
      getBusinessAddressCityList();
    }
    if (initialValues.shipping_address?.phone_number) {
      getShippingAddressStateList();
      getShippingAddressCityList();
    }
  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /* This function will  set all state for default country  */

  const getBillingAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.billing_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    setCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.billing_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getBillingAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.billing_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.billing_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0].isoCode;

    setStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.billing_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setFilterCity(cityOptions);
  };

  /* This function will  set all state for default country  */

  const getBusinessAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.business_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    setBusinessAddressCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.business_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setBusinessAddressFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getBusinessAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.business_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.business_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0].isoCode;

    setBusinessAddressStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.business_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setBusinessAddressFilterCity(cityOptions);
  };
  /* This function will  set all state for default country  */

  const getShippingAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.shipping_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    setShippingAddressCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.shipping_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setShippingAddressFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getShippingAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.shipping_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0].isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.shipping_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0].isoCode;

    setShippingAddressStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.billing_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setShippingAddressFilterCity(cityOptions);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("billing_address.country", selectedCountry.label);
    setFieldTouched("billing_address.country", false);
    setFieldValue("billing_address.state", "");
    setFieldValue("billing_address.city", "");

    const countryisoCode = selectedCountry.value;

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("billing_address.state", selectedState.label);
    setFieldValue("billing_address.city", "");
    setFieldTouched("billing_address.state", false);

    const stateisoCode = selectedState.value;

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("billing_address.city", selectCity.label);
    setFieldTouched("billing_address.city", false);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   *
   */

  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("billing_address.phone_number", selectphone);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /* This Function handle checkbox  */
  const handleCheckbox = (e, setFieldValue) => {
    const newcheckedvalue = JSON.parse(e.target.value);
    setcheckedValue(!newcheckedvalue);
    
  };

  /** / This function handle phone value of business address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */

  const handleBusinessAddressPhoneNumber = (selectphone, setFieldValue) => {
    setBusinessAddressPhoneNumber(selectphone);
    setFieldValue("business_address.phone_number", selectphone);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This  function will give  all the state from selected Country Business address
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCountriesValue(selectedCountry);
    setFieldValue("business_address.country", selectedCountry.label);
    setFieldTouched("business_address.country", false);
    setFieldValue("business_address.state", "");
    setFieldValue("business_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setBusinessAddressFilterStates(stateOptions);
    setBusinessAddressFilterCity([]);
    setNoDataBusinessAddressStateMessage(true);
    setNoDataBusinessAddressCityMessage(false);
    setBusinessAddressStatesValues("");
    setBusinessAddressCityValues("");
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This  function will give  all the city from selected state BusinessAddress
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressStatesValues(selectedState);
    setFieldValue("business_address.state", selectedState.label);
    setFieldValue("business_address.city", "");
    setFieldTouched("business_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === businessAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setBusinessAddressFilterCity(cityOptions);
    setBusinessAddressCityValues("");
    setNoDataBusinessAddressCityMessage(true);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCityValues(selectCity);
    setFieldValue("business_address.city", selectCity.label);
    setFieldTouched("business_address.city", false);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This function handle phone value shipping address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */
  const handleShippingAddressPhoneNumber = (
    selectphone,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressPhoneNumber(selectphone);
    setFieldValue("shipping_address.phone_number", selectphone);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCountriesValue(selectedCountry);
    setFieldValue("shipping_address.country", selectedCountry.label);
    setFieldTouched("shipping_address.country", false);
    setFieldValue("shipping_address.state", "");
    setFieldValue("shipping_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setShippingAddressFilterStates(stateOptions);
    setShippingAddressFilterCity([]);
    setNoDataShippingAddressStateMessage(true);
    setNoDataShippingAddressCityMessage(false);
    setShippingAddressStatesValues("");
    setShippingAddressCityValues("");
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This  function will give  all the city from selected state in shipping address
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressStatesValues(selectedState);
    setFieldValue("shipping_address.state", selectedState.label);
    setFieldValue("shipping_address.city", "");
    setFieldTouched("shipping_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === shippingAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setShippingAddressFilterCity(cityOptions);
    setShippingAddressCityValues("");
    setNoDataShippingAddressCityMessage(true);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This function handle city value of shipping address
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCityValues(selectCity);
    setFieldValue("shipping_address.city", selectCity.label);
    setFieldTouched("shipping_address.city", false);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };


  const handleShippingAddressStreet = (e, setFieldValue) => {
    setShippingAddressStreetValues(e.target.value);
    setFieldValue("shipping_address.street", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressZipCode = (e, setFieldValue) => {
    setShippingAddressZipCodeValues(e.target.value);
    setFieldValue("shipping_address.zip_code", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleBillingAddressStreet = (e, setFieldValue) => {
    setBillingAddressStreetValues(e.target.value);
    setFieldValue("billing_address.street", e.target.value);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  const handleBillingAddressZipCode = (e, setFieldValue) => {
    setBillingAddressZipCodeValues(e.target.value);
    setFieldValue("billing_address.zip_code", e.target.value);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  const handleBusinessAddressStreet = (e, setFieldValue) => {
    setBusinessAddressStreetValues(e.target.value);
    setFieldValue("business_address.street", e.target.value);
    setFieldValue("business_address.business_address_select", "true");
  };

  const handleBusinessAddressZipCode = (e, setFieldValue) => {
    setBusinessAddressZipCodeValues(e.target.value);
    setFieldValue("business_address.zip_code", e.target.value);
    setFieldValue("business_address.business_address_select", "true");
  };

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        getFiles.map((file, index) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve({
                path: ev.target.result,
                mime_type: file.type,
              });
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */

          //   const mergeFiles = files.concat(images);

          setFiles(images);
          setFieldValue("profile_image", images);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  // Convert base 64 to blob data
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  /**
   * This function send updated data to updateSupplierRow function
   * @param  {editSupplierData} editSupplierData data Object
   */
  const editOnSubmit = (editSupplierData) => {
    setisLoadingButton(true);
    let supplierData = JSON.parse(JSON.stringify(editSupplierData));

    if (checkedValue === true) {
      delete supplierData.shipping_address?.shipping_address_select;
      delete supplierData.billing_address?.billing_address_select;
      delete supplierData.business_address?.business_address_select;
      Object.assign(
        supplierData.billing_address,
        supplierData.business_address
      );
      Object.assign(
        supplierData.shipping_address,
        supplierData.business_address
      );
    } else {
      if (supplierData.business_address.business_address_select === "false") {
        delete supplierData.business_address;
      }
      if (supplierData.shipping_address.shipping_address_select === "false") {
        delete supplierData.shipping_address;
      }
      if (supplierData.billing_address.billing_address_select === "false") {
        delete supplierData.billing_address;
      }
      delete supplierData.shipping_address?.shipping_address_select;
      delete supplierData.billing_address?.billing_address_select;
      delete supplierData.business_address?.business_address_select;
    }

    const image = supplierData.profile_image.map(function (x) {
      return {
        mime_type: x.mime_type,
      };
    });
    supplierData.profile_image = image;
 console.log("supplierData",supplierData)
    // supplierData = trimObj(supplierData);
    let data = supplierData;
    // updateSupplierRow(supplierData);
    editSupplier(data)
      .then((res) => {
        if (res.status === 200) {
          //   return;
          let getLenght;
          if(res.data?.data?.uploadURL===undefined)
          {
            getLenght=0
          }else
          {
            getLenght = res.data?.data?.uploadURL.length;
          }
          console.log(getLenght)


          if (getLenght == 0) {
            toast.success("User Update Successfully", {
              autoClose: 3000,
              hideProgressBar: true,
            });
            let user = JSON.parse(localStorage.user);
            localStorage.setItem("user", JSON.stringify(user));
            setisLoadingButton(false);
            history.push("/app/dashboard");
            // window.location.reload();
          } else {
            const getData = files[0];

            const contentType = getData.mime_type;
            const b64Data = getData.path;
            const replaceDataImage = b64Data.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            const blob = b64toBlob(replaceDataImage, contentType);

            fetch(res.data?.data?.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                // last one Last index getting and apply logic here
                // props.history.push("/app/dashboard");
                toast.success("Profile update successfully", {
                  autoClose: 3000,
                  hideProgressBar: true,
                });
                setisLoadingButton(false);
                let user = JSON.parse(localStorage.user);
                user.profile_image = res.data?.data?.imageURL;
                localStorage.setItem("user", JSON.stringify(user));
                history.push("/app/dashboard");
              })
              .catch(function (error) {
                // setIsLoading(false);
              });
          }
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          toast.error(error.data?.message?.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          // setIsLoading(false);
        }
      });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={editOnSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, handleBlur, values, setFieldTouched, errors }) => (
          <Form>
            <strong style={{ fontSize: "20px", marginTop: "10px" }}>
              Business Address
            </strong>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Company Name
                  </Label>

                  <Field
                    type="text"
                    className="form-control"
                    name="company_name"
                  />
                  <ErrorMessage name="company_name">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Email
                  </Label>

                  <Field
                    type="email"
                    className="form-control"
                    name="email"
                    disabled
                  />

                  <ErrorMessage name="email">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Title
                  </Label>

                  <Field type="text" className="form-control" name="title" />
                  <ErrorMessage name="title">
                    {(msg) => <div className="input-feedback">{msg}</div>}
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Primary Contact Name
                  </Label>

                  <Field
                    type="text"
                    className="form-control"
                    name="primary_contact_name"
                  />

                  <ErrorMessage name="primary_contact_name">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Phone
                  </Label>

                  <PhoneInput
                    country="US"
                    value={businessAddressPhoneNumber}
                    name="business_address.phone_number"
                    onBlur={handleBlur}
                    maxLength={14}
                    className="form-control"
                    onChange={(value) =>
                      handleBusinessAddressPhoneNumber(value, setFieldValue)
                    }
                  />
                  <ErrorMessage name="business_address.phone_number">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Country
                  </Label>

                  <Select
                    value={businessAddressCountriesValue}
                    noOptionsMessage={() => "No country found"}
                    onChange={(selectCountry) =>
                      handleBusinessAddressCountry(
                        selectCountry,
                        setFieldValue,
                        setFieldTouched
                      )
                    }
                    options={countries}
                    name="business_address.country"
                    placeholder="Select Country"
                  />

                  <ErrorMessage name="business_address.country">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    State
                  </Label>

                  <Select
                    value={businessAddressStatesValues}
                    noOptionsMessage={() =>
                      noDataBusinessAddressStateMessage
                        ? "No State Found"
                        : "First select country"
                    }
                    onChange={(value) =>
                      handleBusinessAddressState(
                        value,
                        setFieldValue,
                        setFieldTouched
                      )
                    }
                    options={businessAddressFilterStates}
                    name="business_address.state"
                    placeholder="Select State"
                    className="Select"
                  />

                  <ErrorMessage name="business_address.state">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    City
                  </Label>

                  <Select
                    value={businessAddressCityValues}
                    defaultValue={{
                      value: "",
                      label: initialValues.business_address?.city,
                    }}
                    noOptionsMessage={() =>
                      noDataBusinessAddressCityMessage
                        ? "No City Found"
                        : "First select state"
                    }
                    style={{ color: "black" }}
                    onChange={(value) =>
                      handleBusinessAddressCity(
                        value,
                        setFieldValue,
                        setFieldTouched
                      )
                    }
                    options={businessAddressFilterCity}
                    name="business_address.city"
                    placeholder="Select City"
                  />

                  <ErrorMessage name="business_address.city">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Street
                  </Label>

                   <Field
                  type="text"
                  className="form-control"
                  value={businessAddressStreetValues}
                  name="business_address.street"
                  onChange={(e) =>
                    handleBusinessAddressStreet(e, setFieldValue)
                  }
                />
                  <ErrorMessage name="business_address.street">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="recipient-name" className="col-form-label">
                    Zip Code
                  </Label>
                  <Field
                  type="text"
                  value={businessAddressZipCodeValues}
                  className="form-control"
                  name="business_address.zip_code"
                  onChange={(e) =>
                    handleBusinessAddressZipCode(e, setFieldValue)
                  }
                />
                  <ErrorMessage name="business_address.zip_code">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label>
                    <Input
                      type="checkbox"
                      value={checkedValue}
                      checked={checkedValue}
                      onChange={(e) => handleCheckbox(e, setFieldValue)}
                    />{" "}
                     Shipping address and Billing address are
                  same as Business address
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {checkedValue === false && (
              <div>
                <strong style={{ fontSize: "20px", marginTop: "10px" }}>
                  Shipping Address
                </strong>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Phone
                      </Label>

                      <PhoneInput
                        country="US"
                        value={shippingAddressPhoneNumber}
                        name="shipping_address.phone_number"
                        onBlur={handleBlur}
                        maxLength={14}
                        className="form-control"
                        onChange={(value) =>
                          handleShippingAddressPhoneNumber(
                            value,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                      />
                      <ErrorMessage name="shipping_address.phone_number">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Country
                      </Label>

                      <Select
                        value={shippingAddressCountriesValue}
                        noOptionsMessage={() => "No country found"}
                        onChange={(selectCountry) =>
                          handleShippingAddressCountry(
                            selectCountry,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                        options={countries}
                        name="shipping_address.country"
                        placeholder="Select Country"
                      />

                      <ErrorMessage name="shipping_address.country">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        State
                      </Label>

                      <Select
                        value={shippingAddressStatesValues}
                        noOptionsMessage={() =>
                          noDataBusinessAddressStateMessage
                            ? "No State Found"
                            : "First select country"
                        }
                        onChange={(value) =>
                          handleShippingAddressState(
                            value,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                        options={shippingAddressFilterStates}
                        name="shipping_address.state"
                        placeholder="Select State"
                        className="Select"
                      />

                      <ErrorMessage name="shipping_address.state">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        City
                      </Label>

                      <Select
                        defaultValue={{
                          value: "",
                          label: initialValues.shipping_address?.city,
                        }}
                        value={shippingAddressCityValues}
                        noOptionsMessage={() =>
                          noDataBusinessAddressCityMessage
                            ? "No City Found"
                            : "First select state"
                        }
                        onChange={(value) =>
                          handleShippingAddressCity(
                            value,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                        options={shippingAddressFilterCity}
                        name="shipping_address.city"
                        placeholder="Select City"
                      />

                      <ErrorMessage name="shipping_address.city">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Street
                      </Label>

                      <Field
                      type="text"
                      value={shippingAddressStreetValues}
                      className="form-control"
                      name="shipping_address.street"
                      onChange={(e) =>
                        handleShippingAddressStreet(e, setFieldValue)
                      }
                    />

                      <ErrorMessage name="shipping_address.street">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Zip Code
                      </Label>
                      <Field
                      type="text"
                      value={shippingAddressZipCodeValues}
                      className="form-control"
                      name="shipping_address.zip_code"
                      onChange={(e) =>
                        handleShippingAddressZipCode(e, setFieldValue)
                      }
                    />
                      <ErrorMessage name="shipping_address.zip_code">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <strong style={{ fontSize: "20px", marginTop: "10px" }}>
                  Billing Address
                </strong>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Phone
                      </Label>

                      <PhoneInput
                        country="US"
                        value={phoneValue}
                        name="billing_address.phone_number"
                        onBlur={handleBlur}
                        maxLength={14}
                        className="form-control"
                        onChange={(value) =>
                          handlePhoneNumber(value, setFieldValue)
                        }
                      />
                      <ErrorMessage name="billing_address.phone_number">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Country
                      </Label>

                      <Select
                        value={countriesValue}
                        noOptionsMessage={() => "No country found"}
                        onChange={(selectCountry) =>
                          handleCountry(
                            selectCountry,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                        options={countries}
                        name="billing_address.country"
                        placeholder="Select Country"
                      />

                      <ErrorMessage name="billing_address.country">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        State
                      </Label>

                      <Select
                        value={statesValues}
                        noOptionsMessage={() =>
                          noDataStateMessage
                            ? "No State Found"
                            : "First select country"
                        }
                        onChange={(value) =>
                          handleState(value, setFieldValue, setFieldTouched)
                        }
                        options={filterStates}
                        name="billing_address.state"
                        placeholder="Select State"
                        className="Select"
                      />

                      <ErrorMessage name="billing_address.state">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        City
                      </Label>

                      <Select
                        value={cityValues}
                        defaultValue={{
                          value: "",
                          label: initialValues.billing_address?.city,
                        }}
                        noOptionsMessage={() =>
                          noDataCityMessage
                            ? "No City Found"
                            : "First select state"
                        }
                        onChange={(value) =>
                          handleCity(value, setFieldValue, setFieldTouched)
                        }
                        options={filterCity}
                        name="billing_address.city"
                        placeholder="Select City"
                      />

                      <ErrorMessage name="billing_address.city">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Street
                      </Label>
                      <Field
                      type="text"
                      value={billingAddressStreetValues}
                      className="form-control"
                      name="billing_address.street"
                      onChange={(e) =>
                        handleBillingAddressStreet(e, setFieldValue)
                      }
                    />

                      <ErrorMessage name="billing_address.street">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Zip Code
                      </Label>
                      <Field
                      type="text"
                      value={billingAddressZipCodeValues}
                      className="form-control"
                      name="billing_address.zip_code"
                      onChange={(e) =>
                        handleBillingAddressZipCode(e, setFieldValue)
                      }
                    />
                      <ErrorMessage name="billing_address.zip_code">
                        {(errorMsg) => (
                          <label className="err-msg">{errorMsg}</label>
                        )}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>

                
              </div>
            )}
            <Row>
              <Col md="3">
                <div className="form-group">
                  <input
                    className="show-for-sr"
                    type="file"
                    id="upload_imgs"
                    name="profile_image[]"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => readURI(e, setFieldValue)}
                    // id="file"
                  />
                  <label htmlFor="file">
                    <i className="fa fa-plus"></i> Upload Photo
                  </label>
                  <ul className="photo-container">
                    {files.map((imageURI, index) => (
                      <li>
                        <img
                          className="photo-uploaded"
                          src={imageURI.path}
                          alt="Photo uploaded"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isLoadingButton}
                >
                  {isLoadingButton ? "Please Wait..." : "Update"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateSupplieProfileForm;
/* Copyright Information: Created by BuyGroup */
