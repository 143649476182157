/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import { toast } from "react-toastify";
import UpdateSupplieProfileForm from "./UpdateSupplieProfileForm";
import { SupplierDetail, editSupplier } from "../services/SupplierServices";

toast.configure();

function SupplierProfile(props) {
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  useEffect(() => {
    getSupplierDetail();
  }, []);
  const getSupplierDetail = () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    const supplierId = user.supplier_id;

    SupplierDetail(supplierId)
      .then((responce) => {
        let supplierRow = JSON.parse(JSON.stringify(responce.data.data));

        let SupplierDetail = {};
        SupplierDetail.id = supplierRow.supplier_id;
        SupplierDetail.company_name = supplierRow.company_name;
        SupplierDetail.email = supplierRow.email;
        SupplierDetail.title = supplierRow.title;
        SupplierDetail.primary_contact_name = supplierRow.primary_contact_name;
        SupplierDetail.profile_image = [];

        {
          supplierRow.address_info.length > 0 &&
            supplierRow.address_info.map((address) => {
              if (address.address_type === "BILLING_ADDRESS") {
                let billingAddress = {};
                billingAddress.phone_number = address.phone_number;
                billingAddress.street = address.street;
                billingAddress.city = address.city;
                billingAddress.state = address.state;
                billingAddress.country = address.country;
                billingAddress.zip_code = address.zip_code;
                billingAddress.billing_address_select = "false";
                SupplierDetail.billing_address = billingAddress;
              }

              if (address.address_type === "BUSINESS_ADDRESS") {
                let businessAddress = {};

                businessAddress.phone_number = address.phone_number;
                businessAddress.street = address.street;
                businessAddress.city = address.city;
                businessAddress.state = address.state;
                businessAddress.country = address.country;
                businessAddress.zip_code = address.zip_code;
                businessAddress.business_address_select = "false";
                SupplierDetail.business_address = businessAddress;
              }
              if (address.address_type === "SHIPPING_ADDRESS") {
                let shippingAddress = {};
                shippingAddress.phone_number = address.phone_number;
                shippingAddress.street = address.street;
                shippingAddress.city = address.city;
                shippingAddress.state = address.state;
                shippingAddress.country = address.country;
                shippingAddress.zip_code = address.zip_code;
                shippingAddress.shipping_address_select = "false";
                SupplierDetail.shipping_address = shippingAddress;
              }
            });
        }

        setSupplierData(SupplierDetail);
        setLoading(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const updateSupplierRow = (editSupplierData) => {
    setIsLoadingButton(true);

    editSupplier(editSupplierData)
      .then((res) => {
        // if (response.status === 200) {
        //   setIsLoadingButton(false);
        //   toast.success(response.data.message, {
        //     autoClose: 3000,
        //     hideProgressBar: true,
        //   });
        // }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  return (
    <>
      <Breadcrumbs title="Profile" parent="" subtitle="Dashboard Admin Panel" />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <h5>Profile</h5>
                    </div>
                    <CardBody>
                      {loading ? (
                        <UpdateSupplieProfileForm
                          initialValues={supplierData}
                          updateSupplierRow={updateSupplierRow}
                          isLoadingButton={isLoadingButton}
                        />
                      ) : (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SupplierProfile;
/* Copyright Information: Created by BuyGroup */
