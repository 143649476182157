/* Copyright Information: Created by BuyGroup */
import { faMapMarker, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import { customerDetail, deleteCustomer } from "../services/CustomerServices";
import { CustomerData } from "./CustomerData";
import DeleteCustomer from "./DeleteCustomer";

toast.configure();
export default class CustomerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isLoading: true,
      customerData: CustomerData,
      customerDetails: {},
      isAddressLoader: true,
      initialValues: {},
      isDisableUpdate: false,
      deleteCutomerModal: false,
      deleteCustomerId: null,
      showVerifyModal: false,
      documentData: null,
    };
  }
  componentDidMount() {
    this.getCustomerDetail();
  }
  // this function is used for customer detail
  getCustomerDetail = () => {
    this.setState({ isLoading: true });
    customerDetail(this.props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.company_name === null) {
            response.data.company_name = "";
          }
          if (response.data.title === null) {
            response.data.title = "";
          }

          this.setState({
            customerDetails: response.data,
            isLoading: false,
            initialValues: response.data,
            isAddressLoader: false,
          });
          console.log("customerDetails", this.state.customerDetails.orders);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.setState({ isLoading: false });
      });
  };

  /* This Function will remove customer of respective id */
  removeCustomer = (id) => {
    this.setState({ isLoading: true });
    deleteCustomer(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.setState({ isLoading: false });
          this.props.history.push("/app/members");
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.setState({ isLoading: false });
      });
  };

  //Don't delete it data is passing as a props form here to update Customer component
  openVerfiyModal = (docData) => {
    this.setState({
      showVerifyModal: true,
      documentData: docData,
    });
  };

  //Don't delete it data is passing as a props form here to update Customer component
  closeVerfiyModal = () => {
    this.setState({
      showVerifyModal: false,
    });
  };

  /* This Function will Open delete Customer Modal */
  openDeleteCuromerModal = (CustomerId) => {
    this.setState({ deleteCutomerModal: true, deleteCustomerId: CustomerId });
  };

  /* This Function will closed delete Customer Modal */
  closedDeletecustomerMOdal = () => {
    this.setState({ deleteCutomerModal: false });
  };

  /* format phone number */
  formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  headers = [
    { label: "Product Name", key: "product_name" },
    { label: "Description", key: "description" },
    { label: "Price", key: "selling_price" },
    { label: "Status", key: "status" },
  ];

  render() {
    return (
      <div className="container-fluid">
        <Breadcrumbs title="Members" parent="" subtitle="Members" />
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="d-flex align-items-center justify-content-between">
                        <Col md="6">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5>Member Details</h5>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="title float-right">
                            <Link
                              to={`/app/members/edit/${this.props.match.params.id}`}
                              className="btn btn-primary mr-2"
                            >
                              <i className="fa fa-pencil mr-2"></i>
                              Edit Member
                            </Link>
                            <Button
                              type="button"
                              color="secondary"
                              data-toggle="modal"
                              data-original-title="delete Customer"
                              onClick={(e) =>
                                // this.handleDelete(e, this.props.match.params.id)
                                this.openDeleteCuromerModal(
                                  this.props.match.params.id
                                )
                              }
                              disabled={this.state.isLoading}
                            >
                              <i className="fa fa-trash mr-2"></i>
                              Delete
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {this.state.isLoading ? (
                      <div className="custom-loader">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <CardBody>
                        <Row className="mb-5">
                          <Col md="9">
                            <div>
                              <h5>
                                <strong>Company Name</strong>
                              </h5>
                              <div>
                                {this.state.customerDetails?.company_name}
                              </div>
                            </div>

                            <div className="mt-4">
                              <h5>
                                <strong>Title</strong>
                              </h5>
                              <div>{this.state.customerDetails?.title}</div>
                            </div>

                            <div>
                              {this.state.customerDetails.addressInfo?.length >
                                0 &&
                                this.state.customerDetails.addressInfo.map(
                                  (address, index) => {
                                    if (
                                      address.address_type ===
                                        "BILLING_ADDRESS" &&
                                      address.phone_number !== ""
                                    ) {
                                      return (
                                        <div className="mt-4 AddressBox">
                                          <h4>
                                            <strong>Billing Address</strong>
                                          </h4>
                                          <h6>
                                            <strong>
                                              {address.first_name +
                                                " " +
                                                address.last_name}
                                            </strong>
                                          </h6>

                                          <Row className="mt-1">
                                            <Col md="12">
                                              <FontAwesomeIcon
                                                icon={faPhoneAlt}
                                                className="mt-1 mr-2"
                                              />
                                              <label>
                                                {this.formatPhoneNumber(
                                                  address.phone_number
                                                )}
                                              </label>
                                            </Col>
                                          </Row>

                                          <Row className="mt-1">
                                            <Col md="12" className="d-flex">
                                              <FontAwesomeIcon
                                                icon={faMapMarker}
                                                className="mt-1 mr-2"
                                              />{" "}
                                              <label>
                                                {address.street +
                                                  ", " +
                                                  address.city +
                                                  ", " +
                                                  address.state +
                                                  ", " +
                                                  address.country +
                                                  ", " +
                                                  address.zip_code}
                                              </label>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    }
                                    if (
                                      address.address_type ===
                                        "SHIPPING_ADDRESS" &&
                                      address.phone_number !== ""
                                    ) {
                                      return (
                                        <div className="mt-4 AddressBox">
                                          <h4>
                                            <strong>Shipping Address</strong>
                                          </h4>
                                          <h6>
                                            <strong>
                                              {address.first_name +
                                                " " +
                                                address.last_name}
                                            </strong>
                                          </h6>

                                          <Row className="mt-1">
                                            <Col md="12">
                                              <FontAwesomeIcon
                                                icon={faPhoneAlt}
                                                className="mt-1 mr-2"
                                              />{" "}
                                              <label>
                                                {this.formatPhoneNumber(
                                                  address.phone_number
                                                )}
                                              </label>
                                            </Col>
                                          </Row>

                                          <Row className="mt-1">
                                            <Col md="12" className="d-flex">
                                              <FontAwesomeIcon
                                                icon={faMapMarker}
                                                className="mt-1 mr-2"
                                              />{" "}
                                              <label>
                                                {address.street +
                                                  ", " +
                                                  address.city +
                                                  ", " +
                                                  address.state +
                                                  ", " +
                                                  address.country +
                                                  ", " +
                                                  address.zip_code}
                                              </label>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="mt-2 col-md-6">
                            <span>
                              <h5>
                                <strong>Orders List</strong>
                              </h5>
                            </span>
                          </div>
                          {this.state.customerDetails.orders.length > 0 ? (
                            <div className="col-md-6 text-right">
                              <CSVLink
                                className="btn btn-secondary"
                                data={this.state.customerDetails.orders}
                                headers={this.headers}
                                filename={"order.csv"}
                              >
                                <i className="fa fa-download mr-2"></i> CSV
                              </CSVLink>
                            </div>
                          ) : (
                            ""
                          )}
                        </Row>
                        <div>
                          {this.state.customerDetails.orders !== undefined &&
                          this.state.customerDetails.orders?.length > 0 ? (
                            this.state.customerDetails.orders.map((order) => {
                              return (
                                <Row
                                  className="mt-2 customer_row_repeat"
                                  key={order.product_name}
                                >
                                  <Col md="9">
                                    <div className="d-flex">
                                      <div style={{ width: "200px" }}>
                                        <img
                                          src={order.path}
                                          alt="new"
                                          className="img-responsive"
                                        />
                                      </div>
                                      <div className="pl-3">
                                        <h5
                                          style={{
                                            marginBottom: "3px",
                                          }}
                                        >
                                          <strong>{order.product_name}</strong>
                                        </h5>
                                        <p>{order.description}</p>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="3" className="text-right">
                                    <h5>
                                      <strong>
                                        $
                                        {parseFloat(
                                          order.selling_price
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </strong>
                                    </h5>
                                    <div
                                      className={`status_column ${order.status}`}
                                    >
                                      <span>{order.status}</span>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })
                          ) : (
                            <p className="no_data_message">
                              No orders are available.
                            </p>
                          )}
                        </div>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </Row>
              {this.state.deleteCutomerModal && (
                <DeleteCustomer
                  showDeleteModal={this.state.deleteCutomerModal}
                  closeDeleteModal={this.closedDeletecustomerMOdal}
                  deleteCustomerId={this.state.deleteCustomerId}
                  removeCustomer={this.removeCustomer}
                  isLoading={this.state.isLoading}
                />
              )}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
/* Copyright Information: Created by BuyGroup */
