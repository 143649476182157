/* Copyright Information: Created by BuyGroup */
import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const DeleteCustomer = (props) => {
  const {
    showDeleteModal,
    closeDeleteModal,
    deleteCustomerId,
    removeCustomer,
    isLoading,
  } = props;
  return (
    <div>
      <Modal isOpen={showDeleteModal} style={{ overlay: { opacity: 0.1 } }}>
        <ModalHeader toggle={closeDeleteModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Are you sure you want to delete this Customer?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={() => removeCustomer(deleteCustomerId)}
            disabled={isLoading}
          >
            {isLoading ? "Please Wait..." : "Yes, Delete it!"}
          </Button>
          <Button type="button" color="light" onClick={closeDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteCustomer;
/* Copyright Information: Created by BuyGroup */