/* Copyright Information: Created by BuyGroup */
import React from "react";
import MainContainer from "./MainContainer";
import PrivateRoutes from "../../routes/PrivateRoutes";
import Header from "./header_components/header";
import Footer from "./AdminFooter";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
      isLoggedIn: false,
    };
  }
  toggleButton = () => {
    //alert('hello');
    this.setState((prevState) => ({ toggleMenu: !prevState.toggleMenu }));
  };
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/");
    } else {
      this.setState({
        isLoggedIn: true,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-wrapper">
          {this.state.isLoggedIn ? (
            <>
              <Header handletoggle={this.toggleButton} {...this.props} />
              <div className="page-body-wrapper">
                <PrivateRoutes handleClass={this.state.toggleMenu} />
                <div className="page-body">
                  <MainContainer
                    handleMainClass={this.state.toggleMenu}
                    {...this.props}
                  />
                </div>
                <Footer />
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <section></section>
      </React.Fragment>
    );
  }
}

export default Main;
/* Copyright Information: Created by BuyGroup */
