/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import trimObj from "../components/common/TrimData";
import { Formik, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import validationSchema from "./GroupValidationSchema";
import { productList } from "../services/ProductServices";
import { createGroup } from "../services/GroupServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import DeleteQuantity from "./DeleteQuantity";
import moment from "moment";
import { setMinutes, setHours, setSeconds } from "date-fns";
import CreatableSelect from "react-select/creatable";

toast.configure();
const AddGroup = (props) => {
  const [isLoading, setLoading] = useState(false); //loading state
  const [isDisable, setDisable] = useState(false); // for disable add button
  const [errorMessage, setErrorMessage] = useState(""); //for from error message
  const [selectedProduct, setProduct] = useState(""); // slected product
  const [discountErrorMessage, setDiscountErrorMessage] = useState(""); // range error message
  const [levels, setLevels] = useState([]); //levels array
  const [discountPercent, setDiscountPercent] = useState("0"); //discount percentage
  const [quantityLevel, setQuantityLevel] = useState("");
  const [levelsErrorMessage, setLevelsErrorMessage] = useState("");
  const [startDateTime, setStartDateTime] = useState(null);
  const [zipinputValue, setZipInputValue] = useState("");
  const [zipCodeValue, setZipCodeValue] = useState([]);

  const [lastRange, setLastRange] = useState({
    quantity_level: "",
    discount_percent: "",
  });
  const [initialValues, setInitialValues] = useState({
    offer_name: "",
    minimum_quantity: "",
    start_date: new Date(),
    end_date: null,
    product_id: "",
    levels: [{ quantity: "", discount_percent: "" }],
    price: "",
    zip_code: [],
    buy_group_shipping_allow: "false",
  });

  /* const "productOptions" store product list  */
  const [productOptions, setProductOptions] = useState([]);

  /* const "checkedValue" store checkbox value  */
  const [checkedValue, setcheckedValue] = useState(false);

  /*  const "shippingLevel" store shipping range of group*/
  const [shippingLevel, setShippingLevel] = useState([]);

  /*const "deleteRangeModal" responsible for open delete range modal   */
  const [deleteRangeModal, setDeleteRangeModal] = useState(false);

  /* const "deleteRangeId" id Of range that we want to delete */
  const [deleteRangeId, setDeleteRangeId] = useState();

  /* const "groupStartDate" store start date of Group*/
  const [groupStartDate, setgroupStarteDate] = useState(null);

  /* const "groupEndDate" store Max date of Group*/
  const [groupEndDate, setgroupEndDate] = useState(null);

  /* const "disableEndDate" responsible for disable End Date*/
  const [disableEndDate, setdisableEndDate] = useState(true);

  /* const "hideTimeChange" responsible for  hide time change input for end date */
  const [hideTimeChange, sethideTimeChange] = useState(true);

  const [groupPriceValue, setgroupPriceValue] = useState();

  const [sameEndDate, setSameEndDate] = useState(true);

  //levels array
  useEffect(() => {
    getProductList();
    setdisableEndDate(true);
  }, []);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!zipinputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setZipCodeValue((prev) => [...prev, createOption(zipinputValue)]);
        setZipInputValue("");
        event.preventDefault();
    }
  };

  //this function is used for product list dropdown in create group
  const getProductList = () => {
    setLoading(true);
    const data = {
      supplier_id: null,
      category_id: null,
      sub_category_id: null,
    };
    productList(data)
      .then((response) => {
        if (response.status === 200) {
          let productList = [];
          //here we are creating option for s=product select
          response.data.map((product) => {
            productList.push({
              label: product.product_name,
              value: product.product_id,
            });
          });
          setProductOptions(productList);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  // this fuction is used to create group
  const createGroupFunction = (groupData) => {
    setDisable(true);

    if (typeof groupData.minimum_quantity === "string") {
      groupData.minimum_quantity = parseInt(groupData.minimum_quantity);
    }
    if (typeof groupData.price === "string") {
      groupData.price = parseInt(groupData.price);
    }

    createGroup(groupData)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setDisable(false);
          props.history.push(`/app/groups/view/${response.data}`);
        }
      })
      .catch((error) => {
        setDisable(false);
        setErrorMessage(error.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };

  // this funciton used in product change dropdown takes argument selectedOption {label:'',value:''}
  const handleChangeProduct = (selectedOption, setFieldValue) => {
    setProduct(selectedOption);
    setFieldValue("product_id", selectedOption.value);
  };

  const getZipValue = (newValue, setFieldValue) => {
    console.log("newValue", newValue);
    setZipInputValue(newValue);
    setFieldValue("zip_code", zipCodeValue);
    console.log("zip_code", zipCodeValue);
  };
  // discount change custom function logic and condition of discount
  const handleChangeDiscount = (event, key) => {
    //check value is number
    if (!isNaN(event.target.value)) {
      //whole number regx

      setDiscountPercent(event.target.value);
    } else {
      event.target.value = "";
      setDiscountErrorMessage("Please Enter Numeric Value");
      setDiscountPercent("");
      setTimeout(() => {
        setDiscountErrorMessage("");
      }, 3000);
    }
  };
  // quantity change custom function
  const handleChangeQuantity = (event, key) => {
    if (!isNaN(event.target.value)) {
      //whole number regx
      var rx = new RegExp(/^\d+$/);
      if (!rx.test(event.target.value)) {
        event.target.value = "";
        setDiscountErrorMessage("quantity can not be in decimal");
        setQuantityLevel("");
        setTimeout(() => {
          setDiscountErrorMessage("");
        }, 3000);
      }
      setQuantityLevel(event.target.value);
    } else {
      event.target.value = "";
      setDiscountErrorMessage("Please Enter Numeric Value");
      setQuantityLevel("");
      setTimeout(() => {
        setDiscountErrorMessage("");
      }, 3000);
    }
  };
  // add quantity and shipping range funciton
  const addQuantityrange = () => {
    let rangeObject = {};
    let shippingobj = {};
    if (levels.length === 0) {
      rangeObject["quantity_level"] = quantityLevel;
      rangeObject["discount_percent"] = discountPercent;
      levels.push(rangeObject);
      setLevels(levels);

      shippingobj["quantity_level"] = quantityLevel;
      shippingobj["shipping_price"] = "0";
      shippingLevel.push(shippingobj);
      setShippingLevel(shippingLevel);

      setLastRange(rangeObject);
      setQuantityLevel("");
      setDiscountPercent("");
    } else {
      rangeObject["quantity_level"] = quantityLevel;
      rangeObject["discount_percent"] = discountPercent;
      levels.push(rangeObject);
      setLevels(levels);

      shippingobj["quantity_level"] = quantityLevel;
      shippingobj["shipping_price"] = "0";
      shippingLevel.push(shippingobj);
      setShippingLevel(shippingLevel);

      setLastRange(rangeObject);
      setDiscountPercent("");
      setQuantityLevel("");
    }
  };
  const handleBlurQuantity = (event) => {
    if (levels.length > 0) {
      if (
        parseInt(lastRange["quantity_level"], 10) <
        parseInt(event.target.value, 10)
      ) {
      } else {
        event.target.value = "";

        setQuantityLevel("");
        setLevelsErrorMessage(
          `Please  enter quantity value greater than ${lastRange["quantity_level"]} `
        );
        setTimeout(() => {
          setLevelsErrorMessage("");
        }, 3000);
      }
    }
  };

  const handleBlurDiscount = (event) => {
    var rx = new RegExp(/^\d+(\.\d{1,2})?$/);
    if (rx.test(event.target.value)) {
      if (levels.length > 0) {
        if (
          parseFloat(lastRange["discount_percent"]) <
          parseFloat(event.target.value)
        ) {
        } else {
          event.target.value = "";
          setDiscountPercent("");
          setLevelsErrorMessage(
            `Please  enter discount value greater than ${lastRange["discount_percent"]} `
          );
          setTimeout(() => {
            setLevelsErrorMessage("");
          }, 3000);
        }
      }
    } else {
      event.target.value = "";
      setDiscountPercent("");
      setLevelsErrorMessage(
        ` discount must have 2 digits after decimal or less`
      );
      setTimeout(() => {
        setLevelsErrorMessage("");
      }, 3000);
    }
  };

  /* This Function handle quantity range open delete modal*/
  const handleDeleteRange = (e, rowIndex) => {
    setDeleteRangeModal(true);
    setDeleteRangeId(rowIndex);
  };

  const actionFormatter = (cell, row, rowIndex) => (
    <div>
      <span
        className="inline-block pointer-cursor"
        onClick={(e) => handleDeleteRange(e, rowIndex)}
      >
        <i
          className="fa fa-trash"
          style={{
            width: 35,
            fontSize: 20,
            paddingLeft: 11,

            color: "#e4566e",
          }}
        ></i>
      </span>
    </div>
  );

  const quantityFormatter = (cell, row, rowIndex) => (
    <div>
      <span>
        {rowIndex === 0
          ? 1 + " - " + cell
          : parseInt(levels[rowIndex - 1].quantity_level) + 1 + " - " + cell}
      </span>
    </div>
  );

  // columns for bootstrap table
  const columns = [
    {
      dataField: "quantity_level",
      text: "Quantity",

      headerAlign: "right",
      align: "right",
      formatter: quantityFormatter,
    },
    {
      dataField: "discount_percent",
      text: "Discount  $",

      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => {
        return (
          <div className="category_name no-next-line">
            <span> {cell && "$ " + cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "Action",
      text: "Action",
      headerAlign: "center",
      align: "center",
      formatter: actionFormatter,
    },
  ];
  /* This Function handle checkbox  */
  const handleCheckbox = (e, setFieldValue) => {
    const newcheckedvalue = JSON.parse(e.target.value);
    setcheckedValue(!newcheckedvalue);
    setFieldValue("buy_group_shipping_allow", JSON.stringify(!newcheckedvalue));
  };

  /* this function handle shipping cost */
  const handleShippingCost = (evt, index) => {
    const { value } = evt.target;
    const newItems = shippingLevel.map((item, shippingIndex) => {
      if (shippingIndex !== index) {
        return { ...item };
      }
      return { quantity_level: item.quantity_level, shipping_price: value };
    });
    setShippingLevel(newItems);
  };

  /* This function will remove quantity of respective indexId */
  const removeQuantity = (rowIndex) => {
    levels.length = rowIndex;
    setLevels(levels);
    shippingLevel.length = rowIndex;
    setShippingLevel(shippingLevel);
    setDeleteRangeModal(false);
    if (rowIndex > 0) {
      setLastRange(levels[rowIndex - 1]);
    }
    if (levels.length > 0) {
      setDiscountPercent("");
    } else {
      setDiscountPercent("0");
    }
  };
  /** This Function will handle group start date
  @param selectedStartDate start date
 */
  const handleGroupStartDate = (selectedStartDate, setFieldValue) => {
    if (selectedStartDate !== null) {
      let groupMaxDate = new Date(
        selectedStartDate.getFullYear(),
        selectedStartDate.getMonth(),
        selectedStartDate.getDate() + 6
      );
      setgroupStarteDate(selectedStartDate);
      setgroupEndDate(groupMaxDate);
      setdisableEndDate(false);
    } else {
      setdisableEndDate(true);
      setgroupStarteDate(null);
      setgroupEndDate(null);
    }
    setFieldValue("start_date", selectedStartDate);
    setFieldValue("end_date", null);
  };

  /**
   * This Function will handle group end date
   * @param SelectedEndDate end date
   */
  const handleGroupEndDate = (SelectedEndDate, setFieldValue) => {
    setSameEndDate(true);
    // if(SelectedEndDate.getDate()===groupStartDate.getDate()){
    //       console.log("same date")
    //       setSameEndDate(false)
    // }

    if (SelectedEndDate !== null && groupEndDate !== null) {
      if (SelectedEndDate.getDate() === groupEndDate.getDate()) {
        sethideTimeChange(false);
        if (
          moment(SelectedEndDate).format("HH:mm") >
          moment(groupStartDate).format("HH:mm")
        ) {
          SelectedEndDate.setHours(0, 0, 0, 0);
        }
      } else {
        sethideTimeChange(true);
      }
    }
    setFieldValue("end_date", SelectedEndDate);
  };

  /**
   * This Function will handle filter time
   * @param time current time
   */
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    // console.log(
    //   "filterPassedTime",
    //   currentDate.getTime(),
    //   selectedDate.getTime()
    // );

    return currentDate.getTime() < selectedDate.getTime();
  };

  //current Date
  const currentDate = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate());

  return (
    <>
      <Breadcrumbs
        title="Add BuyGroup"
        parent="BuyGroup"
        subtitle="BuyGroup Admin Panel"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Add BuyGroup</h5>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      let newValues = values;
                      // console.log("newValues", newValues);
                      if (Array.isArray(newValues.zip_code)) {
                        let zipcodeArray = newValues?.zip_code?.map(
                          (a) => a.value
                        );
                        const ZipResult = zipcodeArray.toString();
                        newValues.zip_code = ZipResult;
                      }

                      // console.log("myaval", newValues);
                      if (levels.length === 0 || shippingLevel.length === 0) {
                        setLevelsErrorMessage(
                          "Please Enter range and discount"
                        );
                        setTimeout(() => {
                          setLevelsErrorMessage("");
                        }, 3000);
                      } else {
                        newValues.levels = levels;
                        if (checkedValue === false) {
                          newValues.shipping_level = shippingLevel;
                        }
                        newValues = trimObj(newValues);
                        newValues.start_date = values.start_date;
                        newValues.end_date = values.end_date;
                        createGroupFunction(newValues);
                      }
                    }}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      setFieldValue,
                    }) => (
                      <form
                        className="needs-validation add-product-form"
                        onSubmit={handleSubmit}
                      >
                        {console.log("formvalue", values, errors)}
                        {errorMessage !== undefined && errorMessage !== "" ? (
                          <p className="alert alert-danger">{errorMessage}</p>
                        ) : (
                          ""
                        )}

                        <Row className="product-adding">
                          <Col xl="12">
                            <div className="form form-label-center">
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Group Name
                                </Label>
                                <div className="col-xl-9 col-sm-9">
                                  <Input
                                    className="form-control"
                                    name={`offer_name`}
                                    type="text"
                                    placeholder="Group Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage name={`offer_name`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Select Product
                                </Label>
                                <div className="col-xl-9 col-sm-9">
                                  <Select
                                    name="product_id"
                                    className="zIndexIncrease suppliers_dropdown"
                                    value={selectedProduct}
                                    onChange={(selectedOption) => {
                                      handleChangeProduct(
                                        selectedOption,
                                        setFieldValue
                                      );
                                    }}
                                    options={productOptions}
                                    placeholder="Select Product"
                                  />

                                  <ErrorMessage name="product_id">
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Minimum Quantity
                                </Label>
                                <div className="col-xl-9 col-sm-9">
                                  <Input
                                    className="form-control"
                                    name={`minimum_quantity`}
                                    type="text"
                                    placeholder="minimum Quantity"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage name={`minimum_quantity`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Price
                                </Label>
                                <div className="col-xl-9 col-sm-9">
                                  <Input
                                    className="form-control"
                                    name={`price`}
                                    type="text"
                                    placeholder="Price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage name={`price`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Zip Codes
                                </Label>
                                <div className="col-xl-9 col-sm-9">
                                  <CreatableSelect
                                    inputValue={zipinputValue}
                                    name="zip_code"
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onInputChange={(newValue) => {
                                      getZipValue(newValue, setFieldValue);
                                    }}
                                    onChange={(newValue) =>
                                      setZipCodeValue(newValue)
                                    }
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type zip code and press enter..."
                                    value={zipCodeValue}
                                  />
                                  <ErrorMessage name={`zip_code`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  Start Date
                                </Label>
                                <div className="col-xl-9 col-sm-9 full_width_date">
                                  <DatePicker
                                    selected={
                                      values.start_date
                                        ? new Date(values.start_date)
                                        : null
                                    }
                                    onChange={(selectedDate) =>
                                      handleGroupStartDate(
                                        selectedDate,
                                        setFieldValue
                                      )
                                    }
                                    selectsStart
                                    startDate={values.start_date}
                                    endDate={values.end_date}
                                    // showTimeInput
                                    showTimeSelect
                                    timeInputLabel="Time:"
                                    placeholderText="Start Date"
                                    dateFormat="MM/dd/yyyy  h:mm aa"
                                    className="form-control"
                                    name="end_date"
                                    timeIntervals={15}
                                    minDate={tomorrow}
                                    filterTime={filterPassedTime}
                                    // showTimeSelect={true}
                                  />
                                  <ErrorMessage name={`start_date`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-3 mb-0">
                                  End Date
                                </Label>
                                <div className="col-xl-9 col-sm-9 full_width_date">
                                  <DatePicker
                                    selected={
                                      values.end_date
                                        ? new Date(values.end_date)
                                        : null
                                    }
                                    // onChange={(val) => {
                                    //   setFieldValue("end_date", val);
                                    // }}
                                    onChange={(selecteDate) =>
                                      handleGroupEndDate(
                                        selecteDate,
                                        setFieldValue
                                      )
                                    }
                                    selectsEnd
                                    disabled={disableEndDate}
                                    startDate={values.start_date}
                                    endDate={values.end_date}
                                    minDate={groupStartDate}
                                    maxDate={groupEndDate}
                                    //  showTimeInput={hideTimeChange}
                                    showTimeSelect
                                    timeIntervals={15}
                                    minTime={setHours(
                                      setMinutes(setSeconds(new Date(), 0), 0),
                                      0
                                    )}
                                    // minTime={groupStartDate}
                                    maxTime={
                                      hideTimeChange
                                        ? setHours(
                                            setMinutes(
                                              setSeconds(new Date(), 59),
                                              59
                                            ),
                                            23
                                          )
                                        : groupStartDate
                                    }
                                    timeInputLabel="Time:"
                                    placeholderText="End Date"
                                    dateFormat="MM/dd/yyyy  h:mm aa"
                                    className="form-control"
                                    name="end_date"
                                  />
                                  <ErrorMessage name="end_date">
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-2 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Add Quantity Range
                                </Label>
                                <Col md="3">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder=" Range"
                                    name="Quantity"
                                    value={quantityLevel}
                                    onChange={handleChangeQuantity}
                                    onBlur={handleBlurQuantity}
                                  />
                                </Col>

                                <Col md="3">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="discount_percent"
                                    placeholder=" Discount in $"
                                    value={discountPercent}
                                    onBlur={handleBlurDiscount}
                                    onChange={handleChangeDiscount}
                                    disabled={levels.length > 0 ? false : true}
                                  />
                                  <ErrorMessage name={`discount_percent`}>
                                    {(msg) => (
                                      <div className="input-feedback">
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </Col>
                                <FormGroup className="form-group  ml-2 row">
                                  <Button
                                    type="button"
                                    disabled={
                                      discountPercent !== "" &&
                                      quantityLevel !== ""
                                        ? false
                                        : true
                                    }
                                    onClick={addQuantityrange}
                                  >
                                    Add Quantity+
                                  </Button>
                                </FormGroup>
                              </FormGroup>
                              {discountErrorMessage && (
                                <div className="offset-xl-3 offset-sm-4">
                                  <label className="err-msg">
                                    {discountErrorMessage &&
                                      discountErrorMessage}
                                  </label>
                                </div>
                              )}
                              {levelsErrorMessage && (
                                <div className="offset-xl-3 offset-sm-4">
                                  <label className="err-msg">
                                    {levelsErrorMessage && levelsErrorMessage}
                                  </label>
                                </div>
                              )}
                              <FormGroup className="form-group mb-3 row">
                                <div className="offset-xl-3 offset-sm-4 col-md-6">
                                  {levels.length > 0 && (
                                    <BootstrapTable
                                      keyField="id"
                                      key={`levelsTable`}
                                      striped
                                      hover
                                      condensed
                                      bordered={true}
                                      data={levels}
                                      columns={columns}
                                      rowClasses="row-hover"
                                    />
                                  )}
                                </div>
                                {checkedValue == false && levels.length > 0 && (
                                  <div>
                                    <Label
                                      style={{
                                        marginTop: "0px",
                                        padding: "13px 0px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Add Shipping Range ($)
                                    </Label>
                                    <table>
                                      {shippingLevel.map(
                                        (shippinglevel, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <Input
                                                  className="form-control"
                                                  style={{
                                                    maxWidth: "100px",
                                                  }}
                                                  value={
                                                    shippinglevel.quantity_level
                                                  }
                                                  disabled
                                                  type="hidden"
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  type="text"
                                                  style={{
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    maxWidth: "100px",
                                                  }}
                                                  value={
                                                    shippinglevel.shipping_price
                                                  }
                                                  className="form-control"
                                                  onChange={(evt) =>
                                                    handleShippingCost(
                                                      evt,
                                                      index
                                                    )
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </table>
                                  </div>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <Input
                                  type="checkbox"
                                  name="buy_group_shipping_allow"
                                  value={checkedValue}
                                  checked={checkedValue}
                                  onChange={(e) =>
                                    handleCheckbox(e, setFieldValue)
                                  }
                                />{" "}
                                <label style={{ marginTop: "0" }}>
                                  Want to use shippo service?
                                </label>
                              </FormGroup>
                              {deleteRangeModal && (
                                <DeleteQuantity
                                  deleteRangeModal={deleteRangeModal}
                                  removeQuantity={removeQuantity}
                                  deleteRangeId={deleteRangeId}
                                  closedDeleteModal={() =>
                                    setDeleteRangeModal(false)
                                  }
                                />
                              )}
                            </div>

                            <div className="offset-xl-3 offset-sm-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isDisable}
                              >
                                {isDisable ? "Please Wait" : "Add"}
                              </Button>
                              <Link to="/app/groups">
                                <Button type="button" color="light">
                                  Cancel
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddGroup;
/* Copyright Information: Created by BuyGroup */
