/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import moment from "moment";
import {
  groupList,
  assignSuppplierToGroup,
  groupSupplierList,
} from "../services/GroupServices";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./Group.css";
import dateFormat from "dateformat";
toast.configure();
export default class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGroupList: [],
      groupList: [],
      statusList: [
        { label: "Active", value: "Active" },
        { label: "Up Coming", value: "Upcoming" },
        { label: "Closed", value: "Closed" },
      ],
      initialValues: {
        offer_name: "",
        minimum_quantity: "",
        start_date: null,
        end_date: null,
        product_id: "",
        levels: [{ quantity: "", discount_percent: "" }],
        price: "",
        shipping_cost: "",
      },
      showAddScreen: false,
      productList: [],
      quantityList: [
        { label: "1-100", value: "1" },
        { label: "101-500", value: "101" },
        { label: "501-1000", value: "501" },
        { label: "1000-5000", value: "1000" },
        { label: "5000+", value: "5000" },
      ],
      status: null,
      isDisable: false,
      suppliersOptions: [],
      selectedSupplier: null,
      supplierId: "",
      showConfirmAlert: false,
      isDisableButton: false,
      groupId: "",
      isLoadingAssignSupplier: false,
      dateRangeValue: [this.getstartDate(), new Date()],
      range_start: this.getstartDate(),
      range_end: new Date(),
      filterLoading: false,
    };
  }

  getstartDate = () => {
    let date = new Date();
    console.log("date", date);
    let newDay = date.setDate(date.getDate() - 30);
    return date;
  };
  componentDidMount() {
    this.getGroupList();
  }

  // this function call api to get groups list
  getGroupList = () => {
    this.setState({ isLoading: true });
    const range_start = this.state.range_start
      ? moment(this.state.range_start).format("YYYY-MM-DD")
      : this.state.range_start;
    const range_end = this.state.range_end
      ? moment(this.state.range_end).format("YYYY-MM-DD")
      : this.state.range_end;
    groupList(range_start, range_end)
      .then((response) => {
        this.setState(
          {
            allGroupList: response.data,
            groupList: response.data,
            isLoading: false,
            filterLoading: true,
          },
          () => {
            this.handleChangeStatus(this.state.status);
          }
        );
      })
      .catch((error) => {
        this.setState({ isLoading: false, filterLoading: true });
      });
  };
  //formatter for group name
  nameFormatter = (cell, row) => (
    <Link to={{ pathname: "groups/view/" + row.id }}>
      <span>{cell}</span>
    </Link>
  );
  // this formatter is used for action
  actionFormatter = (cell, row) => {
    return (
      <Row className="group-action">
        <Link onClick={() => this.getAllSuppliers(row.id)}>
          <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
        </Link>
      </Row>
    );
  };

  // this funciton used in status change dropdown takes argument as {label:'',value:''}
  handleChangeStatus = (selectedOption) => {
    if (selectedOption) {
      let filterdData = this.state.allGroupList.filter(
        (item) => item.status === selectedOption.value
      );
      this.setState({ groupList: filterdData, status: selectedOption });
    } else {
      this.setState({
        groupList: this.state.allGroupList,
        status: null,
      });
    }
  };

  //formatter for date
  dateFormatter = (cell, row) => <Moment format="MM/DD/YYYY">{cell}</Moment>;

  /*
   * Function getAllSuppliers get all the data for Suppliers
   */
  getAllSuppliers = (groupId) => {
    this.setState({ isLoadingAssignSupplier: true, showConfirmAlert: true });
    groupSupplierList(groupId)
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (supplier) {
            return {
              value: supplier.supplier_id,
              label: supplier.company_name,
            };
          });

          this.setState({
            suppliersOptions: options,
            isDisableButton: false,
            selectedSupplier: null,
            groupId: groupId,
            isLoadingAssignSupplier: false,
          });
        }
      })
      .catch((error) => {
        console.log("error supplier list in group", error);
      });
  };
  // on change handler for supplier
  handleSuppliersChange = (selectedSupplier) => {
    this.setState({
      selectedSupplier: selectedSupplier,
      supplierId: selectedSupplier.value,
    });
  };

  // close modal of assign supplier
  onCloseSupplier = () => {
    this.setState({
      showConfirmAlert: false,
      selectedSupplier: null,
      supplierId: "",
      isDisableButton: false,
      isLoadingAssignSupplier: false,
    });
  };
  // assign supplier to  group api call
  assignSupplierGroup = () => {
    let groupData = {
      buy_group_id: this.state.groupId,
      supplier_id: this.state.supplierId,
    };
    if (this.state.supplierId === "") {
      this.setState({ supplierErrorMessage: "Please select supplier" });
      setTimeout(() => {
        this.setState({ supplierErrorMessage: "" });
      }, 3000);
    } else {
      this.setState({ isDisableButton: true });

      assignSuppplierToGroup(groupData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
            this.getGroupList();

            this.setState({
              showConfirmAlert: false,
              selectedSupplier: null,
              supplierId: "",
              isDisableButton: false,
              isLoadingAssignSupplier: false,
            });
          }
        })
        .catch((error) => {
          if (error !== undefined) {
            this.setState({
              supplierErrorMessage: error.data.message,
              isDisableButton: false,
              isLoadingAssignSupplier: false,
            });
            setTimeout(() => {
              this.setState({ supplierErrorMessage: "" });
            }, 3000);
          }
        });
    }
  };

  onDateRangeChange = (dates) => {
    console.log("dates", dates);
    this.setState(
      {
        dateRangeValue: dates,
        range_start: dates ? dates[0] : dates,
        range_end: dates ? dates[1] : dates,
      },
      () => {
        this.getGroupList();
      }
    );
  };
  render() {
    const { ExportCSVButton } = CSVExport;
    const { groupList } = this.state;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "group_id",
        text: "Group ID",
        formatter: this.nameFormatter,
      },
      {
        dataField: "offer_name",
        text: "Group Name",
        sort: true,
      },
      {
        dataField: "start_date",
        text: " Start",
        sort: true,
        csvFormatter: (cell, row, rowIndex) =>
          dateFormat(cell, "mm/dd/yyyy  h:MM TT"),
        formatter: this.dateFormatter,
      },
      {
        dataField: "end_date",
        text: "End",
        sort: true,
        csvFormatter: (cell, row, rowIndex) =>
          dateFormat(cell, "mm/dd/yyyy  h:MM TT"),
        formatter: this.dateFormatter,
      },

      {
        dataField: "number_of_participants",
        text: "Participants",
        headerAlign: "right",
        align: "right",
        // sort: true,
      },
      {
        dataField: "price",
        text: " Price",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => {
          return (
            <div className="category_name no-next-line">
              <span> {cell && "$ " + cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "final_price",
        text: "Final Price",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => {
          return (
            <div className="category_name no-next-line">
              <span> {cell && "$ " + cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "quantity_booked",
        text: "Qty Booked",
        headerAlign: "right",
        align: "right",
        // sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        headerAlign: "center",
        align: "center",
        formatter: (cell, row) => {
          return (
            <div className={`status_column ${cell}`}>
              <span>{cell}</span>
            </div>
          );
        },
        // sort:
      },
      {
        dataField: "company_name",
        text: "Supplier Name",
        formatter: (cell, row) => {
          return (
            <div className="category_name">
              <span>{cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "zipcode",
        text: "Zip Codes",
        classes: "zipcode",
      },
      {
        dataField: "Assign Supplier",
        text: "Assign Supplier",
        headerAlign: "center",
        align: "center",
        csvExport: false,
        formatter: this.actionFormatter,
        // sort: true,
      },
    ];

    return (
      <>
        <Breadcrumbs title="Groups" parent="" subtitle="Groups" />
        <div className="container-fluid">
          <Row className="row">
            <Col md="12">
              <React.Fragment>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Groups</h5>
                          <Link to={{ pathname: "groups/add-group" }}>
                            <Button
                              type="button"
                              color="secondary"
                              data-toggle="modal"
                              data-original-title="Create Group"
                            >
                              <i className="fa fa-plus mr-2"></i>
                              Create Group
                            </Button>
                          </Link>
                        </div>
                      </div>
                      <CardBody>
                        <ToolkitProvider
                          keyField="ProductListTable"
                          data={groupList}
                          columns={columns}
                          search
                          exportCSV={{
                            fileName: "group.csv",
                          }}
                        >
                          {(props) => (
                            <div>
                              {this.state.filterLoading && (
                                <Row className="table_SearchBar select_dropdown">
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={this.state.status}
                                      onChange={this.handleChangeStatus}
                                      options={this.state.statusList}
                                      placeholder="Filter By Status"
                                      isClearable={true}
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <DateRangePicker
                                      format="MM-dd-y"
                                      onChange={this.onDateRangeChange}
                                      value={this.state.dateRangeValue}
                                      rangeDivider="to"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <SearchBar
                                      {...props.searchProps}
                                      className="custome-search-table"
                                      placeholder="Search"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col d-flex justify-content-end"
                                  >
                                    <ExportCSVButton
                                      className="btn btn-primary"
                                      {...props.csvProps}
                                    >
                                      <i className="fa fa-download mr-2"></i>{" "}
                                      CSV
                                    </ExportCSVButton>
                                  </Col>
                                </Row>
                              )}

                              {this.state.isLoading ? (
                                <div className="custom-loader">
                                  <Spinner animation="border" />
                                </div>
                              ) : (
                                <>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={"No groups available"}
                                    bordered={false}
                                    pagination={paginationFactory({
                                      sizePerPage: 10,
                                      showTotal: true,
                                      hideSizePerPage: true,
                                    })}
                                    rowClasses="row-hover"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </ToolkitProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Modal
                  isOpen={this.state.showConfirmAlert}
                  style={{ overlay: { opacity: 0.1 } }}
                >
                  <ModalHeader toggle={this.onCloseSupplier}>
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                      Assign Supplier
                    </h5>
                  </ModalHeader>
                  <ModalBody>
                    {this.state.isLoadingAssignSupplier ? (
                      <div className="custom-loader">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <div>
                        {this.state.suppliersOptions.length > 0 ? (
                          <FormGroup>
                            <Label className="col-form-label">
                              Select Supplier
                            </Label>
                            <div>
                              <Select
                                name="supplier_id"
                                className="zIndexIncrease"
                                classNamePrefix="children"
                                value={this.state.selectedSupplier}
                                onChange={this.handleSuppliersChange}
                                options={this.state.suppliersOptions}
                                placeholder="Select Supplier"
                              />

                              <label className="err-msg"></label>
                            </div>
                          </FormGroup>
                        ) : (
                          <p className="no_data_message">
                            {" "}
                            No supplier Available
                          </p>
                        )}
                      </div>
                    )}
                    {this.state.supplierErrorMessage !== undefined &&
                    this.state.supplierErrorMessage !== "" ? (
                      <p className="alert alert-danger">
                        {this.state.supplierErrorMessage}
                      </p>
                    ) : (
                      ""
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={this.assignSupplierGroup}
                      disabled={this.state.isDisableButton ? true : false}
                    >
                      {this.state.isDisableButton ? "Please Wait..." : "Assign"}
                    </Button>
                    <Button
                      type="button"
                      color="light"
                      onClick={this.onCloseSupplier}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */
