/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Tooltip, Spinner } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { categoryList } from "../services/ProductServices";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";
import {
  addNewCategory,
  deleteCategory,
  editCategory,
  deleteSubCategory,
} from "../services/CategoryServices";
import EditCategory from "./EditCategory";
import EditSubCategory from "./EditSubCategory";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import DeleteCategory from "./DeleteCategory";
import DeleteSubCategory from "./DeleteSubCategory";
const Category = () => {
  const categoryInitialValues = {
    category_name: "",
  };

  const subCategoryInitialValues = {
    parent_category_id: "",
    parent_category_name: "",
    category_name: "",
  };

  /* This const "catogoryData" store data of all catogory */

  const [categorysData, setCategorysData] = useState([]);

  /* This const "addCategoryModal" responsible for add CategoryModal open or closed  */

  const [addCategoryModal, setAddCategoryModal] = useState(false);

  /* This const "editCategoryModal" responsible for edit CategoryModal open or closed  */

  const [editCategoryModal, setEditCategoryModal] = useState(false);

  /* This const "addSubCategoryModal" responsible for add subCategoryModal open or closed  */

  const [addSubCategoryModal, setAddSubCategoryModal] = useState(false);

  /* This const "editSubCategoryModal" responsible for  edit SubCategoryModal open or closed  */

  const [editSubCategoryModal, setEditSubCategoryModal] = useState(false);

  /* This const "categoryInitialValuesData" store InitialValuesData for addCategory modal  */

  const [categoryInitialValuesData, setCategoryInitialValuesData] = useState(
    categoryInitialValues
  );

  /* This const "categoryInitialValuesData" store InitialValuesData for editCategory modal  */

  const [
    editCategoryInitialValuesData,
    setEditCategoryInitialValuesData,
  ] = useState({});

  /* This const "subCategoryInitialValuesData" store InitialValuesData for addsubCategory modal  */

  const [subCategoryInitialValuesData, setSubCategoryInitialValues] = useState(
    subCategoryInitialValues
  );

  /* This const "editSubCategoryInitialValuesData" store InitialValuesData for editsubCategory modal  */

  const [
    editSubCategoryInitialValuesData,
    setEditSubCategoryInitialValuesData,
  ] = useState({});

  /* "isloading" constant is responsible for showing loader in list category */

  const [isLoading, setIsLoading] = useState(false);

  /* "isLoadingButton" constant is disable button when data is feaching from api */

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /* This const "deleteCategoryModal" responsible for delete CategoryModal open or closed  */

  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);

  /* This const "deleteCategoryId" store id of category that we want delete */

  const [deleteCategoryId, setDeleteCategoryId] = useState();

  /* This const "deleteSubCategoryModal" responsible for delete SubCategoryModal open or closed  */

  const [deleteSubCategoryModal, setDeleteSubCategoryModal] = useState(false);

  /* This const "deleteSubCategoryId" store id of subCategory that we want delete */

  const [deleteSubCategoryId, setDeleteSubCategoryId] = useState();

  const columns = [
    {
      dataField: "category_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "no_of_products",
      text: "No of Products",
      // sort: true,
    },
    {
      dataField: "Action",
      text: "",
      formatter: (cell, categoryRow) => (
        <div>
          <span
            className="inline-block pointer-cursor"
            onClick={() => openEditCategoryModal(categoryRow)}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
          <span
            className="inline-block pointer-cursor"
            onClick={(e) => openDeleteCategoryModal(categoryRow.category_id)}
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                paddingLeft: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
          <span
            className="inline-block pointer-cursor"
            onClick={() => openSubCategoryModal(categoryRow)}
            data-tip="Add Sub Category"
          >
            <i
              className="fa fa-plus"
              style={{
                width: 35,
                fontSize: 20,
                paddingLeft: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>

          <ReactTooltip place="bottom" type="dark" effect="solid" />
        </div>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  /*fetch categoryList data from categoryListApi */

  const getCategoryList = () => {
    setIsLoading(true);
    categoryList()
      .then((response) => {
        if (response.status === 200) {
          setCategorysData(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  /**
   *  This  function  Add New Category in Table
   * @param  {newCategoryData} newCategoryData data Object
   */

  const addNewCategoryHandler = (newCategoryData) => {
    setIsLoadingButton(true);

    addNewCategory(newCategoryData)
      .then((response) => {
        if (response.status === 200) {
          getCategoryList();
          setAddCategoryModal(false);
          setIsLoadingButton(false);
          toast.success(response.data.message, {
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This  function open Edit Category Modal
   * @param  {CategoryRow} CategoryRow data Object
   */
  const openEditCategoryModal = (categoryRow) => {
    setEditCategoryInitialValuesData({
      category_id: categoryRow.category_id,
      category_name: categoryRow.category_name,
    });
    setEditCategoryModal(true);
  };

  /**
   * This function Update the values of category row
   * @param  {updateCategoryValues} updateCategoryValues data Object
   */
  const updateCategoryHandler = (updateCategoryValues) => {
    setIsLoadingButton(true);
    const objUpdateCategoryValues = {
      category_name: updateCategoryValues.category_name,
    };

    editCategory(objUpdateCategoryValues, updateCategoryValues.category_id)
      .then((response) => {
        if (response.status === 200) {
          getCategoryList();
          setEditCategoryModal(false);
          setIsLoadingButton(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This  function open sub Category Modal
   * @param  {subCategoryRow} subCategoryRow data Object
   */
  const openSubCategoryModal = (CategoryRow) => {
    setSubCategoryInitialValues({
      parent_category_id: CategoryRow.category_id,
      parent_category_name: CategoryRow.category_name,
      category_name: "",
    });
    setAddSubCategoryModal(true);
  };
  /* This  function  Add New subCategory in Categary  */

  const addNewSubCategoryhandler = (newSubCategoryData) => {
    setIsLoadingButton(true);
    const objNewSubCategoryData = JSON.stringify({
      parent_category_id: newSubCategoryData.parent_category_id,
      category_name: newSubCategoryData.category_name,
    });

    addNewCategory(objNewSubCategoryData)
      .then((response) => {
        if (response.status === 200) {
          getCategoryList();
          setAddSubCategoryModal(false);
          setIsLoadingButton(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This  function open Edit subCategory Modal
   * @param  {subCategoryRow} subCategoryRow data Object
   */
  const openEditSubCategoryModal = (subCategoryRow, index) => {
    setEditSubCategoryInitialValuesData({
      category_id: subCategoryRow.sub_category[index].category_id,
      parent_category_name: subCategoryRow.category_name,
      category_name: subCategoryRow.sub_category[index].category_name,
    });

    setEditSubCategoryModal(true);
  };
  /**
   * This  function updated subcategory Row
   * @param  {updateSubCategoryValues} updateSubCategoryValues data Object
   */
  const updateSubCategoryHandler = (updateSubCategoryValues) => {
    setIsLoadingButton(true);
    const objupdateSubCategoryValues = {
      category_name: updateSubCategoryValues.category_name,
    };
    editCategory(
      objupdateSubCategoryValues,
      updateSubCategoryValues.category_id
    )
      .then((response) => {
        if (response.status === 200) {
          getCategoryList();
          setEditSubCategoryModal(false);
          setIsLoadingButton(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /* This function open Delete category modal */
  const openDeleteCategoryModal = (supplierRow_Id) => {
    setDeleteCategoryModal(true);
    setDeleteCategoryId(supplierRow_Id);
  };

  /**
   * This  function delete category  Row based on rowId
   * @param  {rowId} rowId data Object
   */

  const removeCategory = (rowId) => {
    setIsLoadingButton(true);
    deleteCategory(rowId)
      .then((response) => {
        if (response.status === 200) {
          setIsLoadingButton(false);
          getCategoryList();
          setDeleteCategoryModal(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This  function  delete subCategory  Row based on rowId
   * @param  {rowId} rowId data Object
   */
  const removeSubCategory = (rowId) => {
    setIsLoadingButton(true);
    deleteCategory(rowId)
      .then((response) => {
        if (response.status === 200) {
          setIsLoadingButton(false);
          getCategoryList();
          setDeleteSubCategoryModal(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  const openDeleteSubCategoryModal = (supplierRow_Id) => {
    setDeleteSubCategoryModal(true);
    setDeleteSubCategoryId(supplierRow_Id);
  };

  /*show subcategory table*/
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (categoryRow) => (
      <div className="innerSubCategory">
        <table className="table">
          {categoryRow.sub_category.length > 0 ? (
            categoryRow.sub_category.map((item, index) => {
              return (
                <tr key={item.category_id}>
                  <td>{item.category_name}</td>
                  <td>{item.no_of_products}</td>
                  <td align="right">
                    <span
                      className="inline-block pointer-cursor"
                      onClick={() =>
                        openEditSubCategoryModal(categoryRow, index)
                      }
                    >
                      <i
                        className="fa fa-pencil"
                        style={{
                          width: 35,
                          fontSize: 20,
                          padding: 11,
                          color: "#e4566e",
                        }}
                      ></i>
                    </span>
                    <span
                      className="inline-block pointer-cursor"
                      onClick={(e) =>
                        openDeleteSubCategoryModal(
                          categoryRow.sub_category[index].category_id
                        )
                      }
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          width: 35,
                          fontSize: 20,
                          paddingLeft: 11,

                          color: "#e4566e",
                        }}
                      ></i>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <td>No subCategory available</td>
          )}
        </table>
      </div>
    ),
  };

  const { SearchBar } = Search;

  return (
    <>
      <Breadcrumbs
        title="Categories"
        parent=""
        subtitle="Category Admin Panel"
      />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Categories</h5>

                        <Button
                          type="button"
                          color="secondary"
                          data-toggle="modal"
                          data-original-title="Add Product"
                          onClick={() => setAddCategoryModal(true)}
                        >
                          <i className="fa fa-plus mr-2"></i>
                          Add category
                        </Button>
                      </div>
                    </div>
                    <CardBody>
                      {addCategoryModal && (
                        <AddCategory
                          addCategoryModal={addCategoryModal}
                          initialValues={categoryInitialValuesData}
                          addNewCategoryHandler={addNewCategoryHandler}
                          isLoadingButton={isLoadingButton}
                          closeAddCategoryModal={() =>
                            setAddCategoryModal(false)
                          }
                        />
                      )}
                      {editCategoryModal && (
                        <EditCategory
                          editCategoryModal={editCategoryModal}
                          initialValues={editCategoryInitialValuesData}
                          updateCategoryHandler={updateCategoryHandler}
                          isLoadingButton={isLoadingButton}
                          closeEditCategoryModal={() =>
                            setEditCategoryModal(false)
                          }
                        />
                      )}
                      {deleteCategoryModal && (
                        <DeleteCategory
                          deleteCategoryModal={deleteCategoryModal}
                          deleteCategoryId={deleteCategoryId}
                          removeCategory={removeCategory}
                          closedCategoryModal={() =>
                            setDeleteCategoryModal(false)
                          }
                          isLoadingButton={isLoadingButton}
                        />
                      )}

                      {addSubCategoryModal && (
                        <AddSubCategory
                          addSubCategoryModal={addSubCategoryModal}
                          initialValues={subCategoryInitialValuesData}
                          addNewSubCategoryhandler={addNewSubCategoryhandler}
                          isLoadingButton={isLoadingButton}
                          closeAddSubCategoryModal={() =>
                            setAddSubCategoryModal(false)
                          }
                        />
                      )}

                      {editSubCategoryModal && (
                        <EditSubCategory
                          editSubCategoryModal={editSubCategoryModal}
                          initialValues={editSubCategoryInitialValuesData}
                          updateSubCategoryHandler={updateSubCategoryHandler}
                          isLoadingButton={isLoadingButton}
                          closeEditSubCategoryModal={() =>
                            setEditSubCategoryModal(false)
                          }
                        />
                      )}
                      {deleteSubCategoryModal && (
                        <DeleteSubCategory
                          deleteSubCategoryModal={deleteSubCategoryModal}
                          removeSubCategory={removeSubCategory}
                          deleteSubCategoryId={deleteSubCategoryId}
                          closedSubCategory={() =>
                            setDeleteSubCategoryModal(false)
                          }
                          isLoadingButton={isLoadingButton}
                        />
                      )}

                      {isLoading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <ToolkitProvider
                          keyField="category_id"
                          data={categorysData}
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="table_SearchBar d-flex align-items-center justify-content-end mb-3 flex-wrap">
                                <div className="product_search">
                                  <SearchBar
                                    {...props.searchProps}
                                    className="custome-search-table"
                                    placeholder="Search Category"
                                  />
                                </div>
                              </div>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={"No category found"}
                                hover
                                condensed
                                bordered={false}
                                expandRow={expandRow}
                                pagination={paginationFactory({
                                  sizePerPage: 10,
                                  showTotal: true,
                                  hideSizePerPage: true,
                                })}
                                rowClasses="row-hover pointer-cursor"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Category;
/* Copyright Information: Created by BuyGroup */
