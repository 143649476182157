/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DashboardList: [],
    };
  }
  render() {
    return (
      <>
        <Breadcrumbs
          title="Dashboard"
          parent=""
          subtitle="Dashboard Admin Panel"
        />
        <div className="container-fluid">
          <Row className="row">
            <Col md="12">
              <React.Fragment>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <h5>Dashboard</h5>
                      </div>
                      <CardBody></CardBody>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */