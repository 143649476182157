/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { fetchSupplierCustomerDetail } from "../services/SupplierServices";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../components/common/breadcrumb";
function SupplierCustomerDetail(props) {
  
  /* const "userDate" store user Data */
  const [userDate, setuserDate] = useState([]);

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    getCustomer();
  }, []);

  /* give Customer detail of respective id */
  const getCustomer = () => {
    setisLoading(true);
    fetchSupplierCustomerDetail(props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          setuserDate(response.data);
          setisLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * This function formate Phone Number in US formate
   */
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [ "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <Breadcrumbs title="Members" parent="" subtitle="Members" />
      <Row className="row">
        <Col md="12">
          <React.Fragment>
            <Row>
              <Col lg="12" md="12">
                <Card className="card-box">
                  <div className="card-header">
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col md="6">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Member Details</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {isLoading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <CardBody>
                      <Row className="mb-5">
                        <Col md="9">
                          <h5>
                            <strong>{userDate[0].customer_name}</strong>
                          </h5>

                          <Row className="mt-1">
                            <Col md="12">
                              <FontAwesomeIcon
                                icon={faPhoneAlt}
                                className="mt-1 mr-2"
                              />{" "}
                              <label>
                                {formatPhoneNumber(userDate[0].phone_number)}
                              </label>
                            </Col>
                          </Row>

                          <Row className="mt-1">
                            <Col md="12">
                              <FontAwesomeIcon
                                icon={faMapMarker}
                                className="mt-1 mr-2"
                              />{" "}
                              <label>
                                {userDate[0].street +
                                  ", " +
                                  userDate[0].city +
                                  ", " +
                                  userDate[0].state +
                                  ", " +
                                  userDate[0].country +
                                  ", " +
                                  userDate[0].zip_code}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              <strong>Quantity placed :</strong>
                              {"  "}
                              <label>{userDate[0].quantity}</label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              <strong>Price :</strong>
                              {"  "}
                              <label>${userDate[0].rate}</label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              <strong>Shipping :</strong>
                              {"  "}
                              <label>
                                {" "}
                                ${parseFloat(userDate[0].shipping).toFixed(2)}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              <strong>Tax :</strong>{" "}
                              <label style={{ paddingLeft: "6px" }}>
                                ${parseFloat(userDate[0].tax_value).toFixed(2)}
                              </label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              <strong>Grand Amount :</strong>{" "}
                              {/* <label style={{ paddingLeft: "6px" }}>
                                ${parseFloat(userDate[0].total).toFixed(2)}
                              </label> */}
                               $
                                    {parseFloat(
                                      userDate[0].rate * userDate[0].quantity +
                                      parseFloat(userDate[0].tax_value) +
                                      parseFloat(userDate[0].shipping)
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                    {console.log("code",userDate[0].rate * userDate[0].quantity +
                                      parseInt(userDate[0].tax_value) +
                                        parseInt(userDate[0].shipping))}
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <div className="mt-2">
                        <span>
                          <h5>{/* <strong>Orders List</strong> */}</h5>
                        </span>
                      </div>
                      <div></div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Col>
      </Row>
    </div>
  );
}

export default SupplierCustomerDetail;
/* Copyright Information: Created by BuyGroup */
