/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const getOrderList = (data) => {
  return Api.post("orders", data).then((res) => res.data);
};

export const orderStatus = (data) => {
  return Api.put("order-status/update", data).then((res) => res.data);
};

export const supplierOrderList = () => {
  return Api.get("orderBySupplierId").then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
