/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import SunEditor from "suneditor-react";
import list from "suneditor/src/plugins/submenu/list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import trimObj from "../components/common/TrimData";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import AddSupplier from "../Suppliers/AddSupplier";
import {
  createProduct,
  supplierList,
  categoryList,
  getSubCategoryDetail,
} from "../services/ProductServices";
import { addNewSupplier } from "../services/SupplierServices";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
toast.configure();
const AddProduct = (props) => {
  const [suppliers, setSuppliers] = useState([]); // state variable in suppliers
  const [uploadButton, setUploadButton] = useState(false); // reset On change handler
  const [files, setFiles] = useState([]); // state variable in files
  const [filesData, setFilesData] = useState([]); // state variable in copy of files Data
  const [isLoding, setIsLoding] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [editorDescription, setEditorDescription] = useState("");
  const [addSupplier, setAddSupplier] = useState(false);
  const [addInitialValuesData, setAddInitialValuesData] = useState({
    name: "",
    email: "",
    phone: "",
    primary_contact_name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    is_taxable: false,
    tax_rate: null,
  });

  const [documentFile, setDocumentFile] = useState([]); // state variable in document File
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [subCategoryEnabled, setSubCategoryEnabled] = useState(false);
  const [categoryOption, setcategoryOption] = useState([]);

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    // setUploadButton(true);

    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      let valid = false;
      for (var j = 0; j < getFiles.length; j++) {
        var sCurExtension = getFiles[j];

        const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

        if (
          checkFileTyype == ".jpeg" ||
          checkFileTyype == ".jpg" ||
          checkFileTyype == ".png"
        ) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            /* Once all promises are resolved, update state with image URI array */

            const mergeFiles = files.concat(images);
            setFiles(mergeFiles);
            setFilesData(mergeFiles);
            setFieldValue("images", mergeFiles);
            // setUploadButton(false);
            e.target.value = "";
          },
          (error) => {
            console.error(error);
          }
        );
      }
      return false;
    } else {
      console.log("Hi Else");
    }
  };

  // Upload pdf file than convert to base 64 and store the data in the state
  const readPDF = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);
      let valid = false;
      var sCurExtension = getFiles[0];

      const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

      if (checkFileTyype == ".pdf") {
        valid = true;
      } else {
        valid = false;
      }

      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  name: file.name,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (pdf) => {
            /* Once all promises are resolved, update state with PDF URI array */

            setDocumentFile(pdf);
            setFieldValue("upload_doc", pdf);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  // Delete Images after uploading
  const handleDeleteImage = (e, index, setFieldValue) => {
    const copyFiles = [...files];
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
    setFieldValue("images", copyFiles);
  };

  // Add product
  const addProduct = async (data) => {
    setIsLoding(true);
    createProduct(data)
      .then((res) => {
        if (res.status === 200) {
          // return;
          const getLenght = res.data.imageUploadUrl.length;
          res.data.imageUploadUrl.map((url, index) => {
            const getData = filesData[index];

            const contentType = getData.mime_type;
            const b64Data = getData.path;
            const replaceDataImage = b64Data.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            const blob = b64toBlob(replaceDataImage, contentType);
            console.log("blobdata", blob);
            fetch(url.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                if (getLenght === index + 1) {
                  // last one Last index getting and apply logic here
                  setIsLoding(false);
                  props.history.push(
                    `/app/products/view-product/${res.data.product_id}`
                  );
                  toast.success("Product Added Successfully", {
                    autoClose: 3000,
                    hideProgressBar: true,
                  });
                }
              })
              .catch(function (error) {
                console.log("error", error);
              });
          });
          res.data.documentUploadUrl.map((url, index) => {
            const getData = documentFile[index];

            const contentType = getData.mime_type;
            const b64Data = getData.path;
            const replaceDataImage = b64Data.replace(
              /^data:application\/[a-z]+;base64,/,
              ""
            );

            const blob = b64toBlob(replaceDataImage, contentType);

            fetch(url.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                console.log("PDF upload ok done");
              })
              .catch(function (error) {
                console.log("error", error);
              });
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.data !== undefined) {
          setIsLoding(false);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  };

  // Convert base 64 to blob data
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  /*
   * Function handleCategoryChange get the value for selectedCategory
   */
  const handleCategoryChange = (selectedCategory, setFieldValue) => {
    setSelectDisabled(true);
    setFieldValue("sub_category", "");
    setSelectedSubCategory("");
    setSelectedCategory(selectedCategory);
    getSubCategoryDetail(selectedCategory.value)
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.category };
          });
          setSubCategories(options);
          setSelectDisabled(false);

          options.length > 0
            ? setSubCategoryEnabled(true)
            : setSubCategoryEnabled(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setSelectDisabled(false);
      });

    setFieldValue("category", selectedCategory.value);
  };

  /*
   * Function handleSubCategoryChange get the value for selectedSubCategory
   */

  const handleSubCategoryChange = (selectedSubCategory, setFieldValue) => {
    setSelectedSubCategory(selectedSubCategory);
    setFieldValue("sub_category", selectedSubCategory.value);
  };

  /*
   * Function handleSuppliersChange get the value for selectedSupplier
   */

  const handleSuppliersChange = (selectedSupplier, setFieldValue) => {
    setSelectedSupplier(selectedSupplier);
    setFieldValue("suppliers", selectedSupplier);
  };

  const onEditorDescriptionChange = (content, setFieldValue) => {
    setEditorDescription(content);
  };

  /**
   * This function Add New Suppliers
   * @param  {newSupplierData} newSupplierData data Object
   */

  const addNewSupplierhandler = (newSupplierData) => {
    setIsLoadingButton(true);
    addNewSupplier(newSupplierData)
      .then((response) => {
        if (response.status === 200) {
          getAllSuppliers();
          setAddSupplier(false);
          setIsLoadingButton(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /*
   * Function getAllSuppliers get all the data for Suppliers
   */
  const getAllSuppliers = () => {
    // return;
    setIsLoadingButton(true);
    supplierList()
      .then((res) => {
        if (res.status === 200) {
          // this.setState({
          //   suppliers: res.data,
          // });
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.company_name };
          });
          setSuppliers(options);
          // setSelectedSupplier(options[0]);
          toast.success(res.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setIsLoadingButton(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoadingButton(false);
      });
  };

  const getAllCategory = () => {
    categoryList()
      .then((res) => {
        if (res.status === 200) {
          // this.setState({
          //   categories: res.data,
          // });
          let options = res.data.map(function (cat) {
            return { value: cat.category_id, label: cat.category_name };
          });
          setcategoryOption(options);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllSuppliers();
    getAllCategory();
  }, []);

  return (
    <>
      <Breadcrumbs
        title="Add Products"
        parent="Product"
        subtitle="Product Admin Panel"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Add Product</h5>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={props.location.state.initialValues}
                  onSubmit={(values, actions) => {
                    let newValues = values;
                    values.product_name = values.product_name.trim();
                    values.specification = editorDescription;
                    const imagesArray = values.images.map(function (x) {
                      return {
                        mime_type: x.mime_type,
                      };
                    });
                    values.images = imagesArray;
                    const uploadDocumentArray = values.upload_doc.map(function (
                      x
                    ) {
                      return {
                        mime_type: x.mime_type,
                      };
                    });
                    values.upload_doc = uploadDocumentArray;

                    values = trimObj(values);
                    values.expiration_date = newValues.expiration_date;
                    console.log("values", values);
                    //return;
                    addProduct(values, actions);
                  }}
                  validationSchema={Yup.object().shape({
                    images: Yup.mixed()
                      .required("A file is required")
                      .test("fileFormat", "Image is required", (value) => {
                        return (
                          value &&
                          ["image/png", "image/jpg", "image/jpeg"].includes(
                            value[0]?.mime_type
                          )
                        );
                      }),
                    product_name: Yup.string().required(
                      "Please enter product name"
                    ),
                    weight: Yup.number().typeError("Must be a number"),
                    unit: Yup.string().required("Please enter unit"),
                    manufacturer: Yup.string().required(
                      "Please enter manufacturer"
                    ),
                    description: Yup.string().required(
                      "Please enter description"
                    ),
                    category: Yup.string().required("Please select category"),
                    suppliers: Yup.array().min(1, "Please select suppliers"),
                    price: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter price")
                      .test(
                        "maxDigitsAfterDecimal",
                        "Price must have 2 digits after decimal or less",
                        (number) => /^\d+(\.\d{1,2})?$/.test(number)
                      ),
                    lot_size: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter lot size"),
                    expiration_date: Yup.date()
                      .required("Please select Expiration Date")
                      .nullable(),
                    minimum_quantity: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter minimum quantity"),
                    maximum_quantity: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter maximum quantity"),
                    is_taxable: Yup.boolean(),
                    tax_rate: Yup.number()
                      .when('is_taxable', {
                        is: true,
                        then: Yup.number().required("Please enter tax rate").typeError("Please enter tax rate"),
                        otherwise: Yup.number(),
                      }),
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                  }) => (
                    <>
                      {console.log("Values handle Change", values)}
                      <form
                        className="add-product-form"
                        onSubmit={handleSubmit}
                      >
                        <Row className="product-adding">
                          <Col xl="5">
                            <div className="form-group">
                              {!uploadButton && (
                                <>
                                  <input
                                    className="show-for-sr"
                                    type="file"
                                    name="images[]"
                                    multiple
                                    accept="image/png, image/jpg, image/jpeg"
                                    onChange={(e) => readURI(e, setFieldValue)}
                                    id="file"
                                  />
                                  <label htmlFor="file">
                                    <i className="fa fa-plus"></i> Upload Photo
                                  </label>
                                </>
                              )}
                              <label className="err-msg">
                                {errors.images &&
                                  touched.images &&
                                  errors.images}
                              </label>
                              <ul className="photo-container">
                                {files.map((imageURI, index) => {
                                  return (
                                    <li>
                                      <img
                                        className="photo-uploaded"
                                        src={imageURI.path}
                                        alt="Photo uploaded"
                                      />
                                      <span
                                        onClick={(e) =>
                                          handleDeleteImage(
                                            e,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Col>
                          <Col xl="7">
                            <div className="form form-label-center">
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Product Name
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="product_name"
                                    type="text"
                                    placeholder="Enter Product Name"
                                    value={values.product_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.product_name &&
                                      touched.product_name &&
                                      errors.product_name}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  UPC
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="upc"
                                    type="text"
                                    placeholder="Code Number"
                                    value={values.upc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.upc && touched.upc && errors.upc}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Details Per Unit
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="unit"
                                    type="text"
                                    placeholder="Enter Unit"
                                    value={values.unit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.unit && touched.unit && errors.unit}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Weight
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="weight"
                                    type="text"
                                    placeholder="Weight in Pound"
                                    value={values.weight}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.weight &&
                                      touched.weight &&
                                      errors.weight}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Manufacturer
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="manufacturer"
                                    type="text"
                                    placeholder="Manufacturer Name"
                                    value={values.manufacturer}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.manufacturer &&
                                      touched.manufacturer &&
                                      errors.manufacturer}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Description
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    placeholder="Enter Description here"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.description &&
                                      touched.description &&
                                      errors.description}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Select Category
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Select
                                    name="category"
                                    className="zIndexIncrease "
                                    classNamePrefix="children"
                                    value={selectedCategory}
                                    // onChange={handleChange}
                                    onChange={(selectedOption) => {
                                      handleCategoryChange(
                                        selectedOption,
                                        setFieldValue
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    options={categoryOption}
                                    placeholder="Select Category"
                                  />
                                  <label className="err-msg">
                                    {errors.category &&
                                      touched.category &&
                                      errors.category}
                                  </label>
                                </div>
                              </FormGroup>
                              {subCategoryEnabled ? (
                                <FormGroup className="form-group mb-3 row">
                                  <Label className="col-xl-3 col-sm-4 mb-0">
                                    Select Sub Category
                                  </Label>
                                  <div className="col-xl-9 col-sm-8">
                                    {selectDisabled ? (
                                      <div className="custom-loader select_loader">
                                        <Spinner animation="border" />
                                      </div>
                                    ) : null}
                                    <Select
                                      name="sub_category"
                                      className={`zIndexIncrease ${selectDisabled
                                        ? "disabled_true"
                                        : "disabled_false"
                                        }`}
                                      classNamePrefix="children"
                                      value={selectedSubCategory}
                                      onChange={(selectedOption) => {
                                        handleSubCategoryChange(
                                          selectedOption,
                                          setFieldValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      options={subCategories}
                                      placeholder="Select Sub Category"
                                    />
                                  </div>
                                </FormGroup>
                              ) : null}

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Select Supplier
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Select
                                    name="suppliers"
                                    className="zIndexIncrease suppliers_dropdown"
                                    classNamePrefix="children"
                                    value={selectedSupplier}
                                    onChange={(selectedOption) => {
                                      handleSuppliersChange(
                                        selectedOption,
                                        setFieldValue
                                      );
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onBlur={handleBlur}
                                    options={suppliers}
                                    placeholder="Select Supplier"
                                  />
                                  <span
                                    className="addSupplier"
                                    onClick={() => setAddSupplier(true)}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </span>
                                  <label className="err-msg">
                                    {errors.suppliers &&
                                      touched.suppliers &&
                                      errors.suppliers}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Product Specification
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <div className="clearfix cu_suneditor">
                                    <SunEditor
                                      defaultValue={editorDescription}
                                      name="specification"
                                      placeholder="Please type here..."
                                      height="150px"
                                      setOptions={{
                                        plugins: [list],
                                        height: 150,
                                        buttonList: [
                                          ["bold", "underline", "italic"],
                                          // "/", // Line break
                                          ["align", "list", "lineHeight"],
                                          ["formatBlock"],
                                        ],
                                      }}
                                      onChange={(data) =>
                                        onEditorDescriptionChange(
                                          data,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Price
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="price"
                                    type="text"
                                    placeholder="Enter Price"
                                    value={values.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.price &&
                                      touched.price &&
                                      errors.price}
                                  </label>
                                </div>
                              </FormGroup>

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Is Taxable
                                </Label>
                                <div className="col-xl-9 col-sm-8 " >

                                  <div className="display-flex">
                                    <div className="checkbox-size">
                                      <Input
                                        className="align-checkbox"
                                        name="is_taxable"
                                        type="checkbox"
                                        // placeholder="Is Taxable"
                                        value={values.is_taxable}
                                        onChange={handleChange}
                                      // onBlur={handleBlur}
                                      />
                                    </div>

                                    {
                                      values.is_taxable &&
                                      <div className="width-100">
                                        <Input
                                          className="testing"
                                          name="tax_rate"
                                          type="number"
                                          placeholder="Tax Rate"
                                          value={values.tax_rate}
                                          onChange={handleChange}
                                        // onBlur={handleBlur}
                                        />
                                      </div>
                                    }
                                  </div>
                                  <div>

                                    {(errors.tax_rate &&
                                      touched.tax_rate &&
                                      errors.tax_rate) &&
                                      (
                                        <label className="err-msg">
                                          {errors.tax_rate}
                                        </label>
                                      )
                                    }
                                  </div>

                                </div>

                              </FormGroup>

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Lot Size
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="lot_size"
                                    type="text"
                                    placeholder="Enter Lot Size"
                                    value={values.lot_size}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.lot_size &&
                                      touched.lot_size &&
                                      errors.lot_size}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Expiration Date
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <DatePicker
                                    selected={
                                      values.expiration_date
                                        ? new Date(values.expiration_date)
                                        : null
                                    }
                                    onChange={(selectedDate) => {
                                      console.log(selectedDate);
                                      setFieldValue(
                                        "expiration_date",
                                        selectedDate
                                      );
                                    }}
                                    showTimeSelect
                                    minDate={new Date()}
                                    timeInputLabel="Time:"
                                    placeholderText="Expiration Date"
                                    dateFormat="MM/dd/yyyy  h:mm aa"
                                    className="form-control"
                                    name="expiration_date"
                                    timeIntervals={15}
                                  />

                                  <label className="err-msg">
                                    {errors.expiration_date &&
                                      touched.expiration_date &&
                                      errors.expiration_date}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Minimum Quantity
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="minimum_quantity"
                                    type="text"
                                    placeholder="Enter Minimum Quantity"
                                    value={values.minimum_quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.minimum_quantity &&
                                      touched.minimum_quantity &&
                                      errors.minimum_quantity}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Maximum Quantity
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="maximum_quantity"
                                    type="text"
                                    placeholder="Enter Maximum Quantity"
                                    value={values.maximum_quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.maximum_quantity &&
                                      touched.maximum_quantity &&
                                      errors.maximum_quantity}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Other Restrictions
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <textarea
                                    className="form-control"
                                    name="other_restrictions"
                                    placeholder="Enter Other Restrictions here"
                                    value={values.other_restrictions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Document
                                </Label>
                                <div className="col-xl-5 col-sm-5">
                                  <div className="form-group">
                                    <input
                                      className="show-for-sr"
                                      type="file"
                                      id="upload_doc"
                                      name="upload_doc[]"
                                      accept="application/pdf"
                                      onChange={(e) =>
                                        readPDF(e, setFieldValue)
                                      }
                                    />
                                    <label htmlFor="upload_doc">
                                      <i className="fa fa-plus"></i> Upload
                                      Document
                                    </label>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-sm-4">
                                  <div className="clearfix  show_pdf_name">
                                    {documentFile.map((pdfURI, index) => {
                                      return (
                                        <p
                                          className="truncate"
                                          title={pdfURI.name}
                                        >
                                          {pdfURI.name}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className="offset-xl-3 offset-sm-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isLoding}
                              >
                                {isLoding ? "Please Wait..." : "Add"}
                              </Button>
                              <Link to="/app/products">
                                <Button type="button" color="light">
                                  Cancel
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {addSupplier && (
        <AddSupplier
          addSupplierModal={addSupplier}
          addNewSupplierhandler={addNewSupplierhandler}
          closeAddSupplierModal={() => setAddSupplier(false)}
          initialValues={addInitialValuesData}
          isLoadingButton={isLoadingButton}
        />
      )}
    </>
  );
};

export default AddProduct;
/* Copyright Information: Created by BuyGroup */
