/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { editProfile } from "../services/GeneralServices";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input/input";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
toast.configure();

function Profile(props) {
  const profileInpputRef = useRef();
  /* const "Profile" store User information */
  const [profile, setProfile] = useState({});

  /* const "files" will store User Photo */
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  /* show modal of profile upload for cropper  */
  const [showProfileUploadModal, setShowProfileUploadModal] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [profileImageType, setProfileImageType] = useState(null);

  /* const "phoneValue"  store phone number of user */
  const [phoneValue, setPhoneValue] = useState();

  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        getFiles.map((file, index) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve({
                path: ev.target.result,
                mime_type: file.type,
              });
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */

          //   const mergeFiles = files.concat(images);

          setFiles(images);
          setFieldValue("profile_image", images);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onProfileImageChange = (e) => {
    console.log("checkFile", e);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      console.log("reader", files, reader);
      setProfileImageType(files[0].type);
      setProfileImage(reader.result);
      setShowProfileUploadModal(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const closedModal = () => {
    console.log("profileInpputRef", profileInpputRef);
    profileInpputRef.current.value = "";
    setShowProfileUploadModal(false);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setFiles(cropper.getCroppedCanvas().toDataURL());
      closedModal();
    }
  };

  // Convert base 64 to blob data
  // const b64toBlob = async (b64Data, contentType = "") => {
  //   console.log("b64Data", b64Data);
  //   console.log("contentType", contentType);
  //   const base64Response = await fetch(`data:${contentType};base64,${b64Data}`);
  //   const blob = await base64Response.blob();
  //   console.log(blob);
  //   return blob;
  // };
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    console.log("blob", blob);

    return blob;
  };
  /* this function will update profile picture of user */

  const updateProfile = (data) => {
    // return;
    setIsLoading(true);
    editProfile(data)
      .then((res) => {
        if (res.status === 200) {
          //   return;
          const getLenght = res.data.uploadURL.length;
          if (getLenght == 0) {
            toast.success("User Update Successfully", {
              autoClose: 3000,
              hideProgressBar: true,
            });
            let user = JSON.parse(localStorage.user);
            console("user sumit", user);
            user.name = data.f_name;

            user.phone_number = data.p_number;
            localStorage.setItem("user", JSON.stringify(user));
            props.history.push("/app/dashboard");
            window.location.reload();
          } else {
            const getData = files;
            console.log("getData", getData);
            const contentType = profileImageType;
            const b64Data = getData.path;
            const replaceDataImage = files.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            const blob = b64toBlob(replaceDataImage, contentType);
            console.log("replaceDataImage", blob);

            fetch(res.data.uploadURL, {
              method: "PUT",
              body: blob,
            })
              .then(function () {
                // last one Last index getting and apply logic here
                // props.history.push("/app/dashboard");
                toast.success("Profile updated successfully", {
                  autoClose: 3000,
                  hideProgressBar: true,
                });
                let user = JSON.parse(localStorage.user);
                user.name = data.f_name;

                user.phone_number = data.p_number;
                user.profile_image = res.data.imageURL;
                localStorage.setItem("user", JSON.stringify(user));
                props.history.push("/app/dashboard");
                // window.location.reload();
              })
              .catch(function (error) {
                console.log("error", error);
                setIsLoading(false);
              });
          }
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          toast.error(error.data.message.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setIsLoading(false);
        }
      });
  };

  /* here we are getting user data from localStorage and set on profile object */
  useEffect(() => {
    const getUserDetail = localStorage.getItem("user");
    const parseObject = JSON.parse(getUserDetail);

    const prapreInitialValues = {
      f_name: parseObject.name,
      email: parseObject.email,
      p_number: parseObject.phone_number,
      profile_image: parseObject.profile_image,
    };
    setProfile(prapreInitialValues);
    setPhoneValue(parseObject.phone_number);
  }, []);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  /* this  function handle phone  number of user */
  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("p_number", selectphone);
  };

  return (
    <>
      <Breadcrumbs title="Profile" parent="" subtitle="Dashboard Admin Panel" />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <h5>Profile</h5>
                    </div>
                    <CardBody>
                      <Formik
                        enableReinitialize
                        initialValues={profile}
                        onSubmit={(values, actions) => {
                          const prepareProfileImage = [
                            {
                              mime_type: profileImageType,
                            },
                          ];
                          values.profile_image = prepareProfileImage;
                          updateProfile(values, actions);
                        }}
                        validationSchema={Yup.object().shape({
                          f_name: Yup.string().required("Please enter name"),
                          p_number: Yup.string().required(
                            "Please enter phone number"
                          ),
                        })}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          resetForm,
                          setFieldValue,
                        }) => (
                          <form
                            className="add-product-form"
                            onSubmit={handleSubmit}
                          >
                            <Row className="product-adding">
                              <Col xl="12">
                                <div className="form form-label-center">
                                  <FormGroup className="form-group mb-3 row">
                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                      Name
                                    </Label>
                                    <div className="col-xl-9 col-sm-8">
                                      <Input
                                        className="form-control"
                                        name="f_name"
                                        type="text"
                                        placeholder="Enter Your Name"
                                        value={values.f_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <label className="err-msg">
                                        {errors.f_name &&
                                          touched.f_name &&
                                          errors.f_name}
                                      </label>
                                    </div>
                                  </FormGroup>
                                  <FormGroup className="form-group mb-3 row">
                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                      Email
                                    </Label>
                                    <div className="col-xl-9 col-sm-8">
                                      <Input
                                        className="form-control"
                                        name="email"
                                        type="text"
                                        placeholder="Enter Email Address"
                                        value={values.email}
                                        readOnly
                                        disabled
                                      />
                                      <label className="err-msg">
                                        {errors.email &&
                                          touched.email &&
                                          errors.email}
                                      </label>
                                    </div>
                                  </FormGroup>

                                  <FormGroup className="form-group mb-3 row">
                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                      Phone Number
                                    </Label>
                                    <div className="col-xl-9 col-sm-8">
                                      <PhoneInput
                                        country="US"
                                        value={phoneValue}
                                        name="p_number"
                                        placeholder="Enter Your Number"
                                        onBlur={handleBlur}
                                        maxLength={14}
                                        className="form-control"
                                        onChange={(value) =>
                                          handlePhoneNumber(
                                            value,
                                            setFieldValue
                                          )
                                        }
                                      />
                                      <ErrorMessage name="p_number">
                                        {(errorMsg) => (
                                          <label className="err-msg">
                                            {errorMsg}
                                          </label>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                  </FormGroup>
                                  <FormGroup className="form-group mb-3 row">
                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                      Photo
                                    </Label>
                                    <div className="col-xl-3 col-sm-4">
                                      <div className="form-group">
                                        <input
                                          className="show-for-sr"
                                          type="file"
                                          // id="upload_imgs"
                                          name="profile_image[]"
                                          accept="image/png, image/jpg, image/jpeg"
                                          ref={profileInpputRef}
                                          // onChange={(e) =>
                                          //   readURI(e, setFieldValue)
                                          // }
                                          onChange={(e) => {
                                            onProfileImageChange(e);
                                          }}
                                          id="file"
                                        />
                                        <label htmlFor="file">
                                          Upload Photo
                                          {/* <i className="fa fa-plus"></i> */}
                                        </label>
                                        {cropData ? (
                                          <img
                                            style={{ width: "100%" }}
                                            src={cropData}
                                            alt="cropped"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {Array.isArray(
                                          profile.profile_image
                                        ) ? (
                                          ""
                                        ) : (
                                          <ul className="photo-container uploaded">
                                            <li>
                                              <img
                                                className="photo-uploaded"
                                                src={profile.profile_image}
                                                alt="Photo uploaded"
                                              />
                                              <label>Uploaded photo</label>
                                            </li>
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className="offset-xl-3 offset-sm-4">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                  >
                                    {!isLoading ? "Update" : "Please Wait..."}
                                  </Button>
                                  <Link to="/app/dashboard">
                                    <Button type="button" color="light">
                                      Cancel
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
      <div>
        <Modal
          isOpen={showProfileUploadModal}
          style={{ overlay: { opacity: 0.1 } }}
        >
          <ModalHeader toggle={closedModal}>
            <h5 className="modal-title f-w-600" id="ProfileFileupload">
              File Upload
            </h5>
          </ModalHeader>
          <ModalBody>
            <Cropper
              src={profileImage}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" onClick={getCropData}>
              Crop
            </Button>
            <Button type="button" color="light" onClick={closedModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Profile;
/* Copyright Information: Created by BuyGroup */
