/* Copyright Information: Created by BuyGroup */
import Api from "../Api";
export const customerList = () => {
  return Api.get("customer").then((res) => res.data);
};
export const addCustomer = (customerData) => {
  return Api.post("customer", customerData).then((res) => res.data);
};
export const deleteCustomer = (id) => {
  return Api.delete("customer/" + id).then((res) => res.data);
};
export const customerDetail = (id) => {
  return Api.get("customer/" + id).then((res) => res.data);
};
export const updateCustomer = (customerData) => {
  return Api.put("customer", customerData).then((res) => res.data);
};
export const verifyDoc = (verifyData) => {
  return Api.post("verifydocument", verifyData).then((res) => res.data);
};

/* Copyright Information: Created by BuyGroup */
