/* Copyright Information: Created by BuyGroup */
import Api from "../Api";
export const groupList = (start, end) => {
  return Api.get(`group/?range_start=${start}&range_end=${end}`).then(
    (res) => res.data
  );
};
export const groupDetail = (id) => {
  return Api.get(`group/${id}`).then((res) => res.data);
};
export const createGroup = (groupData) => {
  return Api.post("group", groupData).then((res) => res.data);
};
export const editGroup = (groupData) => {
  return Api.put("group", groupData).then((res) => res.data);
};
export const deleteGroup = (id) => {
  return Api.delete("group/" + id).then((res) => res.data);
};
export const assignSuppplierToGroup = (groupData) => {
  return Api.post("supplier/assign", groupData).then((res) => res.data);
};
export const supplierGroupList = (id) => {
  return Api.get("supplier/group-list/" + id).then((res) => res.data);
};
export const groupSupplierList = (groupId) => {
  return Api.get("supplier/product-list/" + groupId).then((res) => res.data);
};
export const groupOrderList = (groupId) => {
  return Api.get("group/buygroup-orders/" + groupId).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
