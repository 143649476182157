/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Col, Container, Row } from "reactstrap";

const AdminFooter = () => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <div>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">
                Copyright {n} © BuyGroup All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default AdminFooter;
/* Copyright Information: Created by BuyGroup */
