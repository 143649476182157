/* Copyright Information: Created by BuyGroup */
import React from "react";
import email from "../../assets/images/UserVerification/email.png";
import confetti1 from "../../assets/images/UserVerification/confetti1.png";
import logo from "../../assets/images/dashboard/logo.png";
const Verified = () => {
  return (
    <>
      <div className="email-container">
        <img
          alt=""
          src={logo}
          className="Img-fluid"
          style={{
            display: "block",
            maxWidth: "300px",
            margin: "auto",
            marginBottom: "20px",
          }}
        />
        <div className="main-content">
          <div align="center">
            <div className="" style={{ position: "relative", height: "140px" }}>
              <img
                className="animate"
                style={{ display: "block", fontSize: "0px", border: "0px" }}
                src={email}
                alt="logo"
                width="100"
              />
            </div>
            <div className="img-effect"></div>
          </div>
          <div align="center">
            <h1>
              Thank You! <img src={confetti1} width="35" />
            </h1>
            <h4>Your email has been verified!!</h4>
          </div>
        </div>
      </div>
      <div className="bottom-text">
        You can close this window and head back to application!
      </div>
    </>
  );
};

export default Verified;
/* Copyright Information: Created by BuyGroup */
