/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  supplierList,
  categoryList,
  productList,
  deleteProduct,
  getSubCategoryDetail,
} from "../services/ProductServices";
import ReactTooltip from "react-tooltip";
toast.configure();
export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      suppliervalue: null,
      categories: [],
      subCategory: [],
      categoryvalue: null,
      subcategoryvalue: null,
      productList: [],
      supplier_id: null,
      category_id: null,
      sub_category_id: null,
      isLoading: false,
      loadingFilter: false,
    };
  }

  componentDidMount() {
    this.getAllSuppliers();
    this.getAllCategory();
    this.getProductList();
  }
  truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };
  /*
   * Function getProductList get all the data for Products
   */
  getProductList = () => {
    this.setState({
      isLoading: true,
    });
    const data = {
      supplier_id: this.state.supplier_id,
      category_id: this.state.category_id,
      sub_category_id: this.state.sub_category_id,
    };
    productList(data)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            productList: res.data,
            isLoading: false,
            loadingFilter: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          loadingFilter: true,
        });
      });
  };

  /*
   * Function getAllSuppliers get all the data for Suppliers
   */
  getAllSuppliers = () => {
    // return;
    supplierList()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.company_name };
          });
          this.setState({
            suppliers: options,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /*
   * Function getAllCategory get all the data for categories
   */

  getAllCategory = () => {
    categoryList()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.category_id, label: cat.category_name };
          });
          this.setState(
            {
              categories: options,
            },
            () => this.getSubCategory()
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getSubCategory = () => {
    getSubCategoryDetail(this.state.category_id)
      .then((res) => {
        if (res?.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.category };
          });
          this.setState({
            subCategory: options,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /*
   * Function handleChangeSuppliers filter Product data
   */

  handleChangeSuppliers = (selectedOption) => {
    if (selectedOption !== null) {
      this.setState(
        {
          suppliervalue: selectedOption,
          supplier_id: selectedOption.value,
        },
        () => {
          this.getProductList();
        }
      );
    } else {
      this.setState(
        {
          suppliervalue: null,
          supplier_id: null,
        },
        () => {
          this.getProductList();
        }
      );
    }
  };

  /*
   * Function handleChangeCategory filter Product data
   */
  handleChangeCategory = (selectedOption) => {
    // this.setState({ categoryvalue: selectedOption });
    if (selectedOption !== null) {
      this.setState(
        {
          categoryvalue: selectedOption,
          category_id: selectedOption.value,
        },
        () => {
          this.getSubCategory();
          this.getProductList();
        }
      );
    } else {
      this.setState(
        {
          categoryvalue: null,
          category_id: null,
          subCategory: [],
          subcategoryvalue: null,
          sub_category_id: null,
        },
        () => {
          this.getProductList();
        }
      );
    }
  };

  /*
   * Function handleChangeSubCategory filter Product data
   */
  handleChangeSubCategory = (selectedOption) => {
    // this.setState({ categoryvalue: selectedOption });
    if (selectedOption !== null) {
      this.setState(
        {
          subcategoryvalue: selectedOption,
          sub_category_id: selectedOption.value,
        },
        () => {
          this.getProductList();
        }
      );
    } else {
      this.setState(
        {
          subcategoryvalue: null,
          sub_category_id: null,
        },
        () => {
          this.getProductList();
        }
      );
    }
  };

  render() {
    const {
      productList,
      suppliers,
      categories,
      isLoading,
      categoryvalue,
      suppliervalue,
      subCategory,
      subcategoryvalue,
    } = this.state;
    const { ExportCSVButton } = CSVExport;
    const columns = [
      {
        dataField: "imageUrl",
        text: "Image",
        headerClasses: "header-classes-product-image",
        csvExport: false,
        formatter: (cell, row) => (
          <div className="product_thumb">
            <img alt="Not Found" className="img-responsive" src={cell} />
          </div>
        ),
      },
      {
        dataField: "product_name",
        text: "Product Name",
        headerClasses: "header-classes-product-name",
        classes: "column-classes-product-name",
        sort: true,
        formatter: (cell, row) => (
          <>
            <div>
              <span className="inline-block pointer-cursor">
                <Link
                  to={{
                    pathname: "products/view-product/" + row.product_id,
                    productDetails: row,
                  }}
                >
                  {row.product_name}
                </Link>
              </span>
            </div>
          </>
        ),
      },
      {
        dataField: "description",
        text: "Description",
        headerClasses: "header-classes-description",
        classes: "column-classes-description",
        formatter: (cell, row) => {
          return (
            <div>
              <span>
                {this.truncateString(cell, 40)}{" "}
                <i class="fa fa-info-circle" data-tip={cell}></i>
              </span>
              <ReactTooltip
                place="bottom"
                type="info"
                effect="solid"
                multiline={true}
                className="tool-tip-width"
              />
            </div>
          );
        },
      },
      {
        dataField: "category",
        text: "Category",
        sort: true,
        formatter: (cell, row) => {
          return (
            <div className="category_name">
              <span>{cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "suppliers",
        text: "Supplier Name",
        headerClasses: "header-classes-suppliers",
        classes: "column-classes-suppliers",
        csvFormatter: (cell, row) => {
          let prapreData = [];
          for (var i = 0; i < cell.length; i++) {
            var value = cell[i].label;
            prapreData.push(value);
          }
          const convertToString = prapreData.toString();
          return convertToString;
        },
        formatter: (cell, row) => {
          const suppliersList = row.suppliers.map((value, index) => (
            <>
              <span>{value.label}</span>
              {index < row.suppliers.length - 1 ? ", " : ""}
            </>
          ));
          return suppliersList;
        },
      },
      {
        dataField: "selling_price",
        text: "Price",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => {
          return (
            <>
              <span>$&nbsp;</span>
              <span>{cell}</span>
            </>
          );
        },
      },
    ];
    const getUserInfo = JSON.parse(localStorage.getItem("user"));

    return (
      <>
        <Breadcrumbs
          title="Products"
          parent=""
          subtitle="Product Admin Panel"
        />
        <div className="container-fluid">
          <Row className="row">
            <Col md="12">
              <React.Fragment>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Products</h5>
                          {getUserInfo.role !== "ADMIN" ? null : (
                            <Link
                              to={{
                                pathname: "products/add-product",
                                state: {
                                  categories: categories,
                                  suppliers: suppliers,
                                  initialValues: {
                                    images: [],
                                    product_name: "",
                                    upc: "",
                                    unit: "",
                                    weight: "",
                                    manufacturer: "",
                                    description: "",
                                    category: "",
                                    sub_category: "",
                                    suppliers: [],
                                    specification: "",
                                    price: "",
                                    upload_doc: [],
                                    expiration_date: null,
                                    lot_size: "",
                                    minimum_quantity: "",
                                    maximum_quantity: "",
                                    other_restrictions: "",
                                  },
                                },
                              }}
                            >
                              <Button
                                type="button"
                                color="secondary"
                                data-toggle="modal"
                                data-original-title="Add Product"
                              >
                                <i className="fa fa-plus mr-2"></i>
                                Add Product
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                      <CardBody>
                        <ToolkitProvider
                          keyField="productListTable"
                          data={productList}
                          columns={columns}
                          search
                          exportCSV={{
                            fileName: "product.csv",
                          }}
                        >
                          {(props) => (
                            <>
                              {this.state.loadingFilter && (
                                <Row className="filter-container select_dropdown">
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={categoryvalue}
                                      onChange={this.handleChangeCategory}
                                      options={categories}
                                      isClearable={true}
                                      placeholder="Filter By Category"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={subcategoryvalue}
                                      onChange={this.handleChangeSubCategory}
                                      options={subCategory}
                                      isClearable={true}
                                      placeholder="Filter By Sub Category"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col"
                                  >
                                    <Select
                                      value={suppliervalue}
                                      onChange={this.handleChangeSuppliers}
                                      options={suppliers}
                                      isClearable={true}
                                      placeholder="Filter By Supplier"
                                    />
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={12}
                                    className="filter-container-col d-flex justify-content-end"
                                  >
                                    <ExportCSVButton
                                      className="btn btn-primary"
                                      {...props.csvProps}
                                    >
                                      <i className="fa fa-download mr-2"></i>
                                      CSV
                                    </ExportCSVButton>
                                  </Col>
                                </Row>
                              )}

                              {isLoading ? (
                                <div className="custom-loader">
                                  <Spinner animation="border" />
                                </div>
                              ) : (
                                <>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="product_id"
                                    key={`productListTable`}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={"No Products available"}
                                    bordered={false}
                                    pagination={paginationFactory({
                                      sizePerPage: 10,
                                      showTotal: true,
                                      hideSizePerPage: true,
                                    })}
                                    rowClasses="row-hover"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </ToolkitProvider>
                        {/* )} */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */
