/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Spinner,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumbs from "../components/common/breadcrumb";
import ProductSlider from "../Products/ProductSlider";
import { groupDetail } from "../services/GroupServices";
import Moment from "react-moment";

const SupplierGroupDetails = (props) => {
  // this state is used for loading spinner
  const [isLoading, setLoading] = useState(false);
  //this state for group data
  const [groupData, setGroupData] = useState({});
  // this state for levels list array
  const [levels, setLevels] = useState([]);

  /* it will fetch data from api */
  useEffect(() => {
    getGroupDetail();
  }, []);

  //  this funciton is userd for group details
  const getGroupDetail = () => {
    setLoading(true);
    groupDetail(props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          setGroupData(response.data);
          setLoading(false);
          setLevels(response.data.levels);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // columns for bootstrap table
  const columns = [
    {
      dataField: "quantity_level",
      text: "Quantity",

      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "discount_percent",
      text: "Discount %",

      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Groups" parent="Groups" subtitle="Groups" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h5>View Group</h5>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <Row className="product-adding">
                      <Col className="col-md-5">
                        <div className="clearfix">
                          <ProductSlider
                            sliderImages={groupData.productImage}
                            imageUrlKey="imageUrl"
                          />
                        </div>
                      </Col>
                      <Col className="col-md-7">
                        <h5>
                          <strong>{groupData.offer_name}</strong>
                        </h5>
                        <p>{groupData.description}</p>
                        <Row>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Category </strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.category}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Supplier Name </strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.supplier_name}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Price</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p> $ {groupData.price}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Shipping Cost</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.shipping_cost}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Start Date And End Date</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>
                                  <Moment format="MM/DD/YYYY">
                                    {groupData.start_date}
                                  </Moment>{" "}
                                  -{" "}
                                  <Moment format="MM/DD/YYYY">
                                    {groupData.end_date}
                                  </Moment>
                                </p>
                              </div>
                            </div>
                          </Col>

                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Status</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.status}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <BootstrapTable
                              keyField="id"
                              key={`quantityListTable`}
                              striped
                              hover
                              condensed
                              bordered={false}
                              data={levels}
                              columns={columns}
                              rowClasses="row-hover"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SupplierGroupDetails;
/* Copyright Information: Created by BuyGroup */
