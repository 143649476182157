/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Home } from "react-feather";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title, parent, subtitle }) => {
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col className="col-md-6 col-sm-6">
            <div className="page-header-left">
              <h3>
                {title}
                {/* <small>{subtitle}</small> */}
              </h3>
            </div>
          </Col>
          <Col className="col-md-6 col-sm-6">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <Link
                  to={{
                    pathname: "/app/dashboard",
                  }}
                >
                  <Home />
                </Link>
              </li>
              {parent !== "" ? (
                <li className="breadcrumb-item">{parent}</li>
              ) : null}

              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumb;
/* Copyright Information: Created by BuyGroup */
