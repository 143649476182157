/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ValidationSchemaSubCategory } from "./ValidationSchema";
import trimObj from "../components/common/TrimData";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const EditSubCategory = (props) => {
  const {
    editSubCategoryModal,
    initialValues,
    updateSubCategoryHandler,
    closeEditSubCategoryModal,
    isLoadingButton,
  } = props;

  /**
   * This function send updatedSubCategoryData data
   * @param  {updatedSubCategoryData} updatedSubCategoryData data Object
   */
  const Submit = (updatedSubCategoryData) => {
    updatedSubCategoryData = trimObj(updatedSubCategoryData);
    updateSubCategoryHandler(updatedSubCategoryData);
  };

  /* This function closed edit Supplier Modal */
  const closeHandler = () => {
    closeEditSubCategoryModal();
  };

  return (
    <Modal
      isOpen={editSubCategoryModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Sub Category
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={Submit}
        validationSchema={ValidationSchemaSubCategory}
      >
        <Form>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Category
              </Label>
              <Field
                name="parent_category_name"
                className="form-control"
                disabled
              ></Field>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Edit Sub Category
              </Label>
              <Field
                type="text"
                className="form-control"
                name="category_name"
              />
              <ErrorMessage name="category_name">
                {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
              </ErrorMessage>
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" disabled={isLoadingButton}>
              {isLoadingButton ? "Please Wait..." : "Update"}
            </Button>
            <Button type="button" color="light" onClick={closeHandler}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};
export default EditSubCategory;
/* Copyright Information: Created by BuyGroup */