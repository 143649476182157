/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const addNewCategory = (newCategoryData) => {
  return Api.post("category", newCategoryData).then((response) => response);
};

export const editCategory = (editCategoryData, category_id) => {
  return Api.put(`category/${category_id}`, editCategoryData).then(
    (res) => res
  );
};

export const deleteCategory = (id) => {
  return Api.delete(`category/${id}`).then((res) => res);
};
/* Copyright Information: Created by BuyGroup */
