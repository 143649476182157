/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationUpdateSchema } from "./CustomerValidationSchema";
import { City } from "country-state-city";
import { useParams } from "react-router-dom";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input/input";
import trimObj from "../components/common/TrimData";
import Moment from "react-moment";
import ApproveCustomerDocument from "./ApproveCustomerDocument";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import {
  deleteCustomer,
  customerDetail,
  updateCustomer,
  verifyDoc,
} from "../services/CustomerServices";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Row,
  CardBody,
  Card,
  Col,
} from "reactstrap";
import RejectCustomerDocument from "./RejectCustomerDocument";

const EditCustomerDetail = (props) => {
  let { id } = useParams();

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState();

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const [isDisableUpdate, setIsDisableUpdate] = useState(false);

  const [isLoader, setIsLoader] = useState(true);

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const [showRejectModal, setShowRejectModal] = useState(false);

  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    getCustomerDetail();
  }, []);

  useEffect(() => {
    // console.log("test useEffect");
    if (initialValues) {
      getCountryList();
      getStateList();
      getCityList();
    }
  }, [initialValues]);

  const getCustomerDetail = () => {
    // this.setState({ isLoading: true });
    console.log("getCustomerDetail");
    customerDetail(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.company_name === null) {
            response.data.company_name = "";
          }
          if (response.data.title === null) {
            response.data.title = "";
          }
          setInitialValues(response.data);
          setIsLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };
  //this function call update  customer api
  const updateDetailCustomer = (customerData) => {
    if (customerData.title === "") {
      delete customerData.title;
    }
    if (customerData.company_name == "") {
      delete customerData.company_name;
    }
    delete customerData.addressInfo;
    delete customerData.orders;
    setIsDisableUpdate(true);
    setIsLoader(true);
    updateCustomer(customerData)
      .then((response) => {
        if (response.status === 200) {
          setIsDisableUpdate(false);
          // setIsLoader(false);
          getCustomerDetail();
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          // this.props.history.push("/app/members");
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsDisableUpdate(false);
        // this.setState({ isDisableUpdate: false });
      });
  };

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };
  /* This function will  set all state for default country  */
  const getStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    setCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.country) {
        return country;
      }
    });

    // const defaultCountryIsoCode = "IN";
    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    // const defaultstateIsoCode = "MP";
    const defaultstateIsoCode = getdefaultState[0]?.isoCode;

    setStatesValues({ vlaue: defaultstateIsoCode, label: initialValues.state });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode,
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   */

  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("phone_number", selectphone);
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("country", selectedCountry.label);
    setFieldTouched("country", false);
    setFieldValue("state", "");
    setFieldValue("city", "");

    const countryisoCode = selectedCountry.value;

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode == countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("state", selectedState.label);
    setFieldValue("city", "");
    setFieldTouched("state", false);

    const stateisoCode = selectedState.value;

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("city", selectCity.label);
    setFieldTouched("city", false);
  };

  const onSubmit = (customerData) => {
    delete customerData["name"];
    delete customerData["email"];
    customerData.new_tax_exempt_doc = [];
    customerData.new_other_docs = [];
    customerData.delete_tax_doc = [];
    customerData.delete_other_docs = [];
    customerData = trimObj(customerData);
    updateDetailCustomer(customerData);
  };

  const openVerfiyModal = (docData) => {
    setShowVerifyModal(true);
    setDocumentData(docData);
  };

  const openRejectModal = (docData) => {
    setShowRejectModal(true);
    setDocumentData(docData);
  };

  const closeRejectModal = () => {
    setShowRejectModal(false);
  };
  const closeVerfiyModal = () => {
    setShowVerifyModal(false);
  };

  const verifyDocument = (data) => {
    console.log("verifyDocument", data);
    verifyDoc(data)
      .then((response) => {
        if (response.status === 200) {
          console.log("sresponse", response);
          setShowVerifyModal(false);
          getCustomerDetail();
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };
  const rejectDocument = (data) => {
    console.log("rejectDocument", data);
    verifyDoc(data)
      .then((response) => {
        if (response.status === 200) {
          console.log("sresponse", response);
          setShowRejectModal(false);
          getCustomerDetail();
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  return (
    <>
      <Card className="card-box">
        <div className="card-header">
          <Row className="d-flex align-items-center justify-content-between">
            <Col md="6">
              <div className="d-flex align-items-center justify-content-between">
                <h5>Edit Member Details</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          {isLoader ? (
            <div className="custom-loader">
              <Spinner animation="border" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationUpdateSchema}
            >
              {({ setFieldValue, handleBlur, setFieldTouched, errors }) => (
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">First Name</Label>
                        <Field
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                        />
                        <ErrorMessage name="first_name">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">Last Name</Label>
                        <Field
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                        />
                        <ErrorMessage name="last_name">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Company Name
                        </Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="company_name"
                        />
                        <ErrorMessage name="company_name">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Title
                        </Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="title"
                        />
                        <ErrorMessage name="title">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">Phone Number</Label>

                        <PhoneInput
                          country="US"
                          onBlur={handleBlur}
                          value={initialValues.phone_number}
                          name="phone_number"
                          className="form-control"
                          maxLength={14}
                          onChange={(value) =>
                            handlePhoneNumber(value, setFieldValue)
                          }
                          limitMaxLength={true}
                        />
                        {errors.phone_number ? (
                          <div className="input-feedback">
                            {errors.phone_number}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">Email</Label>

                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          disabled
                        />

                        <ErrorMessage name="email">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Country
                        </Label>

                        <Select
                          value={countriesValue}
                          noOptionsMessage={() => "No country found"}
                          defaultValue={{
                            value: "",
                            label: initialValues.country,
                          }}
                          onChange={(value) =>
                            handleCountry(value, setFieldValue, setFieldTouched)
                          }
                          options={countries}
                          name="country"
                          placeholder="Select Country"
                        />

                        <ErrorMessage name="country">
                          {(errorMsg) => (
                            <label className="err-msg">{errorMsg}</label>
                          )}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          State
                        </Label>

                        <Select
                          value={statesValues}
                          noOptionsMessage={() =>
                            noDataStateMessage
                              ? "No State Found"
                              : "First select country"
                          }
                          defaultValue={{
                            value: "",
                            label: initialValues.state,
                          }}
                          onChange={(value) =>
                            handleState(value, setFieldValue, setFieldTouched)
                          }
                          options={filterStates}
                          name="state"
                          placeholder="Select State"
                        />

                        <ErrorMessage name="state">
                          {(errorMsg) => (
                            <label className="err-msg">{errorMsg}</label>
                          )}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          City
                        </Label>

                        <Select
                          value={cityValues}
                          defaultValue={{
                            value: "",
                            label: initialValues.city,
                          }}
                          noOptionsMessage={() =>
                            noDataCityMessage
                              ? "No City Found"
                              : "First select state"
                          }
                          onChange={(value) =>
                            handleCity(value, setFieldValue, setFieldTouched)
                          }
                          options={filterCity}
                          name="city"
                          placeholder="Select City"
                        />

                        <ErrorMessage name="city">
                          {(errorMsg) => (
                            <label className="err-msg">{errorMsg}</label>
                          )}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">Street</Label>

                        <Field
                          type="text"
                          className="form-control"
                          id="street"
                          name="street"
                        />

                        <ErrorMessage name="street">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">Zip Code</Label>
                        <Field
                          type="text"
                          id="zip_code"
                          className="form-control"
                          name="zip_code"
                        />
                        <ErrorMessage name="zip_code">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </FormGroup>
                    </Col>
                  </Row>
                  {initialValues.tax_exempt_doc.length > 0 ? (
                    <>
                      <Label className="col-form-label">
                        Tax Document / Certificate
                      </Label>
                      <table className="table table-bordered">
                        <thead className="table-secondary">
                          <tr>
                            <th>File Name </th>
                            <th>Expiration Date</th>
                            <th
                              style={{ minWidth: "260px", textAlign: "center" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialValues.tax_exempt_doc.map((docData) => {
                            return (
                              <tr>
                                <td>
                                  <a
                                    href={docData.docUrl}
                                    className="help-text"
                                  >
                                    {docData.doc_filename}
                                  </a>
                                </td>
                                <td>
                                  {docData.expiration_date ? (
                                    <Moment format="MM/DD/YYYY">
                                      {docData.expiration_date}
                                    </Moment>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {docData.is_verified &&
                                  docData.expiration_date ? (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        openVerfiyModal(docData);
                                      }}
                                    >
                                      Modfiy
                                    </button>
                                  ) : docData.is_verified == false &&
                                    docData.is_rejected ? (
                                    <>
                                      <span className="btn disabled btn-sm btn-light">
                                        Rejected
                                      </span>
                                      <span>
                                        <i
                                          class="fa fa-info-circle"
                                          data-tip={docData.reason}
                                        ></i>
                                      </span>
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="solid"
                                        multiline={true}
                                        className="tool-tip-width"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          openVerfiyModal(docData);
                                        }}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-light"
                                        onClick={() => openRejectModal(docData)}
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                  {initialValues.other_docs.length > 0 ? (
                    <>
                      <Label className="col-form-label">Other Document</Label>
                      <table className="table table-bordered">
                        <thead className="table-secondary">
                          <tr>
                            <th>File Name </th>
                            <th>Expiration Date</th>
                            <th
                              style={{ minWidth: "260px", textAlign: "center" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialValues.other_docs.map((docData) => {
                            return (
                              <tr>
                                <td>
                                  <a href={docData.docUrl}>
                                    {docData.doc_filename}
                                  </a>
                                </td>
                                <td>
                                  {docData.expiration_date ? (
                                    <Moment format="MM/DD/YYYY">
                                      {docData.expiration_date}
                                    </Moment>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {docData.is_verified &&
                                  docData.expiration_date ? (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        openVerfiyModal(docData);
                                      }}
                                    >
                                      Modfiy
                                    </button>
                                  ) : docData.is_verified == false &&
                                    docData.is_rejected ? (
                                    <>
                                      <span className="btn disabled btn-sm btn-light">
                                        Rejected
                                      </span>
                                      <span>
                                        <i
                                          class="fa fa-info-circle"
                                          data-tip={docData.reason}
                                        ></i>
                                      </span>
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="solid"
                                        multiline={true}
                                        className="tool-tip-width"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          openVerfiyModal(docData);
                                        }}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-light"
                                        onClick={() => openRejectModal(docData)}
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    ""
                  )}

                  <Button
                    color="primary"
                    type="submit"
                    disabled={isDisableUpdate}
                  >
                    {isDisableUpdate ? "Please Wait" : "Update"}
                  </Button>
                  <Link
                    to={`/app/members/${id}`}
                    className="btn btn-light mr-2"
                  >
                    Cancel
                  </Link>
                </Form>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
      {showVerifyModal && (
        <ApproveCustomerDocument
          documentData={documentData}
          verifyDocument={verifyDocument}
          openVerfiyModal={openVerfiyModal}
          verifyModalOpenState={showVerifyModal}
          closeVerfiyModal={closeVerfiyModal}
        />
      )}
      {showRejectModal && (
        <RejectCustomerDocument
          documentData={documentData}
          openRejectModal={openRejectModal}
          rejectDocument={rejectDocument}
          RejectModalOpenState={showRejectModal}
          closeRejectModal={closeRejectModal}
        />
      )}
    </>
  );
};
export default EditCustomerDetail;
/* Copyright Information: Created by BuyGroup */
