/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ValidationSchemaSubCategory } from "./ValidationSchema";
import trimObj from "../components/common/TrimData";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const AddSubCategory = (props) => {
  const {
    addSubCategoryModal,
    initialValues,
    addNewSubCategoryhandler,
    closeAddSubCategoryModal,
    isLoadingButton,
  } = props;

  /**
   * This function send newSubCategoryData data
   * @param  {newSubCategoryData} newSubCategoryData data Object
   */

  const Submit = (newSubCategoryData) => {
    newSubCategoryData = trimObj(newSubCategoryData);

    addNewSubCategoryhandler(newSubCategoryData);
  };

  /* This function closed Add Supplier Modal */
  const closeHandler = () => {
    closeAddSubCategoryModal();
  };

  return (
    <Modal
      isOpen={addSubCategoryModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Sub Category
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={Submit}
        validationSchema={ValidationSchemaSubCategory}
      >
        <Form>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Category
              </Label>

              <Field
                name="parent_category_name"
                className="form-control"
                disabled
              ></Field>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Add Sub Category
              </Label>

              <Field
                type="text"
                className="form-control"
                name="category_name"
              />
              <ErrorMessage name="category_name">
                {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
              </ErrorMessage>
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" disabled={isLoadingButton}>
              {isLoadingButton ? "Please Wait..." : "Add"}
            </Button>
            <Button type="button" color="light" onClick={closeHandler}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddSubCategory;
/* Copyright Information: Created by BuyGroup */
