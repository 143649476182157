/* Copyright Information: Created by BuyGroup */
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//images import
import man from "../../../assets/images/dashboard/man.png";
import ChangePassword from "../../auth/ChangePassword";
import { changePassword } from "../../../services/AuthServices";
import { toast } from "react-toastify";
toast.configure();

const UserMenu = () => {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleModalOpen = (e) => {
    e.preventDefault();
    setChangePasswordModal(true);
  };

  const handleModalHide = () => {
    setChangePasswordModal(false);
    setErrorMessage("");
  };

  const updatePassword = (values, actions) => {
    console.log("values, actions", values, actions);
    // return;
    setIsloading(true);
    changePassword(values)
      .then((res) => {
        console.log("Change password Response", res);
        setIsloading(false);
        toast.success(res.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.log("error", error);
        setTimeout(
          function () {
            setErrorMessage("");
          }.bind(this),
          3000
        );
        if (error !== undefined) {
          setIsloading(false);
          setErrorMessage(error.data.message);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          setIsloading(false);
          setErrorMessage(error.data.message);
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  };

  const initialValues = {
    old_password: "",
    password: "",
  };
  let user = JSON.parse(localStorage.user);
  console.log("User load", user);
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={
              user.profile_image !== null && user.profile_image !== undefined
                ? user.profile_image
                : man
            }
            alt="#"
          />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/app/settings/profile`}>
              <i data-feather="user"></i>Edit Profile
            </Link>
          </li>
          {/* <li>
						<a href="#javaScript">
							<i data-feather="mail"></i>Inbox
						</a>
					</li>
					<li>
						<a href="#javaScript">
							<i data-feather="lock"></i>Lock Screen
						</a>
					</li> */}
          {/* <li>
            <a href="#javaScript">
              <i data-feather="settings"></i>Settings
            </a>
          </li> */}
          <li>
            <a href="#" onClick={(e) => handleModalOpen(e)}>
              <i data-feather="settings"></i>Change Password
            </a>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/`} onClick={handleLogout}>
              <i data-feather="log-out"></i>Logout
            </Link>
          </li>
        </ul>
      </li>

      {changePasswordModal ? (
        <ChangePassword
          changePasswordModal={changePasswordModal}
          handleModalHide={handleModalHide}
          initialValues={initialValues}
          onSubmit={updatePassword}
          errorMessage={errorMessage}
          isloading={isloading}
        />
      ) : null}
    </Fragment>
  );
};

export default UserMenu;
/* Copyright Information: Created by BuyGroup */
