/* Copyright Information: Created by BuyGroup */
import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const DeleteSupplier = (props) => {
  const {
    deleteSupplierModal,
    removeSupplier,
    deleteSupplierId,
    closedDeleteModal,
    isLoadingButton,
  } = props;

  return (
    <div>
      <Modal isOpen={deleteSupplierModal} style={{ overlay: { opacity: 0.1 } }}>
        <ModalHeader toggle={closedDeleteModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Are you sure you want to delete this Supplier?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={() => removeSupplier(deleteSupplierId)}
            disabled={isLoadingButton}
          >
            {isLoadingButton ? "Please Wait..." : "Yes, Delete it!"}
          </Button>
          <Button type="button" color="light" onClick={closedDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteSupplier;
/* Copyright Information: Created by BuyGroup */
