/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";
const validationSchema = Yup.object({
  offer_name: Yup.string().required("Please enter group name"),
  price: Yup.number()
    .typeError("Must be a number")
    .required("Please enter price")
    .test(
      "maxDigitsAfterDecimal",
      "Price must have 2 digits after decimal or less",
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    ),
  product_id: Yup.string().required("Please select product"),
  // zip_code: Yup.string().required("Please enter zip code"),
  zip_code: Yup.array().min(1, "Please add least one zipcode").nullable(),
  // zip_code: Yup.array()
  //   .min(1, "Please add least one zipcode")
  //   .of(
  //     Yup.object().shape({
  //       value: Yup.string().required("Please enter price"),
  //     })
  //   ),
  start_date: Yup.date("Please select start date")
    .required("Please select start date")
    .nullable(),
  end_date: Yup.date("Please select end date")
    .required("Please select end date")
    .nullable(),
  minimum_quantity: Yup.number()
    .typeError("Must be a number")
    .required("Please enter minimum quantity")
    .test(
      "maxDigitsAfterDecimal",
      "minimum quantity can not be in decimal",
      (number) => /^\d+$/.test(number)
    ),
});
export default validationSchema;
/* Copyright Information: Created by BuyGroup */
