/* Copyright Information: Created by BuyGroup */
import "./App.css";
import Login from "./components/auth/login";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./components/common/Main";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyAccount from "./components/auth/VerifyAccount";
function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/user/verify" component={VerifyAccount} />
          <Route exact path="/" component={Login} />
          <Route path="/app" component={Main} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
/* Copyright Information: Created by BuyGroup */
