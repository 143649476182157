/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Spinner,
  Form,
} from "reactstrap";
import {
  getProductDetail,
  supplierList,
  categoryList,
  editProduct,
  deleteProduct,
  getSubCategoryDetail,
} from "../services/ProductServices";
import Select from "react-select";
import trimObj from "../components/common/TrimData";
import SunEditor from "suneditor-react";
import list from "suneditor/src/plugins/submenu/list";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
toast.configure();

const EditProduct = (props) => {
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [files, setFiles] = useState([]); // state variable in files
  const [filesData, setFilesData] = useState([]); // state variable in copy of files Data
  const [newFiles, setNewFiles] = useState([]); // state variable in newFiles
  const [isLoding, setIsLoding] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [editorDescription, setEditorDescription] = useState("");

  const [documentFile, setDocumentFile] = useState([]);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [documentFileName, setDocumentFileName] = useState("");
  const [isDisabledDocument, setIsDisabledDocument] = useState(true);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [subCategoryEnabled, setSubCategoryEnabled] = useState(false);

  // Upload multiple image than convert to base 64 and store the data in the state
  const readURI = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      let valid = false;
      for (var j = 0; j < getFiles.length; j++) {
        var sCurExtension = getFiles[j];

        const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

        if (
          checkFileTyype == ".jpeg" ||
          checkFileTyype == ".jpg" ||
          checkFileTyype == ".png"
        ) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  newIndex: uuidGenrate(index),
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            /* Once all promises are resolved, update state with image URI array */

            const mergeFiles = files.concat(images);
            setFiles(mergeFiles);
            setFilesData(mergeFiles);
            setNewFiles(images);
            setFieldValue("images", mergeFiles);
            setFieldValue("new_images", images);
            e.target.value = "";
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  // Upload pdf file than convert to base 64 and store the data in the state
  const readPDF = (e, setFieldValue) => {
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);

      let valid = false;
      var sCurExtension = getFiles[0];

      const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];

      if (checkFileTyype == ".pdf") {
        valid = true;
      } else {
        valid = false;
      }

      if (!valid) {
        toast.error("File type not supported", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  name: file.name,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (pdf) => {
            /* Once all promises are resolved, update state with PDF URI array */

            setDocumentFile(pdf);
            setFieldValue("new_upload_doc", pdf);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  const uuidGenrate = (val) => {
    let number = Math.floor(100000000 + Math.random() * 900000000);
    return Math.floor(100000000 + Math.random(val) * 900000000);
  };
  // Delete Images after uploading
  const handleDeleteImage = (e, index, new_index, setFieldValue) => {
    const copyFiles = [...files];

    const getObject = copyFiles[index];
    // check key is exist or not
    if (getObject.sequence !== undefined) {
      let prepareDataDeleteFiles = deleteFiles.concat(getObject.sequence);
      setDeleteFiles(prepareDataDeleteFiles);

      setFieldValue("delete_images", prepareDataDeleteFiles);
    } else {
      if (getObject.newIndex != undefined) {
        let filterData = newFiles.filter((x) => x.newIndex != new_index);

        setNewFiles(filterData);
        setFieldValue("new_images", filterData);
      }
    }

    copyFiles.splice(index, 1);
    setFiles(copyFiles);
    setFieldValue("images", copyFiles);
  };

  // Update product
  const updateProduct = async (data) => {
    setIsLoding(true);
    // return;
    editProduct(data)
      .then((res) => {
        if (res.status === 200) {
          const getLenght = res.data[0].imagessignedURLs.length;
          if (getLenght == 0) {
            setIsLoding(false);
            props.history.push(
              `/app/products/view-product/${res.data[0].product_id}`
            );
            toast.success("Product update successfully", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          } else {
            res.data[0].imagessignedURLs.map((url, index) => {
              const getData = newFiles[index];

              const contentType = getData.mime_type;
              const b64Data = getData.path;
              const replaceDataImage = b64Data.replace(
                /^data:image\/[a-z]+;base64,/,
                ""
              );

              const blob = b64toBlob(replaceDataImage, contentType);

              fetch(url.uploadURL, {
                method: "PUT",
                body: blob,
              })
                .then(function () {
                  if (getLenght === index + 1) {
                    // last one Last index getting and apply logic here
                    setIsLoding(false);
                    props.history.push(
                      `/app/products/view-product/${res.data[0].product_id}`
                    );
                    toast.success("Product update successfully", {
                      autoClose: 3000,
                      hideProgressBar: true,
                    });
                  }
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            });
          }
          const getLenghtDocumentUploadUrl =
            res.data[0].documentUploadUrl.length;

          if (getLenghtDocumentUploadUrl != 0) {
            res.data[0].documentUploadUrl.map((url, index) => {
              const getData = documentFile[index];

              const contentType = getData.mime_type;
              const b64Data = getData.path;
              const replaceDataImage = b64Data.replace(
                /^data:application\/[a-z]+;base64,/,
                ""
              );

              const blob = b64toBlob(replaceDataImage, contentType);

              fetch(url.uploadURL, {
                method: "PUT",
                body: blob,
              })
                .then(function () {
                  console.log("ok PDF upload done");
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            });
          }
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          setIsLoding(false);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  };

  // Convert base 64 to blob data
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getProductDetailList = async (id) => {
    setShowLoader(true);
    getProductDetail(id)
      .then((res) => {
        if (res.status === 200) {
          setInitialValues({
            ...res.data[0],
            new_images: [],
            delete_images: [],
            new_upload_doc: [],
            delete_document: "",
          });
          setFiles(res.data[0].imageUrl);
          setNewFiles([]);
          setSelectedCategory(res.data[0].category);

          getSubCategoryOption(res.data[0].category);

          setSelectedSupplier(res.data[0].suppliers);
          setEditorDescription(res.data[0].specification);
          res.data[0].documentUrl.length >= 1
            ? setDocumentUrl(res.data[0].documentUrl[0].path)
            : setIsDisabledDocument(false);
          setDocumentFileName(res.data[0].document_file[0]);

          // setTimeout(() => {
          setSelectedSubCategory(res.data[0].sub_category);

          setSelectedSupplier(res.data[0].suppliers);
          setShowLoader(false);
          // }, 500);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          console.log("error", error.data.message.message);
        }
      });
  };

  const getSubCategoryOption = (id) => {
    getSubCategoryDetail(id)
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.category };
          });
          setSubCategories(options);
          options.length > 0
            ? setSubCategoryEnabled(true)
            : setSubCategoryEnabled(false);
          setSelectDisabled(false);
        }
      })
      .catch((error) => {
        setSelectDisabled(false);
      });
  };

  useEffect(() => {
    getAllSuppliers();
    getAllCategory();
    getProductDetailList(props.match.params.id);
    setDeleteFiles([]);
  }, []);

  /*
   * Function getAllSuppliers get all the data for Suppliers
   */
  const getAllSuppliers = () => {
    // return;
    supplierList()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.id, label: cat.company_name };
          });
          setSuppliers(options);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /*
   * Function getAllCategory get all the data for categories
   */

  const getAllCategory = () => {
    // return;
    categoryList()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map(function (cat) {
            return { value: cat.category_id, label: cat.category_name };
          });
          setCategories(options);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /*
   * Function handleDelete open confirmation modal and remove the product
   */
  const handleDelete = (e, id) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title f-w-600">Confirmation</h5>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this product?</p>
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => {
                    removeProduct(id);
                    onClose();
                  }}
                >
                  Yes, Delete it!
                </Button>
                <Button type="button" color="light" onClick={onClose}>
                  No
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  /*
   * Function removeProduct Close confirmation modal and API calling
   */
  const removeProduct = (id) => {
    const prapreData = {
      productId: id,
    };

    // return;
    deleteProduct(prapreData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          props.history.push("/app/products");
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  const handleCategoryChange = (selectedCategory, setFieldValue) => {
    setSelectDisabled(true);
    setFieldValue("sub_category", "");
    setSelectedSubCategory("");
    setSelectedCategory(selectedCategory.value);
    getSubCategoryOption(selectedCategory.value);
    setFieldValue("category", selectedCategory.value);
  };

  /*
   * Function handleSubCategoryChange get the value for selectedSubCategory
   */

  const handleSubCategoryChange = (selectedSubCategory, setFieldValue) => {
    setSelectedSubCategory(selectedSubCategory.value);
    setFieldValue("sub_category", selectedSubCategory.value);
  };

  const handleSuppliersChange = (selectedSupplier, setFieldValue) => {
    setSelectedSupplier(selectedSupplier);
    setFieldValue("suppliers", selectedSupplier);
  };

  const onEditorDescriptionChange = (content, setFieldValue) => {
    setEditorDescription(content);
  };

  const deleteDocument = (e, setFieldValue, documentFileName) => {
    setFieldValue("delete_document", documentFileName);
    setDocumentUrl("");
    setIsDisabledDocument(false);
  };

  return (
    <>
      <Breadcrumbs
        title="Edit Products"
        parent="Product"
        subtitle="Product Admin Panel"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h5>Edit Product</h5>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button
                      type="button"
                      color="secondary"
                      data-toggle="modal"
                      data-original-title="Delete Product"
                      onClick={(e) => handleDelete(e, props.match.params.id)}
                    >
                      <i className="fa fa-trash mr-2"></i>
                      Delete Product
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={(values, actions) => {
                    let newValues = values;
                    values.specification = editorDescription;
                    const imagesArray = values.new_images.map(function (x) {
                      return {
                        mime_type: x.mime_type,
                      };
                    });
                    const joinImages = values.images.map(function (x) {
                      return {
                        mime_type: x.mime_type,
                      };
                    });
                    values.new_images = imagesArray;
                    values.images = joinImages;
                    const uploadDocumentArray = values.new_upload_doc.map(
                      function (x) {
                        return {
                          mime_type: x.mime_type,
                        };
                      }
                    );
                    values.new_upload_doc = uploadDocumentArray;
                    values = trimObj(values);
                    values.expiration_date = newValues.expiration_date;
                    // return;
                    updateProduct(values, actions);
                  }}
                  validationSchema={Yup.object().shape({
                    product_name: Yup.string().required(
                      "Please select product name"
                    ),
                    // upc: Yup.string()
                    //   .required()
                    //   .matches(/^[0-9]+$/, "Must be only digits")
                    //   .min(12, "Must be exactly 12 digits")
                    //   .max(12, "Must be exactly 12 digits"),
                    weight: Yup.number().typeError("Must be a number"),
                    unit: Yup.string().required("Please enter unit"),
                    manufacturer: Yup.string().required(
                      "Please select manufacturer"
                    ),
                    description: Yup.string().required(
                      "Please select description"
                    ),
                    category: Yup.string().required("Please select category"),
                    suppliers: Yup.array().min(1, "Please select suppliers"),
                    price: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter price")
                      .test(
                        "maxDigitsAfterDecimal",
                        "Price must have 2 digits after decimal or less",
                        (number) => /^\d+(\.\d{1,2})?$/.test(number)
                      ),
                    lot_size: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter lot size"),
                    expiration_date: Yup.string().required(
                      "Please select expiration date"
                    ),
                    minimum_quantity: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter minimum quantity"),
                    maximum_quantity: Yup.number()
                      .typeError("Must be a number")
                      .required("Please enter maximum quantity"),
                    is_taxable: Yup.boolean().required(),
                    tax_rate: Yup.number().nullable()
                      .when('is_taxable', {
                        is: true,
                        then: Yup.number().required("Please enter tax rate").typeError("Please enter tax rate"),
                        otherwise: Yup.number().nullable(),
                      }),
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                  }) => (
                    <form className="add-product-form" onSubmit={handleSubmit}>
                    {console.log("values :- ", values.tax_rate)}

                      {showLoader ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <Row className="product-adding">
                          <Col xl="5">
                            <div className="form-group">
                              <input
                                className="show-for-sr"
                                type="file"
                                id="upload_imgs"
                                name="images[]"
                                multiple
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => readURI(e, setFieldValue)}
                              // id="file"
                              />
                              <label htmlFor="file">
                                <i className="fa fa-plus"></i> Upload Photo
                              </label>
                              <ul className="photo-container">
                                {files.map((imageURI, index) => (
                                  <li>
                                    <img
                                      className="photo-uploaded"
                                      src={imageURI.path}
                                      alt="Photo uploaded"
                                    />
                                    <span
                                      onClick={(e) =>
                                        handleDeleteImage(
                                          e,
                                          index,
                                          imageURI.newIndex,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <input
                              className="hidden"
                              type="hidden"
                              name="new_images"
                            />
                            <input
                              className="hidden"
                              type="hidden"
                              name="delete_images"
                            />
                          </Col>
                          <Col xl="7">
                            <div className="form form-label-center">
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Product Name
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="product_name"
                                    type="text"
                                    placeholder="Enter Product Name"
                                    value={values.product_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.product_name &&
                                      touched.product_name &&
                                      errors.product_name}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  UPC
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="upc"
                                    type="text"
                                    placeholder="Code Number"
                                    value={values.upc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.upc && touched.upc && errors.upc}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Details Per Unit
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="unit"
                                    type="text"
                                    placeholder="Enter Unit"
                                    value={values.unit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.unit && touched.unit && errors.unit}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Weight
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="weight"
                                    type="text"
                                    placeholder="Weight in Pound"
                                    value={values.weight}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.weight &&
                                      touched.weight &&
                                      errors.weight}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Manufacturer
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="manufacturer"
                                    type="text"
                                    placeholder="Manufacturer Name"
                                    value={values.manufacturer}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.manufacturer &&
                                      touched.manufacturer &&
                                      errors.manufacturer}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Description
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    placeholder="Enter Description here"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.description &&
                                      touched.description &&
                                      errors.description}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Select Category
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Select
                                    name="category"
                                    className="zIndexIncrease"
                                    classNamePrefix="children"
                                    value={categories.filter(
                                      (obj) => obj.value === selectedCategory
                                    )}
                                    onChange={(selectedOption) => {
                                      handleCategoryChange(
                                        selectedOption,
                                        setFieldValue
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    options={categories}
                                    placeholder="Select Category"
                                  />
                                  <label className="err-msg">
                                    {errors.category &&
                                      touched.category &&
                                      errors.category}
                                  </label>
                                </div>
                              </FormGroup>
                              {subCategoryEnabled ? (
                                <FormGroup className="form-group mb-3 row">
                                  <Label className="col-xl-3 col-sm-4 mb-0">
                                    Select Sub Category
                                  </Label>
                                  <div className="col-xl-9 col-sm-8">
                                    <Select
                                      name="sub_category"
                                      className={`zIndexIncrease ${selectDisabled
                                          ? "disabled_true"
                                          : "disabled_false"
                                        }`}
                                      classNamePrefix="children"
                                      value={subCategories.filter(
                                        (obj) =>
                                          obj.value === selectedSubCategory
                                      )}
                                      // value={selectedSubCategory}
                                      onChange={(selectedOption) => {
                                        handleSubCategoryChange(
                                          selectedOption,
                                          setFieldValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      options={subCategories}
                                      placeholder="Select Sub Category"
                                    />
                                  </div>
                                </FormGroup>
                              ) : null}

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Select Supplier
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Select
                                    name="suppliers"
                                    className="zIndexIncrease"
                                    classNamePrefix="children"
                                    value={selectedSupplier}
                                    onChange={(selectedOption) => {
                                      handleSuppliersChange(
                                        selectedOption,
                                        setFieldValue
                                      );
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onBlur={handleBlur}
                                    options={suppliers}
                                    placeholder="Select Supplier"
                                  />
                                  <label className="err-msg">
                                    {errors.suppliers &&
                                      touched.suppliers &&
                                      errors.suppliers}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Product Specification
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <div className="clearfix cu_suneditor">
                                    <SunEditor
                                      defaultValue={editorDescription}
                                      name="specification"
                                      placeholder="Please type here..."
                                      height="150px"
                                      setOptions={{
                                        plugins: [list],
                                        height: 150,
                                        buttonList: [
                                          ["bold", "underline", "italic"],
                                          // "/", // Line break
                                          ["align", "list", "lineHeight"],
                                          ["formatBlock"],
                                        ],
                                      }}
                                      onChange={(data) =>
                                        onEditorDescriptionChange(
                                          data,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Price
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="price"
                                    type="text"
                                    placeholder="Enter Price"
                                    value={values.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.price &&
                                      touched.price &&
                                      errors.price}
                                  </label>
                                </div>
                              </FormGroup>

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Is Taxable
                                </Label>
                                <div className="col-xl-9 col-sm-8 " >

                                  <div className="display-flex">
                                    <div className="checkbox-size">
                                      <Input
                                        className="align-checkbox"
                                        name="is_taxable"
                                        type="checkbox"
                                        // placeholder="Is Taxable"
                                        value={values.is_taxable}
                                        checked={values.is_taxable}
                                        onChange={handleChange}
                                      // onBlur={handleBlur}
                                      />
                                    </div>

                                    {
                                      values.is_taxable &&
                                      <div className="width-100">
                                        <Input
                                          className="testing"
                                          name="tax_rate"
                                          type="number"
                                          placeholder="Tax Rate"
                                          value={values.tax_rate}
                                          onChange={handleChange}
                                        // onBlur={handleBlur}
                                        />
                                      </div>
                                    }
                                  </div>
                                  <div>

                                    {(errors.tax_rate &&
                                      touched.tax_rate &&
                                      errors.tax_rate) &&
                                      (
                                        <label className="err-msg">
                                          {errors.tax_rate}
                                        </label>
                                      )
                                    }
                                  </div>

                                </div>

                              </FormGroup>

                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Lot Size
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="lot_size"
                                    type="text"
                                    placeholder="Enter Lot Size"
                                    value={values.lot_size}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.lot_size &&
                                      touched.lot_size &&
                                      errors.lot_size}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Expiration Date
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <DatePicker
                                    selected={
                                      values.expiration_date
                                        ? new Date(values.expiration_date)
                                        : null
                                    }
                                    onChange={(selectedDate) => {
                                      setFieldValue(
                                        "expiration_date",
                                        selectedDate
                                      );
                                    }}
                                    showTimeSelect
                                    minDate={new Date()}
                                    timeInputLabel="Time:"
                                    placeholderText="Expiration Date"
                                    dateFormat="MM/dd/yyyy  h:mm aa"
                                    className="form-control"
                                    name="expiration_date"
                                    timeIntervals={15}
                                  />

                                  <label className="err-msg">
                                    {errors.expiration_date &&
                                      touched.expiration_date &&
                                      errors.expiration_date}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Minimum Quantity
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="minimum_quantity"
                                    type="text"
                                    placeholder="Enter Minimum Quantity"
                                    value={values.minimum_quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.minimum_quantity &&
                                      touched.minimum_quantity &&
                                      errors.minimum_quantity}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Maximum Quantity
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <Input
                                    className="form-control"
                                    name="maximum_quantity"
                                    type="text"
                                    placeholder="Enter Maximum Quantity"
                                    value={values.maximum_quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label className="err-msg">
                                    {errors.maximum_quantity &&
                                      touched.maximum_quantity &&
                                      errors.maximum_quantity}
                                  </label>
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Other Restrictions
                                </Label>
                                <div className="col-xl-9 col-sm-8">
                                  <textarea
                                    className="form-control"
                                    name="other_restrictions"
                                    placeholder="Enter Other Restrictions here"
                                    value={values.other_restrictions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup className="form-group mb-3 row">
                                <Label className="col-xl-3 col-sm-4 mb-0">
                                  Document
                                </Label>
                                <div className="col-xl-5 col-sm-5">
                                  <div className="form-group">
                                    <input
                                      className="show-for-sr"
                                      type="file"
                                      id="new_upload_doc"
                                      name="new_upload_doc[]"
                                      accept="application/pdf"
                                      onChange={(e) =>
                                        readPDF(e, setFieldValue)
                                      }
                                      disabled={isDisabledDocument}
                                    />
                                    <label
                                      htmlFor="new_upload_doc"
                                      className={
                                        isDisabledDocument ? "disabled" : ""
                                      }
                                    >
                                      <i className="fa fa-plus"></i> Upload
                                      Document
                                      {/* <i className="fa fa-plus"></i> */}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-sm-4">
                                  <div className="clearfix  show_pdf_name">
                                    {documentFile.map((pdfURI, index) => {
                                      return (
                                        <p
                                          className="truncate"
                                          title={pdfURI.name}
                                        >
                                          {pdfURI.name}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                                {isDisabledDocument ? (
                                  <div className="col-xl-12 col-sm-12 offset-xl-3 offset-sm-4">
                                    <div className="show_document">
                                      <a href={documentUrl} target="_blank">
                                        {documentFileName}
                                      </a>
                                      <span
                                        className="delete_document"
                                        onClick={(e) =>
                                          deleteDocument(
                                            e,
                                            setFieldValue,
                                            documentFileName
                                          )
                                        }
                                      >
                                        <i className="fa fa-close"></i>
                                      </span>
                                    </div>
                                  </div>
                                ) : null}

                                <input type="hidden" name="delete_document" />
                              </FormGroup>
                            </div>
                            <div className="offset-xl-3 offset-sm-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isLoding}
                              >
                                {isLoding ? "Please Wait..." : "Update"}
                              </Button>
                              <Link to="/app/products">
                                <Button type="button" color="light">
                                  Cancel
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProduct;
/* Copyright Information: Created by BuyGroup */
