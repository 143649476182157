/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardBody, Spinner, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import Breadcrumbs from "../components/common/breadcrumb";
import Select from "react-select";
import { toast } from "react-toastify";
import Moment from "react-moment";
import OrderUpdate from "../OrderManagement/OrderUpdate";
import { supplierOrderList, orderStatus } from "../services/OrderServices";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { generatePdfDocument } from "./SupplierOrderPdf";
const orderStatusList = [
  {
    label: "Pending",
    value: "in_progress",
  },
  {
    label: "To Be Shipped",
    value: "shipping",
  },
  {
    label: "Completed",
    value: "completed",
  },
];
const SupplierOrdersList = () => {
  const { ExportCSVButton } = CSVExport;

  /* const SearchBar  responsible for serach */
  const { SearchBar } = Search;

  /* const "orderList" constant store all data of order List*/
  const [orderList, setOrderList] = useState([]);

  /* const "orderList" constant store all data of order List*/
  const [orderListFilter, setOrderListFilter] = useState(orderList);

  /*  const "status" store object of selected option   */
  const [status, setStatus] = useState(null);

  /* const "isloading" constant is responsible for showing loader in order List */
  const [isLoading, setIsLoading] = useState(true);

  /*  const "orderUpdateModal" responsible for order Update Modal open or closed  */
  const [orderUpdateModal, setOrderUpdateModal] = useState(false);

  /* const "isLoadingButton" constant is disable button when data is feaching from api */
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /* const "rowData"  store select Row data */
  const [rowData, setRowData] = useState({});

  const dateFormatter = (cell) => {
    return <Moment format="MM/DD/YYYY h:mm a">{cell}</Moment>;
  };

  function priceFormatter(cell, row) {
    let myNumber = cell;
    let myNumberWithTwoDecimalPlaces = parseFloat(myNumber).toFixed(2);
    return myNumberWithTwoDecimalPlaces;
  }
  const totalFormatter = (cell,row) => {
    console.log("row",row.rate)
    console.log("row",row.quantity)
    console.log("row",row.tax_value)
    console.log("row",row.shipping)
    let myNumber =parseFloat(
      row.rate * row.quantity +
        parseInt(row.tax_value) +
        parseInt(row.shipping)
    ).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    
    return "$" + myNumber;
  };

  const orderImage = (cell) => {
    return (
      <div className="product_thumb">
        <img alt="Not Found" className="img-responsive" src={cell} />
      </div>
    );
  };

  const nameFormatter = (cell, row) => (
    <Link
      to={{
        pathname: `/app/supplier/memeber/${row.order_line_id}`,
      }}
    >
      <span>{cell}</span>
    </Link>
  );

  const groupFormatter = (cell, row) => (
    <Link to={{ pathname: "/app/groups/view/" + row.buy_group_id }}>
      <span>{cell}</span>
    </Link>
  );

  const columns = [
    {
      dataField: "path",
      text: "Image",
      csvExport: false,
      formatter: orderImage,
    },
    {
      dataField: "group_name",
      text: "Group Name",
      formatter: groupFormatter,
    },
    {
      dataField: "customer_name",
      text: "Name",
      headerAlign: "right",
      align: "right",
      formatter: nameFormatter,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      formatter: dateFormatter,
      csvFormatter: (cell, row, rowIndex) =>
        dateFormat(cell, "mm/dd/yyyy  h:MM TT"),
    },
    {
      dataField: "end_date",
      text: "End Date",
      formatter: dateFormatter,
      csvFormatter: (cell, row, rowIndex) =>
        dateFormat(cell, "mm/dd/yyyy  h:MM TT"),
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "rate",
      text: "Rate",
      formatter: (cell,row,)=> priceFormatter(cell, row),
    },
    {
      dataField: "total",
      text: "Total",
      formatter: (cell,row,)=> totalFormatter(cell, row),
    },

    {
      dataField: "street",
      text: "Street",
      hidden: true,
    },
    {
      dataField: "city",
      text: "City",
      hidden: true,
    },
    {
      dataField: "state",
      text: "State",
      hidden: true,
    },
    {
      dataField: "country",
      text: "Country",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, orderRow) => (
        <div>
          <span
            className="inline-block pointer-cursor"
            onClick={() => handleModalOpen(orderRow)}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                paddingLeft: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
        </div>
      ),
      headerAlign: "right",
      align: "right",
      csvExport: false,
    },
  ];

  useEffect(() => {
    getOrder(); // Get all orders
  }, []);

  /* it will give order list of respective supplier */
  const getOrder = () => {
    setIsLoading(true);
    const getuserData = window.localStorage.getItem("user");
    const userData = JSON.parse(getuserData);
    supplierOrderList(userData.supplier_id)
      .then((res) => {
        if (res.status === 200) {
          setOrderList(res.data);
          setOrderListFilter(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoading(false);
      });
  };

  /**
   * Function handleChangeStatus filter order data
   * @param  {selectedOption} selectedOption data Object
   */
  const handleChangeStatus = (selectedOption) => {
    if (selectedOption !== null) {
      setStatus(selectedOption);
      let filterOrderList = orderListFilter.filter((order) => {
        if (order.status === selectedOption.value) {
          return order;
        }
      });
      setOrderList(filterOrderList);
    } else {
      setStatus(null);
      getOrder();
    }
  };

  /**it will Open order Status Change Modal
   * @param  {values} values data Object
   */
  const handleModalOpen = (orderRow) => {
    setRowData(orderRow);
    setOrderUpdateModal(true);
  };

  /** it Will update the Status of order
   * @param  {values} values data Object
   */
  const updateOrder = (values, actions) => {
    setIsLoadingButton(true);
    orderStatus(values)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          setIsLoadingButton(false);
          setOrderUpdateModal(false);
          getOrder();
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoadingButton(false);
      });
  };

  /**  formate phone Number in US formate
   * @param phoneNumberString number
   */
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  return (
    <>
      <Breadcrumbs title="Orders" parent="" subtitle="Orders Admin Panel" />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Orders</h5>
                      </div>
                    </div>
                    <CardBody>
                      <ToolkitProvider
                        keyField="order_line_id"
                        data={orderList}
                        columns={columns}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div>
                            <div className="table_SearchBar d-flex align-items-center mb-3 flex-wrap">
                              <div
                                className="select_dropdown"
                                style={{ flex: 1 }}
                              >
                                <ul className="d-flex flex-wrap">
                                  <li className="mr-4">
                                    <div className="form-group mb-0">
                                      <Select
                                        value={status}
                                        onChange={handleChangeStatus}
                                        options={orderStatusList}
                                        isClearable={true}
                                        placeholder="Filter By Status"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="product_search"
                                style={{ marginRight: "15px" }}
                              >
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder="Search Order"
                                />
                              </div>

                              <div>
                                {isLoading !== true && (
                                  <ExportCSVButton
                                    {...props.csvProps}
                                    className="export_Button"
                                  >
                                    <i className="fa fa-download mr-2"></i>
                                    Download CSV
                                  </ExportCSVButton>
                                )}
                              </div>

                              {/* <Button
                                  type="button"
                                  color="secondary"
                                  data-toggle="modal"
                                  data-original-title="Download pdf"
                                  style={{width:200}}
                                  onClick={() =>
                                    generatePdfDocument(orderList,formatPhoneNumber)
                                  }
                                 
                                >
                                  Download Pdf
                               </Button> */}
                            </div>

                            {isLoading ? (
                              <div className="custom-loader">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              <BootstrapTable
                                {...props.baseProps}
                                striped
                                hover
                                condensed
                                noDataIndication={"No orders found"}
                                bordered={false}
                                pagination={paginationFactory({
                                  sizePerPage: 10,
                                  showTotal: true,
                                  hideSizePerPage: true,
                                })}
                                rowClasses="row-hover pointer-cursor"
                              />
                            )}
                          </div>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
      {orderUpdateModal && (
        <OrderUpdate
          orderUpdateModal={orderUpdateModal}
          onSubmit={updateOrder}
          isLoadingButton={isLoadingButton}
          rowData={rowData}
          handleModalClose={() => setOrderUpdateModal(false)}
        />
      )}
    </>
  );
};

export default SupplierOrdersList;
/* Copyright Information: Created by BuyGroup */
