/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import { supplierGroupList } from "../services/GroupServices";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categoryList } from "../services/ProductServices";
toast.configure();
export default class SupplierGroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGroupList: [],
      groupList: [],
      statusList: [
        { label: "Active", value: "Active" },
        { label: "Up Coming", value: "Upcoming" },
        { label: "Closed", value: "Closed" },
      ],
      initialValues: {
        offer_name: "",
        minimum_quantity: "",
        start_date: null,
        end_date: null,
        product_id: "",
        levels: [{ quantity: "", discount_percent: "" }],
        price: "",
        shipping_cost: "",
      },
      showAddScreen: false,
      productList: [],
      quantityList: [
        { label: "1-100", value: "1" },
        { label: "101-500", value: "101" },
        { label: "501-1000", value: "501" },
        { label: "1000-5000", value: "1000" },
        { label: "5000+", value: "5000" },
      ],
      status: "",
      isDisable: false,
      suppliersOptions: [],
      selectedSupplier: null,
      supplierId: "",
      showConfirmAlert: false,
      isDisableButton: false,
      groupId: "",
      categoryOptions: [],
      selectedCategory: null,
    };
  }

  componentDidMount() {
    this.getGroupList();
    //  this.getCategoryList()
  }
  // this function call api to get groups list
  getGroupList = () => {
    const getuserData = window.localStorage.getItem("user");
    const userData = JSON.parse(getuserData);
    this.setState({ isLoading: true });
    supplierGroupList(userData.supplier_id)
      .then((response) => {
        if (response.data !== undefined) {
          let categoryOptions = [];
          this.setState({
            allGroupList: response.data,
            groupList: response.data,
            isLoading: false,
          });
          response.data.map((cateogry) => {
            categoryOptions.push({
              label: cateogry.category,
              value: cateogry.category,
            });
          });

          let uniquecategory = categoryOptions[0];

          const output = categoryOptions.reduce(
            function (acc, curr) {
              let current = true;
              acc.map((acc1) => {
                if (acc1.label === curr.label) {
                  current = false;
                }
              });
              if (current) {
                acc.push(curr);
              }
              return acc;
            },
            [uniquecategory]
          );

          this.setState({
            categoryOptions: output,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };
  //this function is used for category list dropdown in  group List
  getCategoryList = () => {
    categoryList()
      .then((response) => {
        if (response.status === 200) {
          let categoryOptions = [];
          //here we are creating option for category selectsss
          response.data.map((cateogry) => {
            categoryOptions.push({
              label: cateogry.category_name,
              value: cateogry.category_name,
            });
          });
          this.setState({
            categoryOptions: categoryOptions,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };
  //formatter for group name
  nameFormatter = (cell, row) => (
    <Link to={{ pathname: "groups/view/" + row.id }}>
      <span>{cell}</span>
    </Link>
  );
  // this formatter is used for action
  actionFormatter = (cell, row) => {
    return (
      <Row className="group-action">
        <Link onClick={() => this.handleAddSupplier(row.id)}>
          <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
        </Link>
      </Row>
    );
  };

  //formatter for date
  dateFormatter = (cell, row) => <Moment format="MM/DD/YYYY">{cell}</Moment>;

  // this funciton used in cateogry change dropdown takes argument as {label:'',value:''}
  handleChangeCategory = (selectedOption) => {
    if (selectedOption !== null) {
      let filterdData = this.state.allGroupList.filter(
        (item) => item.category === selectedOption.value
      );
      this.setState({
        groupList: filterdData,
        selectedCategory: selectedOption,
      });
    } else {
      this.setState({
        groupList: this.state.allGroupList,
        selectedCategory: "",
      });
    }
  };

  render() {
    const { statusList, groupList } = this.state;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "offer_name",
        text: "Group Name",
        formatter: this.nameFormatter,
        headerClasses: "header-classes-product-name",
        classes: "column-classes-product-name",
      },
      {
        dataField: "category",
        text: "Category",
        formatter: (cell, row) => {
          return (
            <div className="category_name">
              <span>{cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "start_date",
        text: " Start Date",
        formatter: this.dateFormatter,
      },
      {
        dataField: "end_date",
        text: "End Date",
        formatter: this.dateFormatter,
      },
      {
        dataField: "price",
        text: " Price",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => {
          return (
            <div className="category_name no-next-line">
              <span> {cell && "$ " + cell}</span>
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Breadcrumbs title="Groups" parent="" subtitle="Groups" />
        <div className="container-fluid">
          <Row className="row">
            <Col md="12">
              <React.Fragment>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Groups</h5>
                        </div>
                      </div>
                      <CardBody>
                        {this.state.isLoading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <ToolkitProvider
                            keyField="ProductListTable"
                            data={groupList}
                            columns={columns}
                            search
                          >
                            {(props) => (
                              <div>
                                <div className="table_SearchBar d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                  <div className="select_dropdown">
                                    <ul className="d-flex flex-wrap">
                                      <li className="mr-4">
                                        <div className="form-group mb-0">
                                          <Select
                                            value={this.state.selectedCategory}
                                            onChange={this.handleChangeCategory}
                                            options={this.state.categoryOptions}
                                            placeholder="Filter By Category"
                                            isClearable={true}
                                          />
                                        </div>
                                      </li>
                                      <li></li>
                                    </ul>
                                  </div>
                                  <div className="product_search">
                                    <SearchBar
                                      {...props.searchProps}
                                      className="custome-search-table"
                                      placeholder="Search"
                                    />
                                  </div>
                                </div>

                                <BootstrapTable
                                  {...props.baseProps}
                                  striped
                                  hover
                                  condensed
                                  bordered={false}
                                  noDataIndication={"No groups available"}
                                  pagination={paginationFactory({
                                    sizePerPage: 10,
                                    showTotal: true,
                                    hideSizePerPage: true,
                                  })}
                                  rowClasses="row-hover"
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */