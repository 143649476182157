import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
const RejectCustomerDocument = (props) => {
  const {
    documentData,
    openRejectModal,
    closeRejectModal,
    rejectDocument,
    RejectModalOpenState,
  } = props;
  console.log("openRejectModal", openRejectModal);
  console.log("closeRejectModal", closeRejectModal);
  const onSubmit = (data) => {
    const doc_data = {
      doc_id: documentData.doc_id,
      reason: data.reason,
    };
    console.log("data", doc_data);
    rejectDocument(doc_data);
  };

  return (
    <Modal isOpen={RejectModalOpenState} style={{ overlay: { opacity: 0.1 } }}>
      <ModalHeader toggle={RejectModalOpenState}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Reject Document
        </h5>
      </ModalHeader>

      <Formik
        initialValues={{ reason: "" }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required(
            "Please provide the reason to reject the document"
          ),
        })}
      >
        {({
          setFieldValue,
          handleBlur,
          errors,
          touched,
          values,
          setFieldTouched,
          handleChange,
        }) => (
          <Form>
            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="col-form-label">File Name</Label>
                    <span className="d-flex">{documentData?.doc_filename}</span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Reason to Reject the Document
                    </Label>
                    <div className="d-block">
                      <textarea
                        className="form-control"
                        name="reason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="err-msg">
                        {errors.reason && touched.reason && errors.reason}
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Button color="primary" type="submit">
                Reject
              </Button>
              <Button type="button" color="light" onClick={closeRejectModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RejectCustomerDocument;
