/* Copyright Information: Created by BuyGroup */
export const CustomerData = {
  customerDetails: {
    customer_name: "Ashutosh Pandya",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, ",
    phone: "8989402564",
    email: "ashutosh.pandya@sofmen.com",
    address: "122/2 vijay nagar indore",
    customer_img:
      "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
  },
  orders: [
    {
      product_img: "https://homepages.cae.wisc.edu/~ece533/images/barbara.png",
      product_name: "Product Name 1",
      price: "$ 50",
      status: "processed",
      product_discription:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      product_img: "https://homepages.cae.wisc.edu/~ece533/images/monarch.png",
      product_name: "Product Name 2",
      price: "$ 25",
      status: "processed",
      product_discription:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      product_img: "https://homepages.cae.wisc.edu/~ece533/images/frymire.png",
      product_name: "Product Name 3",
      price: "$ 65",
      status: "processed",
      product_discription:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
  ],
};

export default {
  CustomerData,
};
/* Copyright Information: Created by BuyGroup */