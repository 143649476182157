/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "./ValidationSchema";
import { City } from "country-state-city";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import trimObj from "../components/common/TrimData";
import PhoneInput from "react-phone-number-input/input";

import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap";

const AddSupplier = (props) => {
  const {
    addSupplierModal,
    initialValues,
    addNewSupplierhandler,
    closeAddSupplierModal,
    isLoadingButton,
  } = props;

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store  selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store  selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store  selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState();

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  /* const "businessAddressCountriesValue" store   businessAddress selected country values */
  const [
    businessAddressCountriesValue,
    setBusinessAddressCountriesValue,
  ] = useState();

  /* const "businessAddressFilterStates" store all state of businessAddress selected country */
  const [
    businessAddressFilterStates,
    setBusinessAddressFilterStates,
  ] = useState([]);

  /* const "businessAddressStatesValues" store  businessAddress selected state values */
  const [
    businessAddressStatesValues,
    setBusinessAddressStatesValues,
  ] = useState();

  /* const "businessAddressFilterCity" store all state of businessAddress selected country */
  const [businessAddressFilterCity, setBusinessAddressFilterCity] = useState(
    []
  );

  /* const "businessAddressCityValues" store businessAddress selected city Values  */
  const [businessAddressCityValues, setBusinessAddressCityValues] = useState();

  /* const "businessAddressStreetValues" store businessAddress selected city Values  */
  const [
    businessAddressStreetValues,
    setBusinessAddressStreetValues,
  ] = useState();

  /* const "businessAddressCityValues" store businessAddress selected city Values  */
  const [
    businessAddressZipCodeValues,
    setBusinessAddressZipCodeValues,
  ] = useState();

  /* const "businessAddressPhoneNumber" store  businessAddress selected phone Value   */
  const [
    businessAddressPhoneNumber,
    setBusinessAddressPhoneNumber,
  ] = useState();

  /* const "noDataBusinessAddressStateMessage" show status of BusinessAddress state   */
  const [
    noDataBusinessAddressStateMessage,
    setNoDataBusinessAddressStateMessage,
  ] = useState(false);

  /* const "noDataBusinessAddressCityMessage" show status of BusinessAddress city   */
  const [
    noDataBusinessAddressCityMessage,
    setNoDataBusinessAddressCityMessage,
  ] = useState(false);

  /* const "shippingAddressCountriesValue" store shippingAddress selected country values */
  const [
    shippingAddressCountriesValue,
    setShippingAddressCountriesValue,
  ] = useState();

  /* const "shippingAddressFilterStates" store all state of shippingAddress selected country */
  const [
    shippingAddressFilterStates,
    setShippingAddressFilterStates,
  ] = useState([]);

  /* const "shippingAddressStatesValues" store  shippingAddress selected state values */
  const [
    shippingAddressStatesValues,
    setShippingAddressStatesValues,
  ] = useState();

  /* const "shippingAddressFilterCity" store shippingAddress state of selected country */
  const [shippingAddressFilterCity, setShippingAddressFilterCity] = useState(
    []
  );

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [shippingAddressCityValues, setShippingAddressCityValues] = useState();

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [
    shippingAddressStreetValues,
    setShippingAddressStreetValues,
  ] = useState();

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [
    shippingAddressZipCodeValues,
    setShippingAddressZipCodeValues,
  ] = useState();

  /* const "shippingAddressPhoneNumber" store shippingAddress selected phone Value   */
  const [
    shippingAddressPhoneNumber,
    setShippingAddressPhoneNumber,
  ] = useState();

  /* const "noDataShippingAddressStateMessage" show status of state   */
  const [
    noDataShippingAddressStateMessage,
    setNoDataShippingAddressStateMessage,
  ] = useState(false);

  /* const "noDataShippingAddressCityMessage" show status of city   */
  const [
    noDataShippingAddressCityMessage,
    setNoDataShippingAddressCityMessage,
  ] = useState(false);

  /* const "checkedValue" store checkbox value  */
  const [checkedValue, setcheckedValue] = useState(true);

  const empty_address = {
    phone_number: "",
    country: "",
    state: "",
    city: "",
    street: "",
    zip_code: "",
  };

  useEffect(() => {
    getCountryList();
  }, []);

  /* This function will give list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /** / This  function will give  all the state from selected Country in Billing address
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("billing_address.country", selectedCountry.label);
    setFieldTouched("billing_address.country", false);
    setFieldValue("billing_address.state", "");
    setFieldValue("billing_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This  function will give  all the city from selected state in billing address
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("billing_address.state", selectedState.label);
    setFieldValue("billing_address.city", "");
    setFieldTouched("billing_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This function handle city value in billing address
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("billing_address.city", selectCity.label);
    setFieldTouched("billing_address.city", false);
    setFieldValue("billing_address.billing_address_select", "true");
  };
  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */
  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("billing_address.phone_number", selectphone);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /* This Function handle checkbox  */
  const handleCheckbox = (e, setFieldValue) => {
    const newcheckedvalue = JSON.parse(e.target.value);
    setcheckedValue(!newcheckedvalue);
    if (e.target.value === "true") {
      setFieldValue("billing_address.billing_address_select", "true");
      setFieldValue("shipping_address.shipping_address_select", "true");
    }
    // if(!newcheckedvalue===false){
    //   Object.assign(initialValues.business_address,empty_address)
    //   Object.assign(initialValues.shipping_address,empty_address)
    // }
  };

  /** / This function handle phone value of business address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */
  const handleBusinessAddressPhoneNumber = (selectphone, setFieldValue) => {
    setBusinessAddressPhoneNumber(selectphone);
    setFieldValue("business_address.phone_number", selectphone);
  };

  /** / This  function will give  all the state from selected Country Business address
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleBusinessAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCountriesValue(selectedCountry);
    setFieldValue("business_address.country", selectedCountry.label);
    setFieldTouched("business_address.country", false);
    setFieldValue("business_address.state", "");
    setFieldValue("business_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setBusinessAddressFilterStates(stateOptions);
    setBusinessAddressFilterCity([]);
    setNoDataBusinessAddressStateMessage(true);
    setNoDataBusinessAddressCityMessage(false);
    setBusinessAddressStatesValues("");
    setBusinessAddressCityValues("");
  };

  /** / This  function will give  all the city from selected state of business address
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressStatesValues(selectedState);
    setFieldValue("business_address.state", selectedState.label);
    setFieldValue("business_address.city", "");
    setFieldTouched("business_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === businessAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setBusinessAddressFilterCity(cityOptions);
    setBusinessAddressCityValues("");
    setNoDataBusinessAddressCityMessage(true);
  };

  /** / This function handle city value of business address
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCityValues(selectCity);
    setFieldValue("business_address.city", selectCity.label);
    setFieldTouched("business_address.city", false);
  };

  const handleBusinessAddressStreet = (e, setFieldValue) => {
    setBusinessAddressStreetValues(e.target.value);
    setFieldValue("business_address.street", e.target.value);
  };

  const handleBusinessAddressZipCode = (e, setFieldValue) => {
    setBusinessAddressZipCodeValues(e.target.value);
    setFieldValue("business_address.zip_code", e.target.value);
  };

  /** / This function handle phone value of shipping address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */
  const handleShippingAddressPhoneNumber = (selectphone, setFieldValue) => {
    setShippingAddressPhoneNumber(selectphone);
    setFieldValue("shipping_address.phone_number", selectphone);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCountriesValue(selectedCountry);
    setFieldValue("shipping_address.country", selectedCountry.label);
    setFieldTouched("shipping_address.country", false);
    setFieldValue("shipping_address.state", "");
    setFieldValue("shipping_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setShippingAddressFilterStates(stateOptions);
    setShippingAddressFilterCity([]);
    setNoDataShippingAddressStateMessage(true);
    setNoDataShippingAddressCityMessage(false);
    setShippingAddressStatesValues("");
    setShippingAddressCityValues("");
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This  function will give  all the city from selected state of shipping address
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressStatesValues(selectedState);
    setFieldValue("shipping_address.state", selectedState.label);
    setFieldValue("shipping_address.city", "");
    setFieldTouched("shipping_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === shippingAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setShippingAddressFilterCity(cityOptions);
    setShippingAddressCityValues("");
    setNoDataShippingAddressCityMessage(true);

    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This function handle city value shipping address
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCityValues(selectCity);
    setFieldValue("shipping_address.city", selectCity.label);
    setFieldTouched("shipping_address.city", false);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressStreet = (e, setFieldValue) => {
    setShippingAddressStreetValues(e.target.value);
    setFieldValue("shipping_address.street", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressZipCode = (e, setFieldValue) => {
    setShippingAddressZipCodeValues(e.target.value);
    setFieldValue("shipping_address.zip_code", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /**
   * This function send New Suppliers data
   * @param  {newSupplierData} newSupplierData data Object
   */
  const addOnSubmit = (newSupplierData) => {
    
    let supplierData = JSON.parse(JSON.stringify(newSupplierData));

    if (checkedValue === true) {
      Object.assign(
        supplierData.billing_address,
        supplierData.business_address
      );
      Object.assign(
        supplierData.shipping_address,
        supplierData.business_address
      );
    }
    delete supplierData.shipping_address.shipping_address_select;
    delete supplierData.billing_address.billing_address_select;

    if (supplierData.billing_address.phone_number === "") {
      delete supplierData.billing_address;
    }
    if (supplierData.shipping_address.phone_number === "") {
      delete supplierData.shipping_address;
    }

    newSupplierData = trimObj(newSupplierData);
    addNewSupplierhandler(supplierData);
  };

  /* This function closed Add Supplier Modal */
  const closeHandler = () => {
    closeAddSupplierModal();
  };

  return (
    <Modal
      isOpen={addSupplierModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Supplier
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={addOnSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, handleBlur, values, setFieldTouched, errors }) => (
          <Form>
            <ModalBody>
              <h4>Business Address</h4>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Company Name
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  name="company_name"
                />
                <ErrorMessage name="company_name">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Email
                </Label>

                <Field type="email" className="form-control" name="email" />

                <ErrorMessage name="email">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Title
                </Label>

                <Field type="text" className="form-control" name="title" />
                <ErrorMessage name="title">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Primary Contact Name
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  name="primary_contact_name"
                />

                <ErrorMessage name="primary_contact_name">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Phone
                </Label>

                <PhoneInput
                  country="US"
                  id="business_address.phone_number"
                  value={businessAddressPhoneNumber}
                  name="business_address.phone_number"
                  onBlur={handleBlur}
                  maxLength={14}
                  className="form-control"
                  onChange={(value) =>
                    handleBusinessAddressPhoneNumber(value, setFieldValue)
                  }
                />
                <ErrorMessage name="business_address.phone_number">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Country
                </Label>

                <Select
                  value={businessAddressCountriesValue}
                  noOptionsMessage={() => "No country found"}
                  onChange={(selectCountry) =>
                    handleBusinessAddressCountry(
                      selectCountry,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={countries}
                  name="business_address.country"
                  placeholder="Select Country"
                />

                <ErrorMessage name="business_address.country">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  State
                </Label>

                <Select
                  value={businessAddressStatesValues}
                  noOptionsMessage={() =>
                    noDataBusinessAddressStateMessage
                      ? "No State Found"
                      : "First select country"
                  }
                  onChange={(value) =>
                    handleBusinessAddressState(
                      value,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={businessAddressFilterStates}
                  name="business_address.state"
                  placeholder="Select State"
                  className="Select"
                />

                <ErrorMessage name="business_address.state">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  City
                </Label>

                <Select
                  value={businessAddressCityValues}
                  noOptionsMessage={() =>
                    noDataBusinessAddressCityMessage
                      ? "No City Found"
                      : "First select state"
                  }
                  onChange={(value) =>
                    handleBusinessAddressCity(
                      value,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={businessAddressFilterCity}
                  name="business_address.city"
                  placeholder="Select City"
                />

                <ErrorMessage name="business_address.city">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Street
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  name="business_address.street"
                  value={businessAddressStreetValues}
                  onChange={(e) =>
                    handleBusinessAddressStreet(e, setFieldValue)
                  }
                />

                <ErrorMessage name="business_address.street">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Zip Code
                </Label>
                <Field
                  type="text"
                  value={businessAddressZipCodeValues}
                  className="form-control"
                  name="business_address.zip_code"
                  onChange={(e) =>
                    handleBusinessAddressZipCode(e, setFieldValue)
                  }
                />
                <ErrorMessage name="business_address.zip_code">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Input
                  type="checkbox"
                  value={checkedValue}
                  checked={checkedValue}
                  onChange={(e) => handleCheckbox(e, setFieldValue)}
                  style={{ marginLeft: "4px" }}
                />
                <label style={{ marginLeft: "25px", marginTop: "1px" }}>
                  Shipping address and Billing address are
                  same as Business address
                </label>
              </FormGroup>
              {checkedValue === false && (
                <div>
                  <h4>Shipping Address</h4>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Phone
                    </Label>

                    <PhoneInput
                      country="US"
                      value={shippingAddressPhoneNumber}
                      name="shipping_address.phone_number"
                      onBlur={handleBlur}
                      maxLength={14}
                      className="form-control"
                      onChange={(value) =>
                        handleShippingAddressPhoneNumber(value, setFieldValue)
                      }
                    />
                    <ErrorMessage name="shipping_address.phone_number">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Country
                    </Label>

                    <Select
                      value={shippingAddressCountriesValue}
                      noOptionsMessage={() => "No country found"}
                      onChange={(selectCountry) =>
                        handleShippingAddressCountry(
                          selectCountry,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={countries}
                      name="shipping_address.country"
                      placeholder="Select Country"
                    />

                    <ErrorMessage name="shipping_address.country">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      State
                    </Label>

                    <Select
                      value={shippingAddressStatesValues}
                      noOptionsMessage={() =>
                        noDataBusinessAddressStateMessage
                          ? "No State Found"
                          : "First select country"
                      }
                      onChange={(value) =>
                        handleShippingAddressState(
                          value,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={shippingAddressFilterStates}
                      name="shipping_address.state"
                      placeholder="Select State"
                      className="Select"
                    />

                    <ErrorMessage name="shipping_address.state">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      City
                    </Label>

                    <Select
                      value={shippingAddressCityValues}
                      noOptionsMessage={() =>
                        noDataBusinessAddressCityMessage
                          ? "No City Found"
                          : "First select state"
                      }
                      onChange={(value) =>
                        handleShippingAddressCity(
                          value,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={shippingAddressFilterCity}
                      name="shipping_address.city"
                      placeholder="Select City"
                    />

                    <ErrorMessage name="shipping_address.city">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Street
                    </Label>

                    <Field
                      type="text"
                      value={shippingAddressStreetValues}
                      className="form-control"
                      name="shipping_address.street"
                      onChange={(e) =>
                        handleShippingAddressStreet(e, setFieldValue)
                      }
                    />

                    <ErrorMessage name="shipping_address.street">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Zip Code
                    </Label>
                    <Field
                      type="text"
                      value={shippingAddressZipCodeValues}
                      className="form-control"
                      name="shipping_address.zip_code"
                      onChange={(e) =>
                        handleShippingAddressZipCode(e, setFieldValue)
                      }
                    />
                    <ErrorMessage name="shipping_address.zip_code">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                  <h4>Billing Address</h4>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Phone
                    </Label>

                    <PhoneInput
                      country="US"
                      value={phoneValue}
                      name="billing_address.phone_number"
                      onBlur={handleBlur}
                      maxLength={14}
                      className="form-control"
                      onChange={(value) =>
                        handlePhoneNumber(value, setFieldValue)
                      }
                    />
                    <ErrorMessage name="billing_address.phone_number">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Country
                    </Label>

                    <Select
                      value={countriesValue}
                      noOptionsMessage={() => "No country found"}
                      onChange={(selectCountry) =>
                        handleCountry(
                          selectCountry,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={countries}
                      name="billing_address.country"
                      placeholder="Select Country"
                    />

                    <ErrorMessage name="billing_address.country">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      State
                    </Label>

                    <Select
                      value={statesValues}
                      noOptionsMessage={() =>
                        noDataStateMessage
                          ? "No State Found"
                          : "First select country"
                      }
                      onChange={(value) =>
                        handleState(value, setFieldValue, setFieldTouched)
                      }
                      options={filterStates}
                      name="billing_address.state"
                      placeholder="Select State"
                      className="Select"
                    />

                    <ErrorMessage name="billing_address.state">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      City
                    </Label>

                    <Select
                      value={cityValues}
                      noOptionsMessage={() =>
                        noDataCityMessage
                          ? "No City Found"
                          : "First select state"
                      }
                      onChange={(value) =>
                        handleCity(value, setFieldValue, setFieldTouched)
                      }
                      options={filterCity}
                      name="billing_address.city"
                      placeholder="Select City"
                    />

                    <ErrorMessage name="billing_address.city">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Street
                    </Label>

                    <Field
                      type="text"
                      className="form-control"
                      name="billing_address.street"
                    />

                    <ErrorMessage name="billing_address.street">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Zip Code
                    </Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="billing_address.zip_code"
                    />
                    <ErrorMessage name="billing_address.zip_code">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button color="primary" type="submit" disabled={isLoadingButton}>
                {isLoadingButton ? "Please Wait..." : "Add"}
              </Button>

              <Button type="button" color="light" onClick={closeHandler}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddSupplier;
/* Copyright Information: Created by BuyGroup */
