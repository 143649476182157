/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const login = (user) => {
  return Api.post("user/login", user).then((res) => res.data);
};

export const forgotPassword = (userData) => {
  return Api.post("user/forgotPassword", userData).then((res) => res.data);
};

export const resetPassword = (userData) => {
  return Api.put("user/resetPassword", userData).then((res) => res.data);
};

export const tokenExpire = (token) => {
  return Api.post("user/check/token-expire", token).then((res) => res.data);
};

export const changePassword = (userData) => {
  return Api.put("user/changePassword", userData).then((res) => res.data);
};

export const VerifyUserAccount = (userData) => {
  return Api.put("user/verify", userData).then((res) => res.data);
};
/* Copyright Information: Created by BuyGroup */
