/* Copyright Information: Created by BuyGroup */
import Api from "../Api";

export const supplierList = () => {
  return Api.get("supplier")
    .then((res) => res.data)
    .catch((err) => console.log("error in supplier list  data ", err));
};

export const categoryList = () => {
  return Api.get("category")
    .then((res) => res.data)
    .catch((err) => console.log("error in category list data ", err));
};

export const productList = (data) => {
  return Api.post("getProduct", data).then((res) => res.data);
};

export const createProduct = (data) => {
  return Api.post("product", data).then((res) => res.data);
};

export const editProduct = (data) => {
  return Api.put("product", data).then((res) => res.data);
};

export const getProductDetail = (id) => {
  return Api.get("product/" + id)
    .then((res) => res.data)
    .catch((err) => console.log("error in get product detail ", err));
};

export const deleteProduct = (id) => {
  return Api.post("deleteProduct", id).then((res) => res.data);
};

export const getSubCategoryDetail = (id) => {
  return Api.get(`category/${id}`)
    .then((res) => res.data)
    .catch((err) => console.log("error in get subcategory detail ", err));
};
/* Copyright Information: Created by BuyGroup */
