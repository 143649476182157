/* Copyright Information: Created by BuyGroup */
import * as Yup from "yup";
const validationSchema = Yup.object({
  company_name: Yup.string().required("Please enter company name"),
  title: Yup.string().required("Please enter title"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email"),

  primary_contact_name: Yup.string().required(
    "Please enter primary contact name"
  ),
  business_address: Yup.object({
    phone_number: Yup.string()
      .max(15, "invalid phone number")
      .required("Please enter phone number"),
    street: Yup.string().required("Please enter street"),
    city: Yup.string().required("Please select city"),
    state: Yup.string().required("Please select state"),
    country: Yup.string().required("Please select country"),
    zip_code: Yup.number()
      .typeError("Please enter correct zip code")
      .positive()
      .required("Please enter zip code"),
  }),
  billing_address: Yup.object({
    billing_address_select: Yup.boolean(),
    phone_number: Yup.string().when(
      "billing_address_select",
      (phone_number) => {
        if (phone_number)
          return Yup.string()
            .max(15, "invalid phone number")
            .required("Please enter phone number");
      }
    ),
    street: Yup.string().when("billing_address_select", (street) => {
      if (street) return Yup.string().required("Please enter street");
    }),
    state: Yup.string().when("billing_address_select", (state) => {
      if (state) return Yup.string().required("Please enter state");
    }),
    country: Yup.string().when("billing_address_select", (country) => {
      if (country) return Yup.string().required("Please enter country");
    }),
    city: Yup.string().when("billing_address_select", (city) => {
      if (city) return Yup.string().required("Please enter city");
    }),
    zip_code: Yup.string().when("billing_address_select", (zip_code) => {
      if (zip_code) return Yup.string().required("Please enter zip code ");
    }),
  }),
  shipping_address: Yup.object({
    shipping_address_select: Yup.boolean(),
    phone_number: Yup.string().when(
      "shipping_address_select",
      (phone_number) => {
        if (phone_number)
          return Yup.string()
            .max(15, "invalid phone number")
            .required("Please enter phone number");
      }
    ),
    street: Yup.string().when("shipping_address_select", (street) => {
      if (street) return Yup.string().required("Please enter street");
    }),
    state: Yup.string().when("shipping_address_select", (state) => {
      if (state) return Yup.string().required("Please enter state ");
    }),
    country: Yup.string().when("shipping_address_select", (country) => {
      if (country) return Yup.string().required("Please enter country ");
    }),
    city: Yup.string().when("shipping_address_select", (city) => {
      if (city) return Yup.string().required("Please enter cityy ");
    }),
    zip_code: Yup.string().when("shipping_address_select", (zip_code) => {
      if (zip_code) return Yup.string().required("Please enter zip code ");
    }),
  }),
});
export default validationSchema;
/* Copyright Information: Created by BuyGroup */
