/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "./ValidationSchema";
import { City } from "country-state-city";
import CountryListJson from "../Json/CountryListJson";
import StateListJson from "../Json/StateListJson";
import Select from "react-select";
import PhoneInput from "react-phone-number-input/input";
import trimObj from "../components/common/TrimData";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap";

const EditSupplier = (props) => {
  const {
    editSupplierModal,
    initialValues,
    updateSupplierRow,
    closeEditSupplierModal,
    isLoadingButton,
  } = props;

  console.log("initialValues",initialValues)

  /* const "allCountryList" store all countrys data */
  const allCountryList = CountryListJson;

  /* const "allStateList" store all states data */
  const allStateList = StateListJson;

  /* const "allCityList" store all citys data */
  const allCityList = City.getAllCities();

  /* const "countries" store all countries data */
  const [countries, setCountries] = useState([]);

  /* const "countriesValue" store all selected country values */
  const [countriesValue, setCountriesValue] = useState();

  /* const "filterStates" store all state of selected country */
  const [filterStates, setFilterStates] = useState([]);

  /* const "statesValues" store all selected state values */
  const [statesValues, setStatesValues] = useState();

  /* const "filterCity" store all state of selected country */
  const [filterCity, setFilterCity] = useState([]);

  /* const "cityValues" store all selected city Values  */
  const [cityValues, setCityValues] = useState();

  /* const "phoneValue" store all selected phone Value   */
  const [phoneValue, setPhoneValue] = useState(
    initialValues.billing_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [noDataStateMessage, setNoDataStateMessage] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [noDataCityMessage, setNoDataCityMessage] = useState(false);

  /* const "countriesValue" store all selected country values */
  const [
    businessAddressCountriesValue,
    setBusinessAddressCountriesValue,
  ] = useState();

  /* const "filterStates" store all state of selected country */
  const [
    businessAddressFilterStates,
    setBusinessAddressFilterStates,
  ] = useState([]);

  /* const "statesValues" store all selected state values */
  const [
    businessAddressStatesValues,
    setBusinessAddressStatesValues,
  ] = useState();

  /* const "filterCity" store all state of selected country */
  const [businessAddressFilterCity, setBusinessAddressFilterCity] = useState(
    []
  );

  /* const "cityValues" store all selected city Values  */
  const [businessAddressCityValues, setBusinessAddressCityValues] = useState();

  /* const "businessAddressStreetValues" store businessAddress selected city Values  */
  const [
    businessAddressStreetValues,
    setBusinessAddressStreetValues,
  ] = useState(initialValues.business_address?.street);

  /* const "businessAddressCityValues" store businessAddress selected city Values  */
  const [
    businessAddressZipCodeValues,
    setBusinessAddressZipCodeValues,
  ] = useState(initialValues.business_address?.zip_code);

  /* const "phoneValue" store all selected phone Value   */
  const [businessAddressPhoneNumber, setBusinessAddressPhoneNumber] = useState(
    initialValues.business_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [
    noDataBusinessAddressStateMessage,
    setNoDataBusinessAddressStateMessage,
  ] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [
    noDataBusinessAddressCityMessage,
    setNoDataBusinessAddressCityMessage,
  ] = useState(false);

  /* const "countriesValue" store all selected country values */
  const [
    shippingAddressCountriesValue,
    setShippingAddressCountriesValue,
  ] = useState();

  /* const "filterStates" store all state of selected country */
  const [
    shippingAddressFilterStates,
    setShippingAddressFilterStates,
  ] = useState([]);

  /* const "statesValues" store all selected state values */
  const [
    shippingAddressStatesValues,
    setShippingAddressStatesValues,
  ] = useState();

  /* const "filterCity" store all state of selected country */
  const [shippingAddressFilterCity, setShippingAddressFilterCity] = useState(
    []
  );

  /* const "cityValues" store all selected city Values  */
  const [shippingAddressCityValues, setShippingAddressCityValues] = useState();

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [
    shippingAddressStreetValues,
    setShippingAddressStreetValues,
  ] = useState(initialValues.shipping_address?.street);

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [
    shippingAddressZipCodeValues,
    setShippingAddressZipCodeValues,
  ] = useState(initialValues.shipping_address?.zip_code);

   /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
   const [
    billingAddressStreetValues,
    setBillingAddressStreetValues,
  ] = useState(initialValues.billing_address?.street);

  /* const "shippingAddressCityValues" store shippingAddress  selected city Values  */
  const [
    billingAddressZipCodeValues,
    setBillingAddressZipCodeValues,
  ] = useState(initialValues.billing_address?.zip_code);

  /* const "phoneValue" store all selected phone Value   */
  const [shippingAddressPhoneNumber, setShippingAddressPhoneNumber] = useState(
    initialValues.shipping_address?.phone_number
  );

  /* const "noDataStateMessage" show status of state   */
  const [
    noDataShippingAddressStateMessage,
    setNoDataShippingAddressStateMessage,
  ] = useState(false);

  /* const "noDataCityMessage" show status of city   */
  const [
    noDataShippingAddressCityMessage,
    setNoDataShippingAddressCityMessage,
  ] = useState(false);

  /* const "checkedValue" store checkbox value  */
  const [checkedValue, setcheckedValue] = useState(
    initialValues.business_address?.zip_code ===
      initialValues.shipping_address?.zip_code
      ? true
      : false
  );

  useEffect(() => {
    getCountryList();
    getBillingAddressStateList();
    getBillingAddressCityList();
    if (initialValues.business_address?.phone_number) {
      getBusinessAddressStateList();
      getBusinessAddressCityList();
    }
    if (initialValues.shipping_address?.phone_number) {
      getShippingAddressStateList();
      getShippingAddressCityList();
    }
  }, []);

  /* This function will give  list of all the Country */
  const getCountryList = () => {
    let countryOptions = allCountryList.map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountries(countryOptions);
  };

  /* This function will  set all state for default country  */

  const getBillingAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.billing_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    setCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.billing_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getBillingAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.billing_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.billing_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0]?.isoCode;

    setStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.billing_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setFilterCity(cityOptions);
  };

  /* This function will  set all state for default country  */

  const getBusinessAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.business_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    setBusinessAddressCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.business_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setBusinessAddressFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getBusinessAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.business_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.business_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0]?.isoCode;

    setBusinessAddressStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.business_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setBusinessAddressFilterCity(cityOptions);
  };
  /* This function will  set all state for default country  */

  const getShippingAddressStateList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.shipping_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    setShippingAddressCountriesValue({
      value: defaultCountryIsoCode,
      label: initialValues.shipping_address?.country,
    });

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === defaultCountryIsoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setShippingAddressFilterStates(stateOptions);
  };

  /* This function will  set all city for default state */
  const getShippingAddressCityList = () => {
    const getdefaultCountry = allCountryList.filter((country) => {
      if (country.name === initialValues.shipping_address?.country) {
        return country;
      }
    });

    const defaultCountryIsoCode = getdefaultCountry[0]?.isoCode;

    const getdefaultState = allStateList.filter((state) => {
      if (
        initialValues.shipping_address?.state === state.name &&
        defaultCountryIsoCode === state.countryCode
      ) {
        return state;
      }
    });

    const defaultstateIsoCode = getdefaultState[0]?.isoCode;

    setShippingAddressStatesValues({
      vlaue: defaultstateIsoCode,
      label: initialValues.shipping_address?.state,
    });

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === defaultstateIsoCode &&
          city.countryCode === defaultCountryIsoCode
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    const cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setShippingAddressFilterCity(cityOptions);
  };

  /** / This function handle phone value
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   *
   */

  const handlePhoneNumber = (selectphone, setFieldValue) => {
    setPhoneValue(selectphone);
    setFieldValue("billing_address.phone_number", selectphone);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This  function will give  all the state from selected Country
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleCountry = (selectedCountry, setFieldValue, setFieldTouched) => {
    setCountriesValue(selectedCountry);
    setFieldValue("billing_address.country", selectedCountry.label);
    setFieldTouched("billing_address.country", false);
    setFieldValue("billing_address.state", "");
    setFieldValue("billing_address.city", "");

    const countryisoCode = selectedCountry.value;

    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });

    setFilterStates(stateOptions);
    setFilterCity([]);
    setNoDataStateMessage(true);
    setNoDataCityMessage(false);
    setStatesValues("");
    setCityValues("");
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This  function will give  all the city from selected state
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleState = (selectedState, setFieldValue, setFieldTouched) => {
    setStatesValues(selectedState);
    setFieldValue("billing_address.state", selectedState.label);
    setFieldValue("billing_address.city", "");
    setFieldTouched("billing_address.state", false);

    const stateisoCode = selectedState.value;

    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === countriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let cityOptions = cityOfState.map((city) => {
      return {
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });
    setFilterCity(cityOptions);
    setCityValues("");
    setNoDataCityMessage(true);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */

  const handleCity = (selectCity, setFieldValue, setFieldTouched) => {
    setCityValues(selectCity);
    setFieldValue("billing_address.city", selectCity.label);
    setFieldTouched("billing_address.city", false);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  /* This Function handle checkbox  */
  const handleCheckbox = (e, setFieldValue) => {
    const newcheckedvalue = JSON.parse(e.target.value);
    setcheckedValue(!newcheckedvalue);
    
  };

  /** / This function handle phone value of business address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */

  const handleBusinessAddressPhoneNumber = (selectphone, setFieldValue) => {
    setBusinessAddressPhoneNumber(selectphone);
    setFieldValue("business_address.phone_number", selectphone);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This  function will give  all the state from selected Country Business address
   * @param  {selectedCountry} selectedCountry data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCountriesValue(selectedCountry);
    setFieldValue("business_address.country", selectedCountry.label);
    setFieldTouched("business_address.country", false);
    setFieldValue("business_address.state", "");
    setFieldValue("business_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setBusinessAddressFilterStates(stateOptions);
    setBusinessAddressFilterCity([]);
    setNoDataBusinessAddressStateMessage(true);
    setNoDataBusinessAddressCityMessage(false);
    setBusinessAddressStatesValues("");
    setBusinessAddressCityValues("");
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This  function will give  all the city from selected state BusinessAddress
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressStatesValues(selectedState);
    setFieldValue("business_address.state", selectedState.label);
    setFieldValue("business_address.city", "");
    setFieldTouched("business_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === businessAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setBusinessAddressFilterCity(cityOptions);
    setBusinessAddressCityValues("");
    setNoDataBusinessAddressCityMessage(true);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This function handle city value
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleBusinessAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setBusinessAddressCityValues(selectCity);
    setFieldValue("business_address.city", selectCity.label);
    setFieldTouched("business_address.city", false);
    setFieldValue("business_address.business_address_select", "true");
  };

  const handleBusinessAddressStreet = (e, setFieldValue) => {
    setBusinessAddressStreetValues(e.target.value);
    setFieldValue("business_address.street", e.target.value);
    setFieldValue("business_address.business_address_select", "true");
  };

  const handleBusinessAddressZipCode = (e, setFieldValue) => {
    setBusinessAddressZipCodeValues(e.target.value);
    setFieldValue("business_address.zip_code", e.target.value);
    setFieldValue("business_address.business_address_select", "true");
  };

  /** / This function handle phone value shipping address
   * @param  {selectphone} selectphone numericvalue with country code
   * @param  {setFieldValue} setFieldValue function
   */
  const handleShippingAddressPhoneNumber = (
    selectphone,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressPhoneNumber(selectphone);
    setFieldValue("shipping_address.phone_number", selectphone);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressCountry = (
    selectedCountry,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCountriesValue(selectedCountry);
    setFieldValue("shipping_address.country", selectedCountry.label);
    setFieldTouched("shipping_address.country", false);
    setFieldValue("shipping_address.state", "");
    setFieldValue("shipping_address.city", "");
    const countryisoCode = selectedCountry.value;
    const StateOfCountry = allStateList
      .filter((state) => {
        if (state.countryCode === countryisoCode) {
          return state;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    let stateOptions = StateOfCountry.map((state) => {
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setShippingAddressFilterStates(stateOptions);
    setShippingAddressFilterCity([]);
    setNoDataShippingAddressStateMessage(true);
    setNoDataShippingAddressCityMessage(false);
    setShippingAddressStatesValues("");
    setShippingAddressCityValues("");
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This  function will give  all the city from selected state in shipping address
   * @param  {selectedState} selectedState data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressState = (
    selectedState,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressStatesValues(selectedState);
    setFieldValue("shipping_address.state", selectedState.label);
    setFieldValue("shipping_address.city", "");
    setFieldTouched("shipping_address.state", false);
    const stateisoCode = selectedState.value;
    const cityOfState = allCityList
      .filter((city) => {
        if (
          city.stateCode === stateisoCode &&
          city.countryCode === shippingAddressCountriesValue.value
        ) {
          return city;
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    let cityOptions = cityOfState.map((city) => {
      return {
        // value: city.stateCode.concat("_").concat(city.name),
        value: `${city.stateCode}_${city.name}`,
        label: city.name,
      };
    });

    setShippingAddressFilterCity(cityOptions);
    setShippingAddressCityValues("");
    setNoDataShippingAddressCityMessage(true);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  /** / This function handle city value of shipping address
   * @param  {selectCity} selectCity data object
   * @param  {setFieldValue} setFieldValue function
   * @param {setFieldTouched} setFieldTouched  function
   */
  const handleShippingAddressCity = (
    selectCity,
    setFieldValue,
    setFieldTouched
  ) => {
    setShippingAddressCityValues(selectCity);
    setFieldValue("shipping_address.city", selectCity.label);
    setFieldTouched("shipping_address.city", false);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressStreet = (e, setFieldValue) => {
    setShippingAddressStreetValues(e.target.value);
    setFieldValue("shipping_address.street", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleShippingAddressZipCode = (e, setFieldValue) => {
    setShippingAddressZipCodeValues(e.target.value);
    setFieldValue("shipping_address.zip_code", e.target.value);
    setFieldValue("shipping_address.shipping_address_select", "true");
  };

  const handleBillingAddressStreet = (e, setFieldValue) => {
    setBillingAddressStreetValues(e.target.value);
    setFieldValue("billing_address.street", e.target.value);
    setFieldValue("billing_address.billing_address_select", "true");
  };

  const handleBillingAddressZipCode = (e, setFieldValue) => {
    setBillingAddressZipCodeValues(e.target.value);
    setFieldValue("billing_address.zip_code", e.target.value);
    setFieldValue("billing_address.billing_address_select", "true");
  };


  /**
   * This function send updated data to updateSupplierRow function
   * @param  {editSupplierData} editSupplierData data Object
   */
  const editOnSubmit = (editSupplierData) => {
    let supplierData = JSON.parse(JSON.stringify(editSupplierData));

   

    if (checkedValue === true) {
      delete supplierData.shipping_address?.shipping_address_select;
      delete supplierData.billing_address?.billing_address_select;
      delete supplierData.business_address?.business_address_select;
      Object.assign(
        supplierData.billing_address,
        supplierData.business_address
      );
      Object.assign(
        supplierData.shipping_address,
        supplierData.business_address
      );
      
    } else {
      if (supplierData.business_address.business_address_select === "false") {
        delete supplierData.business_address;
      }
      if (supplierData.shipping_address.shipping_address_select === "false") {
        delete supplierData.shipping_address;
      }
      if (supplierData.billing_address.billing_address_select === "false") {
        delete supplierData.billing_address;
      }
      delete supplierData.shipping_address?.shipping_address_select;
      delete supplierData.billing_address?.billing_address_select;
      delete supplierData.business_address?.business_address_select;
    }

   

   supplierData = trimObj(supplierData);
    console.log("supplierData",supplierData)
    updateSupplierRow(supplierData);
  };

  /* This function closed Edit Supplier Modal */
  const closeHandler = () => {
    closeEditSupplierModal();
  };

  return (
    <Modal
      isOpen={editSupplierModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Supplier
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={editOnSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, handleBlur, values, setFieldTouched, errors }) => (
          <Form>
            <ModalBody>
              <h4>Business Address</h4>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Company Name
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  name="company_name"
                />
                <ErrorMessage name="company_name">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Email
                </Label>

                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  disabled
                />

                <ErrorMessage name="email">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Title
                </Label>

                <Field type="text" className="form-control" name="title" />
                <ErrorMessage name="title">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Primary Contact Name
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  name="primary_contact_name"
                />

                <ErrorMessage name="primary_contact_name">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Phone
                </Label>

                <PhoneInput
                  country="US"
                  value={businessAddressPhoneNumber}
                  name="business_address.phone_number"
                  onBlur={handleBlur}
                  maxLength={14}
                  className="form-control"
                  onChange={(value) =>
                    handleBusinessAddressPhoneNumber(value, setFieldValue)
                  }
                />
                <ErrorMessage name="business_address.phone_number">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Country
                </Label>

                <Select
                  value={businessAddressCountriesValue}
                  noOptionsMessage={() => "No country found"}
                  onChange={(selectCountry) =>
                    handleBusinessAddressCountry(
                      selectCountry,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={countries}
                  name="business_address.country"
                  placeholder="Select Country"
                />

                <ErrorMessage name="business_address.country">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  State
                </Label>

                <Select
                  value={businessAddressStatesValues}
                  noOptionsMessage={() =>
                    noDataBusinessAddressStateMessage
                      ? "No State Found"
                      : "First select country"
                  }
                  onChange={(value) =>
                    handleBusinessAddressState(
                      value,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={businessAddressFilterStates}
                  name="business_address.state"
                  placeholder="Select State"
                  className="Select"
                />

                <ErrorMessage name="business_address.state">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  City
                </Label>

                <Select
                  value={businessAddressCityValues}
                  defaultValue={{
                    value: "",
                    label: initialValues.business_address?.city,
                  }}
                  noOptionsMessage={() =>
                    noDataBusinessAddressCityMessage
                      ? "No City Found"
                      : "First select state"
                  }
                  style={{ color: "black" }}
                  onChange={(value) =>
                    handleBusinessAddressCity(
                      value,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  options={businessAddressFilterCity}
                  name="business_address.city"
                  placeholder="Select City"
                />

                <ErrorMessage name="business_address.city">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Street
                </Label>

                <Field
                  type="text"
                  className="form-control"
                  value={businessAddressStreetValues}
                  name="business_address.street"
                  onChange={(e) =>
                    handleBusinessAddressStreet(e, setFieldValue)
                  }
                />

                <ErrorMessage name="business_address.street">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="recipient-name" className="col-form-label">
                  Zip Code
                </Label>
                <Field
                  type="text"
                  value={businessAddressZipCodeValues}
                  className="form-control"
                  name="business_address.zip_code"
                  onChange={(e) =>
                    handleBusinessAddressZipCode(e, setFieldValue)
                  }
                />
                <ErrorMessage name="business_address.zip_code">
                  {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup>
                <Input
                  type="checkbox"
                  value={checkedValue}
                  checked={checkedValue}
                  onChange={(e) => handleCheckbox(e, setFieldValue)}
                  style={{ marginLeft: "4px" }}
                />{" "}
                <label style={{ marginLeft: "20px", marginTop: "1px" }}>
                  Shipping address and Billing address are
                  same as Business address
                </label>
              </FormGroup>

              {checkedValue === false && (
                <div>
                  <h4>Shipping Address</h4>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Phone
                    </Label>

                    <PhoneInput
                      country="US"
                      value={shippingAddressPhoneNumber}
                      name="shipping_address.phone_number"
                      onBlur={handleBlur}
                      maxLength={14}
                      className="form-control"
                      onChange={(value) =>
                        handleShippingAddressPhoneNumber(
                          value,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                    />
                    <ErrorMessage name="shipping_address.phone_number">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Country
                    </Label>

                    <Select
                      value={shippingAddressCountriesValue}
                      noOptionsMessage={() => "No country found"}
                      onChange={(selectCountry) =>
                        handleShippingAddressCountry(
                          selectCountry,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={countries}
                      name="shipping_address.country"
                      placeholder="Select Country"
                    />

                    <ErrorMessage name="shipping_address.country">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      State
                    </Label>

                    <Select
                      value={shippingAddressStatesValues}
                      noOptionsMessage={() =>
                        noDataBusinessAddressStateMessage
                          ? "No State Found"
                          : "First select country"
                      }
                      onChange={(value) =>
                        handleShippingAddressState(
                          value,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={shippingAddressFilterStates}
                      name="shipping_address.state"
                      placeholder="Select State"
                      className="Select"
                    />

                    <ErrorMessage name="shipping_address.state">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      City
                    </Label>

                    <Select
                      defaultValue={{
                        value: "",
                        label: initialValues.shipping_address?.city,
                      }}
                      value={shippingAddressCityValues}
                      noOptionsMessage={() =>
                        noDataBusinessAddressCityMessage
                          ? "No City Found"
                          : "First select state"
                      }
                      onChange={(value) =>
                        handleShippingAddressCity(
                          value,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={shippingAddressFilterCity}
                      name="shipping_address.city"
                      placeholder="Select City"
                    />

                    <ErrorMessage name="shipping_address.city">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Street
                    </Label>

                    <Field
                      type="text"
                      value={shippingAddressStreetValues}
                      className="form-control"
                      name="shipping_address.street"
                      onChange={(e) =>
                        handleShippingAddressStreet(e, setFieldValue)
                      }
                    />

                    <ErrorMessage name="shipping_address.street">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Zip Code
                    </Label>
                    <Field
                      type="text"
                      value={shippingAddressZipCodeValues}
                      className="form-control"
                      name="shipping_address.zip_code"
                      onChange={(e) =>
                        handleShippingAddressZipCode(e, setFieldValue)
                      }
                    />
                    <ErrorMessage name="shipping_address.zip_code">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                  <h4>Billing Address</h4>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Phone
                    </Label>

                    <PhoneInput
                      country="US"
                      value={phoneValue}
                      name="billing_address.phone_number"
                      onBlur={handleBlur}
                      maxLength={14}
                      className="form-control"
                      onChange={(value) =>
                        handlePhoneNumber(value, setFieldValue)
                      }
                    />
                    <ErrorMessage name="billing_address.phone_number">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Country
                    </Label>

                    <Select
                      value={countriesValue}
                      noOptionsMessage={() => "No country found"}
                      onChange={(selectCountry) =>
                        handleCountry(
                          selectCountry,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                      options={countries}
                      name="billing_address.country"
                      placeholder="Select Country"
                    />

                    <ErrorMessage name="billing_address.country">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      State
                    </Label>

                    <Select
                      value={statesValues}
                      noOptionsMessage={() =>
                        noDataStateMessage
                          ? "No State Found"
                          : "First select country"
                      }
                      onChange={(value) =>
                        handleState(value, setFieldValue, setFieldTouched)
                      }
                      options={filterStates}
                      name="billing_address.state"
                      placeholder="Select State"
                      className="Select"
                    />

                    <ErrorMessage name="billing_address.state">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      City
                    </Label>

                    <Select
                      value={cityValues}
                      defaultValue={{
                        value: "",
                        label: initialValues.billing_address?.city,
                      }}
                      noOptionsMessage={() =>
                        noDataCityMessage
                          ? "No City Found"
                          : "First select state"
                      }
                      onChange={(value) =>
                        handleCity(value, setFieldValue, setFieldTouched)
                      }
                      options={filterCity}
                      name="billing_address.city"
                      placeholder="Select City"
                    />

                    <ErrorMessage name="billing_address.city">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Street
                    </Label>

                    <Field
                      type="text"
                      value={billingAddressStreetValues}
                      className="form-control"
                      name="billing_address.street"
                      onChange={(e) =>
                        handleBillingAddressStreet(e, setFieldValue)
                      }
                    />

                    <ErrorMessage name="billing_address.street">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Zip Code
                    </Label>
                    <Field
                      type="text"
                      value={billingAddressZipCodeValues}
                      className="form-control"
                      name="billing_address.zip_code"
                      onChange={(e) =>
                        handleBillingAddressZipCode(e, setFieldValue)
                      }
                    />
                    <ErrorMessage name="billing_address.zip_code">
                      {(errorMsg) => (
                        <label className="err-msg">{errorMsg}</label>
                      )}
                    </ErrorMessage>
                  </FormGroup>

                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button color="primary" type="submit" disabled={isLoadingButton}>
                {isLoadingButton ? "Please Wait..." : "Update"}
              </Button>

              <Button type="button" color="light" onClick={closeHandler}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditSupplier;
/* Copyright Information: Created by BuyGroup */
