/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import Slider from "react-slick";

export default class ProductSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div>
        <>
          {this.props.sliderImages != undefined ? (
            <>
              <div className="react_slick_main_slider">
                <Slider
                  className="parent_slider"
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.slider1 = slider)}
                >
                  {this.props.sliderImages.map((image) => {
                    return (
                      <div className="main_slider_image">
                        <img
                          className="img-responsive"
                          src={image[this.props.imageUrlKey]}
                          alt="not found"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="react_slick_thumb_slider">
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  infinite={false}
                  dots={false}
                  arrows={false}
                >
                  {this.props.sliderImages.map((image) => {
                    return (
                      <div className="thumb_slider_image">
                        <img
                          className="img-responsive"
                          src={image[this.props.imageUrlKey]}
                          alt="not found"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </>
          ) : null}
        </>
      </div>
    );
  }
}
/* Copyright Information: Created by BuyGroup */