/* Copyright Information: Created by BuyGroup */
import Api from "../Api";
export const supplierList = () => {
  return Api.get("supplier").then((res) => res.data);
};
export const addNewSupplier = (newSupplierData) => {
  return Api.post("supplier", newSupplierData).then((res) => res);
};
export const editSupplier = (editSupplierData) => {
  return Api.put("supplier", editSupplierData).then((res) => res);
};
export const deleteSupplier = (id) => {
  return Api.delete(`supplier/${id}`).then((res) => res);
};
export const SupplierProductGroupDetail = (id) => {
  return Api.get(`supplier/product-group-detail/${id}`);
};
export const SupplierDetail = (id) => {
  return Api.get(`supplier/${id}`);
};
export const fetchSupplierCustomerDetail = (id) => {
  return Api.get(`orderBySupplierId?order_line_id=${id}`).then(
    (res) => res.data
  );
};

/* Copyright Information: Created by BuyGroup */
