/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from "formik";
import { ValidationSchemaCategory } from "./ValidationSchema";
import trimObj from "../components/common/TrimData";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const EditCategory = (props) => {
  const {
    editCategoryModal,
    initialValues,
    updateCategoryHandler,
    closeEditCategoryModal,
    isLoadingButton,
  } = props;

  /**
   * This function send updateCategory data
   * @param  {updatebCategorydata} updatebCategorydata data Object
   */
  const onSubmit = (updatebCategorydata) => {
    updatebCategorydata = trimObj(updatebCategorydata);

    updateCategoryHandler(updatebCategorydata);
  };

  /* This function closed Edit Supplier Modal */
  const closeHandler = () => {
    closeEditCategoryModal();
  };

  return (
    <Modal
      isOpen={editCategoryModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Category
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchemaCategory}
      >
        <Form>
          <ModalBody>
            <Field type="hidden" className="form-control" name="category_id" />
            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Edit Category
              </Label>

              <Field
                type="text"
                className="form-control"
                name="category_name"
              />
              <ErrorMessage name="category_name">
                {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
              </ErrorMessage>
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" disabled={isLoadingButton}>
              {isLoadingButton ? "Please Wait..." : "Update"}
            </Button>

            <Button type="button" color="light" onClick={closeHandler}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default EditCategory;
/* Copyright Information: Created by BuyGroup */
