/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import DeleteSupplier from "./DeleteSupplier";
import {
  supplierList,
  addNewSupplier,
  editSupplier,
  deleteSupplier,
} from "../services/SupplierServices";
import AddSupplier from "./AddSupplier";
import EditSupplier from "./EditSupplier";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const SuppliersList = () => {
  const initialValues = {
    company_name: "",
    email: "",
    title: "",
    primary_contact_name: "",
    business_address: {
      phone_number: "",
      country: "",
      state: "",
      city: "",
      street: "",
      zip_code: "",
    },
    billing_address: {
      phone_number: "",
      country: "",
      state: "",
      city: "",
      street: "",
      zip_code: "",
      billing_address_select: "false",
    },

    shipping_address: {
      phone_number: "",
      country: "",
      state: "",
      city: "",
      street: "",
      zip_code: "",
      shipping_address_select: "false",
    },
  };

  /* "suppliersData" constant store all data of suppliers*/

  const [suppliersData, setSuppliersData] = useState([]);

  /* This const "addSupplierModal" responsible for add SupplierModal open or closed  */

  const [addSupplierModal, setAddSupplierModal] = useState(false);

  /* This const "editSupplierModal" responsible for edit SupplierModal open or closed  */

  const [editSupplierModal, setEditSupplierModal] = useState(false);

  /* "editInitialValuesData" constant store InitialValues for edit suppliers*/

  const [editInitialValuesData, setEditInitialValuesData] = useState({});

  /* "isloading" constant is responsible for showing loader in list supplier */

  const [isloading, setIsLoading] = useState(false);

  /* "isLoadingButton" constant is disable button when data is feaching from api */

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /* This const "deleteSupplierModal" responsible for delete SupplierModal open or closed  */

  const [deleteSupplierModal, setDeleteSupplierModal] = useState(false);

  /* This const "deleteSupplierId" store id of supplier that we want to delete  */

  const [deleteSupplierId, setDeleteSupplierId] = useState();
  const { ExportCSVButton } = CSVExport;

  const nameFormatter = (cell, row) => (
    <>
      <Link
        to={{
          pathname: `suppliers/${row.id}`,
        }}
      >
        <span>{cell}</span>
      </Link>
    </>
  );

  const columns = [
    {
      dataField: "company_name",
      text: "Company name",
      formatter: nameFormatter,
      sort: true,
    },
    {
      dataField: "no_of_product",
      text: " Number of Products",
    },
    {
      dataField: "no_of_groups",
      text: "Group",
    },
    {
      dataField: "gross_sales",
      text: "Gross Sales (exc. taxes and shipping)",
      style: { width: "20%" },
    },

    {
      dataField: "",
      text: "",
      csvExport: false,
      formatter: (cell, supplierRow) => (
        <div>
          <span
            className="inline-block pointer-cursor"
            onClick={() => openEditSupplierModal(supplierRow)}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                paddingLeft: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
          <span
            className="inline-block pointer-cursor"
            onClick={() => openDeleteSupplierModal(supplierRow.id)}
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                paddingLeft: 11,

                color: "#e4566e",
              }}
            ></i>
          </span>
        </div>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  /* function getSuppliersList will fetch supplier list data from api */

  const getSuppliersList = () => {
    setIsLoading(true);
    supplierList()
      .then((res) => {
        if (res.status === 200) {
          setSuppliersData(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSuppliersList();
  }, []);

  /**
   * This function Add New Suppliers
   * @param  {newSupplierData} newSupplierData data Object
   */
  const addNewSupplierhandler = (newSupplierData) => {
    setIsLoadingButton(true);
    const createNewSupplierData = JSON.stringify(newSupplierData);

    addNewSupplier(createNewSupplierData)
      .then((response) => {
        if (response.status === 200) {
          getSuppliersList();
          setAddSupplierModal(false);
          setIsLoadingButton(false);
          toast.success(response.data?.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        toast.error(error.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**
   * This function Open edit Supplier Modal
   * @param  {supplierRow} supplierRow data Object
   */

  const openEditSupplierModal = (supplierRow) => {
    console.log("supplierRow", supplierRow);
    let SupplierDetail = {};
    SupplierDetail.id = supplierRow.id;
    SupplierDetail.company_name = supplierRow.company_name;
    SupplierDetail.email = supplierRow.email;
    SupplierDetail.title = supplierRow.title;
    SupplierDetail.primary_contact_name = supplierRow.primary_contact_name;
    supplierRow.addressInfo.map((address) => {
      SupplierDetail.profile_image = [];
      if (address.address_type === "BILLING_ADDRESS") {
        let billingAddress = {};
        billingAddress.phone_number = address.phone;
        billingAddress.street = address.street;
        billingAddress.city = address.city;
        billingAddress.state = address.state;
        billingAddress.country = address.country;
        billingAddress.zip_code = address.zip_code;
        billingAddress.billing_address_select = "false";
        SupplierDetail.billing_address = billingAddress;
      }

      if (address.address_type === "BUSINESS_ADDRESS") {
        let businessAddress = {};

        businessAddress.phone_number = address.phone;
        businessAddress.street = address.street;
        businessAddress.city = address.city;
        businessAddress.state = address.state;
        businessAddress.country = address.country;
        businessAddress.zip_code = address.zip_code;
        businessAddress.business_address_select = "false";
        SupplierDetail.business_address = businessAddress;
      }
      if (address.address_type === "SHIPPING_ADDRESS") {
        let shippingAddress = {};
        shippingAddress.phone_number = address.phone;
        shippingAddress.street = address.street;
        shippingAddress.city = address.city;
        shippingAddress.state = address.state;
        shippingAddress.country = address.country;
        shippingAddress.zip_code = address.zip_code;
        shippingAddress.shipping_address_select = "false";
        SupplierDetail.shipping_address = shippingAddress;
      }
    });

    console.log("SupplierDetail", SupplierDetail);
    setEditInitialValuesData(SupplierDetail);

    setEditSupplierModal(true);
  };

  /**
   * This function Edit  Suppliers data
   * @param  {newSupplierData} newSupplierData data Object
   */

  const updateSupplierRow = (editSupplierData) => {
    setIsLoadingButton(true);

    editSupplier(editSupplierData)
      .then((response) => {
        if (response.status === 200) {
          getSuppliersList();
          setEditSupplierModal(false);
          setIsLoadingButton(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        setEditSupplierModal(false);
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /** This function remove supplier
   * @param id id
   */
  const removeSupplier = (id) => {
    setIsLoadingButton(true);
    deleteSupplier(id)
      .then((response) => {
        if (response.status === 200) {
          getSuppliersList();
          setIsLoadingButton(false);
          setDeleteSupplierModal(false);
          toast.success(response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /**  Open Supplier Delete modal
   * @param supplierRow_Id id
   */
  const openDeleteSupplierModal = (supplierRow_Id) => {
    setDeleteSupplierModal(true);
    setDeleteSupplierId(supplierRow_Id);
  };

  const { SearchBar } = Search;
  return (
    <>
      <Breadcrumbs
        title="Suppliers"
        parent=""
        subtitle="Supplier Admin Panel"
      />
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Suppliers</h5>

                        <Button
                          type="button"
                          color="secondary"
                          data-toggle="modal"
                          data-original-title="Add Product"
                          onClick={() => setAddSupplierModal(true)}
                        >
                          <i className="fa fa-plus mr-2"></i>
                          Add Supplier
                        </Button>
                      </div>
                    </div>
                    <CardBody>
                      {addSupplierModal && (
                        <AddSupplier
                          addSupplierModal={addSupplierModal}
                          initialValues={initialValues}
                          addNewSupplierhandler={addNewSupplierhandler}
                          isLoadingButton={isLoadingButton}
                          closeAddSupplierModal={() =>
                            setAddSupplierModal(false)
                          }
                        />
                      )}

                      {editSupplierModal && (
                        <EditSupplier
                          editSupplierModal={editSupplierModal}
                          initialValues={editInitialValuesData}
                          updateSupplierRow={updateSupplierRow}
                          isLoadingButton={isLoadingButton}
                          closeEditSupplierModal={() =>
                            setEditSupplierModal(false)
                          }
                        />
                      )}
                      {deleteSupplierModal && (
                        <DeleteSupplier
                          deleteSupplierModal={deleteSupplierModal}
                          removeSupplier={removeSupplier}
                          deleteSupplierId={deleteSupplierId}
                          closedDeleteModal={() =>
                            setDeleteSupplierModal(false)
                          }
                          isLoadingButton={isLoadingButton}
                        />
                      )}
                      {isloading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <ToolkitProvider
                          keyField="id"
                          data={suppliersData}
                          columns={columns}
                          search
                          exportCSV={{
                            fileName: "supplier.csv",
                          }}
                        >
                          {(props) => (
                            <>
                              <div className="table_SearchBar d-flex align-items-center justify-content-end flex-wrap">
                                <div className="product_search  d-flex align-item-center flex-wrap">
                                  <div className="mb-3 mr-3">
                                    <SearchBar
                                      {...props.searchProps}
                                      className="custome-search-table"
                                      placeholder="Search Supplier"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <ExportCSVButton
                                      className="btn btn-primary"
                                      {...props.csvProps}
                                    >
                                      <i className="fa fa-download mr-2"></i>
                                      CSV
                                    </ExportCSVButton>
                                  </div>
                                </div>
                              </div>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={"No suppliers available"}
                                striped
                                hover
                                condensed
                                bordered={false}
                                pagination={paginationFactory({
                                  sizePerPage: 10,
                                  showTotal: true,
                                  hideSizePerPage: true,
                                })}
                                rowClasses="row-hover"
                              />
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SuppliersList;
/* Copyright Information: Created by BuyGroup */
