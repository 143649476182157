/* Copyright Information: Created by BuyGroup */
import React from "react";
import moment, { normalizeUnits } from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import RobotoRegular from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";
import RobotoBlack from "../assets/fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" }, // font-style: normal, font-weight: normal
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    width: "100%",
    boxShadow: "0px 0px 30px -11px rgba(128,128,128,1)",
    height: "1000px",
    fontFamily: "Roboto",
  },
  content: {
    padding: "0 20px",
  },
  logoLeft: {
    width: "49%",
    display: "inline-block",
  },
  logoRight: {
    width: "49%",
    display: "inline-block",
  },
  leftImg: {
    // padding: "10px",
    width: "40%",
  },
  topHead: {
    textAlign: "center",
    backgroundColor: "#000",
    minHeight: "10px",
  },
  table: {
    width: "100%",
    display: "table",
    border: "1pt solid #ccc",
    marginBottom: "9pt",
  },
  tableRow: {
    display: "table-row",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    display: "table-cell",
    width: "25%",
    padding: "5px",
  },
  tableCol5: {
    display: "table-cell",
    width: "5%",
    padding: "5px",
  },
  tableCol50: {
    display: "table-cell",
    width: "50%",
    padding: "5px",
  },
  tableCol40: {
    display: "table-cell",
    width: "40%",
    padding: "5px",
  },
  tableCol75: {
    display: "table-cell",
    width: "75%",
    padding: "5px",
  },
  tableCol80: {
    display: "table-cell",
    width: "80%",
    padding: "5px",
  },
  tableCol20: {
    display: "table-cell",
    width: "20%",
    padding: "5px",
  },
  tableCol10: {
    display: "table-cell",
    width: "10%",
    padding: "5px",
  },
  tableCol15: {
    display: "table-cell",
    width: "15%",
    padding: "5px",
  },
  tableCol30: {
    display: "table-cell",
    width: "30%",
    padding: "5px",
  },
  tableCol100: {
    isplay: "table-cell",
    width: "100%",
    padding: "5px",
  },
  infoBlue: {
    backgroundColor: "#e7e7e7",
    textAlign: "left",
    color: "#333333",
  },
  infoGray: {
    backgroundColor: "#cccccc",
    color: "#000000",
  },
  infoLightGray: {
    backgroundColor: "#f3f3f3",
    color: "#000000",
  },
  interviewHeading: {
    color: "#555",
    fontSize: "13pt",
    fontWeight: "700",
  },
  childBorder: {
    border: "1pt solid #cccccc",
  },
  borderB: {
    borderBottom: "1pt solid #ccc",
  },
  borderL: {
    borderLeft: "1pt solid #ccc",
  },
  borderR: {
    borderRight: "1pt solid #ccc",
  },
  py: {
    padding: "10pt 5pt",
  },
  borderT: {
    borderTop: "1pt solid #ccc",
  },
  borderA: {
    border: "1pt solid #ccc",
  },
  borderNone: {
    border: "none",
  },
  MainHead: {
    width: "100%",
    display: "block",
    textAlign: "center",
    marginBottom: "2rem",
  },
  mainText: {
    fontSize: "18pt",
    color: "#555555",
    padding: "10px 0",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  font14: {
    fontSize: "8pt",
  },
  font16: {
    fontSize: "11pt",
  },
  font17: {
    fontSize: "12.75pt",
  },
  marginB15: {
    marginBottom: "15px",
  },
});

const getOrderTable1 = (orderList) => {
  return orderList.map((orderRow, index) => {
    return (
      <View key={index} style={{ ...styles.marginB15 }}>
        <View style={{ ...styles.tableRow, ...styles.borderT }} key={index}>
          <View
            style={{
              ...styles.tableCol40,
              ...styles.py,
            }}
          >
            <Text>{orderRow.Order_id}</Text>
          </View>
          <View
            style={{ ...styles.tableCol20, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.customer_name}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{parseFloat(orderRow.total).toFixed(2)}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{parseFloat(orderRow.Total_tax).toFixed(2)}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{parseFloat(orderRow.Shipping_value).toFixed(2)}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.Status}</Text>
          </View>
        </View>
        <View style={{ ...styles.childBorder }}>
          <View style={{ ...styles.tableRow, fontWeight: "bold" }}>
            <View
              style={{
                ...styles.tableCol10,
                ...styles.infoLightGray,
              }}
            >
              <Text>{"Group Id"}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol10,
                ...styles.infoLightGray,
              }}
            >
              <Text>{"Group Name"}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol10,
                ...styles.infoLightGray,
              }}
            >
              <Text>{"Category"}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol10,
                ...styles.infoLightGray,
              }}
            >
              <Text>{"Sub Category"}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol10,
                ...styles.infoLightGray,
              }}
            >
              <Text>{"Start Date"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray }}>
              <Text>{"End Date"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray }}>
              <Text>{"Quantity"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray }}>
              <Text>{"Rate"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray }}>
              <Text>{"Supplier"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray }}>
              <Text>{"Status"}</Text>
            </View>
          </View>
          {orderRow.order_lines.length > 0 ? (
            orderRow.order_lines.map((orderSubRow, index) => {
              return (
                <View style={{ ...styles.tableRow }} key={index}>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.group_id}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.group_name}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.category}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.sub_category}</Text>
                  </View>

                  <View style={{ ...styles.tableCol10 }}>
                    <Text>
                      {moment(orderSubRow.start_date).format("MM/DD/YYYY")}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>
                      {moment(orderSubRow.end_date).format("MM/DD/YYYY")}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.quantity}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{parseFloat(orderSubRow.rate).toFixed(2)}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.company_name}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.status}</Text>
                  </View>
                </View>
              );
            })
          ) : (
            <td>No Data Found</td>
          )}
        </View>
      </View>
    );
  });
};

export function PdfDocument(props) {
  let orderHtml = getOrderTable1(props.pdfDocumentData);

  return (
    <Document style={{ backgroundColor: "#f2f3f8" }}>
      <Page style={styles.page} renderMode="canvas">
        <View style={styles.topHead}></View>

        <View style={styles.content}>
          <View style={{ marginBottom: "10pt" }}>
            <Text style={{ ...styles.interviewHeading, fontWeight: "bold" }}>
              {"Orders"}
            </Text>
          </View>
          <View>
            <View
              style={{
                ...styles.table,
                ...styles.font14,
                marginBottom: "10px",
              }}
            >
              <View style={{ ...styles.tableRow, fontWeight: "bold" }}>
                <View style={{ ...styles.tableCol40, ...styles.infoGray }}>
                  <Text>{"Order ID"}</Text>
                </View>
                <View style={{ ...styles.tableCol20, ...styles.infoGray }}>
                  <Text>{"Name"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Total Amount"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Tax Amount"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Shipping Value"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Status"}</Text>
                </View>
              </View>

              {orderHtml.length > 0 ? (
                orderHtml
              ) : (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol80 }}>
                    <Text>{"No orders"}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const data = [
  {
    Order_id: "222e0004-75ea-4920-a161-2a5d303f0982",
    Shipping_value: 0,
    Status: "pending",
    Total_tax: 2,
    customer_name: "Sudhir Singh",
    order_lines: [
      {
        Order_id: "222e0004-75ea-4920-a161-2a5d303f0982",
        Shipping_value: 0,
        Status: "pending",
        Total_tax: 2,
        customer_name: "Sudhir Singh",
      },
    ],
    total: 26.25,
  },
];
export const generatePdfDocument = async (
  orderList,
  handleLodingDownloadPdf
) => {
  handleLodingDownloadPdf(true);
  const blob = await pdf(
    <PdfDocument title="My PDF" pdfDocumentData={orderList} />
  ).toBlob();

  saveAs(blob, `order${format(new Date(), "_MM_dd_yyyy_HH:mm:ss")}.pdf`);
  handleLodingDownloadPdf(false);
};
/* Copyright Information: Created by BuyGroup */
