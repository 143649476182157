/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Spinner,
  CardBody,
  Button,
  Container,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import Breadcrumbs from "../components/common/breadcrumb";
import { Link } from "react-router-dom";
import ProductSlider from "../Products/ProductSlider";
import {
  groupDetail,
  deleteGroup,
  groupOrderList,
} from "../services/GroupServices";
import Moment from "react-moment";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const GroupDetails = (props) => {
  // this state is used for loading spinner
  const [isLoading, setLoading] = useState(false);

  //this state for group data
  const [groupData, setGroupData] = useState({});

  // this state for levels list array
  const [levels, setLevels] = useState([]);

  //current Date
  const currentDate = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  let formatTime = "MM/DD/YYYY h:mm a";
  let newCurrent = moment(currentDate, formatTime);
  let newstartdate = moment(new Date(groupData.start_date), formatTime);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  /* const "isDisableButton" is responsible for disable delete button */
  const [isDisableButton, setIsDisableButton] = useState(false);

  /* const "showDeleteAlert" responsible for open and closed  delete modal */
  const [showDeleteAlert, setshowDeleteAlert] = useState(false);

  /* const groupOrder  store order od group */

  const [groupOrder, setgroupOrder] = useState([]);

  /* it will fetch data from api */
  useEffect(() => {
    getGroupDetail();
    getGroupOrderDetail();
  }, []);
  //  this funciton is userd for group details
  const getGroupDetail = () => {
    setLoading(true);
    groupDetail(props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setGroupData(response.data);
          setLoading(false);
          setLevels(response.data.levels);
          setShippingLevel(response.data.shipping_level);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getGroupOrderDetail = () => {
    groupOrderList(props.match.params.id)
      .then((res) => {
        setgroupOrder(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [shippingLevel, setShippingLevel] = useState([]);

  // this function remove group and call api s
  const removeGroup = () => {
    setIsDisableButton(true);
    deleteGroup(props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          setIsDisableButton(false);
          setshowDeleteAlert(false);

          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          props.history.push("/app/groups");
        }
      })
      .catch((error) => {
        setIsLoadingButton(false);
        toast.error(error.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  /* This Function will close delete Group modal */
  const onshowDeleteAlert = () => {
    setshowDeleteAlert(!showDeleteAlert);
  };
  const quantityFormatter = (cell, row, rowIndex) => (
    <div>
      <span>
        {rowIndex === 0
          ? 1 + " - " + cell
          : parseInt(levels[rowIndex - 1].quantity_level) + 1 + " - " + cell}
      </span>
    </div>
  );

  //    columns for bootstrap table
  const columns = [
    {
      dataField: "quantity_level",
      text: "Quantity",
      headerAlign: "right",
      align: "right",
      formatter: quantityFormatter,
    },
    {
      dataField: "discounted_price",
      text: "Price",

      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => {
        return (
          <div className="category_name no-next-line">
            <span> {cell && "$" + parseFloat(cell).toFixed(2)}</span>
          </div>
        );
      },
    },
  ];
  const columnsShipping = [
    {
      dataField: "quantity_level",
      text: "Quantity",
      headerAlign: "right",
      align: "right",
      //  formatter: shippingQuantityFormatter,
      formatter: quantityFormatter,
    },
    {
      dataField: "shipping_price",
      text: "Shipping Cost $",
      headerAlign: "right",
      align: "right",
      formatter: (cell, row) => {
        return (
          <div className="category_name no-next-line">
            <span> {cell && "$ " + cell}</span>
          </div>
        );
      },
    },
  ];

  const headers = [
    { label: "Customer name", key: "customer_name" },
    { label: "quantity", key: "quantity" },
    { label: "street", key: "street" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Country", key: "country" },
    { label: "ZipCode", key: "zip_code" },
  ];

  const OrderColumn = [
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "state",
      text: "State",
    },
  ];
  let role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      <Breadcrumbs title="Groups" parent="Groups" subtitle="Groups" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h5>View Group</h5>
                  </div>
                  <div className="col-md-6 text-right d-flex justify-content-end">
                    {!isLoading && groupOrder.length > 0 ? (
                      <div style={{ marginTop: "8px" }}>
                        <CSVLink
                          className="GroupOrder"
                          data={groupOrder}
                          headers={headers}
                          filename={"group.csv"}
                        >
                          Order CSV
                        </CSVLink>
                      </div>
                    ) : (
                      ""
                    )}

                    {!isLoading && role[0] !== "SUPPLIER" ? (
                      <div>
                        <Link
                          to={{
                            pathname: "/app/groups/" + props.match.params.id,
                          }}
                          className={
                            groupData.status == "Closed" ? "disable-link" : ""
                          }
                        >
                          <Button
                            type="button"
                            color="secondary"
                            data-toggle="modal"
                            disabled={groupData.status == "Closed"}
                            // disabled={moment(newCurrent).isAfter(newstartdate)}
                          >
                            <i className="fa fa-pencil mr-2"></i>
                            Edit
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <Row className="product-adding">
                      <Col className="col-md-5">
                        <div className="clearfix">
                          <ProductSlider
                            sliderImages={groupData.productImage}
                            imageUrlKey="imageUrl"
                          />
                        </div>
                      </Col>
                      <Col className="col-md-7">
                        <h5>
                          <strong>{groupData.offer_name}</strong>
                        </h5>
                        <p>{groupData.description}</p>

                        <Row>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Group ID </strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.group_id}</p>
                              </div>
                            </div>
                          </Col>
                          {role[0] !== "SUPPLIER" && (
                            <Col md="4">
                              <div>
                                <h5>
                                  <strong>Supplier Name </strong>
                                </h5>
                                <div className="clearfix product_detail_category">
                                  <p>{groupData.company_name}</p>
                                </div>
                              </div>
                            </Col>
                          )}
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Price</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p> $ {groupData.price}</p>
                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Start Date </strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>
                                  <Moment format="MM/DD/YYYY h:mm a">
                                    {groupData.start_date}
                                  </Moment>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>End Date</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>
                                  <Moment format="MM/DD/YYYY h:mm a">
                                    {groupData.end_date}
                                  </Moment>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Status</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.status}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Min. Qty</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.minimum_quantity}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Total Order Placed</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.quantity_booked}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Total Participants</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.number_of_participants}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Zip Codes</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{groupData.zip_code}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {levels.length > 0 && (
                            <Col md="6">
                              <div>
                                <h5>
                                  <strong>Quantity Range</strong>
                                </h5>

                                <BootstrapTable
                                  keyField="id"
                                  key={`quantityListTable`}
                                  striped
                                  hover
                                  condensed
                                  bordered={true}
                                  data={levels}
                                  columns={columns}
                                  rowClasses="row-hover"
                                />
                              </div>
                            </Col>
                          )}
                          {shippingLevel.length > 0 && (
                            <Col md="6">
                              <div>
                                <h5>
                                  <strong>Shipping Range</strong>
                                </h5>

                                <BootstrapTable
                                  keyField="id"
                                  key={`ShippingTable`}
                                  striped
                                  hover
                                  condensed
                                  bordered={true}
                                  data={shippingLevel}
                                  columns={columnsShipping}
                                  rowClasses="row-hover"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
                <Modal
                  isOpen={showDeleteAlert}
                  style={{ overlay: { opacity: 0.1 } }}
                >
                  <ModalHeader toggle={onshowDeleteAlert}>
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                      Delete Group
                    </h5>
                  </ModalHeader>
                  <ModalBody>
                    <p>Are you sure you want to delete this Group</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={removeGroup}
                      disabled={isDisableButton ? true : false}
                    >
                      {isDisableButton ? "Please Wait..." : "Yes, Delete it!"}
                    </Button>
                    <Button
                      type="button"
                      color="light"
                      onClick={onshowDeleteAlert}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default GroupDetails;
/* Copyright Information: Created by BuyGroup */
