/* Copyright Information: Created by BuyGroup */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ValidationSchemaCategory } from "./ValidationSchema";
import trimObj from "../components/common/TrimData";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const AddCategory = (props) => {
  const {
    addCategoryModal,
    initialValues,
    addNewCategoryHandler,
    closeAddCategoryModal,
    isLoadingButton,
  } = props;

  /**
   * This function send New Category data
   * @param  {newCategoryData} newCategoryData data Object
   */

  const onSubmit = (newCategoryData) => {
    newCategoryData = trimObj(newCategoryData);
    addNewCategoryHandler(newCategoryData);
  };

  /* This function closed Add Supplier Modal */
  const closeHandler = () => {
    closeAddCategoryModal();
  };

  return (
    <Modal
      isOpen={addCategoryModal}
      toggle={closeHandler}
      style={{ overlay: { opacity: 0.1 } }}
    >
      <ModalHeader toggle={closeHandler}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Category
        </h5>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchemaCategory}
      >
        <Form>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="recipient-name" className="col-form-label">
                Add Category
              </Label>

              <Field
                type="text"
                className="form-control"
                name="category_name"
              />
              <ErrorMessage name="category_name">
                {(errorMsg) => <label className="err-msg">{errorMsg}</label>}
              </ErrorMessage>
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" type="submit" disabled={isLoadingButton}>
              {isLoadingButton ? "Please Wait..." : "Add"}
            </Button>

            <Button type="button" color="light" onClick={closeHandler}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddCategory;
/* Copyright Information: Created by BuyGroup */
