/* Copyright Information: Created by BuyGroup */
import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const DeleteSubCategory = (props) => {
  const {
    deleteSubCategoryModal,
    removeSubCategory,
    deleteSubCategoryId,
    closedSubCategory,
    isLoadingButton,
  } = props;
  return (
    <div>
      <Modal
        isOpen={deleteSubCategoryModal}
        style={{ overlay: { opacity: 0.1 } }}
      >
        <ModalHeader toggle={closedSubCategory}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Are you sure you want to delete this SubCategory?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={() => removeSubCategory(deleteSubCategoryId)}
            disabled={isLoadingButton}
          >
            {isLoadingButton ? "Please Wait..." : "Yes, Delete it!"}
          </Button>
          <Button type="button" color="light" onClick={closedSubCategory}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteSubCategory;
/* Copyright Information: Created by BuyGroup */