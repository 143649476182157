/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  faPhoneAlt,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { SupplierProductGroupDetail } from "../services/SupplierServices";

const SupplierDetail = (props) => {
  /* "data" constant store Group & Product Detail of respective suppliers*/
  const [data, setData] = useState([]);

  const [isloading, setIsLoading] = useState(true);

  /* column of supplier's Product */
  const productColumn = [
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "view",
      formatter: (cell, row) => (
        <>
          <div>
            <span className="inline-block pointer-cursor">
              <Link
                to={{
                  pathname: "/app/products/view-product/" + row.id,
                  productDetails: row,
                }}
              >
                View
              </Link>
            </span>
          </div>
        </>
      ),
    },
  ];

  /* column of supplier's Groups */
  const groupColumn = [
    {
      dataField: "offer_name",
      text: "Group Name",
      sort: true,
    },
    {
      dataField: "view",
      formatter: (cell, row) => (
        <>
          <div>
            <span className="inline-block pointer-cursor">
              <Link to={{ pathname: "/app/groups/view/" + row.id }}>
                <span>View</span>
              </Link>
            </span>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    getSupplierProductGroupDetail();
  }, []);

  /* This  function will give  Group & Product Detail of respective suppliers Id=props.match.params.id*/
  const getSupplierProductGroupDetail = () => {
    setIsLoading(true);
    SupplierProductGroupDetail(props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  /* it will formate the number in US formate */

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");

    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <Breadcrumbs title="Supplier" parent="" subtitle="Customers" />
      <Row className="row">
        <Col md="12">
          <React.Fragment>
            <Row>
              <Col lg="12" md="12">
                <Card className="card-box">
                  <div className="card-header">
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col md="6">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Supplier Details</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {isloading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <CardBody>
                      <Row>
                        <Col md="9">
                          <Row className="mt-1">
                            <Col md="12">
                              {/* <FontAwesomeIcon
                                icon={faPhoneAlt}
                                className="mt-1 mr-2"
                              />{" "} */}
                              <strong>Company Name</strong> :{" "}
                              <label>{data[0].supplier?.company_name}</label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              {/* <FontAwesomeIcon
                                icon={faEnvelope}
                                className="mt-1 mr-2"
                              />{" "} */}
                              <strong>Email</strong> :{" "}
                              <label>{data[0].supplier?.email}</label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              {/* <FontAwesomeIcon
                                icon={faMapMarker}
                                className="mt-1 mr-2"
                              />{" "} */}
                              <strong>Title</strong> :{" "}
                              <label>{data[0].supplier?.title}</label>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md="12">
                              {/* <FontAwesomeIcon
                                icon={faMapMarker}
                                className="mt-1 mr-2"
                              />{" "} */}
                              <strong> Primary Contact Name</strong> :{" "}
                              <label>
                                {data[0].supplier?.primary_contact_name}
                              </label>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12">
                              {data[0].address_info.map?.length > 0 &&
                                data[0].address_info.map((address, index) => {
                                  if (
                                    address.address_type == "BILLING_ADDRESS" &&
                                    address.phone !== ""
                                  ) {
                                    return (
                                      <div className="mt-3 AddressBox">
                                        <h5>
                                          <strong>Billing Address</strong>
                                        </h5>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faPhoneAlt}
                                            className="mt-1 mr-2"
                                          />{" "}
                                          <label>
                                            {formatPhoneNumber(address.phone)}
                                          </label>
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faMapMarker}
                                            className="mt-1 mr-2"
                                          />
                                          <label>
                                            {address.street +
                                              ", " +
                                              address.city +
                                              ", " +
                                              address.state +
                                              ", " +
                                              address.country +
                                              ", " +
                                              address.zip_code}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (
                                    address.address_type ==
                                      "BUSINESS_ADDRESS" &&
                                    address.phone !== ""
                                  ) {
                                    return (
                                      <div className="mt-3 AddressBox">
                                        <h5>
                                          <strong>Business Address</strong>
                                        </h5>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faPhoneAlt}
                                            className="mt-1 mr-2"
                                          />{" "}
                                          <label>
                                            {formatPhoneNumber(address.phone)}
                                          </label>
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faMapMarker}
                                            className="mt-1 mr-2"
                                          />{" "}
                                          <label>
                                            {address.street +
                                              ", " +
                                              address.city +
                                              ", " +
                                              address.state +
                                              ", " +
                                              address.country +
                                              ", " +
                                              address.zip_code}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (
                                    address.address_type ==
                                      "SHIPPING_ADDRESS" &&
                                    address.phone !== ""
                                  ) {
                                    return (
                                      <div className="mt-3 AddressBox">
                                        <h5>
                                          <strong>Shipping Address</strong>
                                        </h5>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faPhoneAlt}
                                            className="mt-1 mr-2"
                                          />{" "}
                                          <label>
                                            {formatPhoneNumber(address.phone)}
                                          </label>
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faMapMarker}
                                            className="mt-1 mr-2"
                                          />{" "}
                                          <label>
                                            {address.street +
                                              ", " +
                                              address.city +
                                              ", " +
                                              address.state +
                                              ", " +
                                              address.country +
                                              ", " +
                                              address.zip_code}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <Col md="12">
                              <h5>
                                <strong>Product</strong>
                              </h5>
                              <ToolkitProvider
                                keyField="id"
                                data={data[0].product_info}
                                columns={productColumn}
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      noDataIndication={"No Products Found"}
                                      striped
                                      hover
                                      condensed
                                      bordered={false}
                                      pagination={paginationFactory({
                                        sizePerPage: 10,
                                        showTotal: true,
                                        hideSizePerPage: true,
                                      })}
                                      rowClasses="row-hover"
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <Col md="12">
                              <h5>
                                <strong>Groups</strong>
                              </h5>
                              <ToolkitProvider
                                keyField="id"
                                data={data[0].group_info}
                                columns={groupColumn}
                              >
                                {(props) => (
                                  <div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      noDataIndication={"No Groups Found"}
                                      striped
                                      hover
                                      condensed
                                      bordered={false}
                                      pagination={paginationFactory({
                                        sizePerPage: 10,
                                        showTotal: true,
                                        hideSizePerPage: true,
                                      })}
                                      rowClasses="row-hover"
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Col>
      </Row>
    </div>
  );
};

export default SupplierDetail;
/* Copyright Information: Created by BuyGroup */
