/* Copyright Information: Created by BuyGroup */
import React, { useState, useEffect } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { resetPassword, tokenExpire } from "../../services/AuthServices";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
toast.configure();

const ResetPassword = (props) => {
  const params = new URLSearchParams(window.location.search);

  const paramsValue = params.get("reset_token");
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(true);

  //   if (params.has("reset_token") !== false) {
  //     setIsValid(false);
  //   }
  const [verifyLoder, setVerifyLoder] = useState(true);
  useEffect(() => {
    setVerifyLoder(true);
    const data = {
      token: paramsValue,
    };

    tokenExpire(data)
      .then((res) => {
        if (res.status === 200) {
          setIsValid(true);
          setVerifyLoder(false);
        } else {
          setIsValid(false);
          setVerifyLoder(false);
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          setIsValid(false);
        } else {
          setIsValid(false);
        }
        setVerifyLoder(false);
      });
  }, []);
  function onSubmitForgotPassword(data) {
    // return;
    setIsloading(true);
    resetPassword(data)
      .then((res) => {
        setIsloading(false);
        toast.success(res.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        props.history.push("/");
      })
      .catch((error) => {
        setTimeout(
          function () {
            setError("");
          }.bind(this),
          3000
        );
        if (error !== undefined) {
          setIsloading(false);
          setError(error.data.message);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          setIsloading(false);
          setError(error.data.message);
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  }

  return (
    <section>
      <div className="clearfix">
        {verifyLoder ? (
          <div className="email-loader">
            <Spinner animation="border" />
          </div>
        ) : isValid ? (
          <ResetPasswordForm
            error={error}
            onSubmit={onSubmitForgotPassword}
            isloading={isloading}
            queryParam={paramsValue}
          />
        ) : (
          <div className="d-flex expired_parent justify-content-center align-items-center">
            <p className="text-center expired_text">
              Your link has been expired
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
export default ResetPassword;
/* Copyright Information: Created by BuyGroup */