/* Copyright Information: Created by BuyGroup */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const OrderUpdate = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.rowData.status);
console.log("props.rowData",props.rowData)
  const [showTracking, setShowTracking] = useState(
    props.rowData.status !== "shipping" ? false : true
  );

  /* closed update order modal */

  const closeHandler = () => {
    props.handleModalClose();
  };

  /* handle status of order  */

  const statusHandleChange = (e, setFieldValue) => {
    setSelectedValue(e.target.value);
    e.target.value !== "shipping"
      ? setShowTracking(false)
      : setShowTracking(true);
    setFieldValue("status", e.target.value);
  };

  return (
    <div>
      <Modal
        isOpen={props.orderUpdateModal}
        toggle={closeHandler}
        style={{ overlay: { opacity: 0.1 } }}
      >
        <ModalHeader toggle={closeHandler}>
          <p className="modal-title f-w-600">Update Order</p>
        </ModalHeader>

        <Formik
          initialValues={{
            order_line_id: props.rowData.order_line_id,
            status: props.rowData.status,
            tracking_number: props.rowData.tracking_number,
            tracking_company_name: props.rowData.tracking_company_name,
          }}
          onSubmit={(values, actions) => {
            // return;
            props.onSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <Form>
              <ModalBody>
                <input
                  type="hidden"
                  className="form-control"
                  name="order_line_id"
                  value={values.order_line_id}
                />
                <FormGroup>
                  <Label className="col-form-label">Status</Label>
                  <select
                    name="status"
                    className="form-control"
                    value={selectedValue}
                    // onChange={handleChange}
                    onChange={(e) => statusHandleChange(e, setFieldValue)}
                    onBlur={handleBlur}
                  >
                    <option value="in_progress">In Progress</option>
                    <option value="shipping">To Be Shipped</option>
                    <option value="completed">Completed</option>
                  </select>

                  <ErrorMessage name="status">
                    {(errorMsg) => (
                      <label className="err-msg">{errorMsg}</label>
                    )}
                  </ErrorMessage>
                </FormGroup>
                {showTracking ? (
                  <>
                    <FormGroup>
                      <Label className="col-form-label">
                        Add Tracking Code
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        name="tracking_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tracking_number}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">Company Name</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="tracking_company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tracking_company_name}
                      />
                    </FormGroup>
                  </>
                ) : null}
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={props.isLoadingButton}
                >
                  {props.isLoadingButton ? "Please Wait..." : "Update"}
                </Button>

                <Button type="button" color="light" onClick={closeHandler}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default OrderUpdate;
/* Copyright Information: Created by BuyGroup */
