/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../components/common/breadcrumb";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { customerList, addCustomer } from "../services/CustomerServices";
import AddCustomer from "./AddCustomer";
import Select from "react-select";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../assets/scss/ToggleSwitch.css";
import "./customer.css";
toast.configure();
export default class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      allCustomersList: [],
      customersList: [],
      memberType: null,
      taxExempt: null,
      memberTypeList: [
        { label: "Business", value: "Business" },
        { label: "Individual", value: "Individual" },
      ],
      taxExemptOption: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
      initialValues: {
        address_type: "BILLING_ADDRESS",
        first_name: "",
        last_name: "",
        company_name: "",
        title: "",
        email: "",
        phone_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      },
      errorMessage: "",
      showModalCustomer: false,
      isDisableAdd: false,
      deleteCutomerModal: false,
      deleteCustomerId: null,
    };
  }
  componentDidMount() {
    this.getCustomerList();
  }
  // this function call api to get customers list
  getCustomerList = () => {
    this.setState({ isLoading: true });
    customerList()
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            allCustomersList: response.data,
            customersList: response.data,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.setState({ isLoading: false });
      });
  };

  //img formatter to render image of customer cell is column cell and row is table row
  imgFormatter = (cell, row) => {
    return (
      <div className="product_thumb">
        <img alt="Not Found" className="img-responsive" src={row.profile_img} />
      </div>
    );
  };

  //address formatter to create address from response
  addressFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <label>
          {row.street + " " + row.city + " " + row.state + " " + row.country}
        </label>
      </React.Fragment>
    );
  };
  // this function is used to open add modal for customer
  openAddCustomerModal = () => {
    this.setState({ showModalCustomer: true });
  };
  // this function is used to close add modal for customer
  closeAddModal = () => {
    this.setState({ showModalCustomer: false });
  };

  // this funciton used in status change dropdown takes argument as {label:'',value:''}
  handleChangeStatus = (selectedOption) => {
    this.setState(
      {
        memberType: selectedOption,
      },
      () => {
        this.filterStatus(
          this.state.memberType?.value,
          this.state.taxExempt?.value
        );
      }
    );
  };

  filterStatus = (memberType, taxExempt) => {
    const allCustomersList = this.state.allCustomersList;
    let filterdData = allCustomersList;
    const isTaxExempt = taxExempt === "Yes" ? true : false;

    if (memberType && taxExempt) {
      filterdData = allCustomersList.filter(
        (item) =>
          item.customer_type === memberType &&
          item.is_tax_exempt === isTaxExempt
      );
    } else if (memberType) {
      filterdData = allCustomersList.filter(
        (item) => item.customer_type === memberType
      );
    } else if (taxExempt) {
      filterdData = allCustomersList.filter(
        (item) => item.is_tax_exempt === isTaxExempt
      );
    }
    this.setState({ customersList: filterdData });
  };

  handleTaxExemptChange = (selectedOption) => {
    this.setState(
      {
        taxExempt: selectedOption,
      },
      () => {
        this.filterStatus(
          this.state.memberType?.value,
          this.state.taxExempt?.value
        );
      }
    );
  };

  //this function call add customer api
  addCustomer = (customerData) => {
    this.setState({ isloading: true, isDisableAdd: true });
    if (customerData.title === "") {
      delete customerData.title;
    }
    if (customerData.company_name == "") {
      delete customerData.company_name;
    }
    addCustomer(customerData)
      .then((response) => {
        if (response.status === 200) {
          let customersList = [...this.state.customersList];
          customersList.push(response.data);

          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.setState({
            isloading: false,
            showModalCustomer: false,
            customersList: customersList,
            initialValues: {
              address_type: "BILLING_ADDRESS",
              company_name: "",
              title: "",
              first_name: "",
              last_name: "",
              city: "",
              state: "",
              country: "",
              street: "",
              zip_code: "",
              email: "",
              // password: "",
              phone_number: "",
            },
            isDisableAdd: false,
          });
          this.getCustomerList();
        }
      })
      .catch((error) => {
        toast.error(error.data?.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });

        this.setState({ isDisableAdd: false });
      });
  };

  openDeleteCuromerModal = (CustomerId) => {
    this.setState({ deleteCutomerModal: true, deleteCustomerId: CustomerId });
  };

  //formatter for customer name
  nameFormatter = (cell, row) => (
    <Link
      to={{
        pathname: "members/" + row.id,
        customerDetails: row,
      }}
    >
      <span>{cell}</span>
    </Link>
  );

  //formatter for date
  dateFormatter = (cell, row) => <Moment format="MM/DD/YYYY">{cell}</Moment>;

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  render() {
    const { SearchBar } = Search;

    const columns = [
      {
        dataField: "name",
        text: " Name",
        sort: true,
        formatter: this.nameFormatter,
        headerClasses: "header-classes-customer-name",
        classes: "column-classes-customer-name",
      },
      {
        dataField: "phone_number",
        text: "Phone",
        headerAlign: "left",
        align: "left",
        headerClasses: "header-classes-phone",
        style: { width: "200px" },
        formatter: (cell, row) => {
          return this.formatPhoneNumber(cell);
        },
      },
      {
        dataField: "email",
        text: "Email",
        headerStyle: {
          width: "15%",
        },
      },
      {
        dataField: "customer_type",
        text: "Member Type",
        headerClasses: "header-classes-address",
      },
      {
        dataField: "is_tax_exempt",
        text: "Tax Exempt",
        headerClasses: "header-classes-address",
        formatter: (cell, row) => {
          return <span> {cell ? "Yes" : "No"}</span>;
        },
      },
      {
        dataField: "count",
        text: "Orders",
        headerAlign: "right",
        align: "right",
        headerClasses: "header-classes-order",
        formatter: (cell, row) => {
          return (
            <div>
              <span> {cell}</span>
            </div>
          );
        },
      },
      {
        dataField: "date_created",
        text: "Member Since",
        sort: true,
        formatter: this.dateFormatter,
        headerStyle: {
          width: "175px",
        },
      },
    ];

    return (
      <>
        <Breadcrumbs title="Members" parent="" subtitle="Members" />
        <div className="container-fluid">
          <Row className="row">
            <Col md="12">
              <React.Fragment>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                          <h5>All Members</h5>

                          <Button
                            type="button"
                            color="secondary"
                            data-toggle="modal"
                            data-original-title="Add Member"
                            onClick={this.openAddCustomerModal}
                          >
                            <i className="fa fa-plus mr-2"></i>
                            Add Member
                          </Button>
                        </div>
                      </div>
                      <CardBody>
                        <ToolkitProvider
                          keyField="CustomersListTable"
                          data={this.state.customersList}
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <>
                              {this.state.isLoading ? (
                                <div className="custom-loader">
                                  <Spinner animation="border" />
                                </div>
                              ) : (
                                <>
                                  <Row className="table_SearchBar select_dropdown">
                                    <Col
                                      lg={4}
                                      className="filter-container-col"
                                    >
                                      <Select
                                        value={this.state.memberType}
                                        onChange={this.handleChangeStatus}
                                        options={this.state.memberTypeList}
                                        placeholder="Filter By Member Type"
                                        isClearable={true}
                                      />
                                    </Col>
                                    <Col
                                      lg={4}
                                      className="filter-container-col"
                                    >
                                      <Select
                                        value={this.state.taxExempt}
                                        onChange={this.handleTaxExemptChange}
                                        options={this.state.taxExemptOption}
                                        placeholder="Filter By Tax Exempt"
                                        isClearable={true}
                                      />
                                    </Col>
                                    <Col
                                      lg={4}
                                      className="filter-container-col"
                                    >
                                      <SearchBar
                                        {...props.searchProps}
                                        className="custome-search-table manage-width"
                                        placeholder="Search Member"
                                      />
                                    </Col>
                                  </Row>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="customer_id"
                                    key={`CustomersListTable`}
                                    striped
                                    hover
                                    condensed
                                    bordered={false}
                                    data={this.state.customersList}
                                    columns={columns}
                                    noDataIndication={"No customers available"}
                                    pagination={paginationFactory({
                                      sizePerPage: 10,
                                      showTotal: true,
                                      hideSizePerPage: true,
                                    })}
                                    rowClasses="row-hover"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </ToolkitProvider>
                      </CardBody>
                    </Card>
                    {this.state.showModalCustomer && (
                      <AddCustomer
                        showModalCustomer={this.state.showModalCustomer}
                        closeAddModal={this.closeAddModal}
                        initialValues={this.state.initialValues}
                        addCustomer={this.addCustomer}
                        errorMessage={this.state.errorMessage}
                        isDisableAdd={this.state.isDisableAdd}
                      />
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
/* Copyright Information: Created by BuyGroup */
