/* Copyright Information: Created by BuyGroup */
import React from "react";
import man from "../../assets/images/dashboard/man.png";

const UserPanel = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    <div>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            src={
              user.profile_image !== null && user.profile_image !== undefined
                ? user.profile_image
                : man
            }
            alt="#"
          />
        </div>
        {user !== undefined && (
          <div>
            <h6 className="mt-3 f-14">{user.name}</h6>
            <p>{user.role}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPanel;
/* Copyright Information: Created by BuyGroup */