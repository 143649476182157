/* Copyright Information: Created by BuyGroup */
import React, { Component } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { saveAs } from "file-saver";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    width: "900px",
    boxShadow: "0px 0px 30px -11px rgba(128,128,128,1)",
    height: "1000px",
  },
  content: {
    padding: "0 20px",
  },
  logoLeft: {
    width: "49%",
    display: "inline-block",
  },
  logoRight: {
    width: "49%",
    display: "inline-block",
  },
  leftImg: {
    // padding: "10px",
    width: "40%",
  },
  topHead: {
    textAlign: "center",
    backgroundColor: "#000",
    minHeight: "10px",
  },
  table: {
    width: "100%",
    display: "table",
    border: "1pt solid #ccc",
    marginBottom: "9pt",
  },
  tableRow: {
    display: "table-row",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    display: "table-cell",
    width: "25%",
    padding: "5px",
  },
  tableCol50: {
    display: "table-cell",
    width: "50%",
    padding: "5px",
  },
  tableCol40: {
    display: "table-cell",
    width: "40%",
    padding: "5px",
  },
  tableCol75: {
    display: "table-cell",
    width: "75%",
    padding: "5px",
  },
  tableCol80: {
    display: "table-cell",
    width: "80%",
    padding: "5px",
  },
  tableCol20: {
    display: "table-cell",
    width: "20%",
    padding: "5px",
  },
  tableCol25: {
    display: "table-cell",
    width: "25%",
    padding: "5px",
  },
  tableCol10: {
    display: "table-cell",
    width: "10%",
    padding: "5px",
  },
  tableCol15: {
    display: "table-cell",
    width: "15%",
    padding: "5px",
  },
  tableCol30: {
    display: "table-cell",
    width: "30%",
    padding: "5px",
  },
  tableCol65: {
    display: "table-cell",
    width: "65%",
    padding: "5px",
  },
  tableCol100: {
    isplay: "table-cell",
    width: "100%",
    padding: "5px",
  },
  tableCol115: {
    isplay: "table-cell",
    width: "115%",
    padding: "5px",
  },
  infoBlue: {
    backgroundColor: "#e7e7e7",
    textAlign: "left",
    color: "#333333",
  },
  infoGray: {
    backgroundColor: "#cccccc",
    color: "#000000",
  },
  infoLightGray: {
    backgroundColor: "#f3f3f3",
    color: "#000000",
  },
  interviewHeading: {
    color: "#555",
    fontSize: "13pt",
    fontWeight: "700",
  },
  childBorder: {
    border: "1pt solid #cccccc",
  },
  borderB: {
    borderBottom: "1pt solid #ccc",
  },
  borderL: {
    borderLeft: "1pt solid #ccc",
  },
  borderR: {
    borderRight: "1pt solid #ccc",
  },
  py: {
    padding: "10pt 5pt",
  },
  borderT: {
    borderTop: "1pt solid #ccc",
    marginHorizontal: "10pt",
  },
  borderA: {
    border: "1pt solid #ccc",
  },
  borderNone: {
    border: "none",
  },
  MainHead: {
    width: "100%",
    display: "block",
    textAlign: "center",
    marginBottom: "2rem",
  },
  mainText: {
    fontSize: "18pt",
    color: "#555555",
    padding: "10px 0",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  font14: {
    fontSize: "10.50pt",
  },
  font16: {
    fontSize: "11pt",
  },
  font17: {
    fontSize: "12.75pt",
  },
});

const getOrderTable1 = (orderList, formatPhoneNumber) => {
  return orderList.map((orderRow, index) => {
    return (
      <View key={index}>
        <View style={{ ...styles.tableRow, ...styles.borderT }} key={index}>
          <View
            style={{
              ...styles.tableCol25,
              ...styles.borderR,
              ...styles.borderL,
              ...styles.py,
            }}
          >
            <Text>{orderRow.group_name}</Text>
          </View>

          <View
            style={{ ...styles.tableCol15, ...styles.borderR, ...styles.py }}
          >
            <Text>{moment(orderRow.start_date).format("MM/DD/YYYY")}</Text>
          </View>
          <View
            style={{ ...styles.tableCol15, ...styles.borderR, ...styles.py }}
          >
            <Text>{moment(orderRow.end_date).format("MM/DD/YYYY")}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.quantity}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.rate}</Text>
          </View>
          <View
            style={{ ...styles.tableCol10, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.total}</Text>
          </View>
          <View
            style={{ ...styles.tableCol15, ...styles.borderR, ...styles.py }}
          >
            <Text>{orderRow.status}</Text>
          </View>
        </View>
        <View style={{ ...styles.childBorder }}>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCol15, ...styles.infoLightGray }}>
              <Text>{"Name"}</Text>
            </View>
            <View style={{ ...styles.tableCol20, ...styles.infoLightGray }}>
              <Text>{"Contact"}</Text>
            </View>
            <View style={{ ...styles.tableCol65, ...styles.infoLightGray }}>
              <Text>{"Address"}</Text>
            </View>
            {/* <View style={{ ...styles.tableCol20, ...styles.infoLightGray}}>
              <Text>{"Street"}</Text>
            </View>
            <View style={{ ...styles.tableCol15, ...styles.infoLightGray}}>
              <Text>{"City"}</Text>
            </View>
            <View style={{ ...styles.tableCol15, ...styles.infoLightGray}}>
              <Text>{"State"}</Text>
            </View>
            <View style={{ ...styles.tableCol10, ...styles.infoLightGray}}>
              <Text>{"Country"}</Text>
            </View> */}

            {/* <View style={{ ...styles.tableCol10, ...styles.infoLightGray}}>
              <Text>{"Total Amount"}</Text>
            </View> */}
          </View>
          {
            <View style={{ ...styles.tableRow }} key={index}>
              <View style={{ ...styles.tableCol15 }}>
                <Text>{orderRow.customer_name}</Text>
              </View>
              <View style={{ ...styles.tableCol20 }}>
                <Text>{formatPhoneNumber(orderRow.phone_number)}</Text>
              </View>
              <View style={{ ...styles.tableCol65 }}>
                <Text>
                  {orderRow.street +
                    ", " +
                    orderRow.city +
                    ", " +
                    orderRow.state +
                    ", " +
                    orderRow.country}
                </Text>
              </View>
              {/* <View style={{ ...styles.tableCol15 }}>
                    <Text>
                      {orderRow.city}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol15 }}>
                    <Text>{orderRow.state}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderRow.country}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderRow.quantity}</Text>
                  </View>
                  <View style={{ ...styles.tableCol15 }}>
                    <Text>{orderRow.total}</Text>
                  </View> */}
            </View>
          }
          {/* {orderRow.order_lines?.length < 0 ? (
            orderRow.order_lines.map((orderSubRow, index) => {
              return (
                <View style={{ ...styles.tableRow }} key={index}>
                  <View style={{ ...styles.tableCol20 }}>
                    <Text>{orderSubRow.group_name}</Text>
                  </View>

                  <View style={{ ...styles.tableCol15 }}>
                    <Text>
                      {moment(orderSubRow.start_date).format("MM/DD/YYYY")}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol15 }}>
                    <Text>
                      {moment(orderSubRow.end_date).format("MM/DD/YYYY")}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{orderSubRow.quantity}</Text>
                  </View>
                  <View style={{ ...styles.tableCol10 }}>
                    <Text>{parseFloat(orderSubRow.rate).toFixed(2)}</Text>
                  </View>
                  <View style={{ ...styles.tableCol15 }}>
                    <Text>{orderSubRow.supplier_name}</Text>
                  </View>
                  <View style={{ ...styles.tableCol15 }}>
                    <Text>{orderSubRow.status}</Text>
                  </View>
                </View>
              );
            })
          ) : (
            <td>No Data Found</td>
          )} */}
        </View>
      </View>
    );
  });
};

export function PdfDocument(props) {
  let orderHtml = getOrderTable1(
    props.pdfDocumentData,
    props.formatPhoneNumber
  );

  return (
    <Document style={{ backgroundColor: "#f2f3f8" }}>
      <Page style={styles.page} renderMode="canvas">
        <View style={styles.topHead}></View>

        <View style={styles.content}>
          <View style={{ marginBottom: "10pt" }}>
            <Text style={{ ...styles.interviewHeading, fontWeight: "bold" }}>
              {"Orders"}
            </Text>
          </View>
          <View>
            <View
              style={{
                ...styles.table,
                ...styles.font14,
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              <View style={{ ...styles.tableRow }}>
                <View style={{ ...styles.tableCol25, ...styles.infoGray }}>
                  <Text>{"Group Name"}</Text>
                </View>
                <View style={{ ...styles.tableCol15, ...styles.infoGray }}>
                  <Text>{"Start Date"}</Text>
                </View>
                <View style={{ ...styles.tableCol15, ...styles.infoGray }}>
                  <Text>{"End Date"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Quantity"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Rate"}</Text>
                </View>
                <View style={{ ...styles.tableCol10, ...styles.infoGray }}>
                  <Text>{"Total Amount"}</Text>
                </View>
                <View style={{ ...styles.tableCol15, ...styles.infoGray }}>
                  <Text>{"Status"}</Text>
                </View>
              </View>

              {orderHtml.length > 0 ? (
                orderHtml
              ) : (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol80 }}>
                    <Text>{"No orders"}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const data = [
  {
    Order_id: "222e0004-75ea-4920-a161-2a5d303f0982",
    Shipping_value: 0,
    Status: "pending",
    Total_tax: 2,
    customer_name: "Sudhir Singh",
    order_lines: [
      {
        Order_id: "222e0004-75ea-4920-a161-2a5d303f0982",
        Shipping_value: 0,
        Status: "pending",
        Total_tax: 2,
        customer_name: "Sudhir Singh",
      },
    ],
    total: 26.25,
  },
];
export const generatePdfDocument = async (orderList, formatPhoneNumber) => {
  const blob = await pdf(
    <PdfDocument
      title="My PDF"
      formatPhoneNumber={formatPhoneNumber}
      pdfDocumentData={orderList}
    />
  ).toBlob();

  saveAs(blob, `order${format(new Date(), "_MM_dd_yyyy_HH:mm:ss")}.pdf`);
};

/* Copyright Information: Created by BuyGroup */
