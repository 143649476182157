/* Copyright Information: Created by BuyGroup */
import Roles from "./Roles";
import Dashboard from "../Dashboard/Dashboard";
import Products from "../Products/Products";
import AddProduct from "../Products/AddProduct";
import EditProduct from "../Products/EditProduct";
import ViewProduct from "../Products/ViewProduct";
import Category from "../Category/Category";
import SuppliersList from "../Suppliers/SuppliersList";
import SupplierDetail from "../Suppliers/SupplierDetail";
import {
  faUser,
  faUsers,
  faLayerGroup,
  faChartPie,
  faShoppingCart,
  faSitemap,
  faCartPlus,
  faUserFriends,
  faLuggageCart,
} from "@fortawesome/free-solid-svg-icons";
import CustomersList from "../Customers/CustomersList";
import CustomerDetail from "../Customers/CustomerDetail";
import EditCustomerDetail from "../Customers/EditCustomerDetail";
import OrderList from "../OrderManagement/OrderList";
import GroupDetails from "../Groups/GroupDetails";
import GroupList from "../Groups/GroupList";
import AddGroup from "../Groups/AddGroup";
import Profile from "../Profile/Profile";
import SupplierProfile from "../Profile/SupplierProfile";
import EditGroup from "../Groups/EditGroup";
import SupplierGroupList from "../Groups/SupplierGroupList ";
import SupplierGroupDetails from "../Groups/SupplierGroupDetails";
import SupplierOrdersList from "../SupplierOrder/SupplierOrdersList";
import SupplierCustomerDetail from "../SupplierOrder/SupplierCustomerDetail";

// TODO:
/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * */

const PrivateRoutesConfig = [
  {
    component: Dashboard,
    path: "/app/dashboard",
    title: "Dashboard",
    icon: faChartPie,
    exact: true,
    permission: [Roles.ADMIN, Roles.SUPPLIER],
  },
  {
    component: GroupList,
    path: "/app/groups",
    title: "Groups",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.ADMIN],
  },

  {
    component: GroupDetails,
    path: "/app/groups/view/:id",
    title: "Groups",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.ADMIN, Roles.SUPPLIER],
    hidden: true,
  },
  {
    component: AddGroup,
    path: "/app/groups/add-group",
    title: "Groups",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },

  {
    component: OrderList,
    path: "/app/orders",
    title: "Orders",
    icon: faLuggageCart,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: SupplierOrdersList,
    path: "/app/supplier/orders",
    title: "Orders",
    icon: faLuggageCart,
    exact: true,
    permission: [Roles.SUPPLIER],
  },
  {
    component: Category,
    path: "/app/category",
    title: "Categories",
    icon: faSitemap,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: Products,
    path: "/app/products",
    title: "Products",
    icon: faCartPlus,
    exact: true,
    permission: [Roles.ADMIN],
    permission: [Roles.ADMIN],
  },
  {
    component: AddProduct,
    path: "/app/products/add-product",
    title: "Products",
    icon: faCartPlus,
    exact: true,
    hidden: true,
    permission: [Roles.ADMIN],
  },
  {
    component: EditProduct,
    path: "/app/products/:id",
    title: "Products",
    icon: faCartPlus,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },
  {
    component: ViewProduct,
    path: "/app/products/view-product/:id",
    title: "Products",
    icon: faCartPlus,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },
  {
    component: SuppliersList,
    path: "/app/suppliers",
    title: "Suppliers",
    icon: faUserFriends,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: SupplierDetail,
    path: "/app/suppliers/:id",
    title: "Suppliers",
    icon: faUsers,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },
  {
    component: CustomersList,
    path: "/app/members",
    title: "Members",
    icon: faUsers,
    exact: true,
    permission: [Roles.ADMIN],
  },

  {
    component: CustomerDetail,
    path: "/app/members/:id",
    title: "Members",
    icon: faUsers,
    exact: true,
    permission: [Roles.ADMIN, Roles.SUPPLIER],
    hidden: true,
  },
  {
    component: EditCustomerDetail,
    path: "/app/members/edit/:id",
    title: "Edit Members",
    icon: faUsers,
    exact: true,
    permission: [Roles.ADMIN, Roles.SUPPLIER],
    hidden: true,
  },

  {
    component: Profile,
    path: "/app/settings/profile",
    title: "Profile",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },
  {
    component: SupplierProfile,
    path: "/app/settings/profile",
    title: "Profile",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.SUPPLIER],
    hidden: true,
  },
  {
    component: AddGroup,
    path: "/app/groups/add-group",
    title: "Groups",
    icon: faLayerGroup,
    exact: true,
    hidden: true,
    permission: [Roles.ADMIN],
  },
  {
    component: EditGroup,
    path: "/app/groups/:id",
    title: "Groups",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.ADMIN],
    hidden: true,
  },
  {
    component: SupplierGroupList,
    path: "/app/supplier-groups",
    title: "See Groups",
    icon: faLayerGroup,
    exact: true,
    permission: [Roles.SUPPLIER],
  },
  {
    component: SupplierGroupDetails,
    path: "/app/supplier-groups/:id",
    title: "See Groups",
    icon: faLayerGroup,
    exact: true,
    hidden: true,
    permission: [Roles.SUPPLIER],
  },
  {
    component: SupplierCustomerDetail,
    path: "/app/supplier/memeber/:id",
    title: "See Groups",
    icon: faLayerGroup,
    exact: true,
    hidden: true,
    permission: [Roles.SUPPLIER],
  },
];

export default PrivateRoutesConfig;
/* Copyright Information: Created by BuyGroup */
