/* Copyright Information: Created by BuyGroup */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  CardHeader,
  Spinner,
  Input,
} from "reactstrap";
import { getProductDetail } from "../services/ProductServices";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/common/breadcrumb";
import ProductSlider from "./ProductSlider";
import Moment from "react-moment";
import { toast } from "react-toastify";
toast.configure();

const ViewProduct = (props) => {
  const [showLoader, setShowLoader] = useState(false);

  /* const productData store detail of product */
  const [productData, setProductData] = useState({});

  /* this function will give product detail of respective Id  */
  const getProductDetailList = (id) => {
    setShowLoader(true);
    getProductDetail(id)
      .then((res) => {
        if (res.status === 200) {
          setProductData(res.data[0]);
          setTimeout(() => {
            setShowLoader(false);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          props.history.push("/app/products");
        }
      });
  };

  useEffect(() => {
    getProductDetailList(props.match.params.id);
  }, []);

  const createMarkup = (data) => {
    return { __html: data };
  };

  return (
    <>
      <Breadcrumbs
        title="View Products"
        parent="Product"
        subtitle="Product Admin Panel"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h5>View Product</h5>
                  </div>
                  <div className="col-md-6 text-right">
                    <Link
                      to={{
                        pathname: "/app/products/" + props.match.params.id,
                        productDetails: props.location.productDetails,
                      }}
                    >
                      <Button
                        type="button"
                        color="secondary"
                        data-toggle="modal"
                      >
                        <i className="fa fa-pencil mr-2"></i>
                        Edit Product
                      </Button>
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {showLoader ? (
                  <div className="custom-loader">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <Row className="product-adding">
                      <Col className="col-md-5">
                        <div className="clearfix">
                          <ProductSlider
                            sliderImages={productData.imageUrl}
                            imageUrlKey="path"
                          />
                        </div>
                      </Col>
                      <Col className="col-md-7">
                        <h5>
                          <strong>{productData.product_name}</strong>
                        </h5>
                        <p
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {productData.description}
                        </p>

                        <Row>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Price </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>${productData.price}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Unit </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.unit}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>UPC </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.upc}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Category </strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{productData.category_name}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Sub Category</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                <p>{productData.sub_category_name}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Weight </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.weight} pound</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Lot Size </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.lot_size}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Minimum Quantity </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.minimum_quantity}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Maximum Quantity </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.maximum_quantity}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Expiration Date </strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>
                                  <Moment format="MM/DD/YYYY">
                                    {productData.expiration_date}
                                  </Moment>
                                </p>
                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Is Taxable</strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <Input
                                  className="align-checkbox"
                                  name="is_taxable"
                                  id="is_taxable"
                                  type="checkbox"
                                  checked={productData.is_taxable}
                                // disabled
                                />
                              </div>
                            </div>
                          </Col>

                          {
                            productData.is_taxable &&
                            <Col md="4">
                              <div>
                                <h5>
                                  <strong>Tax Rate</strong>
                                </h5>
                                <div className="clearfix product_detail_value">
                                  <p>{productData.tax_rate}%</p>
                                </div>
                              </div>
                            </Col>
                          }

                          <Col md="4">
                            <div>
                              <h5>
                                <strong>Other Restrictions</strong>
                              </h5>
                              <div className="clearfix product_detail_value">
                                <p>{productData.other_restrictions}</p>
                              </div>
                            </div>
                          </Col>

                          <Col md="12">
                            <div>
                              <h5>
                                <strong>Supplier</strong>
                              </h5>
                              <div className="clearfix product_detail_category">
                                {productData.suppliers != undefined
                                  ? productData.suppliers.map(
                                    (supplier_name, index) => {
                                      return (
                                        <span className="supplier_name_detail_pro">
                                          <Link
                                            to={{
                                              pathname: `/app/suppliers/${supplier_name.value}`,
                                              state: {
                                                supplierId:
                                                  supplier_name.value,
                                              },
                                            }}
                                          >
                                            {supplier_name.label}
                                          </Link>
                                          {index <
                                            productData.suppliers.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      );
                                    }
                                  )
                                  : null}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <h5>
                                <strong>Specification </strong>
                              </h5>
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  productData.specification
                                )}
                                className="list_specification"
                              ></div>
                            </div>
                          </Col>
                        </Row>
                        {productData.documentUrl != undefined ? (
                          <Row>
                            <Col md="12">
                              <div>
                                <h5>
                                  <strong>Document </strong>
                                </h5>
                                {productData.documentUrl.length ? (
                                  <a
                                    href={productData.documentUrl[0].path}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {productData.document_file}
                                  </a>
                                ) : (
                                  "Document is not available"
                                )}
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewProduct;
/* Copyright Information: Created by BuyGroup */
